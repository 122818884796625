<template>
  <section class="workload-history">
    <runai-default-loader v-if="isPageLoading" />
    <template v-else>
      <workload-status-history-chart
        class="q-pt-md"
        :drawer-width="drawerWidth"
        :workload="workload"
        :status-history="statusHistory"
      />
      <workload-event-history
        :workload="workload"
        :columns="columns"
        :filter-by="filterBy"
        :loading="loadingWorkloadHistory"
        :events="workloadHistory"
        @update-filter-by="updateFilterBy"
      />
    </template>
  </section>
</template>

<script lang="ts">
// cmps
import { WorkloadEventHistory } from "@/components/old-workload/workload-event-history";
import { WorkloadStatusHistoryChart } from "@/components/old-workload/workload-status-history-chart";
import { RunaiDefaultLoader } from "@/components/common/runai-default-loader";

// types
import type { IWorkspaceList } from "@/models/workspace.model";
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import { type PropType, defineComponent } from "vue";
import type { QTableProps } from "quasar";
import type { IFilterBy, IJobFilter } from "@/models/filter.model";
import type { ITrainingList } from "@/models/training.model";

// model
import { eventHistoryColumns } from "@/components/runai-event-history-table";
import type { ITableColumn } from "@/models/table.model";

// service
import { jobService } from "@/services/control-plane/job.service/job.service";
import { alertUtil } from "@/utils/alert.util";
import { HttpErrorResponse } from "@/models/http-response.model";

const displayedColumns: Array<string> = eventHistoryColumns.map((col: ITableColumn) => col.name);

function getInitFilters(): IFilterBy {
  return {
    sortBy: "createdAt",
    descending: true,
    columnFilters: [],
    searchTerm: "",
    displayedColumns,
  };
}

export default defineComponent({
  components: {
    WorkloadEventHistory,
    WorkloadStatusHistoryChart,
    RunaiDefaultLoader,
  },
  props: {
    workload: {
      type: Object as PropType<IWorkspaceList | ITrainingList>,
      required: true,
    },
    drawerWidth: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      workloadHistory: [] as WorkloadHistoryResponseEntriesInner[],
      statusHistory: [] as WorkloadHistoryResponseEntriesInner[],
      loadingWorkloadHistory: false as boolean,
      columns: eventHistoryColumns as QTableProps["columns"],
      refreshInterval: 5000 as number,
      refreshDataIntervalId: 0 as number,
      filterBy: getInitFilters() as IFilterBy,
      isPageLoading: false as boolean,
    };
  },
  created() {
    this.resetCmpState();
  },
  computed: {
    workloadId(): string {
      return this.workload.meta.id;
    },
  },
  methods: {
    updateFilterBy(filterBy: IFilterBy): void {
      this.filterBy = filterBy;
      this.loadWorkloadHistory(true);
    },
    async resetCmpState(): Promise<void> {
      try {
        this.isPageLoading = true;
        window.clearInterval(this.refreshDataIntervalId);
        this.filterBy = getInitFilters();
        if (this.workloadId) {
          await this.loadData();
        }
      } finally {
        if (this.workloadId) {
          this.refreshDataIntervalId = window.setInterval(this.loadData, this.refreshInterval);
        }
        this.isPageLoading = false;
      }
    },
    async loadData(): Promise<void> {
      await Promise.all([this.loadWorkloadHistory(), this.loadStatusHistory()]);
    },
    async loadWorkload(filter: IJobFilter): Promise<WorkloadHistoryResponseEntriesInner[]> {
      try {
        if (!this.workload.job || !this.workload.job.clusterId || !this.workload.job.podGroupId) {
          throw new Error("One of the parameters is missing");
        }

        const workloadData: WorkloadHistoryResponseEntriesInner[] = await jobService.workloadHistory(
          this.workload.job.clusterId,
          this.workload.job.podGroupId,
          filter,
        );

        return workloadData;
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to load workload history"));
        }
        throw error;
      }
    },
    async loadWorkloadHistory(filterByChanged = false): Promise<void> {
      try {
        this.loadingWorkloadHistory = filterByChanged;
        this.workloadHistory = await this.loadWorkload(this.filterBy);
      } finally {
        this.loadingWorkloadHistory = false;
      }
    },
    async loadStatusHistory(): Promise<void> {
      this.statusHistory = await this.loadWorkload({ type: "statusChange" });
    },
  },
  watch: {
    workloadId(): void {
      this.resetCmpState();
    },
  },
  unmounted() {
    window.clearInterval(this.refreshDataIntervalId);
  },
});
</script>
