<template>
  <div class="status-chart">
    <highcharts v-if="chartOptions" :options="chartOptions" ref="status-history-chart"></highcharts>
  </div>
</template>
<script lang="ts">
// models
import Highcharts from "highcharts";
import xRange from "highcharts/modules/xrange";
import { Chart } from "highcharts-vue";

// types
import type { Options } from "highcharts";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { PropType } from "vue";
import type { ITrainingList } from "@/models/training.model";

// services
import { chartUtil } from "@/utils/chart.util";
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import { debounce } from "quasar";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Highcharts: Chart,
  },
  props: {
    workload: {
      type: Object as PropType<IWorkspaceList | ITrainingList>,
      required: true,
    },
    drawerWidth: {
      type: String as PropType<string>,
      required: true,
    },
    statusHistory: {
      type: Array as PropType<WorkloadHistoryResponseEntriesInner[]>,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null as null | Options,
    };
  },
  created() {
    xRange(Highcharts);
    this.setChartOptions();
  },
  methods: {
    setChartOptions(): void {
      this.chartOptions = chartUtil.getXRangeOptions(this.statusHistory);
    },
    resizeChart() {
      this.$nextTick(() => {
        (this.$refs["status-history-chart"] as Chart).chart.reflow();
      });
    },
  },
  watch: {
    drawerWidth: debounce(function () {
      //@ts-ignore
      this.resizeChart();
    }, 100),
    statusHistory: {
      handler(): void {
        this.setChartOptions();
      },
    },
  },
});
</script>

<style lang="scss"></style>
