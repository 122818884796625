<template>
  <section class="training-index">
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          :primary-btn-disable="disablePrimaryBtn"
          :primary-tooltip-text="primaryTooltipText"
          primary-btn-label="new training"
          :columns="columnsOptions"
          :filters="filterBy"
          @filters-changed="updateFilterBy"
          @create-entity="createTraining"
          :selected-rows-amount="selectedRowsAmount"
          @selected-actions-close="resetSelectedRows"
          show-details-btn
          empty-message="Select a training to view its details"
          @drawer-toggled="isDrawerOpen = $event"
          @drawer-resized="drawerWidth = $event"
          @export-csv="exportTableAsCsv"
        >
          <template v-slot:selected-rows-actions>
            <div class="row items-center justify-start">
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && (isInitialLoader || !canActivated)"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.activate)"
              >
                <runai-action-button
                  aid="activate-training-btn"
                  btn-action="activate"
                  @click="activateTraining"
                  :disable="!isClusterConnected || isInitialLoader || !canActivated"
                  v-permission="{ resourceType: ResourceType.Trainings, action: Action.Create }"
                />
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && (isInitialLoader || !canStop)"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.stop)"
              >
                <runai-action-button
                  aid="stop-training-btn"
                  btn-action="stop"
                  @click="stopTraining"
                  :disable="!isClusterConnected || isInitialLoader || !canStop"
                />
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canConnect"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.connect)"
              >
                <runai-action-button
                  aid="connect-training-btn"
                  btn-action="connect"
                  :disable="!isClusterConnected || !canConnect"
                >
                  <tools-select @value-changed="onOpenTool" :tool-items="toolItems"></tools-select>
                </runai-action-button>
              </runai-tooltip-wrapper>
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canCreateTraining"
                :tooltip-text="noActionPermissionText"
              >
                <runai-action-button
                  aid="copy-training-btn"
                  btn-action="copy"
                  @click="cloneTraining"
                  :disable="!isClusterConnected || !canCreateTraining"
                />
              </runai-tooltip-wrapper>
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canDeleteTraining"
                :tooltip-text="noActionPermissionText"
              >
                <runai-action-button
                  :disable="!isClusterConnected || !canDeleteTraining"
                  :tooltip-text="noActionPermissionText"
                  aid="delete-training-btn"
                  btn-action="delete"
                  @click="openDeleteModal"
                />
              </runai-tooltip-wrapper>
            </div>
          </template>
          <template v-slot:drawer-content>
            <workload-drawer-content entity-type="training" :drawer-width="drawerWidth" :workload="currentTraining" />
          </template>
        </runai-page-actions>
      </template>
      <template v-slot:table>
        <runai-table
          :style="{ marginRight: tableMargin }"
          :rows="trainings"
          :columns="columnsOptions"
          :loading="isInitialLoader && loading"
          :filter-by="filterBy"
          v-model:selected="selectedRows"
          @data-source-clicked="displayDataSources"
          @update-filters="updateFilterBy"
          :top-row="lastCreatedTraining"
          sticky-columns
          :get-row-icon="getRowIcon"
          :get-row-key="getRowKey"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!isInitialLoader && !lastCreatedTraining"
              entity-name="training"
              icon-name="training"
              :filter-by="filterBy"
              :show-error="loadingError"
              :show-create-btn="!disablePrimaryBtn"
              @clear-filters="clearFilters"
              @create-new="createTraining"
            >
              <template #secondary-text v-if="noExistingProjects">
                <runai-table-no-projects
                  entity-type="training"
                  :has-entity-create-permission="canCreateTraining"
                  :has-project-create-permission="canCreateProject"
                />
              </template>
            </runai-table-no-data>
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>

    <data-source-list-modal
      v-if="openDataSourceModal"
      @close="closeDataSourceModal"
      :modal-options="dataSourceModalOptions"
    ></data-source-list-modal>

    <runai-delete-modal
      v-if="isDeleteModalOpen && selectedTrainings.length >= 1"
      entity-type="training"
      :custom-title="`Permanently delete training ${selectedTrainings[0].meta.name} and its logs?`"
      :entity-name="selectedTrainings[0].meta.name"
      @cancel="isDeleteModalOpen = false"
      @delete="deleteTraining"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Services
import { filterService } from "@/services/filter.service/filter.service";
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";
// utils
import { tableUtil } from "@/utils/table.util";
import { alertUtil } from "@/utils/alert.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { connectionAlertUtil } from "@/utils/connection-alert.util";

// Stores
import { useClusterStore } from "@/stores/cluster.store";
import { useTrainingStore } from "@/stores/training.store";
import { useAppStore } from "@/stores/app.store";

// Constants
import { EWorkloadStatus } from "@/common/status.constant";

// models
import {
  allTrainingColumnsMap,
  type ITrainingFilterBy,
  type ITrainingList,
  trainingIndexColumns,
} from "@/models/training.model";
import type { ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames, ETableFilters } from "@/models/table.model";
import type { IFilterBy } from "@/models/filter.model";
import { DataSourceListModal } from "@/components/data-source/data-source-list-modal";
import type { AssetIdAndKind, DistFramework } from "@/swagger-models/assets-service-client";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { HttpErrorResponse } from "@/models/http-response.model";
import { EWorkloadAction, EWorkloadEntity } from "@/models/workload.model";
import type { IReadinessStatus } from "@/models/researcher.model";

// routes
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";

//components
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { ToolsSelect } from "@/components/tools";
import { RunaiTable, RunaiTableNoProjects } from "@/components/common/runai-table";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { useProjectStore } from "@/stores/project.store";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { WorkloadDrawerContent } from "@/components/old-workload/workload-drawer-content.vue";
import { RunaiDeleteModal } from "@/components/common";
import { toolTypeIconsMap, distFrameworkIconsMap } from "@/common/icons.constant";
import { usePermissionStore } from "@/stores/permissions.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useNodePoolStore } from "@/stores/node-pool.store";
import type { IToolItem } from "@/models/workspace.model";
import type { IDataSourceModalOptions } from "@/models/data-source.model";

export default defineComponent({
  components: {
    RunaiTableWrapper,
    ToolsSelect,
    RunaiPageActions,
    RunaiActionButton,
    RunaiTable,
    RunaiTableNoData,
    DataSourceListModal,
    RunaiTooltipWrapper,
    WorkloadDrawerContent,
    RunaiDeleteModal,
    RunaiTableNoProjects,
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      projectStore: useProjectStore(),
      departmentStore: useDepartmentStore(),
      clusterStore: useClusterStore(),
      trainingStore: useTrainingStore(),
      appStore: useAppStore(),
      nodePoolStore: useNodePoolStore(),
      loading: false as boolean,
      selectedRows: [] as Array<ITrainingList>,
      isDeleteModalOpen: false as boolean,
      refreshInterval: 5000 as number,
      currentTimeoutId: 0 as number,
      openDataSourceModal: false as boolean,
      dataSourceModalOptions: { dataSourceIds: [], header: "" } as IDataSourceModalOptions,
      loadingError: false as boolean,
      isDrawerOpen: false as boolean,
      hideNodePoolsColumn: false as boolean,
      drawerWidth: "0px" as string,
      isInitialLoader: true as boolean,
      columns: trainingIndexColumns as Array<ITableColumn>,
      filterBy: {} as ITrainingFilterBy,
      isClusterConnected: true as boolean,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.checkConnectivityStatus();
    this.loadFilters();
    this.loadProjects();
    this.loadTrainings();
    try {
      this.nodePoolStore.loadNodePoolsCount();
    } catch (e) {
      console.log(e);
      this.hideNodePoolsColumn = true;
    }
    this.currentTimeoutId = window.setInterval(this.loadTrainings, this.refreshInterval);
  },
  computed: {
    EWorkloadAction(): typeof EWorkloadAction {
      return EWorkloadAction;
    },
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    columnsOptions(): ITableColumn[] {
      return this.columns.filter((col: ITableColumn) => {
        if (col.name === "nodePool") {
          return !this.nodePoolStore.isOnlyDefaultNodePool && !this.hideNodePoolsColumn;
        }
        if (col.name === "multi-node") {
          return this.clusterStore.isVersionSupportDistributedTraining;
        }
        return true;
      });
    },
    tableMargin(): string {
      return this.isDrawerOpen ? this.drawerWidth : "0px";
    },
    canCreateTraining(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Trainings, Action.Create);
    },
    canCreateProject(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    noExistingProjects(): boolean {
      return this.projectStore.projects.length === 0;
    },
    trainings(): Array<ITrainingList> {
      return this.trainingStore.trainingList;
    },
    selectedTrainings(): ITrainingList[] {
      return this.selectedRows;
    },
    selectedRowsAmount(): number {
      return this.selectedTrainings.length;
    },
    canActivated(): boolean {
      if (!this.canUpdateTraining) return false;
      return this.selectedTrainings.length === 1 && EWorkloadStatus.Stopped === this.currentTraining?.job?.status;
    },
    canStop(): boolean {
      if (!this.canUpdateTraining) return false;
      return (
        this.selectedTrainings.length === 1 &&
        [EWorkloadStatus.Active, EWorkloadStatus.Pending].some(
          (status: EWorkloadStatus) => status === this.currentTraining?.job?.status,
        )
      );
    },
    canUpdateTraining(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Trainings, Action.Update);
    },
    canDeleteTraining(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Trainings, Action.Delete);
    },
    canConnect(): boolean {
      if (!this.canUpdateTraining) return false;
      return this.currentTraining?.job?.status === EWorkloadStatus.Active && !!this.currentTraining.connections?.length;
    },
    currentTraining(): ITrainingList {
      return this.selectedTrainings[0];
    },
    primaryTooltipText(): string {
      if (!this.isClusterConnected) {
        return "";
      } else if (this.canCreateProject) {
        return "To create a new training, First create at least one project";
      } else if (this.canCreateTraining) {
        return "Trainings can only be created under a project. \nContact your administrator to add a project.";
      } else {
        return "";
      }
    },
    noActionPermissionText(): string {
      return "Action for the selected training is not authorized";
    },
    lastCreatedTraining(): ITrainingList | null {
      return this.trainingStore.lastCreatedTraining;
    },
    findSelectedInTable(): ITrainingList | undefined {
      if (!this.currentTraining) return undefined;

      const selectedRowId: string = this.currentTraining.meta.id;
      if (this.lastCreatedTraining?.meta.id === selectedRowId) {
        return this.lastCreatedTraining;
      }

      return this.trainings.find((training: ITrainingList) => training.meta.id === selectedRowId);
    },
    disablePrimaryBtn(): boolean {
      return this.noExistingProjects || !this.canCreateTraining || !this.isClusterConnected;
    },
    toolItems(): IToolItem[] {
      if (!this.selectedRows[0]?.connections) return [];
      return this.selectedRows[0]?.connections?.map((c: IToolItem) => {
        const authorizedUsers: string[] | null =
          this.selectedRows[0]?.spec.specificEnv?.connections?.find((conn) => conn.name === c.toolName)
            ?.authorizedUsers || null;
        if (!authorizedUsers) return c;
        return {
          ...c,
          authorizedUsers,
        };
      });
    },
  },
  methods: {
    async checkConnectivityStatus(): Promise<void> {
      if (!this.clusterStore.currentCluster.domain) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getMissingDomainMessage()));
        this.isClusterConnected = false;
        return;
      }

      const res: IReadinessStatus = await whoamiService.isCurrentClusterConnected();
      if (!res.connected) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getConnectivityMessage(res.statusCode)));
        this.isClusterConnected = false;
      }
    },
    getRowIcon(training: ITrainingList): string {
      // DistFramwork icon has priority over connections.
      if (training.spec.distributed?.distFramework) {
        const toolType: DistFramework = training.spec.distributed?.distFramework;
        return distFrameworkIconsMap[toolType] || "";
      } else if (training.connections?.length) {
        const toolType: string = training.connections[0].toolType;
        return toolTypeIconsMap[toolType] || "";
      } else return "";
    },
    getRowKey(training: ITrainingList): string {
      return training.meta.id;
    },
    getDisabledToolTipText(action: EWorkloadAction): string {
      if (!this.canUpdateTraining) return this.noActionPermissionText;
      return workloadUtil.getDisabledToolTipText(action, EWorkloadEntity.training);
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
    },
    async loadTrainings(): Promise<void> {
      try {
        this.loading = true;
        await this.trainingStore.loadTrainings(this.filterBy);
        if (this.lastCreatedTraining) {
          try {
            await this.trainingStore.loadLastCreatedTraining(
              this.clusterStore.currentCluster.uuid,
              this.lastCreatedTraining.meta.id,
            );
          } catch (error: unknown) {
            // Handling the case that the last created training was deleted. We don't want to try to load it again.
            this.trainingStore.removeTrainingAdded();
          }
        }
      } catch (e: unknown) {
        console.error(e);
        this.$q.notify(alertUtil.getError(`Failed to load trainings`));
        this.loadingError = true;
      } finally {
        this.isInitialLoader = false;
        this.loading = false;
      }
    },
    cloneTraining(): void {
      this.$router.push({
        name: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
        query: { fromCopyId: this.selectedRows[0].meta.id },
      });
    },
    updateFilterBy(filterBy: IFilterBy, keyChanged: null | string = null, forceLoad = true): void {
      this.filterBy = filterBy;
      filterService.saveFilters(ETableFilters.TRAINING, filterBy);
      if (!forceLoad || keyChanged === "displayedColumns") return;

      this.isInitialLoader = true;
      this.trainingStore.removeTrainingAdded();
      this.loadTrainings();
    },
    clearFilters(): void {
      this.updateFilterBy(
        {
          ...this.filterBy,
          columnFilters: [],
          searchTerm: "",
        },
        null,
        true,
      );
    },
    createTraining(): void {
      this.$router.push({ name: TRAINING_ROUTE_NAMES.TRAINING_NEW });
    },
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(allTrainingColumnsMap.name.name, this.columns);

      const searchParamsFilters: IFilterBy = filterService.loadFilters(
        window.location,
        ETableFilters.TRAINING,
        defaultFilters,
      );
      this.updateFilterBy(searchParamsFilters, null, false);
    },
    resetSelectedRows(): void {
      this.selectedRows = [];
    },
    onOpenTool(url: string): void {
      window.open(url.startsWith("http") ? url : "http://" + url);
    },
    async activateTraining(): Promise<void> {
      this.loading = true;
      try {
        await this.trainingStore.activateTraining(this.selectedTrainings[0]);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to start training", error as string);
        }
      } finally {
        await this.trainingStore.loadTrainings(this.filterBy);
        this.loading = false;
        this.resetSelectedRows();
      }
    },
    async stopTraining(): Promise<void> {
      this.loading = true;
      try {
        await this.trainingStore.stopTrainig(this.selectedTrainings[0]);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to stop training", error as string);
        }
      } finally {
        await this.trainingStore.loadTrainings(this.filterBy);
        this.loading = false;
        this.resetSelectedRows();
      }
    },
    async deleteTraining(): Promise<void> {
      const training: ITrainingList | undefined = this.selectedTrainings[0];
      if (!training) return;

      try {
        this.loading = true;
        await this.trainingStore.deleteTraining(training);
        if (this.lastCreatedTraining?.meta.id === training.meta.id) {
          this.trainingStore.removeTrainingAdded();
        }
        this.$q.notify(alertUtil.getSuccess(`Training ${training.meta.name} deleted`));
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to delete training", error as string);
        }
      } finally {
        this.isDeleteModalOpen = false;
        this.startRefreshTrainings();
        this.resetSelectedRows();
        this.loading = false;
      }
    },
    getErrorMsgIfAccessDenied(baseMsg: string, error: string): string {
      const errStr = error.toString().toLowerCase();
      if (errStr.includes("access denied") || errStr.includes("not allowed")) {
        return `${baseMsg}: Access Denied`;
      }
      return baseMsg;
    },
    notifyMsgWithError(baseMsg: string, error: string): void {
      let errorStr = this.getErrorMsgIfAccessDenied(baseMsg, error);
      this.$q.notify(alertUtil.getError(errorStr));
    },
    openDeleteModal(): void {
      this.stopRefreshTrainings();
      this.isDeleteModalOpen = true;
    },
    async stopRefreshTrainings(): Promise<void> {
      window.clearTimeout(this.currentTimeoutId);
    },
    async startRefreshTrainings(): Promise<void> {
      this.currentTimeoutId = window.setInterval(this.loadTrainings, this.refreshInterval);
    },
    displayDataSources(training: ITrainingList): void {
      if (!training.spec.assets.datasources) return;

      const datasources: Array<AssetIdAndKind> = training.spec.assets.datasources;
      this.dataSourceModalOptions.header = `Data Sources Associated with ${training.meta.name}`;
      this.dataSourceModalOptions.dataSourceIds = datasources.map((dataSource: AssetIdAndKind) => dataSource.id);
      this.openDataSourceModal = Boolean(training);
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Training, this.trainings, columns);
    },
    closeDataSourceModal(): void {
      this.openDataSourceModal = false;
      this.resetDataSourceModalOptions();
    },
    resetDataSourceModalOptions(): void {
      this.dataSourceModalOptions = { dataSourceIds: [], header: "" };
    },
  },
  watch: {
    findSelectedInTable(training: ITrainingList) {
      if (!training) return;
      this.selectedRows[0] = training;
    },
  },
  unmounted() {
    this.stopRefreshTrainings();
    this.trainingStore.resetCreateTrainingData();
    this.trainingStore.removeTrainingAdded();
  },
});
</script>
