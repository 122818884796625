<template>
  <q-list padding class="sidenav-list no-padding">
    <template v-for="item in filteredItems" :key="item.aid">
      <router-link :to="{ name: item.name }" custom v-slot="{ href, navigate, isActive }">
        <a class="sidenav-list-item" v-if="item.subLinks" :aid="item.aid" @click="$emit('toggle-menu', item)">
          <i v-if="item.subLinks" class="fa-sharp fa-solid fa-play extended-icon" :class="{ 'is-open': item.open }"></i>

          <span class="sidenav-list-icon">
            <runai-svg-icon :name="item.icon" size="28" />
          </span>

          <div class="sidenav-list-text">{{ item.text }}</div>
        </a>
        <a
          v-else
          class="sidenav-list-item"
          :href="href"
          :aid="item.aid"
          :class="{ 'is-active': isActive }"
          @click="navigate"
        >
          <span class="sidenav-list-icon">
            <runai-svg-icon :name="item.icon" size="28" />
          </span>

          <div class="sidenav-list-text">{{ item.text }}</div>
        </a>
      </router-link>

      <transition-group name="slide">
        <template v-if="item.subLinks && item.open">
          <q-separator class="separator" />
          <router-link
            v-for="subLink in item.subLinks"
            class="sidenav-list-item"
            active-class="is-active"
            v-ripple
            clickable
            :to="{ name: subLink.name }"
            :key="subLink.name"
            :aid="subLink.aid"
          >
            <span class="sidenav-list-icon">
              <runai-svg-icon :name="subLink.icon" size="28" />
            </span>

            <div class="sidenav-list-text">{{ subLink.text }}</div>
          </router-link>
        </template>
      </transition-group>
    </template>
  </q-list>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import type { INavItem } from "./side-nav.model";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  emits: ["toggle-menu"],
  props: {
    items: {
      type: Array as PropType<Array<INavItem>>,
      required: true,
    },
  },
  computed: {
    filteredItems(): Array<INavItem> {
      return this.items.filter((item: INavItem) => !item.hidden);
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-list {
  .sidenav-list-item {
    color: $white-60;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    height: 53px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      background-color: $white-10;
      color: $white;
      cursor: pointer;
    }
    .sidenav-list-text {
      font-size: 16px;
    }
  }

  .is-active {
    background: $white-10;
    color: $white;
  }

  .sidenav-list-icon {
    fill: currentColor;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 10px;
  }

  .extended-icon {
    position: absolute;
    font-size: 10px;
    transition: transform 0.3s;
    left: 8px;

    &.is-open {
      transform: rotate(90deg);
    }
  }

  .slide-enter-active {
    transition: all 2s ease-out;
    max-height: 500px;
    overflow: hidden;
  }

  .slide-leave-active {
    transition: all 0.5s ease-out;
    max-height: 500px;
    overflow: hidden;
  }

  .slide-enter-from,
  .slide-leave-to {
    max-height: 0;
  }
}
</style>
