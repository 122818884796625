// Stores
import { defineStore } from "pinia";
import { useClusterStore } from "@/stores/cluster.store";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { IDeploymentList } from "@/models/deployment.model";
import { deploymentService } from "@/services/control-plane/deployment.service/deployment.service";

export const useDeploymentStore = defineStore("Deployment", {
  state: () => ({
    deployments: [] as Array<IDeploymentList>,
  }),
  getters: {},
  actions: {
    async loadDeployments(filterBy: IFilterBy = {}): Promise<void> {
      const clusterStore = useClusterStore();
      const clusterId: string = clusterStore.currentCluster.uuid;

      this.deployments = await deploymentService.list(clusterId, {
        ...filterBy,
        sortBy: filterBy.sortBy || "",
      });
    },
    async deleteDeployment(deployment: IDeploymentList): Promise<IDeploymentList> {
      const deploymentDeleted: IDeploymentList = await deploymentService.remove(deployment);

      // TODO: will need to uncomment this when we move the implemantation to backend
      // this.deployments = this.deployments.map((currWorkspace: IDeploymentList) => {
      //   return currWorkspace.id === deploymentDeleted.id ? deploymentDeleted : currWorkspace;
      // });
      return deploymentDeleted;
    },
  },
});
