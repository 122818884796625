import axios, { type AxiosRequestConfig } from "axios";
import { useAuthStore } from "@/stores/auth.store";
import { useClusterStore } from "@/stores/cluster.store";
import { storageUtil } from "@/utils/storage.util";
import { USE_EXTERNAL_TOKEN } from "@/common/storage.constant";
import type { IReadinessStatus } from "@/models/researcher.model";

export const whoamiService = {
  shouldUseExternalToken,
  isInternalTokenValid,
  isCurrentClusterConnected,
};

interface IHeaders {
  Accept: string;
  Authorization: string;
  AuthInfo?: string;
}

async function isCurrentClusterConnected(): Promise<IReadinessStatus> {
  const authStore = useAuthStore();
  const clusterStore = useClusterStore();

  const useExternalToken = storageUtil.get<boolean>(USE_EXTERNAL_TOKEN);
  try {
    let headers: IHeaders;
    if (useExternalToken) {
      headers = getHeaders(authStore.accessToken, authStore.externalToken);
    } else {
      headers = getHeaders(authStore.accessToken);
    }

    const requestOptions: AxiosRequestConfig = getRequestOptions(clusterStore.currentClusterDomain, headers);
    await axios(requestOptions);
    return { connected: true };
    // eslint-disable-next-line
  } catch (error: any) {
    if (error?.response?.status) {
      return { connected: false, statusCode: error.response.status };
    } else {
      return { connected: false };
    }
  }
}

async function shouldUseExternalToken(clusterDomain: string): Promise<IReadinessStatus> {
  const authStore = useAuthStore();
  if (!authStore.externalToken) {
    return { connected: false };
  }
  try {
    const headers: IHeaders = getHeaders(authStore.accessToken, authStore.externalToken);
    const requestOptions: AxiosRequestConfig = getRequestOptions(clusterDomain, headers);
    await axios(requestOptions);
    return { connected: true };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { connected: false, statusCode: error.response.status };
  }
}

async function isInternalTokenValid(clusterDomain: string): Promise<IReadinessStatus> {
  const authStore = useAuthStore();
  try {
    const headers: IHeaders = getHeaders(authStore.accessToken);
    const requestOptions: AxiosRequestConfig = getRequestOptions(clusterDomain, headers);
    await axios(requestOptions);
    return { connected: true };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { connected: false, statusCode: error.response.status };
  }
}

function getRequestOptions(clusterDomain: string, headers: IHeaders): AxiosRequestConfig {
  return {
    url: `${clusterDomain}/researcher/api/v1/whoami`,
    method: "GET",
    headers,
  };
}

function getHeaders(accessToken: string, externalToken?: string) {
  if (externalToken) {
    return {
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${externalToken}`,
      AuthInfo: `Bearer ${accessToken}`,
    };
  } else {
    return {
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${accessToken}`,
    };
  }
}
