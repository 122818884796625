<template>
  <section class="model-spec-new column items-center q-pt-md">
    <runai-form-wrapper ref="deplymentForm" :form-state="modelSpecCreate">
      <runai-expansion-wrapper>
        <project-section
          entity-type="deployment"
          aid="project-section"
          :loading="loadingData"
          :selected-project-id="modelSpecCreate.projectId"
          :projects="projectResources"
          @project-changed="onSelectedProject"
        />

        <workload-name-section
          entity-type="deployment"
          aid="ws-name-section"
          v-model:name="modelSpecCreate.name"
          :disable-closing="true"
          :project-id="modelSpecCreate.projectId"
          :cluster-id="clusterUuid"
        />

        <transition name="fade">
          <compute-resource-section
            v-if="displayComputeResourceSection"
            aid="compute-resource-section"
            :entity-type="workloadType"
            :loading="false"
            :compute-resources="computeResources"
            :node-affinity="nodeAffinity"
            @compute-resource-data-changed="onComputeResourceDataChanged"
            :compute-resource-data="computeResourceData"
            :is-required="true"
            :section-options="{ hideCardsActions: true, autoScale: true }"
          />
        </transition>
      </runai-expansion-wrapper>
      <section class="row items-center q-mt-md">
        <q-field class="col-4 form-hint no-padding" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
        <div class="buttons q-ml-auto">
          <q-btn flat class="q-mr-sm" color="primary" label="Cancel" aid="cancel-btn" @click="onCancel" />
          <q-btn
            label="create deployment"
            aid="create-workspace-continue-btn"
            @click="onDeploy"
            :loading="submitting"
            color="primary"
          ></q-btn>
        </div>
      </section>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Stores
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useProjectStore } from "@/stores/project.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useModelSpecStore } from "@/stores/model-spec.store";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { ProjectSection } from "@/components/section/project-section";
import { ComputeResourceSection } from "@/components/section/compute-resource-section";

// models
import type { IProject, IProjectResources, INodePoolResources, ISelectedNodeAffinity } from "@/models/project.model";
import { EWorkloadFormType, type IUIWorkloadModelCreation } from "@/models/workload.model";
import type { ComputeAsset, ModelAsset, ModelInferenceCreationRequest } from "@/swagger-models/assets-service-client";
import type { IComputeSectionData } from "@/components/section/compute-resource-section";
import { ENodePoolsListOrigin, type IComputeSectionNodePoolData } from "@/components/section/compute-resource-section";

// services
import { modelAssetService } from "@/services/control-plane/model-asset.service/model-asset.service";

// utils
import { alertUtil } from "@/utils/alert.util";
import { omit } from "@/utils/common.util";
import { ErrorAlert } from "@/utils/error-alert.util";
import { is } from "quasar";

import { MODEL_SPEC_NAMES } from "@/router/model-spec.routes/model-spec.routes.names";
import { DEPLOYMENT_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";

const MIN_REPLICAS_DEFAULT_VALUE = 1;
const MAX_REPLICAS_DEFAULT_VALUE = 1;

export default defineComponent({
  components: {
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    ProjectSection,
    WorkloadNameSection,
    ComputeResourceSection,
  },
  data() {
    return {
      appStore: useAppStore(),
      modelSpecStore: useModelSpecStore(),
      clusterStore: useClusterStore(),
      projectStore: useProjectStore(),
      computeResourceStore: useComputeResourceStore(),
      currentModelAsset: null as ModelAsset | null,
      modelSpecCreate: {
        modelId: "",
        projectId: -1,
        name: "",
        namespace: "",
        clusterId: "",
        assets: {
          environment: null,
          compute: null,
        },
        specificEnv: {
          autoScaleData: {
            minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
            maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
            thresholdMetric: undefined,
            thresholdValue: undefined,
          },
        },
      } as IUIWorkloadModelCreation,
      loadingData: false as boolean,
      submitting: false as boolean,
      displayFormHint: false as boolean,
      workloadType: EWorkloadFormType.Deployment,
      nodeAffinity: [] as Array<ISelectedNodeAffinity>,
      deplymentForm: null as HTMLFormElement | null,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
    };
  },
  async created() {
    this.appStore.setPageLoading(false);
    this.modelSpecCreate.modelId = this.$route.query.modelId?.toString() || "";
    this.modelSpecCreate.clusterId = this.clusterStore.currentClusterId;
    if (!this.modelSpecCreate.modelId) {
      this.$router.push({ name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX });
      return;
    }

    await this.loadData();
  },
  mounted() {
    this.deplymentForm = this.$refs.deplymentForm as HTMLFormElement;
  },
  computed: {
    clusterUuid(): string {
      return this.clusterStore.currentCluster.uuid;
    },
    projects(): Array<IProject> {
      return this.projectStore.projects;
    },
    projectResources(): Array<IProjectResources> {
      return this.projectStore.projectResourceList;
    },
    selectedProject(): IProject | undefined {
      return this.projects.find((project: IProject) => project.id === this.modelSpecCreate.projectId);
    },
    computeResources(): Array<ComputeAsset> {
      if (!this.currentModelAsset || !this.currentModelAsset.spec.assets) return [];
      return this.computeResourceStore.computeResourcesList.filter(
        (computeAsset: ComputeAsset) => computeAsset.meta.id === this.currentModelAsset?.spec.assets?.compute,
      );
    },
    computeResourceData(): IComputeSectionData {
      return {
        computeResourceId: this.modelSpecCreate.assets.compute,
        nodeType: this.modelSpecCreate.specificEnv?.nodeType || null,
        nodePools: this.nodePoolsData,
        autoScaleData: {
          minReplicas: is.number(this.modelSpecCreate.specificEnv?.autoScaleData?.minReplicas)
            ? this.modelSpecCreate.specificEnv?.autoScaleData?.minReplicas
            : MIN_REPLICAS_DEFAULT_VALUE,
          maxReplicas: this.modelSpecCreate.specificEnv?.autoScaleData?.maxReplicas || MAX_REPLICAS_DEFAULT_VALUE,
          thresholdMetric: this.modelSpecCreate.specificEnv?.autoScaleData?.thresholdMetric,
          thresholdValue: this.modelSpecCreate.specificEnv?.autoScaleData?.thresholdValue,
        },
      };
    },
    nodePoolsData(): IComputeSectionNodePoolData {
      return {
        defaultNodePools: this.modelSpecCreate.specificEnv.nodePools || [],
        allNodePoolsOptions: this.allNodePoolsOptions,
        nodePoolsListOrigin: ENodePoolsListOrigin.Project,
      };
    },
    allNodePoolsOptions(): Array<string> {
      return (
        this.selectedProject?.nodePoolsResources.map(
          (nodePoolResource: INodePoolResources) => nodePoolResource.nodePool.name,
        ) || []
      );
    },
    displayComputeResourceSection(): boolean {
      return !this.loadingData && !!this.selectedProject;
    },
  },
  methods: {
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
      await this.projectStore.loadProjectsQuotas();
      if (this.projectResources.length === 1) {
        await this.onSelectedProject(this.projectResources[0].id);
      }
    },
    async loadData(): Promise<void> {
      try {
        this.loadingData = true;
        await this.loadComputeResources();
        await this.loadProjects();
        this.currentModelAsset = await modelAssetService.getById(this.modelSpecCreate.modelId);
        this.modelSpecCreate.assets = {
          compute: this.currentModelAsset.spec.assets?.compute || "",
          environment: this.currentModelAsset.spec.assets?.environment || "",
        };
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load data"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingData = false;
      }
    },
    async onSelectedProject(projectId: number | null): Promise<void> {
      this.modelSpecCreate.projectId = projectId || -1;
      this.modelSpecCreate.namespace = this.projectStore.getNamespaceByProjectId(this.modelSpecCreate.projectId);
      this.updateNodePools();
    },
    updateNodePools(): void {
      this.modelSpecCreate.specificEnv.nodePools = this.selectedProject?.defaultNodePools || null;
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    async loadComputeResources(): Promise<void> {
      await this.computeResourceStore.loadComputeResources();

      // set the one we have as a selected
      if (this.computeResources.length) {
        this.modelSpecCreate.assets.compute = this.computeResources[0].meta.id;
      }
    },
    onComputeResourceDataChanged(computeResourceData: IComputeSectionData): void {
      this.modelSpecCreate.assets.compute = computeResourceData.computeResourceId;
      if (this.modelSpecCreate.specificEnv) {
        this.modelSpecCreate.specificEnv.nodePools = computeResourceData.nodePools?.defaultNodePools;
        this.modelSpecCreate.specificEnv.nodeType = computeResourceData.nodeType || null;
        this.modelSpecCreate.specificEnv.autoScaleData = computeResourceData.autoScaleData;
      }
    },
    loadNodeAffinity(): void {
      if (!this.selectedProject) this.nodeAffinity = [];
      this.nodeAffinity = this.projectStore.getInteractiveNodeAffinityByProjectId(this.modelSpecCreate.projectId);
      if (this.modelSpecCreate.specificEnv && this.nodeAffinity && this.nodeAffinity.length === 1)
        this.modelSpecCreate.specificEnv.nodeType = this.nodeAffinity[0].name;
    },
    async validate(): Promise<boolean> {
      return await (this.deplymentForm as HTMLFormElement).validate();
    },
    async onDeploy(): Promise<void> {
      try {
        this.timeOutId && clearTimeout(this.timeOutId);
        this.displayFormHint = false;
        const isValid = await this.validate();
        if (!isValid) {
          this.showHint();
          return;
        }

        this.submitting = true;
        const modelInferenceCreation: ModelInferenceCreationRequest = this.getModelInferenceCreationRequest(
          this.modelSpecCreate,
        );
        await this.modelSpecStore.deployModelInference(modelInferenceCreation);
        this.$router.push({ name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_INDEX });
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("deployment"),
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.submitting = false;
      }
    },
    getModelInferenceCreationRequest(modelSpecCreate: IUIWorkloadModelCreation): ModelInferenceCreationRequest {
      const modelInferenceCreationRequest: ModelInferenceCreationRequest = omit(modelSpecCreate, [
        "assets",
        "specificEnv",
      ]);

      modelInferenceCreationRequest.specificEnv = {
        ...omit(modelSpecCreate.specificEnv, ["autoScaleData"]),
        autoScaling: {
          minReplicas: modelSpecCreate.specificEnv.autoScaleData?.minReplicas,
          maxReplicas: modelSpecCreate.specificEnv.autoScaleData?.maxReplicas,
          thresholdMetric: modelSpecCreate.specificEnv.autoScaleData?.thresholdMetric,
          thresholdValue: modelSpecCreate.specificEnv.autoScaleData?.thresholdValue,
        },
      };

      return modelInferenceCreationRequest;
    },
    onCancel(): void {
      this.$router.back();
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
  },
  provide() {
    return {
      policy: {},
    };
  },
});
</script>
