<template>
  <div class="runai-table-no-projects">
    <span v-if="hasEntityCreatePermission && hasProjectCreatePermission">
      First <span class="link" @click="createProject">create at least one project </span> so {{ entityType }} can be
      created.
    </span>
    <div class="text-center" v-else-if="hasEntityCreatePermission && !hasProjectCreatePermission">
      <div>{{ entityTextPluralCapitalized }} can only be created under a project.</div>
      <div>Contact your administrator to add a project.</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// stores
import { useAuthStore } from "@/stores/auth.store";
import type { PropType } from "vue";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { capitalizeString } from "@/utils/string.util/string.util";

export default defineComponent({
  props: {
    entityType: {
      type: String as PropType<string>,
      required: true,
    },
    hasEntityCreatePermission: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hasProjectCreatePermission: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  computed: {
    entityTextPluralCapitalized(): string {
      return capitalizeString(this.entityType + "s");
    },
  },
  methods: {
    createProject(): void {
      this.$router.push({ name: PROJECT_ROUTE_NAMES.PROJECT_NEW, query: { backToPage: this.$route.name?.toString() } });
    },
  },
});
</script>

<style lang="scss" scoped>
.runai-table-no-projects {
  .link {
    color: $primary;
    cursor: pointer;
  }
}
</style>
