import { defineStore } from "pinia";
import type { INodeType } from "@/models/node-type.model";
import { nodeTypeService } from "@/services/control-plane/node-type.service/node-type.service";
import { useClusterStore } from "@/stores/cluster.store";

export const useNodeTypeStore = defineStore("NodeType", {
  state: () => ({
    clusterStore: useClusterStore(),
  }),
  actions: {
    async getNodesTypes(): Promise<INodeType[]> {
      return await nodeTypeService.getNodeTypes(this.clusterStore.currentCluster.uuid);
    },
    async createNodeType(name: string): Promise<INodeType> {
      return await nodeTypeService.createNodeType(name, this.clusterStore.currentCluster.uuid);
    },
  },
});
