<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

// store
import { useWorkloadTemplateStore } from "@/stores/workload-template.store";

import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "@/router/workload-template.routes/workload-template.routes.names";

export default defineComponent({
  data() {
    return {
      workloadTemplateStore: useWorkloadTemplateStore(),
    };
  },
  created() {
    this.workloadTemplateStore.resetCreateWorkloadTemplateCreationData();
    if (
      this.$route.name !== WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW &&
      this.$route.query.fromCopyId === undefined
    ) {
      this.$router.replace({ name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW });
    }
  },
});
</script>
