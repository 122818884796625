import WorkspaceIndex from "@/views/workspace/workspace-index.vue";
import WorkspaceNewApp from "@/views/workspace/creation/worksapce-new-app.vue";
import WorkspaceNew from "@/views/workspace/creation/workspace-new.vue";
import WorkloadTemplateEdit from "@/views/workspace/creation/workspace-assets-edit.vue";
import WorkspaceAssetsApp from "@/views/workspace/creation/worksapce-assets-app.vue";

import { WORKSPACE_ROUTE_NAMES } from "./workspace.routes.names";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord, RouteMeta } from "vue-router";

export const workspaceRoutes: RunaiRouteRecord = {
  path: "/workspaces",
  // route level code-splitting
  // this generates a separate chunk (workspace.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../../views/workspace/workspace-app.vue"),
  children: [
    {
      path: "",
      name: WORKSPACE_ROUTE_NAMES.WORKSPACE_INDEX,
      component: WorkspaceIndex,
      meta: {
        pageTitle: "Workspaces",
        resourceType: ResourceType.Workspaces,
        minPermittedActions: [Action.Read],
        fullWidth: false,
        fullHeight: false,
        requiresAuth: true,
      } as RouteMeta,
    },
    {
      path: "new",
      component: WorkspaceNewApp, // router-view
      children: [
        {
          path: "",
          name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW,
          component: WorkspaceNew,
          meta: {
            pageTitle: "New workspace",
            fullWidth: true,
            requestToLeave: true,
            backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_INDEX,
            resourceType: ResourceType.Workspaces,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "assets",
          name: "workspace-assets-app",
          component: WorkspaceAssetsApp, // router-view
          children: [
            {
              path: "",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
              component: WorkloadTemplateEdit,
              meta: {
                pageTitle: "New workspace",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_INDEX,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "environment/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_ENVIRONMENT_NEW,
              component: () => import("@/views/environment/environment-new.vue"),
              meta: {
                pageTitle: "New environment",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "compute-resource/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_COMPUTE_RESOURCE_NEW,
              component: () => import("@/views/compute-resource/compute-resource-new.vue"),
              meta: {
                pageTitle: "New compute resource",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/hostpath/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_HOST_PATH_NEW,
              component: () => import("@/views/data-source/host-path/host-path-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/nfs/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NFS_NEW,
              component: () => import("@/views/data-source/nfs/nfs-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/s3/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_S3_NEW,
              component: () => import("@/views/data-source/s3/s3-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/git/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_GIT_NEW,
              component: () => import("@/views/data-source/git/git-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/pvc/new",
              name: WORKSPACE_ROUTE_NAMES.WORKSPACE_PVC_NEW,
              component: () => import("@/views/data-source/pvc/pvc-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
                resourceType: ResourceType.Workspaces,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
  ],
};
