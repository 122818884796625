import { ResourceType, Action } from "@/swagger-models/authorization-client";

import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "./workload-template.routes.names";

import WorkloadTemplateIndex from "@/views/workload-template/workload-template-index.vue";
import WorkloadTemplateNewApp from "@/views/workload-template/workload-template-new-app.vue";
import WorkloadTemplateNew from "@/views/workload-template/workload-template-new.vue";
import WorkloadTemplateAssetsApp from "@/views/workload-template/workload-template-assets-app.vue";
import type { RunaiRouteRecord } from "vue-router";

export const workloadTemplateRoutes: RunaiRouteRecord = {
  path: "/workload-templates",
  // route level code-splitting
  // this generates a separate chunk (workload-template.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/workload-template/workload-template-app.vue"),
  children: [
    {
      path: "",
      name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX,
      component: WorkloadTemplateIndex,
      meta: {
        pageTitle: "Templates",
        resourceType: ResourceType.Templates,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "new",
      name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW_APP,
      component: WorkloadTemplateNewApp,
      children: [
        {
          path: "",
          name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
          component: WorkloadTemplateNew,
          meta: {
            pageTitle: "New template",
            fullWidth: true,
            requestToLeave: true,
            backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX,
            resourceType: ResourceType.Templates,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "assets",
          name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_ASSETS_APP,
          component: WorkloadTemplateAssetsApp,
          children: [
            {
              path: "environment/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_ENVIRONMENT_NEW,
              component: () => import("@/views/environment/environment-new.vue"),
              meta: {
                pageTitle: "New environment",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "compute-resource/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_COMPUTE_RESOURCE_NEW,
              component: () => import("@/views/compute-resource/compute-resource-new.vue"),
              meta: {
                pageTitle: "New compute resource",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/hostpath/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_HOST_PATH_NEW,
              component: () => import("@/views/data-source/host-path/host-path-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/nfs/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NFS_NEW,
              component: () => import("@/views/data-source/nfs/nfs-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/s3/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_S3_NEW,
              component: () => import("@/views/data-source/s3/s3-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/git/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_GIT_NEW,
              component: () => import("@/views/data-source/git/git-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/pvc/new",
              name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_PVC_NEW,
              component: () => import("@/views/data-source/pvc/pvc-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_NEW,
                resourceType: ResourceType.Templates,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
  ],
};
