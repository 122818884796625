<template>
  <section class="wandb-configuration column">
    <boolean-setting :setting="setting" @update-setting="updateSetting" />
    <transition name="fade" :duration="300">
      <div v-if="setting.value && wandbHostSetting" class="column q-pb-md">
        <input-setting
          :setting="wandbHostSetting"
          :tooltip="wandbHostSetting.description"
          show-description
          @update-setting="updateWandbHost"
        />
        <div class="row justify-end q-pr-sm">
          <q-btn color="primary" label="save" @click="saveWandHost" size="sm" />
        </div>
      </div>
    </transition>
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Components
import { BooleanSetting } from "../../boolean-setting";
import { InputSetting } from "../../input-setting";
// Models
import type { Setting } from "@/swagger-models/backend-client";
import { SettingKeys } from "@/models/setting.model";
// Stores
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  name: "wandb-configuration",
  components: {
    BooleanSetting,
    InputSetting,
  },
  emits: ["update-setting"],
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      sweepConfig: null,
      wandbHostSetting: null as Setting | null,
    };
  },
  created() {
    this.wandbHostSetting =
      this.settingStore.settingList.find((setting) => setting.key === SettingKeys.WandbHost) || null;
  },
  methods: {
    updateSetting(key: string, value: string | boolean | number): void {
      this.$emit("update-setting", key, value);
    },
    updateWandbHost(key: string, value: string): void {
      if (this.wandbHostSetting) {
        this.wandbHostSetting.value = value;
      }
    },
    saveWandHost(): void {
      if (this.wandbHostSetting?.value) {
        this.updateSetting(SettingKeys.WandbHost, this.wandbHostSetting.value);
      }
    },
  },
});
</script>
