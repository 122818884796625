<template>
  <section class="server-config">
    <div class="text-subtitle1">Server configuration:</div>
    <runai-code-block :code-html="serverConfigHtml" />
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { ITenantServerConfigInfo } from "@/models/tenant.model";
// Components
import { RunaiCodeBlock } from "@/components/common/runai-code-block";

export default defineComponent({
  name: "server-config",
  components: {
    RunaiCodeBlock,
  },
  props: {
    serverConfig: {
      type: Object as PropType<ITenantServerConfigInfo>,
      required: true,
    },
  },
  computed: {
    serverConfigHtml() {
      // unfortonately we need to render the html as string since this is the only we to render the html for the <pre> tag
      // in a way that will be identical to the required config syntax. This is ugly but creates an accurate config
      // which is critical for config presentation.
      let serverConfigHtml = `
<pre class="yaml-code">
<span class="yaml-key">spec</span><span class="yaml-colon">:</span>
  <span class="yaml-key">containers</span><span class="yaml-colon">:</span>
  <span class="yaml-item">- </span><span class="yaml-key">command</span><span class="yaml-colon">:</span>
    <span class="yaml-value">...</span>`;

      serverConfigHtml += `
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-client-id=${this.serverConfig["clientId"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-issuer-url=${this.serverConfig["issuerUrl"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-username-prefix=-</span>`;

      if (this.serverConfig["groupsClaim"]) {
        serverConfigHtml += `
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-groups-claim=${this.serverConfig["groupsClaim"]}</span>
    <span class="yaml-item">- </span><span class="yaml-value">--oidc-username-claim=email</span>`;
      }

      serverConfigHtml += `</pre>`;
      return serverConfigHtml;
    },
  },
});
</script>
