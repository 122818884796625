<template>
  <span>
    <q-btn
      class="setup-dropdown"
      flat
      round
      icon="fa-regular fa-folder-gear"
      no-icon-animation
      size="12px"
      aid="setup-dropdown"
    >
      <q-menu
        :class="setupDropdownMenuLayout"
        class="setup-dropdown-menu-container"
        anchor="bottom middle"
        self="top middle"
      >
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label>Setup</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-if="!isTrial"
            :clickable="allowToOpenGeneralSettings"
            v-close-popup
            @click="itemClicked(routes.settings)"
            :class="{ disabled: !allowToOpenGeneralSettings }"
          >
            <q-item-section thumbnail class="icon-container">
              <q-icon size="14px" name="fa-regular fa-gear" />
            </q-item-section>
            <q-item-section>
              <q-item-label>General</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            :clickable="allowOpenUsers"
            v-close-popup
            @click="itemClicked(routes.users)"
            :class="{ disabled: !allowOpenUsers }"
            aid="users-btn"
          >
            <q-item-section thumbnail class="icon-container">
              <q-icon size="14px" name="fa-regular fa-user" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Users</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-if="!isTrial"
            :clickable="allowOpenApplications"
            v-close-popup
            @click="itemClicked(routes.applications)"
            :class="{ disabled: !allowOpenApplications }"
          >
            <q-item-section thumbnail class="icon-container">
              <q-icon size="14px" name="fa-regular fa-game-board-simple" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Applications</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            :clickable="allowOpenAccessRules"
            v-close-popup
            @click="itemClicked(routes.accessRules)"
            :class="{ disabled: !allowOpenAccessRules }"
          >
            <q-item-section thumbnail class="icon-container">
              <q-icon size="14px" name="fa-regular fa-chess" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Access rules & Roles</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            :clickable="allowPoliciesRules"
            v-close-popup
            @click="itemClicked(routes.policies)"
            :class="{ disabled: !allowPoliciesRules }"
            aid="policies-btn"
            v-if="showPolicies"
          >
            <q-item-section thumbnail class="icon-container">
              <q-icon size="14px" name="fa-regular fa-clipboard-list-check" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Workload policies</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <template v-if="showAccount">
          <q-separator vertical color="#212529" class="q-mt-md q-mb-md" />

          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>Account</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              v-if="!isTrial"
              :clickable="allowToOpenGeneralSettings"
              v-close-popup
              @click="itemClicked(routes.licenses)"
            >
              <q-item-section thumbnail class="icon-container">
                <q-icon size="14px" name="fa-regular fa-file-shield" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Licenses</q-item-label>
              </q-item-section>
              <q-item-section v-if="showLicenseBadge" side thumbnail class="side-icon-container">
                <q-icon size="14px" name="fa-solid fa-triangle-exclamation" />
              </q-item-section>
            </q-item>
          </q-list>
        </template>
      </q-menu>
    </q-btn>
    <q-tooltip class="q-pa-sm tooltip-dark">
      <div>Tools & Settings</div>
    </q-tooltip>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { usePermissionStore } from "@/stores/permissions.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

import { SETTINGS_ROUTE_NAMES } from "@/router/settings.routes";
import { USER_ROUTES_NAMES } from "@/router/users.routes";
import { APPLICATIONS_ROUTE_NAMES } from "@/router/applications.routes";
import { ROLE_ROUTE_NAMES } from "@/router/role.routes";
import { LICENSE_ROUTE_NAMES } from "@/router/license.routes";
import { POLICIES_ROUTE_NAMES } from "@/router/policy.routes";
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  data() {
    return {
      permissionStore: usePermissionStore(),
      clusterStore: useClusterStore(),
      authStore: useAuthStore(),
      settingStore: useSettingStore(),
      showAccount: false as boolean,
      routes: {
        settings: SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
        users: USER_ROUTES_NAMES.USER_INDEX,
        applications: APPLICATIONS_ROUTE_NAMES.APPLICATIONS_INDEX,
        accessRules: ROLE_ROUTE_NAMES.ACCESS_RULES_INDEX,
        licenses: LICENSE_ROUTE_NAMES.LICENSE_INDEX,
        policies: POLICIES_ROUTE_NAMES.POLICIES_INDEX,
      } as Record<string, string>,
    };
  },
  computed: {
    setupDropdownMenuLayout(): string {
      return this.showAccount ? "setup-grid-menu-layout" : "list-menu-layout";
    },
    isTrial(): boolean {
      return this.authStore.isTrial;
    },
    allowToOpenGeneralSettings(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Settings, Action.Update);
    },
    allowOpenUsers(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Users, Action.Read);
    },
    allowOpenApplications(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Apps, Action.Read);
    },
    allowOpenAccessRules(): boolean {
      return this.permissionStore.hasPermission(ResourceType.AccessRules, Action.Read);
    },
    allowPoliciesRules(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Policies, Action.Read);
    },
    showLicenseBadge(): boolean {
      // TODO: we should implement this when we will have tenantStore
      // ticket: RUN-9592
      return false;
    },
    showPolicies(): boolean {
      return this.clusterStore.clusterList.length > 0 && this.settingStore.isPolicyManagerEnabled;
    },
  },
  methods: {
    itemClicked(name: string): void {
      this.$router.push({ name }); // TODO: get the names from the routes
    },
  },
});
</script>

<style lang="scss">
.setup-dropdown-menu-container {
  background: $dark-gray !important;
  color: $white;

  .setup-grid-menu-layout {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    gap: 10px;
  }

  .icon-container {
    color: $white;
    padding-left: 16px;
  }

  .side-icon-container {
    color: $white;
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
}
</style>
