import { dateUtil } from "@/utils/date.util";
import { memoryFormat } from "@/utils/format.util";
import { tableNumberFormat } from "@/utils/table-format.util";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "./table.model";
import { EWorkloadStatus } from "@/common/status.constant";
import { deploymentUtil } from "@/utils/deployment.util";
import type { IToolItem } from "@/models/workspace.model";

export enum EDeploymentStatus {
  SUCCESS = "Succeeded",
  ERROR = "Failed",
  RUNNING = "Running",
  COMPLETE = "Complete",
  PROGRESSING = "Progressing",
  PENDING = "Pending",
  DELETED = "Deleted",
  SUSPENDED = "Suspended",
  SWAPPED = "Swapped",
}
export const DeploymentPhaseMap: Record<EDeploymentStatus, IStatusColOptions> = {
  [EDeploymentStatus.SUCCESS]: {
    status: EWorkloadStatus.Completed,
    displayAnimation: false,
  },
  [EDeploymentStatus.ERROR]: {
    status: EWorkloadStatus.Failed,
    displayAnimation: false,
    color: "negative",
  },
  [EDeploymentStatus.RUNNING]: {
    status: EWorkloadStatus.Active,
    displayAnimation: false,
    color: "success",
  },
  [EDeploymentStatus.COMPLETE]: {
    status: EWorkloadStatus.Active,
    displayAnimation: false,
    color: "success",
  },
  [EDeploymentStatus.PROGRESSING]: {
    status: EWorkloadStatus.Initializing,
    displayAnimation: false,
  },
  [EDeploymentStatus.PENDING]: {
    status: EWorkloadStatus.Pending,
    displayAnimation: false,
    color: "warning",
  },
  [EDeploymentStatus.DELETED]: {
    status: EWorkloadStatus.Deleted,
    displayAnimation: false,
  },
  [EDeploymentStatus.SUSPENDED]: {
    status: EWorkloadStatus.Suspended,
    displayAnimation: false,
  },
  [EDeploymentStatus.SWAPPED]: {
    status: EDeploymentStatus.SWAPPED,
    displayAnimation: false,
  },
};
interface Container {
  name: string;
  image: string;
}

interface DeploymentTemplate {
  containers: Container[];
  resourceRequest: {
    "nvidia.com/gpu": number;
  };
}

interface DeploymentCondition {
  type: string;
  status: string;
  reason?: string;
  message?: string;
}

export interface IDeploymentList {
  id: string;
  tenantId: number;
  clusterUuid: string;
  name: string;
  revisionUuid?: string;
  status: EDeploymentStatus;
  namespace: string;
  project: string;
  schedulerName: string;
  replicas: number;
  createdAt: Date;
  deletedAt?: Date;
  template: DeploymentTemplate;
  gpuRequest: number;
  cpuUsage?: number;
  memoryUsage?: number;
  gpuAllocation?: number;
  gpuMemoryAllocation?: number;
  conditions?: DeploymentCondition[];
  username: string;
  inferenceworkloadName: string;
  nodePool?: string;
  url: string;
  connections?: Array<IToolItem>;
}

function isSideCarContainer(containerName: string): boolean {
  const regex = "queue-proxy";
  const matchResult = containerName.match(regex);
  return !!matchResult;
}

export const allDeploymentColumnsMap: Record<string, ITableColumn> = {
  deploymentId: {
    name: "deploymentId",
    label: "Deployment ID",
    field: (row: IDeploymentList) => row.id,
    sortable: true,
    align: "left",
  },
  revisionUuid: {
    name: "revisionUuid",
    label: "Revision ID",
    field: (row: IDeploymentList) => row.revisionUuid,
    sortable: true,
    align: "left",
  },
  name: {
    name: "name",
    label: "Deployment Name",
    field: (row: IDeploymentList) => row.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: IDeploymentList) => row.status,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (status: EDeploymentStatus): IStatusColOptions | string =>
      deploymentUtil.getDeploymentStatusColOptions(status),
  },
  project: {
    name: "project",
    label: "Project",
    field: (row: IDeploymentList) => row.project,
    sortable: true,
    align: "left",
  },
  nodePool: {
    name: "nodePool",
    label: "Node pool",
    field: (row: IDeploymentList) => row.nodePool,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "createdAt",
    label: "Creation time",
    field: (row: IDeploymentList) => row.createdAt,
    sortable: true,
    align: "left",
    format: (val: number | undefined) => {
      if (!val) return "";
      return dateUtil.dateAndTimeFormat(new Date(val));
    },
  },
  image: {
    name: "image",
    label: "Image",
    field: (row: IDeploymentList) => row.template.containers,
    sortable: true,
    align: "left",
    format: (containers: Array<Container>) => {
      return containers
        ?.filter((c: Container) => !isSideCarContainer(c.name))
        .map((c: Container) => c.image)
        .join(", ");
    },
  },
  url: {
    name: "url",
    label: "URL",
    field: (row: IDeploymentList) => row.url,
    sortable: true,
    align: "left",
  },
  username: {
    name: "username",
    label: "Username",
    field: (row: IDeploymentList) => row.username,
    sortable: true,
    align: "left",
  },
  replicas: {
    name: "replicas",
    label: "Replicas",
    field: (row: IDeploymentList) => row.replicas,
    sortable: true,
    align: "left",
  },
  gpuRequestPerReplica: {
    name: "gpuRequestPerReplica",
    label: "Requested GPUs per Replica",
    field: (row: IDeploymentList) => row.template.resourceRequest?.["nvidia.com/gpu"] || 0,
    sortable: true,
    align: "left",
  },
  cpuUsage: {
    name: "cpuUsage",
    label: "Used CPU",
    field: (row: IDeploymentList) => row.cpuUsage,
    sortable: true,
    align: "left",
    format: tableNumberFormat(),
  },
  memoryUsage: {
    name: "memoryUsage",
    label: "Used Memory",
    field: (row: IDeploymentList) => row.memoryUsage,
    sortable: true,
    align: "left",
    format: memoryFormat,
  },
  gpuAllocation: {
    name: "gpuAllocation",
    label: "Allocated GPUs",
    field: (row: IDeploymentList) => row.gpuAllocation,
    sortable: true,
    align: "left",
  },
  gpuMemoryAllocation: {
    name: "gpuMemoryAllocation",
    label: "Allocated GPU Memory",
    field: (row: IDeploymentList) => row.gpuMemoryAllocation,
    sortable: true,
    align: "left",
    format: memoryFormat,
  },
};

export const allDeploymentColumns: Array<ITableColumn> = [
  allDeploymentColumnsMap.deploymentId,
  allDeploymentColumnsMap.revisionUuid,
  allDeploymentColumnsMap.name,
  allDeploymentColumnsMap.status,
  allDeploymentColumnsMap.project,
  allDeploymentColumnsMap.nodePool,
  allDeploymentColumnsMap.createdAt,
  allDeploymentColumnsMap.image,
  allDeploymentColumnsMap.url,
  allDeploymentColumnsMap.username,
  allDeploymentColumnsMap.replicas,
  allDeploymentColumnsMap.gpuRequestPerReplica,
  allDeploymentColumnsMap.cpuUsage,
  allDeploymentColumnsMap.memoryUsage,
  allDeploymentColumnsMap.gpuAllocation,
  allDeploymentColumnsMap.gpuMemoryAllocation,
];

export const deploymentIndexColumns: Array<ITableColumn> = [
  { ...allDeploymentColumnsMap.name, display: true, mandatory: true },
  { ...allDeploymentColumnsMap.deploymentId, display: false },
  { ...allDeploymentColumnsMap.revisionUuid, display: false },
  { ...allDeploymentColumnsMap.status, display: true },
  { ...allDeploymentColumnsMap.project, display: false },
  { ...allDeploymentColumnsMap.nodePool, display: false },
  { ...allDeploymentColumnsMap.createdAt, display: true },
  { ...allDeploymentColumnsMap.image, display: true },
  { ...allDeploymentColumnsMap.url, display: false },
  { ...allDeploymentColumnsMap.username, display: false },
  { ...allDeploymentColumnsMap.replicas, display: true },
  { ...allDeploymentColumnsMap.gpuRequestPerReplica, display: true },
  { ...allDeploymentColumnsMap.cpuUsage, display: true },
  { ...allDeploymentColumnsMap.memoryUsage, display: true },
  { ...allDeploymentColumnsMap.gpuAllocation, display: true },
  { ...allDeploymentColumnsMap.gpuMemoryAllocation, display: true },
];
