import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export enum DATA_SOURCE_ROUTE_NAMES {
  DATA_SOURCE_INDEX = "data-source-index",
  DATA_SOURCE_NEW = "data-source-new",
  DATA_SOURCE_ASSETS_EDIT = "data-source-assets-edit",
  DATA_SOURCE_ENVIRONMENT_NEW = "data-source-environment-new",
  DATA_SOURCE_HOST_PATH_NEW = "data-source-host-path-new",
  DATA_SOURCE_NFS_NEW = "data-source-nfs-new",
  DATA_SOURCE_S3_NEW = "datasource-s3-new",
  DATA_SOURCE_GIT_NEW = "data-source-git-new",
  DATA_SOURCE_PVC_NEW = "data-source-pvc-new",
}

export const dataSourceRoutes: RunaiRouteRecord = {
  path: "/data-sources",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../views/data-source/data-source-app.vue"),
  children: [
    {
      path: "",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
      component: () => import("../views/data-source/data-source-index.vue"),
      meta: {
        pageTitle: "Data sources",
        resourceType: [
          ResourceType.NfsAssets,
          ResourceType.S3Assets,
          ResourceType.GitAssets,
          ResourceType.PvcAssets,
          ResourceType.HostPathAssets,
        ],
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "hostpath/new",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_HOST_PATH_NEW,
      component: () => import("../views/data-source/host-path/host-path-new.vue"),
      meta: {
        pageTitle: "New data source",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
        resourceType: ResourceType.HostPathAssets,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "s3/new",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_S3_NEW,
      component: () => import("../views/data-source/s3/s3-new.vue"),
      meta: {
        pageTitle: "New data source",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
        resourceType: ResourceType.S3Assets,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "git/new",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_GIT_NEW,
      component: () => import("../views/data-source/git/git-new.vue"),
      meta: {
        pageTitle: "New data source",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
        resourceType: ResourceType.GitAssets,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "nfs/new",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_NFS_NEW,
      component: () => import("../views/data-source/nfs/nfs-new.vue"),
      meta: {
        pageTitle: "New data source",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
        resourceType: ResourceType.NfsAssets,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "pvc/new",
      name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_PVC_NEW,
      component: () => import("../views/data-source/pvc/pvc-new.vue"),
      meta: {
        pageTitle: "New data source",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
        resourceType: ResourceType.PvcAssets,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
  ],
};
