import type { IStatusColOptions } from "@/models/table.model";
import type { IProject, IProjectCreate, IProjectStatus } from "@/models/project.model";
import { EProjectPhase, IConditionStatus, ProjectPhaseMap, ProjectStatusMessageMap } from "@/models/project.model";
import type { INodePoolResources } from "@/models/project.model";
import {
  EMPTY_CPU_RESOURCE_VALUE,
  OVER_QUOTA_ENABLED_VALUE,
  EOverQuotaPriority,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
} from "@/models/resource.model";
import { tableUtil } from "@/utils/table.util";
export const projectUtil = {
  getStatusColOptions,
  resetCpuResources,
  updateMaxAllowedOverQuota,
};

function getStatusColOptions(phase: EProjectPhase, status?: IProjectStatus): IStatusColOptions {
  const statusOptions: IStatusColOptions = ProjectPhaseMap[phase];
  return tableUtil.getStatusColOptions(statusOptions, _getStatusMessage(phase, status));
}

function _getStatusMessage(phase: string, status?: IProjectStatus): string {
  if (phase === EProjectPhase.Ready) {
    return "";
  }
  if (status && status.conditions) {
    for (const condition of status.conditions) {
      if (condition.status === IConditionStatus.False && condition.reason) {
        const message = ProjectStatusMessageMap[condition.reason];

        if (message) {
          return message;
        }
      }
    }
  }

  return "";
}

//in case of cpu feature flag is off need to reset cpu values
function resetCpuResources(project: IProject | IProjectCreate): void {
  project.resources.cpu = { ...EMPTY_CPU_RESOURCE_VALUE };
  project.resources.memory = { ...EMPTY_CPU_RESOURCE_VALUE };
  project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    nodePool.cpu = { ...EMPTY_CPU_RESOURCE_VALUE };
    nodePool.memory = { ...EMPTY_CPU_RESOURCE_VALUE };
  });
}

// The UI inputs do not include maxAllowed, but its value depends on overQuotaWeight. Therefore, update it with the relevant value.
function updateMaxAllowedOverQuota(project: IProject | IProjectCreate, isOverQuotaPriorityEnabled: boolean): void {
  project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    const isOverQuotaOn = isOverQuotaPriorityEnabled
      ? nodePool.gpu.overQuotaWeight !== EOverQuotaPriority.None
      : nodePool.gpu.overQuotaWeight === OVER_QUOTA_ENABLED_VALUE;

    nodePool.gpu.maxAllowed = isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : nodePool.gpu.deserved;
    nodePool.cpu.maxAllowed = isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : nodePool.cpu.deserved;
    nodePool.memory.maxAllowed = isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : nodePool.memory.deserved;
  });
}
