/* tslint:disable */
/* eslint-disable */
/**
 * ClusterService
 * An API for managing RunAi cluster objects (nodes).
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ClusterCreationRequest
 */
export interface ClusterCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface ClusterDependenciesStatus
 */
export interface ClusterDependenciesStatus {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterDependencyStatus
 */
export interface ClusterDependencyStatus {
    /**
     * whether the dependency is available
     * @type {boolean}
     * @memberof ClusterDependencyStatus
     */
    'available': boolean;
    /**
     * the reason for the dependency status
     * @type {string}
     * @memberof ClusterDependencyStatus
     */
    'reason'?: string;
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependencyStatus
     */
    'components'?: { [key: string]: ClusterDependencyStatus; };
}
/**
 * The cluster\'s displayed status.
 * @export
 * @interface ClusterDisplayedStatus
 */
export interface ClusterDisplayedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterDisplayedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterDisplayedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterDisplayedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatus
     */
    'state'?: ClusterDisplayedStatusStateEnum;
}

export const ClusterDisplayedStatusStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusStateEnum = typeof ClusterDisplayedStatusStateEnum[keyof typeof ClusterDisplayedStatusStateEnum];

/**
 * 
 * @export
 * @interface ClusterDisplayedStatusAllOf
 */
export interface ClusterDisplayedStatusAllOf {
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatusAllOf
     */
    'state'?: ClusterDisplayedStatusAllOfStateEnum;
}

export const ClusterDisplayedStatusAllOfStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusAllOfStateEnum = typeof ClusterDisplayedStatusAllOfStateEnum[keyof typeof ClusterDisplayedStatusAllOfStateEnum];

/**
 * 
 * @export
 * @interface ClusterInfoSyncRequest
 */
export interface ClusterInfoSyncRequest {
    /**
     * the cluster domain
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'domain': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'version': string;
    /**
     * 
     * @type {ClusterInfoSyncRequestDependencies}
     * @memberof ClusterInfoSyncRequest
     * @deprecated
     */
    'dependencies'?: ClusterInfoSyncRequestDependencies | null;
    /**
     * 
     * @type {ClusterInfoSyncRequestStatus}
     * @memberof ClusterInfoSyncRequest
     */
    'status'?: ClusterInfoSyncRequestStatus | null;
}
/**
 * Deprecated. Use status.dependencies instead.
 * @export
 * @interface ClusterInfoSyncRequestDependencies
 */
export interface ClusterInfoSyncRequestDependencies {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterInfoSyncRequestStatus
 */
export interface ClusterInfoSyncRequestStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * 
 * @export
 * @interface ClusterInstallationResponse
 */
export interface ClusterInstallationResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ClusterLivenessSyncRequest
 */
export interface ClusterLivenessSyncRequest {
    /**
     * 
     * @type {number}
     * @memberof ClusterLivenessSyncRequest
     */
    'timeTag': number;
}
/**
 * the cluster status reported by the cluster
 * @export
 * @interface ClusterReportedStatus
 */
export interface ClusterReportedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterReportedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterReportedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterReportedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * Condition contains details for one aspect of the current state of this API Resource
 * @export
 * @interface ClusterReportedStatusConditionsInner
 */
export interface ClusterReportedStatusConditionsInner {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'message': string;
    /**
     * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
     * @type {number}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'observedGeneration'?: number;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'status': ClusterReportedStatusConditionsInnerStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'type': string;
}

export const ClusterReportedStatusConditionsInnerStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type ClusterReportedStatusConditionsInnerStatusEnum = typeof ClusterReportedStatusConditionsInnerStatusEnum[keyof typeof ClusterReportedStatusConditionsInnerStatusEnum];

/**
 * Status specifies the status of an operand. And operand manages resources, some of which are not immediately available and need their status monitored, such as Deployments, Daemonsets, Ingressess etc. Some resources are immediately available and should not be monitored once accepted by the cluster, such as Services, ConfigMaps, Secrets etc. If all resources of an operand are ready, a Status with Ready set to \'true\' and a nil \'Reasons\' slice should be returned, otherwise, a Status with \'Reasons\' slice containig the reason why the Operand is not ready (Deployment pods are not ready for example.)
 * @export
 * @interface ClusterReportedStatusOperandsValue
 */
export interface ClusterReportedStatusOperandsValue {
    /**
     * LastTransitionTime specifies the last time the operand readiness changed
     * @type {string}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'lastTransitionTime'?: string;
    /**
     * Ready specifies if the operand is ready or not
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'ready': boolean;
    /**
     * Reasons specifies the reasons why the operand is not ready
     * @type {Array<string>}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'reasons'?: Array<string>;
    /**
     * UnreadyThresholdCrossed specifies if the operand has been unready for longer than the threshold
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'unreadyThresholdCrossed'?: boolean;
}
/**
 * 
 * @export
 * @interface ClusterUpdateRequest
 */
export interface ClusterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlRequest
 */
export interface ClusterUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlRequest
     */
    'targetHost': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlResponse
 */
export interface ClusterUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface DisplayedCluster
 */
export interface DisplayedCluster {
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'uuid': string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedCluster
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'domain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'version'?: string | null;
    /**
     * 
     * @type {ClusterDisplayedStatus}
     * @memberof DisplayedCluster
     */
    'status'?: ClusterDisplayedStatus | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'lastLiveness'?: string | null;
}
/**
 * 
 * @export
 * @interface GetClusterName200Response
 */
export interface GetClusterName200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClusterName200Response
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GpuInfo
 */
export interface GpuInfo {
    /**
     * 
     * @type {string}
     * @memberof GpuInfo
     */
    'gpuType': string;
    /**
     * 
     * @type {number}
     * @memberof GpuInfo
     */
    'gpuCount': number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof Node
     */
    'status': NodeStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof Node
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof Node
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof Node
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof Node
     */
    'gpuInfo'?: GpuInfo | null;
    /**
     * The name of the node
     * @type {string}
     * @memberof Node
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'updatedAt': string;
}

export const NodeStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeStatusEnum = typeof NodeStatusEnum[keyof typeof NodeStatusEnum];

/**
 * 
 * @export
 * @interface NodeAdditionalFields
 */
export interface NodeAdditionalFields {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeAdditionalFields
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NodeAdditionalReadFields
 */
export interface NodeAdditionalReadFields {
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NodeForSync
 */
export interface NodeForSync {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeForSync
     */
    'name': string;
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSync
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeForSyncAllOf
 */
export interface NodeForSyncAllOf {
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSyncAllOf
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeInfo
 */
export interface NodeInfo {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof NodeInfo
     */
    'status': NodeInfoStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof NodeInfo
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof NodeInfo
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof NodeInfo
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof NodeInfo
     */
    'gpuInfo'?: GpuInfo | null;
}

export const NodeInfoStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeInfoStatusEnum = typeof NodeInfoStatusEnum[keyof typeof NodeInfoStatusEnum];

/**
 * 
 * @export
 * @interface NodeStatusConditionDetails
 */
export interface NodeStatusConditionDetails {
    /**
     * Type of node condition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'type': NodeStatusConditionDetailsTypeEnum;
    /**
     * (brief) reason for the condition\'s last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'reason': string;
    /**
     * Human readable message indicating details about last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'message'?: string;
}

export const NodeStatusConditionDetailsTypeEnum = {
    NodeNotReady: 'NodeNotReady',
    MemoryPressure: 'MemoryPressure',
    DiskPressure: 'DiskPressure',
    PidPressure: 'PIDPressure',
    NetworkUnavailable: 'NetworkUnavailable',
    SchedulingDisabled: 'SchedulingDisabled',
    UndrainedMigratedNode: 'UndrainedMigratedNode',
    MissingNvidiaContainerToolkit: 'MissingNvidiaContainerToolkit',
    MissingNvidiaDcgmExporter: 'MissingNvidiaDcgmExporter'
} as const;

export type NodeStatusConditionDetailsTypeEnum = typeof NodeStatusConditionDetailsTypeEnum[keyof typeof NodeStatusConditionDetailsTypeEnum];

/**
 * 
 * @export
 * @interface NodeTaint
 */
export interface NodeTaint {
    /**
     * The taint key to be applied to a node.
     * @type {string}
     * @memberof NodeTaint
     */
    'key': string;
    /**
     * The taint value corresponding to the taint key.
     * @type {string}
     * @memberof NodeTaint
     */
    'value'?: string;
    /**
     * The effect of the taint on pods that do not tolerate the taint.
     * @type {string}
     * @memberof NodeTaint
     */
    'effect': NodeTaintEffectEnum;
}

export const NodeTaintEffectEnum = {
    NoSchedule: 'NoSchedule',
    PreferNoSchedule: 'PreferNoSchedule',
    NoExecute: 'NoExecute'
} as const;

export type NodeTaintEffectEnum = typeof NodeTaintEffectEnum[keyof typeof NodeTaintEffectEnum];

/**
 * 
 * @export
 * @interface Nodes
 */
export interface Nodes {
    /**
     * 
     * @type {Array<Node>}
     * @memberof Nodes
     */
    'nodes': Array<Node>;
}
/**
 * 
 * @export
 * @interface NodesSyncRequest
 */
export interface NodesSyncRequest {
    /**
     * 
     * @type {Array<NodeForSync>}
     * @memberof NodesSyncRequest
     */
    'nodesForSync': Array<NodeForSync>;
    /**
     * The first node to sync. Will delete all nodes that don\'t exist in the request, after this node. If empty, all nodes before the first one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'from'?: string;
    /**
     * The last node to sync. Will delete all nodes that don\'t exist in the request, before this node. If empty, all nodes after the last one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'to'?: string;
}

/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster: async (clusterCreationRequest: ClusterCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterCreationRequest' is not null or undefined
            assertParamExists('createCluster', 'clusterCreationRequest', clusterCreationRequest)
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster: async (csClusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('deleteCluster', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors: async (csClusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('getClusterAncestors', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/internal/clusters/{csClusterUuid}/ancestors`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid: async (csClusterUuid: string, verbosity?: 'metadata' | 'full', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('getClusterByUuid', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName: async (csClusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('getClusterName', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/internal/clusters/{csClusterUuid}/name`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters: async (verbosity?: 'metadata' | 'full', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster: async (csClusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('updateCluster', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'clusterUpdateRequest' is not null or undefined
            assertParamExists('updateCluster', 'clusterUpdateRequest', clusterUpdateRequest)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo: async (csClusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('updateClusterInfo', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'clusterInfoSyncRequest' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterInfoSyncRequest', clusterInfoSyncRequest)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/cluster-info`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfoSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness: async (csClusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('updateClusterLiveness', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'clusterLivenessSyncRequest' is not null or undefined
            assertParamExists('updateClusterLiveness', 'clusterLivenessSyncRequest', clusterLivenessSyncRequest)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/liveness`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterLivenessSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCluster(clusterCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCluster(csClusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCluster(csClusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterAncestors(csClusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterAncestors(csClusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterByUuid(csClusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterByUuid(csClusterUuid, verbosity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterName(csClusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClusterName200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterName(csClusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusters(verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisplayedCluster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusters(verbosity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCluster(csClusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCluster(csClusterUuid, clusterUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterInfo(csClusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterInfo(csClusterUuid, clusterInfoSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterLiveness(csClusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterLiveness(csClusterUuid, clusterLivenessSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClustersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(clusterCreationRequest: ClusterCreationRequest, options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.createCluster(clusterCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(csClusterUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCluster(csClusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors(csClusterUuid: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getClusterAncestors(csClusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid(csClusterUuid: string, verbosity?: 'metadata' | 'full', options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.getClusterByUuid(csClusterUuid, verbosity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName(csClusterUuid: string, options?: any): AxiosPromise<GetClusterName200Response> {
            return localVarFp.getClusterName(csClusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters(verbosity?: 'metadata' | 'full', options?: any): AxiosPromise<Array<DisplayedCluster>> {
            return localVarFp.getClusters(verbosity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster(csClusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCluster(csClusterUuid, clusterUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo(csClusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterInfo(csClusterUuid, clusterInfoSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness(csClusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterLiveness(csClusterUuid, clusterLivenessSyncRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * 
     * @summary Create cluster
     * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).createCluster(clusterCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the cluster
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public deleteCluster(csClusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).deleteCluster(csClusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster ancestors.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterAncestors(csClusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterAncestors(csClusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get cluster by uuid
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterByUuid(csClusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterByUuid(csClusterUuid, verbosity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster name.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterName(csClusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterName(csClusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List clusters.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusters(verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusters(verbosity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a cluster by id
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateCluster(csClusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateCluster(csClusterUuid, clusterUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cluster-info (domain, version and status) for a specific cluster
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterInfo(csClusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterInfo(csClusterUuid, clusterInfoSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update liveness status for a specific cluster
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterLiveness(csClusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterLiveness(csClusterUuid, clusterLivenessSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstallationApi - axios parameter creator
 * @export
 */
export const InstallationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl: async (csClusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('clusterInstallationUrl', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/installation-url`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl: async (csClusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('mapClusterUrl', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'clusterUrlRequest' is not null or undefined
            assertParamExists('mapClusterUrl', 'clusterUrlRequest', clusterUrlRequest)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/domain`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationApi - functional programming interface
 * @export
 */
export const InstallationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterInstallationUrl(csClusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInstallationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterInstallationUrl(csClusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapClusterUrl(csClusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapClusterUrl(csClusterUuid, clusterUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstallationApi - factory interface
 * @export
 */
export const InstallationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl(csClusterUuid: string, options?: any): AxiosPromise<ClusterInstallationResponse> {
            return localVarFp.clusterInstallationUrl(csClusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl(csClusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: any): AxiosPromise<ClusterUrlResponse> {
            return localVarFp.mapClusterUrl(csClusterUuid, clusterUrlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationApi - object-oriented interface
 * @export
 * @class InstallationApi
 * @extends {BaseAPI}
 */
export class InstallationApi extends BaseAPI {
    /**
     * 
     * @summary Get cluster installation url
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public clusterInstallationUrl(csClusterUuid: string, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).clusterInstallationUrl(csClusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map target url to cluster url
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {ClusterUrlRequest} clusterUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public mapClusterUrl(csClusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).mapClusterUrl(csClusterUuid, clusterUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodesApi - axios parameter creator
 * @export
 */
export const NodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode: async (csClusterUuid: string, nodeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('deleteNode', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('deleteNode', 'nodeName', nodeName)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/nodes/{nodeName}`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes: async (csClusterUuid: string, nodeName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('getNodes', 'csClusterUuid', csClusterUuid)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/nodes`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes: async (csClusterUuid: string, nodesSyncRequest: NodesSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('syncNodes', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'nodesSyncRequest' is not null or undefined
            assertParamExists('syncNodes', 'nodesSyncRequest', nodesSyncRequest)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/nodes/sync`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodesSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode: async (csClusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'csClusterUuid' is not null or undefined
            assertParamExists('upsertNode', 'csClusterUuid', csClusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('upsertNode', 'nodeName', nodeName)
            // verify required parameter 'nodeInfo' is not null or undefined
            assertParamExists('upsertNode', 'nodeInfo', nodeInfo)
            const localVarPath = `/api/v1/clusters/{csClusterUuid}/nodes/{nodeName}`
                .replace(`{${"csClusterUuid"}}`, encodeURIComponent(String(csClusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodesApi - functional programming interface
 * @export
 */
export const NodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNode(csClusterUuid: string, nodeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNode(csClusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodes(csClusterUuid: string, nodeName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodes(csClusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncNodes(csClusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncNodes(csClusterUuid, nodesSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertNode(csClusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertNode(csClusterUuid, nodeName, nodeInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodesApi - factory interface
 * @export
 */
export const NodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode(csClusterUuid: string, nodeName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNode(csClusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes(csClusterUuid: string, nodeName?: string, options?: any): AxiosPromise<Nodes> {
            return localVarFp.getNodes(csClusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes(csClusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncNodes(csClusterUuid, nodesSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} csClusterUuid The clusterId of the current request.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode(csClusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: any): AxiosPromise<void> {
            return localVarFp.upsertNode(csClusterUuid, nodeName, nodeInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodesApi - object-oriented interface
 * @export
 * @class NodesApi
 * @extends {BaseAPI}
 */
export class NodesApi extends BaseAPI {
    /**
     * 
     * @summary Delete the node as is does not exist in the cluster anymore.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {string} nodeName The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public deleteNode(csClusterUuid: string, nodeName: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).deleteNode(csClusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List nodes.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {string} [nodeName] The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodes(csClusterUuid: string, nodeName?: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodes(csClusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync the nodes in the cluster.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public syncNodes(csClusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).syncNodes(csClusterUuid, nodesSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert the node info from the cluster.
     * @param {string} csClusterUuid The clusterId of the current request.
     * @param {string} nodeName The node name.
     * @param {NodeInfo} nodeInfo The node info in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public upsertNode(csClusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).upsertNode(csClusterUuid, nodeName, nodeInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


