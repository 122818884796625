<template>
  <div class="row items-center no-pool-summary">
    <div class="col-1">
      <div class="icon-wrapper">
        <runai-svg-icon :name="iconName" size="22"></runai-svg-icon>
      </div>
    </div>
    <div class="col-11">
      <div class="row border-bottom q-pb-sm items-center text-subtitle1 text-weight-medium">
        <div class="col"></div>
        <div class="col" :class="isCpuEnabled ? 'q-mr-md' : 'q-mr-xl'">{{ header }}</div>
        <div class="col">{{ totalGpus }}</div>
        <template v-if="isCpuEnabled">
          <div class="col">{{ totalCpus }}</div>
          <div class="col">{{ totalMemory }}</div>
        </template>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
//cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

//models
import type { EQuotaEntity } from "@/models/resource.model";
import { EResourceType } from "@/models/resource.model";
//services
import { resourceUtil } from "@/utils/resource.util";
import type { INodePoolResources } from "@/models/project.model";

//stores
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    entity: {
      type: String as PropType<EQuotaEntity>,
      required: true,
    },
    nodePoolsResources: {
      type: Array as PropType<Array<INodePoolResources>>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    totalGpus(): string | number {
      return resourceUtil.getResourcesDisplayValue(this.nodePoolsResources, EResourceType.GPU);
    },
    totalCpus(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.nodePoolsResources, EResourceType.CPU);
    },
    totalMemory(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.nodePoolsResources, EResourceType.MEMORY);
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    header(): string {
      return this.entity === "project" ? "Project total" : "Department total";
    },
    iconName(): string {
      return this.entity === "project" ? "project-icon" : "departments-icon";
    },
  },
});
</script>

<style lang="scss" scoped>
.no-pool-summary {
  min-height: 55px;
}
.total-display {
  text-align: center;
  font-weight: 500;
}
.border-bottom {
  border-bottom: 1px solid $black-12;
}
.icon-wrapper {
  position: relative;
  background-color: $body-background-color;
  border-radius: 5px;
  width: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
</style>
