<template>
  <q-menu fit>
    <q-list>
      <q-item
        clickable
        v-close-popup
        v-for="opt in options"
        :key="opt.title"
        @click="$emit('data-source-selected', opt.title)"
        :aid="opt.title + '-btn'"
      >
        <q-item-section class="option">
          <runai-svg-icon v-if="opt.icon" :name="opt.icon" size="25px" />{{ opt.title }}
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { DATA_SOURCES_TYPES_TO_NAMES } from "@/models/data-source.model";
import { DATA_SOURCE_ICONS_NAMES } from "@/common/icons.constant";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  data() {
    return {
      options: [
        {
          title: DATA_SOURCES_TYPES_TO_NAMES.NFS,
          icon: DATA_SOURCE_ICONS_NAMES.nfs,
        },
        {
          title: DATA_SOURCES_TYPES_TO_NAMES.PVC,
          icon: DATA_SOURCE_ICONS_NAMES.pvc,
        },
        {
          title: DATA_SOURCES_TYPES_TO_NAMES.S3,
          icon: DATA_SOURCE_ICONS_NAMES.s3,
        },
        {
          title: DATA_SOURCES_TYPES_TO_NAMES.GIT,
          icon: DATA_SOURCE_ICONS_NAMES.git,
        },
        {
          title: DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH,
          icon: DATA_SOURCE_ICONS_NAMES.hostPath,
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.option {
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}
</style>
