// Object to store interval references
const intervalRegistry: { [key: string]: number } = {};

export const intervalUtil = { startInterval, stopInterval, displayActiveIntervals };

// Function to start an interval
function startInterval(label: string, callback: () => void, delay: number): number {
  const intervalId = setInterval(callback, delay);
  // Store the interval ID in the registry
  stopInterval(label); // in case we have already interval we want to clear and remove him
  intervalRegistry[label] = Number(intervalId);
  return intervalRegistry[label];
}

// Function to stop an interval by its label
function stopInterval(label: string): void {
  if (intervalRegistry[label]) {
    clearInterval(intervalRegistry[label]);
    delete intervalRegistry[label];
  }
}

// Function to display all active interval labels
function displayActiveIntervals(): void {
  const activeLabels = Object.keys(intervalRegistry);
  console.log("Active Interval Labels:", activeLabels);
}
