import { defineStore } from "pinia";
import { appService } from "@/services/control-plane/app.service/app.service";

import { configService } from "@/services/control-plane/config.service/config.service";
import type { IConfig } from "@/models/config.model";
import { ESidebarState } from "@/models/global.model";

import { useAuthStore } from "./auth.store";
import { urlService } from "@/services/url.service/url.service";

import { storageUtil } from "@/utils/storage.util";
import { WORKLOADS_TOGGLE, NEW_DEPLOYMENTS_PAGE } from "@/common/storage.constant";

export const useAppStore = defineStore("App", {
  state: () => ({
    appVersion: "" as string,
    config: {} as IConfig,
    backendBaseUrl: urlService.getBackendBaseUrl(),
    pageLoading: false as boolean,
    fallback: false as boolean,
    parentAppOrigin: "" as string,
    lastBackPath: "" as string,
    appLoaded: false as boolean,
    sidebarState: ESidebarState.Mini as ESidebarState,
    isNewWorkloadsPageOn: !!storageUtil.get<boolean>(WORKLOADS_TOGGLE),
    isNewDeploymentsPageOn: !!storageUtil.get<boolean>(NEW_DEPLOYMENTS_PAGE),
  }),
  getters: {
    version(): string {
      return this.appVersion;
    },
    supportLink(): string {
      const authStore = useAuthStore();
      return `https://runai.secure.force.com/casesupport/CreateCaseForm?email=${authStore.currentUser.email}`;
    },
    isSaas(): boolean {
      return configService.isSaas(window.location.hostname);
    },
    isStaging(): boolean {
      return !!this.config.isStaging;
    },
    tenantDomain(): string {
      return this.config.tenantName?.concat(".", window.location.hostname).replace("app.run.ai", "run.ai");
    },
    isProductionEnv(): boolean {
      return !["localhost", "test.run.ai"].some((h) => window.location.hostname.startsWith(h));
    },
    isSelfHosted(): boolean {
      return this.config.selfHosted;
    },
    isPageLoading(): boolean {
      return this.pageLoading;
    },
    isFallback(): boolean {
      return this.fallback;
    },
    tenantName(): string {
      return this.config.tenantName;
    },
  },
  actions: {
    async loadAppData(): Promise<void> {
      try {
        const res = await Promise.all([configService.get(this.backendBaseUrl), appService.getAppVersion()]);
        this.config = res[0];
        this.appVersion = res[1];
      } catch (error: unknown) {
        console.error("Failed to load init data: ", error);
        console.log("re-trying to load init data...");
        const res = await Promise.all([configService.get(this.backendBaseUrl), appService.getAppVersion()]);
        this.config = res[0];
        this.appVersion = res[1];
      }
    },
    setPageLoading(pageLoading: boolean): void {
      this.pageLoading = pageLoading;
    },
    setFallback(fallbackStatus: boolean): void {
      this.fallback = fallbackStatus;
    },
    setLastBackPath(path: string): void {
      this.lastBackPath = path;
    },

    setAppLoaded(appLoaded: boolean): void {
      this.appLoaded = appLoaded;
    },
    setSidebarState(sidebarState: ESidebarState): void {
      this.sidebarState = sidebarState;
    },
    setNewWorkloadsPageState(isNewWorkloadsPageOn: boolean): void {
      this.isNewWorkloadsPageOn = isNewWorkloadsPageOn;
      storageUtil.save<boolean>(WORKLOADS_TOGGLE, this.isNewWorkloadsPageOn);
    },
    setNewDeploymentsPageState(isNewDeploymentsPageOn: boolean): void {
      this.isNewDeploymentsPageOn = isNewDeploymentsPageOn;
      storageUtil.save<boolean>(NEW_DEPLOYMENTS_PAGE, this.isNewDeploymentsPageOn);
    },
  },
});
