/* eslint-disable  @typescript-eslint/no-explicit-any */
export const policyModelUtil = {
  policyRulesToPreviewString,
  policyDefaultToPreviewString,
};

function policyRulesToPreviewString(val: any): string {
  if (!val) return "";
  return Object.entries(val).reduce((acc, [key, value]) => {
    if (!Array.isArray(value)) {
      acc += `• ${key} = ${value} <br />`;
    } else {
      acc += `• ${key} = `;
      acc += `${value.map((item) => item.value || item).join(", ")}<br />`;
    }
    return acc;
  }, "");
}

function policyDefaultToPreviewString(val: any[] | string | number | boolean): string {
  if (!val) return "";
  if (!Array.isArray(val)) return val.toString();
  return val.reduce((acc, v, idx) => {
    acc += `•`;
    if (_isObject(v)) {
      acc += _objectToPreviewString(v);
      if (idx !== val.length - 1) acc += "<br />";
    } else {
      acc += ` ${v} <br />`;
    }
    return acc;
  }, "");
}

function _objectToPreviewString(val: object, outterKey?: string): string {
  return Object.entries(val).reduce((acc, [key, value], idx) => {
    const combinedKey = outterKey ? `${outterKey}.${key}` : key;
    if (_isObject(value)) {
      acc += _objectToPreviewString(value, combinedKey);
    } else {
      const isFirstRow = !outterKey && idx === 0;
      acc += `${isFirstRow ? "" : "&nbsp;"} ${combinedKey} = ${value} <br />`;
    }
    return acc;
  }, "");
}

function _isObject(value: any): boolean {
  return !!value && typeof value === "object" && !Array.isArray(value);
}
