<template>
  <section class="inner-table table-body">
    <div class="table-tab">
      <runai-svg-icon name="node-pool" size="22"></runai-svg-icon>
      <div class="rotated-text">NODE POOLS</div>
    </div>
    <table>
      <thead>
        <tr>
          <td>Node pool</td>
          <td>{{ $options.RESOURCE_LABEL.GPU }}</td>
          <template v-if="isCpuEnabled">
            <td>{{ $options.RESOURCE_LABEL.CPU }}</td>
            <td>{{ $options.RESOURCE_LABEL.MEMORY }}</td>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resource in modelValue.nodePoolsResources" :key="resource.nodePool?.id">
          <th><runai-ellipsis-text-with-tooltip :text="resource.nodePool.name" /></th>
          <td>
            <runai-editable-numeric-field
              :read-only="readOnly"
              v-model="resource.gpu.deserved"
              :input-placeholder="0"
              :label-text="$options.RESOURCE_LABEL.GPU"
            />
          </td>
          <template v-if="isCpuEnabled">
            <td>
              <runai-editable-numeric-field
                :read-only="readOnly"
                :model-value="resource.cpu.deserved && resource.cpu.deserved / $options.CPU_VALUE_FACTOR"
                @update:model-value="handleCpuVal(resource.cpu, $event)"
                :input-placeholder="$options.RESOURCE_STATE.Unlimited"
                :label-text="$options.RESOURCE_LABEL.CPU"
              />
            </td>
            <td>
              <memory-quota-input
                hide-slider
                :entity="$options.EQuotaEntity.department"
                :read-only="readOnly"
                v-model="resource.memory.deserved"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
//cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiEditableNumericField } from "@/components/runai-editable-numeric-field/";
import MemoryQuotaInput from "@/components/quota-management/memory-quota-input.vue";

//models
import { CPU_VALUE_FACTOR, EQuotaEntity, EResourceLabel, EResourceState } from "@/models/resource.model";
import type { IDepartment } from "@/models/department.model";
import type { IResourceQuota } from "@/models/project.model";
import RunaiEllipsisTextWithTooltip from "@/components/common/runai-ellipsis-text-with-tooltip/runai-ellipsis-text-with-tooltip.vue";

export default defineComponent({
  components: {
    RunaiEllipsisTextWithTooltip,
    MemoryQuotaInput,
    RunaiEditableNumericField,
    RunaiSvgIcon,
  },
  CPU_VALUE_FACTOR: CPU_VALUE_FACTOR,
  RESOURCE_STATE: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  EQuotaEntity: EQuotaEntity,
  props: {
    modelValue: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  methods: {
    handleCpuVal(cpu: IResourceQuota, newVal: number | null): void {
      cpu.deserved = newVal === null ? newVal : newVal * CPU_VALUE_FACTOR;
    },
  },
});
</script>
