<template>
  <runai-base-dialog :model-value="true" is-modal-persistent @close="onCancel" size="width-md">
    <template #header>Switch Cluster</template>

    <template #body>
      <div>To switch clusters, first sign out of this one and then sign in to the cluster you'd like to use.</div>
      <div class="action-text">Sign out and switch cluster?</div>
    </template>

    <template #footer>
      <q-btn label="CANCEL" color="primary" flat @click="onCancel" aid="logout-cluster-modal-close-button" />
      <q-btn label="SIGN OUT & SWITCH CLUSTER" color="primary" @click="logout" aid="sign-out-and-switch-cluster" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAuthStore } from "@/stores/auth.store";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["cancel", "done"],
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  computed: {},
  methods: {
    async logout(): Promise<void> {
      await this.authStore.logout();
      this.$emit("done");
    },
    onCancel(): void {
      this.$emit("cancel");
    },
  },
});
</script>

<style lang="scss" scoped>
.action-text {
  font-size: larger;
  margin-top: 20px;
}
</style>
