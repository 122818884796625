<template>
  <runai-expansion-item class="settings-section" label="Settings" default-opened :section-invalid="sectionInvalid">
    <template #subheader>
      <span>{{ summary }}</span>
    </template>

    <section class="open-shift-options q-mb-lg" v-if="isHigherClusterVersion">
      <div class="q-mb-sm">Select the Kubernetes distribution</div>
      <runai-radio-options
        class="other-dist-options"
        aid="higher-version-dist-options"
        :model-value="prerequisites.k8sDistribution"
        :options="otherK8sDistributions"
        @update:model-value="onSelectedDistribution([$event])"
        color="primary"
      />
    </section>

    <section class="dist-selection q-mb-md" v-else>
      <runai-form-card-section
        :loading="false"
        main-message="Select the Kubernetes distribution"
        entity-name="cluster"
        :cards-list="distributionCards"
        :selected-cards-ids="selectedDistributionId"
        search-name="k8s distribution"
        @selected-card-changed="onSelectedDistribution"
        :allow-actions="false"
      />
      <div class="col-12 q-ml-sm">
        <q-field class="hidden-field" :model-value="selectedDistributionId" :rules="[isRequiredDist]"></q-field>
      </div>
    </section>

    <section class="cluster-location q-mb-lg" v-if="showLocationOptions">
      <div class="q-mb-sm">Cluster location</div>
      <runai-radio-options
        class="location-options"
        :model-value="prerequisites.location"
        :options="locationOptions"
        @update:model-value="updateClusterLocation"
        color="primary"
      />
    </section>
    <section class="k8s-url-cluster" v-if="showLocationInput">
      <div>Enter a URL for the Kubernetes cluster. It will only be accessible within the organization network.</div>
      <div class="q-my-md italic">
        For more information, see the
        <a
          href="https://docs.run.ai/latest/admin/runai-setup/cluster-setup/cluster-prerequisites/#cluster-url"
          target="_blank"
        >
          Installation guide</a
        >
      </div>

      <q-input
        aid="cluster-url-input"
        :model-value="prerequisites.clusterUrl"
        no-error-icon
        label="Cluster URL"
        stack-label
        placeholder="Enter URL"
        :rules="[notEmpty, validURL]"
        @update:model-value="onUpdateClusterUrl"
      />
    </section>
  </runai-expansion-item>
</template>
<script lang="ts">
// types
import { TCardCmpName, type ICardListItem } from "@/components/common/runai-card-list";
import {
  EClusterDistIds,
  EClusterDistLabels,
  clusterK8SDistributionOptions,
  type IK8sDistributionCardData,
  EClusterLocations,
} from "@/models/cluster.model";
import type { PropType } from "vue";
import type { IPrerequisites } from "@/models/cluster.model";

interface ILocationOptions {
  label: string;
  value: EClusterLocations;
  toolTip: string;
}

// cmps
import { RunaiFormCardSection } from "@/components/common";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { errorMessages } from "@/common/error-message.constant";
import { isEmpty, isNotEmpty, isValidUrl } from "@/common/form.validators";
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiFormCardSection,
    RunaiRadioOptions,
  },
  emits: ["update", "is-section-invalid"],
  props: {
    isHigherClusterVersion: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isSaas: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    prerequisites: {
      type: Object as PropType<IPrerequisites>,
      required: true,
    },
  },
  data() {
    return {
      nameAlreadyExist: false as boolean,
      k8sDistributions: clusterK8SDistributionOptions as IK8sDistributionCardData[],
      otherK8sDistributions: [
        { label: EClusterDistLabels.OPEN_SHIFT, value: EClusterDistIds.OPEN_SHIFT },
        { label: "Other", value: "other" },
      ],
      locationOptions: [
        {
          label: "Same as control plane",
          value: EClusterLocations.CONTROL_PLANE,
          toolTip: "The Run:ai cluster will be installed on the same Kubernetes cluster as the Run:ai control plane",
        },
        {
          label: "Remote control plane",
          value: EClusterLocations.REMOTE,
          toolTip:
            "The Run:ai cluster will be installed on a different Kubernetes cluster than the Run:ai control plane",
        },
      ] as ILocationOptions[],
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return (
        isEmpty(this.prerequisites.k8sDistribution) ||
        (this.prerequisites.location === EClusterLocations.REMOTE && isEmpty(this.prerequisites.clusterUrl))
      );
    },
    summary(): string {
      let selectedDistName;
      let clusterLocation;
      if (this.prerequisites.k8sDistribution === "other") {
        selectedDistName = "Other";
      } else {
        selectedDistName =
          this.k8sDistributions.find((dist) => dist.id === this.prerequisites.k8sDistribution)?.title || "";
      }
      if (this.showLocationOptions) {
        const location = this.locationOptions.find((dist) => dist.value === this.prerequisites.location)?.label || "";
        clusterLocation = ` Cluster location: ${location}`;
      }
      return selectedDistName ? `Kubernetes distribution: ${selectedDistName}, ${clusterLocation || ""}` : "None";
    },
    isOpenShift(): boolean {
      return this.prerequisites.k8sDistribution === EClusterDistIds.OPEN_SHIFT;
    },
    showLocationOptions(): boolean {
      return !(this.isHigherClusterVersion && this.isOpenShift) && !this.isSaas;
    },
    showLocationInput(): boolean {
      if (this.isHigherClusterVersion && this.isOpenShift) return false;
      return this.isSaas || this.prerequisites.location === EClusterLocations.REMOTE;
    },
    distributionCards(): Array<ICardListItem> {
      return this.k8sDistributions.map((k8sDistType) => {
        return {
          id: k8sDistType.id,
          cardName: TCardCmpName.K8S_DISTRIBUTION,
          data: k8sDistType,
        };
      });
    },
    selectedDistributionId(): string[] {
      return this.prerequisites.k8sDistribution ? [this.prerequisites.k8sDistribution] : [];
    },
  },
  methods: {
    validURL(val: string): boolean | string {
      return isValidUrl(val) || errorMessages.INVALID_URL;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.URL_NOT_EMPTY;
    },
    isRequiredDist(val: string[]): boolean | string {
      return val && val.length ? true : errorMessages.SELECT_DISTRIBUTION;
    },
    updatePrerequisites(updatedPrerequisites: IPrerequisites): void {
      this.$emit("update", updatedPrerequisites);
    },
    onUpdateClusterUrl(val: string | number | null): void {
      let clusterUrl = val ? val?.toString() : "";
      this.updatePrerequisites({ ...this.prerequisites, clusterUrl });
    },
    updateClusterLocation(val: ILocationOptions["value"]): void {
      this.updatePrerequisites({ ...this.prerequisites, location: val, clusterUrl: "" });
    },
    onSelectedDistribution(selectedDistributionId: string[]): void {
      this.updatePrerequisites({ ...this.prerequisites, k8sDistribution: selectedDistributionId[0] });
    },
  },
});
</script>
<style lang="scss" scoped>
.prerequisites-section {
  .location-options,
  .other-dist-options {
    margin-left: -18px;
  }
}
</style>
