<template>
  <div class="stack-chart-frame">
    <highcharts :options="chartOptions" ref="chart" :key="chartOptions" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { Options } from "highcharts";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import dataModule from "highcharts/modules/data";
import drilldown from "highcharts/modules/drilldown";

//service
import type { IChartSeries } from "@/components/dashboard/chart.model";
import { dashboardService } from "@/services/control-plane/dashboard/dashboard.service";
import { EChartType } from "@/components/dashboard/chart.model";
import type { IChartArrayItemConfig } from "@/models/metrics.model";

export default defineComponent({
  components: {
    Highcharts: Chart,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    yAxisTitle: {
      type: String as PropType<string>,
      required: false,
    },
    data: {
      type: Array as PropType<Array<IChartSeries>>,
      required: true,
    },
    drilldownData: {
      type: Array as PropType<Array<IChartArrayItemConfig>>,
      defaultValue: [],
    },
    tooltipValueTitle: {
      type: String as PropType<string>,
      required: true,
    },
    toolTipValuePostfix: {
      type: String as PropType<string>,
      default: "",
    },
    tooltipSumTitle: {
      type: String as PropType<string>,
      required: true,
    },
    tooltipExtraSumTitle: {
      type: String as PropType<string>,
      default: "",
    },
    type: {
      type: String as PropType<EChartType>,
      required: true,
    },
    drilldownTitles: {
      type: Array as PropType<Array<string>>,
      required: false,
    },
    showLegend: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      chartOptions: null as null | Options,
    };
  },
  created() {
    drilldown(Highcharts);
    dataModule(Highcharts);
    this.setChartOptions();
  },
  methods: {
    setChartOptions(): void {
      drilldown(Highcharts);
      dataModule(Highcharts);
      this.chartOptions = {};
      switch (this.type) {
        case EChartType.STACKED_COLUMN:
          this.chartOptions = dashboardService.getStackChartOptions(
            this.title,
            this.yAxisTitle as string,
            this.tooltipValueTitle,
            this.tooltipSumTitle,
            this.data,
            this.drilldownData,
            this.drilldownTitles ? this.drilldownTitles : null,
            this.showLegend,
          );
          break;
        case EChartType.MULTI_BAR:
          this.chartOptions = dashboardService.getMultiBarChartOptions(
            this.title,
            this.yAxisTitle as string,
            this.tooltipValueTitle,
            this.toolTipValuePostfix,
            this.tooltipSumTitle,
            this.tooltipExtraSumTitle,
            this.data,
            this.drilldownData,
            this.drilldownTitles ? this.drilldownTitles : null,
            this.showLegend,
          );
          break;
      }
    },
  },
  watch: {
    data: {
      handler(newOptions, oldOptions) {
        if (newOptions === oldOptions || oldOptions === null) return;
        this.setChartOptions();
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.stack-chart-frame {
  background: $white;
  box-shadow: 0 1px 4px $black-35;
  border-radius: 3px;
  height: 300px;
  min-width: 330px;
}
</style>
