<template>
  <q-card flat class="access-rule-expansion-item text-body2" :class="{ 'margin-top': !isCollapsed }">
    <div class="card-wrapper">
      <div class="card-close">
        <q-btn
          v-permission="{ resourceType: ResourceType.AccessRules, action: Action.Delete }"
          :disable="disabledDeleteButton"
          @click="$emit('close')"
          flat
          size="8px"
          round
          icon="fa-solid fa-trash-can"
        />
        <q-tooltip max-width="400px" anchor="bottom end">{{ deleteButtonToolTip }}</q-tooltip>
      </div>
      <div class="card-summary" v-show="isCollapsed">
        <slot name="summary"></slot>
      </div>
      <q-slide-transition>
        <div class="card-content q-pa-sm" v-show="!isCollapsed">
          <slot name="content"></slot>
        </div>
      </q-slide-transition>
    </div>

    <div class="card-footer q-pr-sm" v-show="!isCollapsed">
      <q-field
        class="q-mr-auto hidden-field q-ml-md q-mb-sm error-field"
        :model-value="!displayError"
        no-error-icon
        :rules="[isRequired]"
      />
      <q-btn flat label="SAVE RULE" color="primary" @click="$emit('save')" :loading="loading" />
    </div>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { errorMessages } from "@/common/error-message.constant";
export default defineComponent({
  name: "access-rule-expansion-item",
  emits: ["save", "close"],
  props: {
    isCollapsed: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    displayError: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isAdminTenantRole: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isInheritedFromGroup: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    accessRuleSubjectId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    disabledDeleteButton(): boolean {
      return this.loading || this.isAdminTenantRole || this.isInheritedFromGroup;
    },
    deleteButtonToolTip(): string {
      return this.isAdminTenantRole
        ? `This rule can't be deleted because it is your last system-wide administrator access rule.`
        : this.isInheritedFromGroup
        ? `This rule can't be deleted because it is inherited from group ${this.accessRuleSubjectId}. To delete it for the whole group, go to "Access rules."`
        : `Delete access rule`;
    },
  },
  methods: {
    isRequired(val: string | null): string | boolean {
      return !!val || errorMessages.UNSAVED_RULE;
    },
  },
});
</script>

<style lang="scss" scoped>
.access-rule-expansion-item {
  border-width: 1px 1px 0.5px 1px;
  border-style: solid;
  border-color: $black-12;
  margin-bottom: -1px;
  border-radius: 0;
  &.margin-top {
    margin-top: 15px;
  }
  .card-wrapper {
    position: relative;
    padding: 10px;

    .card-close {
      top: 8px;
      right: 10px;
      cursor: pointer;
      position: absolute;
      z-index: 150;
    }
    .collapsed .card-content {
      height: 50px;
    }

    .card-wrapper:not(.collapsed) .card-content {
      height: 280px;
    }
  }
  .card-footer {
    border-top: 1px solid $black-12;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    .error-field {
      min-width: 100px;
    }
  }
}
</style>
