import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export enum ENVIRONMENT_ROUTE_NAMES {
  ENVIRONMENT_INDEX = "environment-index",
  ENVIRONMENT_NEW = "environment-new",
}

export const environmentRoutes: RunaiRouteRecord = {
  path: "/environments",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../views/environment/environment-app.vue"),
  children: [
    {
      path: "",
      name: ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_INDEX,
      component: () => import("../views/environment/environment-index.vue"),
      meta: {
        pageTitle: "Environments",
        resourceType: ResourceType.Environments,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "new",
      name: ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_NEW,
      component: () => import("../views/environment/environment-new.vue"),
      meta: {
        pageTitle: "New environment",
        fullWidth: true,
        requestToLeave: true,
        backPageName: ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_INDEX,
        resourceType: ResourceType.Environments,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
  ],
};
