import {
  format,
  parse,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import type { DateTimeFormatOptions } from "@/models/date.model";

export enum TimeUnit {
  minute = 0,
  hour = 1,
  day = 2,
  week = 3,
  month = 4,
  year = 5,
}

export const dateUtil = {
  adjustDateBy,
  adjustToEndOfTheDay,
  adjustToStartOfTheDay,
  dateAndTimeFormat,
  dateFormat,
  differenceBy,
  parseToDate,
  timeAgo,
  moreThanHourFromNow,
  isSameDay,
  formatDuration,
  formatToUTCDate,
  getEpochDates,
  dateToTimestamp,
};

function dateFormat(date: Date, dateFormat = "dd/MM/yyyy"): string {
  if (!date) return "";
  return format(date, dateFormat);
}

function formatToUTCDate(date: Date): string {
  // This is a workaround for the fact that the date-fns library does not support UTC dates in simple way
  // We need it more to send to server, so the format is always the same "yyyy-MM-dd hh:mm:ss.SSS"
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function dateAndTimeFormat(date: Date, options: DateTimeFormatOptions = {}): string {
  if (!date) return "";

  const { includeSeconds = false, includeMilliseconds = false } = options;

  const secondsFormat = includeSeconds ? ":ss" : "";
  const millisecondsFormat = includeMilliseconds ? ":SSS" : "";

  const formatString = `M/dd/yyyy, h:mm${secondsFormat}${millisecondsFormat} aa`;

  return format(date, formatString);
}
function adjustToStartOfTheDay(date: Date): void {
  date.setHours(0, 0, 0, 0);
}

function adjustToEndOfTheDay(date: Date): void {
  date.setHours(23, 59, 59, 999);
}

function parseToDate(dateToParse: string, format: string): Date | null {
  if (!dateToParse) return null;
  return parse(dateToParse, format, new Date());
}

function differenceBy(timeUnit: TimeUnit = TimeUnit.hour, endDate: Date | number, startDate: Date | number): number {
  if (!startDate || !endDate) return 0;
  switch (timeUnit) {
    case TimeUnit.minute:
      return differenceInMinutes(endDate, startDate);
    case TimeUnit.day:
      return differenceInDays(endDate, startDate);
    case TimeUnit.week:
      return differenceInWeeks(endDate, startDate);
    case TimeUnit.month:
      return differenceInMonths(endDate, startDate);
    case TimeUnit.year:
      return differenceInYears(endDate, startDate);
    default:
      return differenceInHours(endDate, startDate);
  }
}

function adjustDateBy(timeUnit: TimeUnit, date: Date | number, amount: number): Date {
  switch (timeUnit) {
    case TimeUnit.minute:
      return addMinutes(date, amount);
    case TimeUnit.hour:
      return addHours(date, amount);
    case TimeUnit.day:
      return addDays(date, amount);
    case TimeUnit.week:
      return addWeeks(date, amount);
    case TimeUnit.month:
      return addMonths(date, amount);
    case TimeUnit.year:
      return addYears(date, amount);
    default:
      return addHours(date, amount);
  }
}

// TODO: Convert this to date-fns
function timeAgo(timestamp: number): string {
  const date = new Date(timestamp);
  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // compute residual after removing whole days, hours and minutes
  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  let ret = "";
  if (days > 0) {
    ret += days + "d";
  }
  if (hours > 0 || (days > 0 && minutes > 0)) {
    if (ret) {
      ret += "-";
    }
    ret += hours + "h";
  }
  if (minutes > 0) {
    if (ret) {
      ret += "-";
    }
    ret += minutes + "m";
  }
  if (!ret) {
    ret += seconds + "s";
  }
  return ret;
}

function moreThanHourFromNow(creationTimestamp: number): boolean {
  if (!creationTimestamp) return false;

  const oneHourInMs = 3600000;
  const dateNow: number = new Date().getTime();
  return dateNow - Number(creationTimestamp) > oneHourInMs;
}

function isSameDay(timestamp1: number, timestamp2: number): boolean {
  const date1: Date = new Date(timestamp1);
  const date2: Date = new Date(timestamp2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function formatDuration(seconds: number): string {
  const date = new Date(seconds * 1000);
  const days = Math.floor(seconds / (3600 * 24));
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${days}d-${hours}h-${minutes}m`;
}

function getEpochDates(start: string, end: string): { startDate: number; endDate: number } {
  const startDateObj = new Date(start);
  const endDateObj = new Date(end);

  // Normalize to UTC
  const startDateUTC = new Date(
    Date.UTC(
      startDateObj.getUTCFullYear(),
      startDateObj.getUTCMonth(),
      startDateObj.getUTCDate(),
      startDateObj.getUTCHours(),
      startDateObj.getUTCMinutes(),
      startDateObj.getUTCSeconds(),
    ),
  );
  const endDateUTC = new Date(
    Date.UTC(
      endDateObj.getUTCFullYear(),
      endDateObj.getUTCMonth(),
      endDateObj.getUTCDate(),
      endDateObj.getUTCHours(),
      endDateObj.getUTCMinutes(),
      endDateObj.getUTCSeconds(),
    ),
  );

  const startDate: number = Math.floor(startDateUTC.getTime() / 1000);
  const endDate: number = Math.floor(endDateUTC.getTime() / 1000);

  return { startDate, endDate };
}

export function isDateString(str: string): boolean {
  const date: Date = new Date(str);
  return !isNaN(date.getTime());
}
function dateToTimestamp(date?: string): number {
  return +new Date(date || 0);
}
