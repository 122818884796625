<template>
  <section class="department-new column items-center">
    <div class="loader" v-if="loading"></div>
    <department-edit-form
      :department="department"
      :display-cpu-details="isCpuEnabled"
      :is-only-default-node-pool="isOnlyDefaultNodePool"
      v-if="!loading && department"
      @save-department="save"
      :cluster-uuid="clusterUuid"
      @cancel="onCancel"
      :read-only="isDepartmentViewPage"
    ></department-edit-form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useClusterStore } from "@/stores/cluster.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useSettingStore } from "@/stores/setting.store";

import { DepartmentEditForm } from "@/components/department/department-edit-form";

import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";

import type { IDepartment } from "@/models/department.model";
import type { INodePool } from "@/models/node-pool.model";

// route
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes";
import { useAppStore } from "@/stores/app.store";
import { alertUtil } from "@/utils/alert.util";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { usePermissionStore } from "@/stores/permissions.store";
import { HttpErrorResponse } from "@/models/http-response.model";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { departmentUtil } from "@/utils/department.util";

export default defineComponent({
  components: {
    DepartmentEditForm,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      nodePoolStore: useNodePoolStore(),
      clustersStore: useClusterStore(),
      departmentStore: useDepartmentStore(),
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
      department: {} as IDepartment,
      loading: false as boolean,
      clusterUuid: "" as string,
    };
  },
  async created() {
    try {
      this.loading = true;
      this.clusterUuid = this.clustersStore.currentCluster.uuid;
      await this.nodePoolStore.loadNodePoolsCount();
      if (this.$route.params.id) {
        this.department = await this.loadDepartment(this.$route.params.id as string);
      } else {
        this.department = await this.getEmptyDepartment();
      }
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isNewDepartment(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isOnlyDefaultNodePool(): boolean {
      return this.nodePoolStore.isOnlyDefaultNodePool;
    },
    isDepartmentViewPage(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_VIEW;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
  },
  methods: {
    async loadDepartment(departmentId: string): Promise<IDepartment> {
      const selectedDepartment = await departmentService.getById(+departmentId, this.clusterUuid);
      this.sortNodePools(selectedDepartment);
      return selectedDepartment;
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX });
    },
    async save(department: IDepartment): Promise<void> {
      if (!this.isCpuEnabled) {
        departmentUtil.resetCpuResources(department);
      }
      try {
        await this.departmentStore.save(department);
        this.$q.notify(
          alertUtil.getSuccess(`Department ${department.name} ${this.isNewDepartment ? "created" : "saved"}`),
        );
      } catch (error: unknown) {
        this.handleError(error);
        return;
      }
      await this.$router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX });
    },
    handleError(error: unknown) {
      if (error instanceof HttpErrorResponse) {
        console.error(error.serialize());
        this.$q.notify(alertUtil.getError(error.message));
      } else {
        this.$q.notify(alertUtil.getError(`Failed to ${this.isNewDepartment ? "create" : "save"} department`));
      }
    },
    async getEmptyDepartment(): Promise<IDepartment> {
      const department: IDepartment = departmentService.getEmptyDepartmentModel(this.isCpuEnabled);
      const nodePools: Array<INodePool> = await nodePoolService.getNodePools(this.clusterUuid);
      department.nodePoolsResources = nodePools.map((np: INodePool) => {
        return {
          nodePool: {
            id: np.id,
            name: np.name,
          },
          ...departmentService.getNodeResourcesModel(this.isCpuEnabled),
        };
      });
      this.sortNodePools(department);
      return department;
    },
    sortNodePools(department: IDepartment) {
      department.nodePoolsResources.sort((a, b) => {
        if (!a.nodePool || !b.nodePool) return 0;
        if (a.nodePool.name > b.nodePool.name) {
          return 1;
        }
        if (a.nodePool.name < b.nodePool.name) {
          return -1;
        }
        return 0;
      });
      const defaultIdx = department.nodePoolsResources.findIndex((np) => np.nodePool && np.nodePool.name === "default");
      if (defaultIdx === -1) return;
      const defaultNodePool = department.nodePoolsResources.splice(defaultIdx, 1)[0];
      department.nodePoolsResources.unshift(defaultNodePool);
    },
  },
});
</script>
