import type { IWorkloadCreateFormConfig } from "./workload-create-form.model";

export const workloadCreateFormService = {
  getMergedWorkloadConfigs,
};

function getMergedWorkloadConfigs(
  workloadConfig: IWorkloadCreateFormConfig,
  workloadSectionsOptions: IWorkloadCreateFormConfig,
): IWorkloadCreateFormConfig {
  const workloadCreateFormConfig: IWorkloadCreateFormConfig = {};

  if (workloadConfig.toggleEditingForm) {
    workloadCreateFormConfig.toggleEditingForm = {
      ...workloadConfig.toggleEditingForm,
      ...workloadSectionsOptions.toggleEditingForm,
    };
  }

  if (workloadConfig.project) {
    workloadCreateFormConfig.project = {
      ...workloadConfig.project,
      ...workloadSectionsOptions.project,
    };
  }

  if (workloadConfig.workloadName) {
    workloadCreateFormConfig.workloadName = {
      ...workloadConfig.workloadName,
      ...workloadSectionsOptions.workloadName,
    };
  }

  if (workloadConfig.multiNode) {
    workloadCreateFormConfig.multiNode = {
      ...workloadConfig.multiNode,
      ...workloadSectionsOptions.multiNode,
    };
  }

  if (workloadConfig.environment) {
    workloadCreateFormConfig.environment = {
      ...workloadConfig.environment,
      ...workloadSectionsOptions.environment,
      sectionOptions: {
        ...workloadConfig.environment.sectionOptions,
        ...workloadSectionsOptions.environment?.sectionOptions,
      },
    };
  }

  if (workloadConfig.compute) {
    workloadCreateFormConfig.compute = {
      ...workloadConfig.compute,
      ...workloadSectionsOptions.compute,
      sectionOptions: {
        ...workloadConfig.compute.sectionOptions,
        ...workloadSectionsOptions.compute?.sectionOptions,
      },
    };
  }

  if (workloadConfig.dataSource) {
    workloadCreateFormConfig.dataSource = {
      ...workloadConfig.dataSource,
      ...workloadSectionsOptions.dataSource,
    };
  }

  if (workloadConfig.volumes) {
    workloadCreateFormConfig.volumes = {
      ...workloadConfig.volumes,
      ...workloadSectionsOptions.volumes,
    };
  }

  if (workloadConfig.general) {
    workloadCreateFormConfig.general = {
      ...workloadConfig.general,
      ...workloadSectionsOptions.general,
    };
  }

  return workloadCreateFormConfig;
}
