<template>
  <section class="runai-page-filter">
    <q-chip
      v-for="filter in currentFilters"
      :key="filter.name"
      clickable
      removable
      size="md"
      @remove="removeFilter(filter.name)"
      >{{ filter.label }}:
      <span class="q-pr-md q-pl-xs text-weight-medium"> {{ filter.term }}</span>
      <runai-page-filter-popup
        :title="filter.label"
        :filter="filter"
        @save="saveFilter"
        :input-label="filterInputLabel"
      ></runai-page-filter-popup>
    </q-chip>
    <q-btn class="add-filter-btn" ref="add-filter-btn" flat no-caps label="Add Filter">
      <q-menu fit transition-show="scale" transition-hide="scale" transition-duration="500">
        <q-list>
          <q-item
            v-for="option in availableFilterOptions"
            :key="option.label"
            clickable
            v-close-popup
            class="cursor-pointer"
            @click="selectFilterToAdd(option)"
          >
            <q-item-section>{{ option.label }} </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <runai-page-filter-popup
        :title="selectedFilterLabel"
        :display="!!newFilter.name"
        v-if="!useSelection"
        :filter="newFilter"
        @close="resetNewFilter"
        @save="saveFilter"
        :input-label="filterInputLabel"
      ></runai-page-filter-popup>
      <runai-page-filter-popup-with-select
        :title="selectedFilterLabel"
        :display="!!newFilter.name"
        v-if="useSelection"
        :filter="newFilter"
        @close="resetNewFilter"
        @save="saveFilter"
        :input-label="filterInputLabel"
        :async-options-promise-map="asyncOptionsPromiseMap"
      ></runai-page-filter-popup-with-select>
    </q-btn>
  </section>
</template>

<script lang="ts">
//vue
import { defineComponent, type PropType } from "@vue/runtime-core";

// models
import type { ISelectOption } from "@/models/global.model";
import type { IFilterOption, IFilterModel, ILabeledFilter } from "@/models/filter.model";

// services
import { filterService } from "@/services/filter.service/filter.service";

// utils
import { deepCopy } from "@/utils/common.util";

// cmos
import RunaiPageFilterPopup from "./runai-page-filter-popup.vue";
import RunaiPageFilterPopupWithSelect from "@/components/common/runai-page-filter/runai-page-filter-popup-with-select.vue";

export default defineComponent({
  components: {
    RunaiPageFilterPopupWithSelect,
    RunaiPageFilterPopup,
  },
  emits: ["update-column-filters"],
  props: {
    initialFiltersModel: {
      type: Array as PropType<Array<IFilterModel>>,
      required: true,
    },
    filterOptions: {
      type: Array as PropType<Array<IFilterOption>>,
      required: true,
    },
    filterInputLabel: {
      type: String as PropType<string>,
      required: false,
    },
    useSelection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    asyncOptionsPromiseMap: {
      type: Object as PropType<Record<string, (searchQuery: string) => Promise<ISelectOption[]>>>,
      required: false,
    },
  },
  data() {
    return {
      newFilter: { field: "", term: "", name: "" } as IFilterModel,
      optionsObj: new Map<string, IFilterOption>(),
      filtersModel: [] as Array<IFilterModel>,
    };
  },
  created() {
    this.initFilterOptions();
    this.filtersModel = deepCopy(this.initialFiltersModel);
  },
  computed: {
    selectedFilterLabel(): string {
      return this.optionsObj.get(this.newFilter.name)?.label || "";
    },
    currentFilters(): Array<ILabeledFilter> {
      return this.filtersModel
        .filter((fm: IFilterModel) => this.optionsObj.get(fm.name)?.label)
        .map((fm: IFilterModel) => {
          const label: string = this.optionsObj.get(fm.name)?.label || "";
          return { ...fm, label };
        })
        .filter((fm: ILabeledFilter) => !!fm.label);
    },
    availableFilterOptions(): Array<IFilterOption> {
      return filterService.getAvailableFilterOptions(
        this.filtersModel,
        this.filterOptions.filter((f) => f.label),
      );
    },
  },
  methods: {
    removeFilter(filterName: string): void {
      const filterIdx = this.filtersModel.findIndex((fm: IFilterModel): boolean => fm.name === filterName);
      this.filtersModel.splice(filterIdx, 1);
      this.saveFilters();
    },
    selectFilterToAdd(filterOption: IFilterOption): void {
      this.newFilter.name = filterOption.name;
      this.newFilter.field = filterOption.field;
    },
    saveFilter(filter: IFilterModel): void {
      const filterToSave: IFilterModel | undefined = this.filtersModel.find(
        (fm: IFilterModel): boolean => fm.name === filter.name,
      );
      if (!filter.term) return this.resetNewFilter();
      if (filterToSave) {
        filterToSave.term = filter.term;
      } else {
        this.filtersModel.push(filter);
        this.resetNewFilter();
      }
      this.saveFilters();
    },
    resetNewFilter(): void {
      this.newFilter = { field: "", term: "", name: "" };
    },
    saveFilters(): void {
      this.$emit("update-column-filters", this.filtersModel);
    },
    initFilterOptions(): void {
      this.filterOptions.reduce((acc: Map<string, IFilterOption>, item: IFilterOption) => {
        acc.set(item.name, item);
        return acc;
      }, this.optionsObj);
    },
  },
  watch: {
    filterOptions: {
      handler() {
        this.initFilterOptions();
      },
      deep: true,
    },
    initialFiltersModel: {
      handler(newVal) {
        this.filtersModel = newVal;
      },
    },
  },
});
</script>

<style lang="scss">
.runai-page-filter {
  .filter-value {
    font-weight: 500;
  }

  .add-filter-btn {
    color: $black-70;
  }
}
</style>
