import { defineStore } from "pinia";

// models
import type {
  DatasourceListResponseEntry,
  GitAsset,
  NFSAsset,
  HostPathAsset,
  S3Asset,
  PVCAsset,
  NFSUpdateRequest,
  GitUpdateRequest,
  PVCUpdateRequest,
  HostPathUpdateRequest,
  S3UpdateRequest,
} from "@/swagger-models/assets-service-client";
import { ResourceType, type Action, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IAssetsFilter } from "@/models/filter.model";

// services
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";

import { arrayToObjectMap } from "@/utils/common.util";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { useClusterStore } from "./cluster.store";

export const useDataSourceStore = defineStore("DataSource", {
  state: () => ({
    dataSources: [] as Array<DatasourceListResponseEntry>,
    selectedHostPath: null as HostPathAsset | null,
    selectedS3: null as S3Asset | null,
    selectedPVC: null as PVCAsset | null,
    selectedGit: null as GitAsset | null,
    selectedNFS: null as NFSAsset | null,
    dataSourcesActionPermissions: {} as Record<ResourceType, Record<Action, PermittedScopes>>,
  }),
  getters: {
    dataSourceList(): Array<DatasourceListResponseEntry> {
      return this.dataSources;
    },
    dataSourcesMapByIds(): Record<string, DatasourceListResponseEntry> {
      return arrayToObjectMap<DatasourceListResponseEntry>(
        this.dataSources,
        (dataSource: DatasourceListResponseEntry) => dataSource.meta.id,
      );
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.dataSourcesActionPermissions).length) return;
      const dsResourceTypes: Array<ResourceType> = [
        ResourceType.NfsAssets,
        ResourceType.GitAssets,
        ResourceType.PvcAssets,
        ResourceType.S3Assets,
        ResourceType.HostPathAssets,
      ];
      return await Promise.all(
        dsResourceTypes.map((dsResourceType: ResourceType) =>
          permissionService.getPermittedScopesForAction(dsResourceType),
        ),
      ).then((actions: Array<Record<Action, PermittedScopes>>) => {
        actions.forEach((permissions: Record<Action, PermittedScopes>, index: number) => {
          this.dataSourcesActionPermissions[dsResourceTypes[index]] = permissions;
        });
      });
    },
    async loadDataSources(filterBy: IAssetsFilter = {}): Promise<Array<DatasourceListResponseEntry>> {
      filterBy.clusterId = useClusterStore().currentCluster.uuid;
      this.dataSources = await dataSourceService.list(filterBy);
      return this.dataSources;
    },
    async deleteDepartment(dataSource: DatasourceListResponseEntry): Promise<void> {
      return await dataSourceService.remove(dataSource);
    },
    async loadHostPathById(hostPathId: string): Promise<HostPathAsset> {
      this.selectedHostPath = await dataSourceService.getHostPathById(hostPathId);
      return this.selectedHostPath;
    },
    async loadS3ById(s3Id: string): Promise<S3Asset> {
      this.selectedS3 = await dataSourceService.getS3ById(s3Id);
      return this.selectedS3;
    },
    async loadNFSById(nfsId: string): Promise<NFSAsset> {
      this.selectedNFS = await dataSourceService.getNFSById(nfsId);
      return this.selectedNFS;
    },
    async loadGitById(gitId: string): Promise<GitAsset> {
      this.selectedGit = await dataSourceService.getGitById(gitId);
      return this.selectedGit;
    },
    async loadPVCById(pvcId: string): Promise<PVCAsset> {
      this.selectedPVC = await dataSourceService.getPvcById(pvcId);
      return this.selectedPVC;
    },
    async updateNfs(nfsId: string, dataSource: NFSUpdateRequest): Promise<void> {
      await dataSourceService.updateNFS(nfsId, dataSource);
    },
    async updateHostPath(hostPathId: string, dataSource: HostPathUpdateRequest): Promise<void> {
      await dataSourceService.updateHostPath(hostPathId, dataSource);
    },
    async updatePVC(pvcId: string, dataSource: PVCUpdateRequest): Promise<void> {
      await dataSourceService.updatePVC(pvcId, dataSource);
    },
    async updateGit(gitId: string, dataSource: GitUpdateRequest): Promise<void> {
      await dataSourceService.updateGit(gitId, dataSource);
    },
    async updateS3(s3Id: string, dataSource: S3UpdateRequest): Promise<void> {
      await dataSourceService.updateS3(s3Id, dataSource);
    },
    clearSelectedS3(): void {
      this.selectedS3 = null;
    },
    clearSelectedHostPath(): void {
      this.selectedHostPath = null;
    },
    clearSelectedPVC(): void {
      this.selectedPVC = null;
    },
    clearSelectedGit(): void {
      this.selectedGit = null;
    },
    clearSelectedNFS(): void {
      this.selectedNFS = null;
    },
  },
});
