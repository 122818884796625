import { K8S_API } from "@/common/api.constant";
import type { IPrometheusResponse } from "@/models/prometheus.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
const PROM_BASE_URL = `${K8S_API.v1}/prom/proxy/api/v1/`;
const enum PrometheusQueryType {
  QUERY = "query",
  QUERY_RANGE = "query_range",
}

type CustomExtractor = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (key: string, valueKey: string, row: any, metric: any, value: any) => any | undefined
>;

const DYNAMIC_PARAMETER_KEY = "allReporterPushGatewayParameters";
const DYNAMIC_METRIC_KEY = "allReporterPushGatewayMetrics";
const vShouldReturnFilteredResult = true;

export const prometheusService = {
  multipleQueries,
  multipleQueriesWithTimeRange,
  rangeQuery,
  getFilteredResultMultipleQueries,
  get,
};

function multipleQueries(queries: Record<string, string>): Promise<Array<IPrometheusResponse>> {
  const requestKeys: Array<string> = Object.keys(queries);
  const queriesPrms: Array<Promise<IPrometheusResponse>> = requestKeys.map(
    (key: string): Promise<IPrometheusResponse> => {
      const time: number = Math.floor(Date.now() / 1000);
      return get({ query: queries[key], time })
        .then((res) => res.data)
        .then((data) => ({ key, data: data.result }));
    },
  );
  return Promise.all(queriesPrms);
}

function multipleQueriesWithTimeRange(
  queries: Record<string, string>,
  start: number,
  end: number,
  step: number,
): Promise<Array<IPrometheusResponse>> {
  const requestKeys: Array<string> = Object.keys(queries);
  const queriesPrms: Array<Promise<IPrometheusResponse>> = requestKeys.map(
    (key: string): Promise<IPrometheusResponse> => {
      return get({ query: queries[key], start, end, step }, PrometheusQueryType.QUERY_RANGE)
        .then((res) => res.data)
        .then((data) => ({ key, data: data.result }));
    },
  );
  return Promise.all(queriesPrms);
}

function getFilteredResultMultipleQueries<T>(
  promQueries: { [key: string]: string },
  uniqueKey: string | Array<string>,
  time = Math.floor(Date.now() / 1000),
  customExtractor?: CustomExtractor,
  forgiveness = 0, // how metrics can be missing
) {
  return _getMultipleQueriesResults(promQueries, time, forgiveness).then(
    (results) =>
      Object.values(
        _getMapMultipleQueriesFinalResult(results, uniqueKey, vShouldReturnFilteredResult, customExtractor),
      ) as T[],
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function rangeQuery(query: string, start: number, end: number, step: number): Promise<any> {
  const res = await get({ query, start, end, step }, PrometheusQueryType.QUERY_RANGE);
  return res?.data?.result[0]?.values;
}

function _getMultipleQueriesResults(
  promQueries: { [key: string]: string },
  time = Math.floor(Date.now() / 1000),
  forgiveness = 0, // how metrics can be missing
) {
  let failures = 0;
  return Promise.all(
    Object.keys(promQueries).map((key) =>
      get({ query: promQueries[key], time })
        .then((res) => (res.data ? res.data : Promise.reject(new Error("Failed to retrieve data."))))
        .then((data) => {
          data.query = { key, query: promQueries[key] };
          return data;
        })
        .catch((err) => {
          failures++;
          if (failures > forgiveness) {
            return Promise.reject(err);
          }
          return false;
        }),
    ),
  );
}

/* eslint-disable */
function _getMapMultipleQueriesFinalResult(
  results: any[],
  uniqueKey: string | Array<string>,
  shouldReturnFilteredResult: boolean,
  customExtractor?: CustomExtractor,
) {
  const rows: Record<string, any> = {};
  results
    .filter((d) => d)
    .forEach((data: { query: any; result: Array<{ metric: any; value: any }> }) => {
      data.result.forEach(({ metric, value }, index) => {
        const key = _getItemKey(metric, uniqueKey);
        const valueKey = data.query.key;
        if (typeof key === "undefined") {
          return;
        }

        if (!rows[key]) {
          rows[key] = {
            id: key ? key : index,
            dynamicData: {},
          };
        }

        const row = rows[key];
        Object.assign(row, metric);

        if (customExtractor && customExtractor[valueKey]) {
          customExtractor[valueKey](key, valueKey, row, metric, value);
          return;
        } else if (shouldReturnFilteredResult) {
          row[valueKey] = value[1];
          return;
        }

        const isDynamicField = data.query.key === DYNAMIC_PARAMETER_KEY || data.query.key === DYNAMIC_METRIC_KEY;
        const rowKey = _getKey(metric, data.query.key);
        const propValue = _getValue(metric, data.query.key, value);
        if (isDynamicField) {
          row.dynamicData[rowKey] = {
            value: propValue,
            type: data.query.key === DYNAMIC_PARAMETER_KEY ? "parameter" : "metric",
          };
        } else {
          row[rowKey] = propValue;
        }
      });
    });

  return rows;
}

function _getValue(metric: any, key: any, value: any) {
  switch (key) {
    case DYNAMIC_PARAMETER_KEY:
      return metric.param_value;

    default:
      return value[1];
  }
}

function _getKey(metric: any, key: any) {
  switch (key) {
    case DYNAMIC_PARAMETER_KEY:
      return metric.param_name;

    case DYNAMIC_METRIC_KEY:
      return metric.metric_name;

    default:
      return key;
  }
}

function _getItemKey(item: any, uniqueKey: string | Array<string>) {
  if (typeof uniqueKey === "string") {
    return item[uniqueKey];
  } else {
    return uniqueKey.map((key) => item[key]).join(".");
  }
}
/* eslint-enable */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(query: Record<string, string | number>, queryType = PrometheusQueryType.QUERY): Promise<any> {
  return controlPlaneService.get(PROM_BASE_URL + queryType, query);
}
