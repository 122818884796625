<template>
  <section class="quota-table">
    <div class="row items-center">
      <div class="col-1"></div>
      <div class="col-11">
        <div class="row q-pa-sm">
          <div class="col"></div>
          <div class="col">{{ $options.RESOURCE_LABEL.GPU }}</div>
          <template v-if="isCpuEnabled">
            <div class="col">{{ $options.RESOURCE_LABEL.CPU }}</div>
            <div class="col">{{ $options.RESOURCE_LABEL.MEMORY }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="row items-center">
      <div class="col-1">
        <runai-svg-icon class="department-icon" name="departments-icon" size="22"></runai-svg-icon>
      </div>
      <div class="col-11">
        <div class="row border-top-bottom items-center q-pa-sm">
          <div class="col title">Department total</div>
          <div class="col">
            <runai-editable-numeric-field
              @update:model-value="$emit('gpu-change', $event)"
              :model-value="gpu.deserved"
              :input-placeholder="0"
              :label-text="$options.RESOURCE_LABEL.GPU"
              :read-only="readOnly"
            />
          </div>
          <template v-if="isCpuEnabled">
            <div class="col">
              <runai-editable-numeric-field
                @update:model-value="handleCpuChange($event)"
                :model-value="cpu.deserved && cpu.deserved / $options.CPU_VALUE_FACTOR"
                :input-placeholder="$options.RESOURCE_STATE.Unlimited"
                :label-text="$options.RESOURCE_LABEL.CPU"
                :read-only="readOnly"
              />
            </div>
            <div class="col">
              <memory-quota-input
                hide-slider
                :read-only="readOnly"
                :model-value="memory.deserved"
                :entity="$options.EQuotaEntity.department"
                @update:model-value="$emit('memory-change', $event)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiEditableNumericField } from "@/components/runai-editable-numeric-field";
import MemoryQuotaInput from "@/components/quota-management/memory-quota-input.vue";

// models
import { CPU_VALUE_FACTOR, EResourceState, EResourceLabel, EQuotaEntity } from "@/models/resource.model";
import type { IResourceQuota } from "@/models/project.model";
export default defineComponent({
  components: { MemoryQuotaInput, RunaiSvgIcon, RunaiEditableNumericField },
  emits: ["memory-change", "cpu-change", "gpu-change"],
  CPU_VALUE_FACTOR: CPU_VALUE_FACTOR,
  RESOURCE_STATE: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  EQuotaEntity: EQuotaEntity,
  props: {
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    cpu: {
      type: Object as PropType<IResourceQuota>,
      required: true,
    },
    gpu: {
      type: Object as PropType<IResourceQuota>,
      required: true,
    },
    memory: {
      type: Object as PropType<IResourceQuota>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    handleCpuChange(newVal: number | null): void {
      if (newVal === null) {
        this.$emit("cpu-change", null);
      } else {
        this.$emit("cpu-change", newVal * CPU_VALUE_FACTOR);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.quota-table {
  color: $black-54;
  .headers {
    color: $black-12;
  }
  .title {
    color: $black-70;
    font-size: 16px;
    font-weight: 500;
  }
  .border-top-bottom {
    border-bottom: 1px solid $black-12;
    border-top: 1px solid $black-12;
  }

  .department-icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $body-background-color;
    border-radius: 5px;
    width: 49px;
    height: 46px;
    padding: 10px 0;
    margin-block: 4px;
  }
}
</style>
