import { memoryFormat, toPercent } from "@/utils/format.util";
import { tableUtil } from "@/utils/table.util";
import { dateUtil } from "@/utils/date.util";
import type { IAssignedResources } from "@/models/resource.model";
import { EResourceType } from "@/models/resource.model";
import type { ITableColumn } from "./table.model";
import type { INodePoolResources } from "./project.model";
import { resourceUtil } from "@/utils/resource.util";
import { ECustomCell } from "./table.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

export const DEFAULT_DEPARTMENT_NAME = "default";
export interface IDepartmentProject {
  name: string;
  id: number;
  deserved_gpus: number;
}

export interface IDepartment {
  id?: number;
  name: string;
  tenantId?: number;
  deservedGpus?: number;
  maxAllowedGpus?: number | null;
  clusterUuid?: string;
  projectsDeservedGpus?: null | number;
  projects?: Array<IDepartmentProject>;
  resources: IAssignedResources;
  nodePoolsResources: INodePoolResources[];
  assignedResourcesId: number;
  createdAt: string;
  roles?: string[];
  allocatedCpu?: number;
  allocatedGpus?: number;
  allocatedMemory?: number;
  quotaStatuses?: Array<NodePoolQuotaStatus>;
}

export interface IRemoveDepartmentAction {
  action: string;
  toDepartmentId: number;
  fromDepartmentId: number;
}

export const allDepartmentColumnsMap: Record<string, ITableColumn> = {
  departmentName: {
    name: "department-name",
    label: "Department",
    field: (row: IDepartment) => row.name,
    sortable: true,
    align: "left",
  },
  nodePools: {
    name: "node-pools",
    label: "Node pool(s) with quota",
    field: (row: IDepartment) => row.nodePoolsResources,
    sortable: true,
    align: "left",
    format: (nodePoolResources: INodePoolResources[]): string[] =>
      nodePoolResources.map((nodePoolResources) => nodePoolResources.nodePool.name),
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "node-pools-clicked" },
    sort: (fieldA, fieldB, rowA, rowB) => tableUtil.customColumnSort(allDepartmentColumnsMap.nodePools, rowA, rowB),
  },
  assignedGpus: {
    name: "assigned-gpus",
    label: "GPU quota",
    field: (row: IDepartment) => row.resources,
    sortable: true,
    align: "left",
    format: (resources: IAssignedResources) =>
      resourceUtil.getResourceDisplayValue(resources.gpu.deserved, EResourceType.GPU),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.assignedGpus, rowA, rowB, parseFloat),
  },
  projectsGpus: {
    name: "projects-gpus",
    label: "Total GPUs for projects",
    field: (row: IDepartment) => row.projectsDeservedGpus,
    sortable: true,
    align: "left",
    format: (val: string) => (val ? val : "0.00"),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.projectsGpus, rowA, rowB, parseFloat),
  },
  accessRules: {
    name: "accessRules",
    label: "Subject(s)",
    field: (row: IDepartment) => row?.roles,
    sortable: false,
    align: "left",
    customCell: ECustomCell.ROLE_ASSIGMENT_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
  },
  projects: {
    name: "projects",
    label: "Project(s)",
    field: (row: IDepartment) => row.projects,
    sortable: true,
    align: "left",
    format: (projects: Array<IDepartmentProject>): Array<string> | string => {
      if (!projects) return "-";
      return projects.map((p: IDepartmentProject) => p.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "projects-clicked" },
    sort: (fieldA, fieldB, rowA, rowB) => tableUtil.customColumnSort(allDepartmentColumnsMap.projects, rowA, rowB),
  },
  assignedCpu: {
    name: "assigned-cpu",
    label: "Assigned CPUs (cores)",
    field: (row: IDepartment) => row.resources,
    sortable: true,
    align: "left",
    format: (resources: IAssignedResources) =>
      resourceUtil.getResourceDisplayValue(resources.cpu.deserved, EResourceType.CPU),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.assignedCpu, rowA, rowB, parseFloat),
  },
  assignedMemory: {
    name: "assigned-memory",
    label: "Assigned memory",
    field: (row: IDepartment) => row.resources,
    sortable: true,
    align: "left",
    format: (resources: IAssignedResources) =>
      resourceUtil.getResourceDisplayValue(resources.memory.deserved, EResourceType.MEMORY),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.assignedMemory, rowA, rowB, parseFloat),
  },
  allocatedCpu: {
    name: "allocated-cpu",
    label: "Allocated CPUs (Cores)",
    field: (row: IDepartment) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: number | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(allocatedCpu, EResourceType.CPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.allocatedCpu, rowA, rowB, parseFloat),
  },
  allocatedMemory: {
    name: "allocated-memory",
    label: "Allocated CPU memory",
    field: (row: IDepartment) => row.allocatedMemory,
    sortable: true,
    align: "left",
    //1000000 = 10^6 - backend return in mb need to convert to bytes - to keep on homogeneity
    format: (allocatedMemory: number | undefined) => (allocatedMemory ? memoryFormat(allocatedMemory * 1000000) : "-"),
  },
  allocatedGpus: {
    name: "allocated-gpus",
    label: "Allocated GPUs",
    field: (row: IDepartment) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(allocatedGpus, EResourceType.GPU) : "-",
    sort: (fieldA, fieldB, rowA, rowB) => tableUtil.customColumnSort(allDepartmentColumnsMap.allocatedGpus, rowA, rowB),
  },
  gpuUtilization: {
    name: "gpu-utilization",
    label: "GPU allocation ratio",
    field: (row: IDepartment) => row.allocatedGpus,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined, department: IDepartment) =>
      toPercent(allocatedGpus || 0, resourceUtil.sumOfResourcesByType(department.nodePoolsResources, EResourceType.GPU)),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.gpuUtilization, rowA, rowB, parseFloat),
  },
  cpuUtilization: {
    name: "cpu-utilization",
    label: "CPU allocation ratio",
    field: (row: IDepartment) => row.allocatedCpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: number | undefined, department: IDepartment) =>
      toPercent(allocatedCpu || 0, resourceUtil.sumOfResourcesByType(department.nodePoolsResources, EResourceType.CPU)),
    sort: (fieldA, fieldB, rowA, rowB) => tableUtil.customColumnSort(allDepartmentColumnsMap.cpuUtilization, rowA, rowB),
  },
  memoryUtilization: {
    name: "memory-utilization",
    label: "CPU memory allocation ratio",
    field: (row: IDepartment) => row.allocatedMemory,
    sortable: true,
    align: "left",
    format: (allocatedMemory: number | undefined, department: IDepartment) =>
      toPercent(
        resourceUtil.fromBytesToMiB(allocatedMemory || 0),
        resourceUtil.sumOfResourcesByType(department.nodePoolsResources, EResourceType.MEMORY),
      ),
    sort: (fieldA, fieldB, rowA, rowB) =>
      tableUtil.customColumnSort(allDepartmentColumnsMap.memoryUtilization, rowA, rowB),
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: IDepartment) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => (createdAt ? dateUtil.dateAndTimeFormat(new Date(createdAt)) : "-"),
  },
};

export const allDepartmentColumns: Array<ITableColumn> = [
  allDepartmentColumnsMap.departmentName,
  allDepartmentColumnsMap.nodePools,
  allDepartmentColumnsMap.assignedGpus,
  allDepartmentColumnsMap.projectsGpus,
  allDepartmentColumnsMap.projects,
  allDepartmentColumnsMap.accessRules,
  allDepartmentColumnsMap.assignedCpu,
  allDepartmentColumnsMap.assignedMemory,
  allDepartmentColumnsMap.createdAt,
];

export const departmentIndexColumns: Array<ITableColumn> = [
  { ...allDepartmentColumnsMap.departmentName, display: true, mandatory: true },
  { ...allDepartmentColumnsMap.nodePools, display: true, mandatory: true },
  { ...allDepartmentColumnsMap.assignedGpus, display: true },
  { ...allDepartmentColumnsMap.projectsGpus, display: true },
  { ...allDepartmentColumnsMap.projects, display: true },
  { ...allDepartmentColumnsMap.accessRules, display: true },
  { ...allDepartmentColumnsMap.assignedCpu, display: true },
  { ...allDepartmentColumnsMap.assignedMemory, display: true },
  { ...allDepartmentColumnsMap.allocatedGpus, display: true },
  { ...allDepartmentColumnsMap.allocatedCpu, display: true },
  { ...allDepartmentColumnsMap.allocatedMemory, display: true },
  { ...allDepartmentColumnsMap.gpuUtilization, display: true },
  { ...allDepartmentColumnsMap.cpuUtilization, display: true },
  { ...allDepartmentColumnsMap.memoryUtilization, display: true },
  { ...allDepartmentColumnsMap.createdAt, display: true },
];

export interface IDepartmentValidation {
  name?: boolean;
  cpu?: boolean;
  gpu?: boolean;
  memory?: boolean;
}

export const departmentDependentColumns = {
  cpu: [
    allDepartmentColumnsMap.assignedCpu.name,
    allDepartmentColumnsMap.assignedMemory.name,
    allDepartmentColumnsMap.memoryUtilization.name,
    allDepartmentColumnsMap.cpuUtilization.name,
    allDepartmentColumnsMap.allocatedCpu.name,
    allDepartmentColumnsMap.allocatedMemory.name,
  ],
  nodePools: [allDepartmentColumnsMap.nodePools.name],
  accessRules: [allDepartmentColumnsMap.accessRules.name],
};

export interface ILoadDepartmentsOptions {
  withMetrics?: boolean;
  withAccessRules?: boolean;
}
