import type { ILicense, ISignedLicense } from "@/models/license.model";
import { licenseService } from "@/services/control-plane/license.service/license.service";
import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";

export const useLicenseStore = defineStore("License", {
  state: () => ({
    licenses: [] as ILicense[],
    license: null as null | ISignedLicense,
    inUseGPUs: 0 as number,
  }),
  getters: {
    licensedGPUs(): number {
      return this.licenses.reduce((acc, license) => acc + license.limit, 0);
    },
    inUseGPUsAmount(): number {
      return this.inUseGPUs;
    },
  },
  actions: {
    async loadLicenses(): Promise<void> {
      const tenantId = useAuthStore().tenant.id?.toString() || "";
      try {
        this.licenses = await licenseService.list(tenantId);
      } catch (error: unknown) {
        console.error("Failed to get licenses", error);
      }
      try {
        this.inUseGPUs = await licenseService.getTotalUsedGPUs();
      } catch (error: unknown) {
        console.error("Failed to get the amount of gpus in use", error);
      }
    },
    async uploadLicense(license: File): Promise<ISignedLicense> {
      const tenantId = useAuthStore().tenant.id?.toString() || "";
      this.license = await licenseService.upload(tenantId, license);
      if (license) {
        this.loadLicenses();
      }
      return this.license;
    },
  },
});
