import { DeploymentPhaseMap, EDeploymentStatus } from "@/models/deployment.model";
import type { IStatusColOptions } from "@/models/table.model";
import { EWorkloadStatus } from "@/common/status.constant";

export const deploymentUtil = { getDeploymentStatusColOptions };

function getDeploymentStatusColOptions(status: EDeploymentStatus): IStatusColOptions {
  const statusOptions: IStatusColOptions = DeploymentPhaseMap[status];
  if (!statusOptions || !statusOptions.status) {
    return { status: EWorkloadStatus.Creating, displayAnimation: false };
  }
  return statusOptions;
}
