<template>
  <span class="cluster-dropdown-container">
    <q-btn-dropdown flat class="cluster-dropdown" aid="cluster-dropdown">
      <template v-slot:label>
        Cluster:<span class="cluster-name">{{ clusterName }}</span>
      </template>
      <q-list class="cluster-dropdown-list-container">
        <q-item
          v-for="cluster in clusters"
          clickable
          v-close-popup
          @click="onItemClick(cluster)"
          :key="cluster.uuid"
          :aid="cluster.name"
        >
          <q-item-section>
            <q-item-label>{{ cluster.name }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <logout-cluster-modal v-if="showLogoutModal" @done="showLogoutModal = false" @cancel="onCancel" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";

// models
import type { ICluster } from "@/models/cluster.model";
import type { IReadinessStatus } from "@/models/researcher.model";

// cmps
import { LogoutClusterModal } from "./logout-cluster-modal";

export default defineComponent({
  components: {
    LogoutClusterModal,
  },
  data() {
    return {
      authStore: useAuthStore(),
      clusterStore: useClusterStore(),
      showLogoutModal: false as boolean,
      tempCluster: null as null | ICluster,
    };
  },
  computed: {
    clusters(): Array<ICluster> {
      return this.clusterStore.clusterList;
    },
    currentCluster(): ICluster {
      return this.clusterStore.currentCluster;
    },
    clusterName(): string {
      return this.currentCluster.name;
    },
  },
  methods: {
    async onItemClick(cluster: ICluster): Promise<void> {
      this.tempCluster = this.clusterStore.currentCluster;
      this.clusterStore.setCurrentCluster(cluster);
      if (!cluster.domain || !this.authStore.currentUser.identityProvider) {
        this.tempCluster = null;
        return;
      }

      const externalTokenResponse: IReadinessStatus = await whoamiService.shouldUseExternalToken(cluster.domain);
      if (externalTokenResponse.connected) {
        this.tempCluster = null;
        return;
      }

      const internalTokenResponse: IReadinessStatus = await whoamiService.isInternalTokenValid(cluster.domain);
      if (internalTokenResponse.connected) {
        this.tempCluster = null;
        return;
      }

      if (internalTokenResponse.statusCode === 401) {
        this.showLogoutModal = true;
      }
    },
    onCancel(): void {
      this.showLogoutModal = false;
      if (!this.tempCluster) return;
      this.clusterStore.setCurrentCluster(this.tempCluster);
      this.tempCluster = null;
    },
  },
});
</script>

<style lang="scss" scoped>
.cluster-dropdown-container {
  .cluster-dropdown {
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    .cluster-name {
      margin-left: 4px;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss">
.cluster-dropdown-list-container {
  background: $dark-gray;
  color: $white;
}

.cluster-dropdown {
  .q-icon.fa-caret-down {
    font-size: 15px;
  }
}
</style>
