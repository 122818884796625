import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

import type { ICluster, TClusterVersions } from "@/models/cluster.model";
import { allClusterColumns } from "@/models/cluster.model";
import type { IFilterBy } from "@/models/filter.model";

import { API, K8S_API } from "@/common/api.constant";

import { filterService } from "@/services/filter.service/filter.service";
import axios, { type AxiosRequestConfig } from "axios";

export const clusterService = {
  list,
  remove,
  create,
  getOperatorVersions,
  update,
  getAwsClustaerInstallationUrl,
  checkClusterConnection,
};

const ENDPOINT = `${K8S_API.v1}/clusters`;
const ENDPOINT_V2 = `${API.v1}/clusters`;

// api calls
async function list(filterBy: IFilterBy): Promise<Array<ICluster>> {
  let clusters: Array<ICluster> = await controlPlaneService.get(ENDPOINT);

  if (!filterBy || !filterBy.displayedColumns) return clusters;

  if (filterBy.searchTerm) {
    clusters = filterService.filterBySearchTerm<ICluster>(
      clusters,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allClusterColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    clusters = filterService.filterByColumns(clusters, filterBy.columnFilters, allClusterColumns);
  }
  return clusters;
}

async function remove(uuid: string): Promise<void> {
  await controlPlaneService.delete(`${ENDPOINT}/${uuid}`);
}

async function update(uuid: string, updatedCluster: ICluster): Promise<void> {
  await controlPlaneService.put(`${ENDPOINT}/${uuid}`, updatedCluster);
}

function create(name: string): Promise<ICluster> {
  return controlPlaneService.post(ENDPOINT, { name });
}

function getOperatorVersions(): Promise<Record<TClusterVersions, string>> {
  return controlPlaneService.get(`${ENDPOINT}/versions`);
}

function getAwsClustaerInstallationUrl(clusterId: string): Promise<string> {
  return controlPlaneService.get(`${ENDPOINT_V2}/${clusterId}/installation-url`);
}

async function checkClusterConnection(
  clusterDomain: string,
  accessToken: string,
  externalToken: string,
): Promise<number> {
  let headers = {};
  headers = externalToken
    ? {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${externalToken}`,
        AuthInfo: `Bearer ${accessToken}`,
      }
    : {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${accessToken}`,
      };
  const requestOptions: AxiosRequestConfig = {
    url: `${clusterDomain}/researcher/api/v1/whoami`,
    method: "GET",
    headers: headers,
  };
  try {
    const response = await axios(requestOptions);
    return response.status;
  } catch {
    return 401;
  }
}
