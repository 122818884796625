// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";
import { pick } from "@/utils/common.util";

// constants
import { API } from "@/common/api.constant";

// models
import type { ComputeAsset, ComputeUpdateRequest } from "@/swagger-models/assets-service-client";
import type { IAssetsFilter } from "@/models/filter.model";
import { allComputeResourceColumns, type IUIComputeResourceCreation } from "@/models/compute-resource.model";

export const computeResourceService = {
  list,
  getEmptyComputeResourceModel,
  remove,
  create,
  getById,
  update,
};

const endpoint = `${API.asset}/compute`;

// api calls
async function list(filterBy: IAssetsFilter = {}): Promise<Array<ComputeAsset>> {
  const filters: IAssetsFilter = pick(
    filterBy,
    "projectId",
    "departmentId",
    "scope",
    "usageInfo",
    "complyToProject",
    "complyToWorkloadType",
    "clusterId",
  );
  let computeResources: Array<ComputeAsset> = await controlPlaneService
    .get(endpoint, filters)
    .then((res) => res.entries);

  if (!filterBy || !filterBy.displayedColumns) return computeResources;

  if (filterBy.searchTerm) {
    computeResources = filterService.filterBySearchTerm<ComputeAsset>(
      computeResources,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allComputeResourceColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    computeResources = filterService.filterByColumns(
      computeResources,
      filterBy.columnFilters,
      allComputeResourceColumns,
    );
  }

  return computeResources;
}

async function getById(computeResourceId: string): Promise<ComputeAsset> {
  return controlPlaneService.get(`${endpoint}/${computeResourceId}`);
}

async function create(computeResource: IUIComputeResourceCreation): Promise<ComputeAsset> {
  return controlPlaneService.post(endpoint, computeResource);
}

async function remove(computeResourceId: string): Promise<void> {
  return controlPlaneService.delete(`${endpoint}/${computeResourceId}`);
}

async function update(computeId: string, computeResource: ComputeUpdateRequest): Promise<ComputeAsset> {
  return await controlPlaneService.put(`${endpoint}/${computeId}`, computeResource);
}

function getEmptyComputeResourceModel(): IUIComputeResourceCreation {
  return {
    meta: {
      name: "",
      scope: null,
      projectId: null,
    },
    spec: {
      gpuDevicesRequest: 0,
      cpuCoreRequest: 0,
      cpuMemoryRequest: "0M",
      largeShmRequest: false,
    },
  };
}
