<template>
  <section class="workload-metrics-tab">
    <section class="q-pa-md workload-metrics-date-picker">
      <runai-datepicker-with-predefined
        @changed="updateDateRange"
        :range-of-dates="rangeOfDatesCallback"
        info-bar-message="Select a period within the last 90 days"
        :predefined-options="predefinedOptions"
        :default-option-index="0"
      />
    </section>
    <runai-default-loader v-if="loadingChartsData" />
    <template v-else>
      <workload-charts
        ref="workloadCharts"
        :gpu-utilization-metrics="gpuUtilizationMetrics"
        :gpu-memory-usage-metrics="gpuMemoryUsageMetrics"
        :cpu-usage-metrics="cpuUsageMetrics"
        :cpu-memory-usage-metrics="cpuMemoryUsageMetrics"
        :is-hours-range="isHoursRange"
      ></workload-charts>
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Models
import { hoursGranularityPredefinedOptions, type PredefinedOption } from "@/models/date.model";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { ITrainingList } from "@/models/training.model";
// Services
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { dateUtil, TimeUnit } from "@/utils/date.util";
// Components
import { RunaiDatepickerWithPredefined } from "@/components/common/runai-datepicker-with-predefined";
import { WorkloadCharts } from "@/components/old-workload/workload-charts";
import { RunaiDefaultLoader } from "@/components/common/runai-default-loader";
import { debounce } from "quasar";

const LIVE_INTERVAL = 20000; // 20 seconds
const HALF_DAY = 12;
type TWorkloadCharts = InstanceType<typeof WorkloadCharts>;

export default defineComponent({
  components: {
    RunaiDatepickerWithPredefined,
    WorkloadCharts,
    RunaiDefaultLoader,
  },
  props: {
    workload: {
      type: Object as PropType<IWorkspaceList | ITrainingList>,
      required: true,
    },
    drawerWidth: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      startDate: dateUtil.adjustDateBy(TimeUnit.hour, new Date(), -1) as Date,
      endDate: new Date() as Date,
      loadingChartsData: false as boolean,
      predefinedOptions: hoursGranularityPredefinedOptions as Array<PredefinedOption>,
      gpuUtilizationMetrics: [] as Array<Array<number>>,
      gpuMemoryUsageMetrics: [] as Array<Array<number>>,
      cpuUtilizationMetrics: [] as Array<Array<number>>,
      cpuMemoryUsageMetrics: [] as Array<Array<number>>,
      cpuUsageMetrics: [] as Array<Array<number>>,
      liveDataIntervalId: null as number | null,
    };
  },
  created() {
    this.resizeCharts = debounce(this.resizeCharts, 100);
    this.loadChartsData();
  },
  computed: {
    isHoursRange(): boolean {
      return dateUtil.differenceBy(TimeUnit.hour, this.endDate, this.startDate) < 48;
    },
  },
  methods: {
    async loadChartsData(live?: boolean): Promise<void> {
      try {
        if (!live) this.loadingChartsData = true;
        if (!this.workload.job?.podGroupId) {
          if (!live) this.loadingChartsData = false;
          return;
        }

        const rangeStart: number = this.startDate.getTime() / 1000;
        const rangeEnd: number = this.endDate.getTime() / 1000;
        const range = rangeEnd - rangeStart;
        const step = Math.max(5, Math.floor(range / 200 / 100) * 100);
        await Promise.all([
          this.loadGpuUtilization(this.workload.job.podGroupId, rangeStart, rangeEnd, step),
          this.loadGpuMemoryUsage(this.workload.job.podGroupId, rangeStart, rangeEnd, step),
          this.loadCpuUsage(this.workload.job.podGroupId, rangeStart, rangeEnd, step),
          this.loadCpuMemoryUsage(this.workload.job.podGroupId, rangeStart, rangeEnd, step),
        ]);

        const differenceTime: number = dateUtil.differenceBy(TimeUnit.hour, this.endDate, this.startDate);
        if (differenceTime < HALF_DAY) {
          if (!this.liveDataIntervalId) {
            this.liveDataIntervalId = window.setInterval(() => {
              this.loadChartsData(true);
            }, LIVE_INTERVAL);
          }
        } else {
          this.liveDataIntervalId && clearInterval(this.liveDataIntervalId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (!live) this.loadingChartsData = false;
      }
    },
    async updateDateRange(dateRange: Array<Date>): Promise<void> {
      this.startDate = dateRange[0];
      this.endDate = dateRange[1];
      this.loadChartsData();
    },
    rangeOfDatesCallback(date: string): boolean {
      const todayDate: number = new Date().getTime();
      const dateToCheck: number = Date.parse(date);
      const back90Days: Date = dateUtil.adjustDateBy(TimeUnit.day, todayDate, -90);
      return dateToCheck >= back90Days.getTime() && dateToCheck <= todayDate;
    },

    async loadGpuUtilization(podGroupId: string, rangeStart: number, rangeEnd: number, step: number): Promise<void> {
      try {
        const metrics = await workspaceService.gpuUtilizationMetric(podGroupId, rangeStart, rangeEnd, step);
        this.gpuUtilizationMetrics =
          metrics?.map((metric: Array<number | string>) => {
            return [Number(metric[0]) * 1000, Number(metric[1])];
          }) || [];
      } catch (err) {
        console.error("Failed to load GPU utilization", err);
      }
    },
    async loadGpuMemoryUsage(podGroupId: string, rangeStart: number, rangeEnd: number, step: number): Promise<void> {
      try {
        const metrics = await workspaceService.gpuMemoryUsageMetric(podGroupId, rangeStart, rangeEnd, step);
        this.gpuMemoryUsageMetrics =
          metrics?.map((metric: Array<number | string>) => {
            return [Number(metric[0]) * 1000, Number(metric[1])];
          }) || [];
      } catch (err) {
        console.error("Failed to load GPU memory usage", err);
      }
    },
    async loadCpuUsage(podGroupId: string, rangeStart: number, rangeEnd: number, step: number): Promise<void> {
      try {
        const metrics = await workspaceService.cpuUsageMetric(podGroupId, rangeStart, rangeEnd, step);
        this.cpuUsageMetrics =
          metrics?.map((metric: Array<number | string>) => {
            return [Number(metric[0]) * 1000, Number(metric[1])];
          }) || [];
      } catch (err) {
        console.error("Failed to load CPU usage", err);
      }
    },
    async loadCpuMemoryUsage(podGroupId: string, rangeStart: number, rangeEnd: number, step: number): Promise<void> {
      try {
        const metrics: Array<Array<number | string>> = await workspaceService.cpuMemoryUsageMetric(
          podGroupId,
          rangeStart,
          rangeEnd,
          step,
        );
        this.cpuMemoryUsageMetrics =
          metrics?.map((metric: Array<string | number>) => {
            return [Number(metric[0]) * 1000, Number(metric[1])];
          }) || [];
      } catch (err) {
        console.error("Failed to load CPU memory usage", err);
      }
    },
    resizeCharts(): void {
      this.$nextTick(() => {
        (this.$refs.workloadCharts as TWorkloadCharts).resizeCharts();
      });
    },
  },
  watch: {
    "workload.meta.id": {
      handler() {
        this.loadChartsData();
      },
    },
    drawerWidth: {
      handler() {
        this.resizeCharts();
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.workload-metrics-tab {
  height: 100%;
  overflow: hidden;
}
.workload-metrics-date-picker {
  border-bottom: solid 1px $black-10;
}
</style>
