<template>
  <section class="q-pt-md row justify-start">
    <q-input
      class="col-9 q-mr-xl scope-input"
      label="Scope"
      clearable
      readonly
      @clear="$emit('clear')"
      clear-icon="fa-regular fa-xmark"
      :model-value="selectedScope ? selectedScope?.path : ''"
      :rules="[requiredScope]"
      no-error-icon
    >
      <q-btn
        class="x-button scope-input"
        round
        v-if="selectedScope"
        flat
        dense
        icon="fas fa-close"
        @click="$emit('clear')"
        size="sm"
      />
    </q-input>
    <div class="tree-button-wrapper">
      <q-btn class="tree-button" color="primary" icon="fa-solid fa-folder-tree" size="sm">
        <runai-org-tree-with-pop-over @update:selected="$emit('update-selected', $event)" cover />
      </q-btn>
    </div>
  </section>
  <section class="q-pt-md row justify-start">
    <div class="col-12 q-mr-xl scope-input error">
      <p v-if="error">
        You don't have permissions to set this scope for the role selected above. Try selecting a different role and
        scope combination.
      </p>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiOrgTreeWithPopOver } from "@/components/common/runai-org-tree/with-popover";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import type { ISelectOption } from "@/models/global.model";
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "scope-input",
  components: { RunaiOrgTreeWithPopOver },
  emits: ["clear", "update-selected"],
  props: {
    selectedScope: {
      type: [Object, null] as PropType<null | IOrgTreeNodeId>,
      required: true,
    },
    error: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  methods: {
    requiredScope(val: ISelectOption): boolean | string {
      return !!val || errorMessages.SCOPE_TYPE_NOT_EMPTY;
    },
  },
});
</script>

<style scoped lang="scss">
.tree-button-wrapper {
  align-self: center;
  margin-left: 10px;
}
.tree-button {
  width: 40px;
  height: 40px;
}
.x-button {
  margin-top: 10px;
  color: $black-54;
  width: 40px;
  height: 40px;
}
</style>
