export const INTERVAL_LABELS = {
  REFRESH_TOKEN: "refresh-token",
  IDLE_TIMER: "idle-timer",
  CHECK_CLUSTER_STATUS: "check-cluster-status",
  FETCH_CLUSTERS: "fetch-clusters",
  DEPLOYMENT_TABLE: "deployment-table",
  ADMIN_MESSAGE: "admin-message",
  GRAFANA_LOGIN: "grafana-login",
  CLUSTER_STATUS_BAR: "cluster-status-bar",
};
