// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";

// utils
import { pick } from "@/utils/common.util";

// models
import type { WorkloadHistoryResponse, WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import { allEventHistoryColumns, eventHistoryColumns } from "@/components/runai-event-history-table";
import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import type { IFilterBy, IFilterModel } from "@/models/filter.model";
import type { IJobFilter } from "@/models/filter.model";
// constants
import { K8S_API } from "@/common/api.constant";

export const jobService = {
  workloadHistory,
  getJobs,
  list,
  getWorkloadsCount,
};

async function workloadHistory(
  clusterUuid: string,
  podGroupId: string,
  filterBy: IJobFilter,
): Promise<Array<WorkloadHistoryResponseEntriesInner>> {
  const data: WorkloadHistoryResponse = await controlPlaneService.get(
    `${_jobEndpoint(clusterUuid)}/${podGroupId}/workload-history`,
  );
  let eventsHistory: Array<WorkloadHistoryResponseEntriesInner> = data.entries;

  if (!filterBy) return eventsHistory;

  if (filterBy.searchTerm && filterBy.displayedColumns) {
    eventsHistory = filterService.filterBySearchTerm<WorkloadHistoryResponseEntriesInner>(
      eventsHistory,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allEventHistoryColumns,
    );
  }

  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    eventsHistory = filterService.filterByColumns(
      eventsHistory,
      filterBy.columnFilters,
      eventHistoryColumns,
    ) as Array<WorkloadHistoryResponseEntriesInner>;
  }

  if (filterBy.type) {
    eventsHistory = eventsHistory.filter((evHistory: WorkloadHistoryResponseEntriesInner) => {
      return evHistory.meta.type === filterBy.type;
    });
  }

  return eventsHistory;
}

async function getJobs(clusterUuid: string, queryParams: string[] = []): Promise<Array<DisplayedJob>> {
  const data = await controlPlaneService.get(`${_jobEndpoint(clusterUuid)}?limit=1200&${queryParams.join("&")}`);

  return data as Array<DisplayedJob>;
}
function buildColumnFiltersQueryString(columnFilters?: Array<IFilterModel>, searchTerm?: string): string {
  const filters = columnFilters?.map(({ term, name }) => `${name}:${term}`) || [];
  if (searchTerm) {
    filters.push(`text:${searchTerm}`);
  }

  return filters.length > 0 ? `filter=${filters.join(" ")}` : "filter=jobType:Train|Interactive";
}
function filterJobsQueryToUrlQuery(filterBy: IFilterBy): string {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  const from = ((filters.page || 0) - 1) * (filters.rowsPerPage || 0);
  const params = [
    buildColumnFiltersQueryString(filterBy.columnFilters, filterBy.searchTerm),
    filters.sortBy && `sortBy=${filters.sortBy}&sortDirection=${filterBy.descending ? "desc" : "asc"}`,
    filters.page && `from=${from}`,
    filters.rowsPerPage && `limit=${filters.rowsPerPage}`,
  ].filter(Boolean);

  return params.length > 0 ? params.join("&") : "";
}
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<DisplayedJob>> {
  const params = filterJobsQueryToUrlQuery(filterBy);

  return await controlPlaneService.get(`${_jobEndpoint(clusterUuid)}/?${params}`);
}

// helpers
function _jobEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/jobs`;
}

async function getWorkloadsCount(clusterUuid: string, filterBy: IFilterBy): Promise<{ count: number }> {
  const params: string = buildColumnFiltersQueryString(filterBy.columnFilters, filterBy.searchTerm);

  return controlPlaneService.get(`${_jobEndpoint(clusterUuid)}/count?${params}`);
}
