<template>
  <section class="permormance-modal">
    <runai-base-dialog :model-value="true" @close="onCancel" size="width-md" :no-padding="true">
      <template #header>Enable settings?</template>

      <template #body>
        <div class="q-px-lg q-py-md column">
          <p class="delete-modal-msg q-mt-md">Enable dashboards performance optimization?</p>
          <div class="permormance-modal-info q-mt-lg q-mb-xl">
            <slot name="description">
              Enabling dashboards performance optimization will delete all historical dashboards data. Once the dashboard
              data is deleted, it cannot be restored.
            </slot>
          </div>
        </div>
      </template>

      <template #footer-left> </template>
      <template #footer>
        <q-btn label="Don't enable" color="primary" flat @click="onCancel" aid="performance-modal-cancel-button" />
        <q-btn label="Enable" color="primary" @click="onEnabled" aid="performance-modal-enable-button" />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// Components
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["enable", "cancel"],
  props: {
    entityName: {
      type: String as PropType<string>,
      required: false,
    },
    entityType: {
      type: String as PropType<string>,
      required: false,
    },
    customDescription: {
      type: String as PropType<string>,
      default: null,
    },
    customTitle: {
      type: String as PropType<string>,
      default: null,
    },
    deleting: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    description(): string {
      return this.customDescription
        ? this.customDescription
        : `Once a ${this.entityType} is deleted, it cannot be restored`;
    },
  },
  methods: {
    onEnabled(): void {
      this.$emit("enable");
    },
    onCancel(): void {
      this.$emit("cancel");
    },
  },
});
</script>

<style lang="scss" scoped>
.permormance-modal {
  .delete-modal-msg {
    font-size: 16px;
  }
  .permormance-modal-info {
    font-size: 14px;
  }
}
</style>
