// models
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import type { ITableColumn } from "@/models/table.model";

// services
import { dateUtil } from "@/utils/date.util";

const allEventHistoryColumnsMap = {
  createdAt: {
    name: "createdAt",
    label: "Date & Time",
    field: (row: WorkloadHistoryResponseEntriesInner) => row.meta.creationTimestamp,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  event: {
    name: "event",
    label: "Event",
    field: (row: WorkloadHistoryResponseEntriesInner) =>
      row.meta.type === "statusChange" ? "Status changed" : row.spec.workloadEvent?.reason,
    align: "left",
    display: true,
  },
  eventType: {
    name: "eventType",
    label: "Event type",
    field: (row: WorkloadHistoryResponseEntriesInner) => row.spec.workloadEvent?.type || "",
    align: "left",
    display: true,
  },
  eventIssuer: {
    name: "eventIssuer",
    label: "Event issuer",
    field: (row: WorkloadHistoryResponseEntriesInner) => row.spec.workloadEvent?.sourceComponent || "",
    align: "left",
    display: true,
  },
  component: {
    name: "component",
    label: "Component",
    field: (row: WorkloadHistoryResponseEntriesInner) => row.spec.workloadEvent?.involvedObjKind || "",
    align: "left",
    display: true,
  },
  details: {
    name: "details",
    label: "Details",
    field: (row: WorkloadHistoryResponseEntriesInner) =>
      row.meta.type === "statusChange" ? row.spec.statusChange?.status : row.spec.workloadEvent?.message,
    align: "left",
    display: true,
  },
} as Record<string, ITableColumn>;

export const allEventHistoryColumns: Array<ITableColumn> = [
  allEventHistoryColumnsMap.createdAt,
  allEventHistoryColumnsMap.event,
  allEventHistoryColumnsMap.eventType,
  allEventHistoryColumnsMap.eventIssuer,
  allEventHistoryColumnsMap.component,
  allEventHistoryColumnsMap.details,
];

export const eventHistoryColumns: Array<ITableColumn> = [
  { ...allEventHistoryColumnsMap.createdAt, display: true },
  { ...allEventHistoryColumnsMap.event, display: true },
  { ...allEventHistoryColumnsMap.eventType, display: true },
  { ...allEventHistoryColumnsMap.eventIssuer, display: true },
  { ...allEventHistoryColumnsMap.component, display: true },
  { ...allEventHistoryColumnsMap.details, display: true },
];
