import type { ITableColumn } from "./table.model";
import {
  type AssetRef,
  type Connection,
  type EnvironmentVariable,
  type ImagePullPolicy,
  type InternalConnectionType,
  type ToolType,
  type WorkloadRefAndStatus,
  type EnvironmentAsset,
  type EnvironmentAssetSpecUidGidSourceEnum,
  type Capability,
  Scope,
  type AssetUsageInfo,
  type WorkloadSupportedTypes,
} from "@/swagger-models/assets-service-client";

import { dateUtil } from "@/utils/date.util";
import { toolTypeIconsMap } from "@/common/icons.constant";
import { ECustomCell } from "./table.model";
import type { ISelectOption, IUIAssetCreationMeta } from "./global.model";
import { EWorkloadNodeArchitecture } from "@/models/workload.model";

export interface ISecurityModel {
  uidGidSource?: EnvironmentAssetSpecUidGidSourceEnum;
  overrideUidGidInWorkspace?: boolean;
  runAsUid?: number | null;
  runAsGid?: number | null;
  supplementalGroups?: string | null;
  capabilities?: Array<Capability> | null;
}

export interface IUidGidSupplementalGroupsModel {
  runAsUid?: number | null;
  runAsGid?: number | null;
  supplementalGroups?: string | null;
}

export interface ISecurityOption {
  label: string;
  value: EnvironmentAssetSpecUidGidSourceEnum;
  toolTip: string;
  display: boolean;
}

export interface IImage {
  image: string | null;
  imagePullPolicy: ImagePullPolicy;
}

export interface IRuntimeSettings {
  command: string | null;
  args: string | null;
  workingDir: string | null;
  environmentVariables: Array<EnvironmentVariable>;
}

export interface IUICommandAndArgs {
  command?: string;
  args: string;
}

export interface IUIConnection {
  id: string;
  name: string;
  toolType: ToolType | null;
  connectionType?: InternalConnectionType | null;
  containerPort?: number;
  isCustomUrl?: boolean;
  isCustomPort?: boolean;
  nodePort?: number | null;
  externalUrl?: string | null;
  authorizedUsers?: Array<string> | null;
}

export interface IUIEnvironmentAsset {
  meta: IUIAssetCreationMeta;
  spec: IUIEnvironmentAssetSpec;
}

export interface IUIEnvironmentAssetSpec {
  command: string | null;
  args: string | null;
  environmentVariables: Array<EnvironmentVariable>;
  image: string | null;
  imagePullPolicy: ImagePullPolicy;
  workingDir: string | null;
  connections: Array<IUIConnection>;
  uidGidSource?: EnvironmentAssetSpecUidGidSourceEnum;
  overrideUidGidInWorkspace?: boolean;
  runAsUid?: number | null;
  runAsGid?: number | null;
  supplementalGroups?: string | null;
  capabilities?: Array<Capability> | null;
}

export interface IToolTypeSelectOption extends ISelectOption {
  value: ToolType;
}

export const toolTypeOptions: Array<IToolTypeSelectOption> = [
  {
    label: "Jupyter",
    value: "jupyter-notebook",
    icon: toolTypeIconsMap["jupyter-notebook"],
  },
  {
    label: "MLflow",
    value: "mlflow",
    icon: toolTypeIconsMap.mlflow,
  },
  {
    label: "RStudio",
    value: "rstudio",
    icon: toolTypeIconsMap.rstudio,
  },
  {
    label: "TensorBoard",
    value: "tensorboard",
    icon: toolTypeIconsMap.tensorboard,
  },
  {
    label: "VSCode",
    value: "visual-studio-code",
    icon: toolTypeIconsMap["visual-studio-code"],
  },
  {
    label: "MATLAB",
    value: "matlab",
    icon: toolTypeIconsMap.matlab,
  },
  {
    label: "Weights & Biases",
    value: "wandb",
    icon: toolTypeIconsMap.wandb,
  },
  {
    label: "Comet",
    value: "comet",
    icon: toolTypeIconsMap.comet,
  },
  {
    label: "Custom",
    value: "custom",
    icon: toolTypeIconsMap.custom,
  },
];

export interface IConnectionTypeSelectOption extends ISelectOption {
  value: InternalConnectionType;
}

export const connectionTypeOptions: Array<IConnectionTypeSelectOption> = [
  {
    label: "External URL",
    value: "ExternalUrl",
  },
  {
    label: "NodePort",
    value: "NodePort",
  },
  // { // Commented out to be hidden in the UI temporarily untill load balancer works
  //   label: "Load balancer",
  //   value: "LoadBalancer",
  // },
];

export const allEnvironmentColumnsMap: Record<string, ITableColumn> = {
  environment: {
    name: "environment",
    label: "Environment",
    field: (row: EnvironmentAsset) => row.meta.name,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NAME_COL,
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row: EnvironmentAsset) => row.meta.scope,
    sortable: true,
    align: "left",
    display: true,
    format: (scope: Scope, env: EnvironmentAsset): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(env.meta.projectId);
        case Scope.Department:
          return String(env.meta.departmentId);
        default:
          return String(env.meta.tenantId);
      }
    },
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  image: {
    name: "image",
    label: "Image",
    field: (row: EnvironmentAsset) => row.spec.image,
    sortable: true,
    align: "left",
    display: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: EnvironmentAsset) => row.meta.workloadSupportedTypes?.distributed,
    sortable: true,
    align: "left",
    format: (val: boolean | undefined) =>
      val ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    display: true,
  },
  tools: {
    name: "tools",
    label: "Tool(s)",
    field: (row: EnvironmentAsset) => row.spec.connections,
    sortable: true,
    align: "left",
    display: true,
    format: (connections: Array<Connection>): string[] => {
      if (!connections?.length) return [];
      return connections.map((con) => con.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "tool-clicked" },
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.usedBy,
    sortable: true,
    align: "left",
    format: (usageInfo: AssetUsageInfo | undefined): Array<string> => {
      if (!usageInfo) return [];
      const workspaces: Array<string> = usageInfo.workspaces?.map((ws: WorkloadRefAndStatus) => ws.name) || [];
      const trainings: Array<string> = usageInfo.trainings?.map((training: WorkloadRefAndStatus) => training.name) || [];
      return [...workspaces, ...trainings];
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  workloadTypes: {
    name: "workload-types",
    label: "Workload types",
    field: (row) => row.meta.workloadSupportedTypes,
    sortable: true,
    align: "left",
    format: (workloadTypes: WorkloadSupportedTypes | undefined): string => {
      if (!workloadTypes || (!workloadTypes.workspace && !workloadTypes.training)) return "-";
      const relevantTypes = Object.keys(workloadTypes).filter((key) => key === "workspace" || key === "training");
      const selectedString = relevantTypes.join(", ");
      return selectedString.charAt(0).toUpperCase() + selectedString.slice(1);
    },
  },
  templates: {
    name: "templates",
    label: "Template(s)",
    field: (row: EnvironmentAsset) => row.usedBy?.templates,
    sortable: true,
    align: "left",
    display: false,
    format: (templates: Array<AssetRef>): Array<string> => {
      if (!templates?.length) return [];
      return templates.map((template: AssetRef) => template.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-template-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: EnvironmentAsset) => row.meta.createdBy,
    sortable: true,
    align: "left",
    display: false,
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: EnvironmentAsset) => row.meta.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allEnvironmentColumns: Array<ITableColumn> = [
  allEnvironmentColumnsMap.environment,
  allEnvironmentColumnsMap.scope,
  allEnvironmentColumnsMap.image,
  allEnvironmentColumnsMap.multiNode,
  allEnvironmentColumnsMap.tools,
  allEnvironmentColumnsMap.workloads,
  allEnvironmentColumnsMap.workloadTypes,
  allEnvironmentColumnsMap.templates,
  allEnvironmentColumnsMap.createdBy,
  allEnvironmentColumnsMap.createdAt,
];

export const environmentIndexColumns: Array<ITableColumn> = [
  { ...allEnvironmentColumnsMap.environment, display: true, mandatory: true },
  { ...allEnvironmentColumnsMap.scope, display: true },
  { ...allEnvironmentColumnsMap.image, display: true },
  { ...allEnvironmentColumnsMap.multiNode, display: false },
  { ...allEnvironmentColumnsMap.tools, display: true },
  { ...allEnvironmentColumnsMap.workloads, display: true },
  { ...allEnvironmentColumnsMap.workloadTypes, display: true },
  { ...allEnvironmentColumnsMap.templates, display: false },
  { ...allEnvironmentColumnsMap.createdBy, display: false },
  { ...allEnvironmentColumnsMap.createdAt, display: true },
];

export const allToolTableColumnsMap: Record<string, ITableColumn> = {
  toolName: {
    name: "tool-name",
    label: "Tool name",
    field: (row) => row.name,
    sortable: true,
    align: "left",
  },
  connectionType: {
    name: "connection-type",
    label: "Connection type",
    field: (row) => row.internalToolInfo.connectionType,
    sortable: true,
    align: "left",
  },
};

export const toolTableColumns: Array<ITableColumn> = [
  {
    ...allToolTableColumnsMap.toolName,
    display: true,
  },
  {
    ...allToolTableColumnsMap.connectionType,
    display: true,
  },
];
