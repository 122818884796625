import { defineStore } from "pinia";

// models
import { GpuRequestType, type ComputeAsset, type ComputeUpdateRequest } from "@/swagger-models/assets-service-client";
import { Action, ResourceType, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { IAssetsFilter } from "@/models/filter.model";

// services
import { computeResourceService } from "@/services/control-plane/compute-resource.service/compute-resource.service";
// stores
import { useClusterStore } from "./cluster.store";
import { permissionService } from "@/services/authorization/permission.service/permission.service";

export const useComputeResourceStore = defineStore("ComputeResource", {
  state: () => ({
    computeResources: [] as Array<ComputeAsset>,
    selectedComputeResource: null as ComputeAsset | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    computeResourcesList(): Array<ComputeAsset> {
      const isClusterAmdGpuType: boolean = useClusterStore().isClusterAmdGpuType;
      if (!isClusterAmdGpuType) return this.computeResources;
      return this.computeResources.filter((computeResource: ComputeAsset) => {
        return (
          computeResource.spec.gpuRequestType === GpuRequestType.Portion &&
          Number.isInteger(computeResource.spec.gpuPortion)
        );
      });
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(
        ResourceType.ComputeResources,
      );
    },
    async loadComputeResources(filterBy: IAssetsFilter = {}): Promise<Array<ComputeAsset>> {
      filterBy.clusterId = useClusterStore().currentCluster.uuid;
      this.computeResources = await computeResourceService.list(filterBy);
      return this.computeResources;
    },
    async loadById(computeResourceId: string): Promise<ComputeAsset> {
      let computeResource: ComputeAsset | undefined = this.computeResourcesList.find(
        (env: ComputeAsset) => env.meta.id === computeResourceId,
      );
      if (!computeResource) computeResource = await computeResourceService.getById(computeResourceId);
      this.setSelectedComputeResource(computeResource);
      return computeResource;
    },
    setSelectedComputeResource(computeResource: ComputeAsset | null): void {
      this.selectedComputeResource = computeResource;
    },
    async remove(computeResource: ComputeAsset): Promise<void> {
      await computeResourceService.remove(computeResource.meta.id);
    },
    async update(computeId: string, computeResource: ComputeUpdateRequest): Promise<void> {
      await computeResourceService.update(computeId, computeResource);
    },
  },
});
