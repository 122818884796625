import { defineStore } from "pinia";
const UNKNOWN_RESOURCE = "Unknown";
// Models
import type {
  ChartDataArrayItem,
  ChartDataMapItem,
  DateRange,
  DepartmentsMetricData,
  IAllocationItem,
  IChartArrayItemConfig,
  IChartMapItemConfig,
  IResourceData,
  ITimeRangeChartData,
  MetricsData,
  MetricsFilter,
  TimeRangeData,
} from "@/models/metrics.model";
import { EChartDataType, EDrillDownType } from "@/models/metrics.model";

// Services
import { resourcesService } from "@/services/control-plane/resources/resources.service";
import { dateUtil } from "@/utils/date.util";
import { useSettingStore } from "@/stores/setting.store";
import { isEmpty } from "@/utils/object.util";
import { isEmptyObject } from "@/common/form.validators";

const LOWEST_ITEMS_COUNT = 5;
export const useMetricsStore = defineStore("Metrics", {
  state: () => ({
    // holds the metrics data for the current cluster which comes from the backend
    metricsResponse: [] as Array<MetricsData>,
    timeRange: {} as TimeRangeData,

    // single values for the single value charts
    pendingWorkloads: 0,
    allocatedGpu: 0,
    totalGpu: 0,
    quota: 0,

    // filters from resource dashboard
    filter: {} as MetricsFilter,
    dateRange: {} as DateRange,

    // stack chart data for the resource dashboard
    quotaClusterByNodePoolArray: [] as IChartArrayItemConfig[],
    allocationClusterByNodePoolArray: [] as IChartArrayItemConfig[],
    pendingClusterByNodePoolArray: [] as IChartArrayItemConfig[],
    quotaDepartmentsByNodePoolArray: [] as IChartArrayItemConfig[],
    allocationDepartmentsByNodePoolArray: [] as IChartArrayItemConfig[],
    pendingDepartmentsByNodePoolArray: [] as IChartArrayItemConfig[],

    // multi bar chart data for the resource dashboard
    lowestRatioAllocationDepartmentsArray: [] as IChartArrayItemConfig[],
    lowestRatioAllocationProjectsArray: [] as IChartArrayItemConfig[],
    lowestRatioAllocationProjectsArrayByDepartments: [] as IChartArrayItemConfig[],

    // time series chart data for the resource dashboard
    quotaAllocationTimeSeriesDate: {} as ITimeRangeChartData,
    projectDepartmentMap: {} as Record<string, string>,
  }),
  actions: {
    async loadMetrics(
      clusterUUID: string,
      startDate: string,
      endDate: string,
      isClusterMetrics: boolean,
      metricFilter?: MetricsFilter,
    ): Promise<void> {
      if (startDate && endDate) {
        this.dateRange.startDate = startDate;
        this.dateRange.endDate = endDate;
      }
      this.filter = metricFilter ? metricFilter : {};
      if (!isEmptyObject(this.filter)) {
        await this.loadMetricsWithFilter(clusterUUID, isClusterMetrics);
      } else {
        let clusterResponse: MetricsData = {} as MetricsData;
        let departmentResponse: DepartmentsMetricData = {} as DepartmentsMetricData;
        if (isClusterMetrics) {
          clusterResponse = await resourcesService.getClusterMetrics(
            clusterUUID,
            this.dateRange.startDate,
            this.dateRange.endDate,
          );
        } else {
          departmentResponse = (await resourcesService.getDepartmentsMetrics(
            clusterUUID,
            this.dateRange.startDate,
            this.dateRange.endDate,
          )) as DepartmentsMetricData;
        }
        this.metricsResponse = isClusterMetrics ? [clusterResponse] : departmentResponse.data;
        this.timeRange = clusterResponse.timeRange ? clusterResponse.timeRange : departmentResponse.timeRange;
      }
      this.buildAllData();
    },

    async loadMetricsWithFilter(clusterUUID: string, isClusterMetrics: boolean): Promise<void> {
      let metricsResponse: MetricsData = {} as MetricsData;
      let departmentsMetricsResponse: DepartmentsMetricData = {} as DepartmentsMetricData;
      if (this.filter.project) {
        metricsResponse = await resourcesService.getProjectsMetrics(
          clusterUUID,
          this.dateRange.startDate,
          this.dateRange.endDate,
          this.filter.nodePool,
          this.filter.project,
        );
      } else if (this.filter.department) {
        metricsResponse = (await resourcesService.getDepartmentsMetrics(
          clusterUUID,
          this.dateRange.startDate,
          this.dateRange.endDate,
          this.filter.nodePool,
          this.filter.department,
        )) as MetricsData;
      } else {
        if (isClusterMetrics) {
          // department admin has no access to cluster metrics so we need to call the departments metrics
          metricsResponse = await resourcesService.getClusterMetrics(
            clusterUUID,
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.filter.nodePool,
          );
        } else {
          departmentsMetricsResponse = (await resourcesService.getDepartmentsMetrics(
            clusterUUID,
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.filter.nodePool,
          )) as DepartmentsMetricData;
        }
      }
      this.metricsResponse = !isEmpty(metricsResponse) ? [metricsResponse] : departmentsMetricsResponse.data;
      this.timeRange = !isEmpty(metricsResponse) ? metricsResponse.timeRange : departmentsMetricsResponse.timeRange;
    },

    buildSingleValues(): void {
      this.pendingWorkloads = 0;
      this.allocatedGpu = 0;
      this.totalGpu = 0;
      this.quota = 0;
      // Filter the project resources based on the provided filter
      this.metricsResponse.forEach((metricData: MetricsData) => {
        this.totalGpu += metricData.current.resources.reduce(
          (acc: number, resource: IResourceData) => acc + resource.gpu.quota,
          0,
        );
        const resourceData: IResourceData[] = metricData.current.projectResources
          ? metricData.current.projectResources
          : metricData.current.resources;
        resourceData.forEach((resourceData: IResourceData) => {
          const departmentId = resourceData.departmentId
            ? metricData.metadata.departmentId
            : metricData.metadata.departmentId || UNKNOWN_RESOURCE;
          const projectId = resourceData.projectId
            ? metricData.metadata.projectId
            : metricData.metadata.projectId || UNKNOWN_RESOURCE;

          if (
            (resourceData.nodepoolName === this.filter.nodePool || !this.filter.nodePool) &&
            (String(projectId) === this.filter.project || !this.filter.project) &&
            (String(departmentId) === this.filter.department || !this.filter.department)
          ) {
            this.pendingWorkloads += resourceData.numberOfPendingWorkloads;
            this.allocatedGpu += resourceData.gpu.allocated;
            this.quota += resourceData.gpu.quota;
          }
        });
      });
    },
    resetDataState(): void {
      this.quotaClusterByNodePoolArray = [];
      this.allocationClusterByNodePoolArray = [];
      this.pendingClusterByNodePoolArray = [];
      this.quotaDepartmentsByNodePoolArray = [];
      this.allocationDepartmentsByNodePoolArray = [];
      this.pendingDepartmentsByNodePoolArray = [];

      // multi bar chart data for the resource dashboard
      this.lowestRatioAllocationDepartmentsArray = [];
      this.lowestRatioAllocationProjectsArray = [];
      this.lowestRatioAllocationProjectsArrayByDepartments = [];

      this.quotaAllocationTimeSeriesDate = {
        categories: [] as string[],
        columnSeries: {
          title: "Allocation",
          data: [] as Array<number>,
          tooltipTitle: "GPU allocation",
        },
        splineSeries: {
          title: "Quota",
          data: [] as Array<number>,
          tooltipTitle: "GPU quota",
        },
      };
    },
    buildClusterDataForResourceDashboard(): void {
      const isDepartmentEnabled = useSettingStore().isDepartmentEnabled;
      const clusterByNodePoolMap: Record<string, IChartMapItemConfig> = {};
      const departmentsByNodePoolMap: Record<string, IChartMapItemConfig> = {};
      const projectsByNodePoolAndDepartmentsMap: Record<string, IChartMapItemConfig> = {};
      const projectsByNodePoolsMap: Record<string, IChartMapItemConfig> = {};

      const metricsResponse = this.metricsResponse;
      for (const departmentData of metricsResponse) {
        const clusterName = departmentData.metadata.clusterName;

        const resources: IResourceData[] = departmentData.current.projectResources
          ? departmentData.current.projectResources
          : departmentData.current.resources;

        for (const projectResource of resources) {
          const departmentName: string = projectResource.departmentName
            ? projectResource.departmentName
            : departmentData.metadata.departmentName || UNKNOWN_RESOURCE;
          const projectName: string = projectResource.projectName
            ? projectResource.projectName
            : departmentData.metadata.projectName || UNKNOWN_RESOURCE;

          const nodepoolName = projectResource.nodepoolName;

          this.buildResourcesByNodePoolMap(
            clusterByNodePoolMap,
            nodepoolName,
            clusterName,
            this.getDrilldownId(isDepartmentEnabled, EDrillDownType.Clusters, clusterName, nodepoolName, departmentName),
            "",
            projectResource,
          );

          this.buildResourcesByNodePoolMap(
            departmentsByNodePoolMap,
            nodepoolName,
            isDepartmentEnabled ? departmentName : (projectName as string),
            isDepartmentEnabled ? `projects-${nodepoolName}` : "",
            this.getDrilldownId(
              isDepartmentEnabled,
              EDrillDownType.Departments,
              clusterName,
              nodepoolName,
              departmentName,
            ),
            projectResource,
          );
          this.buildResourcesByNodePoolMap(
            projectsByNodePoolAndDepartmentsMap,
            nodepoolName + "-" + isDepartmentEnabled ? nodepoolName + "-" + departmentName : projectName,
            projectName,
            "",
            this.getDrilldownId(isDepartmentEnabled, EDrillDownType.Projects, clusterName, nodepoolName, departmentName),
            projectResource,
          );
          this.buildResourcesByNodePoolMap(
            projectsByNodePoolsMap,
            nodepoolName,
            projectName,
            "",
            this.getDrilldownId(isDepartmentEnabled, EDrillDownType.Projects, clusterName, nodepoolName, departmentName),
            projectResource,
          );
        }
      }

      // Convert the resource map to an array so the charts can be built from it
      this.buildResourcesArrays(clusterByNodePoolMap, [
        this.quotaClusterByNodePoolArray,
        this.allocationClusterByNodePoolArray,
        this.pendingClusterByNodePoolArray,
      ]);
      this.buildResourcesArrays(departmentsByNodePoolMap, [
        this.quotaDepartmentsByNodePoolArray,
        this.allocationDepartmentsByNodePoolArray,
        this.pendingDepartmentsByNodePoolArray,
      ]);
      this.buildResourcesArrays(projectsByNodePoolAndDepartmentsMap, [
        this.quotaDepartmentsByNodePoolArray,
        this.allocationDepartmentsByNodePoolArray,
        this.pendingDepartmentsByNodePoolArray,
      ]);

      // Convert the departments resource map to the lowest allocation array
      this.buildLowestAllocationArrays(departmentsByNodePoolMap, this.lowestRatioAllocationDepartmentsArray, true);

      this.buildLowestAllocationArrays(
        projectsByNodePoolAndDepartmentsMap,
        this.lowestRatioAllocationProjectsArrayByDepartments,
      );

      this.buildLowestAllocationArrays(projectsByNodePoolsMap, this.lowestRatioAllocationProjectsArray, true);
    },

    buildResourcesArrays(
      resourcesMap: Record<string, IChartMapItemConfig>,
      chartArray: Array<IChartArrayItemConfig[]>,
    ): void {
      this.convertResourceMapToChartArrayData(resourcesMap, chartArray[0], EChartDataType.QUOTA);
      this.convertResourceMapToChartArrayData(resourcesMap, chartArray[1], EChartDataType.ALLOCATED);
      this.convertResourceMapToChartArrayData(resourcesMap, chartArray[2], EChartDataType.PENDING);
    },

    buildLowestAllocationArrays(
      resourceMap: Record<string, IChartMapItemConfig>,
      chartArray: IChartArrayItemConfig[],
      shouldSortLowestRatio = false,
    ): void {
      const allDataItems: ChartDataArrayItem[] = [];

      Object.entries(resourceMap).forEach(([, value]) => {
        const dataItems: ChartDataArrayItem[] = [];

        Object.entries(value.data).forEach(([, dataValue]) => {
          if (dataValue.quotaY !== 0) {
            const allocationPercentage = (dataValue.allocatedY / dataValue.quotaY) * 100;
            const roundedPercentage = Math.round(allocationPercentage * 100) / 100;
            const item = {
              name: dataValue.name,
              y: roundedPercentage,
              optionValues: [dataValue.allocatedY, dataValue.quotaY],
              drilldown: dataValue.drilldown,
            };
            dataItems.push(item);
            allDataItems.push(item);
          }
        });

        chartArray.push({
          id: value.id,
          name: value.name.split("-")[0],
          data: dataItems,
        });
      });

      if (shouldSortLowestRatio) {
        const lowestRatioDataItemsArray: string[] = this.findLowestRatioNames(allDataItems);
        const lowestRatioDataItemsSet: Set<string> = new Set(this.findLowestRatioNames(allDataItems));
        // Remove items from chartArray that are not in lowestRatioDataItems
        chartArray.forEach((chart) => {
          chart.data = chart.data.filter((dataItem: ChartDataArrayItem) => lowestRatioDataItemsSet.has(dataItem.name));
          chart.data.sort(
            (a, b) => lowestRatioDataItemsArray.indexOf(a.name) - lowestRatioDataItemsArray.indexOf(b.name),
          );
        });
      }
    },

    getDrilldownId(
      isDepartmentEnabled: boolean,
      drillDownType: EDrillDownType,
      cluster: string,
      nodepool: string,
      department: string,
    ): string {
      switch (drillDownType) {
        case EDrillDownType.Clusters:
          return isDepartmentEnabled ? `departments-${nodepool}` : `projects-${nodepool}`;
        case EDrillDownType.Departments:
          return isDepartmentEnabled ? `departments-${nodepool}-${cluster}` : `projects-${nodepool}-${cluster}`;
        case EDrillDownType.Projects:
          return isDepartmentEnabled ? `projects-${nodepool}-${department}` : `projects-${nodepool}`;
      }
    },
    findLowestRatioNames(data: ChartDataArrayItem[]): string[] {
      const lowestValueMap: Map<string, IAllocationItem> = new Map();

      for (const item of data) {
        const name: string = item.name;

        let currentValue: IAllocationItem = lowestValueMap.get(name) as IAllocationItem;
        if (lowestValueMap.has(name)) {
          currentValue.quota += item.optionValues ? item.optionValues[1] : 0;
          currentValue.allocation += item.optionValues ? item.optionValues[0] : 0;
        } else {
          currentValue = { quota: 0, allocation: 0 };
          currentValue.quota = item.optionValues ? item.optionValues[1] : 0;
          currentValue.allocation = item.optionValues ? item.optionValues[0] : 0;
        }
        lowestValueMap.set(name, currentValue);
      }

      const sortedEntries: [string, IAllocationItem][] = Array.from(lowestValueMap.entries()).sort(
        (a, b) => a[1].allocation / a[1].quota - b[1].allocation / b[1].quota,
      );
      return sortedEntries.slice(0, LOWEST_ITEMS_COUNT).map((entry) => entry[0]);
    },

    buildResourcesByNodePoolMap(
      resourceMap: Record<string, IChartMapItemConfig>,
      resourceKey: string,
      resourceName: string,
      drilldownTo: string,
      drilldownId: string,
      projectResource: ProjectResourceData,
    ): void {
      if (!resourceMap[resourceKey]) {
        resourceMap[resourceKey] = {
          id: drilldownId,
          name: resourceKey,
          data: {},
        };
      }
      if (!resourceMap[resourceKey].data[resourceName]) {
        resourceMap[resourceKey].data[resourceName] = {
          name: resourceName,
          quotaY: projectResource.gpu.quota,
          allocatedY: projectResource.gpu.allocated,
          pendingY: projectResource.numberOfPendingWorkloads,
          drilldown: drilldownTo ? `${drilldownTo}-${resourceName}` : "",
        };
      } else {
        resourceMap[resourceKey].data[resourceName].quotaY += projectResource.gpu.quota;
        resourceMap[resourceKey].data[resourceName].allocatedY += projectResource.gpu.allocated;
        resourceMap[resourceKey].data[resourceName].pendingY += projectResource.numberOfPendingWorkloads;
      }
    },

    convertResourceMapToChartArrayData(
      resourceMap: Record<string, IChartMapItemConfig>,
      chartArray: IChartArrayItemConfig[],
      chartDataType: EChartDataType,
    ): void {
      for (const nodepoolName in resourceMap) {
        const nodePoolData = resourceMap[nodepoolName];
        const data: Array<ChartDataMapItem> = Object.values(nodePoolData.data);

        const item: IChartArrayItemConfig = {
          id: nodePoolData.id,
          name: nodePoolData.name.split("-")[0],
          data: data.map((entry: ChartDataMapItem) => ({
            name: entry.name,
            y:
              chartDataType === EChartDataType.QUOTA
                ? entry.quotaY
                : chartDataType === EChartDataType.ALLOCATED
                ? entry.allocatedY
                : entry.pendingY,
            drilldown: entry.drilldown,
          })),
        };

        chartArray.push(item);
      }
    },
    buildTimeSeriesData(): void {
      this.timeRange.resources.forEach((resourceData: IResourceData) => {
        this.quotaAllocationTimeSeriesDate.columnSeries.data.push(resourceData.gpu.allocated);
        this.quotaAllocationTimeSeriesDate.splineSeries.data.push(resourceData.gpu.quota);
        const formatDate = dateUtil.dateFormat(new Date(resourceData.timestamp as string), "dd/MM HH:mm");
        this.quotaAllocationTimeSeriesDate.categories.push(formatDate);
      });
    },

    buildAllData(): void {
      this.resetDataState();
      // top level data
      this.buildSingleValues();
      // stack chart data and multi bar chart data
      this.buildClusterDataForResourceDashboard();
      // time series chart data
      this.buildTimeSeriesData();
    },
  },
});
