import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IConfig } from "@/models/config.model";

import { K8S_API } from "@/common/api.constant";

export const configService = {
  get,
  getSassEnvList,
  getCustomerEnvList,
  isSaas,
  isStagingOrTestOrLocalEnv,
  isLocalEnv,
  isTestEnv,
  isStagingEnv,
};
const ENDPOINT = `${K8S_API.v1}/config`;

// api calls
async function get(hostname: string): Promise<IConfig> {
  const env = _getEnvByHostname(hostname);
  const tenantName = _getTenantNameFromHostname(hostname);

  const queryParams: Record<string, string> = {};
  if (tenantName) {
    queryParams.tenantName = tenantName;
  }
  const config = await controlPlaneService.get(ENDPOINT, queryParams);
  return { ...config, env };
}

// helpers
export enum EEnvTypes {
  Local = "local",
  OnPremise = "on-premise",
  Staging = "staging",
  Test = "test",
  CustomerTest = "customer-test",
  CustomerStaging = "customer-staging",
  CustomerCloud = "customer-cloud",
  Cloud = "cloud",
  Unknown = "unknown",
}

function getSassEnvList(): Array<string> {
  return [
    EEnvTypes.CustomerCloud,
    EEnvTypes.Cloud,
    EEnvTypes.CustomerStaging,
    EEnvTypes.Staging,
    EEnvTypes.CustomerTest,
    EEnvTypes.Test,
  ];
}

function getCustomerEnvList(): Array<string> {
  return [EEnvTypes.CustomerCloud, EEnvTypes.CustomerStaging, EEnvTypes.CustomerTest];
}

function isSaas(hostname: string): boolean {
  return getSassEnvList().includes(_getEnvByHostname(hostname));
}

function _getEnvByHostname(hostname: string = window.location.hostname): string {
  if (hostname.startsWith("localhost")) {
    return EEnvTypes.Local;
  }
  if (!hostname.endsWith(".run.ai")) {
    return EEnvTypes.OnPremise;
  }
  if (hostname == "test.run.ai") {
    return EEnvTypes.Test;
  }
  if (hostname.endsWith(".test.run.ai")) {
    return EEnvTypes.CustomerTest;
  }
  if (hostname.endsWith(".staging.run.ai")) {
    return EEnvTypes.CustomerStaging;
  }
  if (hostname == "staging.run.ai") {
    return EEnvTypes.Staging;
  }
  if (hostname == "app.run.ai") {
    return EEnvTypes.Cloud;
  }
  if (hostname.endsWith(".run.ai")) {
    return EEnvTypes.CustomerCloud;
  }
  return EEnvTypes.Unknown;
}

function _getTenantNameFromHostname(hostname: string): string {
  let tenantName = "";
  const customerEnvList = getCustomerEnvList();
  if (customerEnvList.includes(hostname)) {
    tenantName = location.hostname.split(".")[0];
  }
  return tenantName;
}

function isStagingEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Staging || _getEnvByHostname() === EEnvTypes.CustomerStaging;
}

function isTestEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Test || _getEnvByHostname() === EEnvTypes.CustomerTest;
}

function isLocalEnv(): boolean {
  return _getEnvByHostname() === EEnvTypes.Local;
}

function isStagingOrTestOrLocalEnv(): boolean {
  return isStagingEnv() || isTestEnv() || isLocalEnv();
}
