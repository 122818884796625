export enum CHART_COLOR_PALETTE {
  // Don't change the order of the colors!!!
  // The order of the colors is important for the chart it will be consistent and look nice.
  ELECTRIC_SKY_BLUE = "#0BB4FF",
  MIDNIGHT_INDIGO = "#372FB5",
  LIVELY_LIME = "#50E991",
  CORAL_ORANGE = "#FF8552",
  STORMY_LAVENDER = "#6075AF",
  GOLDEN_APRICOT = "#EBAD5D",
  ROYAL_PURPLE = "#B048F3",
  AQUAMARINE = "#55D6BE",
  MAGENTA_BERRY = "#D336B9",
}

export enum SINGLE_STAT_COLOR_PALETTE {
  GREEN = "#4CAF50",
  RED = "#EF5350",
  YELLOW = "#FFAB00",
}
export const chartColorPaletteArray = Object.entries(CHART_COLOR_PALETTE).map(([, value]) => value);

export interface IChartData {
  name: string;
  y: number;
  drilldown?: string;
}

export enum EChartType {
  COLUMN = "column",
  STACKED_COLUMN = "stacked_column",
  MULTI_BAR = "multi_bar",
}

export interface IChartSeries {
  name: string;
  data: Array<IChartData>;
}

export interface IDrilldownSeries {
  id: string;
  data: [string, number][];
}

export interface IColumnChartSeries {
  title: string;
  data: number[];
  tooltipTitle?: string;
}

export interface IColorConfigurationItem {
  from: number;
  to: number;
  color: SINGLE_STAT_COLOR_PALETTE;
}
