<template>
  <div class="toggle-setting row items-center">
    <div class="col-4 items-center">
      <div class="row">
        <div class="col-10">
          <span class="q-mr-md text-subtitle1">{{ setting.label }}</span>
        </div>
        <div class="col-2">
          <q-toggle
            :model-value="Boolean(setting.value)"
            @update:model-value="updateSettings(setting.key, $event)"
            :disable="disabled"
          >
            <q-tooltip v-if="tooltipMessage" anchor="bottom end">{{ tooltipMessage }}</q-tooltip>
          </q-toggle>
        </div>
      </div>
    </div>
    <q-separator vertical class="q-mx-lg" />
    <div class="col-4">
      <div class="description" v-html="setting.description"></div>
      <runai-information-bar v-if="warningMessage" class="q-mt-md" type="warning">
        {{ warningMessage }}
      </runai-information-bar>
    </div>
    <div class="col-3 toggle-item">
      <q-badge
        v-if="hasBadge"
        class="badge"
        outline
        align="middle"
        :class="{ legacy: !isExperimental, experimental: isExperimental }"
      >
        {{ badgeText }}
      </q-badge>
    </div>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { Setting } from "@/swagger-models/backend-client";
import type { SettingKeys } from "@/models/setting.model";
// cmps
import RunaiInformationBar from "@/components/common/runai-information-bar/runai-information-bar.vue";
// utils
import { alertUtil } from "@/utils/alert.util";
//stores
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  name: "toggle-setting",
  components: { RunaiInformationBar },
  emits: ["update-setting"],
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: "",
    },
    emitOnly: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    settingStore: useSettingStore(),
  }),
  computed: {
    hasBadge(): boolean {
      return this.setting.stage !== undefined;
    },
    isExperimental(): boolean {
      return this.setting.stage === "Alpha";
    },
    badgeText(): string {
      return this.isExperimental ? "Experimental" : "Legacy";
    },
  },
  methods: {
    async updateSettings(key: string | undefined, value: string | number | boolean | null): Promise<void> {
      if (value === null || key === undefined) return;
      if (!this.emitOnly) {
        try {
          await this.settingStore.updateSetting(key as SettingKeys, value);
          this.$q.notify(alertUtil.getSuccess("Setting updated successfully"));
        } catch (err) {
          this.$q.notify(alertUtil.getError("Failed to update setting"));
        }
      }
      this.$emit("update-setting", key, value);
    },
  },
});
</script>
<style lang="scss" scoped>
.badge {
  font-size: 12px;
  width: 90px;
}
.legacy {
  color: $black-54 !important;
  padding: 1px 24px;
}
.experimental {
  color: $lighter-green !important;
  padding: 1px 10px;
}
.toggle-item {
  text-align: center;
}
.description {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}
</style>
