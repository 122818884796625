import type { Setting } from "@/swagger-models/backend-client";

export const enum SettingKeys {
  DashboardShowOld = "dashboard.show_old",
  DashboardOptimization = "dashboard.optimized_analytics_queries",
  DepartmentsUse = "departments.use",
  ProjectPermissionUse = "project.permissions.use",
  ProjectManualOverQuota = "project.quota.manual_over_quota",
  CPUResourcesQuota = "project.quota.cpu_resources",
  LoginWithSSO = "tenant.login_with_sso",
  AllowSwap = "tenant.allow_swap",
  NodePoolsFlag = "tenant.enable_node_pools",
  RegistryIntegration = "tenant.enable_registry_integration",
  ConsumptionReport = "tenant.show_consumption_report",
  ResourcesCost = "tenant.resources_cost",
  AdvancedGPUMetrics = "tenant.enable_advanced_gpu_metrics",
  EnableWandbSweeps = "tenant.enable_wandb_sweeps",
  EnableLegacyMpi = "tenant.enable_legacy_mpi",
  WandbHost = "tenant.wandb_host",
  EnableInactivityLogout = "tenant.enable_inactivity_logout",
  InactivityMinutesTillLogout = "tenant.inactivity_minutes_till_logout",
  ExcludeViewerInactivityLogout = "tenant.exclude_viewers_from_inactivity_logout",
  EnableCpuDashboards = "tenant.enable_cpu_dashboards",
  EnableJobSubmitForm = "tenant.enable_job_submit_form",
  LimitQuotaOverSubscription = "tenant.limit_quota_over_subscription",
  LogoutURI = "tenant.logout_uri",
  InactivityMinuetsTillLogout = "tenant.inactivity_minutes_till_logout",
  EnablePolicies = "tenant.enable_policies",
  EnableModelCatalog = "tenant.enable_model_catalog",
  AdminMessage = "tenant.admin_message",
  GpuOverProvisioning = "tenant.gpu_over_provisioning",
}

export interface IResourcesCost {
  gpu: number | null;
  cpu: number | null;
  memory: number | null;
}

export interface IAdminMessage {
  msg: string;
  id: string;
  createdAt: Date;
  enableDismiss: boolean;
}

export type TSettingValue = boolean | string | number | IResourcesCost | IAdminMessage;

export interface IAdminMessageSetting extends Omit<Setting, "value"> {
  value: IAdminMessage;
}

export interface AnalyticsSectionSettings {
  cpu: Setting;
  consumption: Setting;
  advancedGpuMetrics: Setting;
  optimizationEnabled: Setting;
}
