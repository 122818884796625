import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";

import type { IAssetsFilter } from "@/models/filter.model";

import { API } from "@/common/api.constant";
import { makeId, pick } from "@/utils/common.util";
import {
  ToolType,
  InternalConnectionType,
  ImagePullPolicy,
  Scope,
  type Connection,
  type EnvironmentAsset,
  type EnvironmentCreationRequest,
  type EnvironmentVariable,
  type InternalToolInfo,
  type AssetCreationRequest,
  type EnvironmentAssetSpec,
  EnvironmentAssetSpecUidGidSourceEnum,
  type EnvironmentUpdateRequest,
} from "@/swagger-models/assets-service-client";
import { allEnvironmentColumns, type IUIConnection, type IUIEnvironmentAsset } from "@/models/environment.model";
import {
  isFirstLetterIsLowerCase,
  isLowerCaseAndNumbers,
  isNotEmpty,
  isValidDirectoryPath,
  validCommandAndArgsLine,
} from "@/common/form.validators";

export const environmentService = {
  list,
  getEnvironmentModel,
  getConnectionModel,
  save,
  remove,
  update,
  validateEnvironment,
  validateName,
  validateImage,
  validateConnections,
  validateUIConnections,
  validateRuntimeSettings,
  getById,
  prepareConnectionForRequest,
  prepareConnectionForUI,
};

const endpoint = `${API.asset}/environment`;

// api calls
async function list(filterBy: IAssetsFilter = {}): Promise<Array<EnvironmentAsset>> {
  const filters: IAssetsFilter = pick(
    filterBy,
    "sortBy",
    "page",
    "rowsPerPage",
    "projectId",
    "departmentId",
    "scope",
    "usageInfo",
    "complyToProject",
    "complyToWorkloadType",
    "isWorkspace",
    "isTraining",
    "isDistributed",
    "distributedFramework",
    "clusterId",
  );
  let environments: Array<EnvironmentAsset> = await controlPlaneService
    .get(endpoint, filters)
    .then((res) => res.entries);

  if (!filterBy || !filterBy.displayedColumns) return environments;

  if (filterBy.searchTerm) {
    environments = filterService.filterBySearchTerm<EnvironmentAsset>(
      environments,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allEnvironmentColumns,
    );
  }

  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    environments = filterService.filterByColumns(environments, filterBy.columnFilters, allEnvironmentColumns);
  }

  return environments;
}

async function getById(environmentId: string): Promise<EnvironmentAsset> {
  return controlPlaneService.get(`${endpoint}/${environmentId}`, { usageInfo: true });
}

async function save(environment: EnvironmentCreationRequest): Promise<EnvironmentAsset> {
  const savedEnvironment = await controlPlaneService.post(endpoint, environment);
  return savedEnvironment;
}

async function update(environmentId: string, environment: EnvironmentUpdateRequest): Promise<EnvironmentAsset> {
  const updatedEnvironment = await controlPlaneService.put(`${endpoint}/${environmentId}`, environment);
  return updatedEnvironment;
}

async function remove(environmentId: string): Promise<void> {
  return controlPlaneService.delete(`${endpoint}/${environmentId}`);
}

function getConnectionModel(): IUIConnection {
  return {
    id: makeId(),
    name: "",
    toolType: null,
    connectionType: InternalConnectionType.ExternalUrl,
    containerPort: 0,
    isCustomUrl: false,
  };
}

function getEnvironmentModel(isSSO: boolean): IUIEnvironmentAsset {
  return {
    meta: {
      name: "",
      scope: null,
      projectId: null,
      workloadSupportedTypes: {
        workspace: true,
        training: true,
      },
    },
    spec: {
      command: null,
      args: null,
      environmentVariables: [],
      image: "",
      imagePullPolicy: ImagePullPolicy.IfNotPresent,
      workingDir: null,
      connections: [],
      uidGidSource: isSSO
        ? EnvironmentAssetSpecUidGidSourceEnum.FromIdpToken
        : EnvironmentAssetSpecUidGidSourceEnum.FromTheImage,
      overrideUidGidInWorkspace: isSSO ? true : false,
      capabilities: [],
    },
  };
}

function validateEnvironment(environment: EnvironmentCreationRequest): boolean {
  const { meta, spec }: { meta: AssetCreationRequest; spec: EnvironmentAssetSpec } = environment;
  if (!validateScope(meta.scope, meta.projectId, meta.departmentId)) return false;
  if (!validateName(meta.name)) return false;
  if (!validateImage(spec.image || "")) return false;
  if (!validateConnections(spec.connections)) return false;
  if (
    !validateRuntimeSettings(
      spec.command,
      spec.args,
      spec.environmentVariables as Array<EnvironmentVariable>,
      spec.workingDir,
    )
  )
    return false;
  return true;
}
function validateScope(
  scope: Scope,
  projectId: number | null | undefined,
  departmentId: string | null | undefined,
): boolean {
  if (scope === Scope.Project) return !!projectId;
  else if (scope === Scope.Department) return !!departmentId;
  else if (scope === Scope.Tenant) return !projectId && !departmentId;
  return false;
}
function validateName(name: string): boolean {
  // TODO :: Add api uniqueness validation.
  return isNotEmpty(name) && isFirstLetterIsLowerCase(name) && isLowerCaseAndNumbers(name);
}
function validateImage(image: string): boolean {
  // TODO :: add image regex
  return isNotEmpty(image);
}
function validateConnections(connections: Array<Connection> = []): boolean {
  return connections.every((tool: Connection) => {
    if (tool.isExternal) {
      return tool.name && tool.externalToolInfo?.externalUrl;
    } else {
      const { containerPort, connectionType, toolType } = tool.internalToolInfo as InternalToolInfo;
      return tool.name && containerPort && connectionType && toolType;
    }
  });
}
function validateUIConnections(connections: Array<IUIConnection> = []): boolean {
  return connections.every((tool: IUIConnection) => {
    return tool.containerPort + "" && tool.name && tool.toolType;
  });
}

function validateRuntimeSettings(
  command: string | null = null,
  args: string | null = null,
  envVariables: Array<EnvironmentVariable> = [],
  workingDir: string | null = null,
): boolean {
  const isValidDirPath: boolean = workingDir === null || workingDir.length === 0 || isValidDirectoryPath(workingDir);
  const isValidEnvVar: boolean = envVariables.every((envVar: EnvironmentVariable) => isNotEmpty(envVar.name));
  return validCommandAndArgsLine(command) && validCommandAndArgsLine(args) && isValidEnvVar && isValidDirPath;
}

function prepareConnectionForRequest(connection: IUIConnection): Connection {
  const { name, toolType, connectionType, containerPort, isCustomPort, isCustomUrl, externalUrl } = connection;
  if (toolType === ToolType.Wandb || toolType === ToolType.Comet) {
    if (!name || !externalUrl) {
      throw new Error("Some of the fields are missing");
    }

    return {
      name,
      isExternal: true,
      externalToolInfo: {
        toolType,
        externalUrl: externalUrl,
      },
    };
  } else {
    const internalTool: Connection = {
      name,
      isExternal: false,
      internalToolInfo: {
        toolType: toolType,
        connectionType: connectionType,
        containerPort,
      } as InternalToolInfo,
    };
    if (connectionType === InternalConnectionType.ExternalUrl) {
      internalTool.internalToolInfo = {
        ...internalTool.internalToolInfo,
        externalUrlInfo: {
          isCustomUrl,
          externalUrl: externalUrl || null,
        },
      } as InternalToolInfo;
    } else if (connectionType === InternalConnectionType.NodePort) {
      internalTool.internalToolInfo = {
        ...internalTool.internalToolInfo,
        nodePortInfo: {
          isCustomPort,
        },
      } as InternalToolInfo;
    }
    return internalTool;
  }
}

function prepareConnectionForUI(connection: Connection): IUIConnection | void {
  if (connection.isExternal) {
    return {
      id: makeId(),
      name: connection.name,
      toolType: connection.externalToolInfo?.toolType || ToolType.Wandb,
      externalUrl: connection.externalToolInfo?.externalUrl || null,
    };
  }
  if (connection.internalToolInfo) {
    const { connectionType, containerPort, toolType, externalUrlInfo, nodePortInfo } = connection.internalToolInfo;
    return {
      id: makeId(),
      name: connection.name,
      connectionType: connectionType,
      containerPort: containerPort,
      toolType: toolType,
      isCustomUrl: externalUrlInfo?.isCustomUrl,
      isCustomPort: nodePortInfo?.isCustomPort,
    };
  }
}
