<template>
  <runai-form-wrapper :form-state="formData" ref="elProjectEditCreateForm">
    <runai-expansion-wrapper>
      <department-select-section
        v-if="isDepartmentEnabled && formData.departmentId"
        :selected-department-id="formData.departmentId"
        @update-selected-department-id="updateDepartmentId"
        @is-section-invalid="invalidSection.departmentSelect = !$event"
      />
      <project-name-section
        v-model:project-name="formData.name"
        v-model:namespace="formData.namespace"
        @is-section-invalid="invalidSection.projectName = !$event"
      />
      <quota-management-section
        v-model:node-pools-resources="formData.nodePoolsResources"
        v-model:node-pools-priorities="formData.defaultNodePools"
        :department-id="departmentId"
      />
      <scheduling-rules-section
        v-model:training-duration="formData.trainingJobMaxIdleDurationSecs"
        v-model:interactive-duration="formData.interactiveJobMaxIdleDurationSecs"
        v-model:interactive-preemptible-duration="formData.interactivePreemptibleJobMaxIdleDurationSecs"
        v-model:interactive-time-limit="formData.interactiveJobTimeLimitSecs"
        v-model:training-time-limit="formData.trainingJobTimeLimitSecs"
        v-model:node-affinity="formData.nodeAffinity"
        :training-time-limit-supported="isNewClusterVersion"
        @is-section-invalid="invalidSection.schedulingRules = !$event"
      />
      <project-form-footer-section
        :loading="isProjectSubmitting"
        :is-form-valid="isFormValid"
        @on-cancel="$emit('on-cancel')"
        @on-save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { SchedulingRulesSection } from "@/components/project/project-edit-form/scheduling-rules-section/";
import { DepartmentSelectSection } from "@/components/project/project-edit-form/department-select-section/";
import { ProjectNameSection } from "@/components/project/project-edit-form/project-name-section";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { ProjectFormFooterSection } from "@/components/project/project-edit-form/project-form-footer-section";
// services
import { deepCopy } from "@/utils/common.util";
import { isNewerVersion } from "@/utils/version.util";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import type { IProject, IProjectCreate } from "@/models/project.model";
import { useClusterStore } from "@/stores/cluster.store";
import { MIN_TRAIN_TIME_LIMIT_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
interface ISectionValidation {
  departmentSelect: boolean;
  projectName: boolean;
  schedulingRules: boolean;
}
export default defineComponent({
  components: {
    SchedulingRulesSection,
    ProjectFormFooterSection,
    DepartmentSelectSection,
    ProjectNameSection,
    QuotaManagementSection,
    RunaiFormWrapper,
    RunaiExpansionWrapper,
  },
  emits: ["on-save", "on-cancel"],
  props: {
    project: {
      type: Object as PropType<IProject | IProjectCreate>,
      required: true,
    },
    isProjectSubmitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      formData: deepCopy(this.project) as IProject,
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      isFormValid: true as boolean,
      invalidSection: {
        departmentSelect: false as boolean,
        projectName: false as boolean,
        schedulingRules: false as boolean,
      } as ISectionValidation,
    };
  },
  created() {
    if (!this.isDepartmentEnabled) {
      this.invalidSection.departmentSelect = true;
    }
  },
  computed: {
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    departmentId(): number | undefined {
      return this.formData.departmentId;
    },
    isNewClusterVersion(): boolean {
      const version = this.clusterStore.currentCluster.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_TRAIN_TIME_LIMIT_VERSION) || version.includes(TEST_ENV_VERSION);
    },
  },
  methods: {
    updateDepartmentId(id: number): void {
      this.formData.departmentId = id;
    },
    async save(): Promise<void> {
      const isValidationSuccess: boolean = await (this.$refs.elProjectEditCreateForm as HTMLFormElement).validate();
      this.isFormValid = isValidationSuccess;
      if (isValidationSuccess) {
        this.$emit("on-save", this.formData);
      }
    },
  },
  watch: {
    formData: {
      handler() {
        this.isFormValid = true;
      },
      deep: true,
    },
  },
});
</script>
