<template>
  <section class="researcher-authentication">
    <q-linear-progress animation-speed="100" indeterminate v-if="loading" />
    <template v-else>
      <client-config v-if="clientConfigInfo" :client-config="clientConfigInfo" />
      <server-config v-if="serverConfigInfo" :server-config="serverConfigInfo" />

      <div v-if="realm" class="text-subtitle1">Realm: <runai-code-block :code-html="realm" /></div>
      <div class="text-subtitle1">
        For further information on how to configure Researcher authentication see the
        <a
          href="https://docs.run.ai/latest/admin/runai-setup/authentication/researcher-authentication/#mandatory-kubernetes-configuration"
          target="_blank"
        >
          Researcher authentication setup guide</a
        >
      </div>
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// Stores
import { useAuthStore } from "@/stores/auth.store";
// Models
import type { ITenantClientConfigInfo, ITenantServerConfigInfo } from "@/models/tenant.model";
// Components
import { ClientConfig } from "./client-config";
import { ServerConfig } from "./server-config";
import { RunaiCodeBlock } from "@/components/common/runai-code-block";

export default defineComponent({
  name: "researcher-authentication",
  components: {
    ClientConfig,
    ServerConfig,
    RunaiCodeBlock,
  },
  data() {
    return {
      authStore: useAuthStore(),
      clientConfigInfo: null as ITenantClientConfigInfo | null,
      serverConfigInfo: null as ITenantServerConfigInfo | null,
      realm: "" as string,
      loading: false as boolean,
    };
  },
  async created() {
    this.loading = true;
    try {
      const configInfo = await this.authStore.loadTenantConfigInfo();
      this.clientConfigInfo = configInfo.clientConfigInfo;
      this.serverConfigInfo = configInfo.serverConfigInfo;
      this.realm = configInfo.clientConfigInfo.realm;
    } catch (err: unknown) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  },
});
</script>
