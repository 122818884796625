<template>
  <section class="resources-cost">
    <boolean-setting :setting="localSetting" @update-setting="updateLocalSetting" />
    <transition name="fade" :duration="300">
      <div v-if="localSetting.value" class="column q-pb-md">
        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <div class="label">GPU cost</div>
          <q-input
            :model-value="resourcesCost.gpu"
            @update:model-value="updateResourceCost('gpu', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            dense
            type="number"
            :min="0"
          />
        </div>
        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <div class="label">CPU cost</div>
          <q-input
            :model-value="resourcesCost.cpu"
            @update:model-value="updateResourceCost('cpu', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            dense
            type="number"
            :min="0"
          />
        </div>

        <div class="input-with-label-wrapper row items-center q-mb-md q-mr-sm">
          <div class="label">Memory (GB) cost</div>
          <q-input
            :model-value="resourcesCost.memory"
            @update:model-value="updateResourceCost('memory', $event)"
            class="q-mb-sm cost-input"
            no-error-icon
            outlined
            dense
            type="number"
            :min="0"
          />
        </div>

        <div class="row justify-end">
          <q-btn @click="save" label="save" color="primary" size="sm" />
        </div>
      </div>
    </transition>
  </section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { BooleanSetting } from "@/components/settings/boolean-setting";
import { deepCopy } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { type IResourcesCost, SettingKeys } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";
import { useSettingStore } from "@/stores/setting.store";
import { ErrorAlert } from "@/utils/error-alert.util";

export default defineComponent({
  name: "resource-cost",
  components: { BooleanSetting },
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      localSetting: deepCopy(this.setting),
      resourcesCost: {
        gpu: null,
        cpu: null,
        memory: null,
      } as IResourcesCost,
    };
  },
  created() {
    this.resourcesCost = { ...this.settingStore.resourcesCost };
  },
  methods: {
    async updateLocalSetting(key: string, value: string): Promise<void> {
      this.localSetting.value = value;
      this.updateSetting(SettingKeys.ConsumptionReport, value);
    },
    updateSetting(key: string, value: string | boolean | number): void {
      this.$emit("update-setting", key, value);
    },
    updateResourceCost(key: keyof typeof this.resourcesCost, value: string): void {
      if (value === "") {
        this.resourcesCost[key] = null;
      } else {
        this.resourcesCost[key] = parseFloat(value);
      }
    },
    async save(): Promise<void> {
      try {
        await this.settingStore.updateSetting(SettingKeys.ResourcesCost, this.resourcesCost);
        this.$q.notify(alertUtil.getSuccess("Resources cost updated successfully"));
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: "Cannot update resources cost",
        });
        this.$q.notify(errorAlert.getNotification(error));
      }
    },
  },
});
</script>

<style scoped lang="scss">
.input-with-label-wrapper {
  width: 50%;
  .label {
    min-width: 40%;
  }
  .cost-input {
    width: 50%;
  }
}
</style>
