import { formatDate } from "@/utils/table-format.util";
import { ECustomCell, type ITableColumn } from "./table.model";
import type { TRolesTypes } from "./roles.model";

export interface IApplication {
  id: string;
  tenantId: number;
  name: string;
  enabled: boolean;
  roles?: TRolesTypes[];
  lastLogin?: string;
  createdBy?: string;
  updatedAt?: string;
  createdAt?: string;
}

export interface IApplicationCreationRequeset {
  name: string;
}

export interface IApplicationCreationResponse {
  id: string;
  name: string;
  secret: string;
}

export interface IApplicationRegenerateSecretResponse {
  secret: {
    type: "secret";
    value: string;
  };
}

export const allApplicationsColumnsMap: Record<string, ITableColumn> = {
  application: {
    name: "application",
    label: "Application",
    field: (row: IApplication) => row.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: IApplication) => row.enabled,
    sortable: true,
    customCell: ECustomCell.APP_STATUS_COL,
    align: "left",
    exportFormat: (row: IApplication) => (row.enabled ? "Active" : "Revoked"),
  },
  accessRules: {
    name: "accessRules",
    label: "Access rule(s)",
    field: (row: IApplication) => row.roles,
    sortable: true,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
  },
  lastLogin: {
    name: "lastLogin",
    label: "Last login",
    field: (row: IApplication) => row.lastLogin,
    sortable: true,
    align: "left",
    format: (lastLogin) => formatDate(lastLogin),
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: IApplication) => row.createdBy,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: IApplication) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => formatDate(createdAt),
  },
  lastUpdated: {
    name: "last-updated",
    label: "Last updated",
    field: (row: IApplication) => row.updatedAt,
    sortable: true,
    align: "left",
    format: (updatedAt: string) => formatDate(updatedAt),
  },
};

export const applicationsColumns: Array<ITableColumn> = [
  allApplicationsColumnsMap.application,
  allApplicationsColumnsMap.status,
  allApplicationsColumnsMap.accessRules,
  allApplicationsColumnsMap.lastLogin,
  allApplicationsColumnsMap.createdBy,
  allApplicationsColumnsMap.createdAt,
  allApplicationsColumnsMap.lastUpdated,
];

export const applicationsIndexColumns: Array<ITableColumn> = [
  { ...allApplicationsColumnsMap.application, display: true, mandatory: true },
  { ...allApplicationsColumnsMap.status, display: true },
  { ...allApplicationsColumnsMap.accessRules, display: true },
  { ...allApplicationsColumnsMap.lastLogin, display: true },
  { ...allApplicationsColumnsMap.createdBy, display: true },
  { ...allApplicationsColumnsMap.createdAt, display: true },
  { ...allApplicationsColumnsMap.lastUpdated, display: true },
];
