<template>
  <span :class="getStatusClass"
    >{{ options.status }} <span v-if="displayUpdateTime">({{ updateTime }})</span></span
  >
  <q-spinner-hourglass class="on-left" v-if="options.displayAnimation" />
  <template v-if="options.tooltipText">
    <q-btn
      @click.stop
      v-if="options.showIconToolTip"
      class="q-ml-sm q-pa-none"
      icon="fa-regular fa-circle-info"
      flat
      round
      size="10px"
    >
      <q-menu class="q-pa-sm" v-if="options.tooltipText" anchor="center right"
        ><div class="tooltip" v-html="options.tooltipText" @click="handleToolTipClick"></div
      ></q-menu>
    </q-btn>
    <q-tooltip v-else max-width="300px" :offset="[0, -10]" class="tooltip"
      ><span v-html="options.tooltipText"></span
    ></q-tooltip>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

//utils
import { dateUtil } from "@/utils/date.util";

// models
import type { IStatusColOptions } from "@/models/table.model";

export default defineComponent({
  name: "runai-status",
  emits: ["tooltip-clicked"],
  props: {
    options: {
      type: Object as PropType<IStatusColOptions>,
      required: true,
    },
  },
  computed: {
    displayUpdateTime(): boolean {
      return !!this.options.statusUpdatedTimeInMs && !this.options.displayAnimation;
    },
    updateTime(): string {
      return this.options.statusUpdatedTimeInMs !== undefined
        ? dateUtil.timeAgo(this.options.statusUpdatedTimeInMs)
        : "";
    },
    getStatusClass(): string {
      if (this.options.color) {
        return this.options.color.toLowerCase() + "-color";
      }
      return "";
    },
  },
  methods: {
    handleToolTipClick(event: Event) {
      this.$emit("tooltip-clicked", event);
    },
  },
});
</script>
<style lang="scss" scoped>
.tooltip {
  font-size: 12px;
}
.success-color {
  color: $success;
}
.negative-color {
  color: $negative;
}
.warning-color {
  color: $warning;
}
</style>
