<template>
  <q-table
    class="runai-table"
    :rows="rows"
    :columns="columns"
    :visible-columns="filterBy.displayedColumns"
    :pagination="filterBy"
    :filter="searchTerm"
    @update:pagination="updateFilters"
    :selection="disableSelection ? 'none' : 'single'"
    :selected="selected"
    :row-key="getRowKey"
    :class="{ 'sticky-column-table': stickyColumns, 'sticky-header': stickyHeader }"
    :style="style"
    :binary-state-sort="true"
    :hide-selected-banner="true"
    table-class="runai-table"
    rows-per-page-label="Rows per page"
    :loading="loading"
    :hide-bottom="hidePagination"
    :flat="noShadow"
    :bordered="bordered"
    square
    @request="fetchPartialPagedData"
    :rows-per-page-options="rowsPerPageOptions"
  >
    <template v-slot:top-row="{ cols }">
      <runai-table-row
        v-if="topRow"
        :columns="cols"
        :row="topRow"
        :name-column-icon="getRowIcon(topRow)"
        :is-selected="isTopRowSelected"
        :is-selectable="!disableSelection"
        @on-select="onSelect(getRowKey(topRow), topRow)"
        @custom-cell-event="$emit($event.emitName, topRow)"
      />
    </template>

    <template v-slot:body="props">
      <runai-table-row
        :columns="props.cols"
        :row="props.row"
        :name-column-icon="getRowIcon(props.row)"
        :is-selectable="!disableSelection"
        @custom-cell-event="$emit($event.emitName, props.row)"
        @on-select="onSelect(props.key, props.row)"
        :is-selected="props.selected"
      />
    </template>

    <template v-slot:no-data>
      <slot v-if="!loading && !topRow" name="no-data"></slot>
    </template>
  </q-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiTableRow } from "@/components/common/runai-table/runai-table-row";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";

// Utils
import { isEqual } from "@/utils/common.util";

export default defineComponent({
  components: {
    RunaiTableRow,
  },
  emits: ["update:selected", "update-filters"],
  props: {
    rows: {
      type: Array as PropType<any[]>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    columns: {
      type: Array as PropType<ITableColumn[]>,
      required: true,
    },
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    selected: {
      type: Array as PropType<any[]>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => [],
    },
    disableSelection: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    topRow: {
      type: [Object, null] as PropType<any | null>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: null,
    },
    getRowKey: {
      type: Function as PropType<(row: any) => string | number>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => "",
    },
    getRowIcon: {
      type: Function as PropType<(row: any) => string>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: false,
      default: () => "",
    },
    stickyColumns: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hidePagination: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    noShadow: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    modalView: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    stickyHeader: {
      // When using sticky header, height/max-height must be provided as well in the style prop
      type: Boolean as PropType<boolean>,
      default: false,
    },
    style: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    bordered: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isServerSidePagination: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rowsPerPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [0, 5, 7, 10, 15, 20, 25, 50],
    },
  },
  computed: {
    searchTerm(): string | undefined {
      return this.modalView ? this.filterBy.searchTerm : undefined;
    },
    isTopRowSelected(): boolean {
      const topRowKey = this.getRowKey(this.topRow);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return !!this.selected?.find((currRow: any) => this.getRowKey(currRow) === topRowKey);
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect(key: any, row: any): void {
      if (this.selected.find((currRow) => this.getRowKey(currRow) === key)) {
        this.$emit("update:selected", []);
      } else {
        this.$emit("update:selected", [row]);
      }
    },
    updateFilters(newFilterBy: IFilterBy): void {
      if (isEqual(this.filterBy, newFilterBy)) return;
      if (this.isServerSidePagination) {
        this.fetchPartialPagedData(newFilterBy as { pagination: IFilterBy });
      } else {
        this.$emit("update-filters", { ...this.filterBy, ...newFilterBy });
      }
    },
    fetchPartialPagedData(props: { pagination: IFilterBy }) {
      this.$emit("update-filters", props.pagination);
    },
  },
});
</script>
<style lang="scss" scoped></style>
