<template>
  <q-menu
    class="page-filter-popup"
    transition-show="scale"
    transition-hide="scale"
    transition-duration="500"
    :model-value="display"
    @hide="emitClose()"
  >
    <form @submit.prevent>
      <q-card-section class="row justify-between items-center q-py-sm">
        <div class="text-weight-medium">{{ title }}</div>
        <q-btn flat icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup @click="emitClose"></q-btn>
      </q-card-section>
      <q-card-section class="q-py-sm">
        <q-input outlined ref="filter-input" :autofocus="true" v-model="inputValue" :label="inputLabel" />
      </q-card-section>
      <q-card-section class="row justify-end q-py-sm">
        <q-btn type="submit" flat color="primary" label="Apply" v-close-popup @click="saveFilter" />
      </q-card-section>
    </form>
  </q-menu>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { IFilterModel } from "@/models/filter.model";

export default defineComponent({
  emits: ["save", "close"],
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    display: {
      type: Boolean as PropType<boolean>,
    },
    filter: {
      type: Object as PropType<IFilterModel>,
      required: true,
    },
    inputLabel: {
      type: String as PropType<string>,
      required: false,
      default: "Contains",
    },
  },
  data() {
    return {
      inputValue: "" as string,
    };
  },
  created() {
    this.inputValue = this.filter.term;
  },
  methods: {
    emitClose(): void {
      this.$emit("close");
    },
    saveFilter(): void {
      const editedFilter: IFilterModel = {
        ...this.filter,
        term: this.inputValue,
      };
      this.inputValue = "";
      this.$emit("save", editedFilter);
    },
  },
  watch: {
    "filter.term": {
      handler(newVal) {
        this.inputValue = newVal;
      },
    },
  },
});
</script>

<style lang="scss">
.page-filter-popup {
  width: 250px;
}
</style>
