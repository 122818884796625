import type { IAdminMessage } from "@/models/setting.model";
import { storageUtil } from "../storage.util";
import { STORAGE_KEYS } from "@/models/storage.model";

export const adminMessageUtil = {
  getAdminMessage,
  dismissAdminMessage,
};

function getAdminMessage(adminMessage: IAdminMessage): null | IAdminMessage {
  const savedMessageId: string | undefined = storageUtil.get(STORAGE_KEYS.ADMIN_MESSAGE_ID);
  const dismissedMessageId: string | undefined = storageUtil.get(STORAGE_KEYS.DISMISSED_MESSAGE_ID);
  let msg = null as IAdminMessage | null;
  if ((adminMessage.enableDismiss && adminMessage.id === dismissedMessageId) || !adminMessage.msg) return msg;
  if (dismissedMessageId !== adminMessage.id) storageUtil.remove(STORAGE_KEYS.DISMISSED_MESSAGE_ID);
  if (!savedMessageId || savedMessageId !== adminMessage.id) {
    msg = adminMessage;
    storageUtil.save(STORAGE_KEYS.ADMIN_MESSAGE_ID, adminMessage.id);
  }
  return msg;
}

function dismissAdminMessage(adminMessageId: string): void {
  storageUtil.save(STORAGE_KEYS.DISMISSED_MESSAGE_ID, adminMessageId);
}
