<template>
  <section class="cluster-index">
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          primary-btn-label="new cluster"
          :columns="columns"
          :filters="filterBy"
          @filters-changed="updateFilterBy"
          @create-entity="createCluster"
          :selected-rows-amount="selectedRowsAmount"
          :show-primary-btn="!isTrial"
          @selected-actions-close="resetSelectedRows"
          @export-csv="exportTableAsCsv"
        >
          <template v-slot:selected-rows-actions>
            <div class="row items-center justify-start">
              <runai-action-button
                v-permission="{ resourceType: ResourceType.Cluster, action: Action.Create }"
                aid="install-cluster-btn"
                btn-action="install"
                @click="installCluster"
                v-if="!isTrial"
              />
              <runai-tooltip-wrapper
                :display-tooltip="disableDelete"
                tooltip-text="This is the only cluster and therefore can't be removed"
              >
                <runai-action-button
                  v-permission="{
                    resourceType: ResourceType.Cluster,
                    action: Action.Delete,
                  }"
                  :disable="disableDelete"
                  aid="delete-cluster-btn"
                  btn-action="remove"
                  @click="openDeleteModal"
                  v-if="!isTrial"
                />
              </runai-tooltip-wrapper>
            </div>
          </template>
        </runai-page-actions>
      </template>
      <template v-slot:table>
        <runai-table
          :disable-selection="isTrial"
          :rows="tableClusters"
          :columns="columns"
          :loading="isInitialLoader && loading"
          :filter-by="filterBy"
          v-model:selected="selectedRows"
          @update-filters="updateFilterBy"
          :top-row="lastCreatedCluster"
          :get-row-key="getRowKey"
          sticky-columns
          @tooltip-click="handleToolTipClick"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!lastCreatedCluster"
              :filter-by="filterBy"
              entity-name="cluster"
              icon-name=""
              @clear-filters="clearFilters"
              @create-new="createCluster"
              :show-error="loadingError"
            />
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>

    <remove-cluster-modal
      v-if="isDeleteModalOpen && selectedRows.length >= 1"
      :cluster-name="selectedRows[0].name"
      :deleting="deleting"
      @cancel="isDeleteModalOpen = false"
      @remove="deleteCluster"
    ></remove-cluster-modal>
    <nonfunctioning-services-table-modal
      :modal-options="nonfunctioningServicesTableModalOptions"
      v-if="isNonfunctioningModalOpen"
      @close="isNonfunctioningModalOpen = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Stores
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";

// components
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { RunaiTable } from "@/components/common";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { RemoveClusterModal } from "@/components/cluster/remove-cluster-modal";

// models
import { allClusterColumnsMap, clusterIndexColumns, type ICluster } from "@/models/cluster.model";
import type { IFilterBy } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames, ETableFilters } from "@/models/table.model";
import { HttpErrorResponse } from "@/models/http-response.model";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

// Services
import { alertUtil } from "@/utils/alert.util";
import { tableUtil } from "@/utils/table.util";
import { filterService } from "@/services/filter.service/filter.service";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes";
import NonfunctioningServicesTableModal from "@/components/cluster/nonfunctioning-services-table-modal/nonfunctioning-services-table-modal.vue";
import { IService, IServiceTableModalOptions, serviceMiniTableColumns } from "@/models/service.model";
import { clusterUtil } from "@/utils/cluster.util";

export default defineComponent({
  components: {
    NonfunctioningServicesTableModal,
    RunaiTableWrapper,
    RunaiPageActions,
    RunaiActionButton,
    RunaiTable,
    RunaiTableNoData,
    RunaiTooltipWrapper,
    RemoveClusterModal,
  },
  data() {
    return {
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      loading: false as boolean,
      selectedRows: [] as Array<ICluster>,
      refreshInterval: 5000 as number,
      currentTimeoutId: 0 as number,
      loadingError: false as boolean,
      isInitialLoader: true as boolean,
      columns: clusterIndexColumns as Array<ITableColumn>,
      filterBy: {} as IFilterBy,
      deleting: false as boolean,
      isDeleteModalOpen: false as boolean,
      isNonfunctioningModalOpen: false as boolean,
      isTrial: useAuthStore().isTrial,
      nonfunctioningServicesTableModalOptions: {
        services: [] as Array<IService>,
        header: "Nonfunctioning Services",
        columns: serviceMiniTableColumns,
        loading: false,
      } as IServiceTableModalOptions,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.loadFilters();
    this.loadClusters();
    this.startRefreshClusters();
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    tableClusters(): Array<ICluster> {
      return this.clusterStore.clusterListLastCreatedExcluded;
    },
    clusters(): Array<ICluster> {
      return this.clusterStore.clusterList;
    },
    isSingleCluster(): boolean {
      return this.clusters.length === 1;
    },
    selectedRowsAmount(): number {
      return this.isTrial ? 0 : this.selectedRows.length;
    },
    disableDelete(): boolean {
      return this.isTrial || this.isSingleCluster || this.selectedRows.length === 0;
    },
    lastCreatedCluster(): ICluster | null {
      return this.clusterStore.lastCreatedCluster;
    },
  },
  methods: {
    handleToolTipClick(row: ICluster): void {
      const services = clusterUtil.getNonFunctioningServices(row.status.operands);
      this.nonfunctioningServicesTableModalOptions.services = services.map((service) => ({ name: service }));
      this.isNonfunctioningModalOpen = true;
    },
    getRowKey(cluster: ICluster): string {
      return cluster.uuid;
    },
    async loadClusters(): Promise<void> {
      try {
        this.loading = true;
        await this.clusterStore.loadClusters(this.filterBy);
      } catch (e: unknown) {
        console.error(e);
        this.$q.notify(alertUtil.getError(`Failed to load clusters`));
        this.loadingError = true;
      } finally {
        this.isInitialLoader = false;
        this.loading = false;
      }
    },
    updateFilterBy(filterBy: IFilterBy, keyChanged: null | string = null, forceLoad = true): void {
      this.filterBy = filterBy;
      filterService.saveFilters(ETableFilters.CLUSTER, filterBy);
      if (!forceLoad || keyChanged === "displayedColumns") return;

      this.clusterStore.removeLastCreatedCluster();
      this.isInitialLoader = true;
      this.loadClusters();
    },
    clearFilters(): void {
      this.updateFilterBy(
        {
          ...this.filterBy,
          columnFilters: [],
          searchTerm: "",
        },
        null,
        true,
      );
    },
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(allClusterColumnsMap.name.name, this.columns);

      const searchParamsFilters: IFilterBy = filterService.loadFilters(
        window.location,
        ETableFilters.CLUSTER,
        defaultFilters,
      );
      this.updateFilterBy(searchParamsFilters, null, false);
    },
    resetSelectedRows(): void {
      this.selectedRows = [];
    },
    async createCluster(): Promise<void> {
      this.$router.push({
        name: CLUSTER_ROUTE_NAMES.CLUSTER_NEW,
      });
    },
    stopRefreshClusters(): void {
      window.clearTimeout(this.currentTimeoutId);
    },
    startRefreshClusters(): void {
      this.currentTimeoutId = window.setInterval(this.loadClusters, this.refreshInterval);
    },
    openDeleteModal(): void {
      this.stopRefreshClusters();
      this.isDeleteModalOpen = true;
    },
    async deleteCluster(): Promise<void> {
      const cluster: ICluster | undefined = this.selectedRows[0];
      if (!cluster || this.isSingleCluster) return;

      try {
        this.deleting = true;
        await this.clusterStore.deleteCluster(cluster.uuid);
        this.clusterStore.removeLastCreatedCluster();
        this.$q.notify(alertUtil.getSuccess(`Cluster ${cluster.name} deleted`));
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.$q.notify(alertUtil.getError("Failed to delete cluster"));
        }
      } finally {
        this.startRefreshClusters();
        this.resetSelectedRows();
        this.isDeleteModalOpen = false;
        this.deleting = false;
      }
    },
    installCluster(): void {
      const selectedClusterId: string = this.selectedRows[0].uuid;
      if (!selectedClusterId) return;
      this.$router.push({
        name: CLUSTER_ROUTE_NAMES.CLUSTER_INSTALL,
        params: { id: selectedClusterId },
      });
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Cluster, this.clusters, columns);
    },
  },
  unmounted() {
    this.stopRefreshClusters();
    this.clusterStore.removeLastCreatedCluster();
  },
});
</script>
