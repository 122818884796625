<template>
  <section class="runai-radio-options column items-start">
    <div v-if="title" class="q-mb-md">
      {{ title }}
      <runai-tooltip v-if="titleToolTip" :tooltip-text="titleToolTip" tooltip-position="right" />
    </div>
    <runai-tooltip-wrapper :display-tooltip="!!disableTooltip" :tooltip-text="disableTooltip">
      <q-option-group
        :class="{ 'horizontal-options': horizontal, row: horizontal }"
        v-if="options"
        :options="options"
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
        type="radio"
        :inline="horizontal"
        :disable="disable"
      >
        <template v-slot:label="opt">
          <div :class="{ row: !horizontal, 'items-center': !horizontal }" :aid="`radio-option-${opt.label}`">
            <span :class="{ 'q-mr-md': !horizontal }">
              {{ opt.label }}
              <q-tooltip v-if="opt.disable && opt.disabledTooltip">{{ opt.disabledTooltip }}</q-tooltip>
            </span>
            <runai-tooltip
              v-if="opt.toolTip"
              :tooltip-text="opt.toolTip"
              tooltip-position="right"
              :width="tooltipWidth"
            />
          </div>
        </template>
      </q-option-group>
    </runai-tooltip-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
// Models
import type { ISelectOption } from "@/models/global.model";

export default defineComponent({
  name: "runai-radio-options",
  components: {
    RunaiTooltip,
    RunaiTooltipWrapper,
  },
  emits: ["update:modelValue"],
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
    },
    options: {
      type: Array as PropType<Array<ISelectOption>>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
      required: true,
    },
    horizontal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    titleToolTip: {
      type: String as PropType<string>,
      required: false,
    },
    tooltipWidth: {
      type: String as PropType<string>,
      required: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.runai-radio-options {
  .horizontal-options {
    margin-left: -18px;
    gap: 40px;
  }
}
</style>
