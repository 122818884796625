<template>
  <runai-expansion-item class="admin-message settings-container" label="Message from administrator">
    <template #subheader>
      <div class="subheader ellipsis">{{ savedMessage || "None" }}</div>
    </template>

    <div class="row items-center q-mb-md">
      <span class="q-mr-md">{{ setting.label }}</span>
      <runai-tooltip
        v-if="setting.description"
        :tooltip-text="setting.description"
        width="400px"
        tooltip-position="right"
      />
    </div>

    <q-btn
      v-if="!savedMessage && !editorOpen"
      aid="add-message-btn"
      label="+ message"
      flat
      color="primary"
      @click="editorOpen = true"
    />

    <runai-text-editor v-else-if="editorOpen" v-model="message">
      <template #footer>
        <div class="row justify-between items-center">
          <q-checkbox aid="enable-dismiss-checkbox" :label="checkboxLabel" v-model="enableDismiss" dense />
          <div>
            <q-btn
              aid="discard-message-btn"
              class="q-mr-md"
              label="discard"
              color="primary"
              flat
              @click="discardMessage"
            />
            <q-btn
              aid="publish-message-btn"
              label="publish"
              color="primary"
              :loading="savingMessage"
              @click="publishMessage"
            />
          </div>
        </div>
      </template>
    </runai-text-editor>

    <div class="saved-message row justify-between no-wrap items-center q-pa-sm q-mt-md" v-else>
      <span class="ellipsis">{{ savedMessage }}</span>
      <div class="buttons row no-wrap q-gutter-sm">
        <q-btn icon="fa-solid fa-pencil" class="q-pa-sm" flat size="sm" @click="editorOpen = true">
          <q-tooltip>Edit</q-tooltip>
        </q-btn>
        <q-btn icon="fa-solid fa-trash" class="q-pa-sm" flat size="sm" @click="deleteMessage">
          <q-tooltip>Delete to unpublish</q-tooltip>
        </q-btn>
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { IAdminMessageSetting } from "@/models/setting.model";
import { SettingKeys } from "@/models/setting.model";
import { STORAGE_KEYS } from "@/models/storage.model";
// Components
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiTextEditor } from "@/components/common/runai-text-editor";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
// Stores
import { useSettingStore } from "@/stores/setting.store";
// Utils
import { alertUtil } from "@/utils/alert.util";
import { storageUtil } from "@/utils/storage.util";
import { HTMLUtil } from "@/utils/html.util/html.util";

export default defineComponent({
  name: "admin-message",
  components: {
    RunaiTooltip,
    RunaiTextEditor,
    RunaiExpansionItem,
  },
  emits: {},
  props: {
    setting: {
      type: Object as PropType<IAdminMessageSetting>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      message: "" as string,
      enableDismiss: false as boolean,
      editorOpen: false as boolean,
      savingMessage: false as boolean,
    };
  },
  created() {
    this.message = this.setting.value?.msg || "";
    this.enableDismiss = this.setting.value?.enableDismiss || false;
  },
  computed: {
    checkboxLabel(): string {
      return `Display "Don't show this again" checkbox on message to users`;
    },
    savedMessage(): string {
      return this.setting.value?.msg ? HTMLUtil.HTMLToPlainText(this.setting.value?.msg) : "";
    },
  },
  methods: {
    discardMessage(): void {
      this.message = this.setting.value?.msg || "";
      this.enableDismiss = this.setting.value?.enableDismiss || false;
      this.editorOpen = false;
    },
    async publishMessage(): Promise<void> {
      try {
        this.savingMessage = true;
        if (this.message !== this.setting.value?.msg || this.enableDismiss !== this.setting.value?.enableDismiss) {
          await this.settingStore.updateSetting(SettingKeys.AdminMessage, {
            ...this.setting.value,
            msg: this.message,
            enableDismiss: this.enableDismiss,
          });
          const savedMessage = await this.settingStore.loadAdminMessage();
          storageUtil.save(STORAGE_KEYS.ADMIN_MESSAGE_ID, savedMessage?.id || undefined);
        }
        const successToast = this.message ? "Message published" : "Message unpublished";
        this.$q.notify(alertUtil.getSuccess(successToast));
        this.editorOpen = false;
      } catch (e: unknown) {
        this.$q.notify(alertUtil.getError("Failed to update message"));
        console.error(e);
      } finally {
        this.savingMessage = false;
      }
    },
    async deleteMessage(): Promise<void> {
      this.message = "";
      this.enableDismiss = false;
      this.publishMessage();
    },
  },
});
</script>

<style lang="scss">
.admin-message {
  .saved-message {
    border: 1px solid $black-12;
    .q-icon {
      color: $black-54;
    }
  }

  .subheader {
    max-width: 350px;
  }
}
</style>
