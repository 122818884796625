import { ref, onUnmounted, type Ref } from "vue";
import { useRoute } from "vue-router";

interface IUseTopRowOptions {
  refreshTime?: number;
  requiresKind?: boolean;
}

export function useTopRow<T>(loadEntityById: CallableFunction, options: IUseTopRowOptions = { requiresKind: false }) {
  const route = useRoute();

  const lastCreatedEntity: Ref<null | T> = ref(null);
  const refreshIntervalId: Ref<null | number> = ref(null);

  const createdEntityId: string | null = route.query.createdEntityId?.toString() || null;
  const createdEntityKind: string | null = route.query.kind?.toString() || null;

  const executeCall = async () => {
    if (!createdEntityId) {
      lastCreatedEntity.value = null;
      return;
    }
    try {
      if (options.requiresKind && createdEntityKind)
        lastCreatedEntity.value = await loadEntityById(createdEntityId, createdEntityKind);
      else lastCreatedEntity.value = await loadEntityById(createdEntityId);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const clearLastCreated = () => {
    lastCreatedEntity.value = null;
    refreshIntervalId.value && window.clearInterval(refreshIntervalId.value);
  };

  executeCall();

  if (options.refreshTime) refreshIntervalId.value = window.setInterval(executeCall, options.refreshTime);
  onUnmounted(() => refreshIntervalId.value && window.clearInterval(refreshIntervalId.value));

  return { lastCreatedEntity, clearLastCreated };
}
