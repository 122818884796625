<template>
  <section class="deployment-table">
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          :primary-btn-disable="disablePrimaryBtn"
          :primary-tooltip-text="primaryTooltipText"
          primary-btn-label="new deployment"
          :columns="columns"
          :filters="filterBy"
          @filters-changed="updateFilterBy"
          @create-entity="createDeployment"
          :selected-rows-amount="selectedRowsAmount"
          @selected-actions-close="selectedRows = []"
          @export-csv="exportTableAsCsv"
          empty-message="Select a deployment to view its details"
        >
          <template v-slot:selected-rows-actions>
            <div class="row items-center justify-start">
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canConnect"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.connect)"
              >
                <runai-action-button
                  btn-action="connect"
                  :disable="!isClusterConnected || !canConnect"
                  aid="deployment-index-connect-btn"
                >
                  <tools-select @value-changed="onOpenTool" :tool-items="toolItems"></tools-select>
                </runai-action-button>
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canDeleteDeployment"
                :tooltip-text="noActionPermissionText"
              >
                <runai-action-button
                  :disable="!isClusterConnected || !canDeleteDeployment"
                  :tooltip-text="noActionPermissionText"
                  aid="delete-deployment-btn"
                  btn-action="delete"
                  @click="openDeleteModal"
                />
              </runai-tooltip-wrapper>
            </div>
          </template>
        </runai-page-actions>
      </template>
      <template v-slot:table>
        <runai-table
          :style="{ marginRight: tableMargin }"
          :rows="deployments"
          :columns="columns"
          :loading="isInitialLoader && loading"
          :filter-by="filterBy"
          v-model:selected="selectedRows"
          @update-filters="updateFilterBy"
          sticky-columns
          :get-row-key="getRowKey"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!isInitialLoader"
              :filter-by="filterBy"
              :show-error="loadingError"
              :show-create-btn="!disablePrimaryBtn"
              entity-name="deployment"
              icon-name="deployment"
              @clear-filters="clearFilters"
              @create-new="createDeployment"
            >
              <template #secondary-text v-if="noExistingProjects">
                <runai-table-no-projects
                  entity-type="deployment"
                  :has-entity-create-permission="canCreateDeployment"
                  :has-project-create-permission="canCreateProject"
                />
              </template>
            </runai-table-no-data>
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>

    <runai-delete-modal
      v-if="isDeleteModalOpen && selectedDeployment"
      entity-type="deployment"
      :custom-title="`Permanently delete deployment ${selectedDeployments[0].name} and its logs?`"
      :entity-name="selectedDeployments[0].name"
      @cancel="isDeleteModalOpen = false"
      @delete="deleteDeployment"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { RunaiTable, RunaiTableNoProjects } from "@/components/common/runai-table";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { RunaiDeleteModal } from "@/components/common/runai-delete-modal";
import { ToolsSelect } from "@/components/tools";

// models
import type { IFilterBy } from "@/models/filter.model";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { ETableExportCsvFilesNames, ETableFilters } from "@/models/table.model";
import type { IReadinessStatus } from "@/models/researcher.model";
import {
  allDeploymentColumnsMap,
  type IDeploymentList,
  deploymentIndexColumns,
  EDeploymentStatus,
} from "@/models/deployment.model";
import type { ITableColumn } from "@/models/table.model";
import { HttpErrorResponse } from "@/models/http-response.model";
import type { IToolItem } from "@/models/workspace.model";
import { EWorkloadAction, EWorkloadEntity } from "@/models/workload.model";

// stores
import { useAppStore } from "@/stores/app.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useDeploymentStore } from "@/stores/deployment.store";

// routes
import { DEPLOYMENT_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";

// services
import { filterService } from "@/services/filter.service/filter.service";
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";

// utils
import { alertUtil } from "@/utils/alert.util";
import { tableUtil } from "@/utils/table.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { connectionAlertUtil } from "@/utils/connection-alert.util";
import { intervalUtil } from "@/utils/interval.util";

// constants
import { INTERVAL_LABELS } from "@/common/interval.constant";

const refreshInterval = 5000;

export default defineComponent({
  components: {
    RunaiTableWrapper,
    RunaiPageActions,
    RunaiActionButton,
    RunaiTable,
    RunaiTableNoData,
    RunaiTooltipWrapper,
    RunaiTableNoProjects,
    RunaiDeleteModal,
    ToolsSelect,
  },
  data() {
    return {
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
      projectStore: useProjectStore(),
      clusterStore: useClusterStore(),
      deploymentStore: useDeploymentStore(),
      filterBy: {} as IFilterBy,
      isInitialLoader: true as boolean,
      loadingError: false as boolean,
      loading: false as boolean,
      isClusterConnected: true as boolean,
      columns: deploymentIndexColumns as Array<ITableColumn>,
      selectedRows: [] as Array<IDeploymentList>,
      isDeleteModalOpen: false as boolean,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.checkConnectivityStatus();
    this.loadFilters();
    this.loadProjects();
    this.loadDeployments();
    this.startRefreshDeployments();
  },
  computed: {
    canCreateDeployment(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Deployments, Action.Create);
    },
    canDeleteDeployment(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Deployments, Action.Delete);
    },
    canUpdateDeployment(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Deployments, Action.Update);
    },
    noActionPermissionText(): string {
      return "Action for the selected deployment is not authorized";
    },
    canCreateProject(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    noExistingProjects(): boolean {
      return this.projectStore.projects.length === 0;
    },
    disablePrimaryBtn(): boolean {
      return this.noExistingProjects || !this.canCreateDeployment || !this.isClusterConnected;
    },
    deployments(): Array<IDeploymentList> {
      return this.deploymentStore.deployments;
    },
    tableMargin(): string {
      return "0px";
    },
    selectedDeployment(): IDeploymentList | undefined {
      return this.selectedRows[0];
    },
    selectedDeployments(): IDeploymentList[] {
      return this.selectedRows;
    },
    selectedRowsAmount(): number {
      return this.selectedDeployments.length;
    },
    primaryTooltipText(): string {
      if (!this.isClusterConnected) {
        return "";
      } else if (this.canCreateProject) {
        return "To create a new deployment, First create at least one project";
      } else if (this.canCreateDeployment) {
        return "Deployments can only be created under a project.\nContact your administrator to add a project.";
      } else {
        return "";
      }
    },
    canConnect(): boolean {
      if (!this.canUpdateDeployment) return false;
      return (
        this.selectedDeployment?.status === EDeploymentStatus.COMPLETE && !!this.selectedDeployment.connections?.length
      );
    },
    toolItems(): IToolItem[] {
      return this.selectedDeployment?.connections ? this.selectedDeployment.connections : [];
    },
    EWorkloadAction(): typeof EWorkloadAction {
      return EWorkloadAction;
    },
  },
  methods: {
    getRowKey(deployment: IDeploymentList): string {
      return deployment.id;
    },
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(allDeploymentColumnsMap.name.name, this.columns);

      const searchParamsFilters: IFilterBy = filterService.loadFilters(
        window.location,
        ETableFilters.DEPLOYMENT,
        defaultFilters,
      );
      this.updateFilterBy(searchParamsFilters, null, false);
    },
    updateFilterBy(filterBy: IFilterBy, keyChanged: null | string = null, forceLoad = true): void {
      this.filterBy = filterBy;
      filterService.saveFilters(ETableFilters.DEPLOYMENT, filterBy);
      if (!forceLoad || keyChanged === "displayedColumns") return;

      this.isInitialLoader = true;
      this.loadDeployments();
    },
    clearFilters(): void {
      this.updateFilterBy(
        {
          ...this.filterBy,
          columnFilters: [],
          searchTerm: "",
        },
        null,
        true,
      );
    },
    createDeployment(): void {
      this.$router.push({ name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_NEW });
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
    },
    async loadDeployments(): Promise<void> {
      try {
        this.loading = true;
        await this.deploymentStore.loadDeployments(this.filterBy);
      } catch (e: unknown) {
        console.error(e);
        this.$q.notify(alertUtil.getError(`Failed to load deployments`));
        this.loadingError = true;
      } finally {
        this.isInitialLoader = false;
        this.loading = false;
      }
    },
    async checkConnectivityStatus(): Promise<void> {
      if (!this.clusterStore.currentCluster.domain) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getMissingDomainMessage()));
        this.isClusterConnected = false;
        return;
      }

      const res: IReadinessStatus = await whoamiService.isCurrentClusterConnected();
      if (!res.connected) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getConnectivityMessage(res.statusCode)));
        this.isClusterConnected = false;
      }
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));
      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Deployment, this.deployments, columns);
    },
    openDeleteModal(): void {
      this.stopRefreshDeployments();
      this.isDeleteModalOpen = true;
    },
    stopRefreshDeployments(): void {
      intervalUtil.stopInterval(INTERVAL_LABELS.DEPLOYMENT_TABLE);
    },
    startRefreshDeployments(): void {
      intervalUtil.startInterval(INTERVAL_LABELS.DEPLOYMENT_TABLE, this.loadDeployments, refreshInterval);
    },
    resetSelectedRows(): void {
      this.selectedRows = [];
    },
    async deleteDeployment(): Promise<void> {
      const deployment: IDeploymentList | undefined = this.selectedDeployments[0];
      if (!deployment) return;

      try {
        this.loading = true;
        await this.deploymentStore.deleteDeployment(deployment);
        this.$q.notify(alertUtil.getSuccess(`Deployment ${deployment.name} deleted`));
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to delete deployment", error as string);
        }
      } finally {
        this.isDeleteModalOpen = false;
        this.startRefreshDeployments();
        this.resetSelectedRows();
        this.loading = false;
      }
    },
    getErrorMsgIfAccessDenied(baseMsg: string, error: string): string {
      const errStr = error.toString().toLowerCase();
      if (errStr.includes("access denied") || errStr.includes("not allowed")) {
        return `${baseMsg}: Access Denied`;
      }
      return baseMsg;
    },
    notifyMsgWithError(baseMsg: string, error: string): void {
      let errorStr = this.getErrorMsgIfAccessDenied(baseMsg, error);
      this.$q.notify(alertUtil.getError(errorStr));
    },
    getDisabledToolTipText(action: EWorkloadAction): string {
      if (!this.canUpdateDeployment) return this.noActionPermissionText;
      return workloadUtil.getDisabledToolTipText(action, EWorkloadEntity.deployment);
    },
    onOpenTool(url: string): void {
      window.open(url.startsWith("http") ? url : "http://" + url);
    },
  },
  unmounted() {
    this.stopRefreshDeployments();
  },
});
</script>
<style lang="scss" scoped>
.deployment-table {
  width: 100%;
}
</style>
