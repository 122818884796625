<template>
  <section class="environment-tool-box q-mb-md" v-if="editTool.id">
    <q-btn
      aid="remove-tool-button"
      round
      flat
      icon="fa-regular fa-xmark"
      class="close-btn"
      @click="$emit('removed')"
      v-if="environmentFormDisplay && removeable"
      :disable="disable"
    ></q-btn>

    <section class="row gap q-pa-md top-section">
      <span>
        <tool-type-select
          :disable="!environmentFormDisplay || disable"
          :tool-type="editTool.toolType"
          :options="availableToolTypeOptions"
          @value-changed="updateToolType"
          :not-required="defaultToolBox"
          aid="tool-type-select"
        ></tool-type-select>
        <q-tooltip v-if="!environmentFormDisplay" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
      </span>
      <span :class="showAuthorizedSection ? 'col-5 flex-1' : 'col-6'">
        <q-input
          v-if="editTool.toolType === 'custom'"
          v-model="editTool.name"
          label="Tool name"
          no-error-icon
          stack-label
          placeholder="Enter a name"
          :disable="!environmentFormDisplay || disable"
          :rules="[nameNotEmpty]"
          aid="tool-type-custom-input"
        ></q-input>
        <q-input
          aid="tool-type-wandb-input"
          v-else-if="isExternalTool"
          label="URL"
          stack-label
          placeholder="Enter a URL"
          :rules="[urlNotEmpty]"
          no-error-icon
          v-model="editTool.externalUrl"
          :disable="disable"
        >
        </q-input>
        <q-tooltip v-if="!environmentFormDisplay" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
      </span>
      <div v-if="showAuthorizedSection" class="private-connection-toggle row items-center">
        <q-toggle
          :model-value="!!editTool.authorizedUsers"
          @update:model-value="updateAuthorizedUsers"
          label="Private"
          left-label
        />
        <runai-tooltip
          tooltip-text='Tools with an "External URL" connection can be set as private. When enabled, only the workload creator can
          connect to the tool. When disabled, anyone with access to the project can connect to the tool.'
          tooltip-position="right"
          width="416px"
        ></runai-tooltip>
      </div>
    </section>

    <transition name="slide-fade">
      <section v-if="editTool.toolType && !isExternalTool" class="bottom-section row gap justify-between q-pa-md">
        <section class="row gap">
          <connection-type-select
            v-if="editTool.connectionType"
            :disable="!environmentFormDisplay || disable"
            :connection-type="editTool.connectionType"
            :options="availableConnectionTypeOptions"
            @value-changed="updateConnectionType"
            :tool-tip-text="disabledTooltipText"
          ></connection-type-select>
          <template v-if="editTool.connectionType === 'ExternalUrl' && editTool.isCustomUrl !== null">
            <span>
              <autogen-select
                :disable="!environmentFormDisplay || disable"
                :init-value="editTool.isCustomUrl"
                custom-label="Custom URL"
                @value-changed="updateEditTool('isCustomUrl', $event)"
              ></autogen-select>
              <q-tooltip v-if="!environmentFormDisplay" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
            </span>
            <span v-if="editTool.isCustomUrl">
              <q-input
                aid="custom-url-input"
                :disable="environmentFormDisplay || disable"
                stack-label
                :input-style="{ minWidth: '220px' }"
                input-class="placeholder-italic"
                placeholder="e.g https://runai.domain.hpc.org"
                :debounce="300"
                v-model="editTool.externalUrl"
                label="URL"
                :rules="isRequired ? [urlNotEmpty, isUrl] : [isUrl]"
                no-error-icon
              >
              </q-input>
              <q-tooltip max-width="250px" v-if="environmentFormDisplay">
                The URL is configured within the workspace using this environment
              </q-tooltip>
            </span>
          </template>
          <template v-if="editTool.connectionType === 'NodePort' && editTool.isCustomPort !== null">
            <span>
              <autogen-select
                :init-value="editTool.isCustomPort"
                :disable="!environmentFormDisplay"
                custom-label="Custom port"
                @value-changed="updateEditTool('isCustomPort', $event)"
              ></autogen-select>
              <q-tooltip v-if="!environmentFormDisplay" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
            </span>
            <span v-if="editTool.isCustomPort">
              <q-input
                aid="custom-port-input"
                type="number"
                :debounce="300"
                stack-label
                :input-style="{ minWidth: '220px' }"
                input-class="placeholder-italic"
                placeholder="e.g 30065"
                :disable="environmentFormDisplay || disable"
                v-model.number="editTool.nodePort"
                label="Node port"
                :rules="isRequired ? [validPort, validPortRange] : [validPortRange]"
                no-error-icon
              >
              </q-input>
              <q-tooltip max-width="250px" v-if="environmentFormDisplay">
                The node port is configured within the workspace using this environment
              </q-tooltip>
            </span>
          </template>
        </section>
        <span>
          <q-input
            :disable="!environmentFormDisplay || disable"
            label="Container port"
            :input-style="{ maxWidth: '100px' }"
            type="number"
            v-model.number="editTool.containerPort"
            :rules="[validPort]"
            no-error-icon
          ></q-input>
          <q-tooltip v-if="!environmentFormDisplay" max-width="250px"> {{ disabledTooltipText }} </q-tooltip>
        </span>
      </section>
    </transition>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Utils
import { deepCopy } from "@/utils/common.util";
import { isNotEmpty, isValidUrl } from "@/common/form.validators";
// Models
import { InternalConnectionType, ToolType } from "@/swagger-models/assets-service-client";
import { errorMessages } from "@/common/error-message.constant";
import type { ISelectOption } from "@/models/global.model";
import {
  connectionTypeOptions,
  toolTypeOptions,
  type IUIConnection,
  type IConnectionTypeSelectOption,
  type IToolTypeSelectOption,
} from "@/models/environment.model";
// Cmps
import ConnectionTypeSelect from "../connection-type-select.vue";
import AutogenSelect from "../autogen-select.vue";
import ToolTypeSelect from "../tool-type-select.vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

export default defineComponent({
  components: {
    ToolTypeSelect,
    ConnectionTypeSelect,
    AutogenSelect,
    RunaiTooltip,
  },
  emits: ["removed", "update-tool", "update-custom"],
  props: {
    defaultToolBox: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    tools: {
      type: Array as PropType<Array<IUIConnection>>,
      required: true,
    },
    tool: {
      type: Object as PropType<IUIConnection>,
      required: true,
    },
    removeable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    environmentFormDisplay: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    toolNames: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editTool: deepCopy(this.tool) as IUIConnection,
      toolTypeForName: "" as string,
      connectionTypeForName: "" as string,
      externalTools: [ToolType.Wandb, ToolType.Comet] as Array<ToolType>,
    };
  },
  computed: {
    isExternalTool(): boolean {
      if (!this.editTool.toolType) return false;
      return this.externalTools.includes(this.editTool.toolType);
    },
    autogenOptions(): Array<ISelectOption> {
      const customOption: string =
        this.tool.connectionType === InternalConnectionType.ExternalUrl ? "Custom URL" : "Custom port";
      return [
        { value: true, label: "Auto generate" },
        { value: false, label: customOption },
      ];
    },
    disabledTooltipText(): string {
      const urlText = "The tool and its connection are defined within the environment. Set the URL here.";
      const portText = "The tool and its connection are defined within the environment. Set the node port here.";
      return this.editTool?.isCustomPort ? portText : urlText;
    },
    availableToolTypeOptions(): Array<IToolTypeSelectOption> {
      const otherTools: Array<IUIConnection> = this.tools.filter((tool: IUIConnection) => tool.id !== this.tool.id);
      return toolTypeOptions.map((typeOption: IToolTypeSelectOption) => {
        if (
          typeOption.value !== ToolType.Custom &&
          otherTools.filter((tool: IUIConnection) => tool.toolType === typeOption.value).length >=
            connectionTypeOptions.length
        )
          return {
            ...typeOption,
            disable: true,
            toolTip: "This tool is already used with all possible connection types",
          };
        return typeOption;
      });
    },
    availableConnectionTypeOptions(): Array<IConnectionTypeSelectOption> {
      const usedConnectionTypes: Record<InternalConnectionType, number> = this.tools.reduce(
        (acc, { id, toolType, connectionType }) => {
          if (
            id === this.editTool.id ||
            toolType !== this.editTool.toolType ||
            !connectionType ||
            toolType === ToolType.Custom
          )
            return acc;
          acc[connectionType] = 1;
          return acc;
        },
        {} as Record<InternalConnectionType, number>,
      );
      return connectionTypeOptions.map((typeOption: IConnectionTypeSelectOption) => {
        const unavailable = !!usedConnectionTypes[typeOption.value];
        const updatedOption: IConnectionTypeSelectOption = { ...typeOption, disable: unavailable };
        if (unavailable) updatedOption.toolTip = "This connection type is already in use by this tool";
        return updatedOption;
      });
    },
    showAuthorizedSection(): boolean {
      if (this.environmentFormDisplay) return false;
      return this.editTool.connectionType === InternalConnectionType.ExternalUrl;
    },
  },
  methods: {
    updateEditTool(key: string, val: boolean) {
      switch (key) {
        case "isCustomPort":
          this.editTool[key] = val;
          this.editTool.nodePort = null;
          delete this.editTool.externalUrl;
          break;
        case "isCustomUrl":
          this.editTool[key] = val;
          this.editTool.externalUrl = null;
          delete this.editTool.nodePort;
          break;
      }
    },
    updateTool(): void {
      this.$emit("update-tool", this.editTool);
    },
    nameNotEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.NAME_NOT_EMPTY;
    },
    urlNotEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.URL_NOT_EMPTY;
    },
    isUrl(val: string): boolean | string {
      if (!val) return true;
      return isValidUrl(val) || errorMessages.INVALID_URL;
    },
    validPort(val: number): boolean | string {
      return !!val || "Enter port";
    },
    validPortRange(val: number): boolean | string {
      if (!val) return true;
      return (val >= 30000 && val <= 32767) || "Enter a port between 30000 and 32767";
    },
    updateConnectionType(connectionType: ISelectOption): void {
      switch (connectionType.value) {
        case InternalConnectionType.ExternalUrl:
          delete this.editTool.isCustomPort;
          this.editTool.isCustomUrl = false;
          break;
        case InternalConnectionType.NodePort:
          delete this.editTool.isCustomUrl;
          this.editTool.isCustomPort = false;
          break;
        default:
          delete this.editTool.isCustomPort;
          delete this.editTool.isCustomUrl;
      }
      this.connectionTypeForName = connectionType.label;
      this.editTool.connectionType = connectionType.value as InternalConnectionType;
      this.updateToolName();
    },
    updateToolType(toolType: ISelectOption): void {
      const toolTypePortMap: Record<string, number> = {
        tensorboard: 6006,
        "jupyter-notebook": 8888,
        "visual-studio-code": 8080,
        mlflow: 5000,
        rstudio: 8787,
        custom: 8080,
        matlab: 8888,
      };
      this.toolTypeForName = toolType.label;
      this.editTool.containerPort = toolTypePortMap[toolType.value as ToolType] || 0;
      this.editTool.toolType = toolType.value as ToolType;
      this.editTool.externalUrl = "";
      // When changing the toolType, setting the connectionType to first one available for this toolType.
      const availableConnectionType: InternalConnectionType = this.availableConnectionTypeOptions.filter(
        (o) => !o.disable,
      )[0].value as InternalConnectionType;
      this.editTool.connectionType = availableConnectionType;
      this.updateToolName();
    },
    updateToolName(): void {
      const { toolType, connectionType } = this.editTool;
      if (toolType === ToolType.Custom) {
        this.editTool.name = "";
      } else {
        this.editTool.name = this.toolTypeForName;
        if (
          toolType !== ToolType.Wandb &&
          toolType !== ToolType.Comet &&
          connectionType !== InternalConnectionType.ExternalUrl
        ) {
          this.editTool.name += ` - ${this.connectionTypeForName}`;
        }
      }
    },
    updateAuthorizedUsers(val: boolean): void {
      this.editTool.authorizedUsers = val ? [] : null;
    },
  },
  watch: {
    editTool: {
      handler(): void {
        this.updateTool();
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.environment-tool-box {
  position: relative;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $black-54;
    font-size: 12px;
  }

  .gap {
    gap: 35px;
  }

  .bottom-section,
  .top-section {
    border: 1px solid $black-12;
  }

  .bottom-section {
    background-color: $body-background-color;
    border-top: none;
  }
}
</style>
