export enum EContractTypes {
  Trail = "trial",
  Normal = "normal",
  Freemium = "freemium",
}

export interface ITenant {
  id: number;
  name: string;
  displayName: string;
  contractType: EContractTypes;
  authProviderType: string;
  eulaSigningUser?: boolean;
  eula?: boolean;
  grafanaMetricsAuth: {
    grafanaMetricsUser: string;
    grafanaMetricsViewToken: string | null;
    grafanaMetricsWriterToken: string;
  };
  grafanaUrl: string;
  authClientID: string;
  cliClientId: string;
  authRealm: string;
  ssoEntityId: string;
  ssoRedirectUri: string;
  smgEnabled: boolean;
  logo?: string;
}

export enum ETenantType {
  SELF_HOSTED,
  SELF_HOSTED_SSO,
  SAAS_KEYCLOAK,
  SAAS_SSO,
}

export interface ITenantClientConfigInfo {
  airgapped: boolean;
  authFlow: string;
  realm: string;
  clientId: string;
  idpIssuerUrl: string;
  redirectUri?: string;
}

export interface ITenantServerConfigInfo {
  clientId: string;
  issuerUrl: string;
  groupsClaim?: string;
}

export interface ITenantConfigInfo {
  clientConfigInfo: ITenantClientConfigInfo;
  serverConfigInfo: ITenantServerConfigInfo;
}

export interface IAuthProviderData {
  ssoEnabled: boolean;
  isSelfHosted: boolean;
  issuerUrl: string;
}
