<template>
  <section class="deployment-index">
    <deployment-table v-if="isDeploymentsToggleOn" />
    <iframe-view v-else />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { IframeView } from "@/components/infra/iframe-view";
import DeploymentTable from "./deployment-table.vue";

// Stores
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    IframeView,
    DeploymentTable,
  },
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    isDeploymentsToggleOn(): boolean {
      return this.appStore.isNewDeploymentsPageOn;
    },
  },
});
</script>
