import type { INodePoolResources } from "@/models/project.model";
import {
  CPU_VALUE_FACTOR,
  DEFAULT_NODE_POOL_NAME,
  EMemoryUnit,
  EResourceState,
  EResourceType,
  type NodePoolAllocatedNonPreemptibleSumRecord,
} from "@/models/resource.model";
import type { INodePoolResourcesSum } from "@/models/resource.model";
import type { IDepartmentProject } from "@/models/department.model";
import type { IProject } from "@/models/project.model";
import type { INodePoolIsOverQuota } from "@/models/resource.model";
import type { NodePoolQuotaStatus } from "@/swagger-models/backend-client";

const MIB_TO_MB_FACTOR = 1.048576;
const MIB_TO_GB_FACTOR = 953.67431640625;
const MEMORY_SIZE_THRESHOLD = 9536.7431640625;
const BYTE_TO_MIB_FACTOR = 1048576;
export const resourceUtil = {
  sumOfResourcesByType,
  getNodePoolResourceSumByType,
  isResourcesUnlimited,
  getResourceDisplayValue,
  getResourcesDisplayValue,
  convertMemoryValue,
  fromGbToMib,
  fromMibToMb,
  fromMibToGb,
  fromMbToMib,
  getOverQuotaPriorityKeyByValue,
  getOverQuotaKeyByValue,
  calculateDepartmentQuota,
  isResourceExceedsQuota,
  projectsDeservedResources,
  isResourceUnlimited,
  getDeservedValue,
  sortNodePools,
  computeNodePoolResourceExceedsQuota,
  fromBytesToMiB,
  allocatedNonPreemptibleResources,
  isResourceUnderAllocatedNonPreemptible,
};

function sumOfResourcesByType(nodePoolsResources: INodePoolResources[], resourceType: EResourceType): number {
  return nodePoolsResources.reduce((sum, nodePool) => {
    return sum + (nodePool[resourceType].deserved || 0);
  }, 0);
}

function isResourcesUnlimited(resources: INodePoolResources[], type: EResourceType): boolean {
  return resources.every((nodePoolResource: INodePoolResources) => nodePoolResource[type].deserved === null);
}

function getNodePoolResourceSumByType(resource: INodePoolResources[], type: EResourceType): number | EResourceState {
  if (isResourcesUnlimited(resource, type)) {
    return EResourceState.Unlimited;
  }
  return sumOfResourcesByType(resource, type);
}

function getResourcesDisplayValue(resources: INodePoolResources[], resourceType: EResourceType): string {
  if (
    (resourceType === EResourceType.CPU || resourceType === EResourceType.MEMORY) &&
    isResourcesUnlimited(resources, resourceType)
  ) {
    return EResourceState.Unlimited;
  }
  const sumOfResources = sumOfResourcesByType(resources, resourceType);
  if (resourceType === EResourceType.CPU) {
    return (sumOfResourcesByType(resources, EResourceType.CPU) / CPU_VALUE_FACTOR).toFixed(2);
  } else if (resourceType === EResourceType.MEMORY) {
    const convertedValue = convertMemoryValue(sumOfResources);
    return `${convertedValue.value.toFixed(2)} ${convertedValue.selectedUnit}`;
  }

  return sumOfResources.toFixed(2);
}
function getResourceDisplayValue(value: number | null, resourceType: EResourceType): string {
  if (value === null) {
    if (resourceType === EResourceType.CPU || resourceType === EResourceType.MEMORY) {
      return EResourceState.Unlimited;
    }
    return "0.00";
  }

  if (resourceType === EResourceType.CPU) {
    return (value / CPU_VALUE_FACTOR).toFixed(2);
  } else if (resourceType === EResourceType.MEMORY) {
    const convertedValue = convertMemoryValue(value);
    return `${convertedValue.value.toFixed(2)} ${convertedValue.selectedUnit}`;
  }

  return value.toFixed(2);
}

function fromMibToMb(mib: number): number {
  return mib * MIB_TO_MB_FACTOR;
}

function fromMibToGb(mib: number): number {
  return mib / MIB_TO_GB_FACTOR;
}

function fromMbToMib(mib: number): number {
  return mib / MIB_TO_MB_FACTOR;
}

function fromGbToMib(mib: number): number {
  return mib * MIB_TO_GB_FACTOR;
}

function fromBytesToMiB(bytes: number): number {
  return bytes / BYTE_TO_MIB_FACTOR;
}

function convertMemoryValue(value: number): { value: number; selectedUnit: string } {
  const beforeDecimalPointPart = Math.trunc(value);
  if (beforeDecimalPointPart > MEMORY_SIZE_THRESHOLD) {
    return {
      value: fromMibToGb(value),
      selectedUnit: EMemoryUnit.GB,
    };
  } else {
    return {
      value: fromMibToMb(value),
      selectedUnit: EMemoryUnit.MB,
    };
  }
}

function getOverQuotaPriorityKeyByValue(value: number): string {
  switch (value) {
    case 0:
      return "None";
    case 1:
      return "Low";
    case 2:
      return "Medium";
    case 3:
      return "High";
    default:
      return "Medium";
  }
}
function getOverQuotaKeyByValue(value: number): string {
  switch (value) {
    case 0:
      return "Disabled";
    case 2:
      return "Enabled";
    default:
      return "Enabled";
  }
}

function calculateDepartmentQuota(
  departmentDeservedResource: number,
  resourceType: EResourceType,
  departmentDeservedResourceIsUnlimited = false,
): number | string {
  if (departmentDeservedResourceIsUnlimited) {
    return EResourceState.Unlimited;
  }
  return resourceUtil.getResourceDisplayValue(departmentDeservedResource, resourceType);
}

function isResourceExceedsQuota(
  projectsDeservedResourceWithCurrentInput: number,
  departmentDeservedResource: number,
  departmentDeservedResourceIsUnlimited = false,
): boolean {
  if (departmentDeservedResourceIsUnlimited) {
    return false;
  }
  return projectsDeservedResourceWithCurrentInput > departmentDeservedResource;
}

function isResourceUnderAllocatedNonPreemptible(val: number, allocatedNonPreemptibleResource: number): boolean {
  return val < allocatedNonPreemptibleResource;
}

function projectsDeservedResources(
  nodePoolsResources: INodePoolResources[],
  projectId: number | null,
  departmentProjects: IDepartmentProject[],
  getProjectById: (projectId: number) => IProject | undefined,
  isOnlyDefaultNodePools: boolean,
): Record<string, INodePoolResourcesSum> {
  let isAllCpuUnlimited = true;
  let isAllMemoryUnlimited = true;
  const nodePoolSum = {} as Record<string, INodePoolResourcesSum>;
  const computeNodePoolResourceSum = (nodePoolsResources: INodePoolResources[]) => {
    const resourcesSum: INodePoolResourcesSum = { gpu: 0, cpu: 0, memory: 0 };
    const cpuSum = resourceUtil.getNodePoolResourceSumByType(nodePoolsResources, EResourceType.CPU);
    const memorySum = resourceUtil.getNodePoolResourceSumByType(nodePoolsResources, EResourceType.MEMORY);
    resourcesSum.gpu = resourceUtil.sumOfResourcesByType(nodePoolsResources, EResourceType.GPU);
    if (cpuSum !== EResourceState.Unlimited) {
      isAllCpuUnlimited = false;
      resourcesSum.cpu = cpuSum;
    }
    if (memorySum !== EResourceState.Unlimited) {
      isAllMemoryUnlimited = false;
      resourcesSum.memory = memorySum;
    }
    return resourcesSum;
  };

  //sum current project
  nodePoolsResources.forEach((nodePool: INodePoolResources, index) => {
    nodePoolSum[nodePool.nodePool.name] = computeNodePoolResourceSum([nodePoolsResources[index]]);
  });

  //sum all other department projects
  departmentProjects.forEach((departmentProject: IDepartmentProject) => {
    const project = getProjectById(departmentProject.id);

    if (project && project.id !== projectId) {
      if (!isOnlyDefaultNodePools) {
        project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
          const resourcesSum = computeNodePoolResourceSum([nodePool]);
          nodePoolSum[nodePool.nodePool.name].gpu += resourcesSum.gpu;

          (nodePoolSum[nodePool.nodePool.name].cpu as number) += +resourcesSum.cpu;

          (nodePoolSum[nodePool.nodePool.name].memory as number) += +resourcesSum.memory;
        });
      } else {
        const resourcesSum = computeNodePoolResourceSum([project.nodePoolsResources[0]]);
        nodePoolSum[DEFAULT_NODE_POOL_NAME].gpu += resourcesSum.gpu;

        (nodePoolSum[DEFAULT_NODE_POOL_NAME].cpu as number) += +resourcesSum.cpu;

        (nodePoolSum[DEFAULT_NODE_POOL_NAME].memory as number) += +resourcesSum.memory;
      }
    }
  });

  nodePoolsResources.forEach((nodePool: INodePoolResources) => {
    if (isAllCpuUnlimited) {
      nodePoolSum[nodePool.nodePool.name].cpu = EResourceState.Unlimited;
    }
    if (isAllMemoryUnlimited) {
      nodePoolSum[nodePool.nodePool.name].memory = EResourceState.Unlimited;
    }
  });

  return nodePoolSum;
}

function isResourceUnlimited(resourceValue: number | EResourceState): boolean {
  return resourceValue === EResourceState.Unlimited;
}
function getDeservedValue(resourceValue: EResourceState | number) {
  if (resourceValue === EResourceState.Unlimited) {
    return 0;
  }
  return resourceValue;
}

function sortNodePools(nodePoolsResources: INodePoolResources[]): INodePoolResources[] {
  const sortedNodePools = [...nodePoolsResources].sort(
    ({ nodePool: { name: nameA } }, { nodePool: { name: nameB } }) => {
      if (!nameA || !nameB) return 0;
      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
      return 0;
    },
  );

  const defaultNodePool = sortedNodePools.find((np) => np.nodePool && np.nodePool.name === DEFAULT_NODE_POOL_NAME);
  if (!defaultNodePool) return sortedNodePools;
  const sortedNodePoolsWithoutDefault = sortedNodePools.filter(
    (np) => np.nodePool && np.nodePool.name !== DEFAULT_NODE_POOL_NAME,
  );
  return [defaultNodePool, ...sortedNodePoolsWithoutDefault];
}

function computeNodePoolResourceExceedsQuota(
  projectsResources: INodePoolResourcesSum,
  departmentResources: INodePoolResourcesSum,
): INodePoolIsOverQuota {
  const nodePoolIsOverQuota = { gpu: false, cpu: false, memory: false } as INodePoolIsOverQuota;
  if (!departmentResources || !projectsResources) return nodePoolIsOverQuota;
  nodePoolIsOverQuota.gpu = projectsResources.gpu > departmentResources.gpu;

  if (departmentResources.cpu === EResourceState.Unlimited) {
    nodePoolIsOverQuota.cpu = false;
  } else {
    nodePoolIsOverQuota.cpu = +projectsResources.cpu > departmentResources.cpu;
  }

  if (departmentResources.memory === EResourceState.Unlimited) {
    nodePoolIsOverQuota.memory = false;
  } else {
    //memory in DB is in MIB and in UI is in MG/GB so ignore the fractional digits
    nodePoolIsOverQuota.memory = Math.trunc(+projectsResources.memory) > Math.round(departmentResources.memory);
  }

  return nodePoolIsOverQuota;
}

function allocatedNonPreemptibleResources(
  nodePoolQuotaStatuses: Array<NodePoolQuotaStatus>,
): NodePoolAllocatedNonPreemptibleSumRecord {
  return nodePoolQuotaStatuses.reduce((result, status) => {
    result[status.nodePoolName as string] = {
      cpu: status.allocatedNonPreemptible?.cpu || 0,
      gpu: status.allocatedNonPreemptible?.gpu || 0,
      memory: status.allocatedNonPreemptible?.memory || 0,
    };
    return result;
  }, {} as NodePoolAllocatedNonPreemptibleSumRecord);
}
