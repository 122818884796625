<template>
  <q-layout view="hHh lpR fFf" class="main-layout">
    <top-header @toggle-drawer="toggleDrawer" v-if="!fullPage" :full-width-page="fullWidthPage" />
    <side-nav
      v-if="!fullPage"
      :mini-state="miniState"
      :overlay="overlay"
      :show-drawer="showDrawer"
      @mouse-over="onMouseOver"
      @mouse-out="onMouseOut"
    />
    <q-page-container class="main-content" aid="main-content">
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { TopHeader } from "@/components/infra/top-header";
import { SideNav } from "@/components/infra/side-nav";

// stores
import { useAppStore } from "@/stores/app.store";

// models
import { ESidebarState } from "@/models/global.model";

export default defineComponent({
  components: {
    TopHeader,
    SideNav,
  },
  data() {
    return {
      appStore: useAppStore(),
      miniState: true as boolean,
      overlay: true as boolean,
      showDrawer: true as boolean,
    };
  },
  mounted() {
    this.updateStateFromRoute();
  },
  computed: {
    fullWidthPage(): boolean {
      return !!this.$route.meta.fullWidth;
    },
    fullPage(): boolean {
      return !!this.$route.meta.fullPage;
    },
  },
  methods: {
    toggleDrawer(): void {
      this.miniState = !this.miniState;
      this.overlay = this.miniState;
      this.updateSideBarState();
    },
    onMouseOver(): void {
      this.miniState = false;
      this.updateSideBarState();
    },
    onMouseOut(): void {
      this.miniState = true;
      this.updateSideBarState();
    },
    updateSideBarState(): void {
      this.appStore.setSidebarState(this.miniState ? ESidebarState.Mini : ESidebarState.Full);
    },
    updateStateFromRoute(): void {
      this.showDrawer = !this.fullWidthPage;
      if (!this.showDrawer) {
        this.appStore.setSidebarState(ESidebarState.Hidden);
      } else {
        this.updateSideBarState();
      }
    },
  },
  watch: {
    $route() {
      this.updateStateFromRoute();
    },
  },
});
</script>
<style scoped lang="scss">
.main-layout {
  .main-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
