import { HttpErrorResponse } from "@/models/http-response.model";
import { alertUtil } from "@/utils/alert.util";
import type { QNotifyCreateOptions } from "quasar/dist/types";
import { httpResponseService } from "@/services/infra/http-response.service/http-response.service";
interface IErrorCodeOptoins {
  generalMessage: string;
  [key: number]: string;
}

export class ErrorAlert {
  options: IErrorCodeOptoins;

  constructor(options: IErrorCodeOptoins) {
    this.options = options;
  }

  getNotification(error: unknown, removePattern?: RegExp): QNotifyCreateOptions {
    if (error instanceof HttpErrorResponse) {
      if (removePattern) {
        error.message = error.message.replace(removePattern, "");
      }
      return this.httpError(error);
    } else {
      return this.nonHttpError(error);
    }
  }

  private httpError(error: HttpErrorResponse): QNotifyCreateOptions {
    console.error(error.serialize());
    if (!error.statusCode || error.statusCode >= 500) {
      if (error.statusCode >= 500 && !httpResponseService.isDefaultMessage(error.statusCode, error.message)) {
        return alertUtil.getError(error.message);
      }

      return alertUtil.getError(this.options.generalMessage);
    } else {
      const message = this.options[error.statusCode] ? this.options[error.statusCode] : error.message;
      return alertUtil.getError(message);
    }
  }

  private nonHttpError(error: unknown): QNotifyCreateOptions {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error(error);
    }
    return alertUtil.getError(this.options.generalMessage);
  }

  static createMessage(entity: string): string {
    return `Create ${entity} succeeded`;
  }

  static updateMessage(entity: string): string {
    return `Update ${entity} succeeded`;
  }

  static failedCreateMessage(entity: string): string {
    return `Create ${entity} failed`;
  }

  static failedUpdateMessage(entity: string): string {
    return `Update ${entity} failed`;
  }

  static failedLoadingMessage(entity: string): string {
    return `Failed to load ${entity}`;
  }

  static conflictMessage(entity: string): string {
    return `${entity} already exists`;
  }

  static failedValidateMessage(entity: string): string {
    return `${entity} validation failed`;
  }
}
