// Stores
import { defineStore } from "pinia";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { IUser, IUserCreationRequest, IUserCreationResponse } from "@/models/user.model";
import { userService } from "@/services/control-plane/user.service/user.service";

export const useUserStore = defineStore("User", {
  state: () => ({
    users: [] as Array<IUser>,
    namespaces: {} as Record<string, string | undefined>,
    lastCreatedUserId: null as null | string,
  }),
  getters: {
    usersList(): Array<IUser> {
      return this.users.filter((user: IUser) => user.id !== this.lastCreatedUserId);
    },
    lastCreatedUser(): IUser | null {
      if (!this.lastCreatedUserId) return null;
      return this.users.find((user: IUser) => user.id === this.lastCreatedUserId) || null;
    },
    localUsers(): Array<IUser> {
      return this.users.filter((user: IUser) => user.isLocal);
    },
  },
  actions: {
    setLastCreatedUserId(userId: string): void {
      this.lastCreatedUserId = userId;
    },
    removeLastCreatedUser(): void {
      this.lastCreatedUserId = null;
    },
    isUserExists(email: string): boolean {
      return this.users.some((user) => user.username === email);
    },
    async loadUsers(filterBy?: IFilterBy, localOnly = true): Promise<void> {
      this.users = await userService.list(filterBy, localOnly);
    },
    async remove(userId: string): Promise<void> {
      await userService.remove(userId);
    },
    async create(userCreationRequest: IUserCreationRequest): Promise<IUserCreationResponse> {
      return await userService.create(userCreationRequest);
    },
    async resetPassword(userId: string): Promise<string> {
      return await userService.resetPassword(userId);
    },
  },
});
