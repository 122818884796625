/* eslint-disable */

import type { IUser } from "@/models/user.model";
import type { ITenant } from "@/models/tenant.model";

export const pendoService = {
  init,
};

declare const pendo: any;

async function init(user: IUser, tenant: ITenant) {
  // in your authentication promise handler or callback
  await initPendoDOMElement();
  pendo.initialize({
    visitor: {
      id: user.email, // Required if user is logged in
      email: user.email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: user.name, // Recommended if using Pendo Feedback
      role: user.roles.join(","),

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: tenant.id, // Highly recommended
      name: tenant.name, // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      planLevel: tenant.contractType,
      signedEula: tenant.signedEula,
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
}

function initPendoDOMElement() {
  return new Promise((resolve) => {
    (function (apiKey) {
      (function (p: any, e?: any, n?: any, d?: any, o?: any) {
        let w: any, x: any;
        o = p[d] = p[d] || {};
        o._q = [];
        const v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function (...args: any[]) {
                o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(args, 0)));
              };
          })(v[w]);
        const y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);

        resolve(true);
      })(window, document, "script", "pendo");
    })("864fbd80-1c56-4f6b-40ed-ac85929e4c18");
  });
}
