// Stores
import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";
// Services
import { jobService } from "@/services/control-plane/job.service/job.service";
// Models
import type { IFilterBy } from "@/models/filter.model";
//api
import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import { oldWorkloadService } from "@/services/old-workload.service/old-workload.service";

export const useOldWorkloadStore = defineStore("OldWorkloadStore", {
  state: () => ({
    workloads: [] as Array<DisplayedJob>,
    loading: false as boolean,
    namespaces: {} as Record<string, string | undefined>,
    clusterStore: useClusterStore(),
    workloadAdded: null as null | DisplayedJob,
  }),
  getters: {
    workloadList(): Array<DisplayedJob> {
      if (!this.workloadAdded) return this.workloads;
      return this.workloads.filter((workload: DisplayedJob) => workload.jobName !== this.workloadAdded?.jobName);
    },
    lastCreatedWorkload(): DisplayedJob | null {
      return this.workloadAdded;
    },
  },
  actions: {
    async loadWorkloads(filterBy?: IFilterBy): Promise<void> {
      try {
        this.loading = true;
        const workloads: DisplayedJob[] = await jobService.list(this.clusterStore.currentClusterId, filterBy);
        if (this.workloadAdded) {
          //update top row with realtime data
          const updatedWorkload: DisplayedJob | undefined = workloads.find(
            (workload: DisplayedJob) => workload.jobName === this.workloadAdded?.jobName,
          );
          if (updatedWorkload) {
            this.workloadAdded = updatedWorkload;
          }
        }
        this.workloads = workloads;
      } finally {
        this.loading = false;
      }
    },
    async deleteWorkload(workload: DisplayedJob): Promise<DisplayedJob> {
      const deletedWorkload: DisplayedJob = await oldWorkloadService.remove(workload);
      this.workloads = this.workloads.filter((currWorkload: DisplayedJob) => {
        return workload.jobName === deletedWorkload.jobName ? deletedWorkload : currWorkload;
      });
      return deletedWorkload;
    },
    removeWorkloadAdded(): void {
      this.workloadAdded = null;
    },
    setWorkloadAdded(workloadAdded: DisplayedJob): void {
      this.workloadAdded = workloadAdded;
    },
    removeWorkloadLocally(workloadToRemove: DisplayedJob): void {
      this.workloads = this.workloads.filter((workload: DisplayedJob) => workload.jobName !== workloadToRemove.jobName);
    },
  },
});
