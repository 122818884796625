/* tslint:disable */
/* eslint-disable */
/**
 * Assets
 * An API for managing policies
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Details of an annotation which is populated into the container.
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * The name of the annotation.
     * @type {string}
     * @memberof Annotation
     */
    'name': string;
    /**
     * The value to set the annotation to.
     * @type {string}
     * @memberof Annotation
     */
    'value': string;
    /**
     * Whether to exclude this annotation from the workload. This is necessary in case the annotation definition is inherited from a policy.
     * @type {boolean}
     * @memberof Annotation
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnotationsRules
 */
export interface AnnotationsRules {
    /**
     * 
     * @type {ItemRules}
     * @memberof AnnotationsRules
     */
    'itemRules'?: ItemRules | null;
}
/**
 * 
 * @export
 * @interface ArrayRules
 */
export interface ArrayRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof ArrayRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether this array is mandatory. Mandatory array must include at least one item. default to false.
     * @type {boolean}
     * @memberof ArrayRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the array, as a whole, is editable. default to false.
     * @type {boolean}
     * @memberof ArrayRules
     */
    'canEdit'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AutoScalingRules
 */
export interface AutoScalingRules {
    /**
     * 
     * @type {IntegerRules}
     * @memberof AutoScalingRules
     */
    'minReplicas'?: IntegerRules | null;
    /**
     * 
     * @type {IntegerRules}
     * @memberof AutoScalingRules
     */
    'maxReplicas'?: IntegerRules | null;
    /**
     * 
     * @type {StringRulesOptional}
     * @memberof AutoScalingRules
     */
    'thresholdMetric'?: StringRulesOptional | null;
    /**
     * 
     * @type {IntegerRules}
     * @memberof AutoScalingRules
     */
    'thresholdValue'?: IntegerRules | null;
}
/**
 * 
 * @export
 * @interface BooleanRules
 */
export interface BooleanRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof BooleanRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory. default to false.
     * @type {boolean}
     * @memberof BooleanRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable. default to true
     * @type {boolean}
     * @memberof BooleanRules
     */
    'canEdit'?: boolean | null;
}
/**
 * 
 * @export
 * @interface BooleanRulesOptional
 */
export interface BooleanRulesOptional {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof BooleanRulesOptional
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the value of the field is editable. default to true
     * @type {boolean}
     * @memberof BooleanRulesOptional
     */
    'canEdit'?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Capability = {
    AuditControl: 'AUDIT_CONTROL',
    AuditRead: 'AUDIT_READ',
    AuditWrite: 'AUDIT_WRITE',
    BlockSuspend: 'BLOCK_SUSPEND',
    Chown: 'CHOWN',
    DacOverride: 'DAC_OVERRIDE',
    DacReadSearch: 'DAC_READ_SEARCH',
    Fowner: 'FOWNER',
    Fsetid: 'FSETID',
    IpcLock: 'IPC_LOCK',
    IpcOwner: 'IPC_OWNER',
    Kill: 'KILL',
    Lease: 'LEASE',
    LinuxImmutable: 'LINUX_IMMUTABLE',
    MacAdmin: 'MAC_ADMIN',
    MacOverride: 'MAC_OVERRIDE',
    Mknod: 'MKNOD',
    NetAdmin: 'NET_ADMIN',
    NetBindService: 'NET_BIND_SERVICE',
    NetBroadcast: 'NET_BROADCAST',
    NetRaw: 'NET_RAW',
    Setgid: 'SETGID',
    Setfcap: 'SETFCAP',
    Setpcap: 'SETPCAP',
    Setuid: 'SETUID',
    SysAdmin: 'SYS_ADMIN',
    SysBoot: 'SYS_BOOT',
    SysChroot: 'SYS_CHROOT',
    SysModule: 'SYS_MODULE',
    SysNice: 'SYS_NICE',
    SysPacct: 'SYS_PACCT',
    SysPtrace: 'SYS_PTRACE',
    SysRawio: 'SYS_RAWIO',
    SysResource: 'SYS_RESOURCE',
    SysTime: 'SYS_TIME',
    SysTtyConfig: 'SYS_TTY_CONFIG',
    Syslog: 'SYSLOG',
    WakeAlarm: 'WAKE_ALARM'
} as const;

export type Capability = typeof Capability[keyof typeof Capability];


/**
 * Claim information for the newly created PVC. The information should not be provided when attempting to use existing PVC.
 * @export
 * @interface ClaimInfo
 */
export interface ClaimInfo {
    /**
     * Requested size for the PVC. Mandatory when existingPvc is false.
     * @type {string}
     * @memberof ClaimInfo
     */
    'size': string;
    /**
     * Storage class name to associate with the PVC. This parameter may be omitted if there is a single storage class in the system, or you are using the default storage class. Further details at https://kubernetes.io/docs/concepts/storage/storage-classes.
     * @type {string}
     * @memberof ClaimInfo
     */
    'storageClass'?: string | null;
    /**
     * 
     * @type {PvcAccessModes}
     * @memberof ClaimInfo
     */
    'accessModes'?: PvcAccessModes | null;
    /**
     * The volume mode required by the claim, either Filesystem (default) or Block.
     * @type {string}
     * @memberof ClaimInfo
     */
    'volumeMode'?: ClaimInfoVolumeModeEnum;
}

export const ClaimInfoVolumeModeEnum = {
    Filesystem: 'Filesystem',
    Block: 'Block'
} as const;

export type ClaimInfoVolumeModeEnum = typeof ClaimInfoVolumeModeEnum[keyof typeof ClaimInfoVolumeModeEnum];

/**
 * 
 * @export
 * @interface ClaimInfoRules
 */
export interface ClaimInfoRules {
    /**
     * 
     * @type {StringRules}
     * @memberof ClaimInfoRules
     */
    'size'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof ClaimInfoRules
     */
    'storageClass'?: StringRules | null;
}
/**
 * 
 * @export
 * @interface ClusterPolicy
 */
export interface ClusterPolicy {
    /**
     * 
     * @type {ClusterPolicyRequestInfo}
     * @memberof ClusterPolicy
     */
    'requestInfo'?: ClusterPolicyRequestInfo;
    /**
     * 
     * @type {ClusterPolicyResponseInfo}
     * @memberof ClusterPolicy
     */
    'responseInfo'?: ClusterPolicyResponseInfo;
    /**
     * 
     * @type {ClusterPolicySystemInfo}
     * @memberof ClusterPolicy
     */
    'systemInfo'?: ClusterPolicySystemInfo;
    /**
     * 
     * @type {Array<ClusterPolicyClusterPoliciesInner>}
     * @memberof ClusterPolicy
     */
    'clusterPolicies'?: Array<ClusterPolicyClusterPoliciesInner>;
}
/**
 * 
 * @export
 * @interface ClusterPolicyClusterPoliciesInner
 */
export interface ClusterPolicyClusterPoliciesInner {
    /**
     * 
     * @type {PolicyCreationFields}
     * @memberof ClusterPolicyClusterPoliciesInner
     */
    'meta': PolicyCreationFields;
    /**
     * 
     * @type {object}
     * @memberof ClusterPolicyClusterPoliciesInner
     */
    'effective'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ClusterPolicyClusterPoliciesInner
     */
    'isDeleted'?: boolean | null;
    /**
     * number which uniquely identifies each update.
     * @type {number}
     * @memberof ClusterPolicyClusterPoliciesInner
     */
    'updateCounter'?: number;
}
/**
 * 
 * @export
 * @interface ClusterPolicyRequestInfo
 */
export interface ClusterPolicyRequestInfo {
    /**
     * after parameter as requested by the agent
     * @type {number}
     * @memberof ClusterPolicyRequestInfo
     */
    'after'?: number;
}
/**
 * 
 * @export
 * @interface ClusterPolicyResponseInfo
 */
export interface ClusterPolicyResponseInfo {
    /**
     * lowest update count included in this bulk. zero if the bulk is empty.
     * @type {number}
     * @memberof ClusterPolicyResponseInfo
     */
    'lowestUpdate'?: number;
    /**
     * highest update count included in this bulk. zero if the bulk is empty.
     * @type {number}
     * @memberof ClusterPolicyResponseInfo
     */
    'highestUpdate'?: number;
}
/**
 * 
 * @export
 * @interface ClusterPolicySystemInfo
 */
export interface ClusterPolicySystemInfo {
    /**
     * the highest update count in the system.
     * @type {number}
     * @memberof ClusterPolicySystemInfo
     */
    'highestUpdate'?: number;
    /**
     * number of policy updates the system is configured to include in every bulk.
     * @type {number}
     * @memberof ClusterPolicySystemInfo
     */
    'updatesPerBulk'?: number;
}
/**
 * 
 * @export
 * @interface ComputeAssetSpec
 */
export interface ComputeAssetSpec {
    /**
     * Requested number of GPU devices. Currently if more than one device is requested, it is not possible to provide values for gpuMemory/migProfile/gpuPortion.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuDevicesRequest'?: number | null;
    /**
     * 
     * @type {GpuRequestType}
     * @memberof ComputeAssetSpec
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * Required if and only if gpuRequestType is portion. States the portion of the GPU to allocate for the created workload, per GPU device, between 0 and 1. The default is no allocated GPUs.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuPortionRequest'?: number | null;
    /**
     * Limitations on the portion consumed by the workload, per GPU device. The system guarantees The gpuPotionLimit must be no less than the gpuPortionRequest.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'gpuPortionLimit'?: number | null;
    /**
     * Required if and only if gpuRequestType is memory. States the GPU memory to allocate for the created workload, per GPU device. Note that the workload will not be scheduled unless the system can guarantee this amount of GPU memory to the workload.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'gpuMemoryRequest'?: string | null;
    /**
     * Limitation on the memory consumed by the workload, per GPU device. The system guarantees The gpuMemoryLimit must be no less than gpuMemoryRequest.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'gpuMemoryLimit'?: string | null;
    /**
     * 
     * @type {MigProfile}
     * @memberof ComputeAssetSpec
     */
    'migProfile'?: MigProfile | null;
    /**
     * CPU units to allocate for the created workload (0.5, 1, .etc). The workload will receive at least this amount of CPU. Note that the workload will not be scheduled unless the system can guarantee this amount of CPUs to the workload.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'cpuCoreRequest'?: number | null;
    /**
     * Limitations on the number of CPUs consumed by the workload (0.5, 1, .etc). The system guarantees that this workload will not be able to consume more than this amount of CPUs.
     * @type {number}
     * @memberof ComputeAssetSpec
     */
    'cpuCoreLimit'?: number | null;
    /**
     * The amount of CPU memory to allocate for this workload (1G, 20M, .etc). The workload will receive at least this amount of memory. Note that the workload will not be scheduled unless the system can guarantee this amount of memory to the workload
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'cpuMemoryRequest'?: string | null;
    /**
     * Limitations on the CPU memory to allocate for this workload (1G, 20M, .etc). The system guarantees that this workload will not be able to consume more than this amount of memory. The workload will receive an error when trying to allocate more memory than this limit.
     * @type {string}
     * @memberof ComputeAssetSpec
     */
    'cpuMemoryLimit'?: string | null;
    /**
     * A large /dev/shm device to mount into a container running the created workload. An shm is a shared file system mounted on RAM.
     * @type {boolean}
     * @memberof ComputeAssetSpec
     */
    'largeShmRequest'?: boolean | null;
    /**
     * Set of extended resources with their quantity
     * @type {Array<ExtendedResource>}
     * @memberof ComputeAssetSpec
     */
    'extendedResources'?: Array<ExtendedResource> | null;
}
/**
 * 
 * @export
 * @interface ComputeRules
 */
export interface ComputeRules {
    /**
     * 
     * @type {NumberRules}
     * @memberof ComputeRules
     */
    'cpuCoreRequest'?: NumberRules | null;
    /**
     * 
     * @type {NumberRules}
     * @memberof ComputeRules
     */
    'cpuCoreLimit'?: NumberRules | null;
    /**
     * 
     * @type {QuantityRules}
     * @memberof ComputeRules
     */
    'cpuMemoryRequest'?: QuantityRules | null;
    /**
     * 
     * @type {QuantityRules}
     * @memberof ComputeRules
     */
    'cpuMemoryLimit'?: QuantityRules | null;
    /**
     * 
     * @type {ExtendedResourcesRules}
     * @memberof ComputeRules
     */
    'extendedResources'?: ExtendedResourcesRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof ComputeRules
     */
    'largeShmRequest'?: BooleanRules | null;
    /**
     * 
     * @type {GpuRequestRules}
     * @memberof ComputeRules
     */
    'gpuRequestType'?: GpuRequestRules | null;
    /**
     * 
     * @type {MigProfileRules}
     * @memberof ComputeRules
     */
    'migProfile'?: MigProfileRules | null;
    /**
     * 
     * @type {IntegerRules}
     * @memberof ComputeRules
     */
    'gpuDevicesRequest'?: IntegerRules | null;
    /**
     * 
     * @type {NumberRules}
     * @memberof ComputeRules
     */
    'gpuPortionRequest'?: NumberRules | null;
    /**
     * 
     * @type {NumberRules}
     * @memberof ComputeRules
     */
    'gpuPortionLimit'?: NumberRules | null;
    /**
     * 
     * @type {QuantityRules}
     * @memberof ComputeRules
     */
    'gpuMemoryRequest'?: QuantityRules | null;
    /**
     * 
     * @type {QuantityRules}
     * @memberof ComputeRules
     */
    'gpuMemoryLimit'?: QuantityRules | null;
}
/**
 * 
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * A unique name of this connection. This name correlates between the connection information specified at the environment asset, to the information about the connection as specified in SpecificEnv for a specific workspace.
     * @type {string}
     * @memberof Connection
     */
    'name': string;
    /**
     * Internal tools (isExternal=false) are tools that run as part of the container. External tools (isExternal=true) run outside the container, typically in the cloud.
     * @type {boolean}
     * @memberof Connection
     */
    'isExternal'?: boolean;
    /**
     * 
     * @type {InternalToolInfo}
     * @memberof Connection
     */
    'internalToolInfo'?: InternalToolInfo | null;
    /**
     * 
     * @type {ExternalToolInfo}
     * @memberof Connection
     */
    'externalToolInfo'?: ExternalToolInfo | null;
}
/**
 * 
 * @export
 * @interface EnvironmentAssetSpec
 */
export interface EnvironmentAssetSpec {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof EnvironmentAssetSpec
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof EnvironmentAssetSpec
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof EnvironmentAssetSpec
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'supplementalGroups'?: string | null;
    /**
     * Docker image name. More info at https://kubernetes.io/docs/concepts/containers/images Image name is mandatory for creating a workspace.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'image'?: string | null;
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof EnvironmentAssetSpec
     */
    'imagePullPolicy'?: ImagePullPolicy | null;
    /**
     * Container\'s working directory. If not specified, the container runtime default will be used, which might be configured in the container image.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'workingDir'?: string | null;
    /**
     * Whether to enable host IPC. Defaults to false.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'hostIpc'?: boolean | null;
    /**
     * Whether to enable host networking. Default to false.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'hostNetwork'?: boolean | null;
    /**
     * List of connections that either expose ports from the container (each port is associated with a tool that the container runs), or URL\'s to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
     * @type {Array<Connection>}
     * @memberof EnvironmentAssetSpec
     */
    'connections'?: Array<Connection>;
    /**
     * Whether to create a home directory for the container.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'createHomeDir'?: boolean | null;
    /**
     * Allow the container running the workload and all launched processes to gain additional privileges after the workload starts. For more information consult the User Identity in Container guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'allowPrivilegeEscalation'?: boolean | null;
    /**
     * Indicate the way to determine the user and group ids of the container. The options are a. fromTheImage - user and group ids are determined by the docker image that the container runs. this is the default option. b. custom - user and group ids can be specified in the environment asset and/or the workspace creation request. c. idpToken - user and group ids are determined according to the identity provider (idp) access token. This option is intended for internal use of the environment UI form. For further details consult the User Identity guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'uidGidSource'?: EnvironmentAssetSpecUidGidSourceEnum;
    /**
     * Allow specifying uid/gid as part of create workspace. This is relevant only for custom uigGidSource.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'overrideUidGidInWorkspace'?: boolean;
    /**
     * The POSIX capabilities to add when running containers. Defaults to the default set of capabilities granted by the container runtime.
     * @type {Array<Capability>}
     * @memberof EnvironmentAssetSpec
     */
    'capabilities'?: Array<Capability> | null;
    /**
     * Indicates which kind of seccomp profile will be applied to the container. The options are a. RuntimeDefault - the container runtime default profile should be used. b. Unconfined - no profile should be applied. c. Localhost is not yet supported by Run:ai.
     * @type {string}
     * @memberof EnvironmentAssetSpec
     */
    'seccompProfileType'?: EnvironmentAssetSpecSeccompProfileTypeEnum;
    /**
     * Indicates that the container must run as a non-root user.
     * @type {boolean}
     * @memberof EnvironmentAssetSpec
     */
    'runAsNonRoot'?: boolean | null;
}

export const EnvironmentAssetSpecUidGidSourceEnum = {
    FromTheImage: 'fromTheImage',
    FromIdpToken: 'fromIdpToken',
    Custom: 'custom'
} as const;

export type EnvironmentAssetSpecUidGidSourceEnum = typeof EnvironmentAssetSpecUidGidSourceEnum[keyof typeof EnvironmentAssetSpecUidGidSourceEnum];
export const EnvironmentAssetSpecSeccompProfileTypeEnum = {
    RuntimeDefault: 'RuntimeDefault',
    Unconfined: 'Unconfined',
    Localhost: 'Localhost'
} as const;

export type EnvironmentAssetSpecSeccompProfileTypeEnum = typeof EnvironmentAssetSpecSeccompProfileTypeEnum[keyof typeof EnvironmentAssetSpecSeccompProfileTypeEnum];

/**
 * 
 * @export
 * @interface EnvironmentRules
 */
export interface EnvironmentRules {
    /**
     * 
     * @type {BooleanRules}
     * @memberof EnvironmentRules
     */
    'allowPrivilegeEscalation'?: BooleanRules | null;
    /**
     * 
     * @type {StringRulesOptional}
     * @memberof EnvironmentRules
     */
    'args'?: StringRulesOptional | null;
    /**
     * 
     * @type {ArrayRules}
     * @memberof EnvironmentRules
     */
    'capabilities'?: ArrayRules | null;
    /**
     * 
     * @type {StringRulesOptional}
     * @memberof EnvironmentRules
     */
    'command'?: StringRulesOptional | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof EnvironmentRules
     */
    'createHomeDir'?: BooleanRules | null;
    /**
     * 
     * @type {EnvironmentVariablesRules}
     * @memberof EnvironmentRules
     */
    'environmentVariables'?: EnvironmentVariablesRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof EnvironmentRules
     */
    'hostIpc'?: BooleanRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof EnvironmentRules
     */
    'hostNetwork'?: BooleanRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof EnvironmentRules
     */
    'image'?: StringRules | null;
    /**
     * 
     * @type {ImagePullPolicyRules}
     * @memberof EnvironmentRules
     */
    'imagePullPolicy'?: ImagePullPolicyRules | null;
    /**
     * 
     * @type {BooleanRulesOptional}
     * @memberof EnvironmentRules
     */
    'overrideUidGidInWorkspace'?: BooleanRulesOptional | null;
    /**
     * 
     * @type {IntegerRulesOptional}
     * @memberof EnvironmentRules
     */
    'runAsUid'?: IntegerRulesOptional | null;
    /**
     * 
     * @type {IntegerRulesOptional}
     * @memberof EnvironmentRules
     */
    'runAsGid'?: IntegerRulesOptional | null;
    /**
     * 
     * @type {StringRulesOptional}
     * @memberof EnvironmentRules
     */
    'supplementalGroups'?: StringRulesOptional | null;
    /**
     * 
     * @type {StringRules}
     * @memberof EnvironmentRules
     */
    'uidGidSource'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof EnvironmentRules
     */
    'workingDir'?: StringRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof EnvironmentRules
     */
    'runAsNonRoot'?: BooleanRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof EnvironmentRules
     */
    'seccompProfileType'?: StringRules | null;
}
/**
 * Details of an environment variable which is populated into the container.
 * @export
 * @interface EnvironmentVariable
 */
export interface EnvironmentVariable {
    /**
     * The name of the environment variable.
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'name': string;
    /**
     * The value to set the environment variable to.
     * @type {string}
     * @memberof EnvironmentVariable
     */
    'value': string;
    /**
     * Whether to exclude this environment variable from the workload. This is necessary in case the variable definition is inherited from a policy.
     * @type {boolean}
     * @memberof EnvironmentVariable
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface EnvironmentVariablesRules
 */
export interface EnvironmentVariablesRules {
    /**
     * 
     * @type {ItemRules}
     * @memberof EnvironmentVariablesRules
     */
    'itemRules'?: ItemRules | null;
}
/**
 * Quantity of an extended resource.
 * @export
 * @interface ExtendedResource
 */
export interface ExtendedResource {
    /**
     * The name of the extended resource.
     * @type {string}
     * @memberof ExtendedResource
     */
    'resource': string;
    /**
     * The requested quantity for the given resource.
     * @type {string}
     * @memberof ExtendedResource
     */
    'quantity': string;
    /**
     * Whether to exclude this extended resource from the workload. This is necessary in case the extended resource definition is inherited from a policy.
     * @type {boolean}
     * @memberof ExtendedResource
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface ExtendedResourcesRules
 */
export interface ExtendedResourcesRules {
    /**
     * 
     * @type {ItemRules}
     * @memberof ExtendedResourcesRules
     */
    'itemRules'?: ItemRules | null;
    /**
     * 
     * @type {ExtendedResourcesRulesMembers}
     * @memberof ExtendedResourcesRules
     */
    'members'?: ExtendedResourcesRulesMembers | null;
}
/**
 * 
 * @export
 * @interface ExtendedResourcesRulesMembers
 */
export interface ExtendedResourcesRulesMembers {
    /**
     * 
     * @type {StringRules}
     * @memberof ExtendedResourcesRulesMembers
     */
    'quantity'?: StringRules | null;
}
/**
 * Information of the external tool.
 * @export
 * @interface ExternalToolInfo
 */
export interface ExternalToolInfo {
    /**
     * 
     * @type {ExternalToolType}
     * @memberof ExternalToolInfo
     */
    'toolType': ExternalToolType;
    /**
     * The external url for connecting to the external tool. The url can include environment variables that will be replaced with the value provided for those variables when the workspace is created.
     * @type {string}
     * @memberof ExternalToolInfo
     */
    'externalUrl': string;
}
/**
 * The type of external tool that is associated with the connection. External tool typically runs in the cloud and require external url for connecting to it.
 * @export
 * @enum {string}
 */

export const ExternalToolType = {
    Wandb: 'wandb',
    Comet: 'comet'
} as const;

export type ExternalToolType = typeof ExternalToolType[keyof typeof ExternalToolType];


/**
 * 
 * @export
 * @interface ExternalUrlInfo
 */
export interface ExternalUrlInfo {
    /**
     * Whether external url is provided in the specific env of the workspace. Default is false, and in this case the external url is auto generated by the system.
     * @type {boolean}
     * @memberof ExternalUrlInfo
     */
    'isCustomUrl'?: boolean;
    /**
     * Default value for the external url. You can override it in the specific env of the workspace. Should be specified for ExternalUrl only.
     * @type {string}
     * @memberof ExternalUrlInfo
     */
    'externalUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface GitAssetRules
 */
export interface GitAssetRules {
    /**
     * 
     * @type {StringRules}
     * @memberof GitAssetRules
     */
    'repository'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof GitAssetRules
     */
    'branch'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof GitAssetRules
     */
    'revision'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof GitAssetRules
     */
    'path'?: StringRules | null;
}
/**
 * 
 * @export
 * @interface GitAssetSpec
 */
export interface GitAssetSpec {
    /**
     * URL to a remote git repository. The content of this repository will be mapped to the container running the workload. Repository name is mandatory for creating a workspace.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'repository': string | null;
    /**
     * Specific branch to synchronize the repository from.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'branch'?: string | null;
    /**
     * Specific revision to synchronize the repository from.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'revision'?: string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'path': string | null;
    /**
     * ID of credentials asset of type password. Needed for non public repository which requires authentication.
     * @type {string}
     * @memberof GitAssetSpec
     */
    'passwordAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface GpuRequestOptions
 */
export interface GpuRequestOptions {
    /**
     * Limit the permitted values for gpuRequest to a given set of values.
     * @type {Array<GpuRequestOptionsOptionsInner>}
     * @memberof GpuRequestOptions
     */
    'options'?: Array<GpuRequestOptionsOptionsInner> | null;
}
/**
 * 
 * @export
 * @interface GpuRequestOptionsOptionsInner
 */
export interface GpuRequestOptionsOptionsInner {
    /**
     * 
     * @type {GpuRequestType}
     * @memberof GpuRequestOptionsOptionsInner
     */
    'value': GpuRequestType | null;
    /**
     * Textual description of the value
     * @type {string}
     * @memberof GpuRequestOptionsOptionsInner
     */
    'displayed'?: string | null;
}
/**
 * 
 * @export
 * @interface GpuRequestRules
 */
export interface GpuRequestRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof GpuRequestRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof GpuRequestRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof GpuRequestRules
     */
    'canEdit'?: boolean | null;
    /**
     * Limit the permitted values for gpuRequest to a given set of values.
     * @type {Array<GpuRequestOptionsOptionsInner>}
     * @memberof GpuRequestRules
     */
    'options'?: Array<GpuRequestOptionsOptionsInner> | null;
}
/**
 * Whether the request for GPU resources is stated in terms of portion, memory or mig profile. If gpuDevicesRequest > 1, only portion with gpuPortionRequest 1 is supported. If gpuDeviceRequest = 1, request type can be stated as portion, memory or migProfile.
 * @export
 * @enum {string}
 */

export const GpuRequestType = {
    Portion: 'portion',
    Memory: 'memory',
    MigProfile: 'migProfile'
} as const;

export type GpuRequestType = typeof GpuRequestType[keyof typeof GpuRequestType];


/**
 * 
 * @export
 * @interface HostPathRules
 */
export interface HostPathRules {
    /**
     * 
     * @type {StringRules}
     * @memberof HostPathRules
     */
    'path'?: StringRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof HostPathRules
     */
    'readOnly'?: BooleanRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof HostPathRules
     */
    'mountPath'?: StringRules | null;
}
/**
 * 
 * @export
 * @interface HostPathSpec
 */
export interface HostPathSpec {
    /**
     * Local path within the controller to which the host volume will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof HostPathSpec
     */
    'path': string | null;
    /**
     * Whether to force the volume to be mounted with read-only permissions. Defaults to false.
     * @type {boolean}
     * @memberof HostPathSpec
     */
    'readOnly'?: boolean | null;
    /**
     * The path that the host volume will be mounted to when in use. MountPath is mandatory for creating a workspace.
     * @type {string}
     * @memberof HostPathSpec
     */
    'mountPath': string | null;
}
/**
 * Image pull policy. Defaults to Always if :latest tag is specified, or IfNotPresent otherwise.
 * @export
 * @enum {string}
 */

export const ImagePullPolicy = {
    Always: 'Always',
    Never: 'Never',
    IfNotPresent: 'IfNotPresent'
} as const;

export type ImagePullPolicy = typeof ImagePullPolicy[keyof typeof ImagePullPolicy];


/**
 * 
 * @export
 * @interface ImagePullPolicyOptions
 */
export interface ImagePullPolicyOptions {
    /**
     * Limit the permitted values for imagePullPolicy to a given set of values.
     * @type {Array<ImagePullPolicyOptionsOptionsInner>}
     * @memberof ImagePullPolicyOptions
     */
    'options'?: Array<ImagePullPolicyOptionsOptionsInner> | null;
}
/**
 * 
 * @export
 * @interface ImagePullPolicyOptionsOptionsInner
 */
export interface ImagePullPolicyOptionsOptionsInner {
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof ImagePullPolicyOptionsOptionsInner
     */
    'value': ImagePullPolicy | null;
    /**
     * Textual description of the value
     * @type {string}
     * @memberof ImagePullPolicyOptionsOptionsInner
     */
    'displayed'?: string | null;
}
/**
 * 
 * @export
 * @interface ImagePullPolicyRules
 */
export interface ImagePullPolicyRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof ImagePullPolicyRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof ImagePullPolicyRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof ImagePullPolicyRules
     */
    'canEdit'?: boolean | null;
    /**
     * Limit the permitted values for imagePullPolicy to a given set of values.
     * @type {Array<ImagePullPolicyOptionsOptionsInner>}
     * @memberof ImagePullPolicyRules
     */
    'options'?: Array<ImagePullPolicyOptionsOptionsInner> | null;
}
/**
 * 
 * @export
 * @interface ImposedAssetRules
 */
export interface ImposedAssetRules {
    /**
     * Whether it is possible to add non-imposed assets in the workload
     * @type {boolean}
     * @memberof ImposedAssetRules
     */
    'canAdd'?: boolean | null;
}
/**
 * A list of asset to be imposed on the workloads created in org units affected by this policy.
 * @export
 * @interface ImposedAssets
 */
export interface ImposedAssets {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImposedAssets
     */
    'datasources'?: Array<string> | null;
}
/**
 * set of rules to apply on imposed assets
 * @export
 * @interface ImposedAssetsRules
 */
export interface ImposedAssetsRules {
    /**
     * 
     * @type {ImposedAssetsRulesDatasources}
     * @memberof ImposedAssetsRules
     */
    'datasources'?: ImposedAssetsRulesDatasources | null;
}
/**
 * 
 * @export
 * @interface ImposedAssetsRulesDatasources
 */
export interface ImposedAssetsRulesDatasources {
    /**
     * 
     * @type {ImposedAssetRules}
     * @memberof ImposedAssetsRulesDatasources
     */
    'hostPath'?: ImposedAssetRules | null;
    /**
     * 
     * @type {ImposedAssetRules}
     * @memberof ImposedAssetsRulesDatasources
     */
    'nfs'?: ImposedAssetRules | null;
    /**
     * 
     * @type {ImposedAssetRules}
     * @memberof ImposedAssetsRulesDatasources
     */
    'pvc'?: ImposedAssetRules | null;
    /**
     * 
     * @type {ImposedAssetRules}
     * @memberof ImposedAssetsRulesDatasources
     */
    'git'?: ImposedAssetRules | null;
    /**
     * 
     * @type {ImposedAssetRules}
     * @memberof ImposedAssetsRulesDatasources
     */
    's3'?: ImposedAssetRules | null;
}
/**
 * 
 * @export
 * @interface IntegerRules
 */
export interface IntegerRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof IntegerRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory. default to false.
     * @type {boolean}
     * @memberof IntegerRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable. default to true
     * @type {boolean}
     * @memberof IntegerRules
     */
    'canEdit'?: boolean | null;
    /**
     * The minimum value that the field can be assigned to.
     * @type {number}
     * @memberof IntegerRules
     */
    'min'?: number | null;
    /**
     * The maximum value that the field can be assigned to.
     * @type {number}
     * @memberof IntegerRules
     */
    'max'?: number | null;
    /**
     * The minimal difference between two values the field can be assigned to. For example, min=2, max=10, step=2 implies that the values the field can hold are 2, 4, 6, 8 and 10.
     * @type {number}
     * @memberof IntegerRules
     */
    'step'?: number | null;
}
/**
 * 
 * @export
 * @interface IntegerRulesOptional
 */
export interface IntegerRulesOptional {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof IntegerRulesOptional
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the value of the field is editable. default to true
     * @type {boolean}
     * @memberof IntegerRulesOptional
     */
    'canEdit'?: boolean | null;
    /**
     * The minimum value that the field can be assigned to.
     * @type {number}
     * @memberof IntegerRulesOptional
     */
    'min'?: number | null;
    /**
     * The maximum value that the field can be assigned to.
     * @type {number}
     * @memberof IntegerRulesOptional
     */
    'max'?: number | null;
    /**
     * The minimal difference between two values the field can be assigned to. For example, min=2, max=10, step=2 implies that the values the field can hold are 2, 4, 6, 8 and 10.
     * @type {number}
     * @memberof IntegerRulesOptional
     */
    'step'?: number | null;
}
/**
 * The type of connection that exposes the container port.
 * @export
 * @enum {string}
 */

export const InternalConnectionType = {
    LoadBalancer: 'LoadBalancer',
    NodePort: 'NodePort',
    ExternalUrl: 'ExternalUrl'
} as const;

export type InternalConnectionType = typeof InternalConnectionType[keyof typeof InternalConnectionType];


/**
 * Information of the internal tool.
 * @export
 * @interface InternalToolInfo
 */
export interface InternalToolInfo {
    /**
     * 
     * @type {InternalToolType}
     * @memberof InternalToolInfo
     */
    'toolType': InternalToolType;
    /**
     * 
     * @type {InternalConnectionType}
     * @memberof InternalToolInfo
     */
    'connectionType': InternalConnectionType;
    /**
     * The port within the container that the connection exposes.
     * @type {number}
     * @memberof InternalToolInfo
     */
    'containerPort': number;
    /**
     * 
     * @type {NodePortInfo}
     * @memberof InternalToolInfo
     */
    'nodePortInfo'?: NodePortInfo | null;
    /**
     * 
     * @type {ExternalUrlInfo}
     * @memberof InternalToolInfo
     */
    'externalUrlInfo'?: ExternalUrlInfo | null;
}
/**
 * The type of the internal tool. Internal tool runs within the container and exposes ports associated with the tool using NodePort, LoadBalancer or ExternalUrl.
 * @export
 * @enum {string}
 */

export const InternalToolType = {
    JupyterNotebook: 'jupyter-notebook',
    Pycharm: 'pycharm',
    VisualStudioCode: 'visual-studio-code',
    Tensorboard: 'tensorboard',
    Rstudio: 'rstudio',
    Mlflow: 'mlflow',
    Custom: 'custom',
    Matlab: 'matlab'
} as const;

export type InternalToolType = typeof InternalToolType[keyof typeof InternalToolType];


/**
 * 
 * @export
 * @interface ItemRules
 */
export interface ItemRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof ItemRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether it is permitted to add items. Default to true.
     * @type {boolean}
     * @memberof ItemRules
     */
    'canAdd'?: boolean | null;
    /**
     * Set of keys for items that are \"locked\", i.e. cannot be removed or deleted.
     * @type {Array<string>}
     * @memberof ItemRules
     */
    'locked'?: Array<string>;
}
/**
 * Details of a label which is populated into the container.
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * The name of the label.
     * @type {string}
     * @memberof Label
     */
    'name': string;
    /**
     * The value to set the label to.
     * @type {string}
     * @memberof Label
     */
    'value': string;
    /**
     * Whether to exclude this label from the workload. This is necessary in case the label definition is inherited from a policy.
     * @type {boolean}
     * @memberof Label
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface LabelsRules
 */
export interface LabelsRules {
    /**
     * 
     * @type {ItemRules}
     * @memberof LabelsRules
     */
    'itemRules'?: ItemRules | null;
}
/**
 * Required if and only if gpuRequestType is migProfile. States the memory profile to be used for workload running on NVIDIA Multi-Instance GPU (MIG) technology.
 * @export
 * @enum {string}
 */

export const MigProfile = {
    _1g5gb: '1g.5gb',
    _1g10gb: '1g.10gb',
    _2g10gb: '2g.10gb',
    _2g20gb: '2g.20gb',
    _3g20gb: '3g.20gb',
    _3g40gb: '3g.40gb',
    _4g20gb: '4g.20gb',
    _4g40gb: '4g.40gb',
    _7g40gb: '7g.40gb',
    _7g80gb: '7g.80gb'
} as const;

export type MigProfile = typeof MigProfile[keyof typeof MigProfile];


/**
 * 
 * @export
 * @interface MigProfileOptions
 */
export interface MigProfileOptions {
    /**
     * Limit the permitted values for migProfile to a given set of values.
     * @type {Array<MigProfileOptionsOptionsInner>}
     * @memberof MigProfileOptions
     */
    'options'?: Array<MigProfileOptionsOptionsInner> | null;
}
/**
 * 
 * @export
 * @interface MigProfileOptionsOptionsInner
 */
export interface MigProfileOptionsOptionsInner {
    /**
     * 
     * @type {MigProfile}
     * @memberof MigProfileOptionsOptionsInner
     */
    'value': MigProfile | null;
    /**
     * Textual description of the value
     * @type {string}
     * @memberof MigProfileOptionsOptionsInner
     */
    'displayed'?: string | null;
}
/**
 * 
 * @export
 * @interface MigProfileRules
 */
export interface MigProfileRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof MigProfileRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof MigProfileRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof MigProfileRules
     */
    'canEdit'?: boolean | null;
    /**
     * Limit the permitted values for migProfile to a given set of values.
     * @type {Array<MigProfileOptionsOptionsInner>}
     * @memberof MigProfileRules
     */
    'options'?: Array<MigProfileOptionsOptionsInner> | null;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface NFSAssetSpec
 */
export interface NFSAssetSpec {
    /**
     * Path that is exported by the NFS server. More info at https://kubernetes.io/docs/concepts/storage/volumes#nfs. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'path': string | null;
    /**
     * Whether to force the NFS export to be mounted with read-only permissions.
     * @type {boolean}
     * @memberof NFSAssetSpec
     */
    'readOnly'?: boolean | null;
    /**
     * The hostname or IP address of the NFS server. Server is mandatory for creating a workspace
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'server': string | null;
    /**
     * The path that the NFS volume will be mounted to when in use. MountPath is mandatory for creating a workspace.
     * @type {string}
     * @memberof NFSAssetSpec
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface NfsAssetRules
 */
export interface NfsAssetRules {
    /**
     * 
     * @type {StringRules}
     * @memberof NfsAssetRules
     */
    'path'?: StringRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof NfsAssetRules
     */
    'readOnly'?: BooleanRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof NfsAssetRules
     */
    'server'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof NfsAssetRules
     */
    'mountPath'?: StringRules | null;
}
/**
 * 
 * @export
 * @interface NodePortInfo
 */
export interface NodePortInfo {
    /**
     * Whether the node port is provided in the specific env of the workspace. Default is false, and in this case the node port is auto generated by the system.
     * @type {boolean}
     * @memberof NodePortInfo
     */
    'isCustomPort'?: boolean;
}
/**
 * Fields of environment spec that cannot be overridden when creating a workload.
 * @export
 * @interface NonOverridableSpecFields
 */
export interface NonOverridableSpecFields {
    /**
     * Docker image name. More info at https://kubernetes.io/docs/concepts/containers/images Image name is mandatory for creating a workspace.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'image'?: string | null;
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof NonOverridableSpecFields
     */
    'imagePullPolicy'?: ImagePullPolicy | null;
    /**
     * Container\'s working directory. If not specified, the container runtime default will be used, which might be configured in the container image.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'workingDir'?: string | null;
    /**
     * Whether to enable host IPC. Defaults to false.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'hostIpc'?: boolean | null;
    /**
     * Whether to enable host networking. Default to false.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'hostNetwork'?: boolean | null;
    /**
     * List of connections that either expose ports from the container (each port is associated with a tool that the container runs), or URL\'s to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
     * @type {Array<Connection>}
     * @memberof NonOverridableSpecFields
     */
    'connections'?: Array<Connection>;
    /**
     * Whether to create a home directory for the container.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'createHomeDir'?: boolean | null;
    /**
     * Allow the container running the workload and all launched processes to gain additional privileges after the workload starts. For more information consult the User Identity in Container guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'allowPrivilegeEscalation'?: boolean | null;
    /**
     * Indicate the way to determine the user and group ids of the container. The options are a. fromTheImage - user and group ids are determined by the docker image that the container runs. this is the default option. b. custom - user and group ids can be specified in the environment asset and/or the workspace creation request. c. idpToken - user and group ids are determined according to the identity provider (idp) access token. This option is intended for internal use of the environment UI form. For further details consult the User Identity guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'uidGidSource'?: NonOverridableSpecFieldsUidGidSourceEnum;
    /**
     * Allow specifying uid/gid as part of create workspace. This is relevant only for custom uigGidSource.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'overrideUidGidInWorkspace'?: boolean;
    /**
     * The POSIX capabilities to add when running containers. Defaults to the default set of capabilities granted by the container runtime.
     * @type {Array<Capability>}
     * @memberof NonOverridableSpecFields
     */
    'capabilities'?: Array<Capability> | null;
    /**
     * Indicates which kind of seccomp profile will be applied to the container. The options are a. RuntimeDefault - the container runtime default profile should be used. b. Unconfined - no profile should be applied. c. Localhost is not yet supported by Run:ai.
     * @type {string}
     * @memberof NonOverridableSpecFields
     */
    'seccompProfileType'?: NonOverridableSpecFieldsSeccompProfileTypeEnum;
    /**
     * Indicates that the container must run as a non-root user.
     * @type {boolean}
     * @memberof NonOverridableSpecFields
     */
    'runAsNonRoot'?: boolean | null;
}

export const NonOverridableSpecFieldsUidGidSourceEnum = {
    FromTheImage: 'fromTheImage',
    FromIdpToken: 'fromIdpToken',
    Custom: 'custom'
} as const;

export type NonOverridableSpecFieldsUidGidSourceEnum = typeof NonOverridableSpecFieldsUidGidSourceEnum[keyof typeof NonOverridableSpecFieldsUidGidSourceEnum];
export const NonOverridableSpecFieldsSeccompProfileTypeEnum = {
    RuntimeDefault: 'RuntimeDefault',
    Unconfined: 'Unconfined',
    Localhost: 'Localhost'
} as const;

export type NonOverridableSpecFieldsSeccompProfileTypeEnum = typeof NonOverridableSpecFieldsSeccompProfileTypeEnum[keyof typeof NonOverridableSpecFieldsSeccompProfileTypeEnum];

/**
 * 
 * @export
 * @interface NumberRules
 */
export interface NumberRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof NumberRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory. default to false.
     * @type {boolean}
     * @memberof NumberRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable. default to true
     * @type {boolean}
     * @memberof NumberRules
     */
    'canEdit'?: boolean | null;
    /**
     * The minimum value that the field can be assigned to.
     * @type {number}
     * @memberof NumberRules
     */
    'min'?: number | null;
    /**
     * The maximum value that the field can be assigned to.
     * @type {number}
     * @memberof NumberRules
     */
    'max'?: number | null;
    /**
     * The minimal difference between two values the field can be assigned to. For example, min=2, max=3, step=0.25 implies that the values the field can hold are 2, 2.25, 2.5 and 3.
     * @type {number}
     * @memberof NumberRules
     */
    'step'?: number | null;
}
/**
 * Fields of environment spec that can be overridden when creating a workload.
 * @export
 * @interface OverridableSpecFields
 */
export interface OverridableSpecFields {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof OverridableSpecFields
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof OverridableSpecFields
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof OverridableSpecFields
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof OverridableSpecFields
     */
    'supplementalGroups'?: string | null;
}
/**
 * 
 * @export
 * @interface PVCAssetSpec
 */
export interface PVCAssetSpec {
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be create if it does not exist.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces. ClaimName is mandatory for creating a workspace.
     * @type {string}
     * @memberof PVCAssetSpec
     */
    'claimName': string | null;
    /**
     * Local path within the workspace to which the PVC bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof PVCAssetSpec
     */
    'path': string | null;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'readOnly'?: boolean | null;
    /**
     * Whether the PVC is ephemeral. If set to true, the PVC will be deleted when the workspace is stopped.
     * @type {boolean}
     * @memberof PVCAssetSpec
     */
    'ephemeral'?: boolean | null;
    /**
     * 
     * @type {ClaimInfo}
     * @memberof PVCAssetSpec
     */
    'claimInfo'?: ClaimInfo | null;
}
/**
 * 
 * @export
 * @interface PolicyCreationFields
 */
export interface PolicyCreationFields {
    /**
     * 
     * @type {Scope}
     * @memberof PolicyCreationFields
     */
    'scope': Scope;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof PolicyCreationFields
     */
    'projectId'?: number | null;
    /**
     * The id of the department. Must be specified for department scoped policies.
     * @type {string}
     * @memberof PolicyCreationFields
     */
    'departmentId'?: string | null;
    /**
     * A chosen name for the policy.
     * @type {string}
     * @memberof PolicyCreationFields
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PolicyListEntry
 */
export interface PolicyListEntry {
    /**
     * 
     * @type {PolicyType}
     * @memberof PolicyListEntry
     */
    'type'?: PolicyType;
    /**
     * 
     * @type {PolicyMeta}
     * @memberof PolicyListEntry
     */
    'meta'?: PolicyMeta;
    /**
     * 
     * @type {PolicySyncStatus}
     * @memberof PolicyListEntry
     */
    'status'?: PolicySyncStatus;
}
/**
 * 
 * @export
 * @interface PolicyListResponse
 */
export interface PolicyListResponse {
    /**
     * 
     * @type {Array<PolicyListEntry>}
     * @memberof PolicyListResponse
     */
    'policies'?: Array<PolicyListEntry>;
}
/**
 * 
 * @export
 * @interface PolicyMeta
 */
export interface PolicyMeta {
    /**
     * The unique id of the policy.
     * @type {string}
     * @memberof PolicyMeta
     */
    'id': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof PolicyMeta
     */
    'tenantId'?: number;
    /**
     * The id of the cluster, provided for policies of scope department and project.
     * @type {string}
     * @memberof PolicyMeta
     */
    'clusterId'?: string | null;
    /**
     * The user who created the policy.
     * @type {string}
     * @memberof PolicyMeta
     */
    'createdBy': string;
    /**
     * The time at which the policy wes created
     * @type {string}
     * @memberof PolicyMeta
     */
    'createdAt': string;
    /**
     * The user who updated the policy.
     * @type {string}
     * @memberof PolicyMeta
     */
    'updatedBy': string;
    /**
     * The time at which the policy has been updated
     * @type {string}
     * @memberof PolicyMeta
     */
    'updatedAt': string;
    /**
     * 
     * @type {Scope}
     * @memberof PolicyMeta
     */
    'scope': Scope;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof PolicyMeta
     */
    'projectId'?: number | null;
    /**
     * The id of the department. Must be specified for department scoped policies.
     * @type {string}
     * @memberof PolicyMeta
     */
    'departmentId'?: string | null;
    /**
     * A chosen name for the policy.
     * @type {string}
     * @memberof PolicyMeta
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PolicyReadOnlyFields
 */
export interface PolicyReadOnlyFields {
    /**
     * The unique id of the policy.
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'id': string;
    /**
     * The id of the tenant.
     * @type {number}
     * @memberof PolicyReadOnlyFields
     */
    'tenantId'?: number;
    /**
     * The id of the cluster, provided for policies of scope department and project.
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'clusterId'?: string | null;
    /**
     * The user who created the policy.
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'createdBy': string;
    /**
     * The time at which the policy wes created
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'createdAt': string;
    /**
     * The user who updated the policy.
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'updatedBy': string;
    /**
     * The time at which the policy has been updated
     * @type {string}
     * @memberof PolicyReadOnlyFields
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PolicySyncStatus
 */
export interface PolicySyncStatus {
    /**
     * 
     * @type {Array<PolicySyncStatusOfCluster>}
     * @memberof PolicySyncStatus
     */
    'clusters'?: Array<PolicySyncStatusOfCluster>;
}
/**
 * 
 * @export
 * @interface PolicySyncStatusOfCluster
 */
export interface PolicySyncStatusOfCluster {
    /**
     * The id of the cluster
     * @type {string}
     * @memberof PolicySyncStatusOfCluster
     */
    'clusterId': string;
    /**
     * Policy sync status of the cluster
     * @type {string}
     * @memberof PolicySyncStatusOfCluster
     */
    'status': PolicySyncStatusOfClusterStatusEnum;
    /**
     * In case sync failed, holds the error message received from the cluster
     * @type {string}
     * @memberof PolicySyncStatusOfCluster
     */
    'errorMessage'?: string | null;
}

export const PolicySyncStatusOfClusterStatusEnum = {
    NotReady: 'Not ready',
    Applying: 'Applying',
    Failed: 'Failed',
    Ready: 'Ready',
    PendingDeletion: 'Pending deletion',
    Deleting: 'Deleting',
    Deleted: 'Deleted',
    DeletionFailed: 'Deletion failed'
} as const;

export type PolicySyncStatusOfClusterStatusEnum = typeof PolicySyncStatusOfClusterStatusEnum[keyof typeof PolicySyncStatusOfClusterStatusEnum];

/**
 * The type of policy which determines the kind of workloads it is affecting
 * @export
 * @enum {string}
 */

export const PolicyType = {
    Workspace: 'workspace',
    Training: 'training'
} as const;

export type PolicyType = typeof PolicyType[keyof typeof PolicyType];


/**
 * Requested access mode(s) for the newly created PVC.
 * @export
 * @interface PvcAccessModes
 */
export interface PvcAccessModes {
    /**
     * Requesting claim that can be mounted in read/write mode to exactly one host. This is the default access mode.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readWriteOnce'?: boolean | null;
    /**
     * Requesting claim that can be mounted in read-only mode to many hosts.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readOnlyMany'?: boolean | null;
    /**
     * Requesting claim that can be mounted in read/write mode to many hosts.
     * @type {boolean}
     * @memberof PvcAccessModes
     */
    'readWriteMany'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PvcAssetRules
 */
export interface PvcAssetRules {
    /**
     * 
     * @type {StringRules}
     * @memberof PvcAssetRules
     */
    'claimName'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof PvcAssetRules
     */
    'path'?: StringRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof PvcAssetRules
     */
    'readOnly'?: BooleanRules | null;
    /**
     * 
     * @type {ClaimInfoRules}
     * @memberof PvcAssetRules
     */
    'claimInfo'?: ClaimInfoRules | null;
}
/**
 * 
 * @export
 * @interface QuantityRules
 */
export interface QuantityRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof QuantityRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof QuantityRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof QuantityRules
     */
    'canEdit'?: boolean | null;
    /**
     * The minimum value that the field can be assigned to.
     * @type {string}
     * @memberof QuantityRules
     */
    'min'?: string | null;
    /**
     * The maximum value that the field can be assigned to.
     * @type {string}
     * @memberof QuantityRules
     */
    'max'?: string | null;
}
/**
 * 
 * @export
 * @interface S3AssetRules
 */
export interface S3AssetRules {
    /**
     * 
     * @type {StringRules}
     * @memberof S3AssetRules
     */
    'bucket'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof S3AssetRules
     */
    'path'?: StringRules | null;
    /**
     * 
     * @type {StringRules}
     * @memberof S3AssetRules
     */
    'url'?: StringRules | null;
}
/**
 * 
 * @export
 * @interface S3AssetSpec
 */
export interface S3AssetSpec {
    /**
     * The name of the bucket Bucket name is mandatory for creating a workspace.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'bucket': string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'path': string | null;
    /**
     * ID of credentials asset of type access-key, for private S3 buckets.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'accessKeyAssetId'?: string | null;
    /**
     * The url of the S3 service provider. The default is the URL of the Amazon AWS S3 service.
     * @type {string}
     * @memberof S3AssetSpec
     */
    'url'?: string | null;
}
/**
 * The scope that the policy relates to.
 * @export
 * @enum {string}
 */

export const Scope = {
    Tenant: 'tenant',
    Department: 'department',
    Project: 'project'
} as const;

export type Scope = typeof Scope[keyof typeof Scope];


/**
 * This field is used by the system along with effective rules, in order to specify the org unit from which this effective rule has been derived. It should be left empty when sending apply policy requests.
 * @export
 * @interface SourceOfRule
 */
export interface SourceOfRule {
    /**
     * 
     * @type {Scope}
     * @memberof SourceOfRule
     */
    'scope': Scope;
    /**
     * The id of the project. Must be specified for project scoped assets.
     * @type {number}
     * @memberof SourceOfRule
     */
    'projectId'?: number | null;
    /**
     * The id of the department. Must be specified for department scoped policies.
     * @type {string}
     * @memberof SourceOfRule
     */
    'departmentId'?: string | null;
}
/**
 * 
 * @export
 * @interface SpecificRunParamsFields
 */
export interface SpecificRunParamsFields {
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof SpecificRunParamsFields
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof SpecificRunParamsFields
     */
    'nodePools'?: Array<string> | null;
    /**
     * Whether to allow the workspace to exceed the quota of the project.
     * @type {boolean}
     * @memberof SpecificRunParamsFields
     */
    'allowOverQuota'?: boolean | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof SpecificRunParamsFields
     */
    'annotations'?: Array<Annotation>;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof SpecificRunParamsFields
     */
    'labels'?: Array<Label>;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof SpecificRunParamsFields
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof SpecificRunParamsFields
     */
    'backoffLimit'?: number | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof SpecificRunParamsFields
     */
    'terminateAfterPreemption'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SpecificRunParamsRules
 */
export interface SpecificRunParamsRules {
    /**
     * 
     * @type {StringRules}
     * @memberof SpecificRunParamsRules
     */
    'nodeType'?: StringRules | null;
    /**
     * 
     * @type {ArrayRules}
     * @memberof SpecificRunParamsRules
     */
    'nodePools'?: ArrayRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof SpecificRunParamsRules
     */
    'allowOverQuota'?: BooleanRules | null;
    /**
     * 
     * @type {AnnotationsRules}
     * @memberof SpecificRunParamsRules
     */
    'annotations'?: AnnotationsRules | null;
    /**
     * 
     * @type {LabelsRules}
     * @memberof SpecificRunParamsRules
     */
    'labels'?: LabelsRules | null;
    /**
     * 
     * @type {IntegerRules}
     * @memberof SpecificRunParamsRules
     */
    'autoDeletionTimeAfterCompletionSeconds'?: IntegerRules | null;
    /**
     * 
     * @type {BooleanRules}
     * @memberof SpecificRunParamsRules
     */
    'terminateAfterPreemption'?: BooleanRules | null;
    /**
     * 
     * @type {AutoScalingRules}
     * @memberof SpecificRunParamsRules
     */
    'autoScaling'?: AutoScalingRules | null;
    /**
     * 
     * @type {IntegerRules}
     * @memberof SpecificRunParamsRules
     */
    'backoffLimit'?: IntegerRules | null;
}
/**
 * One of the values that the field can be assigned to, when options police is applied on the field.
 * @export
 * @interface StringOption
 */
export interface StringOption {
    /**
     * The value that the field should hold
     * @type {string}
     * @memberof StringOption
     */
    'value': string;
    /**
     * Textual description of the value. to be used by user interface applications.
     * @type {string}
     * @memberof StringOption
     */
    'displayed'?: string | null;
}
/**
 * 
 * @export
 * @interface StringRules
 */
export interface StringRules {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof StringRules
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof StringRules
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof StringRules
     */
    'canEdit'?: boolean | null;
    /**
     * Set of options that the value of the field must be chosen from.
     * @type {Array<StringOption>}
     * @memberof StringRules
     */
    'options'?: Array<StringOption> | null;
}
/**
 * 
 * @export
 * @interface StringRulesCommon
 */
export interface StringRulesCommon {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof StringRulesCommon
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the field is mandatory, default to false.
     * @type {boolean}
     * @memberof StringRulesCommon
     */
    'required'?: boolean | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof StringRulesCommon
     */
    'canEdit'?: boolean | null;
}
/**
 * 
 * @export
 * @interface StringRulesOptional
 */
export interface StringRulesOptional {
    /**
     * 
     * @type {SourceOfRule}
     * @memberof StringRulesOptional
     */
    'sourceOfRule'?: SourceOfRule | null;
    /**
     * Whether the value of the field is editable, default to true
     * @type {boolean}
     * @memberof StringRulesOptional
     */
    'canEdit'?: boolean | null;
    /**
     * Set of options that the value of the field must be chosen from.
     * @type {Array<StringOption>}
     * @memberof StringRulesOptional
     */
    'options'?: Array<StringOption> | null;
}
/**
 * 
 * @export
 * @interface StringRulesOptions
 */
export interface StringRulesOptions {
    /**
     * Set of options that the value of the field must be chosen from.
     * @type {Array<StringOption>}
     * @memberof StringRulesOptions
     */
    'options'?: Array<StringOption> | null;
}
/**
 * 
 * @export
 * @interface SyncStatusUpdate
 */
export interface SyncStatusUpdate {
    /**
     * The id of the reporting cluster.
     * @type {string}
     * @memberof SyncStatusUpdate
     */
    'clusterId'?: string;
    /**
     * 
     * @type {Scope}
     * @memberof SyncStatusUpdate
     */
    'scopeType'?: Scope;
    /**
     * cluster, department or project id, depending on the scope type
     * @type {string}
     * @memberof SyncStatusUpdate
     */
    'scopeId'?: string | null;
    /**
     * number which uniquely identifies the update. For deletion, the updateCounter holds the update count of the recent applied policy, rather than of the deletion.
     * @type {number}
     * @memberof SyncStatusUpdate
     */
    'updateCounter'?: number;
    /**
     * The action that the agent performed
     * @type {string}
     * @memberof SyncStatusUpdate
     */
    'action'?: SyncStatusUpdateActionEnum;
    /**
     * optional error indicating the update has failed.
     * @type {string}
     * @memberof SyncStatusUpdate
     */
    'errorMessage'?: string | null;
}

export const SyncStatusUpdateActionEnum = {
    Apply: 'apply',
    Delete: 'delete'
} as const;

export type SyncStatusUpdateActionEnum = typeof SyncStatusUpdateActionEnum[keyof typeof SyncStatusUpdateActionEnum];

/**
 * 
 * @export
 * @interface TrainingPolicy
 */
export interface TrainingPolicy {
    /**
     * 
     * @type {PolicyMeta}
     * @memberof TrainingPolicy
     */
    'meta'?: PolicyMeta;
    /**
     * 
     * @type {TrainingPolicyDefaultsAndRules}
     * @memberof TrainingPolicy
     */
    'policy'?: TrainingPolicyDefaultsAndRules | null;
    /**
     * 
     * @type {TrainingPolicyDefaultsAndRules}
     * @memberof TrainingPolicy
     */
    'effective'?: TrainingPolicyDefaultsAndRules | null;
    /**
     * The time at which an event occurred. e.g. the time an effective policy has been updated
     * @type {string}
     * @memberof TrainingPolicy
     */
    'effectiveUpdatedAt'?: string;
}
/**
 * 
 * @export
 * @interface TrainingPolicyChangeRequest
 */
export interface TrainingPolicyChangeRequest {
    /**
     * 
     * @type {PolicyCreationFields}
     * @memberof TrainingPolicyChangeRequest
     */
    'meta'?: PolicyCreationFields;
    /**
     * 
     * @type {TrainingPolicyDefaultsAndRules}
     * @memberof TrainingPolicyChangeRequest
     */
    'policy'?: TrainingPolicyDefaultsAndRules | null;
    /**
     * set of fields in jsonpath format that is requested to clear their policy (default and rules)
     * @type {Array<string>}
     * @memberof TrainingPolicyChangeRequest
     */
    'reset'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TrainingPolicyDefaults
 */
export interface TrainingPolicyDefaults {
    /**
     * 
     * @type {WorkspacePolicyDefaultsEnvironment}
     * @memberof TrainingPolicyDefaults
     */
    'environment'?: WorkspacePolicyDefaultsEnvironment | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsCompute}
     * @memberof TrainingPolicyDefaults
     */
    'compute'?: WorkspacePolicyDefaultsCompute | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsHostPath}
     * @memberof TrainingPolicyDefaults
     */
    'hostPath'?: WorkspacePolicyDefaultsHostPath | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsNfs}
     * @memberof TrainingPolicyDefaults
     */
    'nfs'?: WorkspacePolicyDefaultsNfs | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsPvc}
     * @memberof TrainingPolicyDefaults
     */
    'pvc'?: WorkspacePolicyDefaultsPvc | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsGit}
     * @memberof TrainingPolicyDefaults
     */
    'git'?: WorkspacePolicyDefaultsGit | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsS3}
     * @memberof TrainingPolicyDefaults
     */
    's3'?: WorkspacePolicyDefaultsS3 | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsWorkspace}
     * @memberof TrainingPolicyDefaults
     */
    'training'?: WorkspacePolicyDefaultsWorkspace | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsImposedAssets}
     * @memberof TrainingPolicyDefaults
     */
    'imposedAssets'?: WorkspacePolicyDefaultsImposedAssets | null;
}
/**
 * 
 * @export
 * @interface TrainingPolicyDefaultsAndRules
 */
export interface TrainingPolicyDefaultsAndRules {
    /**
     * 
     * @type {TrainingPolicyDefaults}
     * @memberof TrainingPolicyDefaultsAndRules
     */
    'defaults'?: TrainingPolicyDefaults | null;
    /**
     * 
     * @type {TrainingPolicyRules}
     * @memberof TrainingPolicyDefaultsAndRules
     */
    'rules'?: TrainingPolicyRules | null;
}
/**
 * 
 * @export
 * @interface TrainingPolicyOverwriteRequest
 */
export interface TrainingPolicyOverwriteRequest {
    /**
     * 
     * @type {PolicyCreationFields}
     * @memberof TrainingPolicyOverwriteRequest
     */
    'meta'?: PolicyCreationFields;
    /**
     * 
     * @type {TrainingPolicyDefaultsAndRules}
     * @memberof TrainingPolicyOverwriteRequest
     */
    'policy'?: TrainingPolicyDefaultsAndRules | null;
}
/**
 * 
 * @export
 * @interface TrainingPolicyRules
 */
export interface TrainingPolicyRules {
    /**
     * 
     * @type {EnvironmentRules}
     * @memberof TrainingPolicyRules
     */
    'environment'?: EnvironmentRules | null;
    /**
     * 
     * @type {ComputeRules}
     * @memberof TrainingPolicyRules
     */
    'compute'?: ComputeRules | null;
    /**
     * 
     * @type {HostPathRules}
     * @memberof TrainingPolicyRules
     */
    'hostPath'?: HostPathRules | null;
    /**
     * 
     * @type {NfsAssetRules}
     * @memberof TrainingPolicyRules
     */
    'nfs'?: NfsAssetRules | null;
    /**
     * 
     * @type {PvcAssetRules}
     * @memberof TrainingPolicyRules
     */
    'pvc'?: PvcAssetRules | null;
    /**
     * 
     * @type {GitAssetRules}
     * @memberof TrainingPolicyRules
     */
    'git'?: GitAssetRules | null;
    /**
     * 
     * @type {S3AssetRules}
     * @memberof TrainingPolicyRules
     */
    's3'?: S3AssetRules | null;
    /**
     * 
     * @type {ImposedAssetsRules}
     * @memberof TrainingPolicyRules
     */
    'imposedAssets'?: ImposedAssetsRules | null;
    /**
     * 
     * @type {SpecificRunParamsRules}
     * @memberof TrainingPolicyRules
     */
    'training'?: SpecificRunParamsRules | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicy
 */
export interface WorkspacePolicy {
    /**
     * 
     * @type {PolicyMeta}
     * @memberof WorkspacePolicy
     */
    'meta'?: PolicyMeta;
    /**
     * 
     * @type {WorkspacePolicyDefaultsAndRules}
     * @memberof WorkspacePolicy
     */
    'policy'?: WorkspacePolicyDefaultsAndRules | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsAndRules}
     * @memberof WorkspacePolicy
     */
    'effective'?: WorkspacePolicyDefaultsAndRules | null;
    /**
     * The time at which an event occurred. e.g. the time an effective policy has been updated
     * @type {string}
     * @memberof WorkspacePolicy
     */
    'effectiveUpdatedAt'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyChangeRequest
 */
export interface WorkspacePolicyChangeRequest {
    /**
     * 
     * @type {PolicyCreationFields}
     * @memberof WorkspacePolicyChangeRequest
     */
    'meta'?: PolicyCreationFields;
    /**
     * 
     * @type {WorkspacePolicyDefaultsAndRules}
     * @memberof WorkspacePolicyChangeRequest
     */
    'policy'?: WorkspacePolicyDefaultsAndRules | null;
    /**
     * set of fields in jsonpath format that is requested to clear their policy (default and rules)
     * @type {Array<string>}
     * @memberof WorkspacePolicyChangeRequest
     */
    'reset'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaults
 */
export interface WorkspacePolicyDefaults {
    /**
     * 
     * @type {WorkspacePolicyDefaultsEnvironment}
     * @memberof WorkspacePolicyDefaults
     */
    'environment'?: WorkspacePolicyDefaultsEnvironment | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsCompute}
     * @memberof WorkspacePolicyDefaults
     */
    'compute'?: WorkspacePolicyDefaultsCompute | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsHostPath}
     * @memberof WorkspacePolicyDefaults
     */
    'hostPath'?: WorkspacePolicyDefaultsHostPath | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsNfs}
     * @memberof WorkspacePolicyDefaults
     */
    'nfs'?: WorkspacePolicyDefaultsNfs | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsPvc}
     * @memberof WorkspacePolicyDefaults
     */
    'pvc'?: WorkspacePolicyDefaultsPvc | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsGit}
     * @memberof WorkspacePolicyDefaults
     */
    'git'?: WorkspacePolicyDefaultsGit | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsS3}
     * @memberof WorkspacePolicyDefaults
     */
    's3'?: WorkspacePolicyDefaultsS3 | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsWorkspace}
     * @memberof WorkspacePolicyDefaults
     */
    'workspace'?: WorkspacePolicyDefaultsWorkspace | null;
    /**
     * 
     * @type {WorkspacePolicyDefaultsImposedAssets}
     * @memberof WorkspacePolicyDefaults
     */
    'imposedAssets'?: WorkspacePolicyDefaultsImposedAssets | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsAndRules
 */
export interface WorkspacePolicyDefaultsAndRules {
    /**
     * 
     * @type {WorkspacePolicyDefaults}
     * @memberof WorkspacePolicyDefaultsAndRules
     */
    'defaults'?: WorkspacePolicyDefaults | null;
    /**
     * 
     * @type {WorkspacePolicyRules}
     * @memberof WorkspacePolicyDefaultsAndRules
     */
    'rules'?: WorkspacePolicyRules | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsCompute
 */
export interface WorkspacePolicyDefaultsCompute {
    /**
     * Requested number of GPU devices. Currently if more than one device is requested, it is not possible to provide values for gpuMemory/migProfile/gpuPortion.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuDevicesRequest'?: number | null;
    /**
     * 
     * @type {GpuRequestType}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * Required if and only if gpuRequestType is portion. States the portion of the GPU to allocate for the created workload, per GPU device, between 0 and 1. The default is no allocated GPUs.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuPortionRequest'?: number | null;
    /**
     * Limitations on the portion consumed by the workload, per GPU device. The system guarantees The gpuPotionLimit must be no less than the gpuPortionRequest.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuPortionLimit'?: number | null;
    /**
     * Required if and only if gpuRequestType is memory. States the GPU memory to allocate for the created workload, per GPU device. Note that the workload will not be scheduled unless the system can guarantee this amount of GPU memory to the workload.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuMemoryRequest'?: string | null;
    /**
     * Limitation on the memory consumed by the workload, per GPU device. The system guarantees The gpuMemoryLimit must be no less than gpuMemoryRequest.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'gpuMemoryLimit'?: string | null;
    /**
     * 
     * @type {MigProfile}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'migProfile'?: MigProfile | null;
    /**
     * CPU units to allocate for the created workload (0.5, 1, .etc). The workload will receive at least this amount of CPU. Note that the workload will not be scheduled unless the system can guarantee this amount of CPUs to the workload.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'cpuCoreRequest'?: number | null;
    /**
     * Limitations on the number of CPUs consumed by the workload (0.5, 1, .etc). The system guarantees that this workload will not be able to consume more than this amount of CPUs.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'cpuCoreLimit'?: number | null;
    /**
     * The amount of CPU memory to allocate for this workload (1G, 20M, .etc). The workload will receive at least this amount of memory. Note that the workload will not be scheduled unless the system can guarantee this amount of memory to the workload
     * @type {string}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'cpuMemoryRequest'?: string | null;
    /**
     * Limitations on the CPU memory to allocate for this workload (1G, 20M, .etc). The system guarantees that this workload will not be able to consume more than this amount of memory. The workload will receive an error when trying to allocate more memory than this limit.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'cpuMemoryLimit'?: string | null;
    /**
     * A large /dev/shm device to mount into a container running the created workload. An shm is a shared file system mounted on RAM.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'largeShmRequest'?: boolean | null;
    /**
     * Set of extended resources with their quantity
     * @type {Array<ExtendedResource>}
     * @memberof WorkspacePolicyDefaultsCompute
     */
    'extendedResources'?: Array<ExtendedResource> | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsEnvironment
 */
export interface WorkspacePolicyDefaultsEnvironment {
    /**
     * A command to server as the entry point of the container running the workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'command'?: string | null;
    /**
     * Arguments to the command that the container running the workspace executes.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'args'?: string | null;
    /**
     * Set of environment variables to populate into the container running the workspace.
     * @type {Array<EnvironmentVariable>}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'environmentVariables'?: Array<EnvironmentVariable> | null;
    /**
     * The user id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsUid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'runAsUid'?: number | null;
    /**
     * The group id to run the entrypoint of the container which executes the workspace. Default to the (optional) value specified in the environment asset runAsGid field. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'runAsGid'?: number | null;
    /**
     * Comma seperated list of groups that the user running the container belongs to, in addition to the group indicated by runAsGid. Can be provided only when the source uid/gid of the environment asset is not fromTheImage, and overrideUidGidInWorkspace is enabled. Empty string implies reverting to the supplementary groups of the image.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'supplementalGroups'?: string | null;
    /**
     * Docker image name. More info at https://kubernetes.io/docs/concepts/containers/images Image name is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'image'?: string | null;
    /**
     * 
     * @type {ImagePullPolicy}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'imagePullPolicy'?: ImagePullPolicy | null;
    /**
     * Container\'s working directory. If not specified, the container runtime default will be used, which might be configured in the container image.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'workingDir'?: string | null;
    /**
     * Whether to enable host IPC. Defaults to false.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'hostIpc'?: boolean | null;
    /**
     * Whether to enable host networking. Default to false.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'hostNetwork'?: boolean | null;
    /**
     * List of connections that either expose ports from the container (each port is associated with a tool that the container runs), or URL\'s to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
     * @type {Array<Connection>}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'connections'?: Array<Connection>;
    /**
     * Whether to create a home directory for the container.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'createHomeDir'?: boolean | null;
    /**
     * Allow the container running the workload and all launched processes to gain additional privileges after the workload starts. For more information consult the User Identity in Container guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'allowPrivilegeEscalation'?: boolean | null;
    /**
     * Indicate the way to determine the user and group ids of the container. The options are a. fromTheImage - user and group ids are determined by the docker image that the container runs. this is the default option. b. custom - user and group ids can be specified in the environment asset and/or the workspace creation request. c. idpToken - user and group ids are determined according to the identity provider (idp) access token. This option is intended for internal use of the environment UI form. For further details consult the User Identity guide at https://docs.run.ai/admin/runai-setup/config/non-root-containers/
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'uidGidSource'?: WorkspacePolicyDefaultsEnvironmentUidGidSourceEnum;
    /**
     * Allow specifying uid/gid as part of create workspace. This is relevant only for custom uigGidSource.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'overrideUidGidInWorkspace'?: boolean;
    /**
     * The POSIX capabilities to add when running containers. Defaults to the default set of capabilities granted by the container runtime.
     * @type {Array<Capability>}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'capabilities'?: Array<Capability> | null;
    /**
     * Indicates which kind of seccomp profile will be applied to the container. The options are a. RuntimeDefault - the container runtime default profile should be used. b. Unconfined - no profile should be applied. c. Localhost is not yet supported by Run:ai.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'seccompProfileType'?: WorkspacePolicyDefaultsEnvironmentSeccompProfileTypeEnum;
    /**
     * Indicates that the container must run as a non-root user.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsEnvironment
     */
    'runAsNonRoot'?: boolean | null;
}

export const WorkspacePolicyDefaultsEnvironmentUidGidSourceEnum = {
    FromTheImage: 'fromTheImage',
    FromIdpToken: 'fromIdpToken',
    Custom: 'custom'
} as const;

export type WorkspacePolicyDefaultsEnvironmentUidGidSourceEnum = typeof WorkspacePolicyDefaultsEnvironmentUidGidSourceEnum[keyof typeof WorkspacePolicyDefaultsEnvironmentUidGidSourceEnum];
export const WorkspacePolicyDefaultsEnvironmentSeccompProfileTypeEnum = {
    RuntimeDefault: 'RuntimeDefault',
    Unconfined: 'Unconfined',
    Localhost: 'Localhost'
} as const;

export type WorkspacePolicyDefaultsEnvironmentSeccompProfileTypeEnum = typeof WorkspacePolicyDefaultsEnvironmentSeccompProfileTypeEnum[keyof typeof WorkspacePolicyDefaultsEnvironmentSeccompProfileTypeEnum];

/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsGit
 */
export interface WorkspacePolicyDefaultsGit {
    /**
     * URL to a remote git repository. The content of this repository will be mapped to the container running the workload. Repository name is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsGit
     */
    'repository': string | null;
    /**
     * Specific branch to synchronize the repository from.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsGit
     */
    'branch'?: string | null;
    /**
     * Specific revision to synchronize the repository from.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsGit
     */
    'revision'?: string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsGit
     */
    'path': string | null;
    /**
     * ID of credentials asset of type password. Needed for non public repository which requires authentication.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsGit
     */
    'passwordAssetId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsHostPath
 */
export interface WorkspacePolicyDefaultsHostPath {
    /**
     * Local path within the controller to which the host volume will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsHostPath
     */
    'path': string | null;
    /**
     * Whether to force the volume to be mounted with read-only permissions. Defaults to false.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsHostPath
     */
    'readOnly'?: boolean | null;
    /**
     * The path that the host volume will be mounted to when in use. MountPath is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsHostPath
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsImposedAssets
 */
export interface WorkspacePolicyDefaultsImposedAssets {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacePolicyDefaultsImposedAssets
     */
    'datasources'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsNfs
 */
export interface WorkspacePolicyDefaultsNfs {
    /**
     * Path that is exported by the NFS server. More info at https://kubernetes.io/docs/concepts/storage/volumes#nfs. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsNfs
     */
    'path': string | null;
    /**
     * Whether to force the NFS export to be mounted with read-only permissions.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsNfs
     */
    'readOnly'?: boolean | null;
    /**
     * The hostname or IP address of the NFS server. Server is mandatory for creating a workspace
     * @type {string}
     * @memberof WorkspacePolicyDefaultsNfs
     */
    'server': string | null;
    /**
     * The path that the NFS volume will be mounted to when in use. MountPath is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsNfs
     */
    'mountPath': string | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsPvc
 */
export interface WorkspacePolicyDefaultsPvc {
    /**
     * Whether to assume that the PVC exists. If set to true, PVC is assumed to exist. If set to false, the PVC will be create if it does not exist.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'existingPvc'?: boolean | null;
    /**
     * A given name for the PVC. Allowed referencing it across workspaces. ClaimName is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'claimName': string | null;
    /**
     * Local path within the workspace to which the PVC bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'path': string | null;
    /**
     * Whether the path to the PVC permits only read access.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'readOnly'?: boolean | null;
    /**
     * Whether the PVC is ephemeral. If set to true, the PVC will be deleted when the workspace is stopped.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'ephemeral'?: boolean | null;
    /**
     * 
     * @type {ClaimInfo}
     * @memberof WorkspacePolicyDefaultsPvc
     */
    'claimInfo'?: ClaimInfo | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsS3
 */
export interface WorkspacePolicyDefaultsS3 {
    /**
     * The name of the bucket Bucket name is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsS3
     */
    'bucket': string | null;
    /**
     * Local path within the workspace to which the S3 bucket will be mapped. Path is mandatory for creating a workspace.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsS3
     */
    'path': string | null;
    /**
     * ID of credentials asset of type access-key, for private S3 buckets.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsS3
     */
    'accessKeyAssetId'?: string | null;
    /**
     * The url of the S3 service provider. The default is the URL of the Amazon AWS S3 service.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsS3
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyDefaultsWorkspace
 */
export interface WorkspacePolicyDefaultsWorkspace {
    /**
     * Nodes (machines) or a group of nodes on which the workload will run. To use this feature, your Administrator will need to label nodes as explained in the Group Nodes guide at https://docs.run.ai/admin/researcher-setup/limit-to-node-group. This flag can be used in conjunction with Project-based affinity. In this case, the flag is used to refine the list of allowable node groups set in the Project. For more information consult the Projects guide at https://docs.run.ai/admin/admin-ui-setup/project-setup.
     * @type {string}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'nodeType'?: string | null;
    /**
     * A prioritize list of node pools for the scheduler to run the workspace on. The scheduler will always try to use the first node pool before moving to the next one when the fist is not available.
     * @type {Array<string>}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'nodePools'?: Array<string> | null;
    /**
     * Whether to allow the workspace to exceed the quota of the project.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'allowOverQuota'?: boolean | null;
    /**
     * Set of annotations to populate into the container running the workspace.
     * @type {Array<Annotation>}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'annotations'?: Array<Annotation>;
    /**
     * Set of labels to populate into the container running the workspace.
     * @type {Array<Label>}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'labels'?: Array<Label>;
    /**
     * Specifies the duration after which a finished workload (Completed or Failed) will be automatically deleted.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'autoDeletionTimeAfterCompletionSeconds'?: number | null;
    /**
     * Specifies the number of retries before marking a workload as failed.
     * @type {number}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'backoffLimit'?: number | null;
    /**
     * Indicates whether the job should be terminated, by the system, after it has been preempted.
     * @type {boolean}
     * @memberof WorkspacePolicyDefaultsWorkspace
     */
    'terminateAfterPreemption'?: boolean | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyOverwriteRequest
 */
export interface WorkspacePolicyOverwriteRequest {
    /**
     * 
     * @type {PolicyCreationFields}
     * @memberof WorkspacePolicyOverwriteRequest
     */
    'meta'?: PolicyCreationFields;
    /**
     * 
     * @type {WorkspacePolicyDefaultsAndRules}
     * @memberof WorkspacePolicyOverwriteRequest
     */
    'policy'?: WorkspacePolicyDefaultsAndRules | null;
}
/**
 * 
 * @export
 * @interface WorkspacePolicyRules
 */
export interface WorkspacePolicyRules {
    /**
     * 
     * @type {EnvironmentRules}
     * @memberof WorkspacePolicyRules
     */
    'environment'?: EnvironmentRules | null;
    /**
     * 
     * @type {ComputeRules}
     * @memberof WorkspacePolicyRules
     */
    'compute'?: ComputeRules | null;
    /**
     * 
     * @type {HostPathRules}
     * @memberof WorkspacePolicyRules
     */
    'hostPath'?: HostPathRules | null;
    /**
     * 
     * @type {NfsAssetRules}
     * @memberof WorkspacePolicyRules
     */
    'nfs'?: NfsAssetRules | null;
    /**
     * 
     * @type {PvcAssetRules}
     * @memberof WorkspacePolicyRules
     */
    'pvc'?: PvcAssetRules | null;
    /**
     * 
     * @type {GitAssetRules}
     * @memberof WorkspacePolicyRules
     */
    'git'?: GitAssetRules | null;
    /**
     * 
     * @type {S3AssetRules}
     * @memberof WorkspacePolicyRules
     */
    's3'?: S3AssetRules | null;
    /**
     * 
     * @type {ImposedAssetsRules}
     * @memberof WorkspacePolicyRules
     */
    'imposedAssets'?: ImposedAssetsRules | null;
    /**
     * 
     * @type {SpecificRunParamsRules}
     * @memberof WorkspacePolicyRules
     */
    'workspace'?: SpecificRunParamsRules | null;
}

/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Hook for asset service to report a change in spec of a given asset
         * @summary Asset changed hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetChangedHook: async (assetId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetChangedHook', 'assetId', assetId)
            const localVarPath = `/internal/policy/asset/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hook for asset service to report a deletion of a given asset
         * @summary Asset deleted hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetDeletedHook: async (assetId: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetDeletedHook', 'assetId', assetId)
            const localVarPath = `/internal/policy/asset/{AssetId}`
                .replace(`{${"AssetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete training policy for a given org unit.
         * @summary Delete training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainingPolicy: async (scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('deleteTrainingPolicy', 'scope', scope)
            const localVarPath = `/api/v1/policy/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete workspace policy for a given org unit.
         * @summary Delete workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspacePolicy: async (scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('deleteWorkspacePolicy', 'scope', scope)
            const localVarPath = `/api/v1/policy/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of an training policy for a given org unit
         * @summary Get training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingPolicy: async (scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getTrainingPolicy', 'scope', scope)
            const localVarPath = `/api/v1/policy/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a workspace policy for a given org unit
         * @summary Get workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacePolicy: async (scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getWorkspacePolicy', 'scope', scope)
            const localVarPath = `/api/v1/policy/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all the applied policies for this tenant.
         * @summary List policies
         * @param {'Workspace' | 'Training'} [workloadType] Policy for a speicifc workload type.
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] filter by this scope.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPolicies: async (workloadType?: 'Workspace' | 'Training', scope?: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workloadType !== undefined) {
                localVarQueryParameter['workloadType'] = workloadType;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply a training policy for a given org unite
         * @summary Overwrite training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyOverwriteRequest} [trainingPolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overwriteTrainingPolicy: async (validateOnly?: boolean, trainingPolicyOverwriteRequest?: TrainingPolicyOverwriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validateOnly !== undefined) {
                localVarQueryParameter['validateOnly'] = validateOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingPolicyOverwriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply a workspace policy for a given org unit
         * @summary Overwrite workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyOverwriteRequest} [workspacePolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overwriteWorkspacePolicy: async (validateOnly?: boolean, workspacePolicyOverwriteRequest?: WorkspacePolicyOverwriteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validateOnly !== undefined) {
                localVarQueryParameter['validateOnly'] = validateOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacePolicyOverwriteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply changes to training policy for a given org unite
         * @summary Update training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyChangeRequest} [trainingPolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainingPolicy: async (validateOnly?: boolean, trainingPolicyChangeRequest?: TrainingPolicyChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validateOnly !== undefined) {
                localVarQueryParameter['validateOnly'] = validateOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingPolicyChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply changes to workspace policy for a given org unit
         * @summary Update workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyChangeRequest} [workspacePolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspacePolicy: async (validateOnly?: boolean, workspacePolicyChangeRequest?: WorkspacePolicyChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/workspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validateOnly !== undefined) {
                localVarQueryParameter['validateOnly'] = validateOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacePolicyChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * Hook for asset service to report a change in spec of a given asset
         * @summary Asset changed hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetChangedHook(assetId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetChangedHook(assetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hook for asset service to report a deletion of a given asset
         * @summary Asset deleted hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetDeletedHook(assetId: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetDeletedHook(assetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete training policy for a given org unit.
         * @summary Delete training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrainingPolicy(scope, departmentId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete workspace policy for a given org unit.
         * @summary Delete workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkspacePolicy(scope, departmentId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of an training policy for a given org unit
         * @summary Get training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingPolicy(scope, departmentId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of a workspace policy for a given org unit
         * @summary Get workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacePolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacePolicy(scope, departmentId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of all the applied policies for this tenant.
         * @summary List policies
         * @param {'Workspace' | 'Training'} [workloadType] Policy for a speicifc workload type.
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] filter by this scope.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPolicies(workloadType?: 'Workspace' | 'Training', scope?: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPolicies(workloadType, scope, departmentId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply a training policy for a given org unite
         * @summary Overwrite training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyOverwriteRequest} [trainingPolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overwriteTrainingPolicy(validateOnly?: boolean, trainingPolicyOverwriteRequest?: TrainingPolicyOverwriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overwriteTrainingPolicy(validateOnly, trainingPolicyOverwriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply a workspace policy for a given org unit
         * @summary Overwrite workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyOverwriteRequest} [workspacePolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overwriteWorkspacePolicy(validateOnly?: boolean, workspacePolicyOverwriteRequest?: WorkspacePolicyOverwriteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacePolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overwriteWorkspacePolicy(validateOnly, workspacePolicyOverwriteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply changes to training policy for a given org unite
         * @summary Update training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyChangeRequest} [trainingPolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrainingPolicy(validateOnly?: boolean, trainingPolicyChangeRequest?: TrainingPolicyChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrainingPolicy(validateOnly, trainingPolicyChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Apply changes to workspace policy for a given org unit
         * @summary Update workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyChangeRequest} [workspacePolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkspacePolicy(validateOnly?: boolean, workspacePolicyChangeRequest?: WorkspacePolicyChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacePolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkspacePolicy(validateOnly, workspacePolicyChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolicyApiFp(configuration)
    return {
        /**
         * Hook for asset service to report a change in spec of a given asset
         * @summary Asset changed hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetChangedHook(assetId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.assetChangedHook(assetId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Hook for asset service to report a deletion of a given asset
         * @summary Asset deleted hook for asset service
         * @param {string} assetId Unique identifier of the asset.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetDeletedHook(assetId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.assetDeletedHook(assetId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete training policy for a given org unit.
         * @summary Delete training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrainingPolicy(scope, departmentId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete workspace policy for a given org unit.
         * @summary Delete workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkspacePolicy(scope, departmentId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of an training policy for a given org unit
         * @summary Get training policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: any): AxiosPromise<TrainingPolicy> {
            return localVarFp.getTrainingPolicy(scope, departmentId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a workspace policy for a given org unit
         * @summary Get workspace policy
         * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: any): AxiosPromise<WorkspacePolicy> {
            return localVarFp.getWorkspacePolicy(scope, departmentId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of all the applied policies for this tenant.
         * @summary List policies
         * @param {'Workspace' | 'Training'} [workloadType] Policy for a speicifc workload type.
         * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] filter by this scope.
         * @param {string} [departmentId] The unique id of the department.
         * @param {number} [projectId] The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPolicies(workloadType?: 'Workspace' | 'Training', scope?: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: any): AxiosPromise<PolicyListResponse> {
            return localVarFp.listPolicies(workloadType, scope, departmentId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply a training policy for a given org unite
         * @summary Overwrite training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyOverwriteRequest} [trainingPolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overwriteTrainingPolicy(validateOnly?: boolean, trainingPolicyOverwriteRequest?: TrainingPolicyOverwriteRequest, options?: any): AxiosPromise<TrainingPolicy> {
            return localVarFp.overwriteTrainingPolicy(validateOnly, trainingPolicyOverwriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply a workspace policy for a given org unit
         * @summary Overwrite workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyOverwriteRequest} [workspacePolicyOverwriteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overwriteWorkspacePolicy(validateOnly?: boolean, workspacePolicyOverwriteRequest?: WorkspacePolicyOverwriteRequest, options?: any): AxiosPromise<WorkspacePolicy> {
            return localVarFp.overwriteWorkspacePolicy(validateOnly, workspacePolicyOverwriteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply changes to training policy for a given org unite
         * @summary Update training policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {TrainingPolicyChangeRequest} [trainingPolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainingPolicy(validateOnly?: boolean, trainingPolicyChangeRequest?: TrainingPolicyChangeRequest, options?: any): AxiosPromise<TrainingPolicy> {
            return localVarFp.updateTrainingPolicy(validateOnly, trainingPolicyChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply changes to workspace policy for a given org unit
         * @summary Update workspace policy
         * @param {boolean} [validateOnly] Validate the given policy payload without applying it
         * @param {WorkspacePolicyChangeRequest} [workspacePolicyChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspacePolicy(validateOnly?: boolean, workspacePolicyChangeRequest?: WorkspacePolicyChangeRequest, options?: any): AxiosPromise<WorkspacePolicy> {
            return localVarFp.updateWorkspacePolicy(validateOnly, workspacePolicyChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI {
    /**
     * Hook for asset service to report a change in spec of a given asset
     * @summary Asset changed hook for asset service
     * @param {string} assetId Unique identifier of the asset.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public assetChangedHook(assetId: string, body?: object, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).assetChangedHook(assetId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hook for asset service to report a deletion of a given asset
     * @summary Asset deleted hook for asset service
     * @param {string} assetId Unique identifier of the asset.
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public assetDeletedHook(assetId: string, body?: object, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).assetDeletedHook(assetId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete training policy for a given org unit.
     * @summary Delete training policy
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
     * @param {string} [departmentId] The unique id of the department.
     * @param {number} [projectId] The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public deleteTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).deleteTrainingPolicy(scope, departmentId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete workspace policy for a given org unit.
     * @summary Delete workspace policy
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
     * @param {string} [departmentId] The unique id of the department.
     * @param {number} [projectId] The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public deleteWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).deleteWorkspacePolicy(scope, departmentId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of an training policy for a given org unit
     * @summary Get training policy
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
     * @param {string} [departmentId] The unique id of the department.
     * @param {number} [projectId] The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public getTrainingPolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).getTrainingPolicy(scope, departmentId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a workspace policy for a given org unit
     * @summary Get workspace policy
     * @param {'tenant' | 'cluster' | 'department' | 'project'} scope The scope that the policy relates to.
     * @param {string} [departmentId] The unique id of the department.
     * @param {number} [projectId] The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public getWorkspacePolicy(scope: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).getWorkspacePolicy(scope, departmentId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of all the applied policies for this tenant.
     * @summary List policies
     * @param {'Workspace' | 'Training'} [workloadType] Policy for a speicifc workload type.
     * @param {'tenant' | 'cluster' | 'department' | 'project'} [scope] filter by this scope.
     * @param {string} [departmentId] The unique id of the department.
     * @param {number} [projectId] The id of the project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public listPolicies(workloadType?: 'Workspace' | 'Training', scope?: 'tenant' | 'cluster' | 'department' | 'project', departmentId?: string, projectId?: number, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).listPolicies(workloadType, scope, departmentId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply a training policy for a given org unite
     * @summary Overwrite training policy
     * @param {boolean} [validateOnly] Validate the given policy payload without applying it
     * @param {TrainingPolicyOverwriteRequest} [trainingPolicyOverwriteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public overwriteTrainingPolicy(validateOnly?: boolean, trainingPolicyOverwriteRequest?: TrainingPolicyOverwriteRequest, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).overwriteTrainingPolicy(validateOnly, trainingPolicyOverwriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply a workspace policy for a given org unit
     * @summary Overwrite workspace policy
     * @param {boolean} [validateOnly] Validate the given policy payload without applying it
     * @param {WorkspacePolicyOverwriteRequest} [workspacePolicyOverwriteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public overwriteWorkspacePolicy(validateOnly?: boolean, workspacePolicyOverwriteRequest?: WorkspacePolicyOverwriteRequest, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).overwriteWorkspacePolicy(validateOnly, workspacePolicyOverwriteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply changes to training policy for a given org unite
     * @summary Update training policy
     * @param {boolean} [validateOnly] Validate the given policy payload without applying it
     * @param {TrainingPolicyChangeRequest} [trainingPolicyChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public updateTrainingPolicy(validateOnly?: boolean, trainingPolicyChangeRequest?: TrainingPolicyChangeRequest, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).updateTrainingPolicy(validateOnly, trainingPolicyChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply changes to workspace policy for a given org unit
     * @summary Update workspace policy
     * @param {boolean} [validateOnly] Validate the given policy payload without applying it
     * @param {WorkspacePolicyChangeRequest} [workspacePolicyChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public updateWorkspacePolicy(validateOnly?: boolean, workspacePolicyChangeRequest?: WorkspacePolicyChangeRequest, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).updateWorkspacePolicy(validateOnly, workspacePolicyChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SynchronizationApi - axios parameter creator
 * @export
 */
export const SynchronizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingClusterPolicy: async (clusterId: string, after?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getTrainingClusterPolicy', 'clusterId', clusterId)
            const localVarPath = `/api/v1/policy/training/cluster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceClusterPolicy: async (clusterId: string, after?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getWorkspaceClusterPolicy', 'clusterId', clusterId)
            const localVarPath = `/api/v1/policy/workspace/cluster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given training policy
         * @summary Update the sync status of a training policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSyncStatusTraining: async (syncStatusUpdate?: SyncStatusUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/training/sync/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncStatusUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given workspace policy
         * @summary Update the sync status of a workspace policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSyncStatusWorkspace: async (syncStatusUpdate?: SyncStatusUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policy/workspace/sync/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncStatusUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizationApi - functional programming interface
 * @export
 */
export const SynchronizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SynchronizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingClusterPolicy(clusterId: string, after?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingClusterPolicy(clusterId, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceClusterPolicy(clusterId: string, after?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceClusterPolicy(clusterId, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given training policy
         * @summary Update the sync status of a training policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSyncStatusTraining(syncStatusUpdate?: SyncStatusUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSyncStatusTraining(syncStatusUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given workspace policy
         * @summary Update the sync status of a workspace policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSyncStatusWorkspace(syncStatusUpdate?: SyncStatusUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSyncStatusWorkspace(syncStatusUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SynchronizationApi - factory interface
 * @export
 */
export const SynchronizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SynchronizationApiFp(configuration)
    return {
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingClusterPolicy(clusterId: string, after?: number, options?: any): AxiosPromise<ClusterPolicy> {
            return localVarFp.getTrainingClusterPolicy(clusterId, after, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
         * @summary Get cluster policies
         * @param {string} clusterId The id of the cluster
         * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceClusterPolicy(clusterId: string, after?: number, options?: any): AxiosPromise<ClusterPolicy> {
            return localVarFp.getWorkspaceClusterPolicy(clusterId, after, options).then((request) => request(axios, basePath));
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given training policy
         * @summary Update the sync status of a training policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSyncStatusTraining(syncStatusUpdate?: SyncStatusUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSyncStatusTraining(syncStatusUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * This API intended for the cluster sync to update the sync status of a given workspace policy
         * @summary Update the sync status of a workspace policy
         * @param {SyncStatusUpdate} [syncStatusUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSyncStatusWorkspace(syncStatusUpdate?: SyncStatusUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSyncStatusWorkspace(syncStatusUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchronizationApi - object-oriented interface
 * @export
 * @class SynchronizationApi
 * @extends {BaseAPI}
 */
export class SynchronizationApi extends BaseAPI {
    /**
     * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
     * @summary Get cluster policies
     * @param {string} clusterId The id of the cluster
     * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public getTrainingClusterPolicy(clusterId: string, after?: number, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).getTrainingClusterPolicy(clusterId, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of policy objects that can be applied in a cluster, given a list of policy descriptors
     * @summary Get cluster policies
     * @param {string} clusterId The id of the cluster
     * @param {number} [after] search for updates with updateCount greater than the value passed for this parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public getWorkspaceClusterPolicy(clusterId: string, after?: number, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).getWorkspaceClusterPolicy(clusterId, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API intended for the cluster sync to update the sync status of a given training policy
     * @summary Update the sync status of a training policy
     * @param {SyncStatusUpdate} [syncStatusUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public updateSyncStatusTraining(syncStatusUpdate?: SyncStatusUpdate, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).updateSyncStatusTraining(syncStatusUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API intended for the cluster sync to update the sync status of a given workspace policy
     * @summary Update the sync status of a workspace policy
     * @param {SyncStatusUpdate} [syncStatusUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationApi
     */
    public updateSyncStatusWorkspace(syncStatusUpdate?: SyncStatusUpdate, options?: AxiosRequestConfig) {
        return SynchronizationApiFp(this.configuration).updateSyncStatusWorkspace(syncStatusUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


