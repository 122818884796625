<template>
  <section class="auto-delete-time-section">
    <div class="row items-center q-gutter-md q-mb-sm">
      <span>Set auto-deletion after completion or failure</span>
      <runai-tooltip
        tooltip-position="right"
        :tooltip-text="`The timeframe after which a ${workloadType} that has completed or failed will be automatically deleted. If this is left blank, the ${workloadType} won't be deleted automatically. 
If the fields are set to 0, the ${workloadType} will be deleted immediately after completion or failure.`"
      />
    </div>
    <div v-if="showInputs" class="time-inputs-container row items-center justify-between q-pa-md">
      <div>
        <div class="q-pb-md">Set the timeframe between {{ workloadType }} completion/failure and auto-deletion</div>
        <runai-duration-input
          :rules="[notZeroValue]"
          :model-value="modelValue"
          @update:model-value="updateAutoDeletionTime"
          :disable="disable"
        />
      </div>
      <q-btn
        aid="remove-auto-delete-btn"
        class="close-button"
        icon="fa-regular fa-xmark"
        color="grey-7"
        flat
        round
        :disable="disable"
        @click="updateAutoDeletionTime(null, true)"
      />
    </div>
    <q-btn
      v-else
      :disable="disable"
      aid="add-auto-delete-btn"
      label="+ auto-deletion"
      flat
      color="primary"
      @click="showInputs = true"
    />
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { errorMessages } from "@/common/error-message.constant";
import { RunaiDurationInput } from "@/components/common/runai-duration-input/";
export default defineComponent({
  name: "auto-delete-time-section",
  components: {
    RunaiDurationInput,
    RunaiTooltip,
  },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    workloadType: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      showInputs: false,
    };
  },
  created() {
    if (this.modelValue !== null) {
      this.showInputs = true;
    }
  },
  methods: {
    notZeroValue(): string | boolean {
      if (this.modelValue === null || this.modelValue === undefined) {
        return errorMessages.VALID_TIME_FRAME;
      }
      return this.modelValue >= 0 ? true : errorMessages.VALID_TIME_FRAME;
    },
    updateAutoDeletionTime(time: number | null, closeInputs = false): void {
      if (closeInputs) {
        this.showInputs = false;
      }
      this.$emit("update:model-value", time);
    },
  },
});
</script>

<style lang="scss" scoped>
.time-inputs-container {
  background-color: $body-background-color;
  gap: 10px;
}

.close-button {
  color: $black-54;
  font-size: 12px;
}
</style>
