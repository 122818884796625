<template>
  <q-card flat square class="wrapper" :class="{ 'q-mx-md': !noGutters }">
    <div class="row items-center">
      <div class="col-11">
        <q-card-section> <slot /> </q-card-section>
      </div>
      <div class="col-1">
        <q-btn v-if="!hideCloseButton" @click="$emit('close')" size="12px" round flat icon="fa-regular fa-xmark"></q-btn>
      </div>
    </div>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  emits: ["close"],
  props: {
    hideCloseButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noGutters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
</script>
<style scoped lang="scss">
.wrapper {
  background-color: $body-background-color;
  height: 113px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
