import type { IMetricNodePool, INodePool } from "@/models/node-pool.model";
import { ChartDataKeys, nodePoolColumns } from "@/models/node-pool.model";
import { K8S_API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { IFilterBy } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";
import type {
  IPrometheusMetric,
  IPrometheusNodePoolMetrics,
  IPrometheusResponse,
  PrometheusMetricResponse,
} from "@/models/prometheus.model";
import {
  DAY_IN_HOURS,
  MIN_PROMETHEUS_STEP,
  PERCENTAGE_FACTOR,
  PROMETHEUS_STEP_FACTOR,
  SECOND_IN_MS,
} from "@/models/prometheus.model";
import { filterService } from "@/services/filter.service/filter.service";
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { nodeService } from "@/services/control-plane/node.service/node.service";
import { projectService } from "@/services/control-plane/project.service/project.service";
import type { INodePoolResources, INodePoolsResourcesRow, IProject, IResourceQuota } from "@/models/project.model";
import { nodeWorkloadMetrics, nodeWorkloadService } from "@/services/node-workload.service/node-workload.service";
import { dateUtil, TimeUnit } from "@/utils/date.util";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_AMD_GPU_BASED_NODES_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { OVER_QUOTA_ENABLED_VALUE, RESOURCE_MAX_ALLOWED_INFINITE_VALUE } from "@/models/resource.model";
import type { Nodes } from "@/swagger-models/cluster-service-client";
import { nodePoolUtil } from "@/utils/node-pool.util/node-pool.util";

export const nodePoolService = {
  list,
  getNodePools,
  create,
  edit,
  remove,
  getNodePoolGPUAndCPUMetrics,
  getEmptyNodePoolsModel,
  enrichProjectNodePoolWithResourceMetric,
  enrichDepartmentNodePoolsWithResourceMetric,
};

export const nodePoolValidationRegexp = {
  labelKey: "^(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])?/?([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$",
  labelValue: "^(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])?$",
};

const nodePoolsEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}/node-pools`;
const UTILIZATION_SLICES_AMOUNT = 4;
// Because using floor(3) to make the utilization distribution we cannot use 100
// To achieve this need to use clamp_max in Prometheus, @see below the use
const MAX_UTILIZATION_PERCENTAGE = 99.99;

async function getNodePools(clusterUuid: string): Promise<Array<INodePool>> {
  return await controlPlaneService.get(nodePoolsEndpoint(clusterUuid));
}
async function getProjectsAllocation(clusterUuid: string) {
  const projects: Array<IProject> = await projectService.list(clusterUuid);
  const nodePoolCpus: Map<string, number> = new Map<string, number>();
  const nodePoolGpus: Map<string, number> = new Map<string, number>();
  const nodePoolMemory: Map<string, number> = new Map<string, number>();
  projects.forEach((project: IProject) => {
    project.nodePoolsResources.forEach((nodePoolResource: INodePoolResources) => {
      const nodePoolName = nodePoolResource.nodePool.name;
      if (nodePoolResource?.gpu?.deserved != null) {
        nodePoolGpus.set(nodePoolName, (nodePoolGpus.get(nodePoolName) || 0) + (nodePoolResource?.gpu?.deserved || 0));
      }
      if (nodePoolResource?.cpu?.deserved != null) {
        nodePoolCpus.set(nodePoolName, (nodePoolCpus.get(nodePoolName) || 0) + (nodePoolResource?.cpu?.deserved || 0));
      }
      if (nodePoolResource?.memory?.deserved != null) {
        nodePoolMemory.set(
          nodePoolName,
          (nodePoolMemory.get(nodePoolName) || 0) + (nodePoolResource?.memory?.deserved || 0),
        );
      }
    });
  });
  return { nodePoolCpus, nodePoolGpus, nodePoolMemory };
}

async function _enrichNodePoolsWithMetrics(
  nodePools: Array<INodePool>,
  nodePoolsMetricsInfo: Array<IMetricNodePool>,
  clusterUuid: string,
): Promise<Array<INodePool>> {
  const { nodePoolCpus, nodePoolGpus, nodePoolMemory } = await getProjectsAllocation(clusterUuid);
  const getNodesFromMetrics = nodePoolsMetricsInfo && nodePoolsMetricsInfo.length > 0;

  return nodePools.map((dbNodePool) => {
    const nodePoolName = dbNodePool.name;
    const metricNodePool = nodePoolsMetricsInfo.find((element) => nodePoolName === element.name);
    dbNodePool.nodes = getNodesFromMetrics ? metricNodePool?.nodes || "" : _parseNodePoolNodesFromDb(dbNodePool.nodes);
    dbNodePool.projectsGpus = nodePoolGpus.get(nodePoolName);
    const cpusCores = nodePoolCpus.get(nodePoolName);
    dbNodePool.projectsCpus = cpusCores ? cpusCores / SECOND_IN_MS : cpusCores;
    dbNodePool.projectsMemory = nodePoolMemory.get(nodePoolName);
    // 'metricNodePool' is the second one, cause we want it's values to override, if needed
    return { ...dbNodePool, ...metricNodePool };
  });
}

function getWorkloadQueries(useNewWorkloadMetrics: boolean, clusterFilter: string): Record<string, string> {
  const promQueries: Record<string, string> = {};
  if (useNewWorkloadMetrics) {
    promQueries[
      nodeWorkloadMetrics.workloadsGpus.name
    ] = `runai_allocated_gpu_count_per_pod{${clusterFilter}} * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}}==1)`;
    promQueries[
      nodeWorkloadMetrics.workloadsCpus.name
    ] = `runai_allocated_millicpus_per_pod{${clusterFilter}} * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}}==1)`;
    promQueries[
      nodeWorkloadMetrics.workloadsMemory.name
    ] = `runai_allocated_memory_per_pod{${clusterFilter}} * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}}==1)`;
    promQueries[
      nodeWorkloadMetrics.workloadGpuIndex.name
    ] = `runai_gpu_utilization_per_pod_per_gpu{${clusterFilter}} * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}}==1)`;
  } else {
    promQueries[
      nodeWorkloadMetrics.oldWorkloadInfo.name
    ] = `sum((runai_pod_phase_with_info{${clusterFilter}}==1) * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}}==1)) by (nodepool,project,job_name,job_type)`;
  }
  return promQueries;
}

async function getWorkloadsFromMetrics(
  clusterUuid: string,
  nodePools: Array<INodePool>,
  useNewWorkloadMetrics: boolean,
): Promise<void> {
  const clusterFilter = `clusterId="${clusterUuid}"`;
  const promQueries = getWorkloadQueries(useNewWorkloadMetrics, clusterFilter);

  const promResponse = await prometheusService.multipleQueries(promQueries);
  const nodePoolToResponse: Record<string, Array<{ metricName: string; metric: IPrometheusMetric }>> = {};
  promResponse.forEach((response: IPrometheusResponse) => {
    response.data.forEach((promMetric: IPrometheusMetric) => {
      const nodePoolName = promMetric.metric.nodepool;
      if (!nodePoolToResponse[nodePoolName]) {
        nodePoolToResponse[nodePoolName] = [];
      }
      nodePoolToResponse[nodePoolName].push({ metricName: response.key, metric: promMetric });
    });
  });
  nodePools.forEach((nodePool: INodePool) => {
    nodePool.workloads = nodeWorkloadService.updateWorkloadData(nodePoolToResponse[nodePool.name]);
  });
}

async function list(
  nodePools: Array<INodePool>,
  clusterVersion: string,
  clusterUuid: string,
  displayedColumns: Array<ITableColumn>,
  useNewWorkloadMetrics: boolean,
  filterBy: IFilterBy,
): Promise<Array<INodePool>> {
  const isVersionSupportAmdGpu =
    isNewerVersion(clusterVersion, MIN_AMD_GPU_BASED_NODES_VERSION) || clusterVersion.includes(TEST_ENV_VERSION);
  const nodePoolsMetricsInfo: Array<IMetricNodePool> = await getNodePoolMetricsInfo(
    clusterVersion,
    clusterUuid,
    displayedColumns?.filter((column) => column.display)?.map((column) => column.field as string),
    useNewWorkloadMetrics,
  );
  let filteredNodePools = await _enrichNodePoolsWithMetrics(nodePools, nodePoolsMetricsInfo, clusterUuid);
  await getWorkloadsFromMetrics(clusterUuid, filteredNodePools, useNewWorkloadMetrics);
  if (isVersionSupportAmdGpu) {
    try {
      const response: Nodes = await nodeService.getNodesFromClusterService(clusterUuid);
      filteredNodePools = nodePoolUtil.enrichNodePoolsWithInfoFromCluster(filteredNodePools, response.nodes);
    } catch (e: unknown) {
      console.error(e);
      throw e;
    }
  }
  if (!filterBy || !filterBy.displayedColumns) return filteredNodePools;

  if (filterBy.searchTerm) {
    filteredNodePools = filterService.filterBySearchTerm<INodePool>(
      filteredNodePools,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      nodePoolColumns,
    ) as Array<INodePool>;
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    filteredNodePools = filterService.filterByColumns(
      filteredNodePools,
      filterBy.columnFilters,
      nodePoolColumns,
    ) as Array<INodePool>;
  }
  return filteredNodePools;
}

async function remove(clusterUuid: string, nodePoolId: number): Promise<INodePool> {
  return await controlPlaneService.delete(nodePoolsEndpoint(clusterUuid) + `/${nodePoolId}`);
}

async function edit(
  clusterUuid: string,
  nodePoolId: number,
  request: {
    labelKey: string;
    labelValue: string;
    placementStrategy: { gpu: string; cpu: string };
    overProvisioningRatio: number;
  },
): Promise<INodePool> {
  return await controlPlaneService.put(nodePoolsEndpoint(clusterUuid) + `/${nodePoolId}`, request);
}

async function create(
  clusterUuid: string,
  request: {
    name: string;
    labelKey: string;
    labelValue: string;
    placementStrategy: { gpu: string; cpu: string };
    overProvisioningRatio: number;
  },
): Promise<INodePool> {
  return await controlPlaneService.post(nodePoolsEndpoint(clusterUuid), request);
}

async function getNodePoolMetricsInfo(
  clusterVersion: string,
  clusterId: string,
  queryKeys: string[],
  useNewMetrics: boolean,
): Promise<Array<IMetricNodePool>> {
  const { promQueries } = createNodePoolPromQueries(clusterVersion, clusterId, useNewMetrics);

  const filteredPromQueries: Record<string, string> = {};
  queryKeys.forEach((key: string) => {
    if (promQueries[key]) {
      filteredPromQueries[key] = promQueries[key];
    }
  });
  const nodePoolsMetricsInfo: Array<IMetricNodePool> = await prometheusService
    .multipleQueries(filteredPromQueries)
    .then((res) => _prepareDataByNodePools(res));

  const nodePoolNodesFromMetrics: Record<string, string[]> = await getNodePoolNodesFromMetrics(clusterId);

  nodePoolsMetricsInfo.forEach((metricNodePool) => {
    metricNodePool.nodes = nodePoolNodesFromMetrics[metricNodePool.name]?.join(", ");
  });
  return nodePoolsMetricsInfo;
}

function createNodePoolPromQueries(
  clusterVersion: string,
  clusterId: string,
  useNewMetrics: boolean,
  nodePoolFilter = "",
): IPrometheusNodePoolMetrics {
  const nodePromQueries = nodeService.createNodePromQueries(clusterVersion, clusterId, useNewMetrics).promQueries;
  const clusterFilter = `clusterId="${clusterId}"`;

  const promQueries: Record<string, string> = {
    utilization: _avgNodeMetricsByNodePool(
      `(${nodePromQueries.utilization}) * (${nodePromQueries.totalGpus})`,
      nodePromQueries.totalGpus,
      clusterFilter,
      nodePoolFilter,
    ),
    totalGpus: _sumNodeMetricsByNodePool(nodePromQueries.totalGpus, clusterFilter, nodePoolFilter),
    allocatedGpus: _sumNodeMetricsByNodePool(nodePromQueries.allocatedGpus, clusterFilter, nodePoolFilter),
    totalCpus: _sumNodeMetricsByNodePool(nodePromQueries.totalCpus, clusterFilter, nodePoolFilter),
    usedCpus: _avgNodeMetricsByNodePool(
      `(${nodePromQueries.usedCpus}) * (${nodePromQueries.totalCpus})`,
      nodePromQueries.totalCpus,
      clusterFilter,
      nodePoolFilter,
    ),
    allocatedCpus: _sumNodeMetricsByNodePool(nodePromQueries.allocatedCpus, clusterFilter, nodePoolFilter),
    totalGpuMemory: _sumNodeMetricsByNodePool(nodePromQueries.totalGpuMemory, clusterFilter, nodePoolFilter),
    usedGpuMemory: _sumNodeMetricsByNodePool(nodePromQueries.usedGpuMemory, clusterFilter, nodePoolFilter),
    totalCpuMemory: _sumNodeMetricsByNodePool(nodePromQueries.totalCpuMemory, clusterFilter, nodePoolFilter),
    usedCpuMemory: _sumNodeMetricsByNodePool(nodePromQueries.usedCpuMemory, clusterFilter, nodePoolFilter),
    allocatedMemory: _sumNodeMetricsByNodePool(nodePromQueries.allocatedMemory, clusterFilter, nodePoolFilter),
  };
  return { promQueries };
}

async function getNodePoolGPUAndCPUMetrics(
  clusterVersion: string,
  clusterUuid: string,
  start: number,
  end: number,
  nodePoolName: string,
): Promise<Record<string, PrometheusMetricResponse>> {
  const promQueries: Record<string, string> = _getNodePoolPrometheusQueries(clusterVersion, clusterUuid, nodePoolName);
  const hoursInRange: number = dateUtil.differenceBy(TimeUnit.hour, end, start);
  return await prometheusService
    .multipleQueriesWithTimeRange(
      promQueries,
      start / SECOND_IN_MS,
      end / SECOND_IN_MS,
      (PROMETHEUS_STEP_FACTOR * (hoursInRange / DAY_IN_HOURS)) | MIN_PROMETHEUS_STEP,
    )
    .then(_timeRangeSeriesValues);
}

function _sumNodeMetricsByNodePool(metric: string, clusterFilter: string, nodePoolFilter = ""): string {
  return `sum((${metric}) * on(node) group_left(nodepool) (runai_node_nodepool{${clusterFilter}${nodePoolFilter}}==1)) by (nodepool)`;
}

function _dcgmGpuQuery(clusterFilter: string, nodePoolFilter: string): string {
  return `((runai_gpu_utilization_per_gpu{${clusterFilter}}) or on (UUID) (label_replace(dcgm_gpu_utilization{${clusterFilter}}, "pod_ip", "$1", "instance", "(.*):(.*)") * on(pod_ip) group_left(node) kube_pod_info{${clusterFilter},created_by_name=~".*dcgm-exporter"})) * on(node) group_left(nodepool) (runai_node_nodepool {${clusterFilter}${nodePoolFilter}} == 1)`;
}

/*

 */
function _fakeUtilizationRange(sliceAmount: number): string {
  let fakeUtilizationQuery = "";
  for (let i = 0; i < sliceAmount; i++) {
    fakeUtilizationQuery += `count_values("range", vector(${i})) ${i < sliceAmount - 1 ? "or " : ""}`;
  }
  return `(${fakeUtilizationQuery})*0`;
}

/*
  Depends on slicesAmount the query will return the count of the gpu in node with range of utilization in the sliced.
  example for result of 4 slicesAmount, means that the query will return the count of the gpu in node with range of utilization in the sliced.
  0-25%, 2-50%, 50-75%, 75-100%
  {range="0"} 10 // Means that there are 10 nodes with utilization in range of 0-25%
  {range="1"} 9  // Means that there are 9 nodes with utilization in range of 25-50%
  {range="2"} 2  // Means that there are 2 nodes with utilization in range of 50-75%
  {range="3"} 2  // Means that there are 2 nodes with utilization in range of 75-100%
*/
function _countGpuPerNodeMetricInUtilizationSlicedRanges(
  clusterFilter: string,
  nodePoolFilter: string,
  slicesAmount: number,
): string {
  return `count_values("range", floor(${slicesAmount} * clamp_max(${_dcgmGpuQuery(
    clusterFilter,
    nodePoolFilter,
  )},${MAX_UTILIZATION_PERCENTAGE}) / 100)) or ${_fakeUtilizationRange(slicesAmount)}`;
}

function _allocatedGpusPerNodePool(clusterFilter: string, nodePoolFilter: string): string {
  return `sum((runai_allocated_gpu_count_per_node{${clusterFilter}} or on(node) count(runai_gpus_is_running_with_pod2{${clusterFilter}}==1) by (node)) * on(node) group_left(nodepool) (runai_node_nodepool {${clusterFilter}${nodePoolFilter}} == 1)) or vector(0)`;
}

function _notAllocatedGpuPerNodePool(clusterFilter: string, nodePoolFilter: string, totalGpusQuery: string): string {
  return `(sum(${totalGpusQuery}) or vector(0)) - (${_allocatedGpusPerNodePool(clusterFilter, nodePoolFilter)})`;
}

function _avgNodeMetricsByNodePool(
  nominatorMetric: string,
  denominatorMetric: string,
  clusterFilter: string,
  nodePoolFilter = "",
): string {
  return `(${_sumNodeMetricsByNodePool(
    nominatorMetric,
    clusterFilter,
    nodePoolFilter,
  )}) / on (nodepool) (${_sumNodeMetricsByNodePool(denominatorMetric, clusterFilter, nodePoolFilter)})`;
}

function _prepareDataByNodePools(queryResults: Array<IPrometheusResponse>): Array<IMetricNodePool> {
  const nodePoolsMap: Record<string, IMetricNodePool> = queryResults.reduce(
    (nodePoolMetricRecord: Record<string, IMetricNodePool>, query: IPrometheusResponse) => {
      query.data.forEach((data: IPrometheusMetric) => {
        const nodePoolName: string | undefined = data.metric.nodepool;
        if (!nodePoolName || nodePoolName === "-") return nodePoolMetricRecord;
        if (!nodePoolMetricRecord[nodePoolName]) {
          nodePoolMetricRecord[nodePoolName] = {
            name: nodePoolName,
          } as IMetricNodePool;
        }
        nodePoolMetricRecord[nodePoolName][query.key as keyof IMetricNodePool] = data.value[1];
      });
      return nodePoolMetricRecord;
    },
    {} as Record<string, IMetricNodePool>,
  );
  return Object.values(nodePoolsMap);
}

async function getNodePoolNodesFromMetrics(clusterId: string): Promise<Record<string, string[]>> {
  const clusterFilter = `clusterId="${clusterId}"`;
  const promQueries: Record<string, string> = {
    nodepools: `runai_node_nodepool{${clusterFilter}}==1`,
  };

  return await prometheusService.multipleQueries(promQueries).then((res) => _prepareNodePoolNodesData(res));
}

function _prepareNodePoolNodesData(queryResults: Array<IPrometheusResponse>): Record<string, string[]> {
  return queryResults.reduce((nodepoolNodesRecord: Record<string, string[]>, query: IPrometheusResponse) => {
    query.data.forEach((data: IPrometheusMetric) => {
      const nodePoolName: string | undefined = data.metric.nodepool;
      if (!nodePoolName || nodePoolName === "-") return nodepoolNodesRecord;
      if (!nodepoolNodesRecord[nodePoolName]) {
        nodepoolNodesRecord[nodePoolName] = [];
      }
      nodepoolNodesRecord[nodePoolName].push(data.metric.node);
    });
    return nodepoolNodesRecord;
  }, {} as Record<string, string[]>);
}

function _parseNodePoolNodesFromDb(nodes: string): string {
  try {
    const nodesArray = JSON.parse(nodes);
    return nodesArray.join(", ");
  } catch (e: unknown) {
    return "";
  }
}

function _calculateMetricUtilization(
  counterMetric: string,
  denominatorMetric: string,
  clusterId: string,
  nodePoolFilter = "",
): string {
  return `${_sumNodeMetricsByNodePool(counterMetric, clusterId, nodePoolFilter)} / ${_sumNodeMetricsByNodePool(
    denominatorMetric,
    clusterId,
    nodePoolFilter,
  )} * ${PERCENTAGE_FACTOR}`;
}

function _convertDateTimeSeries(values: number[][]): number[][] {
  return values?.map((dateTimeAndValue) => {
    const dateTime = +dateTimeAndValue[0] * SECOND_IN_MS;
    const value = +dateTimeAndValue[1];
    return [dateTime, value];
  });
}

function _timeRangeSeriesValues(queriesResponse: IPrometheusResponse[]): Record<string, PrometheusMetricResponse> {
  const metrics: Record<string, Array<Array<number>[]>> = {};
  queriesResponse.forEach((response: IPrometheusResponse) => {
    if (
      response.key === ChartDataKeys.gpuPerNodeMetricInSlicedRanges ||
      response.key === ChartDataKeys.gpuAllocated ||
      response.key === ChartDataKeys.gpuNotAllocated
    ) {
      const series: PrometheusMetricResponse = Array<Array<number>[]>(response.data.length);
      response.data.forEach((data: IPrometheusMetric, index) => {
        const values: Array<Array<number>> = data.values || [];
        series[index] = _convertDateTimeSeries(values);
      });
      metrics[response.key] = series;
    } else {
      const series: PrometheusMetricResponse = Array<Array<number>[]>(response.data.length);
      response.data.forEach((data: IPrometheusMetric) => {
        const values: Array<Array<number>> = data.values || [];
        series[0] = _convertDateTimeSeries(values);
        metrics[response.key] = series;
      });
    }
  });
  return metrics;
}

function _getNodePoolPrometheusQueries(
  clusterVersion: string,
  clusterId: string,
  nodePoolName: string,
): Record<string, string> {
  const clusterFilter = `clusterId="${clusterId}"`;
  const nodePoolFilter = `, nodepool="${nodePoolName}"`;
  const nodePromQueries: Record<string, string> = nodeService.createNodePromQueries(
    clusterVersion,
    clusterId,
    false,
  ).promQueries;
  const { promQueries } = createNodePoolPromQueries(clusterVersion, clusterId, false, nodePoolFilter);
  return {
    [ChartDataKeys.totalGpus]: _sumNodeMetricsByNodePool(nodePromQueries.totalGpus, clusterFilter, nodePoolFilter),
    [ChartDataKeys.gpuAllocated]: _allocatedGpusPerNodePool(clusterFilter, nodePoolFilter),
    [ChartDataKeys.gpuNotAllocated]: _notAllocatedGpuPerNodePool(
      clusterFilter,
      nodePoolFilter,
      _sumNodeMetricsByNodePool(nodePromQueries.totalGpus, clusterFilter, nodePoolFilter),
    ),
    [ChartDataKeys.gpuPerNodeMetricInSlicedRanges]: _countGpuPerNodeMetricInUtilizationSlicedRanges(
      clusterFilter,
      nodePoolFilter,
      UTILIZATION_SLICES_AMOUNT,
    ),
    // The order here is very important, it should be the same as the order in the valuesRangePerGPU function
    [ChartDataKeys.gpuUtilization]: promQueries.utilization,
    [ChartDataKeys.gpuMemoryUtilization]: _calculateMetricUtilization(
      nodePromQueries.usedGpuMemory,
      nodePromQueries.totalGpuMemory,
      clusterFilter,
      nodePoolFilter,
    ),
    [ChartDataKeys.cpuUtilization]: promQueries.usedCpus,
    [ChartDataKeys.cpuMemoryUtilization]: _calculateMetricUtilization(
      nodePromQueries.usedCpuMemory,
      nodePromQueries.totalCpuMemory,
      clusterFilter,
      nodePoolFilter,
    ),
  };
}

async function getEmptyNodePoolsModel(clusterId: string, isCpuEnabled: boolean): Promise<INodePoolResources[]> {
  const nodePools: Array<INodePool> = await getNodePools(clusterId);
  return nodePools.map((np: INodePool) => {
    return {
      nodePool: {
        id: np.id,
        name: np.name,
      },
      gpu: {
        deserved: 0,
        maxAllowed: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
      } as IResourceQuota,
      cpu: {
        deserved: null,
        maxAllowed: isCpuEnabled ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : null,
        overQuotaWeight: isCpuEnabled ? OVER_QUOTA_ENABLED_VALUE : null,
      } as IResourceQuota,
      memory: {
        deserved: null,
        maxAllowed: isCpuEnabled ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : null,
        overQuotaWeight: isCpuEnabled ? OVER_QUOTA_ENABLED_VALUE : null,
      } as IResourceQuota,
    };
  });
}

//project node pools
async function enrichProjectNodePoolWithResourceMetric(
  nodePools: INodePoolResources[],
  projectName: string,
  clusterUuid: string,
): Promise<INodePoolsResourcesRow[]> {
  const promQueries: Record<string, string> = createProjectNodePoolPromQueries(projectName, clusterUuid);
  let nodePoolsMetrics: Array<IMetricNodePool>;
  try {
    nodePoolsMetrics = await prometheusService
      .multipleQueries(promQueries)
      .then((res: IPrometheusResponse[]) => _prepareDataByNodePools(res));
  } catch (e) {
    console.error(e);
    return nodePools;
  }

  return nodePools.map((nodePool: INodePoolResources): INodePoolsResourcesRow => {
    const nodePoolMetric = nodePoolsMetrics.find((metric: IMetricNodePool) => metric.name === nodePool.nodePool.name);
    if (nodePoolMetric) {
      return {
        ...nodePool,
        allocatedMemory: nodePoolMetric.allocatedMemory,
        allocatedGpus: nodePoolMetric.allocatedGpus,
        allocatedCpu: nodePoolMetric.allocatedCpus,
      };
    }

    return nodePool;
  });
}

function createProjectNodePoolPromQueries(projectName: string, clusterUuid: string): Record<string, string> {
  const projectFilter = `project="${projectName}"`;
  const clusterFilter = `clusterId="${clusterUuid}"`;
  return {
    allocatedGpus: `sum(runai_allocated_gpu_count_per_pod{${projectFilter},${clusterFilter}})  by(nodepool)`,
    allocatedCpus: `sum(runai_allocated_millicpus_per_pod{${projectFilter},${clusterFilter}})  by(nodepool)`,
    allocatedMemory: `sum(runai_allocated_memory_per_pod{${projectFilter},${clusterFilter}})  by(nodepool)`,
  };
}

async function enrichDepartmentNodePoolsWithResourceMetric(
  nodePools: INodePoolResources[],
  departmentName: string,
  clusterUuid: string,
): Promise<INodePoolsResourcesRow[]> {
  let metric;
  try {
    metric = await controlPlaneService.get(`v1/k8s/clusters/${clusterUuid}/departments/metrics`);
  } catch (e) {
    console.error(e);
    return nodePools;
  }

  const nodePoolMap: { [key: string]: INodePoolsResourcesRow } = {};

  for (const nodePool of nodePools) {
    const nodePoolName = nodePool.nodePool.name;
    nodePoolMap[nodePoolName] = {
      ...nodePool,
    };
  }

  for (const item of metric.data) {
    for (const nodePool of item.current.resources) {
      nodePoolMap[nodePool.nodepoolName].allocatedCpu = nodePool.cpu.allocated;
      //1000000 = 10^6 - backend return in mb need to convert to bytes to keep on homogeneity - projects use prometheus for metric and department use department/metric
      nodePoolMap[nodePool.nodepoolName].allocatedMemory = (nodePool.memory.allocated * 1000000).toString();
      nodePoolMap[nodePool.nodepoolName].allocatedGpus = nodePool.gpu.allocated;
    }
  }

  return Object.values(nodePoolMap);
}
