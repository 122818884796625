<template>
  <q-td class="audit-status-col" :class="getStatusClass">
    {{ status }}
  </q-td>
</template>

<script lang="ts">
import type { IAuditEvent } from "@/models/audit.model";
import type { ITableColumn } from "@/models/table.model";
import { getFieldContent } from "@/utils/table-format.util";
import { defineComponent, type PropType } from "vue";

const STATUS_CLASSES = {
  SUCCESS: "success",
  FAILED: "failed",
};

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<IAuditEvent>,
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  computed: {
    status(): string {
      return getFieldContent(this.column, this.row);
    },
    getStatusClass(): string {
      return this.status === "Failed" ? STATUS_CLASSES.FAILED : STATUS_CLASSES.SUCCESS;
    },
  },
});
</script>
<style lang="scss" scoped>
.success {
  color: $success;
}

.failed {
  color: $negative;
}
</style>
