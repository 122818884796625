<template>
  <section class="inner-table table-summary">
    <div class="table-tab summary">
      <runai-svg-icon name="departments-icon" size="22"></runai-svg-icon>
    </div>
    <table>
      <tbody>
        <tr>
          <th>Department total</th>
          <td>
            <div class="total-display">{{ totalGpus }}</div>
          </td>
          <template v-if="isCpuEnabled">
            <td>
              <div class="total-display">{{ totalCpus }}</div>
            </td>
            <td>
              <div class="total-display">{{ totalMemory }}</div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

//cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { EResourceType } from "@/models/resource.model";

//services
import { resourceUtil } from "@/utils/resource.util";
import type { INodePoolResources } from "@/models/project.model";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  props: {
    resources: {
      type: Array as PropType<Array<INodePoolResources>>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    totalGpus(): string | number {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.GPU);
    },
    totalCpus(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.CPU);
    },
    totalMemory(): number | string {
      return resourceUtil.getResourcesDisplayValue(this.resources, EResourceType.MEMORY);
    },
  },
});
</script>

<style lang="scss" scoped>
.total-display {
  width: 80px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
</style>
