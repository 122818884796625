<template>
  <section class="node-pools-table">
    <department-node-pools-table-body
      :model-value="modelValue"
      @update:model-value="$emit('update:model-value', $event)"
      :is-cpu-enabled="isCpuEnabled"
      :read-only="readOnly"
    ></department-node-pools-table-body>
    <department-node-pools-table-summary
      :resources="modelValue.nodePoolsResources"
      :entity-name="modelValue.name"
      :is-cpu-enabled="isCpuEnabled"
    ></department-node-pools-table-summary>
  </section>
</template>

<script lang="ts">
import type { IDepartment } from "@/models/department.model";

import { defineComponent, type PropType } from "vue";

import DepartmentNodePoolsTableBody from "./department-node-pools-table-body.vue";
import DepartmentNodePoolsTableSummary from "./department-node-pools-table-summary.vue";

export default defineComponent({
  components: {
    DepartmentNodePoolsTableBody,
    DepartmentNodePoolsTableSummary,
  },
  props: {
    modelValue: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.node-pools-table {
  .inner-table {
    display: flex;
    gap: 11px;
    .table-tab {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $body-background-color;
      border-radius: 5px;
      width: 46px;
      min-width: 46px;
      padding: 10px 0;
      font-size: 12px;
      margin-block: 4px;
      &:not(.summary) {
        min-height: 130px;
      }
      &.summary {
        justify-content: center;
      }

      .rotated-text {
        white-space: nowrap;
        transform: rotate(-90deg);
        position: relative;
        top: 40px;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
      color: $black-54;
      tr {
        border-top: 1px solid $black-12;
        height: 55px;
        &.arrow-line td {
          padding-block: 20px;
        }
        td,
        th {
          width: 150px;
          text-align: left;
        }
        th {
          font-weight: 500;
        }
      }
    }
    &.table-summary {
      tbody tr:first-of-type {
        td,
        th {
          color: $black-70;
          font-size: 14px;
        }
      }
      tbody tr:last-of-type {
        border-bottom: 1px solid $black-12;
      }
    }
    &.table-body {
      thead tr:first-of-type {
        border-top: none;
      }
      .table-tab {
        margin-top: 55px;
      }
    }
  }
}
</style>
