<template>
  <q-td>
    {{ formattedText }}
    <q-tooltip v-if="text">{{ text }}</q-tooltip>
  </q-td>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { ITableColumn } from "@/models/table.model";
import { getFieldContent } from "@/utils/table-format.util";
import { formatArrayStrings } from "@/utils/table-format.util";

export default defineComponent({
  name: "strings-list-col",
  props: {
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
  },
  computed: {
    content(): Array<string> {
      return getFieldContent(this.column, this.row);
    },
    text(): string | null {
      if (!this.content) return null;
      return this.content.join(", ");
    },
    formattedText(): string {
      return formatArrayStrings(this.content);
    },
  },
});
</script>

<style scoped lang="scss"></style>
