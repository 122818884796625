<template>
  <section class="installation-command-box q-pa-md q-my-md">
    <div class="q-mb-md">
      <slot name="top"></slot>
    </div>
    <section class="middle row no-wrap items-center">
      <div class="commands col-11 q-pa-sm">
        <pre ref="elCommands" :style="{ 'max-height': maxHeight ? maxHeight + 'px' : 'unset', 'overflow-y': 'auto' }">
           <slot name="commands"/>
        </pre>
      </div>
      <q-btn class="copy-btn" flat size="10px" round icon="fas fa-copy" @click="copyText">
        <q-tooltip class="tool-tip-dark"> Copy to clipboard </q-tooltip>
      </q-btn>
    </section>
    <div class="q-mt-md">
      <slot name="bottom"></slot>
    </div>
  </section>
</template>
<script lang="ts">
import { copyToClipboard } from "quasar";
import type { PropType } from "vue";

export default {
  props: {
    maxHeight: {
      type: Number as PropType<number>,
      required: false,
    },
  },
  methods: {
    async copyText(): Promise<void> {
      const text: string = (this.$refs.elCommands as HTMLElement).innerText;
      await copyToClipboard(text);
    },
  },
};
</script>
<style lang="scss">
.installation-command-box {
  border: 1px solid #e0e0e0;
  .middle {
    background: #f1f3f4;
    pre {
      font-family: Roboto;
      white-space: pre-line;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }
  }
  .tool-tip-dark {
    // TODO: check why its not working
    background-color: $positive;
    color: $white;
  }
}
</style>
