<template>
  <runai-select-card :value="isSelected" @clicked="$emit('card-clicked')" :aid="getAid(data.name)">
    <div class="project-card workload-card-content" @mouseover="showTip = true" @mouseout="showTip = false">
      <section class="top-content">
        <div class="col text-subtitle1 text-weight-medium q-mb-xm" :class="{ 'text-primary': isSelected }">
          {{ data.name }}
        </div>
        <div class="col text-caption">Resources</div>
      </section>

      <section class="bottom-content text-caption">
        <runai-resources-chart
          :show-tool-tip="showToolTip"
          :over-quota-value="overQuotaValue"
          :quota-value="quotaValue"
          :allocated-value="allocatedValue"
        />
      </section>
    </div>
  </runai-select-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RunaiResourcesChart } from "@/components/runai-resources-chart";
import { RunaiSelectCard } from "@/components/common/runai-select-card";
// models
import type { IProjectResources } from "@/models/project.model";

// utils
import { spaceToDash, toLowerCase } from "@/utils/string.util";

export default defineComponent({
  components: {
    RunaiResourcesChart,
    RunaiSelectCard,
  },
  emits: ["card-clicked"],
  props: {
    data: {
      type: Object as PropType<IProjectResources>,
      required: true,
    },
    isSelected: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      showTip: false as boolean,
    };
  },
  computed: {
    quotaValue(): number {
      return this.data.deservedGpus || 0;
    },
    allocatedValue(): number {
      return this.data.allocatedGpus || 0;
    },
    overQuotaValue(): number {
      return this.allocatedValue > this.quotaValue ? this.allocatedValue - this.quotaValue : 0;
    },
    showToolTip(): boolean {
      return this.showTip;
    },
  },
  methods: {
    getAid(name: string): string {
      return `${toLowerCase(spaceToDash(name))}-card`;
    },
  },
});
</script>
