// models
import type { TEntity } from "@/models/entity.model";
import type { ITableColumn } from "@/models/table.model";

// services
import { numberFormat, numberWithPercentFormat, onlyPercentFormat } from "@/utils/format.util";
import { dateUtil } from "@/utils/date.util";

export type TCalcPercentFormatFunc = (val: string | number, row: TEntity) => string;
export type TNumberFormatFunc = (val: string | number) => string;

export function tableNumberFormat(suffix?: string): TNumberFormatFunc {
  return (val: string | number): string => {
    return numberFormat(val, suffix);
  };
}

export function tableNumberWithPercentFormat(compareField: string, isMemory = false): TCalcPercentFormatFunc {
  return (val: string | number, row: TEntity) => {
    const compareValue = +(row[compareField as keyof TEntity] as string);
    return numberWithPercentFormat(+val, compareValue, isMemory);
  };
}

export function percentFormat(compareField: string): TCalcPercentFormatFunc {
  return (val: string | number, row: TEntity) => {
    const compareValue = row[compareField as keyof TEntity];
    if (!compareValue) {
      return "-";
    }
    return onlyPercentFormat(val == null ? 0 : +val, +compareValue);
  };
}

export function getMemoryUnitsForUI(val: string): string {
  if (!val) return "";
  return `${val.slice(0, val.length - 1)} ${(val.slice(val.length - 1) + "b").toUpperCase()}`;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function getFieldContent(column: ITableColumn, row: unknown): any {
  const val = getField(column, row as Record<string, unknown>);
  return column.format ? column.format(val, row) : val;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function getField(column: ITableColumn, row: Record<string, unknown>): any {
  return typeof column.field === "function" ? column.field(row) : row[column.field];
}

export function formatDate(date: string | number) {
  if (!date) return "-";
  return dateUtil.dateAndTimeFormat(new Date(date));
}

export function formatArrayStrings(array: Array<string>, separator = ", ", maxLength = 30): string {
  const string = array.join(separator);
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  }
  return string;
}
