<template>
  <section class="eula-contract">
    <runai-base-dialog :model-value="true" is-modal-persistent hide-x size="width-lg" :no-padding="true">
      <template #header>Run:ai - End User License Agreement</template>

      <template #body>
        <div class="eula-contract-content">
          <embed
            class="contract"
            align="middle"
            src="/Runai_EULA_Mar_5_2024.pdf&#35;toolbar=0"
            type="application/pdf"
            frameBorder="10"
            scrolling="auto"
            height="100%"
            width="100%"
          />
        </div>
      </template>

      <template #footer>
        <q-btn label="I AGREE" color="primary" @click="onAgreed" aid="agree-button" :loading="processing" />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

// stores
import { useAuthStore } from "@/stores/auth.store";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

// models
import { EContractTypes } from "@/models/tenant.model";

// utils
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  data() {
    return {
      authStore: useAuthStore(),
      processing: false as boolean,
    };
  },
  computed: {
    contractType(): EContractTypes {
      return this.authStore.tenant.contractType;
    },
    isNormalContract(): boolean {
      return this.contractType === EContractTypes.Normal;
    },
    isFreemiumContract(): boolean {
      return this.contractType === EContractTypes.Freemium;
    },
  },
  methods: {
    onAgreed(): void {
      try {
        this.processing = true;
        this.authStore.signEULA();
        this.$q.notify(alertUtil.getSuccess("Successfully Signed Terms of Service"));
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed To Sign Terms of Service"));
      } finally {
        this.processing = false;
      }
    },
  },
});
</script>

<style lang="scss">
.eula-contract-content {
  padding: 20px;
}
.contract {
  min-height: 500px;
}
</style>
