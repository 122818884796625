<template>
  <span class="help-dropdown">
    <q-btn
      class="help-dropdown"
      content-class="help-dropdown-menu-container"
      flat
      round
      icon="far fa-circle-question"
      size="12px"
    >
      <q-menu class="help-dropdown-menu-container" anchor="bottom middle" self="top middle">
        <q-list>
          <q-item class="no-underline" v-close-popup clickable href="https://docs.run.ai/" target="_blank">
            <q-item-section>
              <q-item-label class="row items-center"
                ><span class="q-mr-sm">{{ EHelpMenuTexts.DOCS }}</span>
                <q-icon name="fa-regular fa-arrow-up-right-from-square"
              /></q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="no-underline" v-close-popup clickable href="/api/docs" target="_blank">
            <q-item-section>
              <q-item-label class="row items-center">
                <span class="q-mr-sm">{{ EHelpMenuTexts.ADMIN }}</span>
                <q-icon name="fa-regular fa-arrow-up-right-from-square" />
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-if="isClusterConnected" v-close-popup @click="openCliInstallationModal">
            <q-item-section>
              <q-item-label class="row items-center">
                <span class="q-mr-sm">{{ EHelpMenuTexts.RESEARCHER }}</span>
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-else class="no-underline" v-close-popup :href="researcherCliHref" target="_blank">
            <q-item-section>
              <q-item-label class="row items-center">
                <span class="q-mr-sm">{{ EHelpMenuTexts.RESEARCHER }}</span>
                <q-icon name="fa-regular fa-arrow-up-right-from-square" />
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="!isTrial" class="no-underline" v-close-popup :href="supportLink" target="_blank">
            <q-item-section>
              <q-item-label class="row items-center"
                ><span class="q-mr-sm">{{ EHelpMenuTexts.SUPPORT }}</span>
                <q-icon name="fa-regular fa-arrow-up-right-from-square"
              /></q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="isTrial && !isSelfHosted" clickable v-close-popup @click="showContactUsModal = true">
            <q-item-section>
              <q-item-label class="row items-center">
                <span class="q-mr-sm">{{ EHelpMenuTexts.CONTACT_US }}</span>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <q-tooltip class="q-pa-sm tooltip-dark">
      <div>Help</div>
    </q-tooltip>

    <researcher-download-cli-modal :open="openCliModal" @close="openCliModal = false" />
    <runai-contact-us-dilaog v-if="showContactUsModal" @close="showContactUsModal = false" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { ResearcherDownloadCliModal } from "../researcher-download-cli-modal";
import { RunaiContactUsDilaog } from "@/components/common/runai-contact-us-dialog";

// stores
import { useAuthStore } from "@/stores/auth.store";
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";

enum EHelpMenuTexts {
  DOCS = "Documentation Center",
  ADMIN = "Administrator API",
  RESEARCHER = "Researcher Command Line Interface",
  SUPPORT = "Contact Support",
  CONTACT_US = "Contact us",
}

export default defineComponent({
  components: {
    ResearcherDownloadCliModal,
    RunaiContactUsDilaog,
  },
  data() {
    return {
      authStore: useAuthStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      openCliModal: false as boolean,
      showContactUsModal: false as boolean,
      EHelpMenuTexts: EHelpMenuTexts,
    };
  },
  computed: {
    setupDropdownMenuLayout(): string {
      return "list-menu-layout";
    },
    isTrial(): boolean {
      return this.authStore.isTrial;
    },
    isAdmin(): boolean {
      return this.authStore.isAdmin;
    },
    isSelfHosted(): boolean {
      return this.appStore.isSelfHosted;
    },
    isClusterConnected(): boolean {
      const currentCluster = this.clusterStore.currentCluster;
      return !!currentCluster?.domain && this.clusterStore.isCLIAvailable;
    },
    researcherCliHref(): string {
      return this.isTrial
        ? "https://docs.run.ai/latest/Researcher/cli-reference/Introduction/"
        : "https://docs.run.ai/latest/admin/researcher-setup/cli-install/";
    },
    supportLink() {
      return `https://runai.secure.force.com/casesupport/CreateCaseForm?email=${this.authStore.userEmail}`;
    },
  },
  methods: {
    openCliInstallationModal(): void {
      this.openCliModal = true;
    },
  },
});
</script>

<style lang="scss">
.help-dropdown-menu-container {
  background: $dark-gray !important;
  color: $white;
}
</style>
@/components/common/runai-contact-us-dialog
