<template>
  <div class="boolean-setting row q-my-sm justify-between items-center">
    <div class="row items-center">
      <span class="q-mr-md text-subtitle1">{{ setting.label }}</span>
      <q-badge v-if="setting.stage" class="text-italic" :color="getBadgeColor(setting.stage)">
        {{ setting.stage }}
      </q-badge>
    </div>
    <q-toggle :model-value="setting.value" @update:model-value="updateSettings(setting.key, $event)" :disable="disabled">
      <q-tooltip v-if="disabled" anchor="top middle" self="bottom middle">
        {{ disabledMessage }}
      </q-tooltip>
    </q-toggle>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { Setting } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "boolean-setting",
  emits: ["update-setting"],
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateSettings(key: string | undefined, value: string | number | boolean | null) {
      if (value === null || !key) return;
      this.$emit("update-setting", key, value);
    },
    getBadgeColor(stage: string): string {
      return stage === "Alpha" ? "negative" : "amber-5";
    },
  },
});
</script>
