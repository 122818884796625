import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import { grafanaService } from "@/services/control-plane/grafana.service/grafana.service";
import type { Dashboards } from "@/swagger-models/backend-client";

export const useGrafanaStore = defineStore("grafana", {
  state: () => ({
    grafanaLoggedIn: false,
    dashboards: {} as Dashboards,
  }),
  actions: {
    /*
        This call to the SMG (self-managed Grafana) does 2 things:
        1. sets the grafana cookie
        2. sets the grafana loggedIn flag
        The SMG will always be in the /grafana path of the current location (test.run.ai/grafana, app.run.ai/grafana, etc).
    */
    login(): void {
      this.grafanaLoggedIn = true;
    },
    async smgLogin(): Promise<void> {
      const authStore = useAuthStore();
      console.debug(`reloading grafana token to cookie for smg tenant: ${authStore.tenant.authRealm}`);
      const headers: Record<string, string> = {};
      headers["Authorization"] = `Bearer ${authStore.accessToken}`;
      headers["Realm-Name"] = `${authStore.tenant.authRealm}`;
      await grafanaService.smgLogin({ ...headers, "content-type": "application/json" });
      this.grafanaLoggedIn = true;
    },
    async getGrafanaDashboards(clusterId?: string): Promise<void> {
      this.dashboards = await grafanaService.getDashboards(clusterId);
    },
  },
});
