import {
  ETenantType,
  type IAuthProviderData,
  type ITenantClientConfigInfo,
  type ITenantServerConfigInfo,
} from "@/models/tenant.model";

export const tenantUtil = {
  getClientConfigInfo,
  getServerConfigInfo,
};

const DEFAULT_SERVER_CLIENT_ID = "runai";

function getClientConfigInfo(
  tenantType: ETenantType,
  authData: IAuthProviderData,
  authRealm: string,
): ITenantClientConfigInfo {
  if ([ETenantType.SAAS_KEYCLOAK, ETenantType.SELF_HOSTED].includes(tenantType)) {
    return {
      airgapped: true,
      authFlow: "cli",
      realm: authRealm,
      clientId: "runai-cli",
      idpIssuerUrl: authData.issuerUrl,
      redirectUri: undefined,
    };
  } else {
    return {
      airgapped: true,
      authFlow: "remote-browser",
      realm: authRealm,
      clientId: "runai-cli",
      idpIssuerUrl: authData.issuerUrl,
      redirectUri: `${window.location.origin}/oauth-code`,
    };
  }
}

function getServerConfigInfo(tenantType: ETenantType, authData: IAuthProviderData): ITenantServerConfigInfo {
  if ([ETenantType.SAAS_KEYCLOAK, ETenantType.SELF_HOSTED].includes(tenantType)) {
    return {
      clientId: DEFAULT_SERVER_CLIENT_ID,
      issuerUrl: authData.issuerUrl,
      groupsClaim: undefined,
    };
  } else {
    return {
      clientId: DEFAULT_SERVER_CLIENT_ID,
      issuerUrl: authData.issuerUrl,
      groupsClaim: "groups",
    };
  }
}
