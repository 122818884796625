// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";
import { makeId, pick } from "@/utils/common.util";

// model
import {
  type DatasourceListResponseEntry,
  type HostPathCreationRequest,
  type HostPathAsset,
  type NFSCreationRequest,
  type S3CreationRequest,
  type NFSAsset,
  type S3Asset,
  type GitCreationRequest,
  type GitAsset,
  type PVCCreationRequest,
  type PVCAsset,
  type ClaimInfo,
  type AccessKeyListResponse,
  type AccessKey,
  type Password,
  type PasswordListResponse,
  type HostPathUpdateRequest,
  type S3UpdateRequest,
  type PVCUpdateRequest,
  type NFSUpdateRequest,
  type GitUpdateRequest,
  ClaimInfoVolumeModeEnum,
  AssetKind,
} from "@/swagger-models/assets-service-client";
import type { PVCListInfo, PVCListResponse } from "@/swagger-models/k8s-objects-tracker-client";
import type { IAssetsFilter } from "@/models/filter.model";
import {
  allDataSourceColumns,
  type IUINFSCreationRequest,
  type IUIPVCCreationRequest,
  type IUIHostPathCreationRequest,
  type IUIVolume,
  type IUIS3CreationRequest,
  type IUIGitCreationRequest,
} from "@/models/data-source.model";
// constant
import { API, K8S_API } from "@/common/api.constant";
import type { TDataSourceKinds } from "@/models/data-source.model";
import { dataSourceUtil } from "@/utils/data-source.util";
import type { IScopeInfo } from "@/utils/data-source.util/data-source.util";
import type { IScopeCredentialsList } from "@/models/credential.model";

export const dataSourceService = {
  list,
  remove,
  createHostPath,
  createNFS,
  createPVC,
  createS3,
  createGit,
  listStorageClass,
  listAccessKeysByScope,
  listPasswordsByScope,
  getHostPathById,
  getS3ById,
  getPvcById,
  getGitById,
  getNFSById,
  updateHostPath,
  updateGit,
  updateNFS,
  updatePVC,
  updateS3,
  loadPVCAssets,
  createWorkloadVolumes,
  listExistingPVCs,
  getDataSourceById,
  // helpers
  getEmptyHostPathModel,
  getEmptyNfsModel,
  getEmptyS3Model,
  getEmptyGitModel,
  getEmptyPvcModel,
  getEmptyClaimInfo,
};

const endpoint = `${API.asset}/datasource`;

// api calls
async function list(filterBy: IAssetsFilter = {}): Promise<Array<DatasourceListResponseEntry>> {
  const filters: IAssetsFilter = pick(
    filterBy,
    "sortBy",
    "page",
    "rowsPerPage",
    "projectId",
    "departmentId",
    "scope",
    "usageInfo",
    "complyToProject",
    "complyToWorkloadType",
    "clusterId",
  );
  let dataSources: Array<DatasourceListResponseEntry> = await controlPlaneService
    .get(endpoint, filters)
    .then((res) => res.entries);

  if (!filterBy || !filterBy.displayedColumns) return dataSources;

  if (filterBy.searchTerm) {
    dataSources = filterService.filterBySearchTerm<DatasourceListResponseEntry>(
      dataSources,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allDataSourceColumns,
    );
  }

  if (filterBy.columnFilters && filterBy?.columnFilters.length) {
    dataSources = filterService.filterByColumns(dataSources, filterBy.columnFilters, allDataSourceColumns);
  }
  return dataSources;
}

async function remove(dataSource: DatasourceListResponseEntry): Promise<void> {
  const asset: string = dataSource.meta.kind === "hostPath" ? "host-path" : dataSource.meta.kind;
  await controlPlaneService.delete(`${endpoint}/${asset}/${dataSource.meta.id}`);
}

async function listExistingPVCs(scopeType: string, scopeId: string): Promise<PVCListInfo[]> {
  const loadedPvcs: PVCListResponse = await controlPlaneService.get(`${API.v1}/pvcs`, { scopeType, scopeId });
  return loadedPvcs.pvcs;
}

async function listStorageClass(clusterId: string): Promise<string[]> {
  const data = await controlPlaneService.get(`${K8S_API.v1}/clusters/${clusterId}/storage-classes`);
  return data.storageClasses;
}

async function listAccessKeysByScope(scope: IScopeCredentialsList): Promise<AccessKey[]> {
  const data: AccessKeyListResponse = await controlPlaneService.get(`${API.asset}/credentials/access-key`, scope);
  return data.entries;
}

async function listPasswordsByScope(scope: IScopeCredentialsList): Promise<Password[]> {
  const data: PasswordListResponse = await controlPlaneService.get(`${API.asset}/credentials/password`, scope);
  return data.entries;
}

async function getDataSourceById(
  dataSourceId: string,
  dataSourceKind: TDataSourceKinds,
): Promise<HostPathAsset | S3Asset | PVCAsset | NFSAsset | GitAsset | null> {
  switch (dataSourceKind) {
    case AssetKind.S3:
      return getS3ById(dataSourceId);
    case AssetKind.HostPath:
      return getHostPathById(dataSourceId);
    case AssetKind.Nfs:
      return getNFSById(dataSourceId);
    case AssetKind.Pvc:
      return getPvcById(dataSourceId);
    case AssetKind.Git:
      return getGitById(dataSourceId);
    default:
      return null;
  }
}
async function getHostPathById(hostPathId: string): Promise<HostPathAsset> {
  return controlPlaneService.get(`${endpoint}/host-path/${hostPathId}`);
}

async function getS3ById(s3Id: string): Promise<S3Asset> {
  return controlPlaneService.get(`${endpoint}/s3/${s3Id}`);
}
async function getPvcById(pvcId: string): Promise<PVCAsset> {
  return controlPlaneService.get(`${endpoint}/pvc/${pvcId}`);
}
async function getNFSById(nfsId: string): Promise<NFSAsset> {
  return controlPlaneService.get(`${endpoint}/nfs/${nfsId}`);
}

async function getGitById(gitId: string): Promise<GitAsset> {
  return controlPlaneService.get(`${endpoint}/git/${gitId}`);
}

async function updateHostPath(hostPathId: string, hostPathDataSource: HostPathUpdateRequest): Promise<HostPathAsset> {
  return controlPlaneService.put(`${endpoint}/host-path/${hostPathId}`, hostPathDataSource);
}

async function updateS3(s3Id: string, s3DataSource: S3UpdateRequest): Promise<S3Asset> {
  return controlPlaneService.put(`${endpoint}/s3/${s3Id}`, s3DataSource);
}
async function updatePVC(pvcId: string, pvcDataSource: PVCUpdateRequest): Promise<PVCAsset> {
  return controlPlaneService.put(`${endpoint}/pvc/${pvcId}`, pvcDataSource);
}
async function updateNFS(nfsId: string, nfsDataSource: NFSUpdateRequest): Promise<NFSAsset> {
  return controlPlaneService.put(`${endpoint}/nfs/${nfsId}`, nfsDataSource);
}

async function updateGit(gitId: string, gitDataSource: GitUpdateRequest): Promise<GitAsset> {
  return controlPlaneService.put(`${endpoint}/git/${gitId}`, gitDataSource);
}

async function loadPVCAssets(pvcIds: Array<string>): Promise<Array<PVCAsset>> {
  if (!pvcIds?.length) return [];
  return await Promise.all(pvcIds.map(getPvcById));
}

async function createWorkloadVolumes(
  workloadName: string,
  volumes: Array<IUIVolume>,
  scopeInfo: IScopeInfo,
): Promise<Array<string>> {
  const createdVolumes: PVCAsset[] = await Promise.all(
    volumes.map((volume: IUIVolume) => {
      volume.claimName = `${workloadName}-pvc-${makeId(5)}`;
      const pvc = dataSourceUtil.convertVolumeToPvc(volume, scopeInfo);
      return createPVC(pvc);
    }),
  );
  return createdVolumes.map((volume: PVCAsset) => volume.meta.id);
}

// helpers
function getEmptyHostPathModel(): IUIHostPathCreationRequest {
  return {
    meta: {
      name: "",
      scope: null,
      projectId: null,
    },
    spec: {
      path: "",
      readOnly: false,
      mountPath: "",
    },
  };
}

function getEmptyNfsModel(): IUINFSCreationRequest {
  return {
    meta: {
      name: "",
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      path: "",
      readOnly: false,
      server: "",
      mountPath: "",
    },
  };
}
function getEmptyS3Model(): IUIS3CreationRequest {
  return {
    meta: {
      name: "",
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      bucket: "",
      path: "",
      url: null,
      accessKeyAssetId: "",
    },
  };
}

function getEmptyGitModel(): IUIGitCreationRequest {
  return {
    meta: {
      name: "",
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      repository: "",
      branch: null,
      revision: null,
      path: "",
      passwordAssetId: "",
    },
  };
}

function getEmptyPvcModel(): IUIPVCCreationRequest {
  return {
    meta: {
      name: "",
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      path: "",
      claimName: "",
      existingPvc: true,
      claimInfo: null,
      readOnly: false,
    },
  };
}

function getEmptyClaimInfo(): ClaimInfo {
  return {
    size: "",
    storageClass: null,
    accessModes: {
      readWriteOnce: false,
      readOnlyMany: false,
      readWriteMany: false,
    },
    volumeMode: ClaimInfoVolumeModeEnum.Filesystem,
  };
}

async function createHostPath(hostPath: HostPathCreationRequest): Promise<HostPathAsset> {
  return controlPlaneService.post(`${endpoint}/host-path`, hostPath);
}
async function createS3(s3: S3CreationRequest): Promise<S3Asset> {
  return controlPlaneService.post(`${endpoint}/s3`, s3);
}

async function createNFS(nfs: NFSCreationRequest): Promise<NFSAsset> {
  return controlPlaneService.post(`${endpoint}/nfs`, nfs);
}

async function createGit(git: GitCreationRequest): Promise<GitAsset> {
  return controlPlaneService.post(`${endpoint}/git`, git);
}

async function createPVC(pvc: PVCCreationRequest): Promise<PVCAsset> {
  return controlPlaneService.post(`${endpoint}/pvc`, pvc);
}
