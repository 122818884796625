<template>
  <section class="row items-center q-mt-md">
    <q-field :error="displayFormHint" class="col-4 form-hint no-padding">
      <template v-slot:error> {{ $options.errorMessage }} </template>
    </q-field>
    <div class="buttons q-ml-auto">
      <q-btn aid="cancel-project-btn" class="q-mx-sm" flat label="cancel" color="primary" @click="$emit('on-cancel')" />
      <q-btn
        aid="save-project-btn"
        class="q-px-lg"
        :label="isNewProject ? 'create project' : 'save'"
        color="primary"
        @click="$emit('on-save')"
        :loading="loading"
        :disable="loading"
      ></q-btn>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { errorMessages } from "@/common/error-message.constant";

// stores
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  components: {},
  emits: ["on-save", "on-cancel"],
  errorMessage: errorMessages.FORM_INCOMPLETE,
  props: {
    isFormValid: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
    };
  },
  computed: {
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
  },
  methods: {
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
  },
  watch: {
    isFormValid(valid: boolean) {
      if (valid) {
        this.displayFormHint = false;
      } else {
        this.showHint();
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
