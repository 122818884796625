// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { pick } from "@/utils/common.util";

// models
import { allDeploymentColumns, type IDeploymentList } from "@/models/deployment.model";
import { type IFilterBy, EOrderDirection } from "@/models/filter.model";
import { ToolType } from "@/swagger-models/assets-service-client";
// constants
import { K8S_API } from "@/common/api.constant";
import type { IToolItem } from "@/models/workspace.model";

export interface IDeploymentListTemp extends Omit<IDeploymentList, "connections"> {
  connections?: Array<string>;
}

export const deploymentService = {
  list,
  remove,
};

function deploymentsEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/deployments`;
}

// api calls
async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Array<IDeploymentList>> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage", "descending");
  const res: Array<IDeploymentListTemp> = await controlPlaneService.get(`${deploymentsEndpoint(clusterUuid)}`, {
    ...filters,
    sortDirection: filterBy.descending ? EOrderDirection.DESC : EOrderDirection.ASC,
  });

  let deployments: Array<IDeploymentList> = res.map((deployment: IDeploymentListTemp) => {
    let connections: Array<IToolItem> | undefined;
    if (deployment.connections) {
      connections = deployment.connections.map((url: string) => {
        return {
          toolType: ToolType.Custom,
          toolName: "chatbot-ui",
          url,
        };
      });
    }

    return {
      ...deployment,
      connections,
    };
  });

  if (!filterBy || !filterBy.displayedColumns) return deployments;

  if (filterBy.searchTerm) {
    deployments = filterService.filterBySearchTerm<IDeploymentList>(
      deployments,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allDeploymentColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    deployments = filterService.filterByColumns(deployments, filterBy.columnFilters, allDeploymentColumns);
  }
  return deployments;
}

async function remove(deployment: IDeploymentList) {
  await researcherService.deleteWorkload(deployment.inferenceworkloadName || "undefined", deployment.namespace);
  return deployment;
}
