import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";
import { RESEARCHER } from "@/common/api.constant";
import type { IClusterProject } from "@/models/cluster.model";
import type { IDownloadLogParams } from "@/models/logs.model";

export const researcherService = {
  getProjects,
  activateWorkload,
  stopWorkload,
  deleteJob,
  deleteWorkload,
  downloadLogs,
};

async function getProjects(): Promise<Array<IClusterProject>> {
  const projects = await clusterApiService.get(`${RESEARCHER.v1}/projects`);
  return projects.data;
}

async function activateWorkload(workloadName: string, project: string) {
  return clusterApiService.post(`${RESEARCHER.v1}/jobs/suspension/resume`, [{ name: workloadName, project }]);
}

async function stopWorkload(workloadName: string, project: string) {
  return clusterApiService.post(`${RESEARCHER.v1}/jobs/suspension/suspend`, [{ name: workloadName, project }]);
}

async function deleteJob(workloadName: string, project: string) {
  return clusterApiService.delete(`${RESEARCHER.v1}/jobs`, [
    {
      name: workloadName,
      project: project,
    },
  ]);
}

async function deleteWorkload(workloadName: string, namespace: string) {
  return clusterApiService.delete(
    `${RESEARCHER.v1}/workload/proxy/namespaces/${namespace}/InferenceWorkload/${workloadName}`,
  );
}

async function downloadLogs(downloadLogParams: IDownloadLogParams): Promise<Blob> {
  return await clusterApiService.get(`${RESEARCHER.v1}/logsfile`, downloadLogParams, { responseType: "blob" });
}
