<template>
  <section class="runai-app-loader">
    <vue3-lottie class="lottie-loader" :animation-data="animationData" :height="50" :width="50" :speed="2.2" />
  </section>
</template>

<script lang="ts">
import { Vue3Lottie } from "vue3-lottie";
import { defineComponent } from "vue";
import animationData from "./animation-data.json";

export default defineComponent({
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      animationData,
    };
  },
});
</script>

<style lang="scss" scoped>
.runai-app-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;
  .lottie-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
