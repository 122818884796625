export const K8S_API = {
  v1: "v1/k8s",
};

export const API = {
  v1: "api/v1",
  asset: "api/v1/asset",
  authorization: "api/v1/authorization",
  accessRules: "api/v1/authorization/access-rules",
};

export const RESEARCHER = {
  v1: "researcher/api/v1",
};
