<template>
  <access-rule-expansion-item
    :loading="loading"
    :is-collapsed="!!accessRuleItem.accessRuleId"
    :display-error="displayError"
    :is-admin-tenant-role="isAdminTenantRole"
    :is-inherited-from-group="isInheritedFromGroup"
    :access-rule-subject-id="accessRuleItem.subjectId"
    @close="deleteRule"
    @save="saveRule"
  >
    <template #summary>
      <access-rule-summary :page="page" :access-rule="accessRuleItem" />
    </template>
    <template #content>
      <q-form ref="accessRuleForm">
        <div class="row">
          <div class="col-5 q-mr-lg">
            <runai-select
              v-if="displayConfig.subject"
              options-dense
              label="Subject"
              :model-value="accessRuleItem.subjectTypeOption"
              @update:model-value="updateSelectedSubjectType"
              :options="subjectOptions"
              :rules="[requiredSubject]"
            />
          </div>
          <div class="col-5">
            <subject-name-select
              v-if="accessRuleItem.subjectTypeOption"
              :subject-type-option="accessRuleItem.subjectTypeOption"
              :rules="[requiredSubjectId]"
              :subject-name="accessRuleItem.subjectId || ''"
              @update:subject-name="updateSelectedSubjectName"
            />
          </div>
        </div>
        <div :class="{ 'q-mt-sm': displayConfig.users }" class="row">
          <div class="col-5">
            <runai-select
              label="Role"
              :options="roleOptions"
              :model-value="accessRuleItem.roleOption"
              @update:model-value="updateSelectedRole"
              :rules="[requiredRoleType]"
            />
          </div>
        </div>
        <scope-input
          v-if="displayConfig.scope"
          :selected-scope="accessRuleItem.scopeInput"
          @clear="updateSelectedScope(null)"
          @update-selected="updateSelectedScope"
        />
      </q-form>
    </template>
  </access-rule-expansion-item>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//cmps
import { AccessRuleExpansionItem } from "@/components/rbac/access-rule/access-rule-management-modal/access-rule-expansion-item/";
import { SubjectNameSelect } from "@/components/rbac/common/subject-name-select/";
import { ScopeInput } from "@/components/rbac/common/scope-input/";
//model
import type { ISelectOption } from "@/models/global.model";
import type { IAccessRuleDisplayConfig, IAccessRuleItem } from "@/models/access-rule.model";
import type { EAccessRuleModalPage } from "@/models/access-rule.model";
import { errorMessages } from "@/common/error-message.constant";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { AccessRuleSummary } from "@/components/rbac/access-rule/access-rule-management-modal/access-rule-summary/";
import { RunaiSelect } from "@/components/common/runai-select";
import { ERoleName } from "@/models/access-rule.model";
import { ScopeType } from "@/swagger-models/authorization-client";
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  name: "access-rule-item",
  components: { AccessRuleSummary, ScopeInput, SubjectNameSelect, AccessRuleExpansionItem, RunaiSelect },
  emits: ["save-rule", "delete-rule", "update", "is-valid"],
  props: {
    displayConfig: {
      type: Object as PropType<IAccessRuleDisplayConfig>,
      required: true,
    },
    subjectOptions: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [],
    },
    roleOptions: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [],
    },
    accessRuleItem: {
      type: Object as PropType<IAccessRuleItem>,
      required: true,
    },
    page: {
      type: String as PropType<EAccessRuleModalPage>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isClosingAccessRule: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isInheritedFromGroup: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  computed: {
    displayError() {
      return this.isClosingAccessRule && !this.loading && !this.accessRuleItem.saved;
    },
    isAdminTenantRole(): boolean {
      return (
        this.authStore.currentUser.id === this.accessRuleItem.subjectId &&
        this.accessRuleItem.roleName === ERoleName.SystemAdmin &&
        this.accessRuleItem.scopeType === ScopeType.Tenant
      );
    },
  },
  methods: {
    updateSelectedSubjectType(option: ISelectOption): void {
      this.$emit("update", { ...this.accessRuleItem, subjectTypeOption: option });
    },
    updateSelectedSubjectName(subjectId: string): void {
      this.$emit("update", { ...this.accessRuleItem, subjectId: subjectId });
    },
    updateSelectedRole(option: ISelectOption): void {
      this.$emit("update", { ...this.accessRuleItem, roleOption: option });
    },
    updateSelectedScope(scope: IOrgTreeNodeId | null): void {
      this.$emit("update", { ...this.accessRuleItem, scopeInput: scope });
    },
    async validate(): Promise<boolean> {
      const form = this.$refs["accessRuleForm"] as HTMLFormElement;
      return await (form as HTMLFormElement).validate();
    },
    requiredRoleType(val: ISelectOption): boolean | string {
      return !!val || errorMessages.ROLE_TYPE_NOT_EMPTY;
    },
    requiredSubject(val: ISelectOption): boolean | string {
      return !!val || errorMessages.SUBJECT_NOT_EMPTY;
    },
    requiredSubjectId(val: string): boolean | string {
      return !!val || errorMessages.SUBJECT_ID_NOT_EMPTY;
    },
    async saveRule(): Promise<void> {
      const isValid = await this.validate();
      this.$emit("is-valid", isValid);
      if (!isValid) {
        return;
      }
      this.$emit("save-rule");
    },
    deleteRule(): void {
      this.$emit("delete-rule");
    },
  },
});
</script>

<style scoped lang="scss"></style>
