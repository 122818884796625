<template>
  <section class="cpu-analytics-index">
    <runai-grafana-iframe class="iframe" :dashboard-name="dashboardName" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RunaiGrafanaIframe } from "@/components/common/runai-grafana-iframe";
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  name: "cpu-analytics-index",
  components: {
    RunaiGrafanaIframe,
  },
  data() {
    return {
      dashboardName: "cpuAnalytics",
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
});
</script>

<style lang="scss">
.cpu-analytics-index {
  height: 100%;
  display: flex;
  overflow: hidden;

  .iframe {
    flex: 1;
  }
}
</style>
