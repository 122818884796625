import type { IFilterBy } from "@/models/filter.model";

export const urlService = {
  getFiltersObj,
  toSearchParams,
  getBackendBaseUrl,
};

function getBackendBaseUrl(): string {
  return `${window.location.origin}/`;
}

function getFiltersObj(location: Location): IFilterBy {
  const searchParamsStr: string = location.href.split("?")[1];
  const searchParams = new URLSearchParams(searchParamsStr);

  const query: string | null = searchParams.get("query");
  const page: number | null = Number(searchParams.get("page"));
  const rowsPerPage: number | null = Number(searchParams.get("rowsPerPage"));
  const sortBy: string | null = searchParams.get("sortBy");
  const descending: string | null = searchParams.get("descending");
  const columnFilters: string | null = searchParams.get("columnFilters");
  const searchTerm: string | null = searchParams.get("searchTerm");
  const clusterId: string | null = searchParams.get("clusterId");
  const retFilters: IFilterBy = {};
  query && (retFilters.query = query);
  page && (retFilters.page = page);
  rowsPerPage && (retFilters.rowsPerPage = rowsPerPage);
  sortBy && (retFilters.sortBy = sortBy);
  columnFilters && (retFilters.columnFilters = JSON.parse(columnFilters));
  searchTerm && (retFilters.searchTerm = searchTerm);
  clusterId && (retFilters.clusterId = clusterId);

  if (descending !== null) {
    retFilters.descending = JSON.parse(descending);
  }

  return retFilters;
}

function toSearchParams(filterBy: IFilterBy): string {
  const searchParams = new URLSearchParams("");
  for (const key in filterBy) {
    const value = filterBy[key as keyof IFilterBy];
    if (value === "" || value === undefined || key === "displayedColumns") continue; // Skip iteration
    if (key.toString() === "columnFilters") {
      if (filterBy.columnFilters && filterBy.columnFilters.length > 0) {
        searchParams.set("columnFilters", `${JSON.stringify(filterBy.columnFilters)}`);
      }
    } else {
      searchParams.set(key, `${value}`);
    }
  }

  return decodeURIComponent(searchParams.toString());
}
