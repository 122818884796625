import type { ISelectOption } from "@/models/global.model";

export enum EQuotaEntity {
  project = "project",
  department = "department",
}

export enum EResourceType {
  GPU = "gpu",
  CPU = "cpu",
  MEMORY = "memory",
}

export enum EResourceState {
  Unlimited = "Unlimited",
}

export const DEFAULT_NODE_POOL_NAME = "default";

export enum EResourceLabel {
  GPU = "GPU devices",
  CPU = "CPUs (Cores)",
  MEMORY = "CPU memory",
}
export const CPU_VALUE_FACTOR = 1000;
export interface IResource {
  deserved: number | null;
  maxAllowed: number | null;
  overQuotaWeight: number | null;
}

export interface IAssignedResources {
  nodePool?: {
    id: number;
    name: string;
  };
  gpu: IResource;
  // CPU counted in millicores
  cpu: IResource;
  // Memory counted in MB
  memory: IResource;
}

export interface IResourcePermissions {
  users: string[];
}

export const enum EMemoryUnit {
  MB = "MB",
  GB = "GB",
}

export interface INodePoolResourcesSum {
  gpu: number;
  cpu: number | EResourceState;
  memory: number | EResourceState;
}
export type NodePoolResourcesSumRecord = Record<string, INodePoolResourcesSum>;

export interface INodePoolAllocatedNonPreemptibleSum {
  gpu: number;
  cpu: number;
  memory: number;
}
export type NodePoolAllocatedNonPreemptibleSumRecord = Record<string, INodePoolAllocatedNonPreemptibleSum>;

export interface INodePoolIsOverQuota {
  gpu: boolean;
  cpu: boolean;
  memory: boolean;
}
export type INodePoolIsOverQuotaRecord = Record<string, INodePoolIsOverQuota>;

export const RESOURCE_MAX_ALLOWED_INFINITE_VALUE = -1;

export const memoryUnitOptions: Array<string> = ["MB", "GB"];

export const OVER_QUOTA_ENABLED_VALUE = 2;
export const OVER_QUOTA_DISABLED_VALUE = 0;

export const overQuotaOptions: Array<ISelectOption> = [
  {
    label: "Enabled",
    value: OVER_QUOTA_ENABLED_VALUE,
  },
  {
    label: "Disabled",
    value: OVER_QUOTA_DISABLED_VALUE,
  },
];

export const defaultQuotaOption: ISelectOption = {
  label: "Enabled",
  value: 2,
};

export enum EOverQuotaPriority {
  None = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}
export const defaultQuotaPriorityOption: ISelectOption = {
  label: "Medium",
  value: EOverQuotaPriority.Medium,
};
export const overQuotaPriorityOptions: Array<ISelectOption> = [
  {
    label: "None",
    value: EOverQuotaPriority.None,
  },
  {
    label: "Low",
    value: EOverQuotaPriority.Low,
  },
  {
    label: "Medium",
    value: EOverQuotaPriority.Medium,
  },
  {
    label: "High",
    value: EOverQuotaPriority.High,
  },
];
export const EMPTY_PRIORITY_VALUE = "Empty";
export const EMPTY_CPU_RESOURCE_VALUE = {
  deserved: null,
  maxAllowed: null,
  overQuotaWeight: null,
};
export const EMPTY_GPU_RESOURCE_VALUE = {
  deserved: 0,
  maxAllowed: null,
  overQuotaWeight: null,
};
export const DEFAULT_RESOURCES_VALUE = {
  nodePool: {
    id: 1,
    name: "",
  },
  gpu: {
    deserved: 0,
    maxAllowed: 0,
    overQuotaWeight: 0,
  },
  cpu: {
    deserved: 0,
    maxAllowed: 0,
    overQuotaWeight: 0,
  },
  memory: {
    deserved: 0,
    maxAllowed: 0,
    overQuotaWeight: 0,
  },
};
