<template>
  <q-btn @click="onLeave" :icon="supportBack ? 'far fa-arrow-left' : 'far fa-xmark'" size="12px" flat round>
    <q-tooltip class="tooltip-dark" anchor="center right" self="center left" :offset="[10, 10]">
      Leave this page
    </q-tooltip>
  </q-btn>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useAppStore } from "@/stores/app.store";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { requestToLeave } from "@/services/infra/router.service/router.service";

export default defineComponent({
  name: "back-or-close-btn",
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  computed: {
    supportBack(): boolean {
      return !!this.$route.meta.supportBack;
    },
    askToLeave(): boolean {
      return !!this.$route.meta.requestToLeave;
    },
  },
  methods: {
    async onLeave() {
      if (!this.askToLeave || this.appStore.isFallback) {
        this.redirect();
        return;
      }
      const confirm = await requestToLeave();
      if (confirm) this.redirect();
    },

    redirect(): void {
      if (this.$route?.meta?.prevRoute === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX) {
        this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
      } else if (this.$route?.meta?.backPageName) {
        if (this.$route?.query?.previousRoute) this.$router.push({ name: this.$route.query.previousRoute as string });
        else this.$router.push({ name: this.$route?.meta?.backPageName as string });
      } else {
        this.$router.push(this.appStore.lastBackPath || "/");
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
