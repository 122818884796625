import type { Pod } from "@/models/pods.model";
import { K8S_API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const podService = {
  getPods,
};

const clusterEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}`;

async function getPods(jobId: string, clusterUuid: string, deploymentId?: string): Promise<Pod[]> {
  const podPath = deploymentId
    ? `${clusterEndpoint(clusterUuid)}/deployments/${deploymentId}/pods`
    : `${clusterEndpoint(clusterUuid)}/jobs/${jobId}/pods`;
  return controlPlaneService.get(podPath);
}
