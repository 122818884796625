<template>
  <runai-expansion-item class="settings-container" label="Analytics" default-opened>
    <div class="row items-center q-mb-md">
      <toggle-setting :setting="analyticsSectionSettings.cpu" />
    </div>
    <q-separator class="q-mb-md" />
    <div>
      <dashborad-resource-cost :consumption-setting="analyticsSectionSettings.consumption" />
    </div>
    <q-separator class="q-mb-md" />
    <div class="row items-center q-mb-md">
      <toggle-setting :setting="analyticsSectionSettings.advancedGpuMetrics" />
    </div>
    <q-separator class="q-mb-md" />
    <div class="row items-center q-mb-md">
      <toggle-setting
        :setting="analyticsSectionSettings.optimizationEnabled"
        warning-message="Note: on activation, historical dashboard data will be lost. this action is irreversible"
        emit-only
        @update-setting="showEnablePerformanceModal"
        :disabled="isPerformanceEnabled"
        :tooltip-message="disabledTooltipMessage"
      />
    </div>
    <enable-performance-modal
      v-if="showPerformanceModal"
      @enable="enablePerformance"
      @cancel="cancelEnablePerformance"
    />
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// Models
// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
// Stores
import { useSettingStore } from "@/stores/setting.store";
import ToggleSetting from "@/components/settings/toggle-setting/toggle-setting.vue";
import DashboradResourceCost from "@/components/settings/custom-settings/dashboard-resources-cost/dashboard-resource-cost.vue";
import EnablePerformanceModal from "@/components/settings/sections/analytics/enable-performance-modal/enable-performance-modal.vue";
import { AnalyticsSectionSettings, SettingKeys } from "@/models/setting.model";
import { alertUtil } from "@/utils/alert.util";
// Utils

export default defineComponent({
  name: "analytics-section",
  components: {
    EnablePerformanceModal,
    DashboradResourceCost,
    ToggleSetting,
    RunaiExpansionItem,
  },
  props: {
    analyticsSectionSettings: {
      type: Object as PropType<AnalyticsSectionSettings>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
      showPerformanceModal: false as boolean,
    };
  },
  computed: {
    isPerformanceEnabled(): boolean {
      return Boolean(this.analyticsSectionSettings.optimizationEnabled.value);
    },
    disabledTooltipMessage(): string | undefined {
      return this.isPerformanceEnabled
        ? "This settings can’t be disabled because legacy dashboard metrics cannot be restored."
        : undefined;
    },
  },
  methods: {
    cancelEnablePerformance(): void {
      this.showPerformanceModal = false;
    },
    showEnablePerformanceModal(): void {
      this.showPerformanceModal = true;
    },
    async enablePerformance(): Promise<void> {
      try {
        this.showPerformanceModal = false;
        await this.settingStore.updateSetting(SettingKeys.DashboardOptimization as SettingKeys, true);
        this.$q.notify(alertUtil.getSuccess("Setting updated successfully"));
      } catch (err) {
        this.$q.notify(alertUtil.getError("Failed to update setting"));
      }
    },
  },
});
</script>
