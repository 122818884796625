import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";
import NewSettingsIndex from "@/views/new-settings/new-settings-index.vue";
import { NEW_SETTINGS_ROUTE_NAMES } from "@/router/new-setttings.routes/new-settings.routes.names";

export const newSettingsRoutes: RunaiRouteRecord = {
  path: "/new-general-settings",
  component: () => import("@/views/new-settings/new-settings-app.vue"),
  children: [
    {
      path: "",
      name: NEW_SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
      component: NewSettingsIndex,
      meta: {
        pageTitle: "General settings",
        resourceType: ResourceType.Settings,
        minPermittedActions: [Action.Update],
        fullWidth: true,
        supportBack: true,
        requiresAuth: true,
      },
    },
  ],
};
