/* eslint-disable */

// component names: runai-custom-table-columns

export enum ECustomCell {
  NAME_COL = "name-col",
  LIST_COL = "list-col",
  LINK_COL = "link-col",
  NODE_POOL_STATUS_COL = "node-pool-status-col",
  ROLE_ASSIGMENT_COL = "role-assigment-col",
  APP_STATUS_COL = "applications-status-col",
  LICENSE_STATUS_COL = "license-status-col",
  AUDIT_STATUS_COL = "audit-status-col",
  AUDIT_CLUSTER_NAME_COL = "audit-cluster-name-col",
  ORG_TREE_COL = "org-tree-col",
  SCOPE_COL = "scope-col",
  TOOLTIP_COL = "tooltip-col",
  STRINGS_LIST_COL = "strings-list-col",
  STATUS_COL = "status-col",
  NODE_STATUS_COL = "node-status-col",
  HTML_COL = "html-col",
  SOURCE_NAME_COL = "source-name-col",
}
// based on QTableProps["columns"] props
export interface ITableColumn {
  name: string;
  label: string;
  field: string | ((row: any) => any);
  required?: boolean;
  align?: "left" | "right" | "center";
  sortable?: boolean;
  sort?: (a: any, b: any, rowA: any, rowB: any) => number;
  sortOrder?: "ad" | "da";
  format?: (val: any, row: any) => any;
  style?: string | ((row: any) => string);
  classes?: string | ((row: any) => string);
  headerStyle?: string;
  headerClasses?: string;
  display?: boolean;
  customCell?: ECustomCell;
  customCellEvent?: { emitName: string }; // the event name that will be emitted
  mandatory?: boolean;
  hideFilter?: boolean;
  filterKey?: string;
  exportFormat?: (row: any) => any;
}

export enum ETableFilters {
  NODE = "node-table-filters",
  NODE_POOL = "node-pool-table-filters",
  DEPARTMENT = "department-table-filters",
  DEPLOYMENT = "deployment-table-filters",
  WORKSPACE = "workspace-table-filters",
  TRAINING = "training-table-filters",
  COMPUTE_RESOURCE = "compute-resource-table-filters",
  DATA_SOURCE = "data-source-table-filters",
  TEMPLATE = "template-table-filters",
  ENVIRONMENT = "environment-table-filters",
  AUDIT = "audit-table-filters",
  CREDENTIAL = "credential-table-filters",
  PROJECT = "project-table-filters",
  ROLE = "role-table-filters",
  APPLICATION = "application-table-filters",
  CLUSTER = "cluster-table-filters",
  USERS = "users-table-filters",
  ASSIGNMENT = "assignment-table-filters",
  WORKLOAD = "workload-table-filters",
  POLICY = "policy-table-filters",
}

export interface IStatusColOptions {
  status: string;
  tooltipText?: string;
  displayAnimation: boolean;
  color?: string;
  showIconToolTip?: boolean;
  filterKey?: string;
  statusUpdatedTimeInMs?: number;
  colStyle?: Object; // example usage - {width: '5px'}
}

export type TColumnOptions = IStatusColOptions; // Add future types so the filter would work

export enum ETableExportCsvFilesNames {
  Application = "applications",
  Audit = "events-history",
  Cluster = "clusters",
  Credential = "credentials",
  DataSource = "data-sources",
  Deployment = "deployments",
  Department = "departments",
  Environment = "environments",
  Node = "nodes",
  Project = "projects",
  AccessRule = "access-rules",
  Role = "roles",
  Training = "trainings",
  User = "users",
  Template = "templates",
  Workload = "workloads",
  Workspace = "workspaces",
  NodePool = "node-pools",
}
