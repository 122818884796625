// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { filterService } from "@/services/filter.service/filter.service";
import { storageUtil } from "@/utils/storage.util";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { dateUtil } from "@/utils/date.util";
import { pick } from "@/utils/common.util";

// models
import type {
  WorkloadCreationRequest,
  Workspace,
  AssetCountResponse,
  Scope,
} from "@/swagger-models/assets-service-client";
import type { WorkspacePolicy } from "@/swagger-models/policy-service-client";
import type { IWorkloadCreate, IWorkloadResponse } from "@/models/workload.model";
import type { IWorkspaceList, IWorkspaceFilterBy, IExtendJobValues } from "@/models/workspace.model";
import { allWorkspaceColumns } from "@/models/workspace.model";
import type { IPrometheusResponse, IPrometheusMetric } from "@/models/prometheus.model";
import type { ILocalStatus } from "@/models/global.model";

// constants
import { K8S_API } from "@/common/api.constant";
import { API } from "@/common/api.constant";
import { EWorkloadStatus } from "@/common/status.constant";

const WORKSPACE_STATUS = "workspaceStatus";

interface IClusterResponse {
  ok: boolean;
  name: string;
  error?: {
    details: string;
    message: string;
    status: number;
  };
}

interface IRemoveWorkspaceResponse {
  data: Array<IClusterResponse>;
}

export const workspaceService = {
  createFromAssets,
  list,
  remove,
  getById,
  getNameCount,
  getByIdFromCluster,
  activate,
  stop,
  gpuUtilizationMetric,
  gpuMemoryUsageMetric,
  cpuMemoryUsageMetric,
  cpuUsageMetric,
  getPolicy,
};

const endpoint = `${API.v1}/workspace`;
function workspacesEndpoint(clusterUuid: string): string {
  return `${K8S_API.v1}/clusters/${clusterUuid}/workspaces`;
}

// api calls
async function list(clusterUuid: string, filterBy: IWorkspaceFilterBy = {}): Promise<Array<IWorkspaceList>> {
  const filters: IWorkspaceFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage", "sortDirection", "name", "id");
  let workspaces: Array<IWorkspaceList> = await controlPlaneService
    .get(`${workspacesEndpoint(clusterUuid)}`, filters)
    .then((res) => res.entries);
  const prometheusQueries: Record<string, string> = createPrometheusQueries(clusterUuid);
  const promData: Array<IPrometheusResponse> = await prometheusService.multipleQueries(prometheusQueries);
  workspaces = _preparePromData(workspaces, promData);
  workspaces = _updateWorkspacesByLocalStatuses(workspaces);

  if (!filterBy || !filterBy.displayedColumns) return workspaces;

  if (filterBy.searchTerm) {
    workspaces = filterService.filterBySearchTerm<IWorkspaceList>(
      workspaces,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allWorkspaceColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    workspaces = filterService.filterByColumns(workspaces, filterBy.columnFilters, allWorkspaceColumns);
  }
  return workspaces;
}

async function remove(workspace: IWorkspaceList): Promise<IWorkspaceList> {
  if (!workspace.job?.project) throw new Error("Can't delete workspace. Job is missing.");
  const res: IRemoveWorkspaceResponse = await researcherService.deleteJob(workspace.meta.name, workspace.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(workspace, EWorkloadStatus.Deleting);

  workspace.job.status = EWorkloadStatus.Deleting;
  workspace.job.lastStatusUpdateTime = 0;
  workspace.job.msSinceLastStatusUpdate = 0;
  return workspace;
}

async function getNameCount(workspaceName: string, clusterId: string, projectId?: number | null): Promise<number> {
  const res: AssetCountResponse = await controlPlaneService.get(`${API.v1}/workload/count`, {
    name: workspaceName,
    projectId,
    clusterId,
  });
  return res.result;
}

async function getById(workspaceId: string): Promise<Workspace> {
  return controlPlaneService.get(`${endpoint}/${workspaceId}`);
}

async function getByIdFromCluster(clusterUuid: string, workspaceId: string): Promise<IWorkspaceList> {
  let workspace: IWorkspaceList = await controlPlaneService.get(`${workspacesEndpoint(clusterUuid)}/${workspaceId}`);
  workspace = _updateWorkspaceStatus(workspace);
  return workspace;
}

async function createFromAssets(workspace: WorkloadCreationRequest): Promise<Workspace> {
  const createdWorkspace: Workspace = await controlPlaneService.post(endpoint, workspace);
  try {
    const workloadCreate: IWorkloadCreate = createdWorkspace.workload as IWorkloadCreate;
    await createWorkload(workloadCreate);
  } catch (e) {
    // submit failure status
    await workloadService.handleFailedWorkloadClusterCreation(createdWorkspace.meta.id, e);
    await _submitWorkspaceCreationStatus(workspace.clusterId, createdWorkspace.meta.id, false);
    throw e;
  }
  // submit success status
  await _submitWorkspaceCreationStatus(workspace.clusterId, createdWorkspace.meta.id, true);
  return createdWorkspace;
}

async function activate(workspace: IWorkspaceList) {
  if (!workspace.job?.project) throw new Error("Can't active workspace. Job is missing.");
  const res = await researcherService.activateWorkload(workspace.meta.name, workspace.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(workspace, EWorkloadStatus.Activating);
}

async function stop(workspace: IWorkspaceList): Promise<void> {
  if (!workspace.job?.project) throw new Error("Can't stop workspace. Job is missing.");
  const res = await researcherService.stopWorkload(workspace.meta.name, workspace.job.project);
  const errorMessage: string | undefined = _handleActionResult(res.data);
  if (errorMessage) throw new Error(errorMessage);
  _updateLocalStatues(workspace, EWorkloadStatus.Stopping);
}

async function getPolicy(projectId: number, scope: Scope): Promise<WorkspacePolicy> {
  return await controlPlaneService.get(`${API.v1}/policy/workspace`, { scope, projectId });
}

async function _submitWorkspaceCreationStatus(clusterUid: string, workspaceId: string, status: boolean): Promise<void> {
  await controlPlaneService.put(`${workspacesEndpoint(clusterUid)}/${workspaceId}/submission`, { success: status });
}

async function createWorkload(workloadCreate: IWorkloadCreate): Promise<IWorkloadResponse> {
  return workloadService.createWorkload("InteractiveWorkload", workloadCreate.metadata, {
    ...workloadCreate.spec,
    name: { value: workloadCreate.metadata.name },
  });
}

function createPrometheusQueries(clusterUuid: string): Record<string, string> {
  const clusterFilter: string = clusterUuid ? `{clusterId="${clusterUuid}"}` : "";

  return {
    gpusUtilization: `(avg(runai_gpu_utilization_per_pod_per_gpu${clusterFilter}) by(pod_group_uuid)) or 
    (sum by (pod_group_uuid) (runai_pod_group_gpu_utilization${clusterFilter})
      / on (pod_group_uuid)
      (count(runai_pod_group_gpu_utilization${clusterFilter}) by (pod_group_uuid)
      or
      sum(runai_utilization_shared_gpu_jobs${clusterFilter}) by (pod_group_uuid)))`,
    usedCPUs: `runai_job_cpu_usage${clusterFilter}`,
    usedMemory: `runai_job_memory_used_bytes${clusterFilter}`,
    usedGpuMemory: `(sum(runai_gpu_memory_used_mebibytes_per_pod_per_gpu${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid)) or (sum(runai_pod_group_used_gpu_memory${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid))`, // this query returns value in Bytes. (without the multiplication its MiB)
    swapCPUMemory: `runai_pod_group_swap_memory_used_bytes${clusterFilter}`,
  };
}

function _handleActionResult(statuses: Array<IClusterResponse>): string | undefined {
  return statuses[0]?.error ? statuses[0].error.details : undefined;
}

function _updateLocalStatues(workspace: IWorkspaceList, loadingStatus: EWorkloadStatus): void {
  const currentStatus: Record<string, ILocalStatus> = _getWorkspaceStatus();
  _saveWorkspaceStatus({
    ...currentStatus,
    [workspace.meta.id]: {
      oldStatus: workspace.job?.status || "",
      loadingStatus,
      creationTimestamp: Date.now(),
    },
  });
}

function _updateWorkspacesByLocalStatuses(workspaces: Array<IWorkspaceList>): Array<IWorkspaceList> {
  const currentStatus: Record<string, ILocalStatus> = _getWorkspaceStatus();
  if (!currentStatus) return workspaces;

  return workspaces.map((workspace: IWorkspaceList) => _updateWorkspaceStatus(workspace));
}

function _updateWorkspaceStatus(workspace: IWorkspaceList): IWorkspaceList {
  const currentStatus: Record<string, ILocalStatus> = _getWorkspaceStatus();
  if (!workspace.job || !currentStatus) return workspace;

  if (workspace.job.status === EWorkloadStatus.Submitted) {
    workspace.job.status = EWorkloadStatus.Creating;
  }

  const workspaceId: string = workspace.meta.id;
  const workspaceToCheck: ILocalStatus | undefined = currentStatus[workspaceId];
  if (!workspaceToCheck) return workspace;

  if (_shouldRemoveLocalStatus(workspaceToCheck, workspace)) {
    delete currentStatus[workspaceId];
    _saveWorkspaceStatus(currentStatus);
  } else if (workspaceToCheck.loadingStatus) {
    workspace.job.status = workspaceToCheck.loadingStatus;
  }
  return workspace;
}

async function gpuUtilizationMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `avg((runai_gpu_utilization_per_workload{job_uuid="${podUUID}"})) or (avg(runai_utilization_full_gpu_jobs{pod_group_uuid="${podUUID}", node_name!=""}) or avg(runai_utilization_shared_gpu_jobs{pod_group_uuid="${podUUID}"}))`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function cpuUsageMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `runai_job_cpu_usage{pod_group_uuid="${podUUID}"}`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function gpuMemoryUsageMetric(podUUID: string, start: number, end: number, step = 100) {
  const query = `sum(runai_gpu_memory_used_mebibytes_per_workload{job_uuid="${podUUID}"} * 1024 * 1024) or sum(runai_pod_group_used_gpu_memory{pod_group_uuid="${podUUID}"} * 1024 * 1024)`;
  return prometheusService.rangeQuery(query, start, end, step);
}

async function cpuMemoryUsageMetric(podUUID: string, start: number, end: number, step = 5) {
  const query = `runai_job_memory_used_bytes{pod_group_uuid="${podUUID}"}`;
  return prometheusService.rangeQuery(query, start, end, step);
}

function _cleanupStatus() {
  const currentStatus: Record<string, ILocalStatus> = _getWorkspaceStatus();
  if (!currentStatus || Object.keys(currentStatus).length === 0) return;
  Object.keys(currentStatus).forEach((key: string) => {
    if (_shouldCleanLocalStatus(currentStatus[key])) {
      delete currentStatus[key];
    }
  });
  _saveWorkspaceStatus(currentStatus);
}

// this should be called only once in a while,
// because it's a heavy operation and not needed to be called on every status update
_cleanupStatus();

function _shouldRemoveLocalStatus(workspaceToCheck: ILocalStatus, workspace: IWorkspaceList): boolean {
  return (
    workspaceToCheck.oldStatus !== workspace.job?.status ||
    dateUtil.moreThanHourFromNow(workspaceToCheck.creationTimestamp)
  );
}

function _shouldCleanLocalStatus(workspaceToCheck: ILocalStatus): boolean {
  return dateUtil.moreThanHourFromNow(workspaceToCheck.creationTimestamp);
}

function _preparePromData(
  apiData: Array<IWorkspaceList>,
  queryResponses: Array<IPrometheusResponse>,
): Array<IWorkspaceList> {
  const promDataByPodId: Record<string, IExtendJobValues> = {};
  queryResponses.forEach((currResponse: IPrometheusResponse) => {
    currResponse.data.forEach(({ metric, value }: IPrometheusMetric) => {
      promDataByPodId[metric.pod_group_uuid] ??= {};
      promDataByPodId[metric.pod_group_uuid] = {
        ...promDataByPodId[metric.pod_group_uuid],
        [currResponse.key]: value[1],
      };
    });
  });

  return apiData.map((workspace: IWorkspaceList) => {
    if (!workspace.job?.podGroupId || !promDataByPodId[workspace.job?.podGroupId]) return workspace;
    return { ...workspace, extendJobValues: { ...promDataByPodId[workspace.job.podGroupId] } };
  });
}

function _getWorkspaceStatus(): Record<string, ILocalStatus> {
  return storageUtil.get<Record<string, ILocalStatus>>(WORKSPACE_STATUS);
}

function _saveWorkspaceStatus(workspaceStatus: Record<string, ILocalStatus>): void {
  storageUtil.save<Record<string, ILocalStatus>>(WORKSPACE_STATUS, workspaceStatus);
}
