import ModelSpecIndex from "@/views/model-spec/model-spec-index.vue";
import ModelSpecNew from "@/views/model-spec/model-spec-new.vue";
import { MODEL_SPEC_NAMES } from "./model-spec.routes.names";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export const modelSpecRoutes: RunaiRouteRecord = {
  path: "/models",
  component: () => import("@/views/model-spec/model-spec-app.vue"),
  children: [
    {
      path: "",
      name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
      component: ModelSpecIndex,
      meta: {
        pageTitle: "Models",
        fullWidth: false,
        fullPage: false,
        requiresAuth: true,
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
      },
    },
    {
      path: "new-deployment",
      name: MODEL_SPEC_NAMES.MODEL_SPEC_NEW,
      component: ModelSpecNew,
      meta: {
        pageTitle: "New deployment",
        fullWidth: true,
        fullPage: false,
        requiresAuth: true,
        requestToLeave: true,
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
        backPageName: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
      },
    },
  ],
};
