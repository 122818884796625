// Stores
import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";
// Services

// Models
import type { IFilterBy } from "@/models/filter.model";
//api
import type { HistoryRecord, Phase, Workload } from "@/swagger-models/workloads-service-client";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import type { IJobFilter } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { eventHistoryColumns } from "@/models/workload.model";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { ITrainingList } from "@/models/training.model";
import { workloadLocalPhaseUpdater } from "@/services/cluster/workload.service/local-status-updater";
const getDefaultFilters = (): IJobFilter => {
  return { ...filterService.getDefaultFilters("createdAt", eventHistoryColumns), descending: false };
};

export const useWorkloadStore = defineStore("WorkloadStore", {
  state: () => ({
    workloads: [] as Array<Workload>,
    workloadHistoryRecords: [] as Array<HistoryRecord>,
    workloadHistoryFilterBy: getDefaultFilters() as IJobFilter,
    loading: false as boolean,
    namespaces: {} as Record<string, string | undefined>,
    clusterStore: useClusterStore(),
    workloadAdded: null as null | Workload,
  }),
  getters: {
    workloadList(): Array<Workload> {
      if (!this.workloadAdded) return this.workloads;
      return this.workloads.filter((workload: Workload) => workload.name !== this.workloadAdded?.name);
    },
    allWorkloads(): Array<Workload> {
      return this.workloads;
    },
    lastCreatedWorkload(): Workload | null {
      return this.workloadAdded;
    },
  },
  actions: {
    async loadWorkloads(filterBy?: IFilterBy): Promise<void> {
      const workloads: Workload[] = await workloadService.list(this.clusterStore.currentClusterId, filterBy);
      this.workloads = workloads;
    },
    async deleteWorkload(workloadToDelete: Workload): Promise<void> {
      try {
        await workloadService.deleteWorkload(workloadToDelete, workloadToDelete.projectName);
        this.workloads = this.workloads.filter((workload: Workload) => workload.name !== workloadToDelete.name);
      } catch (e: unknown) {
        console.error(e);
        throw e;
      }
    },
    removeWorkloadAdded(): void {
      this.workloadAdded = null;
    },
    setWorkloadAdded(workloadAdded: IWorkspaceList | ITrainingList | Workload): void {
      if (workloadUtil.isWorkload(workloadAdded)) {
        this.workloadAdded = workloadAdded;
      } else {
        this.workloadAdded = {
          ...workloadUtil.convertDisplayedJobToWorkload(workloadAdded.job),
          //@ts-ignore - generated api is incorrect
          id: workloadAdded.job.workloadId as string,
        };
      }
    },
    isTopRow(workload: Workload): boolean {
      if (this.workloadAdded == null) return false;
      return this.workloadAdded.name === workload.name;
    },
    updatePhase(workload: Workload, phase: Phase): void {
      workload.phaseUpdatedAt = "";
      workload.phase = phase;
      workload.phaseMessage = "";
    },
    setWorkloadPhase(workloadToUpdate: Workload, phase: Phase): void {
      workloadLocalPhaseUpdater.updateLocalPhases(workloadToUpdate, phase);
      if (this.isTopRow(workloadToUpdate)) {
        this.updatePhase(this.workloadAdded as Workload, phase);
      } else {
        const workload: Workload | undefined = this.workloads.find(
          (workload: Workload) => workload.name === workloadToUpdate.name,
        );
        if (workload) {
          this.updatePhase(workload, phase);
        }
      }
    },
    async loadWorkloadHistory(workloadId: string, clusterUuid: string): Promise<void> {
      this.workloadHistoryRecords = (await workloadService.getWorkloadHistory(workloadId, clusterUuid)).records;
    },
    getWorkloadHistoryList(withFilter = false): HistoryRecord[] {
      if (!withFilter) return this.workloadHistoryRecords;
      let historyRecords: HistoryRecord[] = filterService.filterBySearchTermAndByColumns(
        this.workloadHistoryRecords,
        this.workloadHistoryFilterBy,
        eventHistoryColumns,
      );

      if (this.workloadHistoryFilterBy.type) {
        historyRecords = historyRecords.filter((historyRecord: HistoryRecord) => {
          return historyRecord.meta.type === this.workloadHistoryFilterBy.type;
        });
      }
      return historyRecords;
    },
    setWorkloadHistoryFilterBy(filterBy: IJobFilter): void {
      this.workloadHistoryFilterBy = filterBy;
    },
  },
});
