/* eslint-disable */
import type { IHTTPOptions } from "@/models/http-response.model";
import { httpService } from "@/services/infra/https.service/http.service";
import { storageUtil } from "@/utils/storage.util";
import { USE_EXTERNAL_TOKEN } from "@/common/storage.constant";

let _baseUrl = "";

function getOptions(): IHTTPOptions {
  return {
    responseType: "json",
    useExternalToken: !!storageUtil.get(USE_EXTERNAL_TOKEN),
  };
}

export const clusterApiService = {
  get(endpoint: string, query?: any, options?: IHTTPOptions): Promise<any> {
    return httpService.request(_baseUrl, endpoint, "GET", { ...getOptions(), ...options }, {}, query);
  },
  post(endpoint: string, data?: any, options?: IHTTPOptions): Promise<any> {
    return httpService.request(_baseUrl, endpoint, "POST", { ...getOptions(), ...options }, data);
  },
  put(endpoint: string, data?: any, options?: IHTTPOptions): Promise<any> {
    return httpService.request(_baseUrl, endpoint, "PUT", { ...getOptions(), ...options }, data);
  },
  delete(endpoint: string, data?: any, options?: IHTTPOptions): Promise<any> {
    return httpService.request(_baseUrl, endpoint, "DELETE", { ...getOptions(), ...options }, data);
  },
  setBaseURL(baseUrl: string): void {
    _baseUrl = baseUrl && (baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`);
  },
};
