import type { IAccessRuleDisplayConfig, IAccessRuleManagementModalOptions } from "@/models/access-rule.model";
import { EAccessRuleModalPage } from "@/models/access-rule.model";
import type { IAccessRuleRecords } from "@/models/access-rule.model";
import type { IDepartment } from "@/models/department.model";
import type { IProject } from "@/models/project.model";
import { deepCopy } from "@/utils/common.util";
import type { AccessRule } from "@/swagger-models/authorization-client";
import type { IApplication } from "@/models/applications.model";
import type { TRolesTypes } from "@/models/roles.model";
import type { IUser } from "@/models/user.model";

export const accessRuleUtil = {
  getAccessRuleModalDisplayConfig,
  getAccessRuleModalHeader,
  getAccessRuleModalSubHeader,
  isScopePage,
  getUserSubjectIds,
};

function getAccessRuleModalDisplayConfig(page: EAccessRuleModalPage): IAccessRuleDisplayConfig {
  switch (page) {
    case EAccessRuleModalPage.Project:
      return { subject: true, users: true, roles: true, scope: false };
    case EAccessRuleModalPage.Department:
      return { subject: true, users: true, roles: true, scope: false };
    case EAccessRuleModalPage.Application:
      return { subject: false, users: false, roles: true, scope: true };
    case EAccessRuleModalPage.User:
      return { subject: false, users: false, roles: true, scope: true };
    default:
      return { subject: false, users: false, roles: false, scope: false };
  }
}

function getAccessRuleModalHeader(options: IAccessRuleManagementModalOptions): string {
  switch (options.page) {
    case EAccessRuleModalPage.Project:
      return `Access Rules for ${options.scopeName}`;
    case EAccessRuleModalPage.Department:
      return `Access Rules for ${options.scopeName}`;
    case EAccessRuleModalPage.Application:
      return `Access Rules for ${options.subjectId}`;
    case EAccessRuleModalPage.User:
      return `Access Rules for ${options.subjectId}`;
    default:
      return "";
  }
}
function getAccessRuleModalSubHeader(page: EAccessRuleModalPage): string {
  switch (page) {
    case EAccessRuleModalPage.Project:
      return "Add rules to authorize access to this project";
    case EAccessRuleModalPage.Department:
      return "Add rules to authorize access to this department";
    case EAccessRuleModalPage.Application:
      return "Add rules and select roles and scopes to authorize this application’s access ";
    case EAccessRuleModalPage.User:
      return "Add rules and select roles and scopes to authorize this user’s access ";
    default:
      return "";
  }
}

function isScopePage(page: EAccessRuleModalPage): boolean {
  return [EAccessRuleModalPage.Project, EAccessRuleModalPage.Department].some((p: EAccessRuleModalPage) => p === page);
}

export function enrichApplicationsWithRoles(
  applications: IApplication[],
  applicationAccessRules: IAccessRuleRecords,
): IApplication[] {
  const appNameToRolesMap = _getSubjectIdToRolesMap(applicationAccessRules);
  if (appNameToRolesMap.size > 0) {
    _addRolesToApplications(applications, appNameToRolesMap);
  }
  return applications;
}

export function enrichScopeEntityWithAccessRules<T extends IDepartment | IProject>(
  items: T[],
  scopeAccessRules: IAccessRuleRecords,
): T[] {
  const itemsWithAccessRules = deepCopy(items);
  const accessRulesByScope = scopeAccessRules.accessRules.reduce(
    (assignmentsMap: Record<string, string[]>, item: AccessRule) => {
      assignmentsMap[item.scopeId] ??= [];
      assignmentsMap[item.scopeId].push(item.subjectType);
      return assignmentsMap;
    },
    {} as Record<string, string[]>,
  );
  for (const item of itemsWithAccessRules) {
    item.roles = accessRulesByScope[String(item.id)] || [];
  }
  return itemsWithAccessRules;
}

function _getSubjectIdToRolesMap(subjectAccessRules: IAccessRuleRecords): Map<string, Array<string>> {
  const subjectIdToRolesMap = new Map<string, Array<string>>();
  subjectAccessRules.accessRules.forEach((accessRule) => {
    const subjectId = accessRule.subjectId;
    const roleName = accessRule.roleName;
    if (subjectIdToRolesMap.has(subjectId)) {
      const userRoles = subjectIdToRolesMap.get(subjectId);
      if (userRoles != undefined && !userRoles?.includes(roleName)) {
        userRoles?.push(roleName);
        subjectIdToRolesMap.set(subjectId, userRoles);
      }
    } else {
      subjectIdToRolesMap.set(subjectId, [roleName]);
    }
  });
  return subjectIdToRolesMap;
}

function _addRolesToApplications(apps: IApplication[], appNameToRolesMap: Map<string, Array<string>>): void {
  apps.forEach((app) => {
    if (app.name && appNameToRolesMap.has(app.name)) {
      app.roles = [];
      app.roles.push(...(appNameToRolesMap.get(app.name) as Array<TRolesTypes>));
    }
  });
}

function getUserSubjectIds(user: IUser): Array<string> {
  return user.groups ? [user.username].concat(user.groups || []) : [user.username];
}
