<template>
  <runai-base-dialog :model-value="open" @close="$emit('close')" size="width-md" :no-padding="true">
    <template #header>Researcher Command Line Interface</template>

    <template #body>
      <section class="researcher-download-cli q-pa-md">
        <section class="os-selection-box">
          <p>{{ downloadCLIText }}</p>
          <div class="q-gutter-sm q-mb-sm">
            <q-radio v-model="osModel" val="linux" label="Linux" :disable="isTrial" />
            <q-radio v-model="osModel" val="darwin" label="Mac" :disable="isTrial" />
            <q-radio v-if="showWindows" v-model="osModel" val="windows" label="Windows" :disable="isTrial" />
          </div>

          <div class="help-section-italic pt-3" v-if="isTrial">
            For more information, see the
            <a href="https://docs.run.ai/latest/Researcher/cli-reference/Introduction" target="_blank"
              >command line interface</a
            >.
          </div>
        </section>

        <template v-if="osModel">
          <q-separator />

          <section class="q-py-md">
            <section class="download-section">
              <div class="d-flex align-items-center">
                Download the researcher command line interface
                <q-btn :href="downloadLink" tag="a" flat :ripple="false" color="primary" class="no-underline">
                  <q-icon left size="14px" name="fa-regular fa-arrow-down-to-line" />
                  Download
                </q-btn>
              </div>
              <div><strong>OR</strong></div>
              <div class="copy-code">
                <p>Copy the following and run it in your terminal to log in with the CLI:</p>
                <runai-code-block class="text-subtitle1" :code-html="copylUrl" />
              </div>
            </section>
            <q-separator />

            <section class="help-section pt-3 border-top">
              Need help getting started? <br />
              Check out our
              <a href="https://docs.run.ai/latest/admin/researcher-setup/cli-install" target="_blank"
                >installation guide</a
              >
              and
              <a href="https://docs.run.ai/v2.13/Researcher/Walkthroughs/quickstart-overview" target="_blank"
                >CLI guidelines</a
              >.
            </section>
          </section>
        </template>
      </section>
    </template>

    <template #footer>
      <q-btn
        label="Close"
        color="primary"
        flat
        @click="$emit('close')"
        aid="researcher-cluster-cli-modal-close-button"
      />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// stores
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { RunaiCodeBlock } from "@/components/common/runai-code-block";

// utils
import { isNewerVersion } from "@/utils/version.util";
import { TEST_ENV_VERSION, MIN_WINDOWS_CLI_VERSION, FEATURE_BRANCH_VERSION } from "@/common/version.constant";

export default defineComponent({
  components: {
    RunaiBaseDialog,
    RunaiCodeBlock,
  },
  props: {
    open: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      osModel: "" as string,
      authStore: useAuthStore(),
    };
  },
  computed: {
    downloadLink(): string {
      return `${this.clusterStore.currentCluster?.domain || ""}/cli/${this.osModel}`;
    },
    copylUrl(): string {
      return `wget --content-disposition ${this.downloadLink}`;
    },
    showWindows(): boolean {
      return (
        !this.clusterVersion ||
        isNewerVersion(this.clusterVersion, MIN_WINDOWS_CLI_VERSION) ||
        this.clusterVersion.includes(TEST_ENV_VERSION) ||
        this.clusterVersion.toLowerCase().includes(FEATURE_BRANCH_VERSION)
      );
    },
    clusterVersion(): string | undefined {
      return this.clusterStore.currentCluster?.version;
    },
    isTrial(): boolean {
      return this.authStore.isTrial;
    },
    downloadCLIText(): string {
      return this.isTrial
        ? "The command line interface feature is only available for paid accounts."
        : "Download the researcher command line interface for";
    },
  },
});
</script>

<style lang="scss" scoped>
.help-section-italic {
  font-style: italic;
}
</style>
