import { registryService } from "@/services/control-plane/registry.service/registry.service";
import type {
  Registry,
  RegistryCreationRequest,
  RegistryListResponse,
  RepositoryTags,
} from "@/swagger-models/assets-service-client";
import { defineStore } from "pinia";

export const useRegistryStore = defineStore("Registry", {
  state: () => ({
    registry: null as Registry | null,
  }),
  actions: {
    async loadRegistries(): Promise<void> {
      try {
        const registries: RegistryListResponse = await registryService.getRegistries();
        if (registries && registries.entries.length > 0) {
          this.registry = registries.entries[0];
        }
      } catch (err) {
        console.error("Failed to get registries", err);
      }
    },
    async loadTags(registryId: string, repository: string, query: string): Promise<string[]> {
      try {
        const res: RepositoryTags = await registryService.getTags(registryId, repository, query);
        return res.tags;
      } catch (err) {
        console.error("Failed to get tags", err);
        return [];
      }
    },
    async createRegistry(registry: RegistryCreationRequest): Promise<Registry | void> {
      try {
        this.registry = await registryService.createRegistry(registry);
        return this.registry;
      } catch (err) {
        console.error("Failed to create registry", err);
      }
    },
    async deleteRegistry(): Promise<void> {
      try {
        if (this.registry) {
          await registryService.deleteRegistry(this.registry.meta.id);
          this.registry = null;
        }
      } catch (err) {
        console.error("Failed to delete registry", err);
      }
    },
  },
});
