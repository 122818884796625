import { defineStore } from "pinia";
import { settingService } from "@/services/control-plane/setting.service/setting.service";
import { type IResourcesCost, SettingKeys, type TSettingValue, type IAdminMessage } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";
import { useAuthStore } from "./auth.store";

export const useSettingStore = defineStore("Setting", {
  state: () => ({
    settings: new Map(),
  }),
  getters: {
    settingList(): Array<Setting> {
      return Array.from(this.settings.values());
    },
    isCPUResourcesQuotaEnabled(): boolean {
      return this.settings.get(SettingKeys.CPUResourcesQuota)?.value || false;
    },
    isLimitQuotaOverSubscriptionEnabled(): boolean {
      return this.settings.get(SettingKeys.LimitQuotaOverSubscription)?.value || false;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settings.get(SettingKeys.ProjectManualOverQuota)?.value || false;
    },
    isSSO(): boolean {
      return this.settings.get(SettingKeys.LoginWithSSO)?.value || false;
    },
    isRegistryIntegrationEnabled(): boolean {
      return this.settings.get(SettingKeys.RegistryIntegration)?.value || false;
    },
    isDepartmentEnabled(): boolean {
      return this.settings.get(SettingKeys.DepartmentsUse)?.value || false;
    },
    isAdvancedGpuMetricsEnabled(): boolean {
      return this.settings.get(SettingKeys.AdvancedGPUMetrics)?.value || false;
    },
    resourcesCost(): IResourcesCost {
      return this.settings.get(SettingKeys.ResourcesCost)?.value || { gpu: null, cpu: null, memory: null };
    },
    isCpuDashboardsEnabled(): boolean {
      return this.settings.get(SettingKeys.EnableCpuDashboards)?.value || false;
    },
    logoutURI(): string {
      return this.settings.get(SettingKeys.LogoutURI)?.value || "";
    },
    inactivityMinutesTillLogout(): number | undefined {
      return this.settings.get(SettingKeys.InactivityMinuetsTillLogout)?.value;
    },
    isPolicyManagerEnabled(): boolean {
      return this.settings.get(SettingKeys.EnablePolicies)?.value || false;
    },
    isGPUOverProvisioningEnabled(): boolean {
      return this.settings.get(SettingKeys.GpuOverProvisioning)?.value || false;
    },
  },
  actions: {
    async loadAdminMessage(): Promise<IAdminMessage | undefined> {
      return settingService.getByKey(SettingKeys.AdminMessage) as Promise<IAdminMessage | undefined>;
    },
    async loadSetting(): Promise<Map<string, Setting>> {
      this.settings = await settingService.get();
      return this.settings;
    },
    async updateSetting(key: SettingKeys, value: TSettingValue): Promise<Map<string, Setting>> {
      const originalValue = this.settings.get(key);
      this.settings.set(key, { ...originalValue, value });
      try {
        await settingService.update(key, value);
      } catch (err) {
        this.settings.set(key, originalValue);
        throw err;
      } finally {
        if (key === SettingKeys.DepartmentsUse) {
          await useAuthStore().loadUserOrgUnits(); // when updating the department feature flag, it is also needed to update the org unit for the scope tree
        }
      }
      return this.settings;
    },
  },
});
