<template>
  <section>
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          primary-btn-label="new project"
          :filters="filterBy"
          :columns="columns"
          :selected-rows-amount="selectedRowsAmount"
          @filters-changed="updateFilterBy"
          @create-entity="createProject"
          @selected-actions-close="resetSelectedRows"
          @export-csv="exportTableAsCsv"
        >
          <template v-slot:selected-rows-actions>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.AccessRules, action: Action.Read }"
              btn-action="assign"
              @click="openAccessRuleModal"
            />
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Project, action: Action.Update }"
              aid="edit-project-btn"
              btn-action="edit"
              @click="editProject"
            />
            <runai-tooltip-wrapper
              :display-tooltip="disablePolicyButton"
              tooltip-text="There is no policy applied for the selected project"
            >
              <runai-action-button
                v-if="showPolicyButton"
                v-permission="{ resourceType: ResourceType.Project, action: Action.Read }"
                aid="view-project-policy-btn"
                btn-action="viewPolicy"
                :disable="disablePolicyButton"
              >
                <policy-dropdown
                  @policy-selected="viewPolicy"
                  entity-name="project"
                  :existing-policies="policyMenuOptions"
                />
              </runai-action-button>
            </runai-tooltip-wrapper>
            <runai-action-button
              v-permission="{ resourceType: ResourceType.Project, action: Action.Delete }"
              aid="delete-project-btn"
              btn-action="delete"
              @click="openDeleteModal"
            />
          </template>
        </runai-page-actions>
      </template>

      <template v-slot:table>
        <runai-table
          data-id="project-table"
          :columns="columns"
          :rows="projects"
          :loading="isInitialLoader && loadingProjects"
          :filter-by="filterBy"
          v-model:selected="selectedProjects"
          @update-filters="updateFilterBy"
          @node-pools-clicked="displayProjectNodePools"
          @access-rules-clicked="displayAccessRuleTableModal"
          @workloads-clicked="displayWorkloadsModal"
          :get-row-key="getRowKey"
          :top-row="lastCreatedProject"
          sticky-columns
          ref="runaiTable"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!isInitialLoader && !lastCreatedProject"
              :filter-by="filterBy"
              entity-name="project"
              icon-name="project"
              icon-size="122px"
              @clear-filters="clearFilters"
              @create-new="createProject"
              :show-create-btn="canCreateProjects"
              :show-error="loadingError"
              :custom-message="getCustomMessage"
            >
              <template v-if="!canCreateProjects" v-slot:secondary-text>
                To be assigned to a project contact your administrator.</template
              >
            </runai-table-no-data>
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>
    <node-pools-modal v-if="isNodePoolsModalOpen" :modal-options="nodePoolsModalOptions" @close="closeNodePoolsModal" />
    <access-rule-table-modal
      v-if="isAccessRuleTableModalOpen"
      @close="closeAccessRuleTableModal"
      :modal-options="accessRuleTableModalOptions"
    />
    <project-delete-modal
      v-if="isDeleteModalOpen && selectedProject"
      :project-name="selectedProject.name"
      :namespace="selectedProject.namespace || selectedProject.status?.namespace"
      @delete="onProjectDelete"
      @close="isDeleteModalOpen = false"
      :loading="isDeleting"
    />
    <access-rule-management-modal
      v-if="isAccessRuleManagementModalOpen"
      @close="isAccessRuleManagementModalOpen = false"
      @access-rule-created="onAccessRuleCreated"
      @access-rule-deleted="onAccessRuleDeleted"
      :modal-options="accessRuleManagementModalOptions"
    />
    <workload-list-modal
      v-if="isWorkloadsModalOpen"
      :modal-options="workloadsModalOptions"
      @close="isWorkloadsModalOpen = false"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
//store
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useSettingStore } from "@/stores/setting.store";

//models
import type { IFilterBy } from "@/models/filter.model";
import type { INodePoolModalOptions, IProject, INodePoolsResourcesRow } from "@/models/project.model";
import {
  allProjectColumnsMap,
  projectDependentColumns,
  projectIndexColumns,
  PROJECTS_REFRESH_INTERVAL,
} from "@/models/project.model";
import type { ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames, ETableFilters } from "@/models/table.model";
//service
import { filterService } from "@/services/filter.service/filter.service";
//constants
import { alertUtil } from "@/utils/alert.util";
import { isNewerVersion } from "@/utils/version.util";
import { tableUtil } from "@/utils/table.util";

import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
//components
import { NodePoolsModal } from "@/components/node-pools/node-pools-modal";
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { ProjectDeleteModal } from "@/components/project/project-delete-modal";
import { RunaiTable } from "@/components/common/runai-table";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { PolicyDropdown } from "@/components/policy/policy-dropdown";
import { WorkloadListModal } from "@/components/workload/workload-list-modal";

import type { IAccessRuleTableModalOptions } from "@/models/access-rule.model";
import { AccessRuleManagementModal } from "@/components/rbac/access-rule/access-rule-management-modal";
import type { IAccessRuleManagementModalOptions } from "@/models/access-rule.model";
import { accessRulesMiniTableColumns, EAccessRuleModalPage } from "@/models/access-rule.model";
import { type AccessRule, Action, ResourceType, ScopeType } from "@/swagger-models/authorization-client";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { MIN_PROJECT_STATUS_VERSION, MIN_TRAIN_TIME_LIMIT_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { useClusterStore } from "@/stores/cluster.store";

import { EQuotaEntity } from "@/models/resource.model";
import {
  Scope,
  PolicyType,
  type WorkspacePolicy,
  type TrainingPolicy,
  type TrainingPolicyRules,
  type WorkspacePolicyRules,
  type TrainingPolicyDefaults,
  type WorkspacePolicyDefaults,
} from "@/swagger-models/policy-service-client";
import { AccessRuleTableModal } from "@/components/rbac/access-rule/access-rule-table-modal/";
import { usePermissionStore } from "@/stores/permissions.store";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { isVersionBetween } from "@/utils/version.util/version.util";
import { POLICIES_ROUTE_NAMES } from "@/router/policy.routes";
import { policyUtil } from "@/utils/policy.util/policy.util";
import type { IWorkloadListModalOptions } from "@/models/workload.model";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { WorkloadSortFilterFields } from "@/swagger-models/workloads-service-client";

const DEFAULT_WORKLOADS_MODAL_OPTIONS = {
  header: "",
  entityFilter: "",
  entityName: "",
  filterName: WorkloadSortFilterFields.ProjectName,
};
export default defineComponent({
  name: "projects-index",
  components: {
    RunaiTooltipWrapper,
    WorkloadListModal,
    AccessRuleTableModal,
    AccessRuleManagementModal,
    RunaiTableNoData,
    RunaiTable,
    ProjectDeleteModal,
    NodePoolsModal,
    RunaiPageActions,
    RunaiTableWrapper,
    RunaiActionButton,
    PolicyDropdown,
  },
  data() {
    return {
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      nodePoolStore: useNodePoolStore(),
      permissionStore: usePermissionStore(),
      filterBy: {} as IFilterBy,
      loadingProjects: false as boolean,
      loadingError: false as boolean,
      isDeleting: false as boolean,
      isDeleteModalOpen: false as boolean,
      isNodePoolsModalOpen: false as boolean,
      isAccessRuleTableModalOpen: false as boolean,
      isAccessRuleManagementModalOpen: false as boolean,
      isWorkloadsModalOpen: false as boolean,
      hideNodePoolsColumn: false as boolean,
      isInitialLoader: true as boolean,
      selectedProjects: [] as Array<IProject>,
      projectId: -1 as number,
      accessRuleTableModalOptions: {
        accessRules: [],
        header: "",
        columns: accessRulesMiniTableColumns,
        loading: false,
      } as IAccessRuleTableModalOptions,
      nodePoolsModalOptions: {
        nodePools: [],
        loading: false,
        entity: EQuotaEntity.project,
        header: "",
      } as INodePoolModalOptions,
      accessRuleManagementModalOptions: {
        page: EAccessRuleModalPage.Project,
        scopeType: ScopeType.Project,
        scopeName: "",
        scopeId: "",
      } as IAccessRuleManagementModalOptions,
      refreshInterval: PROJECTS_REFRESH_INTERVAL as number,
      currentTimeoutId: 0 as number,
      loadingPolicies: false as boolean,
      selectedProjectPolicies: null as Record<PolicyType, WorkspacePolicy | TrainingPolicy> | null,
      workloadsModalOptions: {
        ...DEFAULT_WORKLOADS_MODAL_OPTIONS,
      } as IWorkloadListModalOptions,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    const projectId: string | null = this.$route.query.createdProjectId?.toString() || null;
    if (projectId) {
      this.projectStore.setLastCreatedProjectId(+projectId);
    }
    this.loadFilters();
    this.loadProjects();
    this.currentTimeoutId = window.setInterval(this.loadProjects, this.refreshInterval);
    try {
      this.nodePoolStore.loadNodePoolsCount();
    } catch (e) {
      console.log(e);
      this.hideNodePoolsColumn = true;
    }
  },
  computed: {
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    columns(): ITableColumn[] {
      return projectIndexColumns.filter((col: ITableColumn) => {
        if (projectDependentColumns.cpu.has(col.name)) {
          return this.settingStore.isCPUResourcesQuotaEnabled;
        }
        if (projectDependentColumns.department.has(col.name)) {
          return this.isDepartmentEnabled;
        }
        if (projectDependentColumns.status.has(col.name)) {
          return this.isVersionSupportProjectStatus;
        }
        if (projectDependentColumns.trainingJobTimeLimitSecs.has(col.name)) {
          return this.isClusterSupportTrainingJobTimeLimit;
        }
        if (projectDependentColumns.accessRules.has(col.name)) {
          return this.permissionStore.hasPermission(ResourceType.AccessRules, Action.Read);
        }
        if (projectDependentColumns.nodePools.has(col.name)) {
          return !this.nodePoolStore.isOnlyDefaultNodePool && !this.hideNodePoolsColumn;
        }
        if (projectDependentColumns.workloads.has(col.name)) {
          return this.isVersionSupportWorkloadsService;
        }
        return true;
      });
    },
    projects(): Array<IProject> {
      return this.projectStore.projectList;
    },
    selectedRowsAmount(): number {
      return this.selectedProjects.length;
    },
    canCreateProjects(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    lastCreatedProject(): IProject | null {
      return this.projectStore.lastCreatedProject;
    },
    selectedProject(): IProject | null {
      if (this.selectedProjects.length > 0) {
        return this.selectedProjects[0];
      }
      return null;
    },
    getCustomMessage(): string {
      if (this.canCreateProjects) {
        return "You don't have any projects yet.";
      }
      return "You are not assigned to any project yet.";
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isVersionSupportProjectStatus(): boolean {
      const version = this.clusterStore.currentCluster.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_PROJECT_STATUS_VERSION) || version.includes(TEST_ENV_VERSION);
    },
    disablePolicyButton(): boolean {
      return this.loadingPolicies || !this.policyMenuOptions.length;
    },
    hasInteractivePolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: WorkspacePolicyRules = this.selectedProjectPolicies[PolicyType.Workspace]?.effective?.rules || {};
      const defaults: WorkspacePolicyDefaults =
        this.selectedProjectPolicies[PolicyType.Training]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    hasTrainingPolicy(): boolean {
      if (!this.selectedProjectPolicies) return false;
      const rules: TrainingPolicyRules = this.selectedProjectPolicies[PolicyType.Training]?.effective?.rules || {};
      const defaults: TrainingPolicyDefaults =
        this.selectedProjectPolicies[PolicyType.Training]?.effective?.defaults || {};
      return !!Object.keys(rules).length || !!Object.keys(defaults).length;
    },
    policyMenuOptions(): Array<PolicyType> {
      const policyOptions: Array<PolicyType> = [];
      if (!this.selectedProjectPolicies) return policyOptions;

      this.hasInteractivePolicy && policyOptions.push(PolicyType.Workspace);
      this.hasTrainingPolicy && policyOptions.push(PolicyType.Training);

      return policyOptions;
    },
    isClusterSupportTrainingJobTimeLimit(): boolean {
      const version = this.clusterStore.currentCluster.version;
      if (!version) return false;
      return (
        isVersionBetween(version, "2.11", "2.12") ||
        isNewerVersion(version, MIN_TRAIN_TIME_LIMIT_VERSION) ||
        version.includes(TEST_ENV_VERSION)
      );
    },
    isVersionSupportWorkloadsService(): boolean {
      return this.clusterStore.isVersionSupportWorkloadsService;
    },
    showPolicyButton(): boolean {
      return this.settingStore.isPolicyManagerEnabled;
    },
  },
  methods: {
    async loadSelectedProjectPolicy(): Promise<void> {
      if (!this.selectedProject) {
        this.selectedProjectPolicies = null;
        return;
      }
      try {
        this.loadingPolicies = true;
        await Promise.all([
          this.projectStore.loadPolicy(PolicyType.Workspace, this.selectedProject.id),
          this.projectStore.loadPolicy(PolicyType.Training, this.selectedProject.id),
        ]).then((res) => {
          this.selectedProjectPolicies = {
            [PolicyType.Workspace]: res[0],
            [PolicyType.Training]: res[1],
          };
        });
      } catch (err: unknown) {
        console.error("Failed to load project policies", err);
      } finally {
        this.loadingPolicies = false;
      }
    },
    viewPolicy(workloadType: PolicyType): void {
      if (!this.selectedProject?.id) return;
      const policyId = policyUtil.createPolicyId(workloadType, Scope.Project, this.selectedProject.id);
      this.$router.push({
        name: POLICIES_ROUTE_NAMES.POLICY_VIEW,
        params: { id: policyId },
        query: { previousRoute: PROJECT_ROUTE_NAMES.PROJECT_INDEX },
      });
    },
    async loadProjects(): Promise<void> {
      try {
        this.loadingProjects = true;
        await this.projectStore.loadProjects(
          { withAccessRules: true, withNamespace: false, withMetrics: true },
          this.filterBy,
        );
      } catch (e) {
        this.loadingError = true;
        this.$q.notify(alertUtil.getError("Failed to load projects"));
        console.error(e, "Failed to load projects");
      } finally {
        this.isInitialLoader = false;
        this.loadingProjects = false;
      }
    },
    clearFilters(): void {
      this.updateFilterBy({
        ...this.filterBy,
        columnFilters: [],
        searchTerm: "",
      });
    },
    resetSelectedRows(): void {
      this.selectedProjects = [];
    },
    createProject(): void {
      this.$router.push({ name: PROJECT_ROUTE_NAMES.PROJECT_NEW });
    },
    editProject(): void {
      if (!this.selectedProject) return;
      this.$router.push({ name: PROJECT_ROUTE_NAMES.PROJECT_EDIT, params: { id: this.selectedProject.id } });
    },
    openDeleteModal(): void {
      this.stopRefreshProjects();
      this.isDeleteModalOpen = true;
    },
    async onProjectDelete(): Promise<void> {
      if (!this.selectedProject) return;
      this.isDeleting = true;
      try {
        const deletedProject: IProject = await this.projectStore.deleteProject(this.selectedProject);
        this.isDeleteModalOpen = false;
        this.resetSelectedRows();
        this.$q.notify(alertUtil.getSuccess(`Project ${deletedProject.name} deleted`));
      } catch (e) {
        this.$q.notify(alertUtil.getError(`Project ${this.selectedProject.name} couldn't be deleted`));
      } finally {
        this.isDeleting = false;
      }
    },
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        allProjectColumnsMap.projectName.name,
        this.columns,
      );

      const searchParamsFilters: IFilterBy = filterService.loadFilters(
        window.location,
        ETableFilters.PROJECT,
        defaultFilters,
      );

      this.updateFilterBy(searchParamsFilters, null, false);
    },
    updateFilterBy(filterBy: IFilterBy, keyChanged: null | string = null, forceLoad = true): void {
      this.filterBy = filterBy;
      filterService.saveFilters(ETableFilters.PROJECT, filterBy);
      if (!forceLoad || keyChanged === "displayedColumns") return;
      this.isInitialLoader = true;
      this.projectStore.removeLastCreatedProject();
      this.loadProjects();
    },
    async displayProjectNodePools(project: IProject): Promise<void> {
      this.isNodePoolsModalOpen = true;
      this.nodePoolsModalOptions.header = `Node Pools With Quota Associated with Project ${project.name}`;
      this.nodePoolsModalOptions.loading = true;
      const nodePools = await nodePoolService.enrichProjectNodePoolWithResourceMetric(
        project.nodePoolsResources,
        project.name,
        this.clusterStore.currentCluster.uuid,
      );
      this.nodePoolsModalOptions.nodePools = nodePools.map((nodePool: INodePoolsResourcesRow) => {
        return {
          ...nodePool,
          priority: this.getNodePoolPriorityByName(project.defaultNodePools || [], nodePool.nodePool.name),
        };
      });
      this.nodePoolsModalOptions.loading = false;
    },
    async displayAccessRuleTableModal(project: IProject): Promise<void> {
      this.isAccessRuleTableModalOpen = true;
      this.accessRuleTableModalOptions.loading = true;
      this.accessRuleTableModalOptions.header = `Subjects Authorized for Project ${project.name}`;
      const accessRulesRecords = await accessRuleService.getAccessRules({
        scopeType: ScopeType.Project,
        scopeId: project.id.toString(),
      });
      this.accessRuleTableModalOptions.accessRules = accessRulesRecords.accessRules;
      this.accessRuleTableModalOptions.loading = false;
    },
    closeAccessRuleTableModal(): void {
      this.isAccessRuleTableModalOpen = false;
      this.accessRuleTableModalOptions.accessRules = [];
      this.accessRuleTableModalOptions.header = "";
    },
    closeNodePoolsModal(): void {
      this.isNodePoolsModalOpen = false;
      this.resetNodePoolsModalOptions();
    },
    resetNodePoolsModalOptions(): void {
      this.nodePoolsModalOptions = { nodePools: [], loading: false, entity: EQuotaEntity.project, header: "" };
    },
    getRowKey(row: IProject): number {
      return row.id;
    },
    async stopRefreshProjects(): Promise<void> {
      window.clearTimeout(this.currentTimeoutId);
    },
    getNodePoolPriorityByName(nodePoolsPriority: string[], name: string): number | string {
      const index = nodePoolsPriority.indexOf(name);
      if (index > -1) {
        return index + 1;
      }
      return "empty";
    },
    openAccessRuleModal(): void {
      if (!this.selectedProject) return;
      this.accessRuleManagementModalOptions.scopeName = this.selectedProject?.name;
      this.accessRuleManagementModalOptions.scopeId = this.selectedProject.id.toString();
      this.isAccessRuleManagementModalOpen = true;
    },
    onAccessRuleCreated(accessRule: AccessRule): void {
      if (!this.selectedProject?.id) return;
      this.projectStore.updateProjectSubjectType(this.selectedProject.id, accessRule.subjectType);
    },
    onAccessRuleDeleted(accessRuleIndex: number): void {
      if (!this.selectedProject?.id) return;
      this.projectStore.deleteProjectSubjectType(this.selectedProject.id, accessRuleIndex);
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Project, this.projects, columns);
    },
    async displayWorkloadsModal(project: IProject): Promise<void> {
      this.isWorkloadsModalOpen = true;
      this.workloadsModalOptions.header = `Workloads Associated with Project ${project.name}`;
      this.workloadsModalOptions.entityFilter = `projectId==${project.id}`;
      this.workloadsModalOptions.entityName = project.name;
    },
    resetWorkloadsModalOptions(): void {
      this.workloadsModalOptions = { ...DEFAULT_WORKLOADS_MODAL_OPTIONS };
    },
  },
  watch: {
    selectedProject: {
      handler(newVal: IProject | null): void {
        if (!newVal) return;
        this.loadSelectedProjectPolicy();
      },
    },
    isWorkloadsModalOpen(isOpen: boolean): void {
      if (!isOpen) {
        this.resetWorkloadsModalOptions();
      }
    },
  },
  unmounted() {
    this.projectStore.removeLastCreatedProject();
    this.stopRefreshProjects();
  },
});
</script>

<style scoped></style>
