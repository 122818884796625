import { ECustomCell, type ITableColumn } from "./table.model";
import {
  MigProfile,
  type AssetRef,
  type ComputeAsset,
  type ExtendedResource,
  type WorkloadRefAndStatus,
  Scope,
  type AssetUsageInfo,
  GpuRequestType,
} from "@/swagger-models/assets-service-client";
import { EMemoryUnitValue, type ISelectOption, type IUIAssetCreationMeta } from "./global.model";
import { dateUtil } from "@/utils/date.util";
import { getMemoryUnitsForUI } from "@/utils/table-format.util";

export interface IUIComputeResourceCreation {
  meta: IUIAssetCreationMeta;
  spec: {
    gpuDevicesRequest: number;
    gpuRequestType?: GpuRequestType | null | undefined;
    gpuPortionRequest?: number | null | undefined;
    gpuPortionLimit?: number | null | undefined;
    gpuMemoryRequest?: string | null | undefined;
    gpuMemoryLimit?: string | null | undefined;
    migProfile?: MigProfile | null | undefined;
    cpuCoreRequest: number;
    cpuMemoryRequest: string;
    cpuCoreLimit?: number | null | undefined;
    cpuMemoryLimit?: string | null | undefined;
    largeShmRequest?: boolean | null;
    extendedResources?: Array<ExtendedResource> | null;
  };
}

export enum EGpuRequest {
  gpuMemory = "gpuMemory",
  gpuPortion = "gpuPortion",
  migProfile = "migProfile",
}

export interface ICpuCore {
  request: number;
  limit?: number | null | undefined;
}

export interface ICpuMemory {
  request: string;
  limit?: string | null | undefined;
}

export interface IGpuPortion {
  request: number;
  limit?: number | null | undefined;
}

export interface IGpuMemory {
  request: string;
  limit?: string | null | undefined;
}
export interface IResourcesSectionModel {
  gpuDevicesRequest: number;
  gpuRequestType?: GpuRequestType | null;
  gpuPortionRequest?: number | null;
  gpuPortionLimit?: number | null;
  gpuMemoryRequest?: string | null;
  gpuMemoryLimit?: string | null;
  migProfile?: MigProfile | null | undefined;
  cpuCore: ICpuCore;
  cpuMemory: ICpuMemory;
}

export interface IGpuRequestModel {
  gpuDevicesRequest: number;
  gpuRequestType?: GpuRequestType | null;
  gpuPortionRequest?: number | null;
  gpuPortionLimit?: number | null;
  gpuMemoryRequest?: string | null;
  gpuMemoryLimit?: string | null;
  migProfile?: MigProfile | null | undefined;
}

export const memoryUnitOptions: Array<ISelectOption> = [
  { label: "MB", value: "M" },
  { label: "GB", value: "G" },
];

export const cpuUnitOptions: Array<ISelectOption> = [
  { label: "Cores", value: 1 },
  { label: "Millicores", value: 1000 },
];

export const gpuRequestTypeOptions = [
  { label: "% (of device)", value: GpuRequestType.Portion },
  { label: "MB (memory size)", value: EMemoryUnitValue.MB },
  { label: "GB (memory size)", value: EMemoryUnitValue.GB },
  { label: "Multi-instance GPU (MIG)", value: GpuRequestType.MigProfile },
];

export const migProfileOptions: Array<ISelectOption> = Object.values(MigProfile).map((profile: string) => ({
  value: profile,
  label: profile,
}));

export const allComputeResourceColumnsMap: Record<string, ITableColumn> = {
  computeResource: {
    name: "compute-resource",
    label: "Compute resource",
    field: (row) => row.meta.name,
    sortable: true,
    align: "left",
  },
  gpu: {
    name: "gpu",
    label: "GPU",
    field: (row) => row,
    sortable: true,
    align: "left",
    format: (row: ComputeAsset): string => {
      const { gpuDevicesRequest, gpuPortionRequest, gpuMemoryRequest, migProfile } = row.spec;
      return (
        (gpuMemoryRequest && getMemoryUnitsForUI(gpuMemoryRequest)) ||
        gpuPortionRequest?.toString() ||
        migProfile ||
        gpuDevicesRequest?.toString() ||
        "-"
      );
    },
  },
  cpuCores: {
    name: "cpu-cores",
    label: "CPU (Cores)",
    field: (row) => row.spec.cpuCoreRequest,
    sortable: true,
    align: "left",
  },
  cpuMemory: {
    name: "cpu-memory",
    label: "CPU memory",
    field: (row) => getMemoryUnitsForUI(row.spec.cpuMemoryRequest),
    sortable: true,
    align: "left",
    sort: (a: string, b: string) => {
      let aMemory = Number(a?.split(" ")[0] || "");
      let bMemory = Number(b?.split(" ")[0] || "");
      const aUnit = a?.split(" ")[1] || "";
      const bUnit = b?.split(" ")[1] || "";
      if (aUnit === "GB") {
        aMemory *= 1000;
      }
      if (bUnit === "GB") {
        bMemory *= 1000;
      }
      return aMemory - bMemory;
    },
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row) => row.meta.projectName,
    sortable: true,
    align: "left",
    format: (scope: Scope, computeResource: ComputeAsset): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(computeResource.meta.projectId);
        case Scope.Department:
          return String(computeResource.meta.departmentId);
        default:
          return String(computeResource.meta.tenantId);
      }
    },
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.usedBy,
    sortable: true,
    align: "left",
    format: (usageInfo: AssetUsageInfo | undefined): Array<string> => {
      if (!usageInfo) return [];
      const workspaces: Array<string> = usageInfo.workspaces?.map((ws: WorkloadRefAndStatus) => ws.name) || [];
      const trainings: Array<string> = usageInfo.trainings?.map((training: WorkloadRefAndStatus) => training.name) || [];
      return [...workspaces, ...trainings];
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  templates: {
    name: "templates",
    label: "Template(s)",
    field: (row) => row.usedBy?.templates,
    sortable: true,
    align: "left",
    format: (templates: Array<AssetRef>): Array<string> => {
      if (!templates?.length) return [];
      return templates.map((template: AssetRef) => template.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-template-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row) => row.meta.createdBy,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row) => row.meta.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allComputeResourceColumns: Array<ITableColumn> = [
  allComputeResourceColumnsMap.computeResource,
  allComputeResourceColumnsMap.gpu,
  allComputeResourceColumnsMap.cpuCores,
  allComputeResourceColumnsMap.cpuMemory,
  allComputeResourceColumnsMap.scope,
  allComputeResourceColumnsMap.workloads,
  allComputeResourceColumnsMap.templates,
  allComputeResourceColumnsMap.createdBy,
  allComputeResourceColumnsMap.createdAt,
];

export const computeResourceIndexColumns: Array<ITableColumn> = [
  { ...allComputeResourceColumnsMap.computeResource, display: true, mandatory: true },
  { ...allComputeResourceColumnsMap.gpu, display: true },
  { ...allComputeResourceColumnsMap.cpuCores, display: true },
  { ...allComputeResourceColumnsMap.cpuMemory, display: true },
  { ...allComputeResourceColumnsMap.scope, display: true },
  { ...allComputeResourceColumnsMap.workloads, display: true },
  { ...allComputeResourceColumnsMap.templates, display: false },
  { ...allComputeResourceColumnsMap.createdBy, display: true },
  { ...allComputeResourceColumnsMap.createdAt, display: true },
];
