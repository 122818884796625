<template>
  <q-linear-progress animation-speed="100" indeterminate v-if="loading" />
  <div class="runai-org-tree">
    <q-tree
      ref="tree"
      class="col-12 col-sm-6"
      :nodes="nodes"
      node-key="path"
      tick-strategy="strict"
      :ticked="ticked"
      @update:ticked="updateTicked"
      :filter="searchTerm"
      :no-results-label="noResultsLabel"
      :duration="100"
    >
      <template v-slot:default-header="prop">
        <div class="row items-center" :aid="prop.node.path">
          <q-icon class="tree-icon" :name="prop.node.icon" size="16px" /> {{ prop.node.label }}
          <runai-tooltip
            v-if="prop.node.tooltip"
            class="tooltip-icon q-ml-xs"
            :tooltip-text="prop.node.tooltip"
            width="450px"
            size="xs"
            tooltip-position="right"
            icon="far fa-circle-info"
            :ripple="false"
          />
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// stores
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { orgTreeService } from "@/services/control-plane/rbac/org-tree.service/org-tree.service";

// models
import type { IOrgTreeNode, IOrgTreeNodeId } from "@/models/org-tree.model";
import type { QTree } from "quasar";
import { ScopeType, type PermittedScopes } from "@/swagger-models/authorization-client";
import { RunaiTooltip } from "@/components/common/runai-tooltip";

const enum EDisabledScopesTooltips {
  DEFAULT = "This scope can't be selected. Try selecting its subordinates.",
  CLUSTER_VERSION = "This scope can't be selected for the current version of the cluster. Try selecting its subordinates.",
  PERMISSION = "You don't have permissions to select this scope. Try selecting its subordinates.",
}

export default defineComponent({
  components: { RunaiTooltip },
  emits: ["update:selected"],
  props: {
    selected: {
      type: Object as PropType<IOrgTreeNodeId>,
      default: {} as IOrgTreeNodeId,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    searchTerm: {
      type: String as PropType<string>,
      default: "",
    },
    noResultsLabel: {
      type: String as PropType<string>,
      default: "No cluster, department, or project match your filters",
    },
    hideClusters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    allowedScopes: {
      type: Object as PropType<PermittedScopes>,
    },
    disableSpecificScopes: {
      type: Object as PropType<Record<ScopeType, boolean>>,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedNode: {} as IOrgTreeNodeId,
      ticked: [] as string[],
      lastTicked: [] as string[],
      nodes: [] as IOrgTreeNode[],
      loading: false as boolean,
      clusterStore: useClusterStore(),
      authStore: useAuthStore(),
      expandedAllowedScopes: {} as PermittedScopes,
    };
  },
  async created() {
    this.initTreeNodes();
    if (!this.allowedScopes) return;
    this.expandedAllowedScopes = orgTreeService.getExpandedPermittedScopes(
      this.allowedScopes,
      this.authStore.orgUnitList,
    );
  },
  mounted() {
    this.markSelectedNode();
    this.disableNodes(this.nodes);
  },
  methods: {
    async initTreeNodes(): Promise<void> {
      const orgTree: IOrgTreeNode = orgTreeService.getOrgTree(this.authStore.orgUnitList);
      if (this.hideClusters) {
        orgTree.children = this.removeOtherClusters(orgTree);
      }
      this.nodes = [orgTree];
    },
    removeOtherClusters(orgTree: IOrgTreeNode) {
      return orgTree.children.filter((node: IOrgTreeNode) => {
        return node.id === this.clusterStore.currentCluster.uuid;
      });
    },
    markSelectedNode(): void {
      if (!this.selected) return;
      const path: string | null = orgTreeService.getNodePathById(this.nodes[0], this.selected);
      if (!path) return;
      this.updateTicked([path]);
      if (this.readonly) {
        const rootNode: IOrgTreeNode = !!this.$refs.tree && (this.$refs.tree as QTree).getNodeByKey(this.nodes[0].path);
        this.changeTickable(rootNode, false);
        rootNode.tickable = false;
      }
      this.setPathExpanded(path);
    },
    updateTicked(ticked: string[]): void {
      const tree: QTree = this.$refs.tree as QTree;
      if (this.ticked[0] && this.ticked[0] !== ticked[0]) {
        // as part of support single selection, so check if the last ticked node is the same as the current one
        this.unTickAndEnableChildren(tree.getNodeByKey(this.ticked[0]));
        this.ticked = [];
      } else {
        if (this.ticked[0]) {
          // as part of support single unselect the current node, since it is the same node
          this.unTickAndEnableChildren(tree.getNodeByKey(this.ticked[0]));
        }
        this.ticked = ticked[ticked.length - 1] ? [ticked[ticked.length - 1]] : [];
        const lastTicked: string = ticked[ticked.length - 1];
        const lastTickedNode: IOrgTreeNode = !!this.$refs.tree && tree.getNodeByKey(lastTicked);
        if (lastTickedNode?.children) this.disableNodeChildren(lastTickedNode);
      }
      const selectedOrgTreeNode: IOrgTreeNode = !!this.$refs.tree && tree.getNodeByKey(this.ticked[0]);
      if (selectedOrgTreeNode) {
        this.selectedNode = {
          id: selectedOrgTreeNode.id,
          type: selectedOrgTreeNode.type,
          path: selectedOrgTreeNode.path,
        };
      } else {
        this.selectedNode = {} as IOrgTreeNodeId;
      }
      this.$emit("update:selected", this.selectedNode);
    },
    disableNodeChildren(node: IOrgTreeNode): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        this.ticked.push(child.path);
        child.tickable = false;
        this.disableNodeChildren(child);
      });
    },
    changeTickable(node: IOrgTreeNode, tickable: boolean): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        child.tickable = tickable;
        this.changeTickable(child, tickable);
      });
    },
    unTickAndEnableChildren(node: IOrgTreeNode): void {
      node.children?.forEach((child: IOrgTreeNode) => {
        this.ticked = this.ticked.filter((id) => id !== child.path);
        child.tickable = true;
        this.unTickAndEnableChildren(child);
      });
      this.disableNodes(this.nodes);
    },
    setPathExpanded(path: string): void {
      const subPaths = path.split("/");
      let currentSubPath = "";
      for (const subPath of subPaths) {
        if (subPath) {
          currentSubPath += currentSubPath !== "" ? `/${subPath}` : subPath;
          !!this.$refs.tree && (this.$refs.tree as QTree).setExpanded(currentSubPath, true);
        }
      }
    },
    disableNodes(nodes: Array<IOrgTreeNode>): void {
      if (!nodes || !nodes.length || !this.allowedScopes) return;
      nodes.forEach((node: IOrgTreeNode) => {
        // Disabling cluster nodes if hideClusters is true (Assets can not be cluster scoped)
        if (node.type === ScopeType.Cluster && (this.hideClusters || this.disableSpecificScopes.cluster)) {
          node.tickable = false;
          node.tooltip = EDisabledScopesTooltips.DEFAULT;
        }
        // Disabling all non-allowed node scopes
        if (node.type === ScopeType.Project && this.disableSpecificScopes.project) {
          node.tickable = false;
          node.tooltip = EDisabledScopesTooltips.DEFAULT;
        }
        if (node.type === ScopeType.Department && this.disableSpecificScopes.department) {
          node.tickable = false;
          node.tooltip = EDisabledScopesTooltips.CLUSTER_VERSION;
        }
        if (node.type === ScopeType.Tenant && this.disableSpecificScopes.tenant) {
          node.tickable = false;
          node.tooltip = EDisabledScopesTooltips.CLUSTER_VERSION;
        }
        // Disabling according to RBAC permissions
        if (
          (node.type === ScopeType.Tenant && !this.expandedAllowedScopes?.tenant?.includes(node.id)) ||
          (node.type === ScopeType.Department && !this.expandedAllowedScopes?.departments?.includes(node.id)) ||
          (node.type === ScopeType.Project && !this.expandedAllowedScopes?.projects?.includes(node.id))
        ) {
          node.tickable = false;
          node.tooltip = EDisabledScopesTooltips.PERMISSION;
        }
        if (node.children?.length) this.disableNodes(node.children);
      });
    },
  },
});
</script>
<style lang="scss">
.runai-org-tree {
  .fal {
    font-weight: 900;
    font-size: 11px !important;
  }
  .q-tree__icon {
    color: $tree-icon;
    font-size: 16px !important;
    margin-left: 2px;
    margin-right: 6px;
  }
  .q-tree__arrow {
    padding-right: 2px !important;
  }
  .q-tree {
    padding: 10px;
    text-align: center;
    align-items: center;
    display: grid;
  }
  .tree-icon {
    color: $tree-icon;
    margin-left: 2px;
    margin-right: 6px;
  }
  .tooltip-icon .q-icon {
    font-size: 16px;
    color: $info;
  }
}
</style>
<style lang="scss" scoped></style>
