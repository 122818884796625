import { dateUtil } from "@/utils/date.util";
import type { ITableColumn } from "@/models/table.model";
import { ECustomCell } from "@/models/table.model";
import type { IFilterBy } from "@/models/filter.model";
import type { Role } from "@/swagger-models/authorization-client";

export const Actions: Array<IAction> = [
  {
    id: "read",
    title: "View",
    description:
      "If checked, an assigned user with this role can view instances of this type of entity within their defined scope",
  },
  {
    id: "update",
    title: "Edit",
    description:
      "If checked, an assigned user with this role can change the settings of instances of this type of entity within their defined scope",
  },
  {
    id: "create",
    title: "Create",
    description:
      "If checked, an assigned user with this role can create new instances of this type of entity within their defined scope",
  },
  {
    id: "delete",
    title: "Delete",
    description:
      "If checked, an assigned user with this role can delete instances of this type of entity within their defined scope",
  },
];

export interface IAction {
  id: string;
  title: string;
  description: string;
}

export type TRolesTypes =
  | "admin"
  | "researcher"
  | "research_manager"
  | "editor"
  | "viewer"
  | "ml_engineer"
  | "department_admin";

export enum ERolesLabels {
  "admin" = "Admin",
  "researcher" = "Researcher",
  "research_manager" = "Research manager",
  "editor" = "Editor",
  "viewer" = "Viewer",
  "ml_engineer" = "ML engineer",
  "department_admin" = "Department admin",
}

export const allRoleColumnsMap: Record<string, ITableColumn> = {
  role: {
    name: "role",
    label: "Role",
    field: (row: Role) => row.name,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "role-clicked" },
  },
  description: {
    name: "description",
    label: "Description",
    field: (row: Role) => row.description,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.TOOLTIP_COL,
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row: Role) => row.createdBy,
    sortable: true,
    align: "left",
    display: true,
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: Role) => row.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allRoleColumns: Array<ITableColumn> = [
  allRoleColumnsMap.role,
  allRoleColumnsMap.description,
  allRoleColumnsMap.createdBy,
  allRoleColumnsMap.creationTime,
];

export const roleIndexColumns: Array<ITableColumn> = [
  { ...allRoleColumnsMap.role, display: true, mandatory: true },
  { ...allRoleColumnsMap.description, display: true },
  { ...allRoleColumnsMap.createdBy, display: true },
  { ...allRoleColumnsMap.creationTime, display: true },
];

export const defaultRolesFilterBy: IFilterBy = {
  sortBy: "role",
  descending: false,
  page: 1,
  rowsPerPage: 20,
  columnFilters: [],
  searchTerm: "",
  displayedColumns: [
    allRoleColumnsMap.role.name,
    allRoleColumnsMap.description.name,
    allRoleColumnsMap.createdBy.name,
    allRoleColumnsMap.creationTime.name,
  ],
};
