import type { ITableColumn } from "./table.model";
import { ECustomCell } from "./table.model";
import { dateUtil } from "@/utils/date.util";
import {
  type AssetRef,
  Scope,
  type SpecificRunCreationFields,
  type WorkloadTemplate,
} from "@/swagger-models/assets-service-client";
import {
  EWorkloadNodeArchitecture,
  type IUIDistributed,
  type IUIWorkloadAssets,
  type IUIWorkloadSpecificEnv,
} from "./workload.model";
import type { IUIAssetCreationMeta } from "./global.model";

export interface IUIWorkloadTemplateCreation {
  name: string;
  scope: Scope | null;
  projectId?: number | null;
  departmentId?: string | null;
  namespace: string;
  clusterId?: string | null;
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
  distributed?: IUIDistributed | null;
  enableEditingMaster?: boolean;
}

export interface IUIWorkloadTemplateCreationRequestModel {
  meta: IUIAssetCreationMeta;
  spec: SpecificRunCreationFields;
}

export const allWorkloadTemplateColumnsMap: Record<string, ITableColumn> = {
  template: {
    name: "template",
    label: "Template",
    field: (row) => row.meta.name,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NAME_COL,
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row: WorkloadTemplate) => row.meta.scope,
    sortable: true,
    align: "left",
    display: true,
    format: (scope: Scope, workloadTemplate: WorkloadTemplate): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(workloadTemplate.meta.projectId);
        case Scope.Department:
          return String(workloadTemplate.meta.departmentId);
        default:
          return String(workloadTemplate.meta.tenantId);
      }
    },
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  environment: {
    name: "environment",
    label: "Environment",
    field: (row) => row.spec.assets?.environment?.name,
    sortable: true,
    align: "left",
    display: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: WorkloadTemplate) => row.meta.workloadSupportedTypes?.distributed,
    sortable: true,
    align: "left",
    format: (val: boolean | undefined) =>
      val ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    display: true,
  },
  computeResource: {
    name: "compute-resource",
    label: "Compute resource",
    field: (row) => row.spec.assets?.compute?.name,
    format: (name: string | undefined) => name || "-",
    sortable: true,
    align: "left",
    display: true,
  },
  dataSource: {
    name: "datasources",
    label: "Data source(s)",
    field: (row) => row.spec.assets?.datasources,
    format: (dataSources: Array<AssetRef>) => {
      if (!dataSources?.length) return [];
      return dataSources.map((ds) => ds.name);
    },
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "data-source-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row) => row.meta.createdBy,
    sortable: true,
    align: "left",
    display: true,
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row) => row.meta.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
    display: true,
  },
};

export const allWorkloadTemplateColumns: Array<ITableColumn> = [
  allWorkloadTemplateColumnsMap.template,
  allWorkloadTemplateColumnsMap.scope,
  allWorkloadTemplateColumnsMap.environment,
  allWorkloadTemplateColumnsMap.multiNode,
  allWorkloadTemplateColumnsMap.computeResource,
  allWorkloadTemplateColumnsMap.dataSource,
  allWorkloadTemplateColumnsMap.createdBy,
  allWorkloadTemplateColumnsMap.createdAt,
];

export const workloadTemplateIndexColumns: Array<ITableColumn> = [
  { ...allWorkloadTemplateColumnsMap.template, display: true, mandatory: true },
  { ...allWorkloadTemplateColumnsMap.scope, display: true },
  { ...allWorkloadTemplateColumnsMap.environment, display: true },
  { ...allWorkloadTemplateColumnsMap.multiNode, display: false },
  { ...allWorkloadTemplateColumnsMap.computeResource, display: true },
  { ...allWorkloadTemplateColumnsMap.dataSource, display: true },
  { ...allWorkloadTemplateColumnsMap.createdBy, display: true },
  { ...allWorkloadTemplateColumnsMap.createdAt, display: true },
];

export const workloadTemplateMiniTableColumns: Array<ITableColumn> = [
  { ...allWorkloadTemplateColumnsMap.template, display: true },
];
