export function numberFormat(val: string | number, suffix?: string) {
  const numberVal = Number(val);
  if (isNaN(numberVal) && val !== "NA") return "-";
  if (["-1", -1, "NA"].includes(val)) return "N/A";
  let formattedVal: string = numberVal.toFixed(2);
  if (suffix) formattedVal += suffix;
  return formattedVal;
}

export function memoryFormat(bytes: number | string | undefined): string {
  const k = 1000;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (bytes == "-" || bytes === undefined) return "-";
  const val = Number(bytes);
  if (!val || val === 0) return "0 Bytes";
  if (val < 1) return "";

  const i = Math.floor(Math.log(val) / Math.log(k));

  return parseFloat((val / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function toPercent(val: string | number, compareVal: string | number): string {
  if (+compareVal === 0) {
    return "-";
  }
  const calculatedVal: number = (+val / +compareVal) * 100;
  return `${calculatedVal.toFixed(2)}%`;
}

/**
 *
 * @param val
 * @param compareVal
 * @param isMemory
 * @returns string format containt the size + percentage
 *
 * examples:
 * input: numberWithPercentFormat(2097152000, 4194304000, true)
 * output: "2.1 GB (50.00%)"
 * input: numberWithPercentFormat(1, 2)
 * output: "1.00 (50.00%)"
 */
export function numberWithPercentFormat(val: number, compareVal: number, isMemory = false): string {
  const formattedVal: string = isMemory ? memoryFormat(val) : numberFormat(val);
  if (["-", "0.00", "0 Bytes"].includes(formattedVal)) return formattedVal;
  const formattedCalcVal = toPercent(val, compareVal as number | string);
  return `${formattedVal} (${formattedCalcVal})`;
}

export function onlyPercentFormat(val: number | null, compareVal: number): string {
  if (val == null) {
    return "-";
  }
  const formattedCalcVal = toPercent(val, compareVal as number | string);
  return `${formattedCalcVal}`;
}

export function idleGpuDurationFormat(allSeconds: number | undefined): string {
  if (typeof allSeconds === "undefined" || allSeconds < 45) {
    return "-";
  }
  const seconds = Math.floor(allSeconds % 60);
  const allMinutes = allSeconds / 60;
  const minutes = Math.floor(allMinutes % 60);
  const hours = Math.floor(allMinutes / 60);
  return `${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(seconds)}`;
}

function doubleDigit(num: number): string {
  return num > 9 ? num.toString() : "0" + num;
}
