export enum errorMessages {
  ENTER_A_VALUE = "Enter a value",
  SELECT_A_VALUE = "Select a value",
  SELECT_A_MODE = "Select a mode",
  SELECT_NODE_TYPE = "Select node type",
  VALUE_ABOVE_ZERO = "Enter a value above 0",
  NUMBER_GREATER_THAN_ZERO = "Enter a number above 0",
  DURATION_GREATER_THAN_ZERO = "Enter a limit higher than 0 minutes",
  VALID_FORMAT = "Enter a name starting with a lowercase letter and including only a-z, 0-9, -",
  VALID_NAMESPACE = "Enter a namespace starting with a lowercase letter and including only a-z, 0-9, -",
  NAME_NOT_EMPTY = "Enter a name",
  PATH_NOT_EMPTY = "Enter a path",
  CLAIM_NOT_EMPTY = "Select a claim",
  KEY_NOT_EMPTY = "Enter a key",
  STORAGE_CLASS_NOT_EMPTY = "Select a storage class",
  URL_NOT_EMPTY = "Enter a URL",
  SELECT_TYPE = "Select a type",
  SELECT_DEPARTMENT = "Select a department",
  SELECT_TEMPLATE = "Select a template or start from scratch",
  SELECT_ENVIRONMENT = "Select an environment",
  SELECT_DISTRIBUTION = "Select a distribution",
  SELECT_COMPUTE = "Select a resource",
  SELECT_SOURCE = "Select a resource",
  SELECT_VERSION = "Select a version",
  INVALID_DIRECTORY_PATH = "Enter a valid path",
  INVALID_CONTAINER_PATH = "Invalid container path",
  INVALID_URL = "Enter a valid URL",
  UNIQUE_NAME = "This name already exists",
  UNIQUE_LABELS = "This value already exists for this key",
  NAME_ALREADY_EXIST = "This name already exists",
  SERVER_NAME_OR_IP_EMPTY = "Enter a name or IP",
  SELECT_CREDENTIALS = "Select credentials",
  USERNAME_NOT_EMPTY = "Enter a username",
  PASSWORD_NOT_EMPTY = "Enter a password",
  INVALID_PASSWORD_FORMAT = "Please use lower case (a-z), upper case (A-Z), numbers (0-9) and special characters (!@#$%^&*)",
  PASSWORD_DOES_NOT_MATCH = "Passwords do not match",
  SECRET_NOT_EMPTY = "Enter a secret",
  KEY_REGEXP_VALIDATION = "Key must fit the following regular expression",
  VALUE_REGEXP_VALIDATION = "Value must fit the following regular expression",
  ENETER_RESOURCE = "Enter a resource",
  ENTER_QUANTITY = "Enter a quantity",
  RESOURCE_ALREADY_USED = "This resource already exists",
  ENTENDED_RESOURCE_NAME_NOT_VALID = "Enter a valid resource",
  INVALID_QUANTITY = "Invalid quantity",
  SUPPLEMENTAL_GROUPS_NOT_VALID = "Supplementary groups must be a comma-separated list of numbers",
  LIMIT_EQUAL_OR_HIGHER_THAN_REQUEST = "Enter a limit equal to or higher than the request",
  ENTER_A_LIMIT_ABOVE_ZERO = "Enter a limit above 0",
  FORM_INCOMPLETE = "Please review and fix the issues in the form",
  EMAIL_NOT_EMPTY = "Enter an email",
  EMAIL_NOT_VALID = "Enter a valid email address",
  EMAIL_ALREADY_EXIST = "This email already exists",
  SELECT_A_FRAMEWORK = "Select a framework",
  SELECT_A_TYPE = "Select at least one type",

  //Projects
  PERMISSIONS_SELECT_NOT_EMPTY = "Set at least one user with access to the project",
  SELECT_PROJECT = "Select a project",
  // Access rules
  SUBJECT_NOT_EMPTY = "Select a subject",
  ROLE_TYPE_NOT_EMPTY = "Select a role",
  SCOPE_TYPE_NOT_EMPTY = "Select a scope",
  SUBJECT_ID_NOT_EMPTY = "Select or type",
  VALID_TIME_FRAME = "Enter a timeframe of at least 0 seconds",
  UNSAVED_RULE = "Unsaved rule",
  ADD_VALID_POLICY_YAML = "Add a valid policy YAML",
  EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM = "Enter a number equal to or higher than the minimum",
}
