<template>
  <section class="enable-inactivity-logout">
    <boolean-setting :setting="setting" @update-setting="updateSetting" />
    <transition name="fade" :duration="300">
      <div v-if="setting.value">
        <input-setting
          v-if="inactivityTimeoutSetting"
          :setting="inactivityTimeoutSetting"
          tooltip="In case your login session is inactive for this period of time, the system will log you out automatically."
          @update-setting="updateSetting"
        />
        <boolean-setting v-if="excludeViewerSetting" :setting="excludeViewerSetting" @update-setting="updateSetting" />
      </div>
    </transition>
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Components
import { BooleanSetting } from "../../boolean-setting";
import { InputSetting } from "../../input-setting";
// Models
import type { Setting } from "@/swagger-models/backend-client";
import { SettingKeys } from "@/models/setting.model";
// Stores
import { useSettingStore } from "@/stores/setting.store";

export default defineComponent({
  name: "enable-inactivity-logout",
  components: {
    BooleanSetting,
    InputSetting,
  },
  emits: ["update-setting"],
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      settingStore: useSettingStore(),
    };
  },
  computed: {
    inactivityTimeoutSetting(): Setting | undefined {
      return this.settingStore.settingList.find(
        (setting: Setting) => setting.key === SettingKeys.InactivityMinutesTillLogout,
      );
    },
    excludeViewerSetting(): Setting | undefined {
      return this.settingStore.settingList.find(
        (setting: Setting) => setting.key === SettingKeys.ExcludeViewerInactivityLogout,
      );
    },
  },
  methods: {
    updateSetting(key: string, value: string | boolean | number): void {
      this.$emit("update-setting", key, value);
    },
  },
});
</script>
