<template>
  <section class="workload-event-history">
    <runai-event-history-table
      :columns="columns"
      :events="events"
      :filters="filterBy"
      :loading="loading"
      @update:filters="updateFilters"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { QTableProps } from "quasar";

// cmps
import { RunaiEventHistoryTable } from "@/components/runai-event-history-table";

// model
import type { IFilterBy } from "@/models/filter.model";
import type { IWorkspaceList } from "@/models/workspace.model";
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import type { ITrainingList } from "@/models/training.model";

export default defineComponent({
  components: {
    RunaiEventHistoryTable,
  },
  props: {
    workload: {
      type: Object as PropType<IWorkspaceList | ITrainingList>,
      required: true,
    },
    columns: {
      type: Array as PropType<QTableProps["columns"]>,
      required: true,
    },
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    events: {
      type: Array as PropType<Array<WorkloadHistoryResponseEntriesInner>>,
      required: true,
    },
  },
  methods: {
    async updateFilters(filterBy: IFilterBy): Promise<void> {
      this.$emit("update-filter-by", filterBy);
    },
  },
});
</script>
<style lang="scss" scoped></style>
