<template>
  <div class="multi-select-wrapper">
    <q-select
      ref="multiSelect"
      use-input
      use-chips
      multiple
      clearable
      no-error-icon
      reactive-rules
      options-dense
      clear-icon="fa-regular fa-xmark"
      :options="filterOptions"
      :label="label"
      :model-value="selectedOptions"
      :loading="loading"
      :rules="[multiSelectValidation]"
      @new-value="createNewValue"
      @filter="filterFn"
      @clear="onClear"
      @update:model-value="onSelect"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

//models
import type { ISelectOption } from "@/models/global.model";
import { errorMessages } from "@/common/error-message.constant";
const NEW_LABEL_PREFIX = "(New";
export default defineComponent({
  emits: ["add-option", "options-select"],
  props: {
    options: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [],
    },
    selectedOptions: {
      type: Array as PropType<ISelectOption[]>,
      default: () => [],
    },
    label: {
      type: String as PropType<string>,
      default: "",
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    newOptionLabel: {
      type: String as PropType<string>,
      default: "option",
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string>,
      default: errorMessages.SELECT_A_VALUE,
    },
  },
  data() {
    return {
      filterOptions: this.options as ISelectOption[],
    };
  },
  methods: {
    multiSelectValidation(options: ISelectOption[]): boolean | string {
      return !this.required || options.length > 0 || this.errorMessage;
    },
    resetInput() {
      const selectRef = this.$refs.multiSelect as HTMLFormElement;
      selectRef.updateInputValue("");
    },
    onClear(): void {
      this.$emit("options-select", []);
    },
    onSelect(options: ISelectOption[]): void {
      let updatedOptions: ISelectOption[] = [];
      if (options) {
        updatedOptions = options.filter((option: ISelectOption) => {
          if (option.label.includes(NEW_LABEL_PREFIX)) {
            this.createNewValue(option.value as string);
            return false;
          } else {
            return true;
          }
        });
      }
      this.$emit("options-select", updatedOptions);
    },
    createNewValue(val: string) {
      if (val.length > 0) {
        this.$emit("add-option", val, () => {
          this.resetInput();
        });
      }
    },
    filterFn(val: string, update: (func: { (): void }) => void) {
      update(() => {
        if (val === "") {
          this.filterOptions = [...this.options];
        } else {
          const needle = val.toLowerCase();
          this.filterOptions = this.options.filter(
            (option: ISelectOption) => option.label.toLowerCase().indexOf(needle) > -1,
          );
          if (this.filterOptions.length === 0) {
            this.filterOptions = [{ label: `${val} (New ${this.newOptionLabel})`, value: val }];
          }
        }
      });
    },
  },
});
</script>
<style lang="scss">
.multi-select-wrapper {
  .q-icon {
    font-size: 16px;
  }

  .q-field--auto-height.q-field--labeled .q-field__native {
    max-height: 37px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 2px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $black-54;
    }
    .q-field--labeled .q-field__native {
      padding-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped></style>
