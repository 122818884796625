<template>
  <section class="login-with-sso text-body2">
    <boolean-setting
      :setting="localSetting"
      @update-setting="updateLocalSetting"
      :disabled="isSSOUser"
      disabled-message="To disable SSO, sign in as a local user"
    />
    <transition name="fade" :duration="300">
      <q-form>
        <div v-if="localSetting.value">
          <div class="row items-start" v-if="!isSSO">
            <sso-setting-input label="Administrator Email" v-model="adminUser" :rules="[isRequired, isValidEmail]" />
            <q-btn round flat icon="far fa-circle-question" size="sm">
              <q-menu anchor="bottom middle" self="top middle" class="q-pa-sm" max-width="350px">
                Once you config SSO all the users are de-activated. First admin will be the only user with admin
                permissions, as explained
                <a
                  href="https://docs.run.ai/latest/admin/runai-setup/authentication/sso/?h=saml#prerequisites"
                  target="_blank"
                >
                  here
                </a>
              </q-menu>
            </q-btn>
          </div>
          <div class="q-my-md row justify-between items-center" :style="{ width: '500px' }">
            <div>Select the SSO protocol</div>
            <runai-radio-options
              v-model="selectedProtocol"
              @update:model-value="updateProtocol"
              :options="protocolOptions"
              horizontal
              :disable="isSSO"
            />
          </div>

          <!-- Saml -->
          <template v-if="selectedProtocol === 'saml'">
            <template v-if="isSSO">
              <div class="q-mb-md">
                <div>Redirect URI:</div>
                <runai-code-block class="text-subtitle1" :code-html="authStore.tenant.ssoRedirectUri" />
              </div>
              <div class="q-mb-md">
                <div>Entity ID:</div>
                <runai-code-block class="text-subtitle1" :code-html="authStore.tenant.ssoEntityId" />
              </div>
            </template>
            <div v-else class="row items-start">
              <sso-setting-input label="Metadata XML URL" v-model="metadataXmlUrl" :rules="[isRequired]" />
              <q-btn round flat icon="far fa-circle-question" size="sm">
                <q-menu anchor="bottom middle" self="top middle" class="q-pa-sm" max-width="350px">
                  A URL of SAML 2.0 endpoint, as explained
                  <a
                    href="https://docs.run.ai/latest/admin/runai-setup/authentication/sso/?h=saml#prerequisites"
                    target="_blank"
                  >
                    here
                  </a>
                </q-menu>
              </q-btn>
            </div>
          </template>

          <!-- Oidc -->
          <template v-else>
            <div v-if="isSSO" class="q-mb-md">
              <div>Redirect URI:</div>
              <runai-code-block class="text-subtitle1" :code-html="authStore.tenant.ssoRedirectUri" />
            </div>
            <template v-else>
              <sso-setting-input label="Client ID" v-model="clientId" :rules="[isRequired]" />
              <sso-setting-input label="Client Secret" v-model="clientSecret" :rules="[isRequired]" />
              <sso-setting-input label="Discovery Document URL" v-model="wellKnownOidcConfigUrl" :rules="[isRequired]" />
            </template>
          </template>
          <div class="text-subtitle1 q-mb-md">Attributes mapping</div>
          <template v-if="mappers">
            <sso-setting-input
              v-for="mapper in mapperOptions"
              :key="mapper.key"
              :label="getMapperLabel(mapper.key)"
              v-model="mappers[mapper.key]"
            />
          </template>

          <div class="row justify-end">
            <q-btn
              :loading="isSSO ? updating : saving"
              :label="isSSO ? 'update' : 'save'"
              color="primary"
              @click="() => (isSSO ? update() : save())"
              size="sm"
              class="q-mb-md"
            />
          </div>
        </div>
      </q-form>
    </transition>
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { Setting } from "@/swagger-models/backend-client";
import type { ISelectOption } from "@/models/global.model";
// Components
import { BooleanSetting } from "../../boolean-setting";
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { SsoSettingInput } from "./sso-setting-input";
import { RunaiCodeBlock } from "@/components/common/runai-code-block";
// Utils
import { deepCopy } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { errorMessages } from "@/common/error-message.constant";
import { isValidEmail } from "@/common/form.validators";
// Stores
import { useAuthStore } from "@/stores/auth.store";
// Services
import { ssoService } from "@/services/control-plane/sso.service/sso.service";

const defaultMappers: Record<string, string> = {
  "GID Mapper": "",
  "Groups Mapper": "",
  "SupplementaryGroups Mapper": "",
  "UID Mapper": "",
};

enum EProtocolValue {
  SAML = "saml",
  OIDC = "oidc",
}

enum EProtocolLabel {
  SAML = "SAML",
  OIDC = "OpenID Connect",
}

enum EOidcFields {
  clientId = "clientId",
  clientSecret = "clientSecret",
  wellKnownOidcConfigUrl = "wellKnownOidcConfigUrl",
}

enum ESamlFields {
  metadataXmlUrl = "metadataXmlUrl",
}

export default defineComponent({
  name: "login-with-sso",
  components: {
    BooleanSetting,
    RunaiRadioOptions,
    SsoSettingInput,
    RunaiCodeBlock,
  },
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      localSetting: deepCopy(this.setting) as Setting,
      protocolOptions: [
        {
          label: EProtocolLabel.SAML,
          value: EProtocolValue.SAML,
        },
        {
          label: EProtocolLabel.OIDC,
          value: EProtocolValue.OIDC,
        },
      ] as ISelectOption[],
      selectedProtocol: null as string | null,
      mappers: null as Record<string, string> | null,
      adminUser: "" as string,
      metadataXmlUrl: "" as string,
      clientId: "" as string,
      clientSecret: "" as string,
      wellKnownOidcConfigUrl: "" as string,
      updating: false as boolean,
      saving: false as boolean,
    };
  },
  async created() {
    this.selectedProtocol = this.getSelectedProtocol();
    this.mappers = this.isSSO ? await ssoService.getSsoMappers(this.selectedProtocol) : defaultMappers;
  },
  computed: {
    mapperOptions(): Array<{ key: string; value: string }> {
      if (!this.mappers) return [];
      return Object.entries(this.mappers).map(([key, value]) => ({
        key,
        value,
      }));
    },
    isSSO(): boolean {
      return this.authStore.isSSO;
    },
    isSSOUser(): boolean {
      return this.authStore.isCurrentUserSSO;
    },
  },
  methods: {
    updateLocalSetting(key: string, value: string): void {
      this.localSetting.value = value;
      if (!value) {
        this.updateSetting(key, false);
        this.resetOidcFields();
        this.resetSamlFields();
        this.adminUser = "";
      }
    },
    updateSetting(key: string, value: boolean): void {
      this.$emit("update-setting", key, value);
    },
    getSelectedProtocol(): string {
      return this.authStore.tenant.ssoRedirectUri?.includes(EProtocolValue.OIDC)
        ? EProtocolValue.OIDC
        : EProtocolValue.SAML;
    },
    getMapperLabel(mapper: string): string {
      return mapper.slice(0, mapper.indexOf(" "));
    },
    updateProtocol(value: string): void {
      value === EProtocolValue.SAML ? this.resetOidcFields() : this.resetSamlFields();
    },
    resetSamlFields(): void {
      this.metadataXmlUrl = "";
    },
    resetOidcFields(): void {
      this.clientId = "";
      this.clientSecret = "";
      this.wellKnownOidcConfigUrl = "";
    },
    async save(): Promise<void> {
      const request: Record<string, string | Record<string, string> | null> = {
        adminUser: this.adminUser,
        mappers: this.mappers,
      };
      try {
        this.saving = true;
        if (this.selectedProtocol === EProtocolValue.SAML) {
          request[ESamlFields.metadataXmlUrl] = this.metadataXmlUrl;
          await ssoService.configureSamlSso(request);
        } else {
          request[EOidcFields.clientId] = this.clientId;
          request[EOidcFields.clientSecret] = this.clientSecret;
          request[EOidcFields.wellKnownOidcConfigUrl] = this.wellKnownOidcConfigUrl;
          await ssoService.configureOAuthSso(request);
        }

        this.setting.key && this.updateSetting(this.setting.key, true);
        await this.authStore.loadTenant();
      } catch (err: unknown) {
        console.error(err);
        this.$q.notify(alertUtil.getError("Failed to configure SSO"));
      } finally {
        this.saving = false;
      }
    },
    async update(): Promise<void> {
      try {
        if (!this.selectedProtocol || !this.mappers) return;
        this.updating = true;
        await ssoService.updateSsoMappers(this.selectedProtocol, this.mappers);
        this.$q.notify(alertUtil.getSuccess("Attributes mapping configured successfully"));
      } catch (err: unknown) {
        console.error(err);
        this.$q.notify(alertUtil.getError("Failed to configure attributes mapping"));
      } finally {
        this.updating = false;
      }
    },
    isRequired(val: string): string | boolean {
      return !!val || errorMessages.ENTER_A_VALUE;
    },
    isValidEmail(val: string): string | boolean {
      return isValidEmail(val) || errorMessages.EMAIL_NOT_VALID;
    },
  },
});
</script>
