<template>
  <runai-form-wrapper class="department-edit-form" :form-state="editDepartment">
    <q-form ref="departmentForm">
      <section class="create-department-form q-pt-md">
        <runai-expansion-wrapper>
          <runai-expansion-item
            label="Department name"
            default-opened
            @after-show="doValidation"
            @before-hide="departmentValidation(editDepartment)"
            :section-invalid="!!errorsCount.name"
          >
            <template #subheader>
              <span>
                {{ editDepartment.name || "&lt;Department name&gt;" }}
              </span>
            </template>
            <runai-name-validation
              :disable="readOnly"
              v-model="editDepartment.name"
              :rules="[isEmpty, isValidFormat, isNameUnique]"
            />
          </runai-expansion-item>

          <runai-expansion-item label="Quota management" v-if="!isOnlyDefaultNodePool" default-opened>
            <template #subheader>
              <section>{{ getNodePoolsSubheader }}</section>
            </template>
            <section>
              <div>
                <q-toggle
                  :disable="readOnly"
                  left-label
                  label="Allow department to go over quota"
                  :model-value="isOverQuotaOn"
                  @update:model-value="allowGpuOverQuotaChanged"
                />
                <runai-tooltip
                  tooltip-position="right"
                  width="320px"
                  tooltip-text="Departments that have quota overage disabled are limited to
                    using the GPUs assigned to them. Departments that have it
                    enabled can use GPUs beyond their quota, if needed and
                    available."
                ></runai-tooltip>
              </div>
            </section>
            <section class="q-my-lg">Click the boxes below to set the quotas per node pool</section>
            <department-node-pools-table
              :is-cpu-enabled="displayCpuDetails"
              v-model="editDepartment"
              :read-only="readOnly"
            ></department-node-pools-table>
          </runai-expansion-item>
          <template v-else>
            <runai-expansion-item default-opened label="Quota management">
              <template #subheader>
                <section>{{ getResourcesSubheader }}</section>
              </template>
              <section>
                <div>
                  <q-toggle
                    :disable="readOnly"
                    left-label
                    label="Allow department to go over quota"
                    :model-value="isOverQuotaOn"
                    @update:model-value="allowGpuOverQuotaChanged"
                  />
                  <q-btn icon="far fa-circle-question" size="sm" flat round>
                    <q-menu class="q-pa-sm" max-width="320px" anchor="top middle" self="bottom middle">
                      Departments that have quota overage disabled are limited to using the GPUs assigned to them.
                      Departments that have it enabled can use GPUs beyond their quota, if needed and available.
                    </q-menu>
                  </q-btn>
                </div>
              </section>
              <div class="q-my-xl">
                <span class="block q-my-md">Click the boxes below to set the project’s quota </span>
                <span class="text-italic"
                  >For more information , see the
                  <a target="_blank" href="https://docs.run.ai/Researcher/scheduling/the-runai-scheduler/"
                    >Run:ai Scheduler</a
                  >
                  guide</span
                >
              </div>
              <section class="node-pools-table">
                <department-quota-table
                  :cpu="editDepartment.nodePoolsResources[0].cpu"
                  :gpu="editDepartment.nodePoolsResources[0].gpu"
                  :memory="editDepartment.nodePoolsResources[0].memory"
                  :is-cpu-enabled="displayCpuDetails"
                  @cpu-change="handleCpuVal"
                  @gpu-change="handleGpuVal"
                  @memory-change="handleMemoryVal"
                  :read-only="readOnly"
                />
              </section>
            </runai-expansion-item>
          </template>
        </runai-expansion-wrapper>
      </section>
      <section class="row items-center q-mt-md">
        <q-field class="col-4 form-hint no-padding" :model-value="displayFormHint" :rules="[isFormIncomplete]"></q-field>
        <div v-if="readOnly" class="buttons q-ml-auto">
          <q-btn class="q-px-lg" label="close" color="primary" @click="$emit('cancel')"></q-btn>
        </div>
        <div v-if="!readOnly" class="buttons q-ml-auto">
          <q-btn class="q-mx-sm" flat label="cancel" color="primary" @click="$emit('cancel')"></q-btn>
          <q-btn
            class="q-px-lg"
            :label="$route.params.id ? 'save' : 'create department'"
            color="primary"
            @click="createDepartment"
          ></q-btn>
        </div>
      </section>
    </q-form>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { errorMessages } from "@/common/error-message.constant";

// model
import type { IDepartment, IDepartmentValidation } from "@/models/department.model";
import type { nullishOrNumber } from "@/models/number.type";
import type { IUser } from "@/models/user.model";
import {
  EResourceLabel,
  EResourceState,
  EResourceType,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
} from "@/models/resource.model";

// cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { DepartmentNodePoolsTable } from "@/components/department/department-node-pools-table";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import { isNotEmpty, isValidEntityName, isValidNumber, required } from "@/common/form.validators";
import { DepartmentQuotaTable } from "@/components/department/department-quota-table";

// services
import { deepCopy } from "@/utils/common.util";
import { resourceUtil } from "@/utils/resource.util";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { departmentUtil } from "@/utils/department.util";

export default defineComponent({
  components: {
    DepartmentQuotaTable,
    DepartmentNodePoolsTable,
    RunaiExpansionItem,
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    RunaiTooltip,
    RunaiNameValidation,
  },
  emits: ["save-department", "cancel"],
  props: {
    department: {
      type: Object as PropType<IDepartment>,
      required: true,
    },
    displayCpuDetails: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOnlyDefaultNodePool: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    clusterUuid: {
      type: String as PropType<string>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  RESOURCE_STATE: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
  data() {
    return {
      editDepartment: deepCopy(this.department) as IDepartment,
      departmentForm: null as HTMLFormElement | null,
      isSubmitBtnClicked: false as boolean,
      errorsCount: {} as IDepartmentValidation,
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      usersList: [] as Array<IUser>,
      groupsList: [] as Array<IUser>,
      applicationsList: [] as Array<IUser>,
      isOverQuotaOn: true as boolean,
    };
  },
  created() {
    this.isOverQuotaOn = this.editDepartment.maxAllowedGpus === RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
    this.allowGpuOverQuotaChanged(this.isOverQuotaOn);
  },
  mounted() {
    this.departmentForm = this.$refs.departmentForm as HTMLFormElement;
  },
  computed: {
    getNodePoolsSubheader(): string {
      const gpuDisplayValue = resourceUtil.getResourcesDisplayValue(
        this.editDepartment.nodePoolsResources,
        EResourceType.GPU,
      );
      const cpuDisplayValue = resourceUtil.getResourcesDisplayValue(
        this.editDepartment.nodePoolsResources,
        EResourceType.CPU,
      );
      const memoryDisplayValue = resourceUtil.getResourcesDisplayValue(
        this.editDepartment.nodePoolsResources,
        EResourceType.MEMORY,
      );
      return `${EResourceLabel.GPU} ${gpuDisplayValue} / ${EResourceLabel.CPU} ${cpuDisplayValue} / ${EResourceLabel.MEMORY} ${memoryDisplayValue}`;
    },
    getResourcesSubheader(): string {
      const gpuDisplayValue = resourceUtil.getResourceDisplayValue(
        this.editDepartment.resources.gpu.deserved,
        EResourceType.GPU,
      );
      const cpuDisplayValue = resourceUtil.getResourceDisplayValue(
        this.editDepartment.resources.cpu.deserved,
        EResourceType.CPU,
      );
      const memoryDisplayValue = resourceUtil.getResourceDisplayValue(
        this.editDepartment.resources.memory.deserved,
        EResourceType.MEMORY,
      );
      return `${EResourceLabel.GPU} ${gpuDisplayValue} / ${EResourceLabel.CPU} ${cpuDisplayValue} / ${EResourceLabel.MEMORY} ${memoryDisplayValue}`;
    },
  },
  methods: {
    async createDepartment(): Promise<void> {
      this.isSubmitBtnClicked = true;
      const success = await this.validate();
      if (!success) {
        this.showHint();
        return;
      }
      this.$emit("save-department", this.editDepartment);
    },
    validate(): Promise<boolean> {
      this.departmentValidation(this.editDepartment);
      return this.departmentForm?.validate() && Object.keys(this.errorsCount).length === 0;
    },
    isEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.NAME_NOT_EMPTY;
    },
    isValidFormat(val: string): boolean | string {
      return isValidEntityName(val) || errorMessages.VALID_FORMAT;
    },
    isRequired(val: string): boolean | string {
      return required(val + "") || errorMessages.ENTER_A_VALUE;
    },
    async isNameUnique(name: string): Promise<boolean | string> {
      const res = await departmentService.isNameUnique(this.clusterUuid, name, this.editDepartment.id || null);
      return res || errorMessages.UNIQUE_NAME;
    },
    allowGpuOverQuotaChanged(isOverQuotaOn: boolean): void {
      this.isOverQuotaOn = isOverQuotaOn;
      departmentUtil.updateMaxAllowedOverQuota(this.editDepartment, this.displayCpuDetails, isOverQuotaOn);
    },
    handleGpuVal(gpuNewVal: number): void {
      if (
        this.editDepartment.nodePoolsResources[0].gpu.maxAllowed !== RESOURCE_MAX_ALLOWED_INFINITE_VALUE &&
        gpuNewVal >= 0
      ) {
        this.editDepartment.nodePoolsResources[0].gpu.maxAllowed = gpuNewVal;
        this.editDepartment.maxAllowedGpus = gpuNewVal;
      }
      this.editDepartment.nodePoolsResources[0].gpu.deserved = gpuNewVal;
      this.editDepartment.deservedGpus = gpuNewVal;
    },
    handleMemoryVal(memoryNewVal: number | null): void {
      if (this.editDepartment.nodePoolsResources[0].memory.maxAllowed !== RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
        this.editDepartment.nodePoolsResources[0].memory.maxAllowed = memoryNewVal;
      }
      this.editDepartment.nodePoolsResources[0].memory.deserved = memoryNewVal;
    },
    handleCpuVal(cpuNewVal: number | null): void {
      if (this.editDepartment.nodePoolsResources[0].cpu.maxAllowed !== RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
        this.editDepartment.nodePoolsResources[0].cpu.maxAllowed = cpuNewVal;
      }
      this.editDepartment.nodePoolsResources[0].cpu.deserved = cpuNewVal;
    },
    doValidation(): void {
      if (!this.isSubmitBtnClicked) return;
      this.validate();
    },
    departmentValidation(department: IDepartment): void {
      const errors: IDepartmentValidation = {};

      // validate name
      if ([isValidEntityName, isNotEmpty].some((func) => !func(department.name))) errors.name = true;

      // validate gpu
      const deservedGpus: nullishOrNumber = department.resources.gpu.deserved;
      if (!required(deservedGpus + "") || !isValidNumber(deservedGpus + "")) errors.gpu = true;

      // validate cpu
      const cpuDeserved: nullishOrNumber = this.editDepartment.resources.cpu.deserved;
      if (cpuDeserved !== null && !isValidNumber(cpuDeserved + "")) errors.cpu = true;

      // validate memory
      const memoryDeserved: nullishOrNumber = this.editDepartment.resources.memory.deserved;
      if (memoryDeserved !== null && !isValidNumber(memoryDeserved + "")) errors.memory = true;

      this.errorsCount = errors;
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>

<style lang="scss" scoped>
.department-edit-form {
  .create-department-form {
    .number-input {
      width: 220px;
    }
  }
}
</style>
