<template>
  <section class="runai-rename-modal">
    <runai-base-dialog :model-value="true" @close="$emit('close')" size="width-sm" :no-padding="true">
      <template #header>Contact Us</template>

      <template #body>
        <div class="rename-workspace-body q-px-lg q-py-lg">
          <p>Hey Run:ai,</p>
          <q-form id="contact-us-form" ref="elContactUsForm">
            <q-input
              v-model="contactUsText"
              autogrow
              placeholder="Describe how we can help"
              autofocus
              :rules="[required]"
              no-error-icon
              error-message="Let us know how can we help you"
              class="contact-us"
            />
          </q-form>
        </div>
      </template>

      <template #footer>
        <q-btn label="Cancel" color="primary" aid="rename-modal-cancel-button" flat @click="$emit('close')" />
        <q-btn label="Send" color="primary" aid="rename-modal-cancel-button" @click="send" />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
// common
import { defineComponent } from "vue";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

// constants
import { errorMessages } from "@/common/error-message.constant";
import { supportService } from "@/services/control-plane/support.service/support.service";
import { alertUtil } from "@/utils/alert.util";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["close", "send"],
  data() {
    return {
      contactUsText: "" as string,
    };
  },
  methods: {
    async send() {
      const isValid: boolean = await (this.$refs.elContactUsForm as HTMLFormElement).validate();
      if (!isValid) return;
      try {
        await supportService.contactUs(this.contactUsText);
        this.$q.notify(alertUtil.getSuccess("Got it! We'll get back to you soon via email."));
      } catch (e) {
        this.$q.notify(alertUtil.getError("Message couldn't be sent. Try again in a few minutes."));
      }
      this.$emit("close");
    },
    required(val: string): boolean | string {
      return !!val || errorMessages.ENTER_A_VALUE;
    },
  },
});
</script>
