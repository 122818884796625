<template>
  <div class="column items-center q-pt-md">
    <project-edit-form
      v-if="!loading"
      :is-project-submitting="isProjectSubmitting"
      :project="project"
      :is-department-enabled="isDepartmentEnabled"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
// cmps
import { ProjectEditForm } from "@/components/project/project-edit-form";
// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
// stores
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useSettingStore } from "@/stores/setting.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useAuthStore } from "@/stores/auth.store";
// models
import type { INodePoolResources, IProject, IProjectCreate } from "@/models/project.model";
import {
  OVER_QUOTA_ENABLED_VALUE,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
  OVER_QUOTA_DISABLED_VALUE,
} from "@/models/resource.model";
import { ConflictError, HttpErrorResponse } from "@/models/http-response.model";
import { DEFAULT_RESOURCES_VALUE } from "@/models/resource.model";
import { NODE_AFFINITY_DEFAULT_VALUE } from "@/models/project.model";
import { projectUtil } from "@/utils/project.util";
import type { IDepartment } from "@/models/department.model";
import { DEFAULT_DEPARTMENT_NAME } from "@/models/department.model";
import { useNodePoolStore } from "@/stores/node-pool.store";

export default defineComponent({
  components: { ProjectEditForm },
  data() {
    return {
      loading: false as boolean,
      isProjectSubmitting: false as boolean,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      projectStore: useProjectStore(),
      settingStore: useSettingStore(),
      departmentStore: useDepartmentStore(),
      nodePoolStore: useNodePoolStore(),
      project: {
        name: "",
        namespace: "",
        nodePoolsResources: [],
        defaultNodePools: [],
        resources: { ...DEFAULT_RESOURCES_VALUE },
        nodeAffinity: { ...NODE_AFFINITY_DEFAULT_VALUE },
      } as IProject | IProjectCreate,
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.nodePoolStore.loadNodePoolsCount();
      await this.departmentStore.loadDepartments({ withMetrics: false, withAccessRules: false }, {}, true);
      await this.loadProject();
    } catch (err) {
      console.log(err);
      this.appStore.setFallback(true);
    } finally {
      this.loading = false;
      this.appStore.setPageLoading(false);
    }
  },
  computed: {
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    projectId(): number | null {
      return +this.$route.params.id;
    },
    departmentId(): number | undefined {
      return this.project.departmentId;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
    isOverQuotaPriorityEnabled(): boolean {
      return this.settingStore.isOverQuotaPriorityEnabled;
    },
    isSsoEnabled(): boolean {
      return this.authStore.isSSO;
    },
  },
  methods: {
    async loadProject(): Promise<void> {
      const { projects } = this.projectStore;

      if (projects.length === 0) {
        await this.projectStore.loadProjects({
          withMetrics: false,
          withNamespace: false,
          withAccessRules: false,
        });
      }

      if (this.isNewProject) {
        await this.loadNewProject();
      } else if (this.projectId) {
        this.loadExistingProject(this.projectId);
      }
    },
    async loadNewProject(): Promise<void> {
      let defaultDepartmentId;

      if (this.settingStore.isDepartmentEnabled) {
        //select 'default' department if its under user scope, otherwise select the first department.
        const defaultDepartment = this.departmentStore.departmentList.find(
          (department: IDepartment) => department.name === DEFAULT_DEPARTMENT_NAME,
        );
        defaultDepartmentId = defaultDepartment?.id || this.departmentStore.departmentList[0]?.id;
      } else if (this.departmentStore.departmentList.length > 0) {
        defaultDepartmentId = this.departmentStore.departmentList[0]?.id;
      }

      const options: Partial<IProjectCreate> = {
        departmentId: defaultDepartmentId,
      };

      this.project = await this.projectStore.getEmptyProjectModel(this.isCpuEnabled, options);
    },
    loadExistingProject(projectId: number): void {
      const project = this.projectStore.projectById(projectId);

      if (!this.isOverQuotaPriorityEnabled && project) {
        // Backend might mutate overQuotaWeight in some cases, need to assign the correct value based on maxAllowed
        project.nodePoolsResources.forEach((nodePool: INodePoolResources) => {
          nodePool.gpu.overQuotaWeight =
            nodePool.gpu.maxAllowed === RESOURCE_MAX_ALLOWED_INFINITE_VALUE
              ? OVER_QUOTA_ENABLED_VALUE
              : OVER_QUOTA_DISABLED_VALUE;
        });
      }

      if (project) {
        this.project = project;
      }
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        await this.$router.back();
      }
    },
    async onSave(project: IProject | IProjectCreate): Promise<void> {
      this.isProjectSubmitting = true;
      projectUtil.updateMaxAllowedOverQuota(project, this.isOverQuotaPriorityEnabled);

      if (!this.isCpuEnabled) {
        projectUtil.resetCpuResources(project);
      }

      let savedProject: IProject;
      try {
        if (this.isNewProject) {
          savedProject = await this.projectStore.createProject(project as IProjectCreate);
        } else {
          savedProject = await this.projectStore.updateProject(project as IProject);
        }

        if (this.$route.query.backToPage) {
          await this.$router.push({
            name: this.$route.query.backToPage.toString(),
          });
        } else {
          await this.$router.push({
            name: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
            query: { createdProjectId: savedProject.id },
          });
        }
        this.$q.notify(alertUtil.getSuccess(`Project ${project.name} ${this.isNewProject ? "created" : "saved"}`));
      } catch (error: unknown) {
        this.handleError(project, error);
      } finally {
        this.isProjectSubmitting = false;
      }
    },
    handleError(project: IProject | IProjectCreate, error: unknown) {
      if (error instanceof HttpErrorResponse) {
        console.error(error.serialize());
        if (error instanceof ConflictError) {
          this.$q.notify(alertUtil.getError(`Project with name ${project.name} already exists.`));
        } else {
          this.$q.notify(alertUtil.getError(error.message));
        }
      } else {
        this.$q.notify(alertUtil.getError(`Failed to ${this.isNewProject ? "create" : "save"} project`));
      }
    },
  },
});
</script>
