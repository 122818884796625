import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordName,
  type NavigationGuardNext,
} from "vue-router";
import { h } from "vue";

import { projectRoutes } from "./project.routes/project.routes";
import { environmentRoutes } from "./environment.routes";
import { departmentRoutes } from "./department.routes";
import { workspaceRoutes } from "./workspace.routes/workspace.routes";
import { trainingRoutes } from "./training.routes/training.routes";
import { modelSpecRoutes } from "./model-spec.routes/model-spec.routes";
import { dataSourceRoutes } from "./data-source.routes";
import { computeResourceRoutes } from "./compute-resource.routes";
import { workloadTemplateRoutes } from "./workload-template.routes/workload-template.routes";
import { auditRoutes } from "./audit.routes";
import { nodeRoutes } from "./node.routes";
import { credentialRoutes } from "./credential.routes";
import { roleRoutes, ROLE_ROUTE_NAMES } from "./role.routes";
import { APPLICATIONS_ROUTE_NAMES, applicationsRoutes } from "./applications.routes";
import { clusterRoutes } from "./cluster.routes";
import { useAppStore } from "@/stores/app.store";
import { useAuthStore } from "@/stores/auth.store";
import { usersRoutes } from "./users.routes";
import { licenseRoutes, LICENSE_ROUTE_NAMES } from "./license.routes";
import { WORKLOAD_ROUTE_NAMES, workloadRoutes } from "@/router/workloads.routes";
import { settingsRoutes, SETTINGS_ROUTE_NAMES } from "./settings.routes";
import { trialRoutes } from "@/router/trial.routes/trial.routes";
import { dashboardRoutes } from "@/router/dashboard.routes";
import { workloadAssetsRoutes } from "@/models/old-workload.model";
import { POLICIES_ROUTE_NAMES, policiesRoutes } from "./policy.routes";
import { jobRoutes } from "@/router/job.routes/job.routes";
import { deploymentRoutes } from "@/router/deployment.routes/deployment.routes";
import { USER_ROUTES_NAMES } from "./users.routes";
import { errorRoutes } from "./error.routes/error.routes";
import { ERROR_ROUTE_NAMES } from "./error.routes/error.routes.names";
import { oidcRoutes } from "./oidc.routes";
import { usePermissionStore } from "@/stores/permissions.store";
import { newSettingsRoutes } from "@/router/new-setttings.routes/new-settings.routes";

// a render function for an empty component
const AuthCallbackCmp = h(
  "div", // type
);

const supportBackPath: RouteRecordName[] = [
  ROLE_ROUTE_NAMES.ACCESS_RULES_INDEX,
  ROLE_ROUTE_NAMES.ROLE_INDEX,
  APPLICATIONS_ROUTE_NAMES.APPLICATIONS_INDEX,
  LICENSE_ROUTE_NAMES.LICENSE_INDEX,
  USER_ROUTES_NAMES.USER_INDEX,
  ROLE_ROUTE_NAMES.ROLE_VIEW,
  ROLE_ROUTE_NAMES.ACCESS_RULES_ROLE_VIEW,
  SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
  POLICIES_ROUTE_NAMES.POLICIES_INDEX,
  POLICIES_ROUTE_NAMES.POLICY_EDIT,
  POLICIES_ROUTE_NAMES.POLICY_VIEW,
  POLICIES_ROUTE_NAMES.POLICY_NEW,
];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    projectRoutes,
    departmentRoutes,
    workspaceRoutes,
    trainingRoutes,
    nodeRoutes,
    auditRoutes,
    environmentRoutes,
    dataSourceRoutes,
    computeResourceRoutes,
    workloadTemplateRoutes,
    credentialRoutes,
    roleRoutes,
    applicationsRoutes,
    clusterRoutes,
    usersRoutes,
    licenseRoutes,
    workloadRoutes,
    settingsRoutes,
    newSettingsRoutes,
    trialRoutes,
    dashboardRoutes,
    policiesRoutes,
    jobRoutes,
    deploymentRoutes,
    modelSpecRoutes,
    ...errorRoutes,
    oidcRoutes,
    {
      path: "/:catchAll(.*)",
      redirect: "/error/404",
    },
    {
      // We use this route for the authentication flow (in App.vue)
      // Since vue does not support defining routes without component
      // or redirect values and we don't have any logic in this
      // component - I've created an empty component.
      path: "/login/callback",
      component: AuthCallbackCmp,
    },
    {
      path: "/",
      redirect: "dashboards/now",
    },
  ],
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});
const setPrevRoute = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const shouldUpdatePrevRouteToWorkload: boolean =
    from.meta.prevRoute === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX &&
    !!from.name &&
    workloadAssetsRoutes.includes(from.name);
  if (shouldUpdatePrevRouteToWorkload) {
    to.meta.prevRoute = WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX;
  } else {
    to.meta.prevRoute = from.name;
  }
};

function isRoutePermitted(to: RouteLocationNormalized, next: NavigationGuardNext): boolean {
  const permissionStore = usePermissionStore();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  if (appStore.appLoaded && to.meta.requiresAuth && !authStore.accessToken) {
    authStore.logout();
    return false;
  }

  if (
    appStore.appLoaded &&
    to.meta.requiresAuth &&
    to.meta.minPermittedActions &&
    to.meta.resourceType &&
    !permissionStore.hasSomePermittedActions(to.meta.resourceType, to.meta.minPermittedActions)
  ) {
    next({ name: ERROR_ROUTE_NAMES.NO_ROLES });
    return false;
  }

  return true;
}

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const appStore = useAppStore();

  setPrevRoute(to, from);

  if (!isRoutePermitted(to, next)) return;

  appStore.setPageLoading(true);
  return next();
});

router.afterEach((to: RouteLocationNormalized) => {
  const appStore = useAppStore();
  appStore.setFallback(false);
  if (to.name && !supportBackPath.includes(to.name)) {
    appStore.setLastBackPath(to.path);
  }
});

export default router;
