import Trainingindex from "@/views/training/training-index.vue";
import TrainingNewApp from "@/views/training/creation/training-new-app.vue";
import TrainingNew from "@/views/training/creation/training-new.vue";

import TrainingAssetsEdit from "@/views/training/creation/training-assets-edit.vue";
import TrainingAssetsApp from "@/views/training/creation/training-assets-app.vue";

import { ResourceType, Action } from "@/swagger-models/authorization-client";

import { TRAINING_ROUTE_NAMES } from "./training.routes.names";
import type { RunaiRouteRecord } from "vue-router";

export const trainingRoutes: RunaiRouteRecord = {
  path: "/trainings",
  component: () => import("../../views/training/training-app.vue"),
  children: [
    {
      path: "",
      name: TRAINING_ROUTE_NAMES.TRAINING_INDEX,
      component: Trainingindex,
      meta: {
        pageTitle: "Trainings",
        resourceType: ResourceType.Trainings,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "new",
      component: TrainingNewApp, // router-view
      children: [
        {
          path: "",
          name: TRAINING_ROUTE_NAMES.TRAINING_NEW,
          component: TrainingNew,
          meta: {
            pageTitle: "New training",
            fullWidth: true,
            requestToLeave: true,
            backPageName: TRAINING_ROUTE_NAMES.TRAINING_INDEX,
            resourceType: ResourceType.Trainings,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "assets",
          name: "training-assets-app",
          component: TrainingAssetsApp, // router-view
          children: [
            {
              path: "",
              name: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
              component: TrainingAssetsEdit,
              meta: {
                pageTitle: "New training",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_INDEX,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "environment/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_ENVIRONMENT_NEW,
              component: () => import("@/views/environment/environment-new.vue"),
              meta: {
                pageTitle: "New environment",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "compute-resource/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_COMPUTE_RESOURCE_NEW,
              component: () => import("@/views/compute-resource/compute-resource-new.vue"),
              meta: {
                pageTitle: "New compute resource",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/hostpath/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_HOST_PATH_NEW,
              component: () => import("@/views/data-source/host-path/host-path-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/nfs/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_NFS_NEW,
              component: () => import("@/views/data-source/nfs/nfs-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/s3/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_S3_NEW,
              component: () => import("@/views/data-source/s3/s3-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/git/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_GIT_NEW,
              component: () => import("@/views/data-source/git/git-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
            {
              path: "data-source/pvc/new",
              name: TRAINING_ROUTE_NAMES.TRAINING_PVC_NEW,
              component: () => import("@/views/data-source/pvc/pvc-new.vue"),
              meta: {
                pageTitle: "New data source",
                fullWidth: true,
                requestToLeave: true,
                backPageName: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
                resourceType: ResourceType.Trainings,
                minPermittedActions: [Action.Create, Action.Update],
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
  ],
};
