<template>
  <q-header elevated class="top-bar" style="height: 60px">
    <q-toolbar style="height: 100%" class="row justify-between items-center">
      <div class="row items-center">
        <span class="menu-icon" v-if="!fullWidthPage">
          <q-btn
            flat
            round
            @click="$emit('toggle-drawer')"
            aria-label="Menu"
            size="12px"
            icon="fa-sharp fa-regular fa-bars"
          />
        </span>
        <back-or-close-btn v-if="fullWidthPage && canLeavePage" />
        <runai-svg-icon class="logo" name="runai-logo" />
        <q-separator vertical color="white" class="separator" />
        <span aid="page-title" class="page-title">{{ pageTitle }}</span>

        <q-btn
          v-if="displayWorkloadModeOffBtn"
          @click="setWorkloadsModeOff"
          aid="workload-mode-off-btn"
          class="q-ml-md workload-mode-off-btn"
          no-caps
        >
          <q-tooltip class="tooltip-dark">{{ workloadsToolTipText }}</q-tooltip
          >Go back to Jobs view</q-btn
        >

        <span v-if="displayWorkloadsToggle">
          <q-toggle @click="toggleWorkloads" :model-value="isWorkloadsToggleOn" />
          <q-tooltip class="tooltip-dark">{{ workloadsToolTipText }}</q-tooltip>
        </span>

        <span v-if="displayNewDeploymentToggle">
          <q-toggle @click="toggleDeployments" :model-value="isDeploymentsToggleOn" />
          <q-tooltip class="tooltip-dark">{{ deploymentTooltipText }}</q-tooltip>
        </span>
        <q-badge
          v-if="displayViewOnlyMessage"
          aid="view-only-badge"
          color="grey"
          class="q-ml-md q-py-xs q-px-md"
          id="view-only-badge"
        >
          <q-icon name="fa-solid fa-eye" class="q-mr-sm" />
          <span>View only</span>
          <q-tooltip>You can only view this screen</q-tooltip>
        </q-badge>
      </div>

      <cluster-dropdown v-if="displayClusterDropDown" />

      <div class="right-btns row items-center">
        <q-btn
          v-if="displayWorkloadModeOnBtn"
          @click="setWorkloadsModeOn"
          class="workload-mode-on-btn"
          aid="workload-mode-on-btn"
          no-caps
          >Try workloads
          <q-tooltip class="tooltip-dark">Try the new workloads experience</q-tooltip>
        </q-btn>
        <setup-dropdown />
        <help-dropdown />
        <div v-if="getLogo">
          <img class="rounded-logo" :src="'data:image/png;base64, ' + getLogo" alt="" />
        </div>
        <div v-if="!isTrial" class="tenant-name">{{ tenantName }}</div>
        <profile-dropdown />
      </div>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmps
import { ClusterDropdown } from "./cluster-dropdown";
import { SetupDropdown } from "./setup-dropdown";
import { HelpDropdown } from "./help-dropdown";
import { BackOrCloseBtn } from "@/components/infra/top-header/back-or-close-btn";
import { ProfileDropdown } from "./profile-dropdown";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// stores
import { useAuthStore } from "@/stores/auth.store";
import { useAppStore } from "@/stores/app.store";
import { useClusterStore } from "@/stores/cluster.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { useSettingStore } from "@/stores/setting.store";

// models
import { Action, type ResourceType } from "@/swagger-models/authorization-client";
import { SettingKeys } from "@/models/setting.model";
import type { RunaiRouteRecord } from "vue-router";
import { workloadAssetsRoutes } from "@/models/old-workload.model";

//utils
import { alertUtil } from "@/utils/alert.util";

//routes
import { JOB_ROUTE_NAMES } from "@/router/job.routes/job.routes.names";
import { DEPLOYMENT_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";
import type { RouteRecordName } from "vue-router";
import { dashboardRoutes } from "@/router/dashboard.routes";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { WORKLOAD_INTRO_MODAL_SHOWN } from "@/common/storage.constant";
export default defineComponent({
  components: {
    BackOrCloseBtn,
    ClusterDropdown,
    SetupDropdown,
    HelpDropdown,
    ProfileDropdown,
    RunaiSvgIcon,
  },
  emits: ["toggle-drawer"],
  props: {
    fullWidthPage: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
      clusterStore: useClusterStore(),
      settingStore: useSettingStore(),
    };
  },
  computed: {
    pageTitle(): string {
      return (this.$route.meta.pageTitle as string) || "";
    },
    tenantName(): string {
      return this.authStore.tenant?.displayName || "";
    },
    displayClusterDropDown(): boolean {
      return !this.$route.meta.hideClusterSelection;
    },
    displayViewOnlyMessage(): boolean {
      const currentResourceType: ResourceType | ResourceType[] | null = this.$route.meta.resourceType;
      if (!currentResourceType) return false;
      const routeName = this.$router.currentRoute.value.name;
      if (
        dashboardRoutes.children &&
        dashboardRoutes.children.some((route: RunaiRouteRecord) => route.name === routeName)
      ) {
        return false;
      }
      return !this.permissionStore.hasSomePermittedActions(currentResourceType, [
        Action.Update,
        Action.Create,
        Action.Delete,
      ]);
    },
    canLeavePage(): boolean {
      return !(this.clusterStore.clusterList.length === 0 && this.$route.name === CLUSTER_ROUTE_NAMES.CLUSTER_NEW);
    },
    workloadsToolTipText(): string {
      return this.isWorkloadsToggleOn ? "Back to Jobs view" : "New workloads experience";
    },
    deploymentTooltipText(): string {
      return this.isWorkloadsToggleOn ? "Back to old deployments view" : "New deployments experience";
    },
    isWorkloadsToggleOn(): boolean {
      return this.appStore.isNewWorkloadsPageOn;
    },
    isDeploymentsToggleOn(): boolean {
      return this.appStore.isNewDeploymentsPageOn;
    },
    displayWorkloadModeOffBtn(): boolean {
      return this.isWorkloadsToggleOn && this.isClusterSupportWorkloadService && this.isWorkloadPage;
    },
    displayWorkloadsToggle(): boolean {
      return (
        (this.$route.name === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX ||
          this.$route.name === JOB_ROUTE_NAMES.JOB_INDEX ||
          this.$route.name === JOB_ROUTE_NAMES.JOB_HISTORY) &&
        !this.isClusterSupportWorkloadService
      );
    },
    displayNewDeploymentToggle(): boolean {
      return (
        this.$route.name === DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_INDEX &&
        this.settingStore.settings.get(SettingKeys.EnableModelCatalog)?.value
      );
    },
    isTrial(): boolean {
      return this.authStore.isTrial;
    },
    isWorkloadPage(): boolean {
      return this.$route.name === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX;
    },
    isSomeWorkloadPage(): boolean {
      if (this.isWorkloadPage) return true;
      return workloadAssetsRoutes.includes(this.$route.name as RouteRecordName);
    },
    isClusterSupportWorkloadService(): boolean {
      return this.clusterStore.isClusterSupportWorkloadService;
    },
    displayWorkloadModeOnBtn(): boolean {
      if (!this.isClusterSupportWorkloadService) return false;
      return !this.isWorkloadsToggleOn;
    },
    getLogo(): string | undefined {
      return this.authStore.tenant.logo;
    },
  },

  methods: {
    toggleWorkloads(): void {
      this.appStore.setNewWorkloadsPageState(!this.isWorkloadsToggleOn);
      if (this.isWorkloadsToggleOn) {
        this.$router.replace({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
      } else {
        this.$router.replace({ name: JOB_ROUTE_NAMES.JOB_INDEX });
      }
    },
    setWorkloadsModeOn(): void {
      if (!this.isWorkloadsToggleOn && window.localStorage.getItem(WORKLOAD_INTRO_MODAL_SHOWN) !== null) {
        this.$q.notify(alertUtil.getSuccess("Switched to the new workloads experience"));
      }
      this.appStore.setNewWorkloadsPageState(true);
      this.$router.replace({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    setWorkloadsModeOff(): void {
      this.appStore.setNewWorkloadsPageState(false);
      this.$router.replace({ name: JOB_ROUTE_NAMES.JOB_INDEX });
      this.$q.notify(alertUtil.getSuccess("Switched to the Jobs view"));
    },
    toggleDeployments(): void {
      this.appStore.setNewDeploymentsPageState(!this.isDeploymentsToggleOn);
    },
  },
});
</script>

<style lang="scss" scoped>
.workload-mode-off-btn {
  background-color: $grey-input-color;
  font-size: 14px;
  color: $grey-9;
  font-weight: 400;
}
.workload-mode-on-btn {
  background-color: $runai-pink;
  font-size: 14px;
  color: white;
  font-weight: 400;
}
#view-only-badge {
  width: 110px;
  height: 27px;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.top-bar {
  height: 54px;
  background-color: $navy;

  .logo {
    margin-left: 1.8rem;
  }

  .separator {
    margin-inline: 20px;
    height: 30px;
    opacity: 0.3;
    align-self: center;
  }

  .menu-icon {
    padding-left: 5px;
  }

  .page-title {
    font-size: 16px;
    font-weight: 500;
    color: $white;
    font-family: Roboto, sans-serif;
  }

  .tenant-name {
    font-size: 0.875rem;
    color: $white;
    border: 0.125rem solid rgba($color: #ffffff, $alpha: 0.5);
    border-radius: 0.5rem;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0 0.625rem;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  .right-btns {
    gap: 15px;
  }

  .rounded-logo {
    border-radius: 40%;
    width: 35px;
    height: 35px;
    margin: 10px 8px 8px 8px;
  }
}
</style>
