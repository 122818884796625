import {
  EPodStatus,
  EWorkloadPhase,
  PodStatusPhaseMap,
  COMPLETION_STATUSES,
  WorkloadPhaseMap,
  RUNAI_WORKLOAD,
  INFERENCE_WORKLOAD,
} from "@/models/old-workload.model";
import type { IStatusColOptions } from "@/models/table.model";
import type { Job } from "@/swagger-models/backend-client";
import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import type { JobMetricsData } from "@/models/workload.model";
import type { Pod } from "@/models/pods.model";
import { tableUtil } from "@/utils/table.util";
import { dateUtil } from "@/utils/date.util";

export const oldWorkloadUtil = {
  getStatusColOptions,
  getJobMetricsFromResponse,
  getPodStatusColOptions,
  getWorkloadCompletionTime,
  isRunaiWorkload,
  isInferenceWorkload,
};

function getStatusColOptions(phase: EWorkloadPhase): IStatusColOptions {
  const statusOptions: IStatusColOptions = WorkloadPhaseMap[phase];
  return tableUtil.getStatusColOptions(statusOptions, phase);
}

function getPodStatusColOptions(pod: Pod): IStatusColOptions {
  const statusOptions: IStatusColOptions = PodStatusPhaseMap[pod.status as unknown as EPodStatus];
  if (!pod.status || !statusOptions) {
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
      filterKey: "status",
    };
  }
  if (
    [EPodStatus.Running, EPodStatus.Pending, EPodStatus.ContainerCreating].some(
      (status: EPodStatus) => status === statusOptions.status,
    )
  ) {
    const createdTimeAgo = dateUtil.timeAgo(parseInt(pod.created as unknown as string));
    const status = `${statusOptions.status} (${createdTimeAgo})`;
    return {
      ...statusOptions,
      status: status,
    };
  }

  return statusOptions;
}
function getJobMetricsFromResponse(jobMetrics: Job): JobMetricsData {
  const gpu: Array<Array<number>> = [];
  const cpu: Array<Array<number>> = [];
  const cpuMemory: Array<Array<number>> = [];
  const gpuMemory: Array<Array<number>> = [];

  const resourceData = jobMetrics.timeRange?.resources || [];

  for (const entry of resourceData) {
    const timestamp = Date.parse(entry?.timestamp);
    const gpuUtilization = entry.gpu.utilization || 0;
    const cpuUtilization = entry.cpu.utilization || 0;
    const cpuMemoryUtilization = entry["cpu-memory"].utilization || 0;
    const gpuMemoryUtilization = entry["gpu-memory"].utilization || 0;

    gpu.push([timestamp, gpuUtilization]);
    cpu.push([timestamp, cpuUtilization]);
    cpuMemory.push([timestamp, cpuMemoryUtilization]);
    gpuMemory.push([timestamp, gpuMemoryUtilization]);
  }

  return {
    gpuUtilization: gpu,
    gpuMemory: gpuMemory,
    cpuUtilization: cpu,
    cpuMemory: cpuMemory,
  };
}

function getWorkloadCompletionTime(workload: DisplayedJob): string {
  const latestPodCompletedTime: number | undefined = workload?.latestPod?.completed;
  if (latestPodCompletedTime && workload.status && COMPLETION_STATUSES.includes(workload?.status)) {
    return dateUtil.dateFormat(new Date(parseInt(latestPodCompletedTime as unknown as string)), "dd/MM/yyyy HH:mm a");
  }
  return "-";
}

function isRunaiWorkload(workload: DisplayedJob): boolean {
  if (!workload.workloadKind) return false;
  return String(workload.workloadKind) === RUNAI_WORKLOAD;
}

function isInferenceWorkload(workload: DisplayedJob): boolean {
  if (!workload.workloadKind) return false;
  return workload.jobType === INFERENCE_WORKLOAD;
}
