<template>
  <section class="registry-integration column">
    <boolean-setting :setting="localSetting" @update-setting="updateLocalSetting" />
    <transition name="fade" :duration="300">
      <div v-if="localSetting.value" class="column q-pb-md">
        <q-input
          class="q-mb-sm"
          no-error-icon
          outlined
          dense
          label="Registry URL"
          v-model="registryUrl"
          :rules="[required]"
          :disable="disableForm"
        />
        <div class="row justify-between q-mb-sm">
          <q-input
            hide-bottom-space
            no-error-icon
            class="col-5"
            outlined
            dense
            label="User"
            v-model="username"
            :rules="[required]"
            :disable="disableForm"
          />
          <q-input
            hide-bottom-space
            no-error-icon
            class="col-5"
            type="password"
            outlined
            dense
            label="Password"
            v-model="password"
            :rules="[required]"
            :disable="disableForm"
          />
        </div>
        <div class="row justify-end">
          <q-btn :disable="disableForm" label="save" color="primary" size="sm" @click="saveRegistry" />
        </div>

        <div class="q-mt-sm">
          <span v-if="disableForm">
            To update the registry integration section, toggle off and back on. Note: the values will be deleted when
            toggled off.
          </span>
          <br />
          For further information on how to enable registry integration see the
          <a href="https://docs.run.ai/latest/admin/researcher-setup/docker-registry-config" target="_blank">
            Container registry integration guide.</a
          >
        </div>
      </div>
    </transition>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { BooleanSetting } from "../../boolean-setting";
// Models
import type { Setting } from "@/swagger-models/backend-client";
import { Scope, type RegistryCreationRequest } from "@/swagger-models/assets-service-client";
import { SettingKeys } from "@/models/setting.model";
// Utils
import { deepCopy } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
// Stores
import { useRegistryStore } from "@/stores/registry.store";
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "registry-integration",
  components: {
    BooleanSetting,
  },
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
  },
  data() {
    return {
      registryStore: useRegistryStore(),
      registryUrl: "",
      username: "",
      password: "",
      registryId: "",
      localSetting: deepCopy(this.setting),
    };
  },
  created() {
    this.registryUrl = this.registryStore.registry?.spec?.url || "";
    this.username = this.registryStore.registry?.spec?.user || "";
    this.password = this.registryStore.registry?.spec?.password || "";
    this.registryId = this.registryStore.registry?.meta?.id || "";
  },
  computed: {
    disableForm(): boolean {
      return !!this.registryStore.registry?.meta.id;
    },
  },
  methods: {
    async updateLocalSetting(key: string, value: string): Promise<void> {
      this.localSetting.value = value;
      if (!value) {
        await this.registryStore.deleteRegistry();
        this.updateSetting(SettingKeys.RegistryIntegration, false);
        [this.registryId, this.registryUrl, this.username, this.password] = ["", "", "", ""];
      }
    },
    updateSetting(key: string, value: string | boolean | number): void {
      this.$emit("update-setting", key, value);
    },
    async saveRegistry(): Promise<void> {
      const registry: RegistryCreationRequest = {
        meta: {
          name: "registry-name",
          scope: Scope.Tenant,
        },
        spec: {
          url: this.registryUrl,
          user: this.username,
          password: this.password,
          credentialKind: "password",
        },
      };
      try {
        await this.registryStore.createRegistry(registry);
        this.updateSetting(SettingKeys.RegistryIntegration, true);
      } catch (err) {
        console.error(err);
        this.$q.notify(alertUtil.getError("Invalid credentials or registry URL path"));
      }
    },
    required(val: string): boolean | string {
      return !!val || errorMessages.ENTER_A_VALUE;
    },
  },
});
</script>
