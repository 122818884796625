import DepartmentIndex from "../views/department/department-index.vue";
import DepartmentEdit from "../views/department/department-edit.vue";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export enum DEPARTMENT_ROUTE_NAMES {
  DEPARTMENT_INDEX = "department-index",
  DEPARTMENT_NEW = "department-new",
  DEPARTMENT_EDIT = "department-edit",
  DEPARTMENT_VIEW = "department-view",
}

export const departmentRoutes: RunaiRouteRecord = {
  path: "/departments",
  // route level code-splitting
  // this generates a separate chunk (department.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("../views/department/department-app.vue"),
  children: [
    {
      path: "",
      name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
      component: DepartmentIndex,
      meta: {
        pageTitle: "Departments",
        resourceType: ResourceType.Department,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
    {
      path: "new",
      name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW,
      component: DepartmentEdit,
      meta: {
        pageTitle: "New department",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
        resourceType: ResourceType.Department,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
      },
    },
    {
      path: "edit/:id",
      name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_EDIT,
      component: DepartmentEdit,
      meta: {
        pageTitle: "Edit department",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
        resourceType: ResourceType.Department,
        minPermittedActions: [Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "view/:id",
      name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_VIEW,
      component: DepartmentEdit,
      meta: {
        pageTitle: "Review department",
        fullWidth: true,
        requestToLeave: true,
        backPageName: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
        resourceType: ResourceType.Department,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
  ],
};
