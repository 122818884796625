<template>
  <section class="deployment-history">
    <iframe-view />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { IframeView } from "@/components/infra/iframe-view";

// Stores
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    IframeView,
  },
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
});
</script>
