<template>
  <q-td :class="getStatusClass">
    {{ statusDisplayLabel }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// models
import { NodePoolStatusMap } from "@/models/node-pool.model";
import type { INode } from "@/models/node.model";

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<INode>,
      required: true,
    },
  },
  computed: {
    status(): string {
      return this.row.nodeStatus;
    },
    isStatusPending(): boolean {
      return NodePoolStatusMap.get(this.status)?.isPending || false;
    },
    getStatusClass() {
      return this.status?.toLowerCase() + "-color";
    },
    statusDisplayLabel(): string {
      return NodePoolStatusMap.get(this.status)?.displayLabel || this.status;
    },
  },
});
</script>
<style lang="scss" scoped>
.ready-color {
  color: $success;
}
.unschedulable-color {
  color: $negative;
}
</style>
