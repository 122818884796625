import { dateUtil, TimeUnit } from "@/utils/date.util";

export interface PredefinedOption {
  label: string;
  daysCount: number;
  hoursCount: number;
  endDate?: Date;
}

export const defaultPredefinedOptions: Array<PredefinedOption> = [
  { label: "Today", daysCount: 0, hoursCount: 0 },
  { label: "Yesterday", daysCount: 1, hoursCount: 0, endDate: dateUtil.adjustDateBy(TimeUnit.day, new Date(), -1) },
  { label: "Last 7 days", daysCount: 7, hoursCount: 0 },
  { label: "Last 30 days", daysCount: 30, hoursCount: 0 },
];

export const hoursGranularityPredefinedOptions: Array<PredefinedOption> = [
  { label: "Last hour", daysCount: 0, hoursCount: 1 },
  { label: "Last 6 hours", daysCount: 0, hoursCount: 6 },
  ...defaultPredefinedOptions,
];

export const extendedDefaultPredefinedOptions: Array<PredefinedOption> = [
  { label: "Today", daysCount: 0, hoursCount: 0 },
  { label: "Yesterday", daysCount: 1, hoursCount: 0, endDate: dateUtil.adjustDateBy(TimeUnit.day, new Date(), -1) },
  { label: "Last 7 days", daysCount: 7, hoursCount: 0 },
  { label: "Last 14 days", daysCount: 14, hoursCount: 0 },
  { label: "Last 30 days", daysCount: 30, hoursCount: 0 },
];

export const extendedHoursPredefinedOptions: Array<PredefinedOption> = [
  { label: "Last 3 hour", daysCount: 0, hoursCount: 3 },
  { label: "Last 6 hours", daysCount: 0, hoursCount: 6 },
  { label: "Last 24 hours", daysCount: 0, hoursCount: 24 },
  ...extendedDefaultPredefinedOptions,
];

export interface DateTimeFormatOptions {
  includeSeconds?: boolean;
  includeMilliseconds?: boolean;
}
