<template>
  <section class="runai-event-history">
    <section class="table-top-actions-container">
      <runai-page-filter
        :initial-filters-model="columnFilters"
        @update-column-filters="updateFilterBy('columnFilters', $event)"
        :filter-options="filterOptions"
      />
      <runai-general-search :search-term="searchTerm" @update-search-term="updateFilterBy('searchTerm', $event)" />
    </section>
    <q-table
      :="tableDefaultProps"
      :rows="events"
      :columns="columns"
      row-key="creationTimestamp"
      :loading="loading"
      :pagination="filters"
      :rows-per-page-options="[0]"
      virtual-scroll
      hide-pagination
    >
      <template v-slot:body-cell-details="props">
        <q-td style="padding: 7px 16px; white-space: break-spaces">
          <runai-status v-if="props.row.meta.type === 'statusChange'" :options="getStatusOptions(props.value)" />
          <div v-else>{{ props.value }}</div>
        </q-td>
      </template>

      <template v-slot:no-data>
        <runai-table-no-data
          v-if="!loading"
          :filter-by="filters"
          entity-name="event"
          @clear-filters="clearFilters"
          :show-create-btn="false"
          light-icon
        />
      </template>
    </q-table>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { QTableProps } from "quasar";

// components
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { RunaiPageFilter } from "@/components/common/runai-page-filter";
import { RunaiGeneralSearch } from "@/components/common/runai-general-search";
import { RunaiStatus } from "@/components/common/runai-status";

// constants
import { tableDefaultProps } from "@/common/table.constant";

// models
import type { IFilterBy } from "@/models/filter.model";
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import type { IFilterOption, IFilterModel } from "@/models/filter.model";
import type { TTableDefaultProps } from "@/common/table.constant";

// services
import { filterService } from "@/services/filter.service/filter.service";
import type { IStatusColOptions } from "@/models/table.model";
import { UI_WORKSPACE_STATUS } from "@/common/status.constant";

export default defineComponent({
  components: { RunaiTableNoData, RunaiPageFilter, RunaiGeneralSearch, RunaiStatus },
  emits: ["update:filters"],
  props: {
    columns: {
      type: Array as PropType<QTableProps["columns"]>,
      required: true,
    },
    events: {
      type: Array as PropType<Array<WorkloadHistoryResponseEntriesInner>>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
  },
  data() {
    return {
      tableDefaultProps: tableDefaultProps as TTableDefaultProps,
    };
  },
  computed: {
    columnFilters(): Array<IFilterModel> {
      return this.filters.columnFilters || ([] as Array<IFilterModel>);
    },
    searchTerm(): string {
      return this.filters.searchTerm || "";
    },
    filterOptions(): Array<IFilterOption> {
      return this.columns ? filterService.getFilterOptions(this.columns) : [];
    },
  },
  methods: {
    clearFilters(): void {
      this.$emit("update:filters", {
        ...this.filters,
        columnFilters: [],
        searchTerm: "",
      });
    },
    updateFilterBy(key: string, newVal: string | number | []): void {
      this.$emit("update:filters", { ...this.filters, [key]: newVal });
    },
    getStatusOptions(status: string): IStatusColOptions {
      const statusOptions = UI_WORKSPACE_STATUS[status];
      return {
        status: statusOptions.status,
        color: statusOptions.color,
        displayAnimation: false,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.runai-event-history {
  .table-top-actions-container {
    background-color: $white;
    border-top: 1px solid $black-12;
    border-bottom: 1px solid $black-12;
    padding: 7px 0;
  }

  .table-top-actions-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
