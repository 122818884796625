<template>
  <section class="training-assets-edit column items-center q-pt-md">
    <template v-if="isPageReady && selectedProject">
      <workload-distributed-create-form
        v-if="training.distributed"
        :workload-type="formType"
        :workload="training"
        :selected-project="selectedProject"
        submit-btn-text="Create Training"
        :submitting="submitting"
        :workload-sections-options="workloadSectionsOptions"
        :display-step="$route.query.workloadFormName?.toString() || undefined"
        @workload-changed="onWorkloadChanged"
        @canceled="onCancel"
        @submit="onSubmit"
        @back-clicked="onBack"
      />
      <workload-default-create-form
        v-else
        :workload-type="formType"
        :workload="training"
        :selected-project="selectedProject"
        submit-btn-text="Create Training"
        :submitting="submitting"
        :workload-sections-options="workloadSectionsOptions"
        @workload-changed="onWorkloadChanged"
        @canceled="onCancel"
        @submit="onSubmit"
        @back-clicked="onBack"
      />
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

// components
import { WorkloadDistributedCreateForm, WorkloadDefaultCreateForm } from "@/components/old-workload";

// models
import { EWorkloadFormType, EWorkloadType, type IUIWorkloadCreation } from "@/models/workload.model";
import type { IWorkloadCreateFormConfig } from "@/components/old-workload/workload-create-form";
import {
  Scope,
  type EnvironmentAsset,
  type WorkloadCreationRequest,
  type ComputeAsset,
  type Training,
  type AssetIdAndKind,
  AssetKind,
  type PVCAsset,
  type DatasourceListResponseEntry,
  type SpecificRunConnectionInfo,
} from "@/swagger-models/assets-service-client";
import type { TrainingPolicy } from "@/swagger-models/policy-service-client";
import type { IUIWorkloadEnvSectionModel, IWorkloadEnvSectionOptions } from "@/components/section/environment-section";
import type { IProject, ISelectedNodeAffinity } from "@/models/project.model";
import type { ICluster } from "@/models/cluster.model";
import type { IUIVolume } from "@/models/data-source.model";
import type { IWorkloadComputeSectionOptions } from "@/components/section/compute-resource-section";

// stores
import { useAppStore } from "@/stores/app.store";
import { useTrainingStore } from "@/stores/training.store";
import { useProjectStore } from "@/stores/project.store";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useComputeResourceStore } from "@/stores/compute-resource.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useDataSourceStore } from "@/stores/data-source.store";
import { useOldWorkloadStore } from "@/stores/old-workload.store";
import { useWorkloadStore } from "@/stores/workload.store";
import { useAuthStore } from "@/stores/auth.store";

// services
import { trainingService } from "@/services/control-plane/training.service/training.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { requestToLeave } from "@/services/infra/router.service/router.service";

// utils
import { fallbackDefaultIfNullOrUndefined } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { policyUtil } from "@/utils/policy.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { environmentSectionUtil } from "@/components/section/environment-section";

// routes
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";

// common
import type { IAssetsFilter } from "@/models/filter.model";
import { ErrorAlert } from "@/utils/error-alert.util";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import type { ITrainingList } from "@/models/training.model";

export default defineComponent({
  components: {
    WorkloadDistributedCreateForm,
    WorkloadDefaultCreateForm,
  },
  data() {
    return {
      appStore: useAppStore(),
      projectStore: useProjectStore(),
      trainingStore: useTrainingStore(),
      environmentStore: useEnvironmentStore(),
      computeResourceStore: useComputeResourceStore(),
      oldWorkloadStore: useOldWorkloadStore(),
      workloadStore: useWorkloadStore(),
      authStore: useAuthStore(),
      nodeAffinity: [] as Array<ISelectedNodeAffinity>,
      storageClasses: [] as Array<string>,
      dataSourceStore: useDataSourceStore(),
      clusterStore: useClusterStore(),
      formType: EWorkloadFormType.Training,
      training: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
      trainingPolicy: null as TrainingPolicy | null,
      submitting: false as boolean,
      workloadSectionsOptions: {} as IWorkloadCreateFormConfig,
      envSectionOptions: {
        canAddEnvVariable: true,
      } as IWorkloadEnvSectionOptions,
      computeSectionOptions: {
        applyPolicyDefaults: false,
      } as IWorkloadComputeSectionOptions,
      assetsFilter: {} as IAssetsFilter,
      workloadFormName: this.$route.query.workloadFormName
        ? this.$route.query.workloadFormName
        : (null as string | null),
    };
  },
  async created() {
    const { kind, fromCopyId, createdEntityId } = this.$route.query;
    this.training = this.trainingStore.training;
    try {
      if (fromCopyId) {
        await this.loadFromExistingTraining(String(fromCopyId));
      }
      this.assetsFilter = {
        projectId: this.training.projectId,
        usageInfo: true,
        complyToProject: this.training.projectId,
        complyToWorkloadType: EWorkloadType.Training,
      };

      await this.loadProjects();
      await this.loadPolicy();

      const createdEnvironmentId: string | undefined =
        kind === AssetKind.Environment ? createdEntityId?.toString() : undefined;
      const createdComputeId: string | undefined = kind === AssetKind.Compute ? createdEntityId?.toString() : undefined;

      const isDataSource = [AssetKind.HostPath, AssetKind.Nfs, AssetKind.Git, AssetKind.S3, AssetKind.Pvc].some(
        (assetKind) => assetKind === kind,
      );

      const createdDataSourceId: string | undefined = isDataSource ? createdEntityId?.toString() : undefined;

      await Promise.all([
        this.loadEnvironments(createdEnvironmentId),
        this.loadComputeResources(createdComputeId),
        this.loadDataSources(createdDataSourceId, kind as AssetKind),
        this.loadStorageClasses(),
      ]);

      this.loadNodeAffinity();

      this.computeSectionOptions.applyPolicyDefaults = !!fromCopyId || !!this.trainingStore.templateId;
      this.workloadSectionsOptions = {
        project: {
          projects: this.projects,
        },
        workloadName: {
          sectionDefaultOpen: !!fromCopyId,
        },
        multiNode: {
          sectionDefaultOpen: false,
        },
        environment: {
          environments: this.environments,
          sectionOptions: this.envSectionOptions,
        },
        compute: {
          computeResources: this.computeResources,
          nodeAffinity: this.nodeAffinity,
          sectionOptions: this.computeSectionOptions,
        },
        dataSource: {
          dataSources: this.dataSources,
          sectionDefaultOpen: !!createdDataSourceId,
        },
        volumes: {
          storageClasses: this.storageClasses,
        },
      };

      this.applyPolicyDefaultsAndRules();
      this.appStore.setPageLoading(false);
    } catch (error: unknown) {
      this.$q.notify(alertUtil.getError("Failed to load assets"));
      console.error(error);
      this.appStore.setFallback(true);
    }
  },
  computed: {
    isPageReady(): boolean {
      return !this.appStore.isPageLoading;
    },
    projectId(): number {
      return this.training.projectId;
    },
    selectedProject(): IProject | undefined {
      return this.projects.find((project: IProject) => project.id === this.projectId);
    },
    projects(): Array<IProject> {
      return this.projectStore.projects;
    },
    clusterUid(): string {
      return this.clusterStore.currentCluster.uuid;
    },
    environments(): Array<EnvironmentAsset> {
      return this.environmentStore.environmentList;
    },
    computeResources(): Array<ComputeAsset> {
      return this.computeResourceStore.computeResourcesList;
    },
    dataSources(): Array<DatasourceListResponseEntry> {
      return this.dataSourceStore.dataSourceList;
    },
    isVersionSupportWorkloadsService(): boolean {
      return this.clusterStore.isVersionSupportWorkloadsService;
    },
  },
  methods: {
    async loadEnvironments(createdEnvironmentId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;

      const environmentsFilter: IAssetsFilter = {
        ...this.assetsFilter,
        isTraining: true,
      };

      if (this.training.distributed) {
        environmentsFilter.isDistributed = true;
        environmentsFilter.distributedFramework = this.training.distributed.distFramework;
      }

      await this.environmentStore.loadEnvironments(environmentsFilter);

      if (createdEnvironmentId) {
        this.updateEnvironment(createdEnvironmentId);
      }

      // unselect non compliance environment (from policy)
      if (this.training.assets.environment) {
        const selectedEnvironment: EnvironmentAsset | undefined = this.environments.find(
          (environment: EnvironmentAsset) => environment.meta.id === this.training.assets.environment,
        );

        if (!selectedEnvironment?.compliance?.compliance) {
          this.training.assets.environment = "";
        }
      }
    },
    async loadComputeResources(createdComputeResourceId: string | undefined): Promise<void> {
      if (!this.selectedProject) return;
      await this.computeResourceStore.loadComputeResources(this.assetsFilter);

      // set the last created data source as selected
      if (createdComputeResourceId) {
        if (this.workloadFormName === "master" && this.training.distributed?.master) {
          this.training.distributed.master.assets.compute = createdComputeResourceId;
        } else {
          this.setComputeResource(createdComputeResourceId);
        }
      }

      // unselect non compliance compute (from policy)
      if (this.training.assets.compute) {
        const selectedCompute: ComputeAsset | undefined = this.computeResources.find(
          (compute: ComputeAsset) => compute.meta.id === this.training.assets.compute,
        );

        if (!selectedCompute?.compliance?.compliance) {
          this.training.assets.compute = "";
        }
      }
    },
    async loadDataSources(createdDataSourceId: string | undefined, kind: AssetKind): Promise<void> {
      if (!this.selectedProject) return;
      await this.dataSourceStore.loadDataSources(this.assetsFilter);

      // TODO: we need to move it to apply policy
      const nonComplianceDataSources: Set<string> = new Set(
        this.dataSources
          .filter((dataSource: DatasourceListResponseEntry) => !dataSource.compliance?.compliance)
          .map((dataSource: DatasourceListResponseEntry) => dataSource.meta.id),
      );

      // set the last created data source as selected
      if (createdDataSourceId && kind) {
        if (this.workloadFormName === "master" && this.training.distributed?.master) {
          this.training.distributed.master.assets.datasources = this.updateDataSourceIfNeeded(
            this.training.distributed.master.assets.datasources,
            createdDataSourceId,
            kind,
          );
        } else {
          this.training.assets.datasources = this.updateDataSourceIfNeeded(
            this.training.assets.datasources,
            createdDataSourceId,
            kind,
          );
        }
      }

      // force selecting imposed (by policy) data sources
      this.dataSources.forEach((dataSource: DatasourceListResponseEntry) => {
        if (dataSource.compliance?.imposed) {
          this.training.assets.datasources = this.updateDataSourceIfNeeded(
            this.training.assets.datasources,
            dataSource.meta.id,
            dataSource.meta.kind,
          );
        }
      });

      // unselect non compliance data sources (from policy)
      this.training.assets.datasources = this.training.assets.datasources?.filter(
        (datasource: AssetIdAndKind) => !nonComplianceDataSources.has(datasource.id),
      );
    },
    async loadStorageClasses(): Promise<void> {
      const cluster: ICluster = this.clusterStore.currentCluster;
      this.storageClasses = await dataSourceService.listStorageClass(cluster.uuid);
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
    },
    async loadPolicy(): Promise<void> {
      if (!this.selectedProject) return;
      this.trainingPolicy = await trainingService.getPolicy(this.projectId, Scope.Project);
    },
    loadNodeAffinity(): void {
      if (!this.selectedProject) this.nodeAffinity = [];
      this.nodeAffinity = this.projectStore.getTrainingNodeAffinityByProjectId(this.training.projectId);
      if (this.training.specificEnv && this.nodeAffinity && this.nodeAffinity.length === 1)
        this.training.specificEnv.nodeType = this.nodeAffinity[0].name;
    },
    setEnvironment(environment: EnvironmentAsset): void {
      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.training.assets.environment = environment.meta.id;
      this.training.specificEnv = {
        ...this.training.specificEnv,
        ...specificEnv,
      };
    },
    setEnvironmentDistrinuted(environment: EnvironmentAsset): void {
      if (!this.training.distributed?.master) return;

      const specificEnv: IUIWorkloadEnvSectionModel = environmentSectionUtil.getSpecificEnvFromEnvironment(environment);
      this.training.distributed.master.assets.environment = environment.meta.id;
      this.training.distributed.master.specificEnv = {
        ...this.training.distributed.master.specificEnv,
        ...specificEnv,
      };
    },
    updateEnvironment(createdEnvironmentId: string): void {
      const environment: EnvironmentAsset | undefined = this.environments.find(
        (environment: EnvironmentAsset) => environment.meta.id === createdEnvironmentId,
      );
      if (!environment) return;

      if (this.workloadFormName === "master") {
        this.setEnvironmentDistrinuted(environment);
      } else {
        // not set or workers step
        this.setEnvironment(environment);
      }
    },
    setComputeResource(id: string): void {
      this.training.assets.compute = id;
    },
    updateDataSourceIfNeeded(
      datasources: Array<AssetIdAndKind> | undefined,
      id: string,
      kind: AssetKind,
    ): Array<AssetIdAndKind> {
      if (!datasources) datasources = [];
      const alreadyExist: AssetIdAndKind | undefined = datasources.find(
        (datasource: AssetIdAndKind) => datasource.id === id,
      );
      if (!alreadyExist) {
        datasources.push({ id, kind });
      }

      return datasources;
    },
    async loadFromExistingTraining(trainingId: string): Promise<void> {
      try {
        const originalTraining: Training = await this.trainingStore.loadById(trainingId);
        let uiVolumes: Array<IUIVolume> | undefined;
        if (originalTraining.spec.assets.workloadVolumes?.length) {
          const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(
            originalTraining.spec.assets.workloadVolumes,
          );
          uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
        }
        this.training = workloadUtil.convertWorkloadToWorkloadUI(originalTraining, uiVolumes);
        this.saveTraining(this.training);
      } catch (error: unknown) {
        console.error("failed to get training with id:", trainingId, error);
        this.$q.notify(alertUtil.getError("Failed to load training"));
        this.appStore.setFallback(true);
      }
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    redirectToPrevRoute(): void {
      if (this.$route.meta.prevRoute === WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX) {
        this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
      } else {
        this.$router.push({ name: TRAINING_ROUTE_NAMES.TRAINING_INDEX });
      }
    },
    async onSubmit(): Promise<void> {
      try {
        this.submitting = true;
        this.training.clusterId = this.clusterUid;
        this.training.namespace = this.projectStore.getNamespaceByProjectId(this.training.projectId);

        this.setPrivateConnections(); // TODO: the day they change this feature to include more users other than the creator this code becomes useless

        this.saveTraining(this.training);
        let workloadVolumes: Array<string> | undefined;
        if (this.training.assets.uiVolumes?.length) {
          workloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.training.name,
            this.training.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        let masterWorkloadVolumes: Array<string> | undefined;
        if (this.training.distributed?.master?.assets.uiVolumes?.length) {
          masterWorkloadVolumes = await dataSourceService.createWorkloadVolumes(
            this.training.name,
            this.training.distributed.master.assets.uiVolumes,
            {
              scope: Scope.Project,
              projectId: this.projectId,
            },
          );
        }

        const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(
          this.training,
          workloadVolumes,
          masterWorkloadVolumes,
        );

        const trainingAdded: ITrainingList | null = await this.trainingStore.createTraining(workloadCreationRequest);
        if (trainingAdded && trainingAdded.job) {
          //update workloads table with new training
          if (this.isVersionSupportWorkloadsService) {
            this.workloadStore.setWorkloadAdded(trainingAdded);
          } else {
            this.oldWorkloadStore.setWorkloadAdded(trainingAdded.job);
          }
        }

        this.$q.notify(alertUtil.getSuccess(`Training ${this.training.name} created`));
        this.redirectToPrevRoute();
      } catch (error: unknown) {
        // This is a quick fix when creating workload fails we need to create a pvc
        // with a different name
        this.training.assets.uiVolumes?.forEach(
          (uiVolume: IUIVolume) => (uiVolume.claimName = dataSourceUtil.getRandomClaimName(`${this.training.name}-pvc`)),
        );

        if (this.training.distributed?.master?.assets.uiVolumes) {
          this.training.distributed.master.assets.uiVolumes.forEach(
            (uiVolume: IUIVolume) =>
              (uiVolume.claimName = dataSourceUtil.getRandomClaimName(`${this.training.name}-pvc`)),
          );
        }

        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("training"),
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.submitting = false;
      }
    },
    applyPolicyDefaultsAndRules(): void {
      this.applyPolicyForEnvironmentSection();
      this.saveTraining(this.training);
    },
    applyPolicyForEnvironmentSection(): void {
      if (!this.trainingPolicy?.effective) return;

      if (this.trainingPolicy.effective.defaults?.environment?.environmentVariables) {
        this.training.specificEnv.environmentVariables = policyUtil.mergeEnvironmentVariablesWithPolicyDefaults(
          this.training.specificEnv.environmentVariables,
          this.trainingPolicy.effective,
        );
      }

      if (this.trainingPolicy.effective.rules?.environment?.environmentVariables?.itemRules) {
        this.envSectionOptions.canAddEnvVariable = fallbackDefaultIfNullOrUndefined<boolean>(
          this.trainingPolicy.effective.rules?.environment?.environmentVariables?.itemRules.canAdd,
          true,
        );
      }
    },
    saveTraining(training: IUIWorkloadCreation): void {
      this.trainingStore.setTraining(training);
    },
    onBack(): void {
      this.$router.push({ name: TRAINING_ROUTE_NAMES.TRAINING_NEW });
    },
    onWorkloadChanged(training: IUIWorkloadCreation): void {
      this.training = training;
      this.saveTraining(training);
    },
    setPrivateConnections(): void {
      if (!this.training.specificEnv.connections) return;
      this.training.specificEnv.connections = this.training.specificEnv.connections?.map(
        (c: SpecificRunConnectionInfo) => {
          if (!c.authorizedUsers) return c;
          return {
            ...c,
            authorizedUsers: [this.authStore.userEmail],
          };
        },
      );
    },
  },
  provide() {
    return {
      policy: computed(() => this.trainingPolicy),
    };
  },
});
</script>

<style lang="scss" scoped>
.training-assets-edit {
  .training-assets-edit-actions {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
@/utils/error-alert.service
