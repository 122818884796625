import { DistFramework } from "@/swagger-models/assets-service-client";

export const toolTypeIconsMap: Record<string, string> = {
  "jupyter-notebook": "jupyter",
  "visual-studio-code": "vscode2",
  tensorboard: "tensorboard",
  rstudio: "rstudio",
  mlflow: "mlflow",
  wandb: "wandb",
  custom: "custom-tool",
  comet: "comet",
  matlab: "matlab",
};

export const RUNAI_WORKLOAD_ICON = "default-job";
export const jobKindIconsMap: Record<string, string> = {
  MPIJob: "mpi",
  MPI: "mpi",
  TFJob: "tensorboard",
  TF: "tensorboard",
  PyTorchJob: "pytorch",
  PyTorch: "pytorch",
  XGBoostJob: "xgboost",
  XGBoost: "xgboost",
  RayCluster: "ray",
  RayJob: "ray",
  RayService: "ray",
  Spark: "spark",
  Unknown: "custom-tool",
  Training: RUNAI_WORKLOAD_ICON,
  Distributed: RUNAI_WORKLOAD_ICON,
  Interactive: RUNAI_WORKLOAD_ICON,
};

export const allWorkloadsIconsMap: Record<string, string> = {
  ...jobKindIconsMap,
  ...toolTypeIconsMap,
};

export enum DATA_SOURCE_ICONS_NAMES {
  s3 = "s3",
  git = "git",
  nfs = "nfs",
  hostPath = "hostpath",
  pvc = "pvc",
  default = "data-source-gray",
}

export const distFrameworkIconsMap: Record<DistFramework, string> = {
  [DistFramework.Tf]: "tensorboard",
  [DistFramework.PyTorch]: "pytorch",
  [DistFramework.Mpi]: "mpi",
  [DistFramework.XgBoost]: "xgboost",
};
