import { ResourceType, Action } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";

export const auditRoutes: RunaiRouteRecord = {
  path: "/audit",
  component: () => import("../views/audit/audit-app.vue"),
  children: [
    {
      path: "",
      name: "audit-index",
      component: () => import("../views/audit/audit-index.vue"),
      meta: {
        pageTitle: "Event history",
        resourceType: ResourceType.EventsHistory,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
      },
    },
  ],
};
