import type { ITableColumn } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import type { IFilterBy } from "@/models/filter.model";
import { ECustomCell } from "@/models/table.model";
import type { ISelectOption } from "@/models/global.model";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType, SubjectType, type AccessRule } from "@/swagger-models/authorization-client";
import type { IUser } from "@/models/user.model";
import type { ResourceType } from "@/swagger-models/authorization-client";

export interface IAccessRuleRecords {
  accessRules: AccessRule[];
  displayRecords: number;
  totalRecords: number;
}

export enum ERoleName {
  SystemAdmin = "System administrator",
}
export const subjectTypeOptions: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];
export const subjectTypeOptionsWithSSO: ISelectOption[] = [
  {
    label: "User",
    value: SubjectType.User,
  },
  {
    label: "SSO Group",
    value: SubjectType.Group,
  },
  {
    label: "Application",
    value: SubjectType.App,
  },
];

const subjectTypeOptionsMap: Map<string, string> = new Map(
  subjectTypeOptionsWithSSO.map((option: ISelectOption) => [option.value as string, option.label as string]),
);

export interface IAccessRuleTableModalOptions {
  accessRules: AccessRule[];
  columns: Array<ITableColumn>;
  header: string;
  loading: boolean;
}

export enum EAccessRuleModalPage {
  Project = "project",
  Department = "department",
  User = "user",
  Application = "application",
}
export interface IAccessRuleManagementModalOptions {
  subjectId?: string;
  subjectType?: SubjectType;
  scopeType?: ScopeType;
  scopeName?: string;
  scopeId?: string;
  page: EAccessRuleModalPage;
  user?: IUser;
}

export interface IAccessRuleDisplayConfig {
  subject: boolean;
  users: boolean;
  roles: boolean;
  scope: boolean;
}

export interface ISubjectTypeOption extends ISelectOption {
  value: SubjectType;
}

export interface IRoleIdOption extends ISelectOption {
  value: number;
}
export interface IAccessRuleItem {
  subjectTypeOption: null | ISubjectTypeOption;
  roleOption: null | IRoleIdOption;
  scopeInput: null | IOrgTreeNodeId;
  scopeName?: string;
  scopeType?: ScopeType;
  subjectType?: SubjectType;
  roleName?: string;
  subjectId: string;
  accessRuleId?: number;
  saved: boolean;
  isInherited?: boolean;
}

export interface AccessRuleWithGroup extends AccessRule {
  group: string;
}

//table
export const allAccessRulesColumnsMap: Record<string, ITableColumn> = {
  type: {
    name: "type",
    label: "Type",
    field: (row: AccessRule) => row.subjectType,
    sortable: true,
    align: "left",
    display: true,
    format: (subjectType: SubjectType) => subjectTypeOptionsMap.get(subjectType) || "-",
  },
  group: {
    name: "group",
    label: "Group",
    field: (row: AccessRuleWithGroup) => row.group,
    sortable: true,
    align: "left",
    display: false,
    format: (group: string) => group || "-",
  },
  subject: {
    name: "subject",
    label: "Subject",
    field: (row: AccessRule) => row.subjectId,
    sortable: true,
    align: "left",
    display: true,
  },
  role: {
    name: "role",
    label: "Role",
    field: (row: AccessRule) => row.roleName,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "role-clicked" },
  },
  roleWithNoLink: {
    name: "role",
    label: "Role",
    field: (row: AccessRule) => row.roleName,
    sortable: true,
    align: "left",
    display: false,
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (row: AccessRule) => row.scopeName,
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.ORG_TREE_COL,
    customCellEvent: { emitName: "scope-clicked" },
  },
  createdBy: {
    name: "createdBy",
    label: "Authorized by",
    field: (row: AccessRule) => row.createdBy,
    sortable: true,
    align: "left",
    display: true,
    format: (createdBy: string) => createdBy || "-",
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: AccessRule) => row.createdAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  lastUpdated: {
    name: "lastUpdated",
    label: "Last updated",
    field: (row: AccessRule) => row.updatedAt,
    sortable: true,
    align: "left",
    display: true,
    format: (createdAt: string) => createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allAssignmentsColumns: Array<ITableColumn> = [
  allAccessRulesColumnsMap.type,
  allAccessRulesColumnsMap.subject,
  allAccessRulesColumnsMap.role,
  allAccessRulesColumnsMap.roleWithNoLink,
  allAccessRulesColumnsMap.scope,
  allAccessRulesColumnsMap.createdBy,
  allAccessRulesColumnsMap.creationTime,
  allAccessRulesColumnsMap.lastUpdated,
];

export const accessRulesIndexColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.type, display: true, mandatory: true },
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const accessRulesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const roleAccessRulesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.subject, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const subjectRolesMiniTableColumns: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const subjectRolesMiniTableColumnsWithSSO: Array<ITableColumn> = [
  { ...allAccessRulesColumnsMap.roleWithNoLink, display: true },
  { ...allAccessRulesColumnsMap.scope, display: true },
  { ...allAccessRulesColumnsMap.type, display: true },
  { ...allAccessRulesColumnsMap.group, display: true },
  { ...allAccessRulesColumnsMap.createdBy, display: true },
  { ...allAccessRulesColumnsMap.creationTime, display: true },
  { ...allAccessRulesColumnsMap.lastUpdated, display: true },
];

export const defaultAccessRulesFilterBy: IFilterBy = {
  sortBy: "type",
  descending: false,
  page: 1,
  rowsPerPage: 20,
  columnFilters: [],
  searchTerm: "",
  displayedColumns: [
    allAccessRulesColumnsMap.type.name,
    allAccessRulesColumnsMap.subject.name,
    allAccessRulesColumnsMap.role.name,
    allAccessRulesColumnsMap.scope.name,
    allAccessRulesColumnsMap.createdBy.name,
    allAccessRulesColumnsMap.creationTime.name,
    allAccessRulesColumnsMap.lastUpdated.name,
  ],
};

export const ResourceTypeText: Record<ResourceType, string> = {
  department: "Department",
  tenant: "Tenant",
  project: "Project",
  cluster: "Cluster",
  "cluster-config": "Cluster config",
  nodepools: "Nodepools",
  nodes: "Nodes",
  settings: "Settings",
  users: "Users",
  groups: "Groups",
  apps: "Apps",
  "dashboards-overview": "Overview dashboard",
  "dashboards-analytics": "Analytics dashboard",
  "dashboards-consumption": "Consumption dashboard",
  roles: "Roles",
  access_rules: "Access rules",
  jobs: "Jobs",
  workloads: "Workloads",
  deployments: "Deployments",
  trainings: "Trainings",
  workspaces: "Workspaces",
  environments: "Environments",
  "pvc-assets": "PVC Assets",
  "git-assets": "Git Assets",
  "host-path-assets": "Host path assets",
  "nfs-assets": "NFS assets",
  "s3-assets": "S3 assets",
  "compute-resources": "Compute resources",
  templates: "Templates",
  credentials: "Credentials",
  "events-history": "Events history",
  services: "Services",
  policies: "Policies",
};
