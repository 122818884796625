// Models
import type { ILicense, ISignedLicense } from "@/models/license.model";
import type { IPrometheusResponse } from "@/models/prometheus.model";
// Constants
import { API } from "@/common/api.constant";
// Services
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const licenseService = {
  list,
  upload,
  getTotalUsedGPUs,
};

const ENDPOINT = `${API.v1}/tenants`;

async function list(tenantId: string): Promise<Array<ILicense>> {
  return controlPlaneService.get(`${ENDPOINT}/${tenantId}/license?feature=gpu`);
}

async function upload(tenantId: string, license: File): Promise<ISignedLicense> {
  return controlPlaneService.post(`${ENDPOINT}/${tenantId}/license/signed`, license);
}

async function getTotalUsedGPUs(): Promise<number> {
  const result: Array<IPrometheusResponse> = await prometheusService.multipleQueries({
    totalGpus: "sum(runai_gpu_count_per_node) or vector(0)",
  });
  return +result[0].data[0].value[1];
}
