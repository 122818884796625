<template>
  <button :disabled="readOnly" class="editable-button" v-bind="$attrs" :class="buttonClass" type="button">
    {{ inputPlaceholder }}
    <q-menu anchor="bottom middle" self="top middle" @hide="initInput" v-model="isOpen">
      <q-card :class="displayChart ? 'with-chart' : ''">
        <q-card-section class="row justify-between items-center q-py-sm card-wrapper">
          {{ $options.RESOURCE_LABEL.MEMORY }}
          <q-btn flat size="12px" icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="q-py-sm">
          <div class="row" :class="{ 'input-row': isDepartmentEnabled }">
            <div class="col-9">
              <runai-numeric-input
                class="q-mr-md"
                :class="{ 'quota-input': isDepartmentEnabled }"
                aid="memory-quota-input"
                ref="numeric-input"
                :autofocus="true"
                v-model.number="inputValue"
                :placeholder="$options.EResourceState.Unlimited"
                input-class="wide-input"
                no-error-icon
                stack-label
                :read-only="readOnly"
              >
                <template v-if="isMemoryExceedsQuota || isMemoryUnderAllocatedNonPreemptible" #error>
                  <div v-text="alertText" class="quota-exceeds-alert" :class="textAlertClass"></div>
                </template>
              </runai-numeric-input>
            </div>
            <q-select
              class="col-3"
              :options="$options.memoryUnitOptions"
              :model-value="selectedUnit"
              @update:model-value="updateSelectedUnit"
            />
          </div>
          <template v-if="!hideSlider">
            <div class="row q-my-xl">
              <div class="col q-pr-xl">
                <quota-slider
                  v-model="inputValue"
                  :max="departmentDeservedMemoryConverted"
                  :inner-max="departmentAvailableQuota"
                  :inner-min="allocatedNonPreemptibleMemoryConverted"
                  :is-warning-on="isMemoryExceedsQuota || isMemoryUnderAllocatedNonPreemptible"
                  :is-limited="isLimitQuotaOverSubscriptionEnabled"
                  inner-max-marker-text="Department available quota"
                  inner-min-marker-text="In use by non-preemptive workloads"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="italic text-caption">
                  For more information, see the
                  <a
                    target="_blank"
                    href="https://docs.run.ai/latest/admin/admin-ui-setup/project-setup/?h=project+quotas#project-quotas"
                    >Project quotas guide</a
                  >
                </div>
              </div>
            </div>
          </template>
        </q-card-section>
        <q-card-section class="row justify-end q-py-sm">
          <q-btn
            aid="save-quota-btn"
            flat
            color="primary"
            label="Apply"
            v-close-popup="!isInputInvalid"
            @click="updateModelValue"
          />
        </q-card-section>
      </q-card>
    </q-menu>
  </button>
  <q-tooltip v-if="isAlertOn" max-width="350px" :offset="[180, 0]" :class="tooltipAlertClass" anchor="top right"
    >{{ alertText }}
  </q-tooltip>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

// cmps
import { RunaiNumericInput } from "@/components/common/runai-numeric-input";

// models
import {
  EMemoryUnit,
  EQuotaEntity,
  EResourceLabel,
  EResourceState,
  EResourceType,
  memoryUnitOptions,
} from "@/models/resource.model";
//utils
import { resourceUtil } from "@/utils/resource.util";
//stores
import { useSettingStore } from "@/stores/setting.store";
import { QuotaSlider } from "@/components/quota-management/quota-slider";

export default defineComponent({
  components: {
    QuotaSlider,
    RunaiNumericInput,
  },
  emits: ["update:model-value", "close"],
  memoryUnitOptions: memoryUnitOptions,
  EResourceState: EResourceState,
  RESOURCE_LABEL: EResourceLabel,
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null | undefined>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    departmentDeservedMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    allocatedNonPreemptibleMemory: {
      type: Number as PropType<number>,
      default: 0,
    },
    entity: {
      type: String as PropType<EQuotaEntity>,
      required: true,
    },
    isAlertOn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    departmentDeservedMemoryIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    projectsDeservedMemoryIsUnlimited: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hideSlider: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputValue: 0 as number | null,
      inputPlaceholder: "" as string,
      selectedUnit: EMemoryUnit.MB as string,
      settingStore: useSettingStore(),
      isOpen: false as boolean,
    };
  },
  created() {
    this.initInput();
  },
  computed: {
    departmentDeservedMemoryConverted(): number {
      return Math.round(resourceUtil.convertMemoryValue(this.departmentDeservedMemory).value);
    },
    allocatedNonPreemptibleMemoryConverted(): number {
      return Math.round(resourceUtil.convertMemoryValue(this.allocatedNonPreemptibleMemory).value);
    },
    isLimitQuotaOverSubscriptionEnabled(): boolean {
      if (this.entity === EQuotaEntity.department) return false;
      return this.settingStore.isLimitQuotaOverSubscriptionEnabled;
    },
    innerProjectsDeservedMemory(): number {
      if (this.modelValue === null) {
        return this.projectsDeservedMemory;
      }
      return this.projectsDeservedMemory - this.modelValue;
    },
    departmentAvailableQuota(): number {
      if (this.modelValue === null || this.modelValue === undefined) {
        return Math.round(
          resourceUtil.convertMemoryValue(this.departmentDeservedMemory - this.projectsDeservedMemory).value,
        );
      }
      if (this.selectedUnit === EMemoryUnit.MB) {
        return Math.round(
          resourceUtil.convertMemoryValue(this.modelValue + this.departmentDeservedMemory - this.projectsDeservedMemory)
            .value,
        );
      }
      return Math.round(
        resourceUtil.convertMemoryValue(this.modelValue + this.departmentDeservedMemory - this.projectsDeservedMemory)
          .value,
      );
    },
    projectsDeservedMemoryWithCurrentInput(): number {
      if (this.inputValue !== null) {
        if (this.selectedUnit === EMemoryUnit.MB) {
          return this.innerProjectsDeservedMemory + resourceUtil.fromMbToMib(this.inputValue);
        } else {
          return this.innerProjectsDeservedMemory + resourceUtil.fromGbToMib(this.inputValue);
        }
      }
      return this.innerProjectsDeservedMemory;
    },
    isInputInvalid(): boolean {
      return (
        this.isLimitQuotaOverSubscriptionEnabled &&
        (this.isMemoryExceedsQuota || this.isMemoryUnderAllocatedNonPreemptible)
      );
    },
    isMemoryUnderAllocatedNonPreemptible(): boolean {
      if (this.entity === EQuotaEntity.department || !this.isDepartmentEnabled) return false;
      if (this.inputValue === null) return false;
      return resourceUtil.isResourceUnderAllocatedNonPreemptible(
        this.inputValue || 0,
        this.allocatedNonPreemptibleMemory,
      );
    },
    departmentQuota(): number | string {
      return resourceUtil.calculateDepartmentQuota(
        this.departmentDeservedMemory,
        EResourceType.MEMORY,
        this.departmentDeservedMemoryIsUnlimited,
      );
    },
    isMemoryExceedsQuota(): boolean {
      if (this.entity === EQuotaEntity.department || !this.isDepartmentEnabled) return false;
      if (this.inputValue === null && !this.departmentDeservedMemoryIsUnlimited) return true;
      if (Math.trunc(this.departmentDeservedMemory) === Math.trunc(this.projectsDeservedMemoryWithCurrentInput)) {
        return false;
      }
      return resourceUtil.isResourceExceedsQuota(
        this.projectsDeservedMemoryWithCurrentInput,
        this.departmentDeservedMemory,
        this.departmentDeservedMemoryIsUnlimited,
      );
    },
    displayChart(): boolean {
      return this.entity === EQuotaEntity.project && this.isDepartmentEnabled;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    alertText(): string {
      if (this.isMemoryExceedsQuota) {
        const remainQuota = this.departmentDeservedMemory - (this.projectsDeservedMemory - (this.modelValue || 0));
        const quotaInMbOrGb = resourceUtil.convertMemoryValue(remainQuota);
        return remainQuota < 0
          ? "The project's quota exceeds the available department quota"
          : `The project's quota exceeds the available department quota. To remain within the quota, enter a number up to ${Math.round(
              quotaInMbOrGb.value,
            )} ${quotaInMbOrGb.selectedUnit}.`;
      }
      if (this.isMemoryUnderAllocatedNonPreemptible) {
        const allocatedNonPreemptibleMemoryInMbGb = resourceUtil.convertMemoryValue(this.allocatedNonPreemptibleMemory);
        return `The project's quota is less than the usage by non-preemptive workloads. To remain above the usage, enter a number above ${Math.round(
          allocatedNonPreemptibleMemoryInMbGb.value,
        )} ${allocatedNonPreemptibleMemoryInMbGb.selectedUnit}.`;
      }
      return "";
    },
    tooltipAlertClass(): string {
      return this.isLimitQuotaOverSubscriptionEnabled ? "bg-red-2" : "bg-orange-1";
    },
    textAlertClass(): string {
      return this.isLimitQuotaOverSubscriptionEnabled ? "text-negative" : "text-amber-14";
    },
    buttonClass(): string {
      if (this.isOpen) return "primary-border";
      if (!this.isAlertOn) return "";

      return this.isLimitQuotaOverSubscriptionEnabled ? "negative-border" : "amber-14-border";
    },
  },
  methods: {
    initInput(): void {
      if (this.modelValue !== null) {
        const convertedValue = resourceUtil.convertMemoryValue(this.modelValue);
        this.inputValue = parseFloat(convertedValue.value.toFixed(2));
        this.selectedUnit = convertedValue.selectedUnit;
        this.inputPlaceholder = `${this.inputValue} ${convertedValue.selectedUnit}`;
      } else {
        this.inputPlaceholder = EResourceState.Unlimited;
        this.inputValue = null;
      }
    },
    updateModelValue(): void {
      if (this.isInputInvalid) return;
      if (this.inputValue === null) {
        this.inputPlaceholder = EResourceState.Unlimited;
        this.$emit("update:model-value", null);
      } else if (this.selectedUnit === EMemoryUnit.MB) {
        this.$emit("update:model-value", resourceUtil.fromMbToMib(this.inputValue));
        this.inputPlaceholder = `${this.inputValue} ${this.selectedUnit}`;
      } else if (this.selectedUnit === EMemoryUnit.GB) {
        this.$emit("update:model-value", resourceUtil.fromGbToMib(this.inputValue));
        this.inputPlaceholder = `${this.inputValue} ${this.selectedUnit}`;
      }
    },
    updateSelectedUnit(unit: string): void {
      this.selectedUnit = unit;
    },
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  font-weight: 500 !important;
  font-size: 14px;
}
.quota-input {
  max-width: 231px;
  margin-bottom: 30px;
}
.with-chart {
  min-height: 400px;
  width: 400px;
}
.input-row {
  height: 110px;
}
.editable-button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.primary-border {
  border: 2px solid $primary;
}
.amber-14-border {
  border: 2px solid $amber-14;
}
.negative-border {
  border: 2px solid $negative;
}
.quota-exceeds-alert {
  padding-right: 10px;
  margin: 5px 0;
  font-size: 12px;
  max-width: 231px;
  top: 65px;
  position: absolute;
  line-height: 12px;
}
</style>
