<template>
  <section class="client-config">
    <div class="text-subtitle1">Client configuration:</div>
    <runai-code-block :code-html="clientConfigHtml" />
  </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from "vue";
// Models
import type { ITenantClientConfigInfo } from "@/models/tenant.model";
// Components
import { RunaiCodeBlock } from "@/components/common/runai-code-block";

export default defineComponent({
  name: "client-config",
  components: {
    RunaiCodeBlock,
  },
  props: {
    clientConfig: {
      type: Object as PropType<ITenantClientConfigInfo>,
      required: true,
    },
  },
  computed: {
    clientConfigHtml() {
      // unfortonately we need to render the html as string since this is the only we to render the html for the <pre> tag
      // in a way that will be identical to the required config syntax. This is ugly but creates an accurate config
      // which is critical for config presentation.
      let clientConfigHtml = `
<pre class="yaml-code">
<span class="yaml-item">- </span><span class="yaml-key">name</span><span class="yaml-colon">: </span><span class="yaml-value">runai-authenticated-user</span>
  <span class="yaml-key">user</span><span class="yaml-colon">: </span>
    <span class="yaml-key">auth-provider</span><span class="yaml-colon">: </span>
      <span class="yaml-key">config</span><span class="yaml-colon">: </span>`;

      if (this.clientConfig["airgapped"]) {
        clientConfigHtml += `
        <span class="yaml-key">airgapped</span><span class="yaml-colon">: </span><span class="yaml-value">"true"</span>`;
      }

      clientConfigHtml += `
        <span class="yaml-key">auth-flow</span><span class="yaml-colon">: </span><span class="yaml-value">${this.clientConfig["authFlow"]}</span>
        <span class="yaml-key">realm<span class="yaml-colon">: </span></span><span class="yaml-value">${this.clientConfig["realm"]}</span>
        <span class="yaml-key">client-id<span class="yaml-colon">: </span></span><span class="yaml-value">${this.clientConfig["clientId"]}</span>`;

      clientConfigHtml += `
        <span class="yaml-key">idp-issuer-url<span class="yaml-colon">: </span></span><span class="yaml-value">${this.clientConfig["idpIssuerUrl"]}</span>`;

      if (this.clientConfig["redirectUri"]) {
        clientConfigHtml += `
        <span class="yaml-key">redirect-uri</span><span class="yaml-colon">: </span><span class="yaml-value">${this.clientConfig["redirectUri"]}</span>`;
      }

      clientConfigHtml += `
      <span class="yaml-key">name</span><span class="yaml-colon">: </span><span class="yaml-value">oidc</span></pre>`;
      return clientConfigHtml;
    },
  },
});
</script>
