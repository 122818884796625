<template>
  <runai-expansion-item class="data-source-section" label="Data sources" :default-opened="defaultOpened">
    <template #subheader>
      <span>{{ dataSourceSummary }}</span>
    </template>

    <runai-form-card-section
      :loading="loading"
      empty-message="Looks like you don't have any data sources yet..."
      :main-message="cardsMainTitle"
      entity-name="data source"
      :cards-list="dataSourceCards"
      :selected-cards-ids="selectedDataSourceCards"
      icon-name="data-source-gray"
      search-name="data sources"
      multi-select
      @selected-card-changed="onSelectedDataSource"
      @create-new="$emit('create-new', $event)"
      :disabled="sectionDisabled"
      :disable-create-new="disableCreateButton"
      :sort-options="{ name: true, creation: true, recentUsage: true }"
      default-sort-option="recentUsage"
    >
      <template #add-new>
        <q-btn flat color="primary" :disabled="sectionDisabled || disableCreateButton">
          <q-icon left name="fa-regular fa-plus" size="14px"></q-icon>
          <div>new data source</div>
          <data-source-dropdown @data-source-selected="$emit('create-new', $event)" />
        </q-btn>
      </template>
    </runai-form-card-section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import type {
  AssetIdAndKind,
  DatasourceListResponseEntry,
  ComplianceInfoReason,
} from "@/swagger-models/assets-service-client";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { TCardCmpName, type ICardListItem } from "@/components/common/runai-card-list";
import type { IWorkloadDataSourceSectionOptions } from "./data-source-section.model";
// stores
import { usePermissionStore } from "@/stores/permissions.store";
// components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { DataSourceDropdown } from "@/components/data-source/data-source-dropdown";
import { RunaiFormCardSection } from "@/components/common";
import { DATA_SOURCE_TYPE } from "@/models/data-source.model";
import { EWorkloadFormType } from "@/models/workload.model";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    DataSourceDropdown,
    RunaiFormCardSection,
  },
  emits: ["datasource-changed", "create-new"],
  props: {
    entityType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    dataSources: {
      type: Array as PropType<Array<DatasourceListResponseEntry>>,
      required: true,
    },
    selectedDataSources: {
      type: Array as PropType<Array<AssetIdAndKind>>,
      required: true,
    },
    defaultOpened: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    sectionOptions: {
      type: Object as PropType<IWorkloadDataSourceSectionOptions>,
      required: false,
      default: () => ({}),
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
    };
  },
  computed: {
    disableCreateButton(): boolean {
      // only HostPathAssets is passed here simply because we do not yet have roles which have access to only specific data sources, if a role has access to one he has to all of them
      return !this.permissionStore.hasPermission(ResourceType.HostPathAssets, Action.Create);
    },
    dataSourceSummary(): string {
      if (!this.selectedDataSourceCards?.length) return "None";

      const firstSelected: DatasourceListResponseEntry = this.dataSourceMap[this.selectedDataSourceCards[0]];
      let dataSourceName: string = firstSelected.meta.name;

      if (this.selectedDataSourceCards.length > 1) {
        dataSourceName += ` +${this.selectedDataSourceCards.length - 1}`;
      }
      return dataSourceName;
    },
    dataSourceCards(): Array<ICardListItem> {
      return this.dataSources.map((dataSource: DatasourceListResponseEntry) => {
        let locked = false;
        let disabled = false;
        let tooltip = "";
        let showDisabledInfo = false;

        if (dataSource.compliance && dataSource.compliance?.imposed) {
          locked = true;
          disabled = true;
          tooltip = "This data source must stay selected based on the policy your administrator set.";
        }

        if (dataSource.compliance && !dataSource.compliance?.compliance) {
          disabled = true;
          locked = false;
          // if it does not comply due to policy vs if it does not comply for some other reason
          if (dataSource.compliance?.reason?.some((reason: ComplianceInfoReason) => reason.field)) {
            showDisabledInfo = true;
            tooltip = "This data source can't be used because it doesn't comply with the policy your administrator set.";
          } else if (dataSource.compliance?.reason?.length) {
            tooltip = dataSource.compliance.reason[0].details;
          }
        }

        const name = dataSource.meta.name;
        const type = DATA_SOURCE_TYPE[dataSource.meta.kind] || "";

        return {
          id: dataSource.meta.id,
          cardName: TCardCmpName.DATA_SOURCE,
          data: dataSource,
          searchValues: [name, type].filter((val) => !!val),
          locked,
          disabled,
          tooltip,
          showDisabledInfo,
          sortInfo: {
            name: dataSource.meta.name,
            createdAt: dataSource.meta.createdAt,
            recentUsage: dataSource.usageTimes?.lastUsedByWorkload,
          },
        };
      });
    },
    dataSourceMap(): Record<string, DatasourceListResponseEntry> {
      return this.dataSources.reduce(
        (acc: Record<string, DatasourceListResponseEntry>, dataSource: DatasourceListResponseEntry) => {
          acc[dataSource.meta.id] = dataSource;
          return acc;
        },
        {},
      );
    },
    selectedDataSourceCards(): Array<string> {
      return this.selectedDataSources.map((dataSource: AssetIdAndKind) => dataSource.id);
    },
    entityText(): string {
      if (!this.entityType || this.entityType === EWorkloadFormType.Template) return "workload";
      return this.entityType;
    },
    cardsMainTitle(): string {
      return this.sectionOptions.cardsTitle || `Select the data sources your ${this.entityText} needs to access`;
    },
  },
  methods: {
    onSelectedDataSource(selectedDataSourceIds: Array<string>): void {
      const selectedDataSourceModel: Array<AssetIdAndKind> = selectedDataSourceIds.map((dataSourceId: string) => {
        return {
          id: dataSourceId,
          kind: this.dataSourceMap[dataSourceId].meta.kind,
        };
      });
      this.$emit("datasource-changed", selectedDataSourceModel);
    },
  },
});
</script>

<style lang="scss" scoped></style>
