<template>
  <wrapper-card
    @close="$emit('close')"
    header="Idle GPU timeout"
    subheader="Limit the duration of a workload whose GPU is idle"
  >
    <template #body>
      <gray-card
        :hide-close-button="index === 0 && workloads.length == 1"
        @close="hideWorkloadSection(index)"
        v-for="(workload, index) in workloads"
        :key="workload.value"
      >
        <div class="row items-center q-pt-md">
          <div class="col-4 q-mr-md">
            <runai-select
              label="Workload type"
              @update:model-value="onWorkloadSelect(workload, $event, index)"
              :model-value="getOptionValue(workload)"
              :rules="[workloadTypeValidation]"
              :options="workloadOptions"
              aid="gpu-workload-type"
            />
          </div>
          <div class="col">
            <runai-duration-input
              hide-seconds
              @update:model-value="updateDuration(workload, $event, index)"
              :model-value="getWorkloadDuration(workload)"
              :rules="[durationValidation]"
            />
          </div>
        </div>
      </gray-card>
    </template>

    <template #actions>
      <q-btn v-if="showWorkloadsButton" @click="addWorkloadSection" color="primary" flat label="+IDLE GPU TIMEOUT" />
    </template>
  </wrapper-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// cmps
import { RunaiDurationInput } from "@/components/common/runai-duration-input";
import { WrapperCard } from "@/components/project/project-edit-form/cards/";
import { GrayCard } from "@/components/project/project-edit-form/cards/";
import { RunaiSelect } from "@/components/common/runai-select";
// models
import type { ISelectOption } from "@/models/global.model";
import type { IWorkloadDurationOption } from "@/models/workload.model";
import { EIdleWorkloadMaxDuration } from "@/models/workload.model";
import { errorMessages } from "@/common/error-message.constant";

const MAX_NUMBER_OF_LIMITED_WORKLOADS = 3;
export default defineComponent({
  components: { GrayCard, WrapperCard, RunaiDurationInput, RunaiSelect },
  emits: ["close", "add-workload", "remove-workload", "workload-changed"],
  props: {
    workloads: {
      type: Array as PropType<IWorkloadDurationOption[]>,
      required: true,
    },
  },
  computed: {
    workloadOptions(): ISelectOption[] {
      const isOptionDisabled = (value: EIdleWorkloadMaxDuration): boolean =>
        this.workloads.some((workload: IWorkloadDurationOption) => workload.value === value);
      return [
        {
          value: EIdleWorkloadMaxDuration.training,
          label: "Training",
          disable: isOptionDisabled(EIdleWorkloadMaxDuration.training),
        },
        {
          value: EIdleWorkloadMaxDuration.interactivePreemptible,
          label: "Preemptive workspaces",
          disable: isOptionDisabled(EIdleWorkloadMaxDuration.interactivePreemptible),
        },
        {
          value: EIdleWorkloadMaxDuration.interactive,
          label: "Non Preemptive workspaces",
          disable: isOptionDisabled(EIdleWorkloadMaxDuration.interactive),
        },
      ] as ISelectOption[];
    },
    showWorkloadsButton(): boolean {
      return this.workloads.length < MAX_NUMBER_OF_LIMITED_WORKLOADS;
    },
  },
  methods: {
    durationValidation(val: number): boolean | string {
      return val <= 0 ? errorMessages.DURATION_GREATER_THAN_ZERO : true;
    },
    workloadTypeValidation(option: ISelectOption | null): boolean | string {
      return !!option || errorMessages.SELECT_TYPE;
    },
    getWorkloadDuration(workload: IWorkloadDurationOption): number {
      if (workload.duration === null) {
        return 0;
      }
      return workload.duration;
    },
    getOptionValue(workload: ISelectOption): null | ISelectOption {
      if (workload.value) {
        return workload;
      }
      return null;
    },
    addWorkloadSection(): void {
      this.$emit("add-workload");
    },
    hideWorkloadSection(workloadIndex: number): void {
      this.$emit("remove-workload", workloadIndex);
    },
    onWorkloadSelect(workload: IWorkloadDurationOption, selectedWorkload: ISelectOption, workloadIndex: number): void {
      this.$emit("workload-changed", { ...selectedWorkload, duration: workload.duration }, workloadIndex);
    },
    updateDuration(workload: IWorkloadDurationOption, duration: number, workloadIndex: number): void {
      this.$emit("workload-changed", { ...workload, duration }, workloadIndex);
    },
  },
});
</script>
