<template>
  <section class="runai-drawer-nodata q-pt-xl" :class="{ dark: isDarkStyle, light: !isDarkStyle }">
    <div v-for="(message, index) of messages" class="row justify-center" :key="index">{{ message }}</div>
    <div class="row justify-center q-pt-xl">
      <q-icon :name="faIconNameAndStyle" class="empty-content-icon" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  props: {
    messages: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    faIconNameAndStyle: {
      type: String as PropType<string>,
      required: true,
    },
    isDarkStyle: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.runai-drawer-nodata {
  .light {
    background-color: $body-background-color;
    color: $black-54;
  }
  .dark {
    background-color: $navy;
    color: $white;
  }
  height: 100%;
  .empty-content-icon {
    font-size: 60px;
  }
}
</style>
