import type { IPrometheusMetric } from "@/models/prometheus.model";
import type { INodeWorkload } from "@/models/node-workload.model";

export const nodeWorkloadService = {
  updateWorkloadData,
};

export const nodeWorkloadMetrics = {
  workloadsGpus: { name: "workloadsGpus" },
  workloadsCpus: { name: "workloadsCpus" },
  workloadsMemory: { name: "workloadsMemory" },
  oldWorkloadInfo: { name: "oldWorkloadInfo" },
  workloadGpuIndex: { name: "workloadGpuIndex", extractor: "gpu" },
};

const metricLabels = {
  jobName: "job_name",
  jobType: "job_type",
  project: "project",
};

export function updateWorkloadData(
  response: Array<{ metricName: string; metric: IPrometheusMetric }>,
): Array<INodeWorkload> {
  const workloadsMap = new Map<string, Array<INodeWorkload>>();
  if (response) {
    response.forEach((record) => {
      const row = record.metric;
      const workloadToUpdate = getWorkloadToUpdate(row, workloadsMap);
      const extractor = nodeWorkloadMetrics[record.metricName]?.extractor;
      const value = extractor ? +row.metric[extractor] : +row.value[1];
      switch (record.metricName) {
        case nodeWorkloadMetrics.workloadsGpus.name:
          workloadToUpdate.allocatedGpu = workloadToUpdate.allocatedGpu ? workloadToUpdate.allocatedGpu + value : value;
          break;
        case nodeWorkloadMetrics.workloadsCpus.name:
          workloadToUpdate.allocatedCpu = !workloadToUpdate.allocatedCpu
            ? value / 1000
            : workloadToUpdate.allocatedCpu + value / 1000;
          break;
        case nodeWorkloadMetrics.workloadsMemory.name:
          workloadToUpdate.allocatedMemory = workloadToUpdate.allocatedMemory
            ? workloadToUpdate.allocatedMemory + value
            : value;
          break;
        case nodeWorkloadMetrics.workloadGpuIndex.name:
          if (!workloadToUpdate.gpuIndex) {
            workloadToUpdate.gpuIndex = [value];
          } else {
            workloadToUpdate.gpuIndex.push(value);
          }
          break;
      }
    });
  }
  return Array.from(workloadsMap.values()).flat();
}

function getWorkloadToUpdate(row: IPrometheusMetric, workloadsMap: Map<string, Array<INodeWorkload>>): INodeWorkload {
  const jobName = row.metric[metricLabels.jobName];
  const jobType = row.metric[metricLabels.jobType];
  const projectName = row.metric[metricLabels.project];
  let workloads = workloadsMap.get(jobName);
  if (!workloads) {
    workloads = [];
    workloadsMap.set(jobName, workloads);
  }
  let workloadToUpdate = workloads.find(
    (workload: INodeWorkload) => workload.project == projectName && workload.type === jobType,
  );
  if (!workloadToUpdate) {
    workloadToUpdate = {
      name: jobName,
      type: jobType,
      project: projectName,
    };
    workloads.push(workloadToUpdate);
  }
  return workloadToUpdate;
}
