import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export enum USER_ROUTES_NAMES {
  USER_INDEX = "users-index",
}

export const usersRoutes: RunaiRouteRecord = {
  path: "/users",
  component: () => import("@/views/users/users-index.vue"),
  name: USER_ROUTES_NAMES.USER_INDEX,
  meta: {
    pageTitle: "Users",
    fullWidth: true,
    supportBack: true,
    resourceType: ResourceType.Users,
    minPermittedActions: [Action.Read],
    requiresAuth: true,
  },
};
