import { alertUtil } from "@/utils/alert.util";
import type { QNotifyCreateOptions } from "quasar/dist/types";

export const connectionAlertUtil = {
  getAlert,
};

function getAlert(message: string, options = {}): QNotifyCreateOptions {
  return alertUtil.getSuccess(message, {
    timeout: 0,
    actions: [{ icon: "fa-regular fa-xmark", color: "white", flat: true, round: true, size: "sm" }],
    ...options,
  });
}
