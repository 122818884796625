<template>
  <section class="settings-index q-pt-md">
    <analytics-section :analytics-section-settings="analyticsSectionSettings" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import AnalyticsSection from "@/components/settings/sections/analytics/analytics-section.vue";

// stores
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";

// models
import type { AnalyticsSectionSettings } from "@/models/setting.model";
import { SettingKeys } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";

export default defineComponent({
  name: "new-settings-index",
  components: {
    AnalyticsSection,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      authStore: useAuthStore(),
      appStore: useAppStore(),
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    analyticsSectionSettings(): AnalyticsSectionSettings {
      const analyticsSetting: AnalyticsSectionSettings = {
        advancedGpuMetrics: this.getSettingValue(SettingKeys.AdvancedGPUMetrics),
        cpu: this.getSettingValue(SettingKeys.EnableCpuDashboards),
        consumption: this.getSettingValue(SettingKeys.ConsumptionReport),
        optimizationEnabled: this.getSettingValue(SettingKeys.DashboardOptimization),
      };
      analyticsSetting.advancedGpuMetrics.label = "Advanced metrics";
      analyticsSetting.advancedGpuMetrics.description =
        "Enable advanced GPU device-level metrics on Nodes table. To enable the advanced GPU metrics, first contact Run:ai support to configure the <a href='https://docs.nvidia.com/datacenter/dcgm/latest/user-guide/feature-overview.html#metrics' target='_blank'>NVIDIA exporter</a> and Run:ai cluster";

      analyticsSetting.optimizationEnabled.label = "Dashboards performance optimization";
      analyticsSetting.optimizationEnabled.description =
        "Improve dashboard performance by enhancing new optimized queries";
      analyticsSetting.optimizationEnabled.stage = undefined;

      analyticsSetting.cpu.label = "CPU analytics";
      analyticsSetting.cpu.description =
        "Enable the CPU analytics dashboard for better visibility for CPU intensive workloads. For more information, see the <a href='https://docs.run.ai/latest/admin/admin-ui-setup/dashboard-analysis/?h=dashboa#cpu-dashboard' target='_blank'>CPU dashboard guide</a>";

      analyticsSetting.consumption.label = "Consumption";
      analyticsSetting.consumption.description =
        "Enable the consumption dashboard to view consumption by segments. For more information, see the <a href='https://docs.run.ai/latest/admin/admin-ui-setup/dashboard-analysis/?h=consumpt#consumption-dashboard' target='_blank'>Consumption dashboard guide</a>";

      return analyticsSetting;
    },
  },
  methods: {
    getSettingValue(key: string): Setting {
      return this.settingStore.settings.get(key) as Setting;
    },
  },
});
</script>

<style lang="scss">
.settings-container {
  margin: auto;
  width: 800px;

  .runai-expansion-header-content-left {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
