/* eslint-disable */
import { useAuthStore } from "@/stores/auth.store";
import type { IWebSocketSubscription } from "@/models/global.model";
import { storageUtil } from "@/utils/storage.util";
import { USE_EXTERNAL_TOKEN } from "@/common/storage.constant";

const webSocketsMap: Record<string, WebSocket> = {};

function getUrlByServer(baseUrl: string, endpoint: string): string {
  const fixedUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;
  const wsUrl: string = fixedUrl.replace("http", "ws");
  return `${wsUrl}${endpoint}`;
}

function unsubscribe(socketId: string): void {
  const ws: WebSocket = webSocketsMap[socketId];
  if (!ws) {
    console.error(`WebSocket ${socketId} not found`);
    return;
  }
  ws.close();
  console.info(`WebSocket ${socketId} closed`);
  delete webSocketsMap[socketId];
}

export const webSocketsService = {
  // INFRA
  subscribe(baseUrl: string, endpoint: string, params: any): IWebSocketSubscription {
    // if cluster and backend apis use different tokens this will fail authentication. currently we use/support ws for cluster
    const accessToken: string = !!storageUtil.get(USE_EXTERNAL_TOKEN)
      ? useAuthStore().externalToken
      : useAuthStore().accessToken;
    const ws: WebSocket = new WebSocket(getUrlByServer(baseUrl, endpoint), ["bearer", accessToken]);
    const socketId: string = `${endpoint}-${Date.now()}`;
    webSocketsMap[socketId] = ws;

    const delegations: IWebSocketSubscription = {
      onMessage: (messageEvent: MessageEvent) => {},
      onError: (errorEvent: Event) => {},
      onOpen: (openEvent: Event) => {},
      onClose: (closeEvent: CloseEvent) => {},
      unsubscribe: () => unsubscribe.bind(this)(socketId),
    };

    ws.onopen = (openEvent: Event): void => {
      ws.send(JSON.stringify(params));
      delegations.onOpen && delegations.onOpen(openEvent);
    };

    ws.onmessage = (message: MessageEvent): void => {
      delegations.onMessage && delegations.onMessage(message);
    };

    ws.onerror = (error): void => {
      delegations.onError && delegations.onError(error);
    };

    ws.onclose = (closeEvent: CloseEvent): void => {
      delegations.onClose && delegations.onClose(closeEvent);
      console.info(`WebSocket closed`);
      return;
    };

    return delegations;
  },
};
