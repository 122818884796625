import OidcIndex from "@/views/oidc/oidc-index.vue";
import type { RunaiRouteRecord } from "vue-router";

export enum OIDC_ROUTE_NAMES {
  OAUTH_CODE = "oauth-code",
}

export const oidcRoutes: RunaiRouteRecord = {
  path: "/oauth-code",
  component: OidcIndex,
  name: OIDC_ROUTE_NAMES.OAUTH_CODE,
  meta: {
    requiresAuth: false,
    fullPage: true,
    minPermittedActions: null,
    resourceType: null,
  },
};
