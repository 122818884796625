// Stores
import { defineStore } from "pinia";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { Role } from "@/swagger-models/authorization-client";
import { defaultRolesFilterBy } from "@/models/roles.model";
import { rolesService } from "@/services/control-plane/rbac/roles.service/roles.service";

export const useRoleStore = defineStore("Role", {
  state: () => ({
    roles: [] as Array<Role>,
    loading: false as boolean,
    namespaces: {} as Record<string, string | undefined>,
    filterBy: {} as IFilterBy,
  }),
  getters: {
    roleList(): Array<Role> {
      return this.roles;
    },
  },
  actions: {
    async loadRoles(filterBy?: IFilterBy): Promise<void> {
      this.loading = true;
      this.filterBy = filterBy || defaultRolesFilterBy;
      this.roles = await rolesService.list(filterBy);
      this.loading = false;
    },
    getRoleById(id: number): Role {
      return this.roles.find((role: Role) => role.id === id) as Role;
    },
  },
});
