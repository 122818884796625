<template>
  <div class="oidc-index">
    <div class="header">
      <q-btn flat round disable aria-label="Menu" size="12px" icon="fa-sharp fa-regular fa-bars" class="btn-menu" />
      <runai-svg-icon name="runai-logo" class="logo" size="54"></runai-svg-icon>
    </div>
    <section class="content-container">
      <section class="content">
        <p>{{ message }}</p>
        <runai-code-block v-if="code" class="text-subtitle1" :code-html="code" />
      </section>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiCodeBlock } from "@/components/common/runai-code-block";

// stores
import { useAppStore } from "@/stores/app.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
    RunaiCodeBlock,
  },
  data() {
    return {
      appStore: useAppStore(),
      message:
        `Login Successful. Copy the text below and paste it in the terminal where the login attempt originated.` as string,
      code: "" as string,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    const code: string | undefined = this.$route.query.code?.toString();
    if (code) {
      this.code = code;
    } else {
      this.message =
        "No OIDC auth code detected, either you reached this page by mistake or you should redo the auth flow.";
    }
  },
});
</script>

<style lang="scss" scoped>
.oidc-index {
  .header {
    padding: 0 12px;
    height: 56px;
    background: $navy;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    display: flex;
    align-items: center;
    text-align: right;
    color: rgba(255, 255, 255, 0.15);

    .btn-menu {
      opacity: 1 !important;
    }
    .logo {
      margin-left: 1.8rem;
    }
  }
  .content-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  .content {
    font-size: large;
    position: relative;
    width: 70%;
    border-radius: 8px;
    max-width: 900px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.08), 4px 4px 10px rgba(0, 0, 0, 0.04);
    background-color: $white;
    color: black;
    padding: 20px;
  }
}
</style>
