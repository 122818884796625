import { defineStore } from "pinia";

import type { IAssetsFilter } from "@/models/filter.model";
import type {
  EnvironmentAsset,
  EnvironmentCreationRequest,
  EnvironmentUpdateRequest,
} from "@/swagger-models/assets-service-client";
import { Action, type PermittedScopes, ResourceType } from "@/swagger-models/authorization-client";
import { environmentService } from "@/services/control-plane/environment.service/environment.service";
import { registryService } from "@/services/control-plane/registry.service/registry.service";
import { permissionService } from "@/services/authorization/permission.service/permission.service";
import { useClusterStore } from "./cluster.store";

export const useEnvironmentStore = defineStore("Environment", {
  state: () => ({
    environments: [] as Array<EnvironmentAsset>,
    registryId: null as string | null,
    actionPermissionsByScopes: {} as Record<Action, PermittedScopes>,
  }),
  getters: {
    environmentList(): Array<EnvironmentAsset> {
      return this.environments;
    },
  },
  actions: {
    async loadActionPermissions(): Promise<void> {
      if (Object.keys(this.actionPermissionsByScopes).length > 0) return;
      this.actionPermissionsByScopes = await permissionService.getPermittedScopesForAction(ResourceType.Environments);
    },
    async loadEnvironments(filterBy: IAssetsFilter = {}): Promise<void> {
      filterBy.clusterId = useClusterStore().currentCluster.uuid;
      this.environments = await environmentService.list(filterBy);
      return;
    },
    async loadById(environmentId: string): Promise<EnvironmentAsset> {
      let environment: EnvironmentAsset | undefined = this.environmentList.find(
        (env: EnvironmentAsset) => env.meta.id === environmentId,
      );
      if (!environment) environment = await environmentService.getById(environmentId);
      return environment;
    },
    async remove(environmentId: string): Promise<void> {
      await environmentService.remove(environmentId);
    },
    async update(environmentId: string, environment: EnvironmentUpdateRequest): Promise<void> {
      await environmentService.update(environmentId, environment);
    },
    async createEnvironment(environment: EnvironmentCreationRequest): Promise<EnvironmentAsset> {
      return await environmentService.save(environment);
    },
    async loadRegistry(): Promise<void> {
      try {
        const registries = await registryService.getRegistries();
        if (registries?.entries?.length > 0) {
          this.registryId = registries.entries[0].meta.id;
        }
      } catch (error: unknown) {
        console.error("Cannot get registry id.", error);
        return;
      }
    },
  },
});
