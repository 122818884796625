import { environmentService } from "@/services/control-plane/environment.service/environment.service";
import type { IUIWorkloadEnvSectionModel } from "./environment-section.model";
import type { Connection, EnvironmentAsset, EnvironmentAssetSpec } from "@/swagger-models/assets-service-client";
import type { IUIConnection } from "@/models/environment.model";

export const environmentSectionUtil = {
  getSpecificEnvFromEnvironment,
};

function getSpecificEnvFromEnvironment(environment: EnvironmentAsset): IUIWorkloadEnvSectionModel {
  const spec: EnvironmentAssetSpec = environment.spec;
  const specificEnv: IUIWorkloadEnvSectionModel = {
    args: spec.args || "",
    command: spec.command || undefined,
    runAsUid: spec.runAsUid || null,
    runAsGid: spec.runAsGid || null,
    supplementalGroups: spec.supplementalGroups,
    environmentVariables: spec.environmentVariables || [],
    connections:
      spec.connections
        ?.filter((c: Connection) => {
          return c.internalToolInfo?.nodePortInfo?.isCustomPort || c.internalToolInfo?.externalUrlInfo?.isCustomUrl;
        })
        .map((c: Connection) => {
          const uiConnection = environmentService.prepareConnectionForUI(c) as IUIConnection;
          const { name, externalUrl, nodePort } = uiConnection;
          return { name, externalUrl, nodePort };
        }) || [],
  };

  return specificEnv;
}
