<template>
  <runai-drawer-content>
    <template v-slot:header>
      <runai-svg-icon
        v-if="workload?.connections?.length"
        :name="toolTypeIcon"
        size="34"
        :key="toolTypeIcon"
      ></runai-svg-icon>
      <div class="ellipsis">{{ workloadName }}</div>
      <div class="workload-status" v-if="workload?.job">
        <runai-status v-if="statusOptions" :options="statusOptions" />
      </div>
    </template>

    <template v-slot:subheader>
      <q-tabs class="drawer-tabs" active-color="primary" indicator-color="primary" v-model="drawerTab">
        <q-tab :name="drawerTabsOptions.eventHistory" label="event history" />
        <q-tab :name="drawerTabsOptions.metrics" label="metrics" />
        <q-tab :name="drawerTabsOptions.logs" label="logs" />
      </q-tabs>
    </template>

    <template v-slot:body>
      <runai-drawer-no-data
        v-if="!workload"
        :messages="[`Select a ${entityType} to view its details.`]"
        fa-icon-name-and-style="fa-thin fa-memo-circle-info"
      />
      <q-tab-panels v-else v-model="drawerTab" animated>
        <q-tab-panel class="q-pa-none" :name="drawerTabsOptions.eventHistory">
          <workload-history :drawer-width="drawerWidth" :workload="workload" />
        </q-tab-panel>
        <q-tab-panel class="q-pa-none" :name="drawerTabsOptions.metrics">
          <workload-metrics-tab :workload="workload" :drawer-width="drawerWidth" />
        </q-tab-panel>
        <q-tab-panel class="q-pa-none" :name="drawerTabsOptions.logs">
          <runai-log-reader
            :entity-type="entityType"
            :log-params="logParams"
            :loading-logs="loadingLogs"
            :key="logReaderStartTime"
            :show-logs="showLogs"
            :pods-options="podsOptions"
            :selected-pod-name="selectedPodName"
            @update-selected-pod="setSelectedPod"
          ></runai-log-reader>
        </q-tab-panel>
      </q-tab-panels>
    </template>
  </runai-drawer-content>
</template>

<script lang="ts">
// cmps
import { RunaiDrawerContent } from "@/components/common/runai-drawer-content";
import { RunaiLogReader } from "@/components/common/runai-log-reader";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiStatus } from "@/components/common/runai-status/";
import { RunaiDrawerNoData } from "@/components/common/runai-drawer-no-data";
import { WorkloadHistory } from "@/components/old-workload/workload-history";
import { WorkloadMetricsTab } from "@/components/old-workload/workload-metrics-tab";

// types
import type { PropType } from "vue";
import { ToolType } from "@/swagger-models/assets-service-client";
import type { Pod } from "@/models/pods.model";
import type { IToolItem, IWorkspaceList } from "@/models/workspace.model";
import type { ITrainingList } from "@/models/training.model";

// models
import type { ILogParams } from "@/models/logs.model";
import { toolTypeIconsMap } from "@/common/icons.constant";
import { useClusterStore } from "@/stores/cluster.store";

// services
import { podService } from "@/services/control-plane/pod.service/pod.service";

// stores
import { EWorkloadStatus } from "@/common/status.constant";
import { ErrorAlert } from "@/utils/error-alert.util";
import { defineComponent } from "vue";
import type { IStatusColOptions } from "@/models/table.model";
import { workloadUtil } from "@/utils/workload.util";

const TAIL_LENGTH = 1000;

enum EWorkloadDrawerTabs {
  eventHistory,
  metrics,
  logs,
}

export default defineComponent({
  components: {
    RunaiLogReader,
    RunaiSvgIcon,
    RunaiStatus,
    RunaiDrawerNoData,
    RunaiDrawerContent,
    WorkloadMetricsTab,
    WorkloadHistory,
  },
  props: {
    workload: {
      type: Object as PropType<IWorkspaceList | ITrainingList>,
      required: false,
    },
    drawerWidth: {
      type: String as PropType<string>,
      required: true,
    },
    entityType: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      drawerTabsOptions: EWorkloadDrawerTabs,
      pods: null as Pod[] | null,
      selectedPodName: null as string | null,
      logParams: null as ILogParams | null,
      logReaderStartTime: new Date().getTime() as number,
      drawerTab: EWorkloadDrawerTabs.eventHistory as EWorkloadDrawerTabs,
      loadingLogs: false as boolean,
    };
  },
  computed: {
    showLogs(): boolean {
      const status: string | undefined = this.workload?.job?.status;
      return (
        status === EWorkloadStatus.Active || status === EWorkloadStatus.Failed || status === EWorkloadStatus.Completed
      );
    },
    toolTypeIcon(): string {
      const connections: Array<IToolItem> | undefined = this.workload?.connections;
      if (!connections || connections.length === 0) return this.getIcon(ToolType.Custom);

      return this.getIcon(connections[0].toolType);
    },
    currentWorkloadId(): string {
      return this.workload?.meta.id || "";
    },
    workloadName(): string {
      const capitalizedEntityType = this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1);
      return this.workload?.meta.name || `${capitalizedEntityType} details`;
    },
    jobPodGroupId(): string | undefined {
      return this.workload?.job?.podGroupId;
    },
    podsOptions(): string[] {
      if (!this.pods) return [];
      return this.pods?.map((pod: Pod) => pod.name);
    },
    statusOptions(): IStatusColOptions | null {
      if (!this.workload?.job) return null;
      return { ...workloadUtil.getWorkspaceTrainingStatusColOptions(this.workload.job), displayAnimation: false };
    },
  },
  methods: {
    getIcon(iconName: string): string {
      return toolTypeIconsMap[iconName];
    },
    async loadPods(): Promise<void> {
      if (!this.workload?.job?.podGroupId) return;
      try {
        this.loadingLogs = true;
        this.pods = await podService.getPods(this.workload.job.podGroupId, this.clusterStore.currentCluster.uuid);
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: "Cannot get pod logs",
        });
        this.$q.notify(errorAlert.getNotification(error));
      } finally {
        this.logReaderStartTime = new Date().getTime();
        this.loadingLogs = false;
      }
    },
    setSelectedPod(podName: string): void {
      this.selectedPodName = podName;
      this.setLogParams();
    },
    setLogParams(): void {
      const wl: IWorkspaceList | ITrainingList | undefined = this.workload;
      if (!wl || !wl.job?.project || !this.selectedPodName) return;
      this.logParams = {
        project: wl.job.project,
        jobName: wl.meta.name,
        podName: this.selectedPodName,
        tail: TAIL_LENGTH,
      };
    },
  },
  watch: {
    currentWorkloadId: {
      async handler(): Promise<void> {
        this.logParams = null;
        await this.loadPods();
        if (!this.pods || !this.pods.length) return;
        this.selectedPodName = this.pods[0].name;
        this.setLogParams();
      },
      immediate: true,
    },
    jobPodGroupId: {
      async handler(newVal: string | undefined): Promise<void> {
        if (!newVal || this.loadingLogs) return;
        await this.loadPods();
        if (!this.pods || !this.pods.length) return;
        this.selectedPodName = this.pods[0].name;
        this.setLogParams();
      },
    },
  },
});
</script>
<style lang="scss">
.drawer-tabs {
  .q-tab {
    padding: 0 30px;
    min-width: 150px;
  }
}
</style>
