/* tslint:disable */
/* eslint-disable */
/**
 * Authorization
 * An API for managing RunAi authorization objects and perform as the RunAi authorization layer.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessRule
 */
export interface AccessRule {
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof AccessRule
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AccessRule
     */
    'scopeType': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'scopeName': string;
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessRule
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRule
     */
    'createdBy': string;
}
/**
 * 
 * @export
 * @interface AccessRuleCreationFields
 */
export interface AccessRuleCreationFields {
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof AccessRuleCreationFields
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {number}
     * @memberof AccessRuleCreationFields
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'scopeId': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof AccessRuleCreationFields
     */
    'scopeType': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof AccessRuleCreationFields
     */
    'clusterId'?: string;
}
/**
 * 
 * @export
 * @interface AccessRuleForSync
 */
export interface AccessRuleForSync {
    /**
     * Phase of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleForSync
     */
    'phase': AccessRuleForSyncPhaseEnum;
    /**
     * Message for status of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleForSync
     */
    'message': string;
    /**
     * The id of the access rule to retrieve
     * @type {number}
     * @memberof AccessRuleForSync
     */
    'accessRuleId': number;
}

export const AccessRuleForSyncPhaseEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady'
} as const;

export type AccessRuleForSyncPhaseEnum = typeof AccessRuleForSyncPhaseEnum[keyof typeof AccessRuleForSyncPhaseEnum];

/**
 * 
 * @export
 * @interface AccessRuleForSyncAllOf
 */
export interface AccessRuleForSyncAllOf {
    /**
     * The id of the access rule to retrieve
     * @type {number}
     * @memberof AccessRuleForSyncAllOf
     */
    'accessRuleId': number;
}
/**
 * 
 * @export
 * @interface AccessRuleStatusRequest
 */
export interface AccessRuleStatusRequest {
    /**
     * Phase of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleStatusRequest
     */
    'phase': AccessRuleStatusRequestPhaseEnum;
    /**
     * Message for status of Access Rule in the cluster
     * @type {string}
     * @memberof AccessRuleStatusRequest
     */
    'message': string;
}

export const AccessRuleStatusRequestPhaseEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady'
} as const;

export type AccessRuleStatusRequestPhaseEnum = typeof AccessRuleStatusRequestPhaseEnum[keyof typeof AccessRuleStatusRequestPhaseEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const Action = {
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete',
    Sync: 'sync'
} as const;

export type Action = typeof Action[keyof typeof Action];


/**
 * 
 * @export
 * @interface BackendEvaluationRequest
 */
export interface BackendEvaluationRequest {
    /**
     * 
     * @type {string}
     * @memberof BackendEvaluationRequest
     */
    'resourceId'?: string | null;
    /**
     * 
     * @type {ResourceType}
     * @memberof BackendEvaluationRequest
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {Action}
     * @memberof BackendEvaluationRequest
     */
    'requestAction': Action;
    /**
     * 
     * @type {boolean}
     * @memberof BackendEvaluationRequest
     */
    'expectedToAllow'?: boolean | null;
}
/**
 * 
 * @export
 * @interface EnrichmentDataFields
 */
export interface EnrichmentDataFields {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDataFields
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDataFields
     */
    'scopeName': string;
}
/**
 * 
 * @export
 * @interface GetAccessRules200Response
 */
export interface GetAccessRules200Response {
    /**
     * 
     * @type {number}
     * @memberof GetAccessRules200Response
     */
    'totalRecords': number;
    /**
     * 
     * @type {number}
     * @memberof GetAccessRules200Response
     */
    'displayRecords': number;
    /**
     * 
     * @type {Array<AccessRule>}
     * @memberof GetAccessRules200Response
     */
    'accessRules': Array<AccessRule>;
}
/**
 * 
 * @export
 * @interface GetPermittedScopesRequest
 */
export interface GetPermittedScopesRequest {
    /**
     * 
     * @type {ResourceType}
     * @memberof GetPermittedScopesRequest
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {string}
     * @memberof GetPermittedScopesRequest
     */
    'action'?: GetPermittedScopesRequestActionEnum;
}

export const GetPermittedScopesRequestActionEnum = {
    Create: 'create',
    Read: 'read',
    Update: 'update',
    Delete: 'delete'
} as const;

export type GetPermittedScopesRequestActionEnum = typeof GetPermittedScopesRequestActionEnum[keyof typeof GetPermittedScopesRequestActionEnum];

/**
 * 
 * @export
 * @interface InternalCreateAccessRuleRequest
 */
export interface InternalCreateAccessRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequest
     */
    'subjectId': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof InternalCreateAccessRuleRequest
     */
    'subjectType': SubjectType;
    /**
     * 
     * @type {InternalCreateAccessRuleRequestScope}
     * @memberof InternalCreateAccessRuleRequest
     */
    'scope'?: InternalCreateAccessRuleRequestScope | null;
}
/**
 * 
 * @export
 * @interface InternalCreateAccessRuleRequestScope
 */
export interface InternalCreateAccessRuleRequestScope {
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'id': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'type': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof InternalCreateAccessRuleRequestScope
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MetadataFields
 */
export interface MetadataFields {
    /**
     * 
     * @type {number}
     * @memberof MetadataFields
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MetadataFields
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetadataFields
     */
    'createdBy': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {ResourceType}
     * @memberof Permission
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {Array<Action>}
     * @memberof Permission
     */
    'actions': Array<Action>;
}
/**
 * 
 * @export
 * @interface PermittedScopes
 */
export interface PermittedScopes {
    /**
     * 
     * @type {string}
     * @memberof PermittedScopes
     */
    'tenant'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'clusters'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'departments'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermittedScopes
     */
    'projects'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PermittedScopesActions
 */
export interface PermittedScopesActions {
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'create': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'read': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'update': PermittedScopes;
    /**
     * 
     * @type {PermittedScopes}
     * @memberof PermittedScopesActions
     */
    'delete': PermittedScopes;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResourceType = {
    Department: 'department',
    Tenant: 'tenant',
    Project: 'project',
    Cluster: 'cluster',
    ClusterConfig: 'cluster-config',
    Nodepools: 'nodepools',
    Nodes: 'nodes',
    Settings: 'settings',
    Users: 'users',
    Groups: 'groups',
    Apps: 'apps',
    DashboardsOverview: 'dashboards-overview',
    DashboardsAnalytics: 'dashboards-analytics',
    DashboardsConsumption: 'dashboards-consumption',
    Roles: 'roles',
    AccessRules: 'access_rules',
    Jobs: 'jobs',
    Workloads: 'workloads',
    Deployments: 'deployments',
    Trainings: 'trainings',
    Workspaces: 'workspaces',
    Environments: 'environments',
    PvcAssets: 'pvc-assets',
    GitAssets: 'git-assets',
    HostPathAssets: 'host-path-assets',
    NfsAssets: 'nfs-assets',
    S3Assets: 's3-assets',
    ComputeResources: 'compute-resources',
    Templates: 'templates',
    Credentials: 'credentials',
    EventsHistory: 'events-history',
    Services: 'services',
    Policies: 'policies'
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];


/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof Role
     */
    'permissions': Array<Permission>;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdBy': string;
}
/**
 * May contain additional creation fields that are not updatable
 * @export
 * @interface RoleCreationFields
 */
export interface RoleCreationFields {
    /**
     * 
     * @type {string}
     * @memberof RoleCreationFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreationFields
     */
    'description': string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof RoleCreationFields
     */
    'permissions': Array<Permission>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    Tenant: 'tenant',
    Department: 'department',
    Project: 'project',
    Cluster: 'cluster'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubjectType = {
    User: 'user',
    App: 'app',
    Group: 'group'
} as const;

export type SubjectType = typeof SubjectType[keyof typeof SubjectType];


/**
 * 
 * @export
 * @interface UpdateScopeAccessRulesRequest
 */
export interface UpdateScopeAccessRulesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateScopeAccessRulesRequest
     */
    'scopeName': string;
}

/**
 * AccessRulesApi - axios parameter creator
 * @export
 */
export const AccessRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an access rule. Bind a predefined role to a subject (user, group or app) in some scope.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule: async (accessRuleCreationFields: AccessRuleCreationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleCreationFields' is not null or undefined
            assertParamExists('createAccessRule', 'accessRuleCreationFields', accessRuleCreationFields)
            const localVarPath = `/api/v1/authorization/access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleCreationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete access rule. This operation will delete the subject permissions that assigned to it by this access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessRule: async (accessRuleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('deleteAccessRule', 'accessRuleId', accessRuleId)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRule: async (accessRuleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('getAccessRule', 'accessRuleId', accessRuleId)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules: async (subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectIdFilter !== undefined) {
                localVarQueryParameter['subjectIdFilter'] = subjectIdFilter;
            }

            if (subjectIds) {
                localVarQueryParameter['subjectIds'] = subjectIds.join(COLLECTION_FORMATS.csv);
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync all Access Rules statuses from k8s cluster to Run:AI backend
         * @summary Sync the statuses of the access rules in the cluster.
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessRules: async (clusterIdPath: string, accessRuleForSync?: Array<AccessRuleForSync>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterIdPath' is not null or undefined
            assertParamExists('syncAccessRules', 'clusterIdPath', clusterIdPath)
            const localVarPath = `/api/v1/authorization/access-rules/{clusterIdPath}/sync`
                .replace(`{${"clusterIdPath"}}`, encodeURIComponent(String(clusterIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleForSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the status of the access rule in the cluster.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessRuleStatus: async (accessRuleId: number, clusterIdPath: string, accessRuleStatusRequest: AccessRuleStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessRuleId' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'accessRuleId', accessRuleId)
            // verify required parameter 'clusterIdPath' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'clusterIdPath', clusterIdPath)
            // verify required parameter 'accessRuleStatusRequest' is not null or undefined
            assertParamExists('updateAccessRuleStatus', 'accessRuleStatusRequest', accessRuleStatusRequest)
            const localVarPath = `/api/v1/authorization/access-rules/{accessRuleId}/{clusterIdPath}/status`
                .replace(`{${"accessRuleId"}}`, encodeURIComponent(String(accessRuleId)))
                .replace(`{${"clusterIdPath"}}`, encodeURIComponent(String(clusterIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRuleStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessRulesApi - functional programming interface
 * @export
 */
export const AccessRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an access rule. Bind a predefined role to a subject (user, group or app) in some scope.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessRule(accessRuleCreationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete access rule. This operation will delete the subject permissions that assigned to it by this access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessRule(accessRuleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessRule(accessRuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRule(accessRuleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRule(accessRuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccessRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sync all Access Rules statuses from k8s cluster to Run:AI backend
         * @summary Sync the statuses of the access rules in the cluster.
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAccessRules(clusterIdPath: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAccessRules(clusterIdPath, accessRuleForSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the status of the access rule in the cluster.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessRuleStatus(accessRuleId: number, clusterIdPath: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessRuleStatus(accessRuleId, clusterIdPath, accessRuleStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessRulesApi - factory interface
 * @export
 */
export const AccessRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessRulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an access rule. Bind a predefined role to a subject (user, group or app) in some scope.
         * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.createAccessRule(accessRuleCreationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete access rule. This operation will delete the subject permissions that assigned to it by this access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessRule(accessRuleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccessRule(accessRuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access rule.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRule(accessRuleId: number, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.getAccessRule(accessRuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List access rules.
         * @param {string} [subjectType] The type of resource we want to filter by.
         * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {boolean} [includeDeleted] True to include deleted objects in the result.
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] The scope resource id that we want to filter by.
         * @param {number} [roleId] The role id we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, options?: any): AxiosPromise<GetAccessRules200Response> {
            return localVarFp.getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync all Access Rules statuses from k8s cluster to Run:AI backend
         * @summary Sync the statuses of the access rules in the cluster.
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccessRules(clusterIdPath: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: any): AxiosPromise<void> {
            return localVarFp.syncAccessRules(clusterIdPath, accessRuleForSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the status of the access rule in the cluster.
         * @param {number} accessRuleId The id of the access rule to retrieve
         * @param {string} clusterIdPath The clusterId of the current status update
         * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessRuleStatus(accessRuleId: number, clusterIdPath: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAccessRuleStatus(accessRuleId, clusterIdPath, accessRuleStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessRulesApi - object-oriented interface
 * @export
 * @class AccessRulesApi
 * @extends {BaseAPI}
 */
export class AccessRulesApi extends BaseAPI {
    /**
     * 
     * @summary Create an access rule. Bind a predefined role to a subject (user, group or app) in some scope.
     * @param {AccessRuleCreationFields} accessRuleCreationFields The access rule to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public createAccessRule(accessRuleCreationFields: AccessRuleCreationFields, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).createAccessRule(accessRuleCreationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete access rule. This operation will delete the subject permissions that assigned to it by this access rule.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public deleteAccessRule(accessRuleId: number, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).deleteAccessRule(accessRuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access rule.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public getAccessRule(accessRuleId: number, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).getAccessRule(accessRuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List access rules.
     * @param {string} [subjectType] The type of resource we want to filter by.
     * @param {string} [subjectIdFilter] Part of the subject id that we want to filter by.
     * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {string} [lastUpdated] Filter by last update time.
     * @param {boolean} [includeDeleted] True to include deleted objects in the result.
     * @param {string} [clusterId] The cluster id to filter the response for.
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {string} [scopeId] The scope resource id that we want to filter by.
     * @param {number} [roleId] The role id we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public getAccessRules(subjectType?: string, subjectIdFilter?: string, subjectIds?: Array<string>, limit?: number, offset?: number, lastUpdated?: string, includeDeleted?: boolean, clusterId?: string, scopeType?: string, scopeId?: string, roleId?: number, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).getAccessRules(subjectType, subjectIdFilter, subjectIds, limit, offset, lastUpdated, includeDeleted, clusterId, scopeType, scopeId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync all Access Rules statuses from k8s cluster to Run:AI backend
     * @summary Sync the statuses of the access rules in the cluster.
     * @param {string} clusterIdPath The clusterId of the current status update
     * @param {Array<AccessRuleForSync>} [accessRuleForSync] The statuses to sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public syncAccessRules(clusterIdPath: string, accessRuleForSync?: Array<AccessRuleForSync>, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).syncAccessRules(clusterIdPath, accessRuleForSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the status of the access rule in the cluster.
     * @param {number} accessRuleId The id of the access rule to retrieve
     * @param {string} clusterIdPath The clusterId of the current status update
     * @param {AccessRuleStatusRequest} accessRuleStatusRequest The status of the access rule in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRulesApi
     */
    public updateAccessRuleStatus(accessRuleId: number, clusterIdPath: string, accessRuleStatusRequest: AccessRuleStatusRequest, options?: AxiosRequestConfig) {
        return AccessRulesApiFp(this.configuration).updateAccessRuleStatus(accessRuleId, clusterIdPath, accessRuleStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeAccessRules: async (scopeType: string, scopeId: string, lastUpdated?: string, roles?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('deleteScopeAccessRules', 'scopeType', scopeType)
            // verify required parameter 'scopeId' is not null or undefined
            assertParamExists('deleteScopeAccessRules', 'scopeId', scopeId)
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectAccessRules: async (subjectType: string, subjectId: string, lastUpdated?: string, roles?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectType' is not null or undefined
            assertParamExists('deleteSubjectAccessRules', 'subjectType', subjectType)
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('deleteSubjectAccessRules', 'subjectId', subjectId)
            const localVarPath = `/internal/authorization/subject-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluatePermission: async (backendEvaluationRequest: BackendEvaluationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backendEvaluationRequest' is not null or undefined
            assertParamExists('evaluatePermission', 'backendEvaluationRequest', backendEvaluationRequest)
            const localVarPath = `/internal/authorization/evaluate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(backendEvaluationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeAccessRules: async (clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }

            if (departmentIds !== undefined) {
                localVarQueryParameter['departmentIds'] = departmentIds;
            }

            if (projectIds !== undefined) {
                localVarQueryParameter['projectIds'] = projectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectAccessRules: async (subjectType: string, subjectIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subjectType' is not null or undefined
            assertParamExists('getSubjectAccessRules', 'subjectType', subjectType)
            const localVarPath = `/internal/authorization/subject-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subjectType !== undefined) {
                localVarQueryParameter['subjectType'] = subjectType;
            }

            if (subjectIds) {
                localVarQueryParameter['subjectIds'] = subjectIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets user as an admin user.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdminUser: async (internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCreateAccessRuleRequest' is not null or undefined
            assertParamExists('setAdminUser', 'internalCreateAccessRuleRequest', internalCreateAccessRuleRequest)
            const localVarPath = `/internal/authorization/admin-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCreateAccessRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an access rule with a cluster app role.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClusterApp: async (internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalCreateAccessRuleRequest' is not null or undefined
            assertParamExists('setClusterApp', 'internalCreateAccessRuleRequest', internalCreateAccessRuleRequest)
            const localVarPath = `/internal/authorization/cluster-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCreateAccessRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an access rule with an installer app role.
         * @param {number} xRunaiTenantId The id of the tenant
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInstallerApp: async (xRunaiTenantId: number, internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xRunaiTenantId' is not null or undefined
            assertParamExists('setInstallerApp', 'xRunaiTenantId', xRunaiTenantId)
            // verify required parameter 'internalCreateAccessRuleRequest' is not null or undefined
            assertParamExists('setInstallerApp', 'internalCreateAccessRuleRequest', internalCreateAccessRuleRequest)
            const localVarPath = `/internal/authorization/installer-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRunaiTenantId !== undefined && xRunaiTenantId !== null) {
                localVarHeaderParameter['X-Runai-Tenant-Id'] = String(JSON.stringify(xRunaiTenantId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalCreateAccessRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeAccessRules: async (scopeType: string, scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'scopeType', scopeType)
            // verify required parameter 'scopeId' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'scopeId', scopeId)
            // verify required parameter 'updateScopeAccessRulesRequest' is not null or undefined
            assertParamExists('updateScopeAccessRules', 'updateScopeAccessRulesRequest', updateScopeAccessRulesRequest)
            const localVarPath = `/internal/authorization/scope-access-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateScopeAccessRulesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScopeAccessRules(scopeType: string, scopeId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubjectAccessRules(subjectType: string, subjectId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubjectAccessRules(subjectType, subjectId, lastUpdated, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermittedScopes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evaluatePermission(backendEvaluationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccessRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubjectAccessRules(subjectType, subjectIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets user as an admin user.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAdminUser(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAdminUser(internalCreateAccessRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an access rule with a cluster app role.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setClusterApp(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setClusterApp(internalCreateAccessRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an access rule with an installer app role.
         * @param {number} xRunaiTenantId The id of the tenant
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInstallerApp(xRunaiTenantId: number, internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessRule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInstallerApp(xRunaiTenantId, internalCreateAccessRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScopeAccessRules(scopeType: string, scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScopeAccessRules(scopeType: string, scopeId: string, lastUpdated?: string, roles?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete access rules that assigned to the requested subject.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {string} subjectId The subject id that we want to filter by.
         * @param {string} [lastUpdated] Filter by last update time.
         * @param {string} [roles] The roles we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubjectAccessRules(subjectType: string, subjectId: string, lastUpdated?: string, roles?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSubjectAccessRules(subjectType, subjectId, lastUpdated, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
         * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: any): AxiosPromise<PermittedScopes> {
            return localVarFp.evaluatePermission(backendEvaluationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access rules that assigned to the requested scope resource
         * @param {string} [clusterId] The cluster id to filter the response for.
         * @param {string} [roles] The roles we want to filter by.
         * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
         * @param {string} [projectIds] The project scope resource ids that we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: any): AxiosPromise<GetAccessRules200Response> {
            return localVarFp.getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List specific subject access rules.
         * @param {string} subjectType The type of resource we want to filter by.
         * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: any): AxiosPromise<Array<AccessRule>> {
            return localVarFp.getSubjectAccessRules(subjectType, subjectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets user as an admin user.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAdminUser(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.setAdminUser(internalCreateAccessRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an access rule with a cluster app role.
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClusterApp(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.setClusterApp(internalCreateAccessRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an access rule with an installer app role.
         * @param {number} xRunaiTenantId The id of the tenant
         * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInstallerApp(xRunaiTenantId: number, internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: any): AxiosPromise<AccessRule> {
            return localVarFp.setInstallerApp(xRunaiTenantId, internalCreateAccessRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update access rules that assigned to the requested scope resource
         * @param {string} scopeType The type of resource we want to filter by.
         * @param {string} scopeId The scope resource id that we want to filter by.
         * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScopeAccessRules(scopeType: string, scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary Delete access rules that assigned to the requested scope resource
     * @param {string} scopeType The type of resource we want to filter by.
     * @param {string} scopeId The scope resource id that we want to filter by.
     * @param {string} [lastUpdated] Filter by last update time.
     * @param {string} [roles] The roles we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteScopeAccessRules(scopeType: string, scopeId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteScopeAccessRules(scopeType, scopeId, lastUpdated, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete access rules that assigned to the requested subject.
     * @param {string} subjectType The type of resource we want to filter by.
     * @param {string} subjectId The subject id that we want to filter by.
     * @param {string} [lastUpdated] Filter by last update time.
     * @param {string} [roles] The roles we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deleteSubjectAccessRules(subjectType: string, subjectId: string, lastUpdated?: string, roles?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deleteSubjectAccessRules(subjectType, subjectId, lastUpdated, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Evaluate user permission to the requested resource. The returned permitted scopes are the scopes that the action on the resource is permitted, with OR between the multiple scopes.
     * @param {BackendEvaluationRequest} backendEvaluationRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public evaluatePermission(backendEvaluationRequest: BackendEvaluationRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).evaluatePermission(backendEvaluationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access rules that assigned to the requested scope resource
     * @param {string} [clusterId] The cluster id to filter the response for.
     * @param {string} [roles] The roles we want to filter by.
     * @param {string} [departmentIds] The department scope resource ids that we want to filter by.
     * @param {string} [projectIds] The project scope resource ids that we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getScopeAccessRules(clusterId?: string, roles?: string, departmentIds?: string, projectIds?: string, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getScopeAccessRules(clusterId, roles, departmentIds, projectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List specific subject access rules.
     * @param {string} subjectType The type of resource we want to filter by.
     * @param {Array<string>} [subjectIds] The ids of the subjects to filter the response for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getSubjectAccessRules(subjectType: string, subjectIds?: Array<string>, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getSubjectAccessRules(subjectType, subjectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets user as an admin user.
     * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public setAdminUser(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).setAdminUser(internalCreateAccessRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an access rule with a cluster app role.
     * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public setClusterApp(internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).setClusterApp(internalCreateAccessRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an access rule with an installer app role.
     * @param {number} xRunaiTenantId The id of the tenant
     * @param {InternalCreateAccessRuleRequest} internalCreateAccessRuleRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public setInstallerApp(xRunaiTenantId: number, internalCreateAccessRuleRequest: InternalCreateAccessRuleRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).setInstallerApp(xRunaiTenantId, internalCreateAccessRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update access rules that assigned to the requested scope resource
     * @param {string} scopeType The type of resource we want to filter by.
     * @param {string} scopeId The scope resource id that we want to filter by.
     * @param {UpdateScopeAccessRulesRequest} updateScopeAccessRulesRequest The access rule fields to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateScopeAccessRules(scopeType: string, scopeId: string, updateScopeAccessRulesRequest: UpdateScopeAccessRulesRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateScopeAccessRules(scopeType, scopeId, updateScopeAccessRulesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user permissions summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate user permitted scopes for an action on a resource.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedScopes: async (getPermittedScopesRequest: GetPermittedScopesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPermittedScopesRequest' is not null or undefined
            assertParamExists('getPermittedScopes', 'getPermittedScopesRequest', getPermittedScopesRequest)
            const localVarPath = `/api/v1/authorization/permitted-scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPermittedScopesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user permissions summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Permission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate user permitted scopes for an action on a resource.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermittedScopesActions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermittedScopes(getPermittedScopesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user permissions summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(options?: any): AxiosPromise<Array<Permission>> {
            return localVarFp.getPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate user permitted scopes for an action on a resource.
         * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: any): AxiosPromise<PermittedScopesActions> {
            return localVarFp.getPermittedScopes(getPermittedScopesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @summary Get user permissions summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermissions(options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate user permitted scopes for an action on a resource.
     * @param {GetPermittedScopesRequest} getPermittedScopesRequest The request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermittedScopes(getPermittedScopesRequest: GetPermittedScopesRequest, options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermittedScopes(getPermittedScopesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create role
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationFields: RoleCreationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreationFields' is not null or undefined
            assertParamExists('createRole', 'roleCreationFields', roleCreationFields)
            const localVarPath = `/api/v1/authorization/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (roleIdPath: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdPath' is not null or undefined
            assertParamExists('deleteRole', 'roleIdPath', roleIdPath)
            const localVarPath = `/api/v1/authorization/roles/{roleIdPath}`
                .replace(`{${"roleIdPath"}}`, encodeURIComponent(String(roleIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (roleIdPath: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdPath' is not null or undefined
            assertParamExists('getRole', 'roleIdPath', roleIdPath)
            const localVarPath = `/api/v1/authorization/roles/{roleIdPath}`
                .replace(`{${"roleIdPath"}}`, encodeURIComponent(String(roleIdPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get roles list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorization/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create role
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationFields: RoleCreationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(roleIdPath: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(roleIdPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(roleIdPath: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(roleIdPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get roles list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create role
         * @param {RoleCreationFields} roleCreationFields The role to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationFields: RoleCreationFields, options?: any): AxiosPromise<Role> {
            return localVarFp.createRole(roleCreationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(roleIdPath: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(roleIdPath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get role
         * @param {number} roleIdPath The id of the role to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(roleIdPath: number, options?: any): AxiosPromise<Role> {
            return localVarFp.getRole(roleIdPath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get roles list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create role
     * @param {RoleCreationFields} roleCreationFields The role to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRole(roleCreationFields: RoleCreationFields, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRole(roleCreationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete role
     * @param {number} roleIdPath The id of the role to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRole(roleIdPath: number, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRole(roleIdPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get role
     * @param {number} roleIdPath The id of the role to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRole(roleIdPath: number, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRole(roleIdPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get roles list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


