/* eslint-disable */
import Axios from "axios";
import { httpResponseService } from "@/services/infra/http-response.service/http-response.service";
import type { HttpErrorResponse, IHTTPOptions, RequestHeaders } from "@/models/http-response.model";
import { ForbiddenError, UnauthorizedError } from "@/models/http-response.model";
import { useAuthStore } from "@/stores/auth.store";
import qs from "qs";

type Method = "POST" | "GET" | "DELETE" | "PUT" | "PATCH";

export const httpService = {
  request,
};

const axios = Axios.create({});

async function request<T = any>(
  baseUrl: string,
  endpoint: string,
  method: Method = "GET",
  options: IHTTPOptions,
  data: any = {},
  query: any = {},
): Promise<any> {
  const authStore = useAuthStore();

  // Configure arrayFormat to serialize arrays as comma-separated strings
  const paramsSerializer = (params: any) => {
    return qs.stringify(params, {
      arrayFormat: "comma",
      // Other qs options can be added here based on your needs
    });
  };

  try {
    let headers: RequestHeaders = { "content-type": "application/json" };
    if (options.headers) {
      headers = { ...headers, ...options.headers };
    }

    headers["Authorization"] = `Bearer ${authStore.accessToken}`;

    if (options.useExternalToken) {
      headers["AuthInfo"] = `Bearer ${authStore.accessToken}`;
      headers["Authorization"] = `Bearer ${authStore.externalToken}`;
    }

    const res = await axios({
      url: `${baseUrl}${endpoint}`,
      method,
      data,
      headers,
      responseType: options.responseType,
      params: query,
      paramsSerializer: paramsSerializer,
    });
    return res.data;
  } catch (err: any) {
    if (err.response && err.response.status === UnauthorizedError.statusCode) {
      authStore.logout();
      return;
    }

    let errorObj: HttpErrorResponse;
    if (err.response && err.response.status === ForbiddenError.statusCode) {
      errorObj = httpResponseService.getError(ForbiddenError.statusCode);
      throw errorObj;
    }

    if (typeof err === "object" && !err.response) {
      errorObj = httpResponseService.getError(-1);
      errorObj.message = err.message;

      throw errorObj;
    }

    errorObj = httpResponseService.getError(err.response.data.code || err.response.status);
    if (err.response.data.details?.includes("workload-controller")) {
      errorObj.message = _handleWorkLoadError(err.response.data);
    } else if (err.response.data.message) {
      errorObj.message = err.response.data.message;
    } else if (err.response.data.description) {
      errorObj.message = err.response.data.description;
    }

    throw errorObj;
  }
}

function _handleWorkLoadError(data: any, generalMSG?: string): string {
  let toastErrorMsg: string = generalMSG || "Something went wrong... please try again later";
  if (!data.details) {
    try {
      toastErrorMsg = data.message || JSON.stringify(data);
    } catch (e: unknown) {
      console.error(e);
    } finally {
      return toastErrorMsg;
    }
  }
  const errDetails: string = data.details;
  if (errDetails.includes("Internal error occurred")) {
    toastErrorMsg = "Internal error occurred";
  } else if (errDetails.includes("denied the request:")) {
    toastErrorMsg = errDetails.split("denied the request:")[1];
  } else {
    toastErrorMsg = errDetails;
  }
  return toastErrorMsg;
}
