<template>
  <section class="settings-index q-pt-md">
    <runai-expansion-wrapper>
      <runai-expansion-item class="settings-container" label="Settings" default-opened disable-closing>
        <template v-if="settings.length">
          <div v-for="(setting, index) in settings" :key="setting.key" class="column">
            <component
              v-if="setting.key && customComponents[setting.key]"
              :is="customComponents[setting.key]"
              :setting="setting"
              @update-setting="updateSettings"
            />
            <component
              v-else
              :is="getSettingComponent(setting.type)"
              :setting="setting"
              @update-setting="updateSettings"
            />
            <q-separator v-if="index !== settings.length - 1" />
          </div>
        </template>
      </runai-expansion-item>

      <runai-expansion-item class="settings-container" label="Researcher Authentication" default-opened>
        <researcher-authentication />
      </runai-expansion-item>

      <admin-message v-if="adminMessageSetting" :setting="adminMessageSetting" />
    </runai-expansion-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// models
import { SettingKeys, type IAdminMessageSetting } from "@/models/setting.model";
import type { Setting } from "@/swagger-models/backend-client";

// cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";

// stores
import { useAppStore } from "@/stores/app.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAuthStore } from "@/stores/auth.store";

//util
import { alertUtil } from "@/utils/alert.util";

//custom components
import { BooleanSetting } from "@/components/settings/boolean-setting";
import { InputSetting } from "@/components/settings/input-setting";
import { WandbConfiguration } from "@/components/settings/custom-settings/wandb-configuration";
import { RegistryIntegration } from "@/components/settings/custom-settings/registry-integration";
import { EnableInactivityLogout } from "@/components/settings/custom-settings/enable-inactivity-logout";
import { ResearcherAuthentication } from "@/components/settings/custom-settings/researcher-authentication";
import { LoginWithSso } from "@/components/settings/custom-settings/login-with-sso";
import { ResourcesCost } from "@/components/settings/custom-settings/resources-cost";
import { AdminMessage } from "@/components/settings/custom-settings/admin-message";

export default defineComponent({
  name: "settings-index",
  components: {
    BooleanSetting,
    InputSetting,
    WandbConfiguration,
    RegistryIntegration,
    EnableInactivityLogout,
    ResearcherAuthentication,
    LoginWithSso,
    RunaiExpansionWrapper,
    RunaiExpansionItem,
    ResourcesCost,
    AdminMessage,
  },
  data() {
    return {
      settingStore: useSettingStore(),
      authStore: useAuthStore(),
      appStore: useAppStore(),
      customComponents: {
        [SettingKeys.EnableWandbSweeps]: "wandb-configuration",
        [SettingKeys.RegistryIntegration]: "registry-integration",
        [SettingKeys.EnableInactivityLogout]: "enable-inactivity-logout",
        [SettingKeys.ProjectPermissionUse]: "researcher-authentication",
        [SettingKeys.LoginWithSSO]: "login-with-sso",
        [SettingKeys.ConsumptionReport]: "resources-cost",
      } as Record<string, string>,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
  },
  computed: {
    settings(): Setting[] {
      return this.settingStore.settingList.filter(
        (setting: Setting) => setting.active && setting.key !== SettingKeys.ProjectPermissionUse,
      );
    },
    adminMessageSetting(): IAdminMessageSetting | undefined {
      return this.settingStore.settingList.find((setting: Setting) => setting.key === SettingKeys.AdminMessage) as
        | IAdminMessageSetting
        | undefined;
    },
  },
  methods: {
    getBadgeColor(stage: "Alpha" | "Beta"): string {
      return stage === "Alpha" ? "negative" : "amber-5";
    },
    async updateSettings(key: SettingKeys, value: string | number | boolean | null): Promise<void> {
      if (value === null) return;
      try {
        await this.settingStore.updateSetting(key, value);
        await this.authStore.loadTenant();
        this.$q.notify(alertUtil.getSuccess("Setting updated successfully"));
      } catch (err) {
        this.$q.notify(alertUtil.getError("Failed to update setting"));
      }
    },
    getSettingComponent(settingType: string | undefined): string {
      if (settingType === "Boolean") {
        return "boolean-setting";
      }
      return "input-setting";
    },
  },
});
</script>

<style lang="scss">
.settings-container {
  margin: auto;
  width: 800px;

  .runai-expansion-header-content-left {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
