import { defineStore } from "pinia";
import type { IAssetsFilter } from "@/models/filter.model";
import type { ClusterControlPlaneConfigAgentClient } from "@/swagger-models/backend-client";
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { SubjectType } from "@/swagger-models/authorization-client";
import type {
  IApplication,
  IApplicationCreationRequeset,
  IApplicationCreationResponse,
  IApplicationRegenerateSecretResponse,
} from "@/models/applications.model";
import { applicationsService } from "@/services/control-plane/applications.service/applications.service";
import type { IAccessRuleRecords } from "@/models/access-rule.model";
import { enrichApplicationsWithRoles } from "@/utils/rbac.util/access-rule.util/access-rule.util";
export const useApplicationsStore = defineStore("Application", {
  state: () => ({
    applications: [] as IApplication[],
    selected: null as IApplication | null,
    lastCreatedApplicationId: null as null | string,
    registryId: null as string | null,
    runaiInstallerClientDetails: null as ClusterControlPlaneConfigAgentClient | null,
  }),
  getters: {
    applicationList(): IApplication[] {
      return this.applications.filter((application: IApplication) => application.id !== this.lastCreatedApplicationId);
    },
    lastCreatedApplication(): IApplication | null {
      if (!this.lastCreatedApplicationId) return null;
      const lastCreated: IApplication | null =
        this.applications.find((application: IApplication) => application.id === this.lastCreatedApplicationId) || null;
      return lastCreated;
    },
    selectedApplication(): IApplication | null {
      return this.selected;
    },
  },
  actions: {
    async loadApplications(filterBy: IAssetsFilter = {}): Promise<void> {
      let applications: IApplication[];
      let appsAccessRules: IAccessRuleRecords = { displayRecords: 0, totalRecords: 0, accessRules: [] };
      try {
        applications = await applicationsService.list(filterBy);
      } catch (e) {
        console.error(e);
        throw e;
      }

      if (applications.length > 0) {
        try {
          appsAccessRules = await accessRuleService.getAccessRules({
            subjectType: SubjectType.App,
          });
        } catch (e) {
          console.error(e);
        }
        applications = enrichApplicationsWithRoles(applications, appsAccessRules);
      }

      this.applications = applications;
    },
    setSelectedApplication(application: IApplication | null): void {
      this.selected = application;
    },
    async remove(application: IApplication): Promise<void> {
      await applicationsService.remove(application.id);
    },
    setLastCreatedApplicationId(applicationId: string): void {
      this.lastCreatedApplicationId = applicationId;
    },
    removeLastCreatedApplication(): void {
      this.lastCreatedApplicationId = null;
    },
    isApplicationNameExists(name: string): boolean {
      return !!this.applicationList.find((app) => app.name === name);
    },
    async create(appCreationRequest: IApplicationCreationRequeset): Promise<IApplicationCreationResponse> {
      return await applicationsService.create(appCreationRequest);
    },
    async regenerateSecret(id: string): Promise<IApplicationRegenerateSecretResponse> {
      return await applicationsService.regenerateSecret(id);
    },
    async loadRunaiInstallerClientDetails(): Promise<void> {
      this.runaiInstallerClientDetails = await applicationsService.getRunAiInstallerAppClient();
    },
  },
});
