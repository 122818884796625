<template>
  <q-btn
    size="sm"
    class="q-mx-sm"
    aid="run-page-action-select-columns-btn"
    flat
    icon="fa-regular fa-ellipsis-vertical"
    label="More"
    stack
  >
    <q-menu class="more-options-menu" transition-show="scale" transition-hide="scale">
      <q-list style="min-width: 145px" separator v-for="(item, index) in actionItems" :key="index">
        <q-item clickable v-close-popup>
          <q-item-section @click="item.action">{{ item.label }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ActionMenuItem } from "@/components/runai-page-actions/model";

export default defineComponent({
  name: "runai-actions-menu",
  props: {
    actionItems: {
      type: Array as PropType<ActionMenuItem[]>,
      required: false,
    },
  },
});
</script>
