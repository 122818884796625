<template>
  <notification-alert-bar v-if="shouldShowClusterWarning" :level="statusWarningLevel" :message="clusterWarningMessage" />
</template>
<script lang="ts">
import { NotificationAlertBar } from "@/components/common/notification-alert-bar";
import { adminWarningStatuses, nonAdminWarningStatuses } from "@/models/cluster.model";
import { useClusterStore } from "@/stores/cluster.store";
import { useAuthStore } from "@/stores/auth.store";
import { defineComponent } from "vue";
import { ENotificationBarStatusLevel } from "@/models/app.model";
import { intervalUtil } from "@/utils/interval.util";
import { INTERVAL_LABELS } from "@/common/interval.constant";
import { ClusterDisplayedStatus, ClusterDisplayedStatusStateEnum } from "@/swagger-models/cluster-service-client";

const FIFTEEN_SECONDS = 15 * 1000;
export default defineComponent({
  components: {
    NotificationAlertBar,
  },
  emits: [],
  data() {
    return {
      clusterStore: useClusterStore(),
      authStore: useAuthStore(),
    };
  },
  async created() {
    // start cluster polling
    await this.loadClusters();
    intervalUtil.startInterval(INTERVAL_LABELS.CLUSTER_STATUS_BAR, this.loadClusters, FIFTEEN_SECONDS);
  },
  computed: {
    hasClusters(): boolean {
      return this.clusterStore.clusters.length > 0;
    },
    statusWarningLevel(): ENotificationBarStatusLevel {
      return ENotificationBarStatusLevel.WARNING;
    },
    statusState(): ClusterDisplayedStatusStateEnum | undefined {
      return this.clusterStore.currentCluster?.status?.state;
    },
    isAdminWarningStatus(): boolean {
      return this.statusState != undefined && adminWarningStatuses.includes(this.statusState);
    },
    isNonAdminWarningStatus(): boolean {
      return this.statusState != undefined && nonAdminWarningStatuses.includes(this.statusState);
    },
    isDashboardWarningStatus(): boolean {
      const prometheusServiceStatus =
        this.clusterStore.currentCluster.status?.operands?.prometheus?.unreadyThresholdCrossed ?? false;
      return (
        this.$route.path.startsWith("/dashboards") &&
        this.clusterStore.currentCluster.status?.state === ClusterDisplayedStatusStateEnum.ServiceIssues &&
        prometheusServiceStatus
      );
    },
    shouldShowClusterWarning(): boolean {
      if (!this.hasClusters || this.$route.path.startsWith("/clusters")) {
        return false;
      }
      return this.authStore.isAdmin
        ? this.isAdminWarningStatus
        : this.isNonAdminWarningStatus || this.isDashboardWarningStatus;
    },
    clusterWarningMessage(): string {
      const status = this.clusterStore.currentCluster.status;
      const clusterName = this.clusterStore.currentCluster.name;
      if (!status || !clusterName) return "";
      if (this.authStore.isAdmin) {
        return this.showAdminWarning(status);
      } else {
        return this.showNonAdminWarning(status);
      }
    },
  },
  methods: {
    async loadClusters(): Promise<void> {
      await this.clusterStore.loadClusters({});
    },
    showAdminWarning(status: ClusterDisplayedStatus | undefined): string {
      const clusterName = this.clusterStore.currentCluster.name;
      switch (status?.state) {
        case ClusterDisplayedStatusStateEnum.ServiceIssues:
          if (status.operands == undefined) {
            return "";
          } else {
            return `*Some Run:ai services in cluster ${clusterName} are not functioning properly*. Actions may be affected, and the data may be stale. For more information, see [Clusters](/clusters).`;
          }
        case ClusterDisplayedStatusStateEnum.MissingPrerequisites:
          return `*Prerequisites are missing from cluster ${clusterName}.* Actions may be affected, and the data may be stale. For more information, see [Clusters](/clusters).`;
        case ClusterDisplayedStatusStateEnum.Disconnected:
          return `*There's no communication from cluster ${clusterName}.* Actions may be affected, and the data may be stale. For more information, see [Clusters](/clusters).`;
        default:
          return "";
      }
    },
    showNonAdminWarning(status: ClusterDisplayedStatus | undefined): string {
      const clusterName = this.clusterStore.currentCluster.name;
      switch (status.state) {
        case ClusterDisplayedStatusStateEnum.ServiceIssues:
          return `*Issues were found with cluster ${clusterName} and some metrics aren't available right now*. You can continue using the platform as usual. If this persists, contact your administrator.`;
        case ClusterDisplayedStatusStateEnum.MissingPrerequisites:
          return `*Issues were found with cluster ${clusterName}*. Actions may be affected, and the data may be stale. If this persists, contact your administrator."`;
        case ClusterDisplayedStatusStateEnum.Disconnected:
          return `*Issues were found with cluster ${clusterName}*. Actions may be affected, and the data may be stale. If this persists, contact your administrator.`;
        default:
          return "";
      }
    },
  },
  unmounted() {
    intervalUtil.stopInterval(INTERVAL_LABELS.CLUSTER_STATUS_BAR);
  },
});
</script>
