<template>
  <runai-expansion-item label="Department" default-opened :section-invalid="sectionInvalid">
    <template #subheader>
      {{ expansionSubHeader }}
    </template>
    <div class="project-section-subheader q-mb-md">Set under which department to create this project</div>
    <div class="row items-center">
      <div class="col-7">
        <q-select
          aid="department-select"
          option-label="label"
          option-value="value"
          input-debounce="0"
          use-input
          v-model="selectedDepartment"
          @filter="filterDepartments"
          :options="options"
          :rules="[isEmpty]"
          no-error-icon
          :disable="!isNewProject"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
// cmps

// services
import { isEmpty, isNotEmpty } from "@/common/form.validators";
// stores
import { useDepartmentStore } from "@/stores/department.store";
import { errorMessages } from "@/common/error-message.constant";

// models
import type { ISelectOption, SelectUpdateFilterFunction } from "@/models/global.model";
import type { IDepartment } from "@/models/department.model";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";

export default defineComponent({
  components: { RunaiExpansionItem },
  emits: ["update-selected-department-id", "is-section-invalid"],
  props: {
    selectedDepartmentId: {
      type: Number as PropType<number>,
      default: -1,
    },
  },
  data() {
    return {
      departmentStore: useDepartmentStore(),
      selectedDepartment: null as ISelectOption | null,
      options: [] as ISelectOption[],
    };
  },
  created() {
    if (this.departments.length === 0) {
      this.selectedDepartment = null;
    } else {
      this.selectedDepartment =
        this.departments.find((department: ISelectOption) => department.value === this.selectedDepartmentId) || null;
    }
  },
  computed: {
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
    departments(): ISelectOption[] {
      return this.departmentStore.departmentList.map((department: IDepartment) => {
        return {
          label: department.name,
          value: department.id,
        };
      });
    },
    sectionInvalid(): boolean {
      if (this.selectedDepartment === null) {
        return true;
      } else {
        return isEmpty(this.selectedDepartment.label);
      }
    },
    expansionSubHeader(): string {
      if (this.selectedDepartment) {
        return this.selectedDepartment.label;
      }
      return "None";
    },
  },
  methods: {
    filterDepartments(textInput: string, update: SelectUpdateFilterFunction): void {
      if (textInput === "") {
        update(() => {
          this.options = this.departments;
        });
        return;
      }
      update(() => {
        const needle = textInput.toLowerCase();
        this.options = this.departments.filter(
          (department: ISelectOption) => department.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },
    isEmpty(selectedOption: ISelectOption | null): boolean | string {
      if (selectedOption === null) {
        return errorMessages.SELECT_DEPARTMENT;
      } else {
        return isNotEmpty(selectedOption.label) || errorMessages.SELECT_DEPARTMENT;
      }
    },
  },
  watch: {
    selectedDepartment() {
      if (this.selectedDepartment) {
        this.$emit("update-selected-department-id", this.selectedDepartment.value);
      }
    },
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>
