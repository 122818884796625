import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import type { ITableColumn } from "@/models/table.model";
import { ECustomCell, type IStatusColOptions } from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { memoryFormat } from "@/utils/format.util";
import { oldWorkloadUtil } from "@/utils/old-workload.util/old-workload.util";
import { EWorkloadStatus, EWorkloadStatusColor } from "@/common/status.constant";
import type { Pod } from "@/models/pods.model";
import type { RouteRecordName } from "vue-router";
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";

export const COMPLETION_STATUSES = ["Failed", "Succeeded", "Completed"];
export const RUNAI_WORKLOAD = "RunaiJob";
export const INFERENCE_WORKLOAD = "Inference";
export const workloadAssetsRoutes: RouteRecordName[] = [
  TRAINING_ROUTE_NAMES.TRAINING_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_COMPUTE_RESOURCE_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_ENVIRONMENT_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
  TRAINING_ROUTE_NAMES.TRAINING_NFS_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_S3_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_GIT_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_PVC_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_HOST_PATH_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_COMPUTE_RESOURCE_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_ENVIRONMENT_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_NFS_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_S3_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_PVC_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_GIT_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_HOST_PATH_NEW,
];
export const allWorkloadColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "jobName",
    label: "Workload",
    field: (row: DisplayedJob) => row.jobName,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: "jobType",
    label: "Type",
    field: (row: DisplayedJob) => row.jobType,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: DisplayedJob) => row.status,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (status: EPodGroupStatus): IStatusColOptions | string => {
      return status && oldWorkloadUtil.getStatusColOptions(statusToPhaseMapping[status] || status);
    },
    exportFormat: (row: DisplayedJob) => row?.status || "NA",
    filterKey: "status",
  },
  createdBy: {
    name: "user",
    label: "Created by",
    field: (row: DisplayedJob) => row.user,
    sortable: true,
    align: "left",
  },
  project: {
    name: "project",
    label: "Project",
    field: (row: DisplayedJob) => row.project,
    sortable: true,
    align: "left",
  },
  nodePool: {
    name: "nodePool",
    label: "Node pool",
    field: (row: DisplayedJob) => row.nodePool,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  runningPodsVsRequestedPods: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (row: DisplayedJob) => row,
    format: (row: DisplayedJob) => [
      `${row.running ?? 0}/${(row.running ?? 0) + (row.failed ?? 0) + (row.pending ?? 0) + (row.succeeded ?? 0)}`,
    ],
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "pods-clicked" },
    hideFilter: true,
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: DisplayedJob) => row.creationTime,
    format: (creationTime: string | undefined) => {
      return !creationTime ? "-" : dateUtil.dateFormat(new Date(creationTime), "dd/MM/yyyy HH:mm a");
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  completionTime: {
    name: "completionTime",
    label: "Completion time",
    field: (row: DisplayedJob) => row,
    format: (workload: DisplayedJob) => oldWorkloadUtil.getWorkloadCompletionTime(workload),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedGPU: {
    name: "requestedGPU",
    label: "Requested GPU (Devices)",
    field: (row: DisplayedJob) => row.totalRequestedGPUs,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUMemory: {
    name: "requestedCPUMemory",
    label: "Requested CPU memory",
    field: (row: DisplayedJob) => row.totalRequestedMemory,
    format: (value: number) => (value || value === 0 ? memoryFormat(value) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUCompute: {
    name: "requestedCPUCompute",
    label: "Requested CPU compute",
    field: (row: DisplayedJob) => row.totalRequestedCPU,
    sortable: false,
    align: "left",
    hideFilter: true,
  },
};

export const allWorkloadsColumns: Array<ITableColumn> = [
  allWorkloadColumnsMap.name,
  allWorkloadColumnsMap.type,
  allWorkloadColumnsMap.status,
  allWorkloadColumnsMap.createdBy,
  allWorkloadColumnsMap.project,
  allWorkloadColumnsMap.nodePool,
  allWorkloadColumnsMap.runningPodsVsRequestedPods,
  allWorkloadColumnsMap.requestedGPU,
  allWorkloadColumnsMap.requestedCPUMemory,
  allWorkloadColumnsMap.requestedCPUCompute,
];

export const workloadIndexColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true, mandatory: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.createdBy, display: true },
  { ...allWorkloadColumnsMap.project, display: true },
  { ...allWorkloadColumnsMap.nodePool, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPods, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: true },
  { ...allWorkloadColumnsMap.completionTime, display: false },
  { ...allWorkloadColumnsMap.requestedGPU, display: false },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: false },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: false },
];
export enum EWorkloadPhase {
  Active = "Active",
  Stopped = "Stopped",
  Pending = "Pending",
  Completed = "Completed",
  Failed = "Failed",
  Initializing = "Initializing",
  Submitted = "Submitted",
  Unknown = "Unknown",
}

export enum EPodGroupStatus {
  Pending = "Pending",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
  ContainerCreating = "ContainerCreating",
  Submitted = "Submitted",
  CrashLoopBackOff = "CrashLoopBackOff",
  Completed = "Completed",
  Init = "Init", // includes all states that start with InitPrefix (Init:0/1, Init:1/3, etc.)
  PodInitializing = "PodInitializing",
  ImagePullBackOff = "ImagePullBackOff",
  ErrImagePull = "ErrImagePull",
  Suspended = "Suspended",
  Error = "Error",
  Timeout = "Timeout",
  StartError = "StartError",
  DeadlineExceeded = "DeadlineExceeded",
  Preempted = "Preempted",
  UnexpectedAdmissionError = "UnexpectedAdmissionError",
}
export const WorkloadPhaseMap: Record<EWorkloadPhase, IStatusColOptions> = {
  [EWorkloadPhase.Initializing]: {
    status: "Initializing...",
    displayAnimation: true,
    filterKey: "status",
  },
  [EWorkloadPhase.Pending]: {
    status: "Pending",
    displayAnimation: false,
    color: EWorkloadStatusColor.pending,
    filterKey: "status",
  },
  [EWorkloadPhase.Active]: {
    status: "Active",
    displayAnimation: false,
    color: EWorkloadStatusColor.active,
    filterKey: "status",
  },
  [EWorkloadPhase.Completed]: {
    status: "Completed",
    displayAnimation: false,
    filterKey: "status",
  },
  [EWorkloadPhase.Failed]: {
    status: "Failed",
    color: EWorkloadStatusColor.failed,
    displayAnimation: false,
    filterKey: "status",
  },
  [EWorkloadPhase.Stopped]: {
    status: "Stopped",
    color: EWorkloadStatusColor.failed,
    displayAnimation: false,
    filterKey: "status",
  },
  [EWorkloadPhase.Unknown]: {
    status: "Not Ready",
    color: EWorkloadStatusColor.failed,
    displayAnimation: false,
    filterKey: "status",
  },
  [EWorkloadPhase.Submitted]: {
    status: "Submitted",
    color: EWorkloadStatusColor.active,
    displayAnimation: false,
    filterKey: "status",
  },
};
export const statusToPhaseMapping: Record<EPodGroupStatus, EWorkloadPhase> = {
  [EPodGroupStatus.Pending]: EWorkloadPhase.Pending,
  [EPodGroupStatus.Running]: EWorkloadPhase.Active,
  [EPodGroupStatus.Succeeded]: EWorkloadPhase.Completed,
  [EPodGroupStatus.Failed]: EWorkloadPhase.Failed,
  [EPodGroupStatus.ContainerCreating]: EWorkloadPhase.Initializing,
  [EPodGroupStatus.Submitted]: EWorkloadPhase.Submitted,
  [EPodGroupStatus.CrashLoopBackOff]: EWorkloadPhase.Failed,
  [EPodGroupStatus.Completed]: EWorkloadPhase.Completed,
  [EPodGroupStatus.Init]: EWorkloadPhase.Initializing,
  [EPodGroupStatus.PodInitializing]: EWorkloadPhase.Initializing,
  [EPodGroupStatus.ImagePullBackOff]: EWorkloadPhase.Failed,
  [EPodGroupStatus.ErrImagePull]: EWorkloadPhase.Failed,
  [EPodGroupStatus.Suspended]: EWorkloadPhase.Stopped,
  [EPodGroupStatus.Error]: EWorkloadPhase.Failed,
  [EPodGroupStatus.Timeout]: EWorkloadPhase.Failed,
  [EPodGroupStatus.StartError]: EWorkloadPhase.Failed,
  [EPodGroupStatus.DeadlineExceeded]: EWorkloadPhase.Failed,
  [EPodGroupStatus.UnexpectedAdmissionError]: EWorkloadPhase.Failed,
  [EPodGroupStatus.Preempted]: EWorkloadPhase.Completed,
};

export const statusToWLStatusMapping: Record<EPodGroupStatus, EWorkloadStatus> = {
  [EPodGroupStatus.Pending]: EWorkloadStatus.Pending,
  [EPodGroupStatus.Running]: EWorkloadStatus.Active,
  [EPodGroupStatus.Succeeded]: EWorkloadStatus.Completed,
  [EPodGroupStatus.Failed]: EWorkloadStatus.Failed,
  [EPodGroupStatus.ContainerCreating]: EWorkloadStatus.Initializing,
  [EPodGroupStatus.Submitted]: EWorkloadStatus.Submitted,
  [EPodGroupStatus.CrashLoopBackOff]: EWorkloadStatus.Failed,
  [EPodGroupStatus.Completed]: EWorkloadStatus.Completed,
  [EPodGroupStatus.Init]: EWorkloadStatus.Initializing,
  [EPodGroupStatus.PodInitializing]: EWorkloadStatus.Initializing,
  [EPodGroupStatus.ImagePullBackOff]: EWorkloadStatus.Failed,
  [EPodGroupStatus.ErrImagePull]: EWorkloadStatus.Failed,
  [EPodGroupStatus.Suspended]: EWorkloadStatus.Stopped,
  [EPodGroupStatus.Error]: EWorkloadStatus.Failed,
  [EPodGroupStatus.Timeout]: EWorkloadStatus.Failed,
  [EPodGroupStatus.StartError]: EWorkloadStatus.Failed,
  [EPodGroupStatus.DeadlineExceeded]: EWorkloadStatus.Failed,
  [EPodGroupStatus.UnexpectedAdmissionError]: EWorkloadStatus.Failed,
  [EPodGroupStatus.Preempted]: EWorkloadStatus.Completed,
};

//pods modal

export const allWorkloadPodsModalColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Name",
    field: (row: Pod) => row.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: Pod) => row,
    sortable: true,
    align: "left",
    format: (pod: Pod): IStatusColOptions | string => {
      return oldWorkloadUtil.getPodStatusColOptions(pod);
    },
    customCell: ECustomCell.STATUS_COL,
    sort: (podA: Pod, podB: Pod): number => {
      return podA.status.localeCompare(podB.status);
    },
  },
  podId: {
    name: "podId",
    label: "Pod ID",
    field: (row: Pod) => row.podId,
    sortable: true,
    align: "left",
  },
  nodeId: {
    name: "nodeId",
    label: "Node",
    field: (row: Pod) => row.nodeId || "-",
    sortable: true,
    align: "left",
  },
  nodePool: {
    name: "nodePool",
    label: "Node pool",
    field: (row: Pod) => row.nodePool || "-",
    sortable: true,
    align: "left",
  },
  created: {
    name: "created",
    label: "Creation time",
    field: (row: Pod) => row.created,
    format: (created: string): string => (!created ? "-" : dateUtil.dateAndTimeFormat(new Date(parseInt(created)))),
    sortable: true,
    align: "left",
  },
};

export const workloadPodsModalTableColumns: Array<ITableColumn> = [
  { ...allWorkloadPodsModalColumnsMap.name, display: true },
  { ...allWorkloadPodsModalColumnsMap.status, display: true },
  { ...allWorkloadPodsModalColumnsMap.podId, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodeId, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodePool, display: true },
  { ...allWorkloadPodsModalColumnsMap.created, display: true },
];

export const workloadPodsModalDependentColumns = {
  nodePool: [allWorkloadPodsModalColumnsMap.nodePool.name],
};

export interface IWorkloadPodsModalOptions {
  pods: Pod[];
  loading: boolean;
}

export enum EPodStatus {
  Running = "Running",
  Pending = "Pending",
  Unknown = "Unknown",
  Failed = "Failed",
  Succeeded = "Succeeded",
  Terminating = "Terminating",
  UnexpectedAdmissionError = "UnexpectedAdmissionError",
  ContainerCreating = "ContainerCreating",
  Deleted = "Deleted",
}

export const PodStatusPhaseMap: Record<EPodStatus, IStatusColOptions> = {
  [EPodStatus.Running]: {
    status: "Active",
    displayAnimation: false,
    filterKey: "status",
    color: EWorkloadStatusColor.active,
  },
  [EPodStatus.Pending]: {
    status: "Pending",
    displayAnimation: false,
    filterKey: "status",
    color: EWorkloadStatusColor.pending,
  },
  [EPodStatus.ContainerCreating]: {
    status: "Container Creating",
    displayAnimation: false,
    filterKey: "status",
    color: EWorkloadStatusColor.pending,
  },
  [EPodStatus.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
    filterKey: "status",
  },
  [EPodStatus.Failed]: {
    status: "Failed",
    displayAnimation: false,
    filterKey: "status",
    color: EWorkloadStatusColor.failed,
  },
  [EPodStatus.Succeeded]: {
    status: "Succeeded",
    displayAnimation: false,
    filterKey: "status",
  },
  [EPodStatus.Terminating]: {
    status: "Terminating",
    displayAnimation: false,
    filterKey: "status",
  },
  [EPodStatus.UnexpectedAdmissionError]: {
    status: "Unexpected Admission Error",
    displayAnimation: false,
    filterKey: "status",
    color: EWorkloadStatusColor.failed,
  },
  [EPodStatus.Deleted]: {
    status: "Deleted",
    displayAnimation: false,
    filterKey: "status",
  },
};
