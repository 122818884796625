import type { ITableColumn } from "./table.model";
import {
  Scope,
  AssetKind,
  type AssetRef,
  type WorkloadRefAndStatus,
  type DatasourceListResponseEntry,
  type AssetUsageInfo,
  type HostPathSpec,
  type NFSAssetSpec,
  type ClaimInfo,
  type GitAssetSpec,
  type PVCAssetSpec,
  type S3AssetSpec,
} from "@/swagger-models/assets-service-client";

import { dateUtil } from "@/utils/date.util";
import { ECustomCell } from "./table.model";
import type { IUIAssetCreationMeta } from "./global.model";

export const NONE_STORAGE_CLASS = "runai-none";
export const NONE_STORAGE_CLASS_LABEL = "None";

export const DATA_SOURCE_TYPE: Record<string, string> = {
  [AssetKind.HostPath]: "Host path",
  [AssetKind.Nfs]: "NFS",
  [AssetKind.Git]: "Git",
  [AssetKind.S3]: "S3",
  [AssetKind.Pvc]: "PVC",
};

export type TDataSourceKinds =
  | typeof AssetKind.HostPath
  | typeof AssetKind.Nfs
  | typeof AssetKind.Git
  | typeof AssetKind.S3
  | typeof AssetKind.Pvc;

export const DATA_SOURCES_TYPES_TO_NAMES = {
  NFS: "NFS",
  PVC: "PVC",
  S3: "S3 Bucket",
  GIT: "Git",
  HOST_PATH: "Host path",
};

export interface IUIVolume {
  claimName: string;
  path: string;
  claimInfo: ClaimInfo;
  ephemeral: boolean;
}

export interface IUIHostPathCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: HostPathSpec;
}

export interface IUINFSCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: NFSAssetSpec;
}
export interface IUIS3CreationRequest {
  meta: IUIAssetCreationMeta;
  spec: S3AssetSpec;
}
export interface IUIGitCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: GitAssetSpec;
}

export interface IUIPVCCreationRequest {
  meta: IUIAssetCreationMeta;
  spec: PVCAssetSpec;
}

export const allDataSourceColumnsMap: Record<string, ITableColumn> = {
  dataSource: {
    name: "data-source",
    label: "Data source",
    field: (row) => row.meta.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row) => row.meta.kind,
    format: (kind: AssetKind) => DATA_SOURCE_TYPE[kind] || kind,
    sortable: true,
    align: "left",
  },
  scope: {
    name: "scope",
    label: "Scope",
    field: (dataSource: DatasourceListResponseEntry): Scope => dataSource.meta.scope,
    sortable: true,
    align: "left",
    format: (scope: Scope, dataSource: DatasourceListResponseEntry): string | number => {
      switch (scope) {
        case Scope.Project:
          return Number(dataSource.meta.projectId);
        case Scope.Department:
          return String(dataSource.meta.departmentId);
        default:
          return String(dataSource.meta.tenantId);
      }
    },
    exportFormat: (scope: Scope): Scope => scope,
    customCell: ECustomCell.SCOPE_COL,
    hideFilter: true,
  },
  workloads: {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.usedBy,
    sortable: true,
    align: "left",
    format: (usageInfo: AssetUsageInfo | undefined): Array<string> => {
      if (!usageInfo) return [];
      const workspaces: Array<string> = usageInfo.workspaces?.map((ws: WorkloadRefAndStatus) => ws.name) || [];
      const trainings: Array<string> = usageInfo.trainings?.map((training: WorkloadRefAndStatus) => training.name) || [];
      return [...workspaces, ...trainings];
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  templates: {
    name: "templates",
    label: "Template(s)",
    field: (row) => row.usedBy?.templates,
    sortable: true,
    align: "left",
    format: (templates: Array<AssetRef>): Array<string> => {
      if (!templates?.length) return [];
      return templates.map((template: AssetRef) => template.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-template-clicked" },
  },
  createdBy: {
    name: "created-by",
    label: "Created by",
    field: (row) => row.meta.createdBy,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row) => row.meta.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
};

export const allDataSourceColumns: Array<ITableColumn> = [
  allDataSourceColumnsMap.dataSource,
  allDataSourceColumnsMap.type,
  allDataSourceColumnsMap.scope,
  allDataSourceColumnsMap.workloads,
  allDataSourceColumnsMap.templates,
  allDataSourceColumnsMap.createdBy,
  allDataSourceColumnsMap.createdAt,
];

export const dataSourceIndexColumns: Array<ITableColumn> = [
  { ...allDataSourceColumnsMap.dataSource, display: true, mandatory: true },
  { ...allDataSourceColumnsMap.type, display: true },
  { ...allDataSourceColumnsMap.scope, display: true },
  { ...allDataSourceColumnsMap.workloads, display: true },
  { ...allDataSourceColumnsMap.templates, display: false },
  { ...allDataSourceColumnsMap.createdBy, display: false },
  { ...allDataSourceColumnsMap.createdAt, display: true },
];

export const dataSourceMiniTableColumns: Array<ITableColumn> = [
  { ...allDataSourceColumnsMap.dataSource, display: true },
  { ...allDataSourceColumnsMap.type, display: true },
];

export interface IDataSourceModalOptions {
  dataSourceIds: Array<string>;
  header: string;
}
