import type { Chart, Options, AxisSetExtremesEventObject, XrangePointOptionsObject } from "highcharts";
import Highcharts from "highcharts";
import type { PrometheusMetricResponse } from "@/models/prometheus.model";
import { chartSettings } from "@/models/node-pool.model";
import type { INodePoolChartSettings } from "@/models/node-pool.model";
import type { WorkloadHistoryResponseEntriesInner } from "@/swagger-models/backend-client";
import { memoryFormat } from "@/utils/format.util";

const CHART_EVENTS = ["mousemove", "touchmove", "touchstart"];
const MOUSE_OUT_EVENT = "mouseout";
interface SeriesWithColor extends Highcharts.Series {
  color: string;
}

export const chartUtil = {
  getResourcesOptions,
  getMultiLineChartOptions,
  getSingleLineChartOptions,
  getAreaChartOptions,
  addMultiChartsCrosshairEvents,
  removeMultiChartsCrosshairEvent,
  getNodePoolChartSettings,
  getXRangeOptions,
  getQuotaChartOptions,
  getQuotaChartSeries,
};

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

function getResourcesOptions(overQuotaValue: number, quotaValue: number, allocatedValue: number): Options {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: "column",
      height: 105,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        visible: false,
      },
    ],
    yAxis: [
      {
        visible: false,
      },
    ],
    legend: {
      shadow: false,
      itemStyle: {
        fontWeight: "regular",
        fontSize: "10px",
      },
      width: "196px",
      itemDistance: 7,
      symbolWidth: 8,
      symbolHeight: 8,
      symbolPadding: 3,
    },
    tooltip: {
      shared: true,
      outside: true,
      hideDelay: 50,
      positioner: function (labelWidth) {
        const boundingClientRect = this.chart.container.getBoundingClientRect();
        return {
          x: boundingClientRect.x + labelWidth,
          y: boundingClientRect.y,
        };
      },
    },
    plotOptions: {
      column: {
        grouping: false,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        name: "Over quota",
        color: "#DF1995",
        data: [quotaValue + overQuotaValue],
        tooltip: {
          headerFormat: "",
          pointFormat: `<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">${overQuotaValue} GPU</tspan><br />`,
        },
        pointWidth: 70,
      },
      {
        type: "column",
        name: "Quota",
        color: "#BED3FF",
        data: [quotaValue],
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">{point.y} GPU</tspan><br />',
        },
        pointWidth: 70,
      },
      {
        type: "column",
        name: "Allocated",
        color: "#FC774A",
        data: [allocatedValue],
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<tspan style="color: {point.color}; fill: {point.color};">●</tspan> {series.name}: <tspan style="font-weight: bold;">{point.y} GPU</tspan><br />',
        },
        pointWidth: 50,
      },
    ],
  };
}

const AVERAGE_INDEX = 0;
function getMultiLineChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  colors: string[],
  title: string,
  hasTheLegend: boolean,
  yAxisPostfix: string,
  showHours: boolean,
  isMemoryUnit = false,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginRight: 120,
      height: 280,
      type: "line",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "bold",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          const label: string = this.axis.defaultLabelFormatter.call(this);
          if (isMemoryUnit) {
            return memoryFormat(this.value) + yAxisPostfix;
          }
          // Use thousands separator for four-digit numbers too
          if (/^\d{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value as number, 0, ".", ",");
          }
          return `${label}${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        },
      },
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      events: {
        afterSetExtremes: function (event: AxisSetExtremesEventObject) {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
      crosshair: true,
    },
    legend: {
      floating: true,
      verticalAlign: "top",
      align: "right",
      enabled: hasTheLegend,
      layout: "vertical",
      itemMarginTop: 1,
      itemMarginBottom: 7,
      symbolHeight: 20,
      itemStyle: {
        color: "#000000b3",
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
        events: {
          legendItemClick: hasTheLegend
            ? function (): boolean {
                const isVisible = this.visible;
                charts.forEach((chart: Highcharts.Chart) => {
                  if (isVisible) {
                    chart.series[this.index].hide();
                  } else {
                    chart.series[this.index].show();
                  }
                });
                return false;
              }
            : undefined,
        },
        marker: {
          enabled: false,
        },
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },
    tooltip: {
      formatter: function (): string {
        const chartValuePoint: Highcharts.TooltipFormatterContextObject[] | undefined = this.points;
        if (!chartValuePoint) return "";
        const pointsLength = chartValuePoint?.length;
        const timestamp: number = chartValuePoint[0]?.key ? +chartValuePoint[0].key : 0;
        let tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timestamp) + "</span><br/>"
          : "";
        chartValuePoint?.forEach((point: Highcharts.TooltipFormatterContextObject) => {
          const yValue = (point?.y as number).toFixed(2);

          // Check if it is more than 1000 to replace with K
          const thousandsFormat = Highcharts.numberFormat(+yValue / 1000, 0, ".", ",") + "K";
          const value = isMemoryUnit
            ? memoryFormat(point?.y as number)
            : `${yAxisPostfix === "%" ? yValue : thousandsFormat + " "}${yAxisPostfix}`;

          tooltipMarkup +=
            '<span style="color:' +
            (point.series as SeriesWithColor).color +
            '">\u25CF</span> ' +
            point.series.name +
            ": <b>" +
            value +
            " </b><br/>";
        });

        return tooltipMarkup;
      },
      valueDecimals: 2,
      shared: true,
      xDateFormat: "%m/%d/%y %H:%M:%S",
      backgroundColor: "#46465C",
      borderColor: "#46465C",
      shape: "square",
      hideDelay: 50,
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: dataSeries.map((item, index) => {
      return {
        name: `${index === AVERAGE_INDEX ? "Average" : index}`,
        data: item,
        color: colors[index],
        dashStyle: `${index === AVERAGE_INDEX ? "ShortDot" : "Solid"}`,
        marker: {
          symbol: "circle",
        },
      };
    }) as [],
  };
}

function getSingleLineChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  color: string,
  title: string,
  yAxisPostfix: string,
  showHours: boolean,
  isMemoryUnit = false,
  decimalDigitsAmount = 2,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      height: 210,
      type: "line",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "500",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          let label: string = this.axis.defaultLabelFormatter.call(this);
          // Use thousands separator for four-digit numbers too
          if (/^\d{4}$/.test(label)) {
            return Highcharts.numberFormat(this.value as number, decimalDigitsAmount, ".", ",");
          }
          if (isMemoryUnit) {
            label = memoryFormat(this.value);
            return label;
          }
          label = Highcharts.numberFormat(this.value as number, decimalDigitsAmount, ".", ",");
          return `${label}${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        },
      },
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      crosshair: true,
      events: {
        afterSetExtremes: function (event): void {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },
    tooltip: {
      formatter: function (): string {
        const timeStamp: Date = new Date(this?.x ? +this?.x : 0);
        const timeStampWithFormat: string = Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timeStamp.getTime());
        const label = isMemoryUnit
          ? memoryFormat(this?.y as number)
          : Highcharts.numberFormat(this?.y as number, decimalDigitsAmount, ".", ",") +
            `${yAxisPostfix === "%" ? "" : " "}${yAxisPostfix}`;
        return `${timeStampWithFormat}<br><b>${label}</b>`;
      },
      valueDecimals: decimalDigitsAmount,
      hideDelay: 50,
      shared: false,
      xDateFormat: "",
      backgroundColor: "#46465C",
      borderColor: "#46465C",
      shape: "square",
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: dataSeries.map((item) => {
      return {
        data: item,
        color: color,
      };
    }) as [],
  };
}

function getAreaChartOptions(
  charts: Chart[],
  dataSeries: PrometheusMetricResponse,
  colors: string[],
  names: string[],
  title: string,
  showHours: boolean,
  totalValue: number,
  totalColor: string,
  legendPadding = 0,
): Options {
  Highcharts.setOptions({
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginRight: 200,
      height: 280,
      type: "area",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
      },
      zoomType: "x",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#000000b3",
        fontWeight: "500",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (): string {
          const label: string = this.axis.defaultLabelFormatter.call(this);
          return `${label}`;
        },
      },
      plotLines: [
        {
          color: totalColor,
          dashStyle: "ShortDot",
          value: totalValue + 0.2,
          width: 2,
        },
      ],
    },
    xAxis: {
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        formatter: function (): string {
          return _dateFormatter(+this.value, showHours);
        },
      },
      events: {
        afterSetExtremes: function (event: AxisSetExtremesEventObject) {
          _handleAxisSetExtremesEventObject(event, charts, this.chart);
        },
      },
      crosshair: true,
    },
    legend: {
      floating: true,
      verticalAlign: "top",
      align: "right",
      enabled: true,
      layout: "vertical",
      itemMarginTop: 1,
      itemMarginBottom: 10,
      padding: legendPadding,
      symbolHeight: 6,
      symbolRadius: 0,
      squareSymbol: false,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      area: {
        stacking: "normal",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
        },
      },
    },
    tooltip: {
      formatter: function (): string {
        const chartValuePoint: Highcharts.TooltipFormatterContextObject[] | undefined = this.points;
        if (!chartValuePoint) return "";
        const pointsLength = chartValuePoint?.length;
        const timestamp: number = chartValuePoint[0]?.key ? +chartValuePoint[0].key : 0;
        let tooltipMarkup = pointsLength
          ? '<span style="font-size: 10px">' + Highcharts.dateFormat("%m/%d/%y %H:%M:%S", timestamp) + "</span><br/>"
          : "";
        tooltipMarkup += totalColor
          ? '<span style="color:' + totalColor + '">\u25CF</span> ' + "Total" + ": <b>" + totalValue + " </b><br/>"
          : "";
        chartValuePoint?.forEach((point: Highcharts.TooltipFormatterContextObject) => {
          tooltipMarkup +=
            '<span style="color:' +
            (point.series as SeriesWithColor).color +
            '">\u25CF</span> ' +
            point.series.name +
            ": <b>" +
            point?.y +
            " </b><br/>";
        });
        return tooltipMarkup;
      },
      valueDecimals: 0,
      shared: true,
      xDateFormat: "%m/%d/%y %H:%M:%S",
      backgroundColor: "#46465C",
      borderColor: "#46465C",
      shape: "square",
      hideDelay: 50,
      style: {
        color: "#F0F0F0",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Total",
        data: [],
        color: totalColor,
        dashStyle: "ShortDot",
        marker: {
          symbol: "circle",
        },
      },
    ].concat(
      dataSeries.map((item, index) => {
        return {
          fillOpacity: 0.2,
          lineWidth: 1,
          name: `${names[index]}`, //`${index === TOTAL_INDEX ? "Total" : index}`,
          data: item,
          color: colors[index],
          dashStyle: "Solid", //`${index === TOTAL_INDEX ? "ShortDot" : "Solid"}`,
          marker: {
            symbol: "circle",
          },
        };
      }) as [],
    ) as [],
  };
}

function _dateFormatter(timestamp: number, showHours: boolean): string {
  return Highcharts.dateFormat(showHours ? "%m/%d/%y %H:%M" : "%m/%d/%y", timestamp);
}
function _handleAxisSetExtremesEventObject(
  event: AxisSetExtremesEventObject,
  charts: Highcharts.Chart[],
  currentChart: Highcharts.Chart,
): void {
  const xMin: number = event.min;
  const xMax: number = event.max;
  charts.forEach((chart: Highcharts.Chart) => {
    if (chart != currentChart) {
      const ex: Highcharts.ExtremesObject = chart.xAxis[0].getExtremes();

      if (ex.min != xMin || ex.max != xMax) chart.xAxis[0].setExtremes(xMin, xMax, true, false);
    }
  });
}
function removeMultiChartsCrosshairEvent(charts: Highcharts.Chart[], containers: HTMLElement[]): void {
  CHART_EVENTS.forEach(function (eventType: string) {
    containers.forEach((container: HTMLElement) => {
      container.removeEventListener(eventType, _handChartsEvents);
    });
  });
  containers.forEach((container: HTMLElement) => {
    container.removeEventListener(MOUSE_OUT_EVENT, _handleChartMouseOut);
  });
}

function _handChartsEvents(event: MouseEvent | unknown, charts: Highcharts.Chart[] = []): void {
  charts.forEach((chart: Highcharts.Chart) => {
    const e: Highcharts.PointerEventObject = chart.pointer.normalize(event as MouseEvent);
    const chartValuePoint: Highcharts.Point | undefined =
      chart.series[0]?.searchPoint(e, true) || chart.series[1]?.searchPoint(e, true);

    if (chartValuePoint) {
      const pointEvent: Highcharts.PointerEventObject = chartValuePoint.series.chart.pointer.normalize(
        event as MouseEvent,
      );
      chartValuePoint.onMouseOver();
      chartValuePoint.series.chart.xAxis[0].drawCrosshair(pointEvent, chartValuePoint);
    }
  });
}

function _handleChartMouseOut(event: MouseEvent | undefined, charts: Highcharts.Chart[] = []): void {
  charts.forEach((chart: Highcharts.Chart) => {
    chart.tooltip.hide();
    chart.xAxis[0].hideCrosshair();
    const e: Highcharts.PointerEventObject = chart.pointer.normalize(event as MouseEvent);
    const chartValuePoint: Highcharts.Point | undefined =
      chart.series[0]?.searchPoint(e, true) || chart.series[1]?.searchPoint(e, true);

    if (chartValuePoint) {
      chartValuePoint.onMouseOut();
    }
  });
}
/**
 * In order to synchronize tooltips and crosshairs, override the
 * built-in events with handlers defined on the parent element.
 */
function addMultiChartsCrosshairEvents(charts: Highcharts.Chart[], containers: HTMLElement[]): void {
  /**
   * Override the reset function, we don't need to hide the tooltips and crosshair.
   */
  Highcharts.Pointer.prototype.reset = function (): undefined {
    return undefined;
  };

  containers.forEach((container: HTMLElement) => {
    container.addEventListener(MOUSE_OUT_EVENT, function (e: MouseEvent) {
      _handleChartMouseOut(e, charts);
    });
  });
  /**
   * In order to synchronize tooltips and crosshair, override the
   * built-in events with handlers defined on the parent element.
   */
  CHART_EVENTS.forEach(function (eventType: string) {
    containers.forEach((container: HTMLElement) => {
      container.addEventListener(eventType, function (event: Event) {
        _handChartsEvents(event, charts);
      });
    });
  });
}

function getNodePoolChartSettings(hasGpus: boolean): INodePoolChartSettings[] {
  return chartSettings.filter((setting) => (setting.isGpuChart && hasGpus) || !setting.isGpuChart);
}

function getQuotaChartOptions(): Highcharts.Options {
  return {
    chart: {
      type: "xrange",
      height: 72,
      width: 220,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    series: [],
  };
}

function getQuotaChartSeries(
  isOverQuotaExceeds: boolean,
  projectsDepartmentsMemoryRatio: number,
  data?: Array<XrangePointOptionsObject>,
): Highcharts.SeriesXrangeOptions[] {
  const seriesConfiguration: Highcharts.SeriesXrangeOptions[] = [
    {
      type: "xrange",
      name: "cpu-memory-quota",
      pointPadding: 0,
      groupPadding: 0,
      borderRadius: 1,
      borderWidth: 1,
      pointWidth: 25,
      borderColor: "#003F5C",
      enableMouseTracking: false,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return null;
        },
      },
      data: [
        {
          x: 0,
          x2: 1,
          y: 0,
          color: "#E0E0E0",
          partialFill: {
            fill: "#0BB4FF",
            amount: projectsDepartmentsMemoryRatio,
          },
        },
      ],
    },
  ];

  const overQuotaSeriesData = [
    {
      x: 1,
      x2: 1.009,
      y: 0,
      color: "#003F5C",
    },
    {
      x: 1.01,
      x2: 1.2,
      y: 0,
      color: "#FFAB00",
    },
  ];

  if (isOverQuotaExceeds) {
    seriesConfiguration[0].data = seriesConfiguration[0].data?.concat(overQuotaSeriesData);
  }

  if (data) {
    seriesConfiguration[0].data = data;
  }
  return seriesConfiguration;
}

function getXRangeOptions(events: WorkloadHistoryResponseEntriesInner[]): Highcharts.Options {
  return {
    time: {
      useUTC: false,
    },
    chart: {
      height: "300",
      type: "xrange",
      spacingRight: 50,
      spacingLeft: 35,
      style: {
        fontFamily: "Roboto",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        if (this.point.y !== undefined) {
          const category = this.series.yAxis.categories[this.point.y] as TStatusTypes;
          const color = category === "Stopped" ? "#808080" : EStatusColors[category];
          return `<span style="text-align: start;">Status: <b style="color:${color};">${category}</b></span><br/><span style="text-align: start;">${Highcharts.dateFormat(
            "%m/%d/%y, %I:%M %p",
            this.point.x,
          )} - ${Highcharts.dateFormat("%m/%d/%y, %I:%M %p", this.point.x2 as number)}</span>`;
        }
        return "";
      },
      valueDecimals: 2,
      hideDelay: 50,
      shared: false,
      xDateFormat: "",
      backgroundColor: "#46465C",
      borderColor: "#46465C",
      shape: "square",
      style: {
        color: "#F0F0F0",
        fontSize: "10px",
      },
    },
    title: {
      text: "Status over time",
      style: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#000000B2",
        padding: "",
      },
      align: "left",
      x: -18,
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      gridLineWidth: 0,
      type: "category",
      min: 0,
      max: 5,
      title: {
        text: "",
      },
      offset: 70,
      categories: ["Active", "Initializing", "Pending", "Stopped", "Completed", "Failed"],
      labels: {
        formatter: function (category) {
          const categoryName = category.value as TStatusTypes;
          return `<span style="color: ${EStatusColors[categoryName]}">${categoryName}</span>`;
        },
        align: "left",
      },
      reversed: true,
    },
    series: [
      {
        type: "xrange",
        showInLegend: false,
        borderColor: "none",
        pointWidth: 20,
        data: _xRangeChartStatusData(events) as XrangePointOptionsObject[],
      },
    ],
  } as Options;
}

function _xRangeChartStatusData(events: WorkloadHistoryResponseEntriesInner[]): XrangePointOptionsObject[] {
  return events.map((e: WorkloadHistoryResponseEntriesInner, index: number) => {
    const nextEvent: WorkloadHistoryResponseEntriesInner | undefined = events[index + 1];
    const finished: number = nextEvent?.meta.creationTimestamp ? nextEvent?.meta.creationTimestamp : Date.now();

    const statusType = e.spec.statusChange?.status as TStatusTypes;
    const UNKNOWN_STATUS = "Unknown";
    return {
      x: e.meta.creationTimestamp,
      x2: finished,
      y: _getYAxisValue(statusType),
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, EStatusColorsSecondary[statusType] || EStatusColorsSecondary[UNKNOWN_STATUS]],
          [0.4, EStatusColorsSecondary[statusType] || EStatusColorsSecondary[UNKNOWN_STATUS]],
          [0.4, EStatusColors[statusType] || EStatusColors[UNKNOWN_STATUS]],
          [0.6, EStatusColors[statusType] || EStatusColors[UNKNOWN_STATUS]],
          [0.6, EStatusColorsSecondary[statusType] || EStatusColorsSecondary[UNKNOWN_STATUS]],
        ],
      },
    } as XrangePointOptionsObject;
  });
}

type TStatusTypes =
  | "Active"
  | "Initializing"
  | "Pending"
  | "Stopped"
  | "Completed"
  | "Failed"
  | "Terminating"
  | "Unknown";
enum EStatusColors {
  "Active" = "#4CAF50",
  "Initializing" = "#FFAB00",
  "Pending" = "#FFAB00",
  "Stopped" = "#0000008A",
  "Completed" = "#0000008A",
  "Failed" = "#EF5350",
  "Terminating" = "#0000008A",
  "Unknown" = "#0000008A",
}

enum EStatusColorsSecondary {
  "Active" = "#4CAF501A",
  "Initializing" = "#FFAB001A",
  "Pending" = "#FFAB001A",
  "Stopped" = "#0000001A",
  "Completed" = "#0000001A",
  "Failed" = "#fbd1d0",
  "Terminating" = "#0000001A",
  "Unknown" = "#0000008A",
}

function _getYAxisValue(status: TStatusTypes): number {
  const statusTypes = ["Active", "Initializing", "Pending", "Stopped", "Completed", "Failed"];
  return statusTypes.indexOf(status);
}
