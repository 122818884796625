import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";

import { API, K8S_API } from "@/common/api.constant";
import type { IDepartment } from "@/models/department.model";
import { allDepartmentColumns } from "@/models/department.model";
import type { IFilterBy } from "@/models/filter.model";
import type { IAssignedResources, IResource } from "@/models/resource.model";
import { PolicyType, Scope, type WorkspacePolicy } from "@/swagger-models/policy-service-client";
export const departmentService = {
  list,
  remove,
  save,
  getEmptyDepartmentModel,
  getNodeResourcesModel,
  getById,
  isNameUnique,
  enrichDepartmentsWithResourcesMetrics,
  loadPolicy,
};

const getEndpoint = (clusterUuid: string): string => `${K8S_API.v1}/clusters/${clusterUuid}/departments`;

let departments: Array<IDepartment>;

// api calls
async function list(clusterUuid: string, filterBy?: IFilterBy, forceLoading = false): Promise<Array<IDepartment>> {
  if (forceLoading || !departments) {
    departments = await controlPlaneService.get(`${getEndpoint(clusterUuid)}?excludePermissions=true`);
  }

  if (!filterBy || !filterBy.displayedColumns) return departments;

  if (filterBy.searchTerm) {
    departments = filterService.filterBySearchTerm<IDepartment>(
      departments,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allDepartmentColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    departments = filterService.filterByColumns(departments, filterBy.columnFilters, allDepartmentColumns);
  }
  return departments;
}

async function remove(departmentId: number, clusterUuid: string) {
  const endpoint = `${getEndpoint(clusterUuid)}/${departmentId}?excludePermissions=true`;
  await controlPlaneService.delete(endpoint);
}

async function save(department: IDepartment, clusterUuid: string): Promise<IDepartment> {
  const endpoint = `${getEndpoint(clusterUuid)}${department.id ? `/${department.id}` : ""}?excludePermissions=true`;

  if (department.id) {
    return await controlPlaneService.put(endpoint, department);
  } else {
    return await controlPlaneService.post(endpoint, department);
  }
}

async function getById(departmentId: number, clusterUuid: string) {
  const endpoint = `${getEndpoint(clusterUuid)}/${departmentId}?excludePermissions=true`;
  return await controlPlaneService.get(endpoint);
}

async function loadPolicy(workloadType: PolicyType, departmentId: string): Promise<WorkspacePolicy> {
  return controlPlaneService.get(
    `${API.v1}/policy/${workloadType}?scope=${Scope.Department}&departmentId=${departmentId}`,
  );
}

async function isNameUnique(clusterUuid: string, name: string, departmentId: number | null): Promise<boolean> {
  try {
    let departments: IDepartment[] = await controlPlaneService.get(
      `${getEndpoint(clusterUuid)}?excludePermissions=true`,
    );

    if (departmentId) {
      departments = departments.filter((dep) => dep.id !== departmentId);
    }

    const existingNames: string[] = departments.map((dep) => dep.name);
    return !existingNames.includes(name);
  } catch (error) {
    console.error(error);
    return false;
  }
}
function getEmptyDepartmentModel(enableCpuResourcesQuota: boolean): IDepartment {
  return {
    deservedGpus: 0,
    maxAllowedGpus: -1,
    name: "",
    resources: getNodeResourcesModel(enableCpuResourcesQuota),
  } as IDepartment;
}

function getNodeResourcesModel(enableCpuResourcesQuota: boolean): IAssignedResources {
  return {
    gpu: {
      deserved: 0,
      maxAllowed: -1,
      overQuotaWeight: 2,
    } as IResource,
    cpu: {
      deserved: null,
      maxAllowed: enableCpuResourcesQuota ? -1 : null,
      overQuotaWeight: enableCpuResourcesQuota ? 2 : null,
    } as IResource,
    memory: {
      deserved: null,
      maxAllowed: enableCpuResourcesQuota ? -1 : null,
      overQuotaWeight: enableCpuResourcesQuota ? 2 : null,
    } as IResource,
  };
}

async function enrichDepartmentsWithResourcesMetrics(
  departments: IDepartment[],
  clusterUuid: string,
): Promise<IDepartment[]> {
  let metric;
  try {
    metric = await controlPlaneService.get(`v1/k8s/clusters/${clusterUuid}/departments/metrics`);
  } catch (e) {
    console.error(e);
    return departments;
  }
  interface IResourceMap {
    [key: number]: {
      cpu: number;
      memory: number;
      gpu: number;
    };
  }

  const resourceMap: IResourceMap = {};

  for (const item of metric.data) {
    for (const resource of item.current.resources) {
      const departmentId = item.metadata?.departmentId;
      const allocatedResources = {
        cpu: resource.cpu.allocated,
        memory: resource.memory.allocated,
        gpu: resource.gpu.allocated,
      };

      if (departmentId) {
        if (!resourceMap[departmentId]) {
          resourceMap[departmentId] = allocatedResources;
        } else {
          resourceMap[departmentId].cpu += allocatedResources.cpu;
          resourceMap[departmentId].memory += allocatedResources.memory;
          resourceMap[departmentId].gpu += allocatedResources.gpu;
        }
      }
    }
  }

  for (const department of departments) {
    if (department.id) {
      department.allocatedCpu = resourceMap[department.id]?.cpu || 0;
      department.allocatedMemory = resourceMap[department.id]?.memory || 0;
      department.allocatedGpus = resourceMap[department.id]?.gpu || 0;
    }
  }

  return departments;
}
