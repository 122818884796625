import { defineStore } from "pinia";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";

import { TRAINING_DISTRIBUTED_MIN_VERSION, type ICluster } from "@/models/cluster.model";
import { USE_EXTERNAL_TOKEN } from "@/common/storage.constant";
import type { IFilterBy } from "@/models/filter.model";
import { useAuthStore } from "@/stores/auth.store";
import { useGrafanaStore } from "./grafana.store";
import { isNewerVersion } from "@/utils/version.util";
import { storageUtil } from "@/utils/storage.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { ClusterDisplayedStatusStateEnum } from "@/swagger-models/cluster-service-client";

import type { IReadinessStatus } from "@/models/researcher.model";

import {
  FEATURE_BRANCH_VERSION,
  MIN_CLI_VERSION,
  MIN_CLUSTER_SERVICE_NODES_VERSION,
  MIN_DEPARTMENT_SCOPE_CREDENTIALS,
  MIN_TENANT_SCOPE_CREDENTIALS,
  MIN_WORKLOADS_SERVICE_CLUSTER_VERSION,
  TEST_ENV_VERSION,
} from "@/common/version.constant";
import { clusterUtil } from "@/utils/cluster.util";
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";
import type { DisplayedCluster } from "@/swagger-models/cluster-service-client";

export const useClusterStore = defineStore("Cluster", {
  state: () => ({
    clusters: [] as Array<ICluster>,
    currentCluster: {} as DisplayedCluster,
    lastCreatedClusterUuid: null as null | string,
  }),
  getters: {
    isClusterDisconnected(): boolean {
      return this.currentCluster?.status === ClusterDisplayedStatusStateEnum.Disconnected;
    },
    clusterList(): Array<ICluster> {
      return this.clusters;
    },
    clusterListLastCreatedExcluded(): Array<ICluster> {
      if (!this.lastCreatedClusterUuid) return this.clusters;
      return this.clusters.filter((cluster: ICluster) => cluster.uuid !== this.lastCreatedClusterUuid);
    },
    lastCreatedCluster(): ICluster | null {
      if (!this.lastCreatedClusterUuid) return null;
      return this.clusters.find((cluster: ICluster) => cluster.uuid === this.lastCreatedClusterUuid) || null;
    },
    currentClusterId(): string {
      return this.currentCluster?.uuid || "";
    },
    currentClusterVersion(): string {
      return this.currentCluster?.version || "";
    },
    currentClusterName(): string {
      return this.currentCluster?.name || "";
    },
    currentClusterDomain(): string {
      return this.currentCluster?.domain || "";
    },
    isClusterAmdGpuType(): boolean {
      return this.currentCluster?.status?.dependencies?.required?.["gpu-stack"].components?.amd?.available || false;
    },
    availableDependencies(): Array<string> {
      const requiredDependenciesAvailable: Array<string> = Object.keys(
        this.currentCluster?.status?.dependencies?.required || {},
      ).filter((key: string) => this.currentCluster.status?.dependencies?.required[key]?.available);

      const optionalDependenciesAvailable: Array<string> = Object.keys(
        this.currentCluster?.status?.dependencies?.optional || {},
      ).filter((key: string) => this.currentCluster.status?.dependencies?.optional[key]?.available);

      return [...requiredDependenciesAvailable, ...optionalDependenciesAvailable];
    },
    isClusterSupportTenantScopeAssets(): boolean {
      // this is relevant for specific assets only (credentials and data sources that rely on them) other assets that are not directly related to the cluster are not affected
      const version = this.currentCluster?.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_TENANT_SCOPE_CREDENTIALS);
    },
    isClusterSupportDepartmentScopeAssets(): boolean {
      // this is relevant for specific assets only (credentials and data sources that rely on them) other assets that are not directly related to the cluster are not affected
      const version = this.currentCluster?.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_DEPARTMENT_SCOPE_CREDENTIALS);
    },
    isClusterSupportWorkloadService(): boolean {
      const version = this.currentClusterVersion;
      if (!version) return false;
      return isNewerVersion(version, MIN_WORKLOADS_SERVICE_CLUSTER_VERSION);
    },
    isVersionSupportDistributedTraining(): boolean {
      return isNewerVersion(this.currentClusterVersion, TRAINING_DISTRIBUTED_MIN_VERSION);
    },
    isVersionSupportWorkloadsService(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_WORKLOADS_SERVICE_CLUSTER_VERSION);
    },
    isVersionSupportClusterServiceNodes(): boolean {
      return isNewerVersion(this.currentClusterVersion, MIN_CLUSTER_SERVICE_NODES_VERSION);
    },
    isCLIAvailable(): boolean {
      const version = this.currentCluster.version;
      if (!version) return false;
      return (
        isNewerVersion(version, MIN_CLI_VERSION) ||
        version.includes(TEST_ENV_VERSION) ||
        version.toLowerCase().includes(FEATURE_BRANCH_VERSION)
      );
    },
    canCreateCluster(): boolean {
      const permissionStore = usePermissionStore();
      const authStore = useAuthStore();
      return permissionStore.hasPermission(ResourceType.Cluster, Action.Read) || authStore.isAdmin;
    },
  },
  actions: {
    async loadClusters(filterBy: IFilterBy = {}): Promise<Array<ICluster>> {
      this.clusters = await clusterService.list(filterBy);
      return this.clusters;
    },
    async setCurrentCluster(currentCluster: ICluster): Promise<DisplayedCluster> {
      this.currentCluster = currentCluster;
      clusterApiService.setBaseURL(this.currentCluster.domain || "");
      const isClusterShouldUseExternalTokenResponse: IReadinessStatus = await whoamiService.shouldUseExternalToken(
        this.currentCluster.domain as string,
      );
      storageUtil.save<boolean>(USE_EXTERNAL_TOKEN, isClusterShouldUseExternalTokenResponse.connected);
      if (usePermissionStore().hasPermission(ResourceType.DashboardsOverview, Action.Read)) {
        await useGrafanaStore().getGrafanaDashboards(this.currentCluster.uuid);
      }
      clusterUtil.saveLastSelectedClusterUuid(this.currentCluster.uuid);
      return this.currentCluster;
    },
    async create(name: string): Promise<ICluster> {
      const newCluster: ICluster = await clusterService.create(name);
      await useAuthStore().loadUserOrgUnits();
      this.lastCreatedClusterUuid = newCluster?.uuid || null;
      return newCluster;
    },
    async deleteCluster(uuid: string): Promise<void> {
      await clusterService.remove(uuid);
      this.clusters = this.clusters.filter((cluster: ICluster) => cluster.uuid !== uuid);
      await useAuthStore().loadUserOrgUnits();
    },
    async updateCluster(uuid: string, updatedCluster: ICluster): Promise<void> {
      await clusterService.update(uuid, updatedCluster);
    },
    removeLastCreatedCluster(): void {
      this.lastCreatedClusterUuid = null;
    },
    isClusterNameExists(name: string): boolean {
      return !!this.clusters.find((cluster) => cluster.name === name);
    },
    getClusterById(clusterId: string): ICluster | null {
      return this.clusters.find((cluster: ICluster) => cluster.uuid === clusterId) || null;
    },
    async setActiveClusterAfterLogin(isPostRedirectFromLogin = false): Promise<void> {
      const clusterFromLogin: ICluster | undefined = this.getSelectedClusterFromLogin();
      if (clusterFromLogin) {
        await this.setCurrentCluster(clusterFromLogin);
        return;
      }

      if (!isPostRedirectFromLogin) {
        const clusterFromStorage: ICluster | undefined = this.getActiveClusterFromStorage();
        if (clusterFromStorage) {
          await this.setCurrentCluster(clusterFromStorage);
          return;
        }
      }

      if (this.clusterList.length === 1) {
        await this.setCurrentCluster(this.clusterList[0]);
        return;
      }
      if (this.clusterList.length > 1) {
        const firstConnectedCluster: ICluster | undefined = await this.getFirstNonOcpActiveCluster();
        if (firstConnectedCluster) {
          await this.setCurrentCluster(firstConnectedCluster);
          return;
        } else {
          await this.setCurrentCluster(this.clusterList[0]);
          return;
        }
      }
    },
    async getFirstNonOcpActiveCluster() {
      return await this.getFirstActiveCluster();
    },
    getSelectedClusterFromLogin(): ICluster | undefined {
      const authStore = useAuthStore();
      return this.clusterList.find((cluster: ICluster) => cluster.name === authStore.currentUser.identityProvider);
    },
    getActiveClusterFromStorage(): ICluster | undefined {
      const selectedClusterUuid: string = clusterUtil.getLastSelectedClusterUuid();
      if (!selectedClusterUuid) return;

      return this.clusterList.find((cluster: ICluster) => cluster.uuid === selectedClusterUuid);
    },
    async getFirstActiveCluster(): Promise<ICluster | undefined> {
      const authStore = useAuthStore();
      const connectedClusters: ICluster[] = this.clusterList.filter(
        (c) => c.status?.state == ClusterDisplayedStatusStateEnum.Connected,
      );
      let connectedCluster: ICluster | undefined;
      // call /whoami only for connected clusters (cluster connected doesnt mean /whoami returns 200)
      for (const cluster of connectedClusters) {
        const responseStatus = await clusterService.checkClusterConnection(
          cluster.domain,
          authStore.accessToken,
          authStore.externalToken,
        );
        if (responseStatus === 200) {
          connectedCluster = cluster;
          break;
        }
      }
      if (connectedCluster) return connectedCluster;

      const lastSelectedCluster: ICluster | undefined = this.getActiveClusterFromStorage();
      if (lastSelectedCluster) {
        return lastSelectedCluster;
      }

      return this.clusterList[0];
    },
  },
});
