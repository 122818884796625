<template>
  <runai-expansion-item label="Project name" default-opened :section-invalid="sectionInvalid">
    <template #subheader> {{ expansionSubHeader }}</template>
    <div class="row items-center">
      <div class="col-7">
        <runai-name-validation
          aid="project-name-input"
          stack-label
          :rules="[isValidEntityName]"
          :model-value="projectName"
          @update:model-value="$emit('update:projectName', $event)"
          :disable="!isNewProject"
        />
      </div>
      <div class="col-1">
        <runai-tooltip
          tooltip-position="right"
          width="320px"
          tooltip-text="Each project is associated with a namespace. It can be determine whether it will be automatically created with the
      same name as the project name, or will use an existing one. Each namespace will be associated with only one
      project"
        />
      </div>
    </div>
    <runai-sub-expansion-item
      v-if="isNewClusterVersion"
      aid="namespace-section-toggle"
      label="Namespace"
      class="q-mt-lg"
    >
      <q-item-label class="q-my-md">Set the namespace associated to the project</q-item-label>
      <div class="row items-center">
        <q-radio
          :disable="!isNewProject"
          v-model="namespaceCreationMethod"
          aid="project-namespace-radio"
          label="Create from the project name"
          val="projectName"
        />
        <runai-tooltip
          tooltip-position="right"
          tooltip-text="When a new project is created, a new namespace is created in the Kubernetes
          cluster using the project's name with the run:ai prefix."
        />
      </div>
      <div class="row items-center">
        <q-radio
          :disable="!isNewProject"
          v-model="namespaceCreationMethod"
          aid="existing-namespace-radio"
          label="Enter existing namespace from the cluster"
          val="existingNamespace"
        />
        <runai-tooltip
          tooltip-position="right"
          tooltip-text="To use an existing Kubernetes namespace instead of the project name, enter that
        namespace here. The namespace can only be associated with one project and cannot be one of the Kubernetes global
        system default namespaces."
        />
      </div>

      <div class="row items-center q-mt-lg">
        <div class="col-7">
          <q-input
            stack-label
            no-error-icon
            aid="existing-namespace-input"
            :rules="[isValidKubernetesNamespaceName]"
            label="Namespace"
            :disable="isNamespaceEditable"
            v-model="assignedNamespace"
          />
          <q-tooltip v-if="isProjectNameBasedNamespace" anchor="center right"
            >The namespace can only be modified if it is an existing namespace from the cluster.</q-tooltip
          >
        </div>
      </div>
    </runai-sub-expansion-item>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiNameValidation } from "@/components/common/runai-name-validation";
import { RunaiSubExpansionItem } from "@/components/common/runai-sub-expansion-item";

// services
import { isValidEntityName, isValidKubernetesNamespaceName } from "@/common/form.validators";

// stores
import { useProjectStore } from "@/stores/project.store";
// models
import { errorMessages } from "@/common/error-message.constant";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_CREATE_PROJECT_WITH_NAMESPACE_VERSION, TEST_ENV_VERSION } from "@/common/version.constant";
import { useClusterStore } from "@/stores/cluster.store";
export default defineComponent({
  components: { RunaiSubExpansionItem, RunaiTooltip, RunaiNameValidation, RunaiExpansionItem },
  emits: ["update:namespace", "update:projectName", "is-section-invalid"],
  props: {
    projectName: {
      type: String as PropType<string>,
      required: true,
    },
    namespace: {
      type: [String, undefined] as PropType<string | undefined>,
      default: "",
    },
  },
  data() {
    return {
      projectStore: useProjectStore(),
      clusterStore: useClusterStore(),
      namespaceCreationMethod: "projectName" as string,
      projectNameSectionForm: {} as HTMLFormElement,
      assignedNamespace: "" as string,
    };
  },
  created() {
    this.initNamespaceSection();
  },
  computed: {
    isNamespaceEditable(): boolean {
      return this.isProjectNameBasedNamespace || !this.isNewProject;
    },
    sectionInvalid(): boolean {
      const isProjectNameInvalid = !isValidEntityName(this.projectName);
      const isNamespaceInvalid = !this.isProjectNameBasedNamespace && !this.namespace && this.isNewClusterVersion;

      return isProjectNameInvalid || isNamespaceInvalid;
    },
    isProjectNameBasedNamespace(): boolean {
      return this.namespaceCreationMethod === "projectName";
    },
    isNewClusterVersion(): boolean {
      const version = this.clusterStore.currentCluster.version;
      if (!version) return false;
      return isNewerVersion(version, MIN_CREATE_PROJECT_WITH_NAMESPACE_VERSION) || version.includes(TEST_ENV_VERSION);
    },
    expansionSubHeader(): string {
      if (isValidEntityName(this.projectName)) {
        return this.projectName;
      }
      return "None";
    },
    projectsNames(): string[] {
      return this.projectStore.projectsNames;
    },
    isNewProject(): boolean {
      return this.$route.name === PROJECT_ROUTE_NAMES.PROJECT_NEW;
    },
  },
  methods: {
    initNamespaceSection(): void {
      if (this.namespace) {
        this.assignedNamespace = this.namespace;
        this.namespaceCreationMethod = "existingNamespace";
      } else if (isValidEntityName(this.projectName)) {
        this.assignedNamespace = `runai-${this.projectName}`;
        this.namespaceCreationMethod = "projectName";
      }
    },
    isValidEntityName(name: string): boolean | string {
      return isValidEntityName(name) || errorMessages.VALID_FORMAT;
    },
    isValidKubernetesNamespaceName(name: string): boolean | string {
      return isValidKubernetesNamespaceName(name) || errorMessages.VALID_NAMESPACE;
    },
    updateNamespaceByProjectName(): void {
      if (!this.isNewProject) return;
      if (this.isProjectNameBasedNamespace && isValidEntityName(this.projectName)) {
        this.assignedNamespace = `runai-${this.projectName}`;
        this.$emit("update:namespace", undefined);
      } else {
        this.assignedNamespace = "";
        this.$emit("update:namespace", this.assignedNamespace);
      }
    },
  },
  watch: {
    projectName() {
      if (this.isProjectNameBasedNamespace && isValidEntityName(this.projectName)) {
        this.assignedNamespace = `runai-${this.projectName}`;
        this.$emit("update:namespace", undefined);
      } else {
        this.$emit("update:namespace", this.assignedNamespace);
      }
    },
    isProjectNameBasedNamespace() {
      this.updateNamespaceByProjectName();
    },
    assignedNamespace() {
      if (this.isProjectNameBasedNamespace && isValidEntityName(this.projectName)) {
        this.$emit("update:namespace", undefined);
      } else {
        this.$emit("update:namespace", this.assignedNamespace);
      }
    },
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>
