<template>
  <policy-non-compliance-card-wrapper
    :compliance-data="data.compliance || undefined"
    :field="data.meta.kind"
    :show-disabled-info="showDisabledInfo"
  >
    <runai-select-card
      :value="isSelected"
      :disabled="disabled"
      @clicked="$emit('card-clicked')"
      :aid="getAid(data.meta.name)"
      class="compute-resource-card"
      :class="{ 'card-disabled': disabled }"
    >
      <div class="workload-card-content">
        <section class="top-card-section">
          <div class="compute-resource-logo">
            <runai-svg-icon name="compute-resource-gray" size="42" />
          </div>
        </section>
        <div class="compute-resource-card-title q-mb-md" :class="{ 'text-primary': isSelected }">
          <div class="col text-subtitle1 text-weight-medium ellipsis">
            {{ data.meta.name }}
          </div>
        </div>
        <div class="compute-resource-card-details">
          <div class="row">
            <span class="text-weight-medium q-mr-xs">GPU:</span>
            {{ gpu }}
          </div>
          <div class="row">
            <span class="text-weight-medium q-mr-xs">CPU (Cores):</span>
            {{ data.spec.cpuCoreRequest }}
          </div>
          <div class="row">
            <span class="text-weight-medium q-mr-xs">CPU memory:</span>
            {{ data.spec.cpuMemoryRequest }}
          </div>
        </div>
      </div>
    </runai-select-card>
  </policy-non-compliance-card-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// cmp
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { RunaiSelectCard } from "@/components/common/runai-select-card";

// models
import type { ComputeAsset } from "@/swagger-models/assets-service-client";

// utils
import { spaceToDash, toLowerCase } from "@/utils/string.util";
import { policyNonComplianceCardWrapper } from "../../policy-non-compliance-card-wrapper";

export default defineComponent({
  components: {
    RunaiSvgIcon,
    RunaiSelectCard,
    policyNonComplianceCardWrapper,
  },
  emits: ["card-clicked"],
  props: {
    data: {
      type: Object as PropType<ComputeAsset>,
      required: true,
    },
    isSelected: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    showDisabledInfo: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
  },
  computed: {
    gpu(): string {
      const { gpuDevicesRequest, gpuMemoryRequest, gpuPortionRequest, migProfile } = this.data.spec;
      // if (!gpuRequestType) return "";
      return (gpuPortionRequest || gpuMemoryRequest || migProfile || gpuDevicesRequest || 0) + "";
    },
  },
  methods: {
    getAid(name: string): string {
      return `${toLowerCase(spaceToDash(name))}-card`;
    },
  },
});
</script>

<style lang="scss" scoped>
.compute-resource-card {
  .workload-card-content {
    .top-card-section {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
