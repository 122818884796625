<template>
  <section class="workload-charts">
    <template v-if="hasMetrics">
      <section class="chart gpu-ulitization-chart" ref="gpu-ulitization-chart"></section>
      <section class="chart gpu-memory-usage-chart" ref="gpu-memory-usage-chart"></section>
      <section class="chart cpu-usage-chart" ref="cpu-usage-chart"></section>
      <section class="chart cpu-memory-usage-chart" ref="cpu-memory-usage-chart"></section>
    </template>
    <section v-else class="column items-center q-my-lg">
      <span> No data found for the selected time frame </span>
      <q-icon name="fa-thin fa-chart-line q-my-lg" size="60px"></q-icon>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { chartUtil } from "@/utils/chart.util";
import Highcharts from "highcharts";
import type { Options } from "highcharts";
import type { Chart } from "highcharts";

export default defineComponent({
  props: {
    gpuUtilizationMetrics: {
      type: Array as PropType<Array<Array<number>>>,
      required: true,
    },
    gpuMemoryUsageMetrics: {
      type: Array as PropType<Array<Array<number>>>,
      required: true,
    },
    cpuUsageMetrics: {
      type: Array as PropType<Array<Array<number>>>,
      required: true,
    },
    cpuMemoryUsageMetrics: {
      type: Array as PropType<Array<Array<number>>>,
      required: true,
    },
    isHoursRange: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      gpuChartGroup: [] as Highcharts.Chart[],
      cpuChartGroup: [] as Highcharts.Chart[],
      gpuChartContainers: [] as Array<HTMLElement>,
      cpuChartContainers: [] as Array<HTMLElement>,
      cpuUsageChart: null as Highcharts.Chart | null,
      cpuMemoryUsageChart: null as Highcharts.Chart | null,
      gpuUtilizationChart: null as Highcharts.Chart | null,
      gpuMemoryUsageChart: null as Highcharts.Chart | null,
      gpuUtilizationChartOptions: null as Highcharts.Options | null,
      gpuMemoryUsageChartOptions: null as Highcharts.Options | null,
      cpuUsageChartOptions: null as Highcharts.Options | null,
      cpuMemoryUsageChartOptions: null as Highcharts.Options | null,
    };
  },
  mounted() {
    this.cpuChartContainers = [
      this.$refs["cpu-usage-chart"] as HTMLElement,
      this.$refs["cpu-memory-usage-chart"] as HTMLElement,
    ];
    this.gpuChartContainers = [
      this.$refs["gpu-ulitization-chart"] as HTMLElement,
      this.$refs["gpu-memory-usage-chart"] as HTMLElement,
    ];
    this.loadChartsOptions();
    this.initCharts();
  },
  computed: {
    metrics() {
      return {
        gpuUtilizationMetrics: this.gpuUtilizationMetrics,
        gpuMemoryUsageMetrics: this.gpuMemoryUsageMetrics,
        cpuUtilizationMetrics: this.cpuUsageMetrics,
        cpuMemoryUsageMetrics: this.cpuMemoryUsageMetrics,
      };
    },
    hasMetrics(): boolean {
      return (
        !!this.gpuUtilizationMetrics.length ||
        !!this.gpuMemoryUsageMetrics.length ||
        !!this.cpuUsageMetrics.length ||
        !!this.cpuMemoryUsageMetrics.length
      );
    },
  },
  methods: {
    loadChartsOptions(): void {
      if (this.gpuUtilizationMetrics.length) {
        this.gpuUtilizationChartOptions = chartUtil.getSingleLineChartOptions(
          this.gpuChartGroup,
          [this.gpuUtilizationMetrics],
          "#9b19f5",
          "GPU utilization",
          "%",
          this.isHoursRange,
        );
      }

      if (this.gpuMemoryUsageMetrics.length) {
        this.gpuMemoryUsageChartOptions = chartUtil.getSingleLineChartOptions(
          this.gpuChartGroup,
          [this.gpuMemoryUsageMetrics],
          "#9b19f5",
          "GPU memory usage",
          "GB",
          this.isHoursRange,
          true,
          2,
        );
      }

      if (this.cpuUsageMetrics.length) {
        this.cpuUsageChartOptions = chartUtil.getSingleLineChartOptions(
          this.cpuChartGroup,
          [this.cpuUsageMetrics],
          "#0cb3ff",
          "CPU usage",
          "Core(s)",
          this.isHoursRange,
          false,
          5,
        );
      }

      if (this.cpuMemoryUsageMetrics.length) {
        this.cpuMemoryUsageChartOptions = chartUtil.getSingleLineChartOptions(
          this.cpuChartGroup,
          [this.cpuMemoryUsageMetrics],
          "#0cb3ff",
          "CPU memory usage",
          "GB",
          this.isHoursRange,
          true,
        );
      }
    },
    initCharts(): void {
      if (this.gpuUtilizationMetrics.length) {
        this.gpuUtilizationChart = Highcharts.chart(
          this.gpuChartContainers[0] as HTMLElement,
          this.gpuUtilizationChartOptions as Options,
        );
        this.gpuChartGroup.push(this.gpuUtilizationChart);
      }

      if (this.gpuMemoryUsageMetrics.length) {
        this.gpuMemoryUsageChart = Highcharts.chart(
          this.gpuChartContainers[1] as HTMLElement,
          this.gpuMemoryUsageChartOptions as Options,
        );
        this.gpuChartGroup.push(this.gpuMemoryUsageChart);
      }

      if (this.cpuUsageMetrics.length) {
        this.cpuUsageChart = Highcharts.chart(
          this.cpuChartContainers[0] as HTMLElement,
          this.cpuUsageChartOptions as Options,
        );
        this.cpuChartGroup.push(this.cpuUsageChart);
      }

      if (this.cpuMemoryUsageMetrics.length) {
        this.cpuMemoryUsageChart = Highcharts.chart(
          this.cpuChartContainers[1] as HTMLElement,
          this.cpuMemoryUsageChartOptions as Options,
        );
        this.cpuChartGroup.push(this.cpuMemoryUsageChart);
      }
      if (this.gpuChartGroup.length) {
        chartUtil.addMultiChartsCrosshairEvents(this.gpuChartGroup, this.gpuChartContainers);
      }
      if (this.cpuChartGroup.length) {
        chartUtil.addMultiChartsCrosshairEvents(this.cpuChartGroup, this.cpuChartContainers);
      }
    },
    updateCharts(): void {
      if (this.gpuUtilizationMetrics.length) {
        if (!this.gpuUtilizationChart) {
          this.gpuUtilizationChart = Highcharts.chart(
            this.gpuChartContainers[0] as HTMLElement,
            this.gpuUtilizationChartOptions as Options,
          );
        } else {
          this.gpuUtilizationChart.update(this.gpuUtilizationChartOptions as Options, true, false, true);
        }
      }

      if (this.gpuMemoryUsageMetrics.length) {
        if (!this.gpuMemoryUsageChart) {
          this.gpuUtilizationChart = Highcharts.chart(
            this.gpuChartContainers[0] as HTMLElement,
            this.gpuUtilizationChartOptions as Options,
          );
        } else {
          (this.gpuMemoryUsageChart as Chart).update(this.gpuMemoryUsageChartOptions as Options, true, false, true);
        }
      }

      if (this.cpuUsageMetrics.length) {
        if (!this.cpuUsageChart) {
          this.cpuUsageChart = Highcharts.chart(
            this.cpuChartContainers[0] as HTMLElement,
            this.cpuUsageChartOptions as Options,
          );
        } else {
          (this.cpuUsageChart as Chart).update(this.cpuUsageChartOptions as Options, true, false, true);
        }
      }

      if (this.cpuMemoryUsageMetrics.length) {
        if (!this.cpuMemoryUsageChart) {
          this.cpuMemoryUsageChart = Highcharts.chart(
            this.cpuChartContainers[1] as HTMLElement,
            this.cpuMemoryUsageChartOptions as Options,
          );
        } else {
          (this.cpuMemoryUsageChart as Chart).update(this.cpuMemoryUsageChartOptions as Options, true, false, true);
        }
      }
    },
    resizeCharts(): void {
      [...this.cpuChartGroup, ...this.gpuChartGroup].forEach((chart: Highcharts.Chart) => {
        chart.reflow();
      });
    },
  },
  watch: {
    metrics: {
      handler() {
        this.loadChartsOptions();
        this.updateCharts();
      },
      deep: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.workload-charts {
  padding: 0 10px;
  height: 100%;
  overflow: auto;
  .fa-chart-line {
    color: $black-54;
  }
  .chart {
    margin-bottom: 15px;
  }
  .cpu-memory-usage-chart {
    margin-bottom: 60px;
  }
}
</style>
