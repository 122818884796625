import { dateUtil } from "@/utils/date.util";
import { memoryFormat } from "@/utils/format.util";
import { tableNumberFormat } from "@/utils/table-format.util";

import type { AssetRef, DisplayedJob, Workspace, ToolType } from "@/swagger-models/assets-service-client";
import type { IStatusColOptions, ITableColumn } from "./table.model";
import type { IFilterBy } from "./filter.model";
import { ECustomCell } from "./table.model";
import { workloadUtil } from "@/utils/workload.util";

export interface IWorkspaceFilterBy extends IFilterBy {
  sortDirection?: string;
  name?: string;
  id?: string;
}

export interface IExtendJobValues {
  usedMemory?: string;
  usedCPUs?: string;
  gpusUtilization?: string;
  usedGpuMemory?: string;
  currentAllocatedGPUsMemoryBytes?: number;
}
export interface IWorkspaceList extends Workspace {
  connections?: Array<IToolItem>;
  extendJobValues?: IExtendJobValues;
}

export interface IToolItem {
  toolType: ToolType;
  toolName: string;
  url: string;
  authorizedUsers?: string[] | null;
}

export const allWorkspaceColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Workspace",
    field: (row: IWorkspaceList) => row.meta.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  status: {
    name: "workspace-status",
    label: "Status",
    field: (row: IWorkspaceList) => row.job,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (job: DisplayedJob): IStatusColOptions | string =>
      workloadUtil.getWorkspaceTrainingStatusColOptions(job, job?.statusTooltip),
    sort: (jobA: DisplayedJob, jobB: DisplayedJob): number => {
      if (jobA.status && jobB.status) {
        return jobA.status.localeCompare(jobB.status);
      }
      return -1;
    },
    exportFormat: (job: DisplayedJob) => job.status || "NA",
    filterKey: "status",
  },
  createdBy: {
    name: "createdBy",
    label: "Created by",
    field: (row: IWorkspaceList) => row.meta.createdBy,
    sortable: true,
    align: "left",
  },
  project: {
    name: "project",
    label: "Project",
    field: (row: IWorkspaceList) => row.job?.project,
    sortable: true,
    align: "left",
  },
  environment: {
    name: "environment",
    label: "Environment",
    field: (row: IWorkspaceList) => row.spec.assets.environment,
    sortable: true,
    align: "left",
    format: (env: AssetRef) => env.name,
  },
  imageName: {
    name: "imageName",
    label: "Image",
    field: (row: IWorkspaceList) => row.job?.imageName,
    sortable: true,
    align: "left",
  },
  dataSource: {
    name: "data-sources",
    label: "Data source(s)",
    field: (row: IWorkspaceList) => row.spec.assets.datasources,
    sortable: true,
    align: "left",
    format: (dataSources: Array<AssetRef>): Array<string> => {
      if (!dataSources?.length) return [];
      return dataSources.map((ds: AssetRef) => ds.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "data-source-clicked" },
  },
  node: {
    name: "node",
    label: "Node",
    field: (row: IWorkspaceList) => row.job?.nodeId,
    sortable: true,
    align: "left",
  },
  nodePool: {
    name: "nodePool",
    label: "Node pool",
    field: (row: IWorkspaceList) => row.job?.nodePool,
    sortable: true,
    align: "left",
  },
  creationTime: {
    name: "creationTime",
    label: "Creation time",
    field: (row: IWorkspaceList) => row.job?.creationTime,
    sortable: true,
    align: "left",
    format: (val: number | undefined) => {
      if (!val) return "";
      return dateUtil.dateAndTimeFormat(new Date(val));
    },
  },
  currentRequestedGPUs: {
    name: "currentRequestedGPUs",
    label: "Requested GPUs",
    field: (row: IWorkspaceList) => row.job?.currentRequestedGPUs,
    sortable: true,
    align: "left",
    format: tableNumberFormat(),
  },
  currentAllocatedGPUs: {
    name: "currentAllocatedGPUs",
    label: "Allocated GPUs",
    field: (row: IWorkspaceList) => row.job?.currentAllocatedGPUs,
    sortable: true,
    align: "left",
    format: tableNumberFormat(),
  },
  currentAllocatedGPUsMemory: {
    name: "currentAllocatedGPUsMemory",
    label: "Allocated GPU memory",
    field: (row: IWorkspaceList) => row.job?.currentAllocatedGPUsMemory,
    sortable: true,
    align: "left",
  },
  usedGpuMemory: {
    name: "usedGpuMemory",
    label: "Used GPU memory",
    field: (row: IWorkspaceList) => row.extendJobValues?.usedGpuMemory,
    sortable: false,
    align: "left",
    format: memoryFormat,
  },
  gpusUtilization: {
    name: "gpusUtilization",
    label: "GPU compute utilization",
    field: (row: IWorkspaceList) => row.extendJobValues?.gpusUtilization,
    sortable: false,
    align: "left",
    format: (val: number) => {
      if (val || +val === 0) {
        return tableNumberFormat("%")(val);
      } else {
        return "-";
      }
    },
  },
  usedCPUs: {
    name: "usedCPUs",
    label: "Used CPU (cores)",
    field: (row: IWorkspaceList) => row.extendJobValues?.usedCPUs,
    sortable: false,
    align: "left",
    format: tableNumberFormat(),
  },
  usedMemory: {
    name: "usedMemory",
    label: "Used CPU memory",
    field: (row: IWorkspaceList) => row.extendJobValues?.usedMemory,
    sortable: false,
    align: "left",
    format: memoryFormat,
  },
};

export const allWorkspaceColumns: Array<ITableColumn> = [
  allWorkspaceColumnsMap.name,
  allWorkspaceColumnsMap.status,
  allWorkspaceColumnsMap.createdBy,
  allWorkspaceColumnsMap.project,
  allWorkspaceColumnsMap.environment,
  allWorkspaceColumnsMap.imageName,
  allWorkspaceColumnsMap.dataSource,
  allWorkspaceColumnsMap.node,
  allWorkspaceColumnsMap.nodePool,
  allWorkspaceColumnsMap.creationTime,
  allWorkspaceColumnsMap.currentRequestedGPUs,
  allWorkspaceColumnsMap.currentAllocatedGPUs,
  allWorkspaceColumnsMap.currentAllocatedGPUsMemory,
  allWorkspaceColumnsMap.usedGpuMemory,
  allWorkspaceColumnsMap.gpusUtilization,
  allWorkspaceColumnsMap.usedCPUs,
  allWorkspaceColumnsMap.usedMemory,
];

export const workspaceIndexColumns: Array<ITableColumn> = [
  { ...allWorkspaceColumnsMap.name, display: true, mandatory: true },
  { ...allWorkspaceColumnsMap.status, display: true },
  { ...allWorkspaceColumnsMap.createdBy, display: false },
  { ...allWorkspaceColumnsMap.project, display: true },
  { ...allWorkspaceColumnsMap.environment, display: false },
  { ...allWorkspaceColumnsMap.imageName, display: true },
  { ...allWorkspaceColumnsMap.dataSource, display: true },
  { ...allWorkspaceColumnsMap.node, display: false },
  { ...allWorkspaceColumnsMap.nodePool, display: false },
  { ...allWorkspaceColumnsMap.creationTime, display: false },
  { ...allWorkspaceColumnsMap.currentRequestedGPUs, display: true },
  { ...allWorkspaceColumnsMap.currentAllocatedGPUs, display: true },
  { ...allWorkspaceColumnsMap.currentAllocatedGPUsMemory, display: false },
  { ...allWorkspaceColumnsMap.usedGpuMemory, display: false },
  { ...allWorkspaceColumnsMap.gpusUtilization, display: false },
  { ...allWorkspaceColumnsMap.usedCPUs, display: false },
  { ...allWorkspaceColumnsMap.usedMemory, display: false },
];

export const workspaceMiniTableColumns: Array<ITableColumn> = [
  { ...allWorkspaceColumnsMap.name, display: true },
  { ...allWorkspaceColumnsMap.status, display: true },
];
