/* tslint:disable */
/* eslint-disable */
/**
 * WorkloadsService
 * An API for managing RunAi scheduler managed workloads.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * The actions support provided for the workload.
 * @export
 * @interface ActionsSupport
 */
export interface ActionsSupport {
    /**
     * 
     * @type {boolean}
     * @memberof ActionsSupport
     */
    'delete'?: boolean;
    /**
     * This permission includes resume as well as suspend.
     * @type {boolean}
     * @memberof ActionsSupport
     */
    'suspend'?: boolean;
}
/**
 * 
 * @export
 * @interface AllocatedResources
 */
export interface AllocatedResources {
    /**
     * Required if and only if gpuRequestType is portion. States the number of GPUs allocated for the created workload. The default is no allocated GPUs.
     * @type {number}
     * @memberof AllocatedResources
     */
    'gpu'?: number | null;
    /**
     * Required if and only if gpuRequestType is migProfile. States the memory profile to be used for workload running on NVIDIA Multi-Instance GPU (MIG) technology.
     * @type {string}
     * @memberof AllocatedResources
     */
    'migProfile'?: AllocatedResourcesMigProfileEnum;
    /**
     * 
     * @type {string}
     * @memberof AllocatedResources
     */
    'gpuMemory'?: string | null;
    /**
     * States the amount of CPU cores used by the workload running.
     * @type {number}
     * @memberof AllocatedResources
     */
    'cpu'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AllocatedResources
     */
    'cpuMemory'?: string | null;
    /**
     * Set of extended resources
     * @type {Array<ExtendedResourcesInner>}
     * @memberof AllocatedResources
     */
    'extendedResources'?: Array<ExtendedResourcesInner> | null;
}

export const AllocatedResourcesMigProfileEnum = {
    _1g5gb: '1g.5gb',
    _1g10gb: '1g.10gb',
    _2g10gb: '2g.10gb',
    _2g20gb: '2g.20gb',
    _3g20gb: '3g.20gb',
    _3g40gb: '3g.40gb',
    _4g20gb: '4g.20gb',
    _4g40gb: '4g.40gb',
    _7g40gb: '7g.40gb',
    _7g80gb: '7g.80gb'
} as const;

export type AllocatedResourcesMigProfileEnum = typeof AllocatedResourcesMigProfileEnum[keyof typeof AllocatedResourcesMigProfileEnum];

/**
 * 
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * The type of the condition, such as Failed or Available. See Types of domain status conditions.
     * @type {string}
     * @memberof Condition
     */
    'type': string;
    /**
     * The status of the condition, such as True, False or Unknown.
     * @type {string}
     * @memberof Condition
     */
    'status': string;
    /**
     * An optional, human-readable message providing more details about the condition.
     * @type {string}
     * @memberof Condition
     */
    'message'?: string;
    /**
     * The reason for the Failed condition. Not applicable to other types of condition.
     * @type {string}
     * @memberof Condition
     */
    'reason'?: string;
    /**
     * A timestamp of when the condition was created or the last time time the condition transitioned from one status to another.
     * @type {string}
     * @memberof Condition
     */
    'lastTransitionTime'?: string | null;
}
/**
 * Connection that either expose port from the container (a port is associated with a tool that the container runs), or URL to be used for connecting to an external tool that is related to the action of the container (such as Weights & Biases).
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'toolType': string;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'startedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CountWorkloads200Response
 */
export interface CountWorkloads200Response {
    /**
     * 
     * @type {number}
     * @memberof CountWorkloads200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Datasource
 */
export interface Datasource {
    /**
     * 
     * @type {string}
     * @memberof Datasource
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Datasource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Datasource
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     * 
     * @type {Array<Connection>}
     * @memberof Environment
     */
    'connections': Array<Connection>;
    /**
     * 
     * @type {string}
     * @memberof Environment
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Environment
     */
    'id': string;
    /**
     * 
     * @type {ReplicaType}
     * @memberof Environment
     */
    'replicaType'?: ReplicaType | null;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'source'?: string;
    /**
     * 
     * @type {InvolvedObject}
     * @memberof Event
     */
    'involvedObject'?: InvolvedObject;
}
/**
 * 
 * @export
 * @interface EventComputedFields
 */
export interface EventComputedFields {
    /**
     * 
     * @type {number}
     * @memberof EventComputedFields
     */
    'tenantId': number;
}
/**
 * 
 * @export
 * @interface EventSyncFields
 */
export interface EventSyncFields {
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof EventSyncFields
     */
    'source': string;
    /**
     * 
     * @type {InvolvedObject}
     * @memberof EventSyncFields
     */
    'involvedObject': InvolvedObject;
}
/**
 * 
 * @export
 * @interface EventsSyncRequest
 */
export interface EventsSyncRequest {
    /**
     * 
     * @type {Array<EventSyncFields>}
     * @memberof EventsSyncRequest
     */
    'events': Array<EventSyncFields>;
}
/**
 * 
 * @export
 * @interface ExtendedResourcesInner
 */
export interface ExtendedResourcesInner {
    /**
     * 
     * @type {string}
     * @memberof ExtendedResourcesInner
     */
    'limit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtendedResourcesInner
     */
    'request'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtendedResourcesInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GetWorkloadEvents200Response
 */
export interface GetWorkloadEvents200Response {
    /**
     * 
     * @type {number}
     * @memberof GetWorkloadEvents200Response
     */
    'next'?: number;
    /**
     * 
     * @type {Array<Event>}
     * @memberof GetWorkloadEvents200Response
     */
    'events': Array<Event>;
}
/**
 * 
 * @export
 * @interface GetWorkloadHistory200Response
 */
export interface GetWorkloadHistory200Response {
    /**
     * 
     * @type {number}
     * @memberof GetWorkloadHistory200Response
     */
    'next'?: number;
    /**
     * 
     * @type {Array<HistoryRecord>}
     * @memberof GetWorkloadHistory200Response
     */
    'records': Array<HistoryRecord>;
}
/**
 * 
 * @export
 * @interface GetWorkloadPods200Response
 */
export interface GetWorkloadPods200Response {
    /**
     * 
     * @type {number}
     * @memberof GetWorkloadPods200Response
     */
    'next'?: number;
    /**
     * 
     * @type {Array<Pod>}
     * @memberof GetWorkloadPods200Response
     */
    'pods': Array<Pod>;
}
/**
 * 
 * @export
 * @interface GetWorkloads200Response
 */
export interface GetWorkloads200Response {
    /**
     * 
     * @type {number}
     * @memberof GetWorkloads200Response
     */
    'next'?: number;
    /**
     * 
     * @type {Array<Workload>}
     * @memberof GetWorkloads200Response
     */
    'workloads': Array<Workload>;
}
/**
 * Whether the request for GPU resources is stated in terms of portion, memory or mig profile.
 * @export
 * @enum {string}
 */

export const GpuRequestType = {
    Portion: 'portion',
    Memory: 'memory',
    MigProfile: 'migProfile'
} as const;

export type GpuRequestType = typeof GpuRequestType[keyof typeof GpuRequestType];


/**
 * 
 * @export
 * @interface HistoryRecord
 */
export interface HistoryRecord {
    /**
     * 
     * @type {HistoryRecordMeta}
     * @memberof HistoryRecord
     */
    'meta': HistoryRecordMeta;
    /**
     * 
     * @type {HistoryRecordSpec}
     * @memberof HistoryRecord
     */
    'spec': HistoryRecordSpec;
}
/**
 * 
 * @export
 * @interface HistoryRecordMeta
 */
export interface HistoryRecordMeta {
    /**
     * 
     * @type {string}
     * @memberof HistoryRecordMeta
     */
    'creationTimestamp': string;
    /**
     * 
     * @type {HistoryRecordType}
     * @memberof HistoryRecordMeta
     */
    'type': HistoryRecordType;
}
/**
 * 
 * @export
 * @interface HistoryRecordSpec
 */
export interface HistoryRecordSpec {
    /**
     * 
     * @type {Event}
     * @memberof HistoryRecordSpec
     */
    'event'?: Event | null;
    /**
     * 
     * @type {PhaseUpdate}
     * @memberof HistoryRecordSpec
     */
    'phaseUpdate'?: PhaseUpdate | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HistoryRecordType = {
    Event: 'Event',
    PhaseUpdate: 'PhaseUpdate'
} as const;

export type HistoryRecordType = typeof HistoryRecordType[keyof typeof HistoryRecordType];


/**
 * 
 * @export
 * @interface InvolvedObject
 */
export interface InvolvedObject {
    /**
     * 
     * @type {string}
     * @memberof InvolvedObject
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof InvolvedObject
     */
    'kind': string;
    /**
     * 
     * @type {string}
     * @memberof InvolvedObject
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvolvedObject
     */
    'namespace': string;
}
/**
 * Required if and only if gpuRequestType is migProfile. States the memory profile to be used for workload running on NVIDIA Multi-Instance GPU (MIG) technology.
 * @export
 * @enum {string}
 */

export const MigProfile = {
    _1g5gb: '1g.5gb',
    _1g10gb: '1g.10gb',
    _2g10gb: '2g.10gb',
    _2g20gb: '2g.20gb',
    _3g20gb: '3g.20gb',
    _3g40gb: '3g.40gb',
    _4g20gb: '4g.20gb',
    _4g40gb: '4g.40gb',
    _7g40gb: '7g.40gb',
    _7g80gb: '7g.80gb'
} as const;

export type MigProfile = typeof MigProfile[keyof typeof MigProfile];


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Phase = {
    Creating: 'Creating',
    Initializing: 'Initializing',
    Resuming: 'Resuming',
    Pending: 'Pending',
    Deleting: 'Deleting',
    Running: 'Running',
    Updating: 'Updating',
    Stopped: 'Stopped',
    Stopping: 'Stopping',
    Degraded: 'Degraded',
    Failed: 'Failed',
    Completed: 'Completed',
    Terminating: 'Terminating',
    Unknown: 'Unknown'
} as const;

export type Phase = typeof Phase[keyof typeof Phase];


/**
 * 
 * @export
 * @interface PhaseUpdate
 */
export interface PhaseUpdate {
    /**
     * 
     * @type {Phase}
     * @memberof PhaseUpdate
     */
    'phase': Phase;
    /**
     * 
     * @type {string}
     * @memberof PhaseUpdate
     */
    'phaseMessage': string;
}
/**
 * 
 * @export
 * @interface Pod
 */
export interface Pod {
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'priorityClassName': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'workloadId': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'nodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {Array<Container>}
     * @memberof Pod
     */
    'containers': Array<Container>;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'currentNodePool'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pod
     */
    'requestedNodePools'?: Array<string>;
    /**
     * 
     * @type {PodRequestResources}
     * @memberof Pod
     */
    'requestedResources'?: PodRequestResources | null;
    /**
     * 
     * @type {AllocatedResources}
     * @memberof Pod
     */
    'allocatedResources'?: AllocatedResources | null;
    /**
     * 
     * @type {Array<Toleration>}
     * @memberof Pod
     */
    'tolerations'?: Array<Toleration>;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'k8sPhase': string;
    /**
     * 
     * @type {number}
     * @memberof Pod
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'k8sPhaseUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Pod
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PodComputedFields
 */
export interface PodComputedFields {
    /**
     * 
     * @type {number}
     * @memberof PodComputedFields
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof PodComputedFields
     */
    'k8sPhaseUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PodComputedFields
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PodComputedFields
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PodRequestResources
 */
export interface PodRequestResources {
    /**
     * 
     * @type {GpuRequestType}
     * @memberof PodRequestResources
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * 
     * @type {RequestResourceCores}
     * @memberof PodRequestResources
     */
    'gpu'?: RequestResourceCores | null;
    /**
     * 
     * @type {RequestResourceQuantity}
     * @memberof PodRequestResources
     */
    'gpuMemory'?: RequestResourceQuantity | null;
    /**
     * 
     * @type {RequestResourceCores}
     * @memberof PodRequestResources
     */
    'cpu'?: RequestResourceCores | null;
    /**
     * 
     * @type {RequestResourceQuantity}
     * @memberof PodRequestResources
     */
    'cpuMemory'?: RequestResourceQuantity | null;
    /**
     * Required if and only if gpuRequestType is migProfile. States the memory profile to be used for workload running on NVIDIA Multi-Instance GPU (MIG) technology.
     * @type {string}
     * @memberof PodRequestResources
     */
    'migProfile'?: PodRequestResourcesMigProfileEnum;
    /**
     * Set of extended resources
     * @type {Array<ExtendedResourcesInner>}
     * @memberof PodRequestResources
     */
    'extendedResources'?: Array<ExtendedResourcesInner> | null;
}

export const PodRequestResourcesMigProfileEnum = {
    _1g5gb: '1g.5gb',
    _1g10gb: '1g.10gb',
    _2g10gb: '2g.10gb',
    _2g20gb: '2g.20gb',
    _3g20gb: '3g.20gb',
    _3g40gb: '3g.40gb',
    _4g20gb: '4g.20gb',
    _4g40gb: '4g.40gb',
    _7g40gb: '7g.40gb',
    _7g80gb: '7g.80gb'
} as const;

export type PodRequestResourcesMigProfileEnum = typeof PodRequestResourcesMigProfileEnum[keyof typeof PodRequestResourcesMigProfileEnum];

/**
 * 
 * @export
 * @interface PodSyncFields
 */
export interface PodSyncFields {
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'priorityClassName': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'workloadId': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'nodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {Array<Container>}
     * @memberof PodSyncFields
     */
    'containers': Array<Container>;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'currentNodePool'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PodSyncFields
     */
    'requestedNodePools'?: Array<string>;
    /**
     * 
     * @type {PodRequestResources}
     * @memberof PodSyncFields
     */
    'requestedResources'?: PodRequestResources | null;
    /**
     * 
     * @type {AllocatedResources}
     * @memberof PodSyncFields
     */
    'allocatedResources'?: AllocatedResources | null;
    /**
     * 
     * @type {Array<Toleration>}
     * @memberof PodSyncFields
     */
    'tolerations'?: Array<Toleration>;
    /**
     * 
     * @type {string}
     * @memberof PodSyncFields
     */
    'k8sPhase': string;
}
/**
 * 
 * @export
 * @interface PodsSyncRequest
 */
export interface PodsSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof PodsSyncRequest
     */
    'projectId': string;
    /**
     * 
     * @type {Array<PodSyncFields>}
     * @memberof PodsSyncRequest
     */
    'pods': Array<PodSyncFields>;
    /**
     * the first pod id to sync. if empty, all pods before the first one sent will be deleted.
     * @type {string}
     * @memberof PodsSyncRequest
     */
    'from'?: string;
    /**
     * the last pod to sync. if empty, all pods after the last one sent will be deleted.
     * @type {string}
     * @memberof PodsSyncRequest
     */
    'to'?: string;
    /**
     * pod ids to ignore when deleting.
     * @type {Array<string>}
     * @memberof PodsSyncRequest
     */
    'idsToIgnore'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReplicaType = {
    Master: 'Master',
    Worker: 'Worker'
} as const;

export type ReplicaType = typeof ReplicaType[keyof typeof ReplicaType];


/**
 * 
 * @export
 * @interface RequestResourceCores
 */
export interface RequestResourceCores {
    /**
     * 
     * @type {number}
     * @memberof RequestResourceCores
     */
    'limit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestResourceCores
     */
    'request'?: number | null;
}
/**
 * 
 * @export
 * @interface RequestResourceQuantity
 */
export interface RequestResourceQuantity {
    /**
     * 
     * @type {string}
     * @memberof RequestResourceQuantity
     */
    'limit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestResourceQuantity
     */
    'request'?: string | null;
}
/**
 * 
 * @export
 * @interface RequestedPods
 */
export interface RequestedPods {
    /**
     * 
     * @type {number}
     * @memberof RequestedPods
     */
    'number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestedPods
     */
    'min'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestedPods
     */
    'max'?: number | null;
    /**
     * specifies how many Pods can run in parallel
     * @type {number}
     * @memberof RequestedPods
     */
    'parallelism'?: number | null;
    /**
     * specifies how many Pods should terminate successfully before the Workload is completed
     * @type {number}
     * @memberof RequestedPods
     */
    'completions'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Source = {
    Cli: 'CLI',
    ControlPlane: 'Control-plane',
    Other: 'Other'
} as const;

export type Source = typeof Source[keyof typeof Source];


/**
 * 
 * @export
 * @interface Toleration
 */
export interface Toleration {
    /**
     * Key is the taint key that the toleration applies to. Empty means match all taint keys.
     * @type {string}
     * @memberof Toleration
     */
    'key'?: string;
    /**
     * Operator represents a key\'s relationship to the value.
     * @type {string}
     * @memberof Toleration
     */
    'operator'?: string;
    /**
     * Value is the taint value the toleration matches to.
     * @type {string}
     * @memberof Toleration
     */
    'value'?: string;
    /**
     * Effect indicates the taint effect to match. Empty means match all taint effects.
     * @type {string}
     * @memberof Toleration
     */
    'effect'?: string;
    /**
     * TolerationSeconds represents the period of time the toleration (which must be of effect NoExecute, otherwise this field is ignored) tolerates the taint.
     * @type {number}
     * @memberof Toleration
     */
    'tolerationSeconds'?: number | null;
}
/**
 * 
 * @export
 * @interface Workload
 */
export interface Workload {
    /**
     * 
     * @type {number}
     * @memberof Workload
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof Workload
     */
    'runningPods': number;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'phaseUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'k8sPhaseUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'updatedAt': string;
    /**
     * 
     * @type {Source}
     * @memberof Workload
     */
    'source': Source;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'priorityClassName': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'submittedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'createdAt': string;
    /**
     * 
     * @type {WorkloadRequestResources}
     * @memberof Workload
     */
    'workloadRequestedResources'?: WorkloadRequestResources | null;
    /**
     * 
     * @type {WorkloadRequestResources}
     * @memberof Workload
     */
    'podsRequestedResources'?: WorkloadRequestResources | null;
    /**
     * 
     * @type {WorkloadAllocatedResources}
     * @memberof Workload
     */
    'allocatedResources'?: WorkloadAllocatedResources | null;
    /**
     * 
     * @type {ActionsSupport}
     * @memberof Workload
     */
    'actionsSupport'?: ActionsSupport;
    /**
     * 
     * @type {Phase}
     * @memberof Workload
     */
    'phase': Phase;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof Workload
     */
    'conditions': Array<Condition>;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'phaseMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'k8sPhase': string;
    /**
     * 
     * @type {RequestedPods}
     * @memberof Workload
     */
    'requestedPods'?: RequestedPods;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workload
     */
    'requestedNodePools'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workload
     */
    'currentNodePools'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workload
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {Array<WorkloadChildrenIdsInner>}
     * @memberof Workload
     */
    'childrenIds'?: Array<WorkloadChildrenIdsInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workload
     */
    'urls'?: Array<string>;
    /**
     * 
     * @type {Array<Datasource>}
     * @memberof Workload
     */
    'datasources'?: Array<Datasource>;
    /**
     * 
     * @type {Array<Environment>}
     * @memberof Workload
     */
    'environments'?: Array<Environment>;
    /**
     * 
     * @type {Array<Connection>}
     * @memberof Workload
     */
    'externalConnections'?: Array<Connection>;
    /**
     * 
     * @type {string}
     * @memberof Workload
     */
    'distributedFramework'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Workload
     */
    'additionalFields'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface WorkloadAllocatedResources
 */
export interface WorkloadAllocatedResources {
    /**
     * Required if and only if gpuRequestType is portion. States the number of GPUs allocated for the created workload. The default is no allocated GPUs.
     * @type {number}
     * @memberof WorkloadAllocatedResources
     */
    'gpu'?: number | null;
    /**
     * 
     * @type {Array<MigProfile>}
     * @memberof WorkloadAllocatedResources
     */
    'migProfile'?: Array<MigProfile> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkloadAllocatedResources
     */
    'gpuMemory'?: string | null;
    /**
     * States the amount of CPU cores used by the workload running.
     * @type {number}
     * @memberof WorkloadAllocatedResources
     */
    'cpu'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkloadAllocatedResources
     */
    'cpuMemory'?: string | null;
    /**
     * Set of extended resources
     * @type {Array<ExtendedResourcesInner>}
     * @memberof WorkloadAllocatedResources
     */
    'extendedResources'?: Array<ExtendedResourcesInner> | null;
}
/**
 * 
 * @export
 * @interface WorkloadChildrenIdsInner
 */
export interface WorkloadChildrenIdsInner {
    /**
     * 
     * @type {string}
     * @memberof WorkloadChildrenIdsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadChildrenIdsInner
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface WorkloadCreationFields
 */
export interface WorkloadCreationFields {
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'submittedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'createdAt': string;
    /**
     * 
     * @type {WorkloadRequestResources}
     * @memberof WorkloadCreationFields
     */
    'requestedResources'?: WorkloadRequestResources | null;
    /**
     * 
     * @type {RequestedPods}
     * @memberof WorkloadCreationFields
     */
    'requestedPods'?: RequestedPods;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadCreationFields
     */
    'requestedNodePools'?: Array<string>;
    /**
     * 
     * @type {Array<Datasource>}
     * @memberof WorkloadCreationFields
     */
    'datasources'?: Array<Datasource>;
    /**
     * 
     * @type {Array<Environment>}
     * @memberof WorkloadCreationFields
     */
    'environments'?: Array<Environment>;
    /**
     * 
     * @type {Array<Connection>}
     * @memberof WorkloadCreationFields
     */
    'externalConnections'?: Array<Connection>;
    /**
     * 
     * @type {string}
     * @memberof WorkloadCreationFields
     */
    'distributedFramework'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadCreationFields
     */
    'images'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkloadRequestResources
 */
export interface WorkloadRequestResources {
    /**
     * 
     * @type {GpuRequestType}
     * @memberof WorkloadRequestResources
     */
    'gpuRequestType'?: GpuRequestType | null;
    /**
     * 
     * @type {RequestResourceCores}
     * @memberof WorkloadRequestResources
     */
    'gpu'?: RequestResourceCores | null;
    /**
     * 
     * @type {RequestResourceQuantity}
     * @memberof WorkloadRequestResources
     */
    'gpuMemory'?: RequestResourceQuantity | null;
    /**
     * 
     * @type {RequestResourceCores}
     * @memberof WorkloadRequestResources
     */
    'cpu'?: RequestResourceCores | null;
    /**
     * 
     * @type {RequestResourceQuantity}
     * @memberof WorkloadRequestResources
     */
    'cpuMemory'?: RequestResourceQuantity | null;
    /**
     * 
     * @type {Array<MigProfile>}
     * @memberof WorkloadRequestResources
     */
    'migProfile'?: Array<MigProfile> | null;
    /**
     * Set of extended resources
     * @type {Array<ExtendedResourcesInner>}
     * @memberof WorkloadRequestResources
     */
    'extendedResources'?: Array<ExtendedResourcesInner> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkloadSortFilterFields = {
    Type: 'type',
    Name: 'name',
    ClusterId: 'clusterId',
    ProjectId: 'projectId',
    ProjectName: 'projectName',
    DepartmentId: 'departmentId',
    DepartmentName: 'departmentName',
    CreatedAt: 'createdAt',
    DeletedAt: 'deletedAt',
    SubmittedBy: 'submittedBy',
    Phase: 'phase',
    CompletedAt: 'completedAt'
} as const;

export type WorkloadSortFilterFields = typeof WorkloadSortFilterFields[keyof typeof WorkloadSortFilterFields];


/**
 * 
 * @export
 * @interface WorkloadSyncFields
 */
export interface WorkloadSyncFields {
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'submittedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'priorityClassName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'departmentName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'createdAt': string;
    /**
     * 
     * @type {WorkloadRequestResources}
     * @memberof WorkloadSyncFields
     */
    'requestedResources'?: WorkloadRequestResources | null;
    /**
     * 
     * @type {ActionsSupport}
     * @memberof WorkloadSyncFields
     */
    'actionsSupport'?: ActionsSupport;
    /**
     * 
     * @type {Phase}
     * @memberof WorkloadSyncFields
     */
    'phase': Phase;
    /**
     * 
     * @type {Source}
     * @memberof WorkloadSyncFields
     */
    'source': Source;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof WorkloadSyncFields
     */
    'conditions'?: Array<Condition>;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'phaseMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'k8sPhase': string;
    /**
     * 
     * @type {RequestedPods}
     * @memberof WorkloadSyncFields
     */
    'requestedPods'?: RequestedPods;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadSyncFields
     */
    'requestedNodePools'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadSyncFields
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'distributedFramework'?: string;
    /**
     * 
     * @type {Array<WorkloadChildrenIdsInner>}
     * @memberof WorkloadSyncFields
     */
    'childrenIds'?: Array<WorkloadChildrenIdsInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadSyncFields
     */
    'urls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkloadSyncFields
     */
    'additionalFields'?: string;
}
/**
 * 
 * @export
 * @interface WorkloadsSyncRequest
 */
export interface WorkloadsSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadsSyncRequest
     */
    'projectId': string;
    /**
     * 
     * @type {Array<WorkloadSyncFields>}
     * @memberof WorkloadsSyncRequest
     */
    'workloads': Array<WorkloadSyncFields>;
    /**
     * the first workload id to sync. if empty, all workloads before the first one sent will be deleted.
     * @type {string}
     * @memberof WorkloadsSyncRequest
     */
    'from'?: string;
    /**
     * the last workload to sync. if empty, all workloads after the last one sent will be deleted.
     * @type {string}
     * @memberof WorkloadsSyncRequest
     */
    'to'?: string;
    /**
     * workload ids to ignore when deleting.
     * @type {Array<string>}
     * @memberof WorkloadsSyncRequest
     */
    'idsToIgnore'?: Array<string>;
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the workload events. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadEvents: async (workloadId: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('getWorkloadEvents', 'workloadId', workloadId)
            const localVarPath = `/api/v1/workloads/{workloadId}/events`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the workload history. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadHistory: async (workloadId: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('getWorkloadHistory', 'workloadId', workloadId)
            const localVarPath = `/api/v1/workloads/{workloadId}/history`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync events.
         * @param {EventsSyncRequest} [eventsSyncRequest] The events to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEvents: async (eventsSyncRequest?: EventsSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workloads/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the workload events. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloadEvents(workloadId: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkloadEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloadEvents(workloadId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the workload history. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloadHistory(workloadId: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkloadHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloadHistory(workloadId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync events.
         * @param {EventsSyncRequest} [eventsSyncRequest] The events to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncEvents(eventsSyncRequest?: EventsSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncEvents(eventsSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the workload events. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadEvents(workloadId: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetWorkloadEvents200Response> {
            return localVarFp.getWorkloadEvents(workloadId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the workload history. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadHistory(workloadId: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetWorkloadHistory200Response> {
            return localVarFp.getWorkloadHistory(workloadId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync events.
         * @param {EventsSyncRequest} [eventsSyncRequest] The events to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncEvents(eventsSyncRequest?: EventsSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncEvents(eventsSyncRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Get the workload events. [Experimental]
     * @param {string} workloadId The requested workload id.
     * @param {number} [offset] The offset from which to start fetching records.
     * @param {number} [limit] The limit of returned records count, max 500 and default 50.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getWorkloadEvents(workloadId: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getWorkloadEvents(workloadId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the workload history. [Experimental]
     * @param {string} workloadId The requested workload id.
     * @param {number} [offset] The offset from which to start fetching records.
     * @param {number} [limit] The limit of returned records count, max 500 and default 50.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getWorkloadHistory(workloadId: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getWorkloadHistory(workloadId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync events.
     * @param {EventsSyncRequest} [eventsSyncRequest] The events to sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public syncEvents(eventsSyncRequest?: EventsSyncRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).syncEvents(eventsSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PodsApi - axios parameter creator
 * @export
 */
export const PodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a pod as it does not exist in the cluster anymore.
         * @param {string} podId The requested pod id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePod: async (podId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'podId' is not null or undefined
            assertParamExists('deletePod', 'podId', podId)
            const localVarPath = `/api/v1/workloads/pods/{podId}`
                .replace(`{${"podId"}}`, encodeURIComponent(String(podId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the workload pods. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadPods: async (workloadId: string, deleted?: boolean, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('getWorkloadPods', 'workloadId', workloadId)
            const localVarPath = `/api/v1/workloads/{workloadId}/pods`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync workloads pods.
         * @param {PodsSyncRequest} [podsSyncRequest] The pods to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkloadsPods: async (podsSyncRequest?: PodsSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workloads/pods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(podsSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert a single pod info from the cluster.
         * @param {string} podId The requested pod id.
         * @param {PodSyncFields} podSyncFields The pod info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertPod: async (podId: string, podSyncFields: PodSyncFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'podId' is not null or undefined
            assertParamExists('upsertPod', 'podId', podId)
            // verify required parameter 'podSyncFields' is not null or undefined
            assertParamExists('upsertPod', 'podSyncFields', podSyncFields)
            const localVarPath = `/api/v1/workloads/pods/{podId}`
                .replace(`{${"podId"}}`, encodeURIComponent(String(podId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(podSyncFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PodsApi - functional programming interface
 * @export
 */
export const PodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a pod as it does not exist in the cluster anymore.
         * @param {string} podId The requested pod id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePod(podId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePod(podId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the workload pods. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloadPods(workloadId: string, deleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkloadPods200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloadPods(workloadId, deleted, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync workloads pods.
         * @param {PodsSyncRequest} [podsSyncRequest] The pods to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWorkloadsPods(podsSyncRequest?: PodsSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWorkloadsPods(podsSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert a single pod info from the cluster.
         * @param {string} podId The requested pod id.
         * @param {PodSyncFields} podSyncFields The pod info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertPod(podId: string, podSyncFields: PodSyncFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertPod(podId, podSyncFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PodsApi - factory interface
 * @export
 */
export const PodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PodsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a pod as it does not exist in the cluster anymore.
         * @param {string} podId The requested pod id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePod(podId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePod(podId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the workload pods. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadPods(workloadId: string, deleted?: boolean, offset?: number, limit?: number, options?: any): AxiosPromise<GetWorkloadPods200Response> {
            return localVarFp.getWorkloadPods(workloadId, deleted, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync workloads pods.
         * @param {PodsSyncRequest} [podsSyncRequest] The pods to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkloadsPods(podsSyncRequest?: PodsSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncWorkloadsPods(podsSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert a single pod info from the cluster.
         * @param {string} podId The requested pod id.
         * @param {PodSyncFields} podSyncFields The pod info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertPod(podId: string, podSyncFields: PodSyncFields, options?: any): AxiosPromise<void> {
            return localVarFp.upsertPod(podId, podSyncFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PodsApi - object-oriented interface
 * @export
 * @class PodsApi
 * @extends {BaseAPI}
 */
export class PodsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a pod as it does not exist in the cluster anymore.
     * @param {string} podId The requested pod id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodsApi
     */
    public deletePod(podId: string, options?: AxiosRequestConfig) {
        return PodsApiFp(this.configuration).deletePod(podId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the workload pods. [Experimental]
     * @param {string} workloadId The requested workload id.
     * @param {boolean} [deleted] if true, return only deleted resources.
     * @param {number} [offset] The offset from which to start fetching records.
     * @param {number} [limit] The limit of returned records count, max 500 and default 50.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodsApi
     */
    public getWorkloadPods(workloadId: string, deleted?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return PodsApiFp(this.configuration).getWorkloadPods(workloadId, deleted, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync workloads pods.
     * @param {PodsSyncRequest} [podsSyncRequest] The pods to sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodsApi
     */
    public syncWorkloadsPods(podsSyncRequest?: PodsSyncRequest, options?: AxiosRequestConfig) {
        return PodsApiFp(this.configuration).syncWorkloadsPods(podsSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert a single pod info from the cluster.
     * @param {string} podId The requested pod id.
     * @param {PodSyncFields} podSyncFields The pod info in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodsApi
     */
    public upsertPod(podId: string, podSyncFields: PodSyncFields, options?: AxiosRequestConfig) {
        return PodsApiFp(this.configuration).upsertPod(podId, podSyncFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkloadsApi - axios parameter creator
 * @export
 */
export const WorkloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Count workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countWorkloads: async (deleted?: boolean, filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workloads/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the workload as is does not exist in the cluster anymore.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkload: async (workloadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('deleteWorkload', 'workloadId', workloadId)
            const localVarPath = `/api/v1/workloads/{workloadId}`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the workload data. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkload: async (workloadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('getWorkload', 'workloadId', workloadId)
            const localVarPath = `/api/v1/workloads/{workloadId}`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the workload ancestors.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadAncestors: async (workloadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('getWorkloadAncestors', 'workloadId', workloadId)
            const localVarPath = `/internal/workloads/{workloadId}/ancestors`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {'asc' | 'desc'} [sortOrder] Sort results by a descending or ascending order.
         * @param {'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloads: async (deleted?: boolean, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt', filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a workload by the control plane.
         * @param {WorkloadCreationFields} workloadCreationFields The workload info in the control plane.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateWorkload: async (workloadCreationFields: WorkloadCreationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadCreationFields' is not null or undefined
            assertParamExists('internalCreateWorkload', 'workloadCreationFields', workloadCreationFields)
            const localVarPath = `/internal/workloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadCreationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync workloads.
         * @param {WorkloadsSyncRequest} [workloadsSyncRequest] The workloads to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkloads: async (workloadsSyncRequest?: WorkloadsSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/workloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadsSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert the workload info from the cluster.
         * @param {string} workloadId The requested workload id.
         * @param {WorkloadSyncFields} workloadSyncFields The workload info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertWorkload: async (workloadId: string, workloadSyncFields: WorkloadSyncFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('upsertWorkload', 'workloadId', workloadId)
            // verify required parameter 'workloadSyncFields' is not null or undefined
            assertParamExists('upsertWorkload', 'workloadSyncFields', workloadSyncFields)
            const localVarPath = `/api/v1/workloads/{workloadId}`
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloadSyncFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadsApi - functional programming interface
 * @export
 */
export const WorkloadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Count workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countWorkloads(deleted?: boolean, filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountWorkloads200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countWorkloads(deleted, filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the workload as is does not exist in the cluster anymore.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkload(workloadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkload(workloadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the workload data. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkload(workloadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkload(workloadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the workload ancestors.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloadAncestors(workloadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloadAncestors(workloadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {'asc' | 'desc'} [sortOrder] Sort results by a descending or ascending order.
         * @param {'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloads(deleted?: boolean, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt', filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkloads200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloads(deleted, offset, limit, sortOrder, sortBy, filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a workload by the control plane.
         * @param {WorkloadCreationFields} workloadCreationFields The workload info in the control plane.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateWorkload(workloadCreationFields: WorkloadCreationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateWorkload(workloadCreationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync workloads.
         * @param {WorkloadsSyncRequest} [workloadsSyncRequest] The workloads to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWorkloads(workloadsSyncRequest?: WorkloadsSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWorkloads(workloadsSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert the workload info from the cluster.
         * @param {string} workloadId The requested workload id.
         * @param {WorkloadSyncFields} workloadSyncFields The workload info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertWorkload(workloadId: string, workloadSyncFields: WorkloadSyncFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertWorkload(workloadId, workloadSyncFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkloadsApi - factory interface
 * @export
 */
export const WorkloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadsApiFp(configuration)
    return {
        /**
         * 
         * @summary Count workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countWorkloads(deleted?: boolean, filterBy?: Array<string>, options?: any): AxiosPromise<CountWorkloads200Response> {
            return localVarFp.countWorkloads(deleted, filterBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the workload as is does not exist in the cluster anymore.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkload(workloadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkload(workloadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the workload data. [Experimental]
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkload(workloadId: string, options?: any): AxiosPromise<Workload> {
            return localVarFp.getWorkload(workloadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the workload ancestors.
         * @param {string} workloadId The requested workload id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadAncestors(workloadId: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getWorkloadAncestors(workloadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List workloads. [Experimental]
         * @param {boolean} [deleted] if true, return only deleted resources.
         * @param {number} [offset] The offset from which to start fetching records.
         * @param {number} [limit] The limit of returned records count, max 500 and default 50.
         * @param {'asc' | 'desc'} [sortOrder] Sort results by a descending or ascending order.
         * @param {'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt'} [sortBy] Sort results by a parameters.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloads(deleted?: boolean, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt', filterBy?: Array<string>, options?: any): AxiosPromise<GetWorkloads200Response> {
            return localVarFp.getWorkloads(deleted, offset, limit, sortOrder, sortBy, filterBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a workload by the control plane.
         * @param {WorkloadCreationFields} workloadCreationFields The workload info in the control plane.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateWorkload(workloadCreationFields: WorkloadCreationFields, options?: any): AxiosPromise<Workload> {
            return localVarFp.internalCreateWorkload(workloadCreationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync workloads.
         * @param {WorkloadsSyncRequest} [workloadsSyncRequest] The workloads to sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkloads(workloadsSyncRequest?: WorkloadsSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncWorkloads(workloadsSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert the workload info from the cluster.
         * @param {string} workloadId The requested workload id.
         * @param {WorkloadSyncFields} workloadSyncFields The workload info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertWorkload(workloadId: string, workloadSyncFields: WorkloadSyncFields, options?: any): AxiosPromise<void> {
            return localVarFp.upsertWorkload(workloadId, workloadSyncFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkloadsApi - object-oriented interface
 * @export
 * @class WorkloadsApi
 * @extends {BaseAPI}
 */
export class WorkloadsApi extends BaseAPI {
    /**
     * 
     * @summary Count workloads. [Experimental]
     * @param {boolean} [deleted] if true, return only deleted resources.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public countWorkloads(deleted?: boolean, filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).countWorkloads(deleted, filterBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the workload as is does not exist in the cluster anymore.
     * @param {string} workloadId The requested workload id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public deleteWorkload(workloadId: string, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).deleteWorkload(workloadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the workload data. [Experimental]
     * @param {string} workloadId The requested workload id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public getWorkload(workloadId: string, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).getWorkload(workloadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the workload ancestors.
     * @param {string} workloadId The requested workload id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public getWorkloadAncestors(workloadId: string, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).getWorkloadAncestors(workloadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List workloads. [Experimental]
     * @param {boolean} [deleted] if true, return only deleted resources.
     * @param {number} [offset] The offset from which to start fetching records.
     * @param {number} [limit] The limit of returned records count, max 500 and default 50.
     * @param {'asc' | 'desc'} [sortOrder] Sort results by a descending or ascending order.
     * @param {'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt'} [sortBy] Sort results by a parameters.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public getWorkloads(deleted?: boolean, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'type' | 'name' | 'clusterId' | 'projectId' | 'projectName' | 'departmentId' | 'departmentName' | 'createdAt' | 'deletedAt' | 'submittedBy' | 'phase' | 'completedAt', filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).getWorkloads(deleted, offset, limit, sortOrder, sortBy, filterBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a workload by the control plane.
     * @param {WorkloadCreationFields} workloadCreationFields The workload info in the control plane.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public internalCreateWorkload(workloadCreationFields: WorkloadCreationFields, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).internalCreateWorkload(workloadCreationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync workloads.
     * @param {WorkloadsSyncRequest} [workloadsSyncRequest] The workloads to sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public syncWorkloads(workloadsSyncRequest?: WorkloadsSyncRequest, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).syncWorkloads(workloadsSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert the workload info from the cluster.
     * @param {string} workloadId The requested workload id.
     * @param {WorkloadSyncFields} workloadSyncFields The workload info in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadsApi
     */
    public upsertWorkload(workloadId: string, workloadSyncFields: WorkloadSyncFields, options?: AxiosRequestConfig) {
        return WorkloadsApiFp(this.configuration).upsertWorkload(workloadId, workloadSyncFields, options).then((request) => request(this.axios, this.basePath));
    }
}


