import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord } from "vue-router";

export enum LICENSE_ROUTE_NAMES {
  LICENSE_INDEX = "license-index",
}
export const licenseRoutes: RunaiRouteRecord = {
  path: "/licenses",
  // route level code-splitting
  // this generates a separate chunk (node.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import("@/views/license/license-app.vue"),
  children: [
    {
      path: "",
      name: LICENSE_ROUTE_NAMES.LICENSE_INDEX,
      component: () => import("@/views/license/license-index.vue"),
      meta: {
        pageTitle: "Licenses",
        fullWidth: true,
        supportBack: true,
        requiresAuth: true,
        resourceType: ResourceType.Tenant,
        minPermittedActions: [Action.Read],
      },
    },
  ],
};
