import { ResourceType, Action } from "@/swagger-models/authorization-client";

import ClusterConnecting from "@/views/trial/cluster-connecting.vue";
import ClusterConnected from "@/views/trial/cluster-connected.vue";
import TrialIndex from "@/views/trial/trial-index.vue";
import { TRIAL_NAMES } from "./trial.routes.names";
import type { RunaiRouteRecord } from "vue-router";

export const trialRoutes: RunaiRouteRecord = {
  path: "/trial",
  component: () => import("@/views/trial/trial-app.vue"),
  children: [
    {
      path: "",
      name: TRIAL_NAMES.TRIAL,
      component: TrialIndex,
      meta: {
        pageTitle: "Trial",
        fullWidth: true,
        fullPage: true,
        requiresAuth: false,
        resourceType: null,
        minPermittedActions: null,
      },
    },
    {
      path: "cluster/connecting",
      name: TRIAL_NAMES.CLUSTER_CONNECTING,
      component: ClusterConnecting,
      meta: {
        pageTitle: "Cluster connecting",
        fullWidth: true,
        fullPage: true,
        resourceType: ResourceType.Cluster,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
      },
    },
    {
      path: "cluster/connected",
      name: TRIAL_NAMES.CLUSTER_CONNECTED,
      component: ClusterConnected,
      meta: {
        pageTitle: "Cluster connected",
        fullWidth: true,
        fullPage: true,
        resourceType: ResourceType.Cluster,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
      },
    },
  ],
};
