<template>
  <q-td
    :class="{ 'long-text': isLongText | showTooltipAlways }"
    :style="{ maxWidth: maxWidth + 'px', width: maxWidth + 'px', cursor: 'pointer' }"
    :aid="column.name"
  >
    <q-tooltip v-if="isLongText"><span v-html="formattedText"></span></q-tooltip>
    {{ text }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// models
import type { ITableColumn } from "@/models/table.model";

// services
import { getFieldContent } from "@/utils/table-format.util";

export default defineComponent({
  props: {
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    isLongText: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    maxWidth: {
      type: Number as PropType<number>,
      default: 800,
    },
    showTooltipAlways: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    richText: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  computed: {
    text(): string {
      return getFieldContent(this.column, this.row);
    },
    formattedText() {
      // replace new lines with <br> and add bullet points
      return this.richText ? this.text.replace(/\n/g, "<br>&emsp;&#8226;&emsp;") : this.text;
    },
  },
});
</script>
<style scoped lang="scss">
.long-text {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
</style>
