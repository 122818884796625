import { CHART_COLOR_PALETTE, chartColorPaletteArray } from "@/components/dashboard/chart.model";
import type { IChartSeries, IColumnChartSeries, IDrilldownSeries } from "@/components/dashboard/chart.model";
import type { BreadcrumbOptions, DrilldownEventObject, Options, SeriesOptionsType } from "highcharts";
import type { IChartArrayItemConfig } from "@/models/metrics.model";
import { formatNumberWithFixedDecimals } from "@/utils/common.util";

export const dashboardService = {
  getColumnAndLineChartOption,
  getStackChartOptions,
  getMultiBarChartOptions,
};

function getStackChartOptions(
  title: string,
  yAxisTitle: string,
  tooltipValueTitle: string,
  tooltipSumTitle: string,
  data: Array<IChartSeries>,
  drilldownData: Array<IDrilldownSeries>,
  drilldownLevelsNames: string[],
  showLegend = true,
): Options {
  return {
    tooltip: {
      backgroundColor: "#46465C",
      style: {
        color: "#F0F0F0",
        fontSize: "12px",
      },
      borderColor: "#46465C",
      shape: "square",
      headerFormat: "",
      pointFormatter: function () {
        // @ts-ignore workaround for highcharts typescript
        // The z allow to add another value to the tooltip additional to the y value
        const sum = formatNumberWithFixedDecimals(this.stackTotal, 1) | 0;
        const formattedY = formatNumberWithFixedDecimals(this.y ? this.y : 0, 1);
        return `<span style="color:${this.color}">●</span> ${this.series.name}<br><span>&nbsp;&nbsp;&nbsp;${tooltipValueTitle}: <b>${formattedY}</b></span><br><span>&nbsp;&nbsp;&nbsp;${tooltipSumTitle}: <b>${sum}</b></span> `;
      },
    },
    legend: { enabled: showLegend },
    chart: {
      events: {
        drilldown: function (drillDownEvent: DrilldownEventObject) {
          if (drillDownEvent.seriesOptions?.id?.split("-")[0] === drilldownLevelsNames[2].toLowerCase()) {
            // make it clear where is the user in the drilldown levels
            this.xAxis[0].setTitle({ text: drillDownEvent.seriesOptions?.id?.split("-")[0] });
          } else {
            this.xAxis[0].setTitle({ text: "" });
          }
        },
        drillup: function () {
          this.xAxis[0].setTitle({ text: "" });
        },
      },
      type: "column",
      height: 300,
      style: {
        fontFamily: "Roboto",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      align: "left",
      margin: 36,
      x: 6,
      y: 16,
      style: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: yAxisTitle ? yAxisTitle : "   ",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            // @ts-ignore workaround for highcharts typescript
            return this.y === 0 ? "" : formatNumberWithFixedDecimals(this.y);
          },
        },
      },
    },
    colors: chartColorPaletteArray,
    series: data as SeriesOptionsType[],
    drilldown: {
      breadcrumbs: {
        showFullPath: true,
        buttonTheme: {
          style: {
            color: "#0654fe",
          },
        },
        formatter: (breadcrumbOptions: BreadcrumbOptions) => {
          if (drilldownLevelsNames) {
            switch (breadcrumbOptions.level) {
              case 0:
                // @ts-ignore
                return breadcrumbOptions.levelOptions?.data[0].name;
              case 1:
                return drilldownLevelsNames[1];
              case 2:
                return breadcrumbOptions?.levelOptions?.name;
            }
          } else {
            return breadcrumbOptions?.levelOptions?.name;
          }
        },
      },
      allowPointDrilldown: false,
      series: drilldownData,
      activeAxisLabelStyle: {
        color: "#0654fe",
        fontWeight: "500",
      },
      activeDataLabelStyle: {
        color: "#0654fe",
        fontWeight: "500",
      },
    },
  };
}
function getColumnAndLineChartOption(
  title: string,
  categories: string[],
  yAxisTitle: string,
  columnSeries: IColumnChartSeries,
  splineSeries: IColumnChartSeries,
): Options {
  return {
    chart: {
      type: "column",
      height: 254,
      style: {
        fontFamily: "Roboto",
      },
    },
    title: {
      text: title,
      align: "left",
      margin: 30,
      style: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        categories: categories,
      },
    ],
    yAxis: [
      {
        title: {
          text: yAxisTitle,
        },
      },
    ],
    tooltip: {
      backgroundColor: "#46465C",
      style: {
        color: "#F0F0F0",
        fontSize: "12px",
      },
      borderColor: "#46465C",
      shape: "square",
      headerFormat: "",
      shared: true,
      pointFormatter: function () {
        // @ts-ignore workaround for highcharts typescript
        return `<span style="color:${this.color}">●</span> ${
          this.series.name === columnSeries?.title ? columnSeries?.tooltipTitle : splineSeries.tooltipTitle
        }<span>:&nbsp;&nbsp;<b>${this.y}</b><br>`;
      },
      footerFormat: "</table>",
      useHTML: true,
    },
    series: [
      {
        name: columnSeries?.title,
        type: "column",
        data: columnSeries?.data || [],
        color: CHART_COLOR_PALETTE.MIDNIGHT_INDIGO,
      },
      {
        name: splineSeries?.title,
        type: "spline",
        data: splineSeries?.data || [],
        color: CHART_COLOR_PALETTE.ELECTRIC_SKY_BLUE,
      },
    ],
  };
}

function getMultiBarChartOptions(
  title: string,
  yAxixTitle: string,
  tooltipValueTitle: string,
  tooltipPostfix: string,
  tooltipSumTitle: string,
  extraToolTipTitle: string,
  data: Array<IChartArrayItemConfig>,
  drilldownData: Array<IChartArrayItemConfig>,
  drilldownLevelsNames: string[],
  showLegend = true,
): Options {
  return {
    chart: {
      type: "column",
      height: 300,
      style: {
        fontFamily: "Roboto",
      },
    },
    legend: { enabled: showLegend },
    title: {
      text: title,
      align: "left",
      margin: 36,
      x: 6,
      y: 20,
      style: {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxixTitle,
      },
      labels: {
        formatter: function () {
          return this.value + "%"; // Append the percentage symbol to the label
        },
      },
    },
    tooltip: {
      backgroundColor: "#46465C",
      style: {
        color: "#F0F0F0",
        fontSize: "12px",
      },
      borderColor: "#46465C",
      shape: "square",
      headerFormat: "",
      pointFormatter: function () {
        // @ts-ignore workaround for highcharts typescript
        const extraValue1 = this.optionValues ? formatNumberWithFixedDecimals(this.optionValues[0]) : 0;
        // @ts-ignore workaround for highcharts typescript
        const extraValue2 = this.optionValues ? formatNumberWithFixedDecimals(this.optionValues[1]) : 0;
        const formattedY = formatNumberWithFixedDecimals(this.y ? this.y : 0, 1);
        return `<span style="color:${this.color}">●</span> ${this.series.name}<br><span>&nbsp;&nbsp;&nbsp;${tooltipValueTitle}: <b>${formattedY}${tooltipPostfix}</b></span><br><span>&nbsp;&nbsp;&nbsp;${tooltipSumTitle}: <b>${extraValue1}</b></span><br><span>&nbsp;&nbsp;&nbsp;${extraToolTipTitle}: <b>${extraValue2}</b></span> `;
      },
      footerFormat: "</table>",
      useHTML: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    colors: chartColorPaletteArray,
    series: data,
    drilldown: {
      breadcrumbs: {
        buttonTheme: {
          style: {
            color: "#0654fe",
          },
        },
        showFullPath: true,
        formatter: (breadcrumbOptions: BreadcrumbOptions) => {
          if (drilldownLevelsNames) {
            switch (breadcrumbOptions.level) {
              case 0:
                // @ts-ignore
                return breadcrumbOptions.levelOptions?.data[0].name;
              case 1:
                return drilldownLevelsNames[1];
              case 2:
                return breadcrumbOptions?.levelOptions?.name;
            }
          } else {
            return breadcrumbOptions?.levelOptions?.name;
          }
        },
      },
      allowPointDrilldown: false,
      series: drilldownData,
      activeAxisLabelStyle: {
        color: "#0654fe",
        fontWeight: "500",
      },
      activeDataLabelStyle: {
        color: "#0654fe",
        fontWeight: "500",
      },
    },
  };
}
