import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import type { Job } from "@/swagger-models/backend-client";
import type { DisplayedJob } from "@/swagger-models/assets-service-client";
import { EWorkloadStatus } from "@/common/status.constant";
import { httpResponseService } from "@/services/infra/http-response.service/http-response.service";
import { K8S_API } from "@/common/api.constant";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { Pod } from "@/models/pods.model";

export const oldWorkloadService = {
  stop,
  activate,
  remove,
  getWorkloadMetrics,
  getWorkloadPods,
};

async function stop(workload: DisplayedJob): Promise<void> {
  if (!workload?.project) throw new Error("Can't stop workload. Job is missing.");
  const res = await researcherService.stopWorkload(workload.jobName as string, workload.project);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}
async function activate(workload: DisplayedJob): Promise<void> {
  if (!workload?.project) throw new Error("Can't active workspace. Job is missing.");
  const res = await researcherService.activateWorkload(workload.jobName as string, workload.project);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}

async function remove(workload: DisplayedJob): Promise<DisplayedJob> {
  if (!workload?.project) throw new Error("Can't delete workspace. Job is missing.");
  const res = await researcherService.deleteJob(workload.jobName as string, workload.project);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }

  workload.status = EWorkloadStatus.Deleting;
  return workload;
}

async function getWorkloadMetrics(
  workload: DisplayedJob,
  start: number,
  end: number,
  numberOfSamples: number,
): Promise<Job> {
  return await controlPlaneService.get(
    `${K8S_API.v1}/clusters/${workload.clusterId}/jobs/${workload.podGroupId}/metrics?start=${start}&end=${end}&numberOfSamples=${numberOfSamples}`,
  );
}

async function getWorkloadPods(workload: DisplayedJob): Promise<Pod[]> {
  return controlPlaneService.get(
    `${K8S_API.v1}/clusters/${workload.clusterId}/jobs/${workload.latestPod?.podGroupId}/pods`,
  );
}
