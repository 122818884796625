import type { AssetMeta } from "@/swagger-models/assets-service-client";

export const assetsUtil = {
  filterById,
};

interface IAssetMeta {
  meta: AssetMeta;
}

function filterById<T extends IAssetMeta>(list: T[], entityToFilter: T | null): T[] {
  if (!entityToFilter) return list;
  return list.filter((asset: T) => asset.meta.id !== entityToFilter.meta.id);
}
