import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";

export const ssoService = {
  getSsoMappers,
  configureSamlSso,
  configureOAuthSso,
  updateSsoMappers,
};

async function getSsoMappers(identityProvider: string): Promise<Record<string, string>> {
  return controlPlaneService.get(`v1/k8s/auth/sso/idps/${identityProvider}/mappers`);
}
// TODO: Add generated types
function configureSamlSso(samlConfig: object): Promise<object> {
  return controlPlaneService.post(`v1/k8s/auth/config-sso/saml`, samlConfig);
}

// TODO: Add generated types
function configureOAuthSso(oauthConfig: object): Promise<object> {
  return controlPlaneService.post(`v1/k8s/auth/config-sso/oauth`, oauthConfig);
}

function updateSsoMappers(identityProvider: string, mappingConfig: Record<string, string | undefined>) {
  return controlPlaneService.put(`v1/k8s/auth/sso/idps/${identityProvider}/mappers`, mappingConfig);
}
