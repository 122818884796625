<template>
  <q-banner class="notification-alert-bar q-px-lg q-py-xs" :class="backgroundColor" inline-actions>
    <div class="notification-alert-bar-details">
      <q-icon
        v-if="level === ENotificationBarStatusLevel.WARNING"
        name="fa-regular fa-triangle-exclamation"
        :color="color"
        class="icon"
      />
      <q-icon v-else-if="level === ENotificationBarStatusLevel.INFO" name="fa-regular fa-circle-info" :color="color" />
      <runai-html-wrapper tag="span" class="notification-alert-bar-message q-ml-xs" :html="message" />
      <slot name="inline-actions" />
    </div>
  </q-banner>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiHtmlWrapper } from "@/components/common/runai-html-wrapper";
import { ENotificationBarStatusLevel } from "@/models/app.model";

const levels = {
  warning: {
    background: "alert-warning",
    color: "warning",
  },
  info: {
    background: "alert-info",
    color: "info",
  },
};

export default defineComponent({
  components: {
    RunaiHtmlWrapper,
  },
  props: {
    level: {
      type: String as PropType<ENotificationBarStatusLevel>,
      required: true,
    },
    message: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    ENotificationBarStatusLevel() {
      return ENotificationBarStatusLevel;
    },
    backgroundColor(): string {
      return levels[this.level].background;
    },
    color(): string {
      return levels[this.level].color;
    },
  },
});
</script>

<style lang="scss" scoped>
.notification-alert-bar {
  min-height: 50px;
  color: $grey-8;

  .notification-alert-bar-details {
    font-size: 13px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .icon {
    font-size: 16px;
  }
}
</style>
