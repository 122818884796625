import type { IResourcePermissions } from "@/models/resource.model";
import { ECustomCell, type IStatusColOptions, type ITableColumn } from "./table.model";
import { dateUtil } from "@/utils/date.util";
import { clusterUtil } from "@/utils/cluster.util/";
import { tableUtil } from "@/utils/table.util";
import type { ClusterDependenciesStatus } from "@/swagger-models/backend-client";
import type { ClusterDisplayedStatus, DisplayedCluster } from "@/swagger-models/cluster-service-client";
import { isDateString } from "@/utils/date.util/date.util";
import { parseCamelCaseAndCapitalize } from "@/utils/string.util/string.util";
import { ClusterDisplayedStatusStateEnum } from "@/swagger-models/cluster-service-client";

export enum EClusterStatusTooltip {
  WaitingToConnect = "Once the Run:ai cluster has been installed, it will appear as connected." +
    "<br> If the cluster was already installed and isn't connected," +
    "<br> try the following troubleshooting options:",
  Connected = "The Run:ai cluster is connected, and all Run:ai services are running.",
  Disconnected = "There's no communication from the Run:ai cluster." +
    "<br> First make sure the cluster and its network are working." +
    "<br> If so, try the following Run:ai troubleshooting options:",
  MissingPrerequisites = "The following prerequisites are missing from the cluster: {{missing_prerequisit}}." +
    "<br>As a result, some features may be impacted." +
    `<br> For more information see the <a href="https://docs.run.ai/v2.13/admin/runai-setup/cluster-setup/cluster-prerequisites/" style="text-decoration: underline;" target="_blank">Prerequisites guide</a>`,
  ServiceIssues = "Some Run:ai service(s) in the cluster are not functioning properly. <br>" +
    "As a result, some features may be impacted." +
    `<br> See the list of <a href="javascript:void(0)" style="text-decoration: underline;" id="status-col-emit-services">nonfunctioning services</a> and try the following troubleshooting options:`,
  Unknown = "The cluster status couldn't be determined. If this issue persists, <br>",
}

export const adminWarningStatuses: ClusterDisplayedStatusStateEnum[] = [
  ClusterDisplayedStatusStateEnum.Disconnected,
  ClusterDisplayedStatusStateEnum.MissingPrerequisites,
  ClusterDisplayedStatusStateEnum.ServiceIssues,
];

export const nonAdminWarningStatuses: ClusterDisplayedStatusStateEnum[] = [
  ClusterDisplayedStatusStateEnum.Disconnected,
  ClusterDisplayedStatusStateEnum.MissingPrerequisites,
];
export interface ICluster {
  updatedAt: string;
  lastLiveness: string;
  createdAt: string;
  description: string | null;
  domain: string;
  name: string;
  tenantId: number;
  uuid: string;
  version: string;
  status: ClusterDisplayedStatus | undefined;
  dependencies: ClusterDependenciesStatus;
  connected: boolean;
}

export interface IK8sDistributionCardData {
  id: string;
  title: string;
  description: string;
  icon: string;
  value: string;
}

export enum EClusterVersions {
  v2_9 = "2.9",
}
export interface IClusterProject {
  id: string;
  name: string;
  managedNamespace: string;
  createdAt: Date;
  deservedGpus?: number;
  clusterUuid: string;
  permissions?: IResourcePermissions;
  interactiveJobTimeLimitSecs?: number;
  interactiveJobMaxIdleDurationSecs?: number;
  interactivePreemptibleJobMaxIdleDurationSecs?: number;
  trainingJobMaxIdleDurationSecs?: number;
  trainNodeAffinity?: string | string[];
  interactiveNodeAffinity?: string | string[];
  departmentName?: string;
}

export const allClusterColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Cluster",
    field: (row: DisplayedCluster) => row.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (row: DisplayedCluster) => {
      return row.status;
    },
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    customCellEvent: { emitName: "tooltip-click" },
    format: (status: ClusterDisplayedStatus): IStatusColOptions => {
      return clusterUtil.getStatusColOptions(status);
    },
    sort: (fieldA, fieldB, rowA, rowB) => {
      return tableUtil.customColumnSort(allClusterColumnsMap.status, rowA, rowB, (status: IStatusColOptions) => {
        return status.status;
      });
    },
    exportFormat: (row: DisplayedCluster): string =>
      parseCamelCaseAndCapitalize(row.status?.state as ClusterDisplayedStatusStateEnum) || "NA",
    filterKey: "status",
  },
  lastConnected: {
    name: "lastConnected",
    label: "Last connected",
    field: (row: DisplayedCluster) => {
      return row.status?.state === ClusterDisplayedStatusStateEnum.Connected
        ? "Now"
        : row.lastLiveness
        ? row.lastLiveness
        : "Never";
    },
    sortable: true,
    align: "left",
    format: (lastConnected: string) =>
      isDateString(lastConnected) ? dateUtil.dateAndTimeFormat(new Date(lastConnected)) : lastConnected,
  },
  uuid: {
    name: "uuid",
    label: "Run:ai cluster UUID",
    field: (row: DisplayedCluster) => row.uuid,
    sortable: true,
    align: "left",
  },
  createdAt: {
    name: "created-at",
    label: "Creation time",
    field: (row: DisplayedCluster) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
  },
  url: {
    name: "url",
    label: "URL",
    field: (row: DisplayedCluster) => row.domain,
    sortable: true,
    align: "left",
  },
  version: {
    name: "version",
    label: "Run:ai cluster version",
    field: (row: DisplayedCluster) => row.version,
    sortable: true,
    align: "left",
  },
};

export const allClusterColumns: Array<ITableColumn> = [
  allClusterColumnsMap.name,
  allClusterColumnsMap.status,
  allClusterColumnsMap.uuid,
  allClusterColumnsMap.createdAt,
  allClusterColumnsMap.url,
  allClusterColumnsMap.version,
  allClusterColumnsMap.lastConnected,
];

export const clusterIndexColumns: Array<ITableColumn> = [
  { ...allClusterColumnsMap.name, display: true, mandatory: true },
  { ...allClusterColumnsMap.status, display: true },
  { ...allClusterColumnsMap.lastConnected, display: true },
  { ...allClusterColumnsMap.createdAt, display: true },
  { ...allClusterColumnsMap.url, display: true },
  { ...allClusterColumnsMap.version, display: true },
  { ...allClusterColumnsMap.uuid, display: false },
];

export enum EClusterDistIds {
  VANILLA = "vanilla",
  OPEN_SHIFT = "openshift",
  EKS = "eks",
  AKS = "aks",
  GKE = "gke",
  RKE = "rke",
}
export enum EClusterDistVals {
  VANILLA = "op",
  OPEN_SHIFT = "openshift",
  EKS = "aws",
  AKS = "aks",
  GKE = "gcp",
  RKE = "op",
}
export enum EClusterDistLabels {
  VANILLA = "Vanilla Kubernetes",
  OPEN_SHIFT = "OpenShift",
  EKS = "EKS",
  AKS = "AKS",
  GKE = "GKE",
  RKE = "RKE",
}

export const clusterK8SDistributionOptions: IK8sDistributionCardData[] = [
  {
    id: EClusterDistIds.VANILLA,
    title: EClusterDistLabels.VANILLA,
    description: "k8s native installation",
    icon: "vanillak8s",
    value: EClusterDistVals.VANILLA,
  },
  {
    id: EClusterDistIds.OPEN_SHIFT,
    title: EClusterDistLabels.OPEN_SHIFT,
    description: "OpenShift Container Platform",
    icon: "openshift",
    value: EClusterDistVals.OPEN_SHIFT,
  },
  {
    id: EClusterDistIds.EKS,
    title: EClusterDistLabels.EKS,
    description: "Amazon Elastic Kubernetes Service",
    icon: "eks",
    value: EClusterDistVals.EKS,
  },
  {
    id: EClusterDistIds.AKS,
    title: EClusterDistLabels.AKS,
    description: "Azure Kubernetes Services",
    icon: "aks",
    value: EClusterDistVals.AKS,
  },
  {
    id: EClusterDistIds.GKE,
    title: EClusterDistLabels.GKE,
    description: "Google Kubernetes Engine",
    icon: "gke",
    value: EClusterDistVals.GKE,
  },
  {
    id: EClusterDistIds.RKE,
    title: EClusterDistLabels.RKE,
    description: "Rancher Kubernetes Engine",
    icon: "rke",
    value: EClusterDistVals.RKE,
  },
];

export type TClusterVersions = "2.7" | "2.8" | "2.9" | "2.10" | "2.11" | "2.12" | "2.13" | "2.14" | "2.15";
export const clusterNewInstallerThreshold = "2.15";
export const TEST_ENV_VERSION = "-master";
export const TRAINING_DISTRIBUTED_MIN_VERSION = "2.13";

export enum EClusterLocations {
  CONTROL_PLANE = "controlPlane",
  REMOTE = "remote",
}

export interface IPrerequisites {
  location: string;
  clusterUrl: string;
  k8sDistribution: string;
}
export interface IClusterInstalltionModel {
  name: string;
  version: TClusterVersions | null;
  prerequisites: IPrerequisites;
}

export enum EClusterErrorMessage {
  NotConnected = "Your cluster is not connected",
}
