import type {
  IComputeSectionNodePoolsPolicy,
  IComputeSectionPolicy,
} from "@/components/section/compute-resource-section";

import type { Connection } from "@/swagger-models/workloads-service-client";

import type {
  TrainingPolicy,
  TrainingPolicyDefaults,
  TrainingPolicyDefaultsAndRules,
  TrainingPolicyRules,
  WorkspacePolicy,
  WorkspacePolicyDefaults,
  WorkspacePolicyDefaultsAndRules,
  WorkspacePolicyRules,
} from "@/swagger-models/policy-service-client";

import {
  HistoryRecordType,
  Phase,
  Source,
  type HistoryRecord,
  type Workload,
} from "@/swagger-models/workloads-service-client";
import type {
  Annotation,
  AssetIdAndKind,
  AssetsIds,
  AssetsRef,
  DatasourceRef,
  EnvironmentVariable,
  InfoDistributed,
  Label,
  SpecificRunConnectionInfo,
  SpecificRunParams,
  Training,
  WorkloadCreationRequest,
  Workspace,
  DisplayedJob,
  ToolType,
} from "@/swagger-models/assets-service-client";
import type {
  EWorkloadName,
  IUIDistributedMaster,
  IUIWorkloadAssets,
  IUIWorkloadCreation,
  IUIWorkloadSpecificEnv,
  IWorkloadMeta,
} from "@/models/workload.model";
import { EWorkloadAction, EWorkloadEntity, WorkloadPhaseMap, type WorkloadServicePod } from "@/models/workload.model";
import type { IUIVolume } from "@/models/data-source.model";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import { deepCopy, fallbackDefaultIfNullOrUndefined, isEqual, omit, pick } from "@/utils/common.util";
import { tableUtil } from "@/utils/table.util";
import type { IStatusColOptions } from "@/models/table.model";
import { allWorkloadsIconsMap } from "@/common/icons.constant";
import type { IToolItem } from "@/models/workspace.model";
import { UI_WORKSPACE_STATUS } from "@/common/status.constant";

export const workloadUtil = {
  getComputeResourceSectionPolicy,
  getEmptyUIWorkloadCreation,
  getUIWorkloadSpecificEnv,
  getUIWorkloadAssets,
  getWorkloadAssetsFromUIWorkloadAssets,
  getWorkloadSpecificEnvFromUIWorkloadSpecificEnv,
  getWorkloadCreationRequest,
  convertWorkloadToWorkloadUI,
  getDisabledToolTipText,
  getConnectivityMessage,
  getMissingDomainMessage,
  getWorkloadStatusColOptions,
  getPodStatusColOptions,
  isWorkload,
  convertDisplayedJobToWorkload,
  isWorkloadSourceControlPlane,
  isPhaseUpdateRecordType,
  getWorkloadIcon,
  convertConnectionsToToolItems,
  getWorkspaceTrainingStatusColOptions,
  getRunningVsRequestedDisplayedValue,
  isTrainingWorkload,
};
function isTrainingWorkload(workload: Workload): boolean {
  return workload.type === "Training";
}
function getComputeResourceSectionPolicy(
  workloadPolicy: WorkspacePolicy | TrainingPolicy,
  workloadName: EWorkloadName,
): IComputeSectionPolicy | null {
  if (!workloadPolicy.effective) return null;

  const policy: WorkspacePolicyDefaultsAndRules | TrainingPolicyDefaultsAndRules = workloadPolicy.effective;
  const nodePoolsPolicy: IComputeSectionNodePoolsPolicy = {};

  if (policy.rules) {
    const rules: WorkspacePolicyRules & TrainingPolicyRules = policy.rules;
    const workloadRulesType: keyof typeof rules = workloadName;
    const workloadRules = rules[workloadRulesType];
    if (workloadRules?.nodePools) {
      nodePoolsPolicy.rules = workloadRules.nodePools;
    }
  }

  if (policy.defaults) {
    const policyDefaults: WorkspacePolicyDefaults & TrainingPolicyDefaults = policy.defaults;

    const workloadDefaultsType: keyof typeof policyDefaults = workloadName;
    const workloadDefaults = policyDefaults[workloadDefaultsType];

    if (workloadDefaults?.nodePools) {
      nodePoolsPolicy.defaults = workloadDefaults.nodePools;
    }
  }

  return {
    nodePools: nodePoolsPolicy,
  };
}

function getEmptyUIWorkloadCreation(): IUIWorkloadCreation {
  return {
    name: "",
    projectId: -1,
    namespace: "",
    clusterId: "",
    assets: {
      environment: "",
      compute: "",
    },
    specificEnv: {},
  };
}

function getUIWorkloadAssets(assets: AssetsIds | undefined, uiVolumes?: Array<IUIVolume>): IUIWorkloadAssets {
  return {
    environment: assets?.environment || null,
    compute: assets?.compute || null,
    datasources: assets?.datasources,
    uiVolumes,
  };
}

function getUIWorkloadAssetsRef(assets: AssetsRef | undefined, uiVolumes?: Array<IUIVolume>): IUIWorkloadAssets {
  return {
    environment: assets?.environment?.id || null,
    compute: assets?.compute?.id || null,
    datasources: assets?.datasources?.map((dataSource: DatasourceRef) => ({ id: dataSource.id, kind: dataSource.kind })),
    uiVolumes,
  };
}

function getUIWorkloadSpecificEnv(specificEnv: SpecificRunParams | undefined = {}): IUIWorkloadSpecificEnv {
  return {
    args: specificEnv.args,
    command: specificEnv.command,
    runAsUid: specificEnv.runAsUid || null,
    runAsGid: specificEnv.runAsGid || null,
    supplementalGroups: specificEnv.supplementalGroups,
    nodeType: specificEnv.nodeType || null,
    allowOverQuota: specificEnv.allowOverQuota || false,
    nodePools: specificEnv.nodePools ? specificEnv.nodePools : null,
    autoDeletionTimeAfterCompletionSeconds: fallbackDefaultIfNullOrUndefined(
      specificEnv.autoDeletionTimeAfterCompletionSeconds,
      null,
    ),
    connections: specificEnv.connections,
    environmentVariables: specificEnv.environmentVariables?.map((env: EnvironmentVariable) => ({
      ...env,
      locked: false,
    })),
    annotations: specificEnv.annotations?.map((ann: Annotation) => ({
      ...ann,
      locked: false,
    })),
    labels: specificEnv.labels?.map((lbl: Label) => ({ ...lbl, locked: false })),
  };
}

function getWorkloadAssetsFromUIWorkloadAssets(assets: IUIWorkloadAssets, workloadVolumes?: Array<string>): AssetsIds {
  if (!assets.environment) throw new Error("assets.environment is required");

  const retAssets: AssetsIds = {
    environment: assets.environment,
    compute: assets.compute,
  };

  if (assets.datasources) {
    retAssets.datasources = assets.datasources;
  }

  if (workloadVolumes) {
    retAssets.workloadVolumes = workloadVolumes;
  }

  return retAssets;
}

function getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(specificEnv: IUIWorkloadSpecificEnv): SpecificRunParams {
  const retSpecificEnv: SpecificRunParams = {
    ...omit(specificEnv, ["environmentVariables", "annotations", "labels"]),
  };

  retSpecificEnv.args ||= null;
  retSpecificEnv.command ||= null;
  retSpecificEnv.runAsUid = retSpecificEnv.runAsUid ? +retSpecificEnv.runAsUid : undefined;
  retSpecificEnv.runAsGid = retSpecificEnv.runAsGid ? +retSpecificEnv.runAsGid : undefined;
  retSpecificEnv.supplementalGroups = Array.isArray(retSpecificEnv.supplementalGroups)
    ? retSpecificEnv.supplementalGroups.join(",")
    : undefined;

  if (specificEnv.environmentVariables) {
    retSpecificEnv.environmentVariables = specificEnv.environmentVariables.map((env: IItemizedListItem) =>
      pick(env, "name", "value", "deleted"),
    );
  }

  if (specificEnv.annotations) {
    retSpecificEnv.annotations = specificEnv.annotations.map((env: IItemizedListItem) =>
      pick(env, "name", "value", "deleted"),
    );
  }

  if (specificEnv.labels) {
    retSpecificEnv.labels = specificEnv.labels.map((env: IItemizedListItem) => pick(env, "name", "value", "deleted"));
  }

  return retSpecificEnv;
}

function getWorkloadCreationRequest(
  workload: IUIWorkloadCreation,
  workloadVolumes?: Array<string>,
  masterWorkloadVolumes?: Array<string>,
): WorkloadCreationRequest {
  const workloadCreationRequest: WorkloadCreationRequest = {
    name: workload.name,
    namespace: workload.namespace,
    clusterId: workload.clusterId,
    projectId: workload.projectId,
    assets: getWorkloadAssetsFromUIWorkloadAssets(workload.assets, workloadVolumes),
    specificEnv: getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(workload.specificEnv),
  };

  if (workload.distributed) {
    workloadCreationRequest.distributed = {
      ...workload.distributed,
      master: null,
    };

    if (!workload.distributed.noMaster) {
      workloadCreationRequest.distributed.master = {
        specificEnv: getWorkloadSpecificEnvFromUIWorkloadSpecificEnv(workload.distributed.master?.specificEnv || {}),
      };

      if (workload.distributed.master?.assets && workloadCreationRequest.distributed.master) {
        workloadCreationRequest.distributed.master.assets = getWorkloadAssetsFromUIWorkloadAssets(
          workload.distributed.master.assets,
          masterWorkloadVolumes,
        );
      }
    }
  }

  return workloadCreationRequest;
}

function convertWorkloadToWorkloadUI(workload: Workspace | Training, uiVolumes?: Array<IUIVolume>): IUIWorkloadCreation {
  const workloadMeta: IWorkloadMeta = {
    name: workload.meta.name,
    projectId: workload.meta.projectId,
    namespace: "",
    clusterId: workload.meta.clusterId,
  };

  const dataSources: AssetIdAndKind[] =
    workload.spec.assets.datasources?.map(
      (ds: DatasourceRef): AssetIdAndKind => ({
        id: ds.id,
        kind: ds.kind,
      }),
    ) || [];

  const assets = {
    environment: workload.spec.assets.environment.id,
    compute: workload.spec.assets.compute?.id || null,
    datasources: dataSources,
  };

  // For copied workload we need to reset the connection
  const specificEnv: SpecificRunParams = workload.spec.specificEnv ? deepCopy(workload.spec.specificEnv) : {};
  if (specificEnv.connections) {
    specificEnv.connections = _emptyConnectionsCustomInfo(specificEnv.connections);
  }

  let enableEditingMaster: boolean | undefined = undefined;
  if (workload.spec.distributed && !workload.spec.distributed.noMaster) {
    enableEditingMaster =
      !isEqual(workload.spec.distributed.master?.assets, workload.spec.assets) ||
      !isEqual(workload.spec.distributed.master?.specificEnv, workload.spec.specificEnv);
  }

  return _getWorkloadUI(workloadMeta, assets, specificEnv, workload.spec.distributed, uiVolumes, enableEditingMaster);
}

function _getWorkloadUI(
  meta: IWorkloadMeta,
  assets: AssetsIds,
  specificEnv: SpecificRunParams = {},
  distributed?: InfoDistributed | null,
  uiVolumes?: Array<IUIVolume>,
  enableEditingMaster?: boolean | undefined,
): IUIWorkloadCreation {
  const workloadUI: IUIWorkloadCreation = {
    name: meta.name,
    namespace: meta.namespace,
    clusterId: meta.clusterId,
    projectId: meta.projectId,
    assets: getUIWorkloadAssets(assets, uiVolumes),
    specificEnv: getUIWorkloadSpecificEnv(specificEnv),
  };

  if (distributed) {
    const master: IUIDistributedMaster | null = distributed.noMaster
      ? null
      : {
          assets: getUIWorkloadAssetsRef(distributed.master?.assets),
          specificEnv: getUIWorkloadSpecificEnv(distributed.master?.specificEnv || {}),
        };

    workloadUI.distributed = {
      ...distributed,
      master,
    };

    workloadUI.enableEditingMaster = enableEditingMaster;
  }

  return workloadUI;
}

function _emptyConnectionsCustomInfo(connections: Array<SpecificRunConnectionInfo>): Array<SpecificRunConnectionInfo> {
  return connections.map((connection: SpecificRunConnectionInfo) => {
    if (connection.nodePort) {
      return {
        ...connection,
        nodePort: null,
      };
    } else if (connection.externalUrl) {
      return {
        ...connection,
        externalUrl: null,
      };
    }
    return connection;
  });
}

function getDisabledToolTipText(action: EWorkloadAction, entity: EWorkloadEntity): string {
  switch (action) {
    case EWorkloadAction.activate:
      return `The selected ${entity} is already active or in the process of being activated`;
    case EWorkloadAction.run:
      return `The selected ${entity} is already running or being prepared to run`;
    case EWorkloadAction.stop:
      return `The selected ${entity} is already stopped or in the process of being stopped`;
    case EWorkloadAction.connect:
      return `To connect, the selected ${entity} must first be running`;
    default:
      return "Action for the selected workload is not authorized";
  }
}

function getConnectivityMessage(errorCode?: number): string {
  const unauthorizedProblem = "The API server is not configured correctly. Contact your administrator"; // when error code is 401
  const connectivityProblem = (errorCode?: number): string =>
    errorCode
      ? `There are issues with your connection to the cluster. Make sure you're using your organization's VPN, or contact your administrator (error code: ${errorCode})` // when error code is 403, 404 or 50X
      : "There are issues with your connection to the cluster. Contact your administrator.";
  switch (errorCode) {
    case 401:
      return unauthorizedProblem;
    case -1:
      return connectivityProblem();
    default:
      return connectivityProblem(errorCode);
  }
}

function getMissingDomainMessage(): string {
  return "The cluster domain is not defined. Contact your administrator.";
}

function getWorkspaceTrainingStatusColOptions(job: DisplayedJob, tooltipText = ""): IStatusColOptions {
  const statusOptions = UI_WORKSPACE_STATUS[job?.status || ""];
  if (job?.msSinceLastStatusUpdate) {
    statusOptions.statusUpdatedTimeInMs = Date.now() - job?.msSinceLastStatusUpdate;
  }
  return tableUtil.getStatusColOptions(statusOptions, tooltipText);
}

//new workloads table
function getWorkloadStatusColOptions(workload: Workload, phaseMessage?: string): IStatusColOptions {
  const statusOptions: IStatusColOptions = WorkloadPhaseMap[workload.phase];
  statusOptions.statusUpdatedTimeInMs = new Date(workload.phaseUpdatedAt).getTime();
  return tableUtil.getStatusColOptions(statusOptions, phaseMessage);
}
function getPodStatusColOptions(pod: WorkloadServicePod): IStatusColOptions {
  const statusOptions: IStatusColOptions = tableUtil.getStatusColOptions(WorkloadPhaseMap[pod.k8sPhase as Phase]);
  statusOptions.statusUpdatedTimeInMs = new Date(pod.k8sPhaseUpdatedAt).getTime();
  if (statusOptions.status === "-" && pod.k8sPhase) {
    statusOptions.status = pod.k8sPhase;
  }
  return statusOptions;
}

function isWorkloadSourceControlPlane(workload: Workload): boolean {
  return workload.source === Source.ControlPlane;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isWorkload(workload: any): workload is Workload {
  return workload.name !== undefined;
}

function isPhaseUpdateRecordType(historyRecord: HistoryRecord): boolean {
  return historyRecord.meta.type === HistoryRecordType.PhaseUpdate;
}

function convertDisplayedJobToWorkload(displayedJob: DisplayedJob): Workload {
  return {
    source: Source.ControlPlane,
    conditions: [{ status: displayedJob.status || "", type: "", lastTransitionTime: null }],
    priorityClassName: "",
    type: displayedJob.jobType || "",
    name: displayedJob.jobName || "",
    id: "",
    clusterId: displayedJob.clusterId || "",
    projectName: displayedJob.project || "",
    projectId: "",
    namespace: "",
    createdAt: displayedJob.creationTime || "",
    phase: Phase.Creating,
    k8sPhase: "",
    tenantId: 0,
    runningPods: 0,
    phaseUpdatedAt: "",
    k8sPhaseUpdatedAt: "",
    updatedAt: "",
    deletedAt: null,
    submittedBy: displayedJob.user,
  };
}

function getWorkloadIcon(workload: Workload): string {
  if (workload.distributedFramework && allWorkloadsIconsMap[workload.distributedFramework]) {
    return allWorkloadsIconsMap[workload.distributedFramework];
  }

  if (workload.environments?.length && allWorkloadsIconsMap[workload.environments[0].connections[0].toolType]) {
    return allWorkloadsIconsMap[workload.environments[0].connections[0].toolType];
  }

  return allWorkloadsIconsMap[workload.type] || allWorkloadsIconsMap.Unknown;
}

function convertConnectionsToToolItems(connections: Connection[]): IToolItem[] {
  return connections.map((connection: Connection) => ({
    toolType: connection.toolType as ToolType,
    toolName: connection.name,
    url: connection.url || "",
  }));
}

function getRunningVsRequestedDisplayedValue(workload: Workload): string {
  if (workload.requestedPods?.number) {
    return `${workload.runningPods}/${workload.requestedPods.number}`;
  } else if (workload.requestedPods?.min && workload.requestedPods.max) {
    return `${workload.runningPods}/${workload.requestedPods.min}-${workload.requestedPods.max}`;
  }
  return "-";
}
