import { ResourceType, Action } from "@/swagger-models/authorization-client";

import DeploymentIndex from "@/views/deployment/deployment-index.vue";
import DeploymentNew from "@/views/deployment/deployment-new.vue";
import DeploymentHistory from "@/views/deployment/deployment-history.vue";
import type { RunaiRouteRecord } from "vue-router";
import { DEPLOYMENT_ROUTE_NAMES } from "./deployment.routes.names";

export const deploymentRoutes: RunaiRouteRecord = {
  path: "/deployments",
  component: () => import("@/views/deployment/deployment-app.vue"),
  children: [
    {
      path: "",
      name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_INDEX,
      component: DeploymentIndex,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
      },
    },
    {
      path: "new",
      name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_NEW,
      component: DeploymentNew,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Create],
        requiresAuth: true,
        hideVersion: true,
      },
    },
    {
      path: "history",
      name: DEPLOYMENT_ROUTE_NAMES.DEPLOYMENT_HISTORY,
      component: DeploymentHistory,
      meta: {
        pageTitle: "Deployments",
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
        requiresAuth: true,
        hideVersion: true,
      },
    },
  ],
};
