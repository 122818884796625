<template>
  <q-td class="audit-cluter-name-col">
    {{ clusterName }}
  </q-td>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { useClusterStore } from "@/stores/cluster.store";
import type { IAuditEvent } from "@/models/audit.model";
import type { ITableColumn } from "@/models/table.model";
import { getFieldContent } from "@/utils/table-format.util";
import type { ICluster } from "@/models/cluster.model";

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<IAuditEvent>,
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  computed: {
    clusterUuid(): string {
      return getFieldContent(this.column, this.row);
    },
    clusterName(): string {
      if (!this.clusterUuid) {
        return "-";
      }
      const cluster = useClusterStore().clusters.find((cluster: ICluster) => cluster.uuid === this.clusterUuid);
      return cluster ? cluster.name : "-";
    },
  },
});
</script>
<style lang="scss" scoped></style>
