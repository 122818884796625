<template>
  <section class="workspace-index">
    <runai-table-wrapper :filters-object="filterBy" sticky>
      <template v-slot:actions>
        <runai-page-actions
          :primary-btn-disable="disablePrimaryBtn"
          :primary-tooltip-text="primaryTooltipText"
          primary-btn-label="new workspace"
          :columns="columnsOptions"
          :filters="filterBy"
          @filters-changed="updateFilterBy"
          @create-entity="createWorkspace"
          :selected-rows-amount="selectedRowsAmount"
          @selected-actions-close="resetSelectedRows"
          show-details-btn
          empty-message="Select a workspace to view its details"
          @drawer-toggled="isDrawerOpen = $event"
          @drawer-resized="drawerWidth = $event"
          @export-csv="exportTableAsCsv"
        >
          <template v-slot:selected-rows-actions>
            <div class="row items-center justify-start">
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && (isInitialLoader || !canActivated)"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.activate)"
              >
                <runai-action-button
                  aid="activate-workspace-btn"
                  btn-action="activate"
                  @click="activateWorkspace"
                  :disable="!isClusterConnected || isInitialLoader || !canActivated"
                />
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && (isInitialLoader || !canStop)"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.stop)"
              >
                <runai-action-button
                  aid="stop-workspace-btn"
                  btn-action="stop"
                  @click="stopWorkspace"
                  :disable="!isClusterConnected || isInitialLoader || !canStop"
                />
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canConnect"
                :tooltip-text="getDisabledToolTipText(EWorkloadAction.connect)"
              >
                <runai-action-button
                  btn-action="connect"
                  :disable="!isClusterConnected || !canConnect"
                  aid="workspace-index-connect-btn"
                >
                  <tools-select @value-changed="onOpenTool" :tool-items="toolItems"></tools-select>
                </runai-action-button>
              </runai-tooltip-wrapper>

              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canCreateWorkspace"
                :tooltip-text="noActionPermissionText"
              >
                <runai-action-button
                  btn-action="copy"
                  @click="cloneWorkspace"
                  :disable="!isClusterConnected || !canCreateWorkspace"
                />
              </runai-tooltip-wrapper>
              <runai-tooltip-wrapper
                :display-tooltip="isClusterConnected && !canDeleteWorkspace"
                :tooltip-text="noActionPermissionText"
              >
                <runai-action-button
                  :disable="!isClusterConnected || !canDeleteWorkspace"
                  :tooltip-text="noActionPermissionText"
                  aid="delete-workspace-btn"
                  btn-action="delete"
                  @click="openDeleteModal"
                />
              </runai-tooltip-wrapper>
            </div>
          </template>
          <template v-slot:drawer-content>
            <workload-drawer-content entity-type="workspace" :drawer-width="drawerWidth" :workload="currentWorkspace" />
          </template>
        </runai-page-actions>
      </template>
      <template v-slot:table>
        <runai-table
          :style="{ marginRight: tableMargin }"
          :rows="workspaces"
          :columns="columnsOptions"
          :loading="isInitialLoader && loading"
          :filter-by="filterBy"
          v-model:selected="selectedRows"
          @data-source-clicked="displayDataSources"
          @update-filters="updateFilterBy"
          :top-row="lastCreatedWorkspace"
          sticky-columns
          :get-row-icon="getRowIcon"
          :get-row-key="getRowKey"
        >
          <template #no-data>
            <runai-table-no-data
              v-if="!isInitialLoader && !lastCreatedWorkspace"
              :filter-by="filterBy"
              :show-error="loadingError"
              :show-create-btn="!disablePrimaryBtn"
              entity-name="workspace"
              icon-name="workspace"
              @clear-filters="clearFilters"
              @create-new="createWorkspace"
            >
              <template #secondary-text v-if="noExistingProjects">
                <runai-table-no-projects
                  entity-type="workspace"
                  :has-entity-create-permission="canCreateWorkspace"
                  :has-project-create-permission="canCreateProject"
                />
              </template>
            </runai-table-no-data>
          </template>
        </runai-table>
      </template>
    </runai-table-wrapper>

    <data-source-list-modal
      v-if="openDataSourceModal"
      @close="closeDataSourceModal"
      :modal-options="dataSourceModalOptions"
    ></data-source-list-modal>

    <runai-delete-modal
      v-if="isDeleteModalOpen && selectedWorkspaces.length >= 1"
      entity-type="workspace"
      :custom-title="`Permanently delete workspace ${selectedWorkspaces[0].meta.name} and its logs?`"
      :entity-name="selectedWorkspaces[0].meta.name"
      @cancel="isDeleteModalOpen = false"
      @delete="deleteWorkspace"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Services
import { filterService } from "@/services/filter.service/filter.service";
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";

// utils
import { alertUtil } from "@/utils/alert.util";
import { tableUtil } from "@/utils/table.util";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { connectionAlertUtil } from "@/utils/connection-alert.util";

// Stores
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkspaceStore } from "@/stores/workspace.store";
import { useAppStore } from "@/stores/app.store";
import { useProjectStore } from "@/stores/project.store";
import { useSettingStore } from "@/stores/setting.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useNodePoolStore } from "@/stores/node-pool.store";

// Constants
import { EWorkloadStatus } from "@/common/status.constant";
import { toolTypeIconsMap } from "@/common/icons.constant";

// models
import {
  allWorkspaceColumnsMap,
  type IWorkspaceFilterBy,
  type IWorkspaceList,
  workspaceIndexColumns,
  type IToolItem,
} from "@/models/workspace.model";
import type { IProject } from "@/models/project.model";
import type { ITableColumn } from "@/models/table.model";
import { ETableExportCsvFilesNames, ETableFilters } from "@/models/table.model";
import type { IFilterBy } from "@/models/filter.model";
import { DataSourceListModal } from "@/components/data-source/data-source-list-modal";
import type { AssetIdAndKind } from "@/swagger-models/assets-service-client";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { HttpErrorResponse } from "@/models/http-response.model";
import { EWorkloadAction, EWorkloadEntity } from "@/models/workload.model";
import type { IReadinessStatus } from "@/models/researcher.model";

// routes
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";

//components
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import { RunaiActionButton } from "@/components/common/runai-page-actions/runai-action-button";
import { ToolsSelect } from "@/components/tools";
import { RunaiTable, RunaiTableNoProjects } from "@/components/common/runai-table";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { WorkloadDrawerContent } from "@/components/old-workload/workload-drawer-content.vue";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { RunaiDeleteModal } from "@/components/common/runai-delete-modal";
import type { IDataSourceModalOptions } from "@/models/data-source.model";

export default defineComponent({
  components: {
    RunaiTableWrapper,
    ToolsSelect,
    RunaiPageActions,
    RunaiActionButton,
    RunaiTable,
    RunaiTableNoData,
    DataSourceListModal,
    WorkloadDrawerContent,
    RunaiTooltipWrapper,
    RunaiDeleteModal,
    RunaiTableNoProjects,
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      projectStore: useProjectStore(),
      clusterStore: useClusterStore(),
      workspaceStore: useWorkspaceStore(),
      settingStore: useSettingStore(),
      nodePoolStore: useNodePoolStore(),
      appStore: useAppStore(),
      departmentStore: useDepartmentStore(),
      loading: false as boolean,
      selectedRows: [] as Array<IWorkspaceList>,
      isDeleteModalOpen: false as boolean,
      refreshInterval: 5000 as number,
      currentTimeoutId: 0 as number,
      openDataSourceModal: false as boolean,
      dataSourceModalOptions: { dataSourceIds: [], header: "" } as IDataSourceModalOptions,
      loadingError: false as boolean,
      isDrawerOpen: false as boolean,
      hideNodePoolsColumn: false as boolean,
      drawerWidth: "0px" as string,
      isInitialLoader: true as boolean,
      columns: workspaceIndexColumns as Array<ITableColumn>,
      filterBy: {} as IWorkspaceFilterBy,
      isClusterConnected: true as boolean,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.checkConnectivityStatus();
    this.loadFilters();
    this.loadProjects();
    this.loadWorkspaces();
    try {
      this.nodePoolStore.loadNodePoolsCount();
    } catch (e) {
      console.error(e);
      this.hideNodePoolsColumn = true;
    }
    this.currentTimeoutId = window.setInterval(this.loadWorkspaces, this.refreshInterval);
  },
  computed: {
    EWorkloadAction(): typeof EWorkloadAction {
      return EWorkloadAction;
    },
    columnsOptions(): ITableColumn[] {
      if (this.nodePoolStore.isOnlyDefaultNodePool || this.hideNodePoolsColumn)
        return this.columns.filter((col) => col.name !== "nodePool");
      return this.columns;
    },
    tableMargin(): string {
      return this.isDrawerOpen ? this.drawerWidth : "0px";
    },
    noExistingProjects(): boolean {
      return this.projectStore.projects.length === 0;
    },
    workspaces(): Array<IWorkspaceList> {
      return this.workspaceStore.workspaceList;
    },
    selectedWorkspaces(): IWorkspaceList[] {
      return this.selectedRows;
    },
    selectedRowsAmount(): number {
      return this.selectedWorkspaces.length;
    },
    canActivated(): boolean {
      if (!this.canUpdateWorkspace) return false;
      return this.selectedWorkspaces.length === 1 && EWorkloadStatus.Stopped === this.currentWorkspace?.job?.status;
    },
    canStop(): boolean {
      if (!this.canUpdateWorkspace) return false;
      return (
        this.selectedWorkspaces.length === 1 &&
        [EWorkloadStatus.Active, EWorkloadStatus.Pending].some(
          (status: EWorkloadStatus) => status === this.currentWorkspace?.job?.status,
        )
      );
    },
    canDeleteWorkspace(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Workspaces, Action.Delete);
    },
    canConnect(): boolean {
      if (!this.canUpdateWorkspace) return false;
      return (
        this.currentWorkspace?.job?.status === EWorkloadStatus.Active && !!this.currentWorkspace.connections?.length
      );
    },
    currentWorkspace(): IWorkspaceList {
      return this.selectedWorkspaces[0];
    },
    canCreateProject(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Project, Action.Create);
    },
    canCreateWorkspace(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Workspaces, Action.Create);
    },
    canUpdateWorkspace(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Workspaces, Action.Update);
    },
    primaryTooltipText(): string {
      if (!this.isClusterConnected) {
        return "";
      } else if (this.canCreateProject) {
        return "To create a new workspace, First create at least one project";
      } else if (this.canCreateWorkspace) {
        return "Workspaces can only be created under a project.\nContact your administrator to add a project.";
      } else {
        return "";
      }
    },
    noActionPermissionText(): string {
      return "Action for the selected workspace is not authorized";
    },
    lastCreatedWorkspace(): IWorkspaceList | null {
      return this.workspaceStore.lastCreatedWorkspace;
    },
    findSelectedInTable(): IWorkspaceList | undefined {
      if (!this.currentWorkspace) return undefined;

      const selectedRowId: string = this.currentWorkspace.meta.id;
      if (this.lastCreatedWorkspace?.meta.id === selectedRowId) {
        return this.lastCreatedWorkspace;
      }

      return this.workspaces.find((workspace: IWorkspaceList) => workspace.meta.id === selectedRowId);
    },
    disablePrimaryBtn(): boolean {
      return this.noExistingProjects || !this.canCreateWorkspace || !this.isClusterConnected;
    },
    toolItems(): IToolItem[] {
      if (!this.selectedRows[0]?.connections) return [];
      return this.selectedRows[0]?.connections?.map((c: IToolItem) => {
        const authorizedUsers: string[] | null =
          this.selectedRows[0]?.spec.specificEnv?.connections?.find((conn) => conn.name === c.toolName)
            ?.authorizedUsers || null;
        if (!authorizedUsers) return c;
        return {
          ...c,
          authorizedUsers,
        };
      });
    },
  },
  methods: {
    async checkConnectivityStatus(): Promise<void> {
      if (!this.clusterStore.currentCluster.domain) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getMissingDomainMessage()));
        this.isClusterConnected = false;
        return;
      }

      const res: IReadinessStatus = await whoamiService.isCurrentClusterConnected();
      if (!res.connected) {
        this.$q.notify(connectionAlertUtil.getAlert(workloadUtil.getConnectivityMessage(res.statusCode)));
        this.isClusterConnected = false;
      }
    },
    getRowIcon(workspace: IWorkspaceList): string {
      if (!workspace.connections?.length) return "";
      const toolType: string = workspace.connections[0].toolType;
      return toolTypeIconsMap[toolType] || "";
    },
    getRowKey(workspace: IWorkspaceList): string {
      return workspace.meta.id;
    },
    getDisabledToolTipText(action: EWorkloadAction): string {
      if (!this.canUpdateWorkspace) return this.noActionPermissionText;
      return workloadUtil.getDisabledToolTipText(action, EWorkloadEntity.workspace);
    },
    async loadProjects(): Promise<void> {
      await this.projectStore.loadProjects();
    },
    async loadWorkspaces(): Promise<void> {
      try {
        this.loading = true;
        await this.workspaceStore.loadWorkspaces(this.filterBy);
        if (this.lastCreatedWorkspace) this.loadLastCreatedWorkspace();
      } catch (e: unknown) {
        console.error(e);
        this.$q.notify(alertUtil.getError(`Failed to load workspaces`));
        this.loadingError = true;
      } finally {
        this.isInitialLoader = false;
        this.loading = false;
      }
    },
    async loadLastCreatedWorkspace(): Promise<void> {
      if (this.lastCreatedWorkspace) {
        try {
          await this.workspaceStore.loadLastCreatedWorkspace(
            this.clusterStore.currentCluster.uuid,
            this.lastCreatedWorkspace.meta.id,
          );
        } catch (error: unknown) {
          // Handling the case that the last created workspace was deleted. We don't want to try to load it again.
          this.workspaceStore.removeWorkspaceAdded();
        }
      }
    },
    cloneWorkspace(): void {
      this.$router.push({
        name: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
        query: { fromCopyId: this.selectedRows[0].meta.id },
      });
    },
    updateFilterBy(filterBy: IFilterBy, keyChanged: null | string = null, forceLoad = true): void {
      this.filterBy = filterBy;
      filterService.saveFilters(ETableFilters.WORKSPACE, filterBy);
      if (!forceLoad || keyChanged === "displayedColumns") return;

      this.isInitialLoader = true;
      this.workspaceStore.removeWorkspaceAdded();
      this.loadWorkspaces();
    },
    clearFilters(): void {
      this.updateFilterBy(
        {
          ...this.filterBy,
          columnFilters: [],
          searchTerm: "",
        },
        null,
        true,
      );
    },
    createWorkspace(): void {
      this.$router.push({ name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW });
    },
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(allWorkspaceColumnsMap.name.name, this.columns);

      const searchParamsFilters: IFilterBy = filterService.loadFilters(
        window.location,
        ETableFilters.WORKSPACE,
        defaultFilters,
      );
      this.updateFilterBy(searchParamsFilters, null, false);
    },
    resetSelectedRows(): void {
      this.selectedRows = [];
    },
    onOpenTool(url: string): void {
      window.open(url.startsWith("http") ? url : "http://" + url);
    },
    async activateWorkspace(): Promise<void> {
      this.loading = true;
      try {
        await this.workspaceStore.activateWorkspace(this.selectedWorkspaces[0]);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to start workspace", error as string);
        }
      } finally {
        await this.workspaceStore.loadWorkspaces(this.filterBy);
        this.loading = false;
        this.resetSelectedRows();
      }
    },
    async stopWorkspace(): Promise<void> {
      this.loading = true;
      try {
        await this.workspaceStore.stopWorkspace(this.selectedWorkspaces[0]);
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to stop workspace", error as string);
        }
      } finally {
        await this.workspaceStore.loadWorkspaces(this.filterBy);
        this.loading = false;
        this.resetSelectedRows();
      }
    },
    getProjectName(workspace: IWorkspaceList): string | undefined {
      const project: IProject | undefined = this.projectStore.projectList.find(
        (project: IProject) => project.id === workspace.meta.projectId,
      );
      return project ? project.name : "";
    },
    async deleteWorkspace(): Promise<void> {
      const workspace: IWorkspaceList | undefined = this.selectedWorkspaces[0];
      if (!workspace) return;

      try {
        this.loading = true;
        if (!workspace.job?.project) {
          const projectName: string | undefined = this.getProjectName(workspace);
          workspace.job = {
            ...(workspace.job || {}),
            project: projectName || "",
          };
        }

        await this.workspaceStore.deleteWorkspace(workspace);
        if (this.lastCreatedWorkspace?.meta.id === workspace.meta.id) {
          this.workspaceStore.removeWorkspaceAdded();
        }
        this.$q.notify(alertUtil.getSuccess(`Workspace ${workspace.meta.name} deleted`));
      } catch (error: unknown) {
        if (error instanceof HttpErrorResponse) {
          console.error(error.serialize());
          this.$q.notify(alertUtil.getError(error.message));
        } else {
          console.error(error);
          this.notifyMsgWithError("Failed to delete workspace", error as string);
        }
      } finally {
        this.isDeleteModalOpen = false;
        this.startRefreshWorkspaces();
        this.resetSelectedRows();
        this.loading = false;
      }
    },
    getErrorMsgIfAccessDenied(baseMsg: string, error: string): string {
      const errStr = error.toString().toLowerCase();
      if (errStr.includes("access denied") || errStr.includes("not allowed")) {
        return `${baseMsg}: Access Denied`;
      }
      return baseMsg;
    },
    notifyMsgWithError(baseMsg: string, error: string): void {
      let errorStr = this.getErrorMsgIfAccessDenied(baseMsg, error);
      this.$q.notify(alertUtil.getError(errorStr));
    },
    openDeleteModal(): void {
      this.stopRefreshWorkspaces();
      this.isDeleteModalOpen = true;
    },
    async stopRefreshWorkspaces(): Promise<void> {
      window.clearTimeout(this.currentTimeoutId);
    },
    async startRefreshWorkspaces(): Promise<void> {
      this.currentTimeoutId = window.setInterval(this.loadWorkspaces, this.refreshInterval);
    },
    displayDataSources(workspace: IWorkspaceList): void {
      if (!workspace.spec.assets.datasources) return;

      const datasources: Array<AssetIdAndKind> = workspace.spec.assets.datasources;
      this.dataSourceModalOptions.header = `Data Sources Associated with Workspace ${workspace.meta.name}`;
      this.dataSourceModalOptions.dataSourceIds = datasources.map((dataSource: AssetIdAndKind) => dataSource.id);
      this.openDataSourceModal = Boolean(workspace);
    },
    exportTableAsCsv(): void {
      const columns = this.columns.filter((col: ITableColumn) => this.filterBy.displayedColumns?.includes(col.name));

      tableUtil.exportTableAsCsv(ETableExportCsvFilesNames.Workspace, this.workspaces, columns);
    },
    closeDataSourceModal(): void {
      this.openDataSourceModal = false;
      this.resetDataSourceModalOptions();
    },
    resetDataSourceModalOptions(): void {
      this.dataSourceModalOptions = { dataSourceIds: [], header: "" };
    },
  },
  watch: {
    findSelectedInTable(workspace: IWorkspaceList) {
      if (!workspace) return;
      this.selectedRows[0] = workspace;
    },
  },
  unmounted() {
    this.stopRefreshWorkspaces();
    this.workspaceStore.resetCreateWorkspaceData();
    this.workspaceStore.removeWorkspaceAdded();
  },
});
</script>
