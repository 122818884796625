<template>
  <runai-base-dialog model-value :title="title" @close="closeModal">
    <template #header>
      {{ title }}
    </template>
    <template #body>
      <div class="body-text">{{ msg }}</div>
      <div class="bold-text">
        <span v-if="canCreateLicense">
          Go to <span class="link" @click="goToLicenses">Licenses</span> for more information.
        </span>
        <span v-else> Please contact your administrator.</span>
      </div>
    </template>
    <template #footer>
      <q-btn label="Got it" color="primary" @click="closeModal" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// Models
import { LICENSE_ROUTE_NAMES } from "@/router/license.routes";
// Components
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["close"],
  props: {
    canCreateLicense: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isExpiredOrExceeded: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  computed: {
    title(): string {
      if (this.isExpiredOrExceeded) {
        return "License Expired";
      } else {
        return "License Exipring Soon";
      }
    },
    msg(): string {
      if (this.isExpiredOrExceeded) {
        return "Your Run:ai software license has expired or exceeded its GPU capacity. We recommend using valid licensed software.";
      } else {
        return "Your Run:ai software license is expiring soon. We recommend using valid licensed software.";
      }
    },
  },
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
    goToLicenses(): void {
      this.$router.push({ name: LICENSE_ROUTE_NAMES.LICENSE_INDEX });
      this.closeModal();
    },
  },
});
</script>

<style lang="scss" scoped>
.body-text {
  font-size: 14px;
}

.bold-text {
  font-size: 14px;
  font-weight: bold;
  .link {
    cursor: pointer;
    color: #0654fe;
  }
}
</style>
