import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION, QUOTA_MANAGEMENT_MIN_VERSION } from "@/common/version.constant";
import { SettingKeys } from "@/models/setting.model";

export interface INavItem {
  icon: string;
  text: string;
  name: string;
  aid: string;
  open?: boolean;
  hidden?: boolean;
  subLinks?: Array<INavItem>;
  resourceType?: ResourceType | ResourceType[];
  minClusterVersion?: string;
  minPermittedActions?: Action[];
  featureFlagName?: SettingKeys;
}

export const dashboardsLinks: INavItem[] = [
  {
    icon: "menu-icon-dashboards-menu",
    text: "Dashboards",
    name: "overview-index",
    aid: "menu-icon-Dashboards",
    open: false,
    subLinks: [
      {
        icon: "menu-icon-overview-dashboard",
        text: "Overview",
        name: "overview-index",
        aid: "menu-icon-overview",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
      },
      {
        icon: "menu-icon-analytics-dashboard",
        text: "Analytics",
        name: "analytics-index",
        aid: "menu-icon-analytics",
        resourceType: ResourceType.DashboardsAnalytics,
        minPermittedActions: [Action.Read],
      },
      {
        icon: "menu-icon-multi-cluster-dashboard",
        text: "Multi-Cluster Overview",
        name: "clusters-dashboard-index",
        aid: "menu-icon-Multi",
        resourceType: ResourceType.DashboardsOverview,
        minPermittedActions: [Action.Read],
      },
      {
        icon: "menu-icon-consumption-dashboard",
        text: "Consumption",
        name: "consumption-index",
        aid: "menu-icon-consumption",
        resourceType: ResourceType.DashboardsConsumption,
        minPermittedActions: [Action.Read],
        featureFlagName: SettingKeys.ConsumptionReport,
      },
      {
        icon: "menu-icon-resources",
        text: "Quota management",
        aid: "menu-icon-resources",
        name: "resources-index",
        minClusterVersion: QUOTA_MANAGEMENT_MIN_VERSION,
        resourceType: ResourceType.DashboardsConsumption,
        minPermittedActions: [Action.Read],
      },
    ],
  },
];

export const managementsLinks: INavItem[] = [
  {
    icon: "menu-icon-clusters",
    text: "Clusters",
    name: "cluster-index",
    aid: "menu-icon-clusters",
    resourceType: ResourceType.Cluster,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-nodes",
    text: "Nodes",
    name: "node-index",
    aid: "menu-icon-nodes",
    resourceType: ResourceType.Nodes,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-departments",
    text: "Departments",
    name: "department-index",
    aid: "menu-icon-departments",
    resourceType: ResourceType.Department,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-projects",
    text: "Projects",
    name: "project-index",
    aid: "menu-icon-projects",
    resourceType: ResourceType.Project,
    minPermittedActions: [Action.Read],
  },
];

export const workloadsLinks: INavItem[] = [
  {
    icon: "menu-icon-jobs",
    text: "", //depends on the current page
    name: "", //depends on the current page
    aid: "", //depends on the current page
    resourceType: ResourceType.Jobs,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-workspaces",
    text: "Workspaces",
    name: "workspace-index",
    aid: "menu-icon-workspaces",
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: ResourceType.Workspaces,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-trainings",
    text: "Trainings",
    name: "training-index",
    aid: "menu-icon-trainings",
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: ResourceType.Trainings,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-deployments",
    text: "Deployments",
    name: "deployment-index",
    aid: "menu-icon-deployments",
    resourceType: ResourceType.Deployments,
    minPermittedActions: [Action.Read],
  },
];
export const assetsLinks: INavItem[] = [
  {
    icon: "menu-icon-model-spec",
    text: "Models",
    name: "model-spec-index",
    aid: "menu-icon-model-spec",
    resourceType: ResourceType.Deployments,
    minPermittedActions: [Action.Create],
    featureFlagName: SettingKeys.EnableModelCatalog,
  },
  {
    icon: "menu-icon-environments",
    text: "Environments",
    name: "environment-index",
    aid: "menu-icon-environments",
    resourceType: ResourceType.Environments,
    minPermittedActions: [Action.Read],
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
  },
  {
    icon: "menu-icon-data-sources",
    text: "Data Sources",
    name: "data-source-index",
    aid: "menu-icon-data-sources",
    minPermittedActions: [Action.Read],
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: [
      ResourceType.NfsAssets,
      ResourceType.S3Assets,
      ResourceType.GitAssets,
      ResourceType.PvcAssets,
      ResourceType.HostPathAssets,
    ],
  },
  {
    icon: "menu-icon-compute-resource",
    text: "Compute Resources",
    name: "compute-resource-index",
    aid: "menu-icon-compute-resource",
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: ResourceType.ComputeResources,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-templates",
    text: "Templates",
    name: "workload-template-index",
    aid: "menu-icon-templates",
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: ResourceType.Templates,
    minPermittedActions: [Action.Read],
  },
  {
    icon: "menu-icon-credentials",
    text: "Credentials",
    name: "credential-index",
    aid: "menu-icon-credentials",
    minClusterVersion: ASSET_BASED_WORKLOADS_MIN_CLUSTER_VERSION,
    resourceType: ResourceType.Credentials,
    minPermittedActions: [Action.Read],
  },
];

export const eventsLinks: INavItem[] = [
  {
    icon: "menu-icon-event-history",
    text: "Event History",
    name: "audit-index",
    aid: "menu-icon-events",
    resourceType: ResourceType.EventsHistory,
    minPermittedActions: [Action.Read],
  },
];
