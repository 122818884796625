import type { ITableColumn } from "./table.model";
import { memoryFormat } from "@/utils/format.util";
import { dateUtil } from "@/utils/date.util";
import { percentFormat, tableNumberFormat } from "@/utils/table-format.util";
import type { INodeWorkload } from "@/models/node-workload.model";
import { ECustomCell } from "./table.model";

export interface INodePool {
  name: string;
  labelKey: string;
  labelValue: string;
  placementStrategy: {
    gpu: PlacementStrategy;
    cpu: PlacementStrategy;
  };
  id: number;
  clusterId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  status: string;
  statusMessage: string;
  nodes: string;
  createdBy: string;
  updatedBy: string;
  isDefault: boolean;
  projectsGpus: number | undefined;
  projectsCpus: number | undefined;
  projectsMemory: number | undefined;
  workloads?: Array<INodeWorkload>;
  overProvisioningRatio: number;
}

export interface IMetricNodePool {
  name: string;
  utilization: string;
  totalGpus: string;
  allocatedGpus: string;
  totalCpus: string;
  usedCpus: string;
  allocatedCpus: string;
  totalGpuMemory: string;
  usedGpuMemory: string;
  totalCpuMemory: string;
  usedCpuMemory: string;
  allocatedMemory: string;
  nodes: string;
}

export enum PlacementStrategy {
  Binpack = "binpack",
  Spread = "spread",
}

export const binPackLabel = "Bin-pack";
export const spreadLabel = "Spread";
export const PlacementOptions = {
  gpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each GPU and node to use fewer resources and maximize GPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many GPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
  cpu: [
    {
      label: binPackLabel,
      value: PlacementStrategy.Binpack,
      toolTip:
        "Place as many workloads as possible in each CPU and node to use fewer resources and maximize CPU and node vacancy.",
    },
    {
      label: spreadLabel,
      value: PlacementStrategy.Spread,
      toolTip:
        "Spread workloads across as many CPUs and nodes as possible to minimize the load and maximize the available resources per workload.",
    },
  ],
};

export const NodePoolStatusMap = new Map([
  [
    "Creating",
    {
      displayLabel: "Creating...",
      isPending: true,
    },
  ],
  [
    "Updating",
    {
      displayLabel: "Updating...",
      isPending: true,
    },
  ],
  [
    "Deleting",
    {
      displayLabel: "Deleting...",
      isPending: true,
    },
  ],
]);

export const NodePoolStatusMessageMap = new Map([
  [
    "Error Deleting NodePool",
    {
      newMessage: "Node pool cannot be deleted, if the issue persists contact run:ai support",
    },
  ],
  [
    "Scheduler is not ready",
    {
      newMessage: "The Run:ai scheduler is not ready, if the issue persists contact run:ai support",
    },
  ],
  [
    "Nodes unavailable",
    {
      newMessage: "Nodes are unavailable, if the issue persists contact run:ai support",
    },
  ],
]);

export const NodePoolStatusMessagePrefix = [
  {
    originalMessage: "Scheduler is not ready, reason:",
    newMessage: "The Run:ai scheduler is not ready, because of the following:",
  },
  {
    originalMessage: "Unschedulable Nodes:",
    newMessage: "The following nodes within the node pool are not ready:",
  },
  {
    originalMessage: "Nodes Being Drained:",
    newMessage:
      "Seems there are workloads on the nodes under this node pool which are not associated with this node pool. Please delete all these running/failed workloads.\nThe nodes are:",
  },
];

export const nodePoolColumns = [
  {
    name: "name",
    label: "Node pool",
    field: (row: INodePool) => row.name,
    sortable: true,
    align: "left",
    display: true,
    mandatory: true,
  },
  {
    name: "status",
    label: "Status",
    field: "status",
    sortable: true,
    align: "left",
    display: true,
    customCell: ECustomCell.NODE_POOL_STATUS_COL,
  },
  {
    name: "label-key",
    label: "Label key",
    field: "labelKey",
    sortable: true,
    align: "left",
    display: true,
    format: (key: string) => key || "-",
  },
  {
    name: "label-value",
    label: "Label value",
    field: "labelValue",
    sortable: true,
    align: "left",
    display: true,
    format: (val: string) => val || "-",
  },
  {
    name: "nodes",
    label: "Node(s)",
    field: (row: INodePool) => (Array.isArray(row.nodes) ? row.nodes : row?.nodes?.split(",")),
    sortable: false,
    align: "left",
    display: true,
    format: (nodes: Array<string>): string[] | string => {
      if (!nodes?.length) return [];
      if (nodes[0] === "") return "-";
      return nodes.map((node: string) => node.trim());
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "nodes-clicked" },
  },
  {
    name: "gpu",
    label: "GPU devices",
    field: "totalGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "gpu-memory",
    label: "GPU memory",
    field: "totalGpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "gpu-project-quota",
    label: "Projects' GPU quota",
    field: "projectsGpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: "allocated-gpu",
    label: "Allocated GPUs",
    field: "allocatedGpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "used-gpu-memory",
    label: "Used GPU memory",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "utilization",
    label: "GPU compute utilization",
    field: "utilization",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: "memoryUtilization",
    label: "GPU memory utilization",
    field: "usedGpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalGpuMemory"),
  },
  {
    name: "cpu",
    label: "CPUs (Cores)",
    field: "totalCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "cpu-memory",
    label: "CPU memory",
    field: "totalCpuMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "cpu-project-quota",
    label: "Projects' CPU quota (Cores)",
    field: "projectsCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat(),
  },
  {
    name: "memory-project-quota",
    label: "Projects' CPU memory quota",
    field: "projectsMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "allocated-cpu",
    label: "Allocated CPUs (Cores)",
    field: "allocatedCpus",
    sortable: true,
    align: "left",
    display: true,
    format: tableNumberFormat(),
  },
  {
    name: "allocated-cpu-memory",
    label: "Allocated CPU memory",
    field: "allocatedMemory",
    sortable: true,
    align: "left",
    display: true,
    format: memoryFormat,
  },
  {
    name: "used-cpu-memory",
    label: "Used CPU memory",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: memoryFormat,
  },
  {
    name: "cpu-compute-utilization",
    label: "CPU compute utilization",
    field: "usedCpus",
    sortable: true,
    align: "left",
    display: false,
    format: tableNumberFormat("%"),
  },
  {
    name: "cpu-memory-utilization",
    label: "CPU memory utilization",
    field: "usedCpuMemory",
    sortable: true,
    align: "left",
    display: false,
    format: percentFormat("totalCpuMemory"),
  },
  {
    name: "gpu-placement-strategy",
    label: "GPU placement strategy",
    field: (row) => row.placementStrategy.gpu,
    sortable: true,
    align: "left",
    display: false,
    format: (placementStrategy) => (placementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: "cpu-placement-strategy",
    label: "CPU placement strategy",
    field: (row) => row.placementStrategy.cpu,
    sortable: true,
    align: "left",
    display: false,
    format: (placementStrategy) => (placementStrategy === PlacementStrategy.Spread ? spreadLabel : binPackLabel),
  },
  {
    name: "node-pool-updated-at",
    label: "Last update",
    field: "updatedAt",
    format: (updatedAt: string) => dateUtil.dateAndTimeFormat(new Date(updatedAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: "node-pool-created-at",
    label: "Creation time",
    field: "createdAt",
    format: (createdAt: string) => dateUtil.dateAndTimeFormat(new Date(createdAt)),
    sortable: true,
    align: "left",
    display: false,
  },
  {
    name: "workloads",
    label: "Workload(s)",
    field: (row) => row.workloads,
    sortable: false,
    align: "left",
    display: false,
    format: (workloads: Array<INodeWorkload>): string[] => {
      if (!workloads?.length) return [];
      return workloads.map((workload: INodeWorkload) => workload.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
  {
    name: "over-provisioning-ratio",
    label: "Over Provisioning Ratio",
    field: "overProvisioningRatio",
    format: (overProvisioningRatio: number): string | number => {
      if (!overProvisioningRatio || overProvisioningRatio === MIN_OVER_PROVISIONING_RATIO)
        return EOverProvisioningText.NotEnforced;

      return `Enforced - Guaranteed proportion ${OVER_PROVISIONING_RATIO[overProvisioningRatio]}`;
    },
    sortable: true,
    align: "left",
    display: false,
  },
] as Array<ITableColumn>;

export enum ChartDataKeys {
  totalGpus = "totalGpus",
  gpuAllocated = "gpuAllocated",
  gpuNotAllocated = "gpuNotAllocated",
  gpuPerNodeMetricInSlicedRanges = "gpuPerNodeMetricInSlicedRanges",
  gpuMemoryUtilization = "gpuMemoryUtilization",
  gpuUtilization = "gpuUtilization",
  cpuUtilization = "cpuUtilization",
  cpuMemoryUtilization = "cpuMemoryUtilization",
}
export interface INodePoolChartSettings {
  key: string;
  title: string;
  color: string | string[];
  container: string;
  chartType: ChartType;
  names?: string[];
  totalColor?: string;
  isGpuChart?: boolean;
}
export enum ChartType {
  line = "line",
  area = "area",
}

// function to create legend for GPU utilization distribution chart
// example: GPU with 0-25% utilization, GPU with 25-50% utilization, etc.
function createLegendUtilizationArray(min: number, max: number, step: number): string[] {
  return Array.from(
    { length: (max - min) / step },
    (_, i) => `GPU with ${min + i * step}-${min + (i + 1) * step}% utilization`,
  );
}

export const chartSettings: INodePoolChartSettings[] = [
  {
    key: ChartDataKeys.gpuPerNodeMetricInSlicedRanges,
    title: "GPU utilization distribution",
    color: ["#9B19F5", "#FFA300", "#E6D800", "#00BFA0"],
    container: "container-gpu-utilization-range",
    chartType: ChartType.area,
    names: createLegendUtilizationArray(0, 100, 25).reverse(),
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuAllocated,
    title: "Node GPU allocation",
    color: ["#9B19F5", "#00BFA0"],
    container: "container-gpu-allocation",
    chartType: ChartType.area,
    names: ["Allocated GPUs", "Unallocated GPUs"],
    totalColor: "#003F5C",
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuUtilization,
    title: "GPU utilization",
    color: "#9B19F5",
    container: "container-gpu-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.gpuMemoryUtilization,
    title: "GPU memory utilization",
    color: "#9B19F5",
    container: "container-gpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: true,
  },
  {
    key: ChartDataKeys.cpuUtilization,
    title: "CPU utilization",
    color: "#0BB4FF",
    container: "container-cpu-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
  {
    key: ChartDataKeys.cpuMemoryUtilization,
    title: "CPU memory utilization",
    color: "#0BB4FF",
    container: "container-cpu-memory-utilization",
    chartType: ChartType.line,
    isGpuChart: false,
  },
];

export const emptyNodePool: INodePool = {
  clusterId: "",
  createdAt: "",
  createdBy: "",
  deletedAt: "",
  id: 0,
  isDefault: false,
  labelKey: "",
  labelValue: "",
  placementStrategy: {
    gpu: PlacementStrategy.Binpack,
    cpu: PlacementStrategy.Binpack,
  },
  name: "",
  nodes: "",
  projectsCpus: undefined,
  projectsGpus: undefined,
  projectsMemory: undefined,
  status: "",
  statusMessage: "",
  updatedAt: "",
  updatedBy: "",
  workloads: undefined,
  overProvisioningRatio: 1,
};

export const nodePoolsDependentColumns = {
  placementStrategy: ["gpu-placement-strategy", "cpu-placement-strategy"],
  oldVersionColumns: [
    "name",
    "status",
    "label-key",
    "label-value",
    "gpu-project-quota",
    "cpu-project-quota",
    "memory-project-quota",
    "node-pool-updated-at",
    "node-pool-created-at",
  ],
  overProvisioningRatio: ["over-provisioning-ratio"],
};

export const MIN_OVER_PROVISIONING_RATIO = 1;
export const MAX_OVER_PROVISIONING_RATIO = 5;

export enum EOverProvisioningText {
  AsRequested = "As requested",
  NotEnforced = "Not enforced",
}
export const OVER_PROVISIONING_RATIO: { [index: string]: string } = {
  [MIN_OVER_PROVISIONING_RATIO]: EOverProvisioningText.AsRequested,
  2: "0.5",
  3: "0.33",
  4: "0.25",
  [MAX_OVER_PROVISIONING_RATIO]: "0.2",
};
