import type { IK8sDistributionCardData } from "@/models/cluster.model";
import type { IProjectResources } from "@/models/project.model";
import type {
  ComputeAsset,
  DatasourceListResponseEntry,
  EnvironmentAsset,
  WorkloadTemplate,
} from "@/swagger-models/assets-service-client";
export interface ICreateNewCard {
  meta: {
    id: string;
  };
}

export enum TCardCmpName {
  CREATE_NEW = "create-new-card",
  TEMPLATE = "template-card",
  PROJECT = "project-card",
  ENVIRONMENT = "environment-card",
  COMPUTE_RESOURCE = "compute-resource-card",
  DATA_SOURCE = "data-source-card",
  K8S_DISTRIBUTION = "k8s-distribution-card",
}

export type TCardListCmpType =
  | IProjectResources
  | EnvironmentAsset
  | ICreateNewCard
  | WorkloadTemplate
  | ComputeAsset
  | DatasourceListResponseEntry
  | IK8sDistributionCardData;

export interface ICardListItem {
  id: string;
  cardName: TCardCmpName;
  data: TCardListCmpType;
  searchValues?: string[];
  locked?: boolean;
  disabled?: boolean;
  tooltip?: string;
  showDisabledInfo?: boolean;
  sortInfo?: {
    name?: string;
    createdAt?: string;
    recentUsage?: string | null;
  };
}
