import type { INode } from "@/models/node.model";
import { NodeConditionMap } from "@/models/node.model";
import type {
  Node,
  NodeStatusConditionDetails,
  NodeStatusConditionDetailsTypeEnum,
} from "@/swagger-models/cluster-service-client";
import type { IStatusColOptions } from "@/models/table.model";

export const nodeUtil = {
  enrichNodesWithInfoFromCluster,
  getStatusColOptions,
};

function enrichNodesWithInfoFromCluster(nodes: Array<INode>, clusterServiceNodes: Node[]): Array<INode> {
  if (clusterServiceNodes.length === 0) {
    return nodes;
  }
  return clusterServiceNodes.map((csNode: Node) => {
    const node: INode = nodes.find((node: INode) => node.node === csNode.name) || _getEmptyNode();
    return {
      ...node,
      node: csNode.name,
      nodePool: csNode.nodePool,
      gpuType: csNode?.gpuInfo?.gpuType,
      totalGpus: csNode?.gpuInfo?.gpuCount?.toString(),
      nodeStatus: csNode?.status,
      conditions: csNode?.conditions,
    };
  });
}

function getStatusColOptions(node: INode): IStatusColOptions {
  const status: IStatusColOptions = {
    status: "-",
    tooltipText: "",
    displayAnimation: false,
    filterKey: "status",
  };
  switch (node.nodeStatus) {
    case "Ready":
      status.status = "Ready";
      status.color = "success";
      break;
    case "NotReady":
      status.status = "Not Ready";
      if (node.conditions) {
        const reason = NodeConditionMap[node.conditions[0].type as NodeStatusConditionDetailsTypeEnum].reason;
        const conditionsLength = node.conditions.length;
        status.status =
          status.status + " (" + (conditionsLength > 1 ? `${reason} + ${conditionsLength - 1} more` : reason) + ")";
        status.tooltipText = getConditionsAsText(node);
      }
      break;
  }
  return status;
}

function getConditionsAsText(node: INode): string {
  let conditions = "";
  node.conditions?.forEach((condition: NodeStatusConditionDetails) => {
    conditions += NodeConditionMap[condition.type].message + "<br>";
  });
  return conditions;
}

function _getEmptyNode(): INode {
  return {
    id: "",
    node: "",
    nodeStatus: "",
    utilization: "",
    gpuType: "",
    totalGpus: "",
    allocatedGpus: "",
    usedGpus: "",
    totalCpus: "",
    allocatedCpus: "",
    usedCpus: "",
    totalGpuMemory: "",
    usedGpuMemory: "",
    totalCpuMemory: "",
    allocatedMemory: "",
    usedCpuMemory: "",
    swapCpuMemory: "",
    nodePool: "",
    workloads: [],
    gpuDevices: [],
    conditions: [],
  };
}
