<template>
  <runai-app-loader v-if="!appLoaded" />
  <main-layout v-else>
    <cluster-status-bar v-if="hasAccessToken && !pageLoading && !isFallback" />
    <runai-fallback-page v-if="isFallback" />
    <template v-else>
      <runai-default-loader v-if="pageLoading" />
      <router-view v-show="!pageLoading" :key="currentClusterUuid" aid="router-content" />
    </template>
    <section class="q-py-md q-ml-md version-info" v-if="!isFullHeight && !hideVersion && !isFullPage">
      <div>
        <div class="app-info" v-if="lastLogin">Last login: {{ lastLogin }}</div>
        <div class="app-info" v-if="appVersion">Version: {{ appVersion }}</div>
      </div>
    </section>
    <eula-contract-modal v-if="needEulaSigned" />
    <license-warning-modal
      v-if="!openedLicenseModal && shouldOpenLicenseModal"
      :can-create-license="canCreateLicense"
      :is-expired-or-exceeded="isExpiredOrExceeded"
      @close="closeLicenseModal"
    />
    <admin-message-modal v-if="adminMessage" :message="adminMessage" @close="closeAdminMessage" />
  </main-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAuthStore } from "./stores/auth.store";
import { useSettingStore } from "./stores/setting.store";
import { useClusterStore } from "./stores/cluster.store";
import { useAppStore } from "./stores/app.store";
import { usePermissionStore } from "@/stores/permissions.store";
import { useRegistryStore } from "./stores/registry.store";
import { useLicenseStore } from "./stores/license.store";
import type { ICluster } from "./models/cluster.model";

// components
import { RunaiFallbackPage } from "@/components/common/runai-fallback-page";
import { RunaiDefaultLoader } from "./components/common/runai-default-loader";
import { MainLayout } from "@/components/infra/main-layout";
import { EulaContractModal } from "./components/eula-contract-modal";
import { LicenseWarningModal } from "./components/license/license-warning-modal";
import { RunaiAppLoader } from "./components/common/runai-app-loader";
import { AdminMessageModal } from "./components/admin-message-modal";

// services
import { pendoService } from "./services/infra/pendo.service/pendo.service";
import { IdleTimer } from "@/services/infra/idle-timer.service/idle-timer.service";

// utils
import { storageUtil } from "@/utils/storage.util";
import { intervalUtil } from "@/utils/interval.util";
import { adminMessageUtil } from "@/utils/admin-message.util/admin-message.util";

// models
import { ForbiddenError, HttpErrorResponse, UnauthorizedError } from "@/models/http-response.model";
import { INTERVAL_LABELS } from "@/common/interval.constant";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes";
import { ERROR_ROUTE_NAMES } from "@/router/error.routes/error.routes.names";
import { authService } from "./services/control-plane/auth.service/auth.service";
import { TRIAL_NAMES } from "./router/trial.routes/trial.routes.names";
import { DASHBOARD_ROUTE_NAMES, LANDING_PAGE_NAME } from "@/router/dashboard.routes";
import type { IAdminMessage } from "./models/setting.model";
import { ClusterStatusBar } from "./components/cluster/cluster-status-bar";
import { Action, ResourceType } from "@/swagger-models/authorization-client";
import { STORAGE_KEYS } from "@/models/storage.model";

const FIVE_MINUTES = 5 * 60 * 1000;

export default defineComponent({
  components: {
    ClusterStatusBar,
    MainLayout,
    RunaiDefaultLoader,
    RunaiFallbackPage,
    RunaiAppLoader,
    EulaContractModal,
    LicenseWarningModal,
    AdminMessageModal,
  },
  data() {
    return {
      registryStore: useRegistryStore(),
      authStore: useAuthStore(),
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
      appStore: useAppStore(),
      permissionStore: usePermissionStore(),
      licenseStore: useLicenseStore(),
      idleTimerService: new IdleTimer() as IdleTimer,
      error: null,
      openedLicenseModal: false as boolean,
      adminMessage: null as IAdminMessage | null,
    };
  },
  async created() {
    this.appStore.setAppLoaded(false);

    try {
      // pre login
      await this.loadAppConfiguration();
    } catch (error: unknown) {
      console.error("Something went wrong during the app's initial loading. ", error);
      this.appStore.setAppLoaded(true);
      this.$router.push({ name: ERROR_ROUTE_NAMES.BAD_GATEWAY_ERROR });
      return;
    }

    this.$nextTick(() => {
      this.initApp();
    });
  },
  computed: {
    appVersion(): string {
      return this.appStore.appVersion;
    },
    lastLogin(): string {
      return this.authStore.userLastLogin;
    },
    appLoaded(): boolean {
      return this.appStore.appLoaded;
    },
    pageLoading(): boolean {
      return this.appStore.isPageLoading;
    },
    isFallback(): boolean {
      return this.appStore.isFallback;
    },
    isFullHeight(): boolean {
      return !!this.$route.meta.fullHeight;
    },
    isFullPage(): boolean {
      return !!this.$route.meta.fullPage;
    },
    hideVersion(): boolean {
      return !!this.$route?.meta.hideVersion;
    },
    clusters(): Array<ICluster> {
      return this.clusterStore.clusters;
    },
    currentClusterUuid(): string {
      return this.clusterStore.currentCluster.uuid;
    },
    hasAccessToken(): boolean {
      return !!this.authStore.accessToken;
    },
    needEulaSigned(): boolean {
      return !!Object.keys(this.authStore.tenant) && this.hasAccessToken && this.authStore.needEulaSigned;
    },
    shouldOpenLicenseModal(): boolean {
      return false; // TODO RUN-9592: remove when licenses are ready
      // TODO RUN-9592: uncomment when licenses are ready
      // const TWO_WEEKS = 14 * 24 * 60 * 60 * 1000;
      // return (
      //   this.licenseStore.licenses.some((l) => l.expiry < Date.now() + TWO_WEEKS) ||
      //   this.licenseStore.inUseGPUsAmount > this.licenseStore.licensedGPUs
      // );
    },
    isExpiredOrExceeded(): boolean {
      return (
        this.licenseStore.licenses.some((license) => license.expiry < Date.now()) ||
        this.licenseStore.inUseGPUsAmount > this.licenseStore.licensedGPUs
      );
    },
    canCreateLicense(): boolean {
      // We used to check if the user is admin, now we need to add "license" as a resource type to our RBAC resource types
      return true; // TODO RUN-9592: replace this when we have the correct RBAC resource types to check if the user is allowed to create a license
    },
    noEulaNotAdmin(): boolean {
      return (
        // Making sure tenant is loaded
        !!Object.keys(this.authStore.tenant).length &&
        this.appStore.appLoaded &&
        this.authStore.eulaNotSignedAndUserCannotSign
      );
    },
  },
  methods: {
    async initApp(): Promise<void> {
      let shouldShowApp = true;

      try {
        // postLogin
        if (window.location.pathname === "/login/callback") {
          await this.afterLogin();
          await this.loadAuthenticatedData(true);
        } else if (this.$route.meta.requiresAuth || window.location.pathname === "/") {
          // data we should load only for authenticated pages
          await this.loadAuthenticatedData();
        }
        console.log("App is ready!");
      } catch (error: unknown) {
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          if (error.statusCode === UnauthorizedError.statusCode) {
            this.redirectToLogin();
            shouldShowApp = false;
          } else if (error.statusCode === ForbiddenError.statusCode) {
            this.$router.push({ name: ERROR_ROUTE_NAMES.NO_ROLES });
          }
        }
      } finally {
        if (shouldShowApp) {
          this.appStore.setAppLoaded(true);
          if (this.noEulaNotAdmin) this.$router.push({ name: ERROR_ROUTE_NAMES.NO_EULA });
        }
      }
    },
    async loadAppConfiguration(): Promise<void> {
      this.authStore.loadTokenFromLocalStorage();
      await this.appStore.loadAppData();
      if (this.appStore.tenantName) {
        await this.authStore.loadAuthProviderConfig(this.appStore.config.authURL);
      }
    },
    async loadAuthenticatedData(isAfterLogin = false): Promise<void> {
      // when the token is missing, go to logout
      if (!this.authStore.accessToken) {
        throw new UnauthorizedError();
      }

      // if token exist
      await this.loadAppData(isAfterLogin);
      this.authStore.refreshUserTokenInterval();
      this.authStore.updateUserLastLoggedIn();
      this.loadPendo();
      if (this.settingStore.inactivityMinutesTillLogout) {
        this.idleTimerService.track(this.settingStore.inactivityMinutesTillLogout);
      }
      await this.startAppIntervals();
    },
    async loadAppData(isAfterLogin = false): Promise<void> {
      await this.authStore.loadUserInfo();
      await this.permissionStore.loadPermissions();
      await Promise.all([
        this.clusterStore.loadClusters(),
        this.authStore.loadTenant(),
        this.settingStore.loadSetting(),
        this.registryStore.loadRegistries(),
        // this.licenseStore.loadLicenses(), // TODO RUN-9592: Uncomment when licenses are ready
        this.authStore.loadUserOrgUnits(),
      ]);
      await this.changeLandingPageIfNeeded();
      if (!this.isRoutePermitted()) {
        await this.$router.replace({ name: ERROR_ROUTE_NAMES.NO_ROLES });
      } else if (this.clusters.length === 0 && this.clusterStore.canCreateCluster) {
        if (this.authStore.isTrial) {
          await this.$router.replace({ name: TRIAL_NAMES.CLUSTER_CONNECTING });
        } else {
          // waiting for clusters to load, If there are no clusters redirect to cluster creation page
          await this.$router.replace({ name: CLUSTER_ROUTE_NAMES.CLUSTER_NEW });
        }
      } else {
        await this.clusterStore.setActiveClusterAfterLogin(isAfterLogin);
      }
    },
    isRoutePermitted(): boolean {
      if (
        (this.$route.name === DASHBOARD_ROUTE_NAMES.CONSUMPTION_INDEX ||
          this.$route.name === DASHBOARD_ROUTE_NAMES.RESOURCES_INDEX) &&
        !this.permissionStore.isActionGrantedForEveryResource(
          [ResourceType.Cluster, ResourceType.Department, ResourceType.Project],
          Action.Read,
        )
      ) {
        return false;
      }
      if (!this.$route.meta.requiresAuth || !this.$route.meta.minPermittedActions || !this.$route.meta.resourceType)
        return true;

      return this.permissionStore.hasSomePermittedActions(
        this.$route.meta.resourceType,
        this.$route.meta.minPermittedActions,
      );
    },
    loadPendo(): void {
      if (this.appStore.isSelfHosted) return;
      try {
        pendoService.init(this.authStore.currentUser, this.authStore.tenant);
      } catch (e) {
        // do nothing
      }
    },
    redirectToLogin() {
      storageUtil.save(STORAGE_KEYS.LOCATION, this.$route.name);
      this.authStore.login();
    },
    async afterLogin(): Promise<void> {
      await this.authStore.postLogin();
      if (!authService.isValidSsoEmailMapperToken(this.authStore.accessToken)) {
        await this.$router.replace({ name: ERROR_ROUTE_NAMES.NO_SSO_EMAIL_MAPPER });
        return;
      }
    },
    async changeLandingPageIfNeeded(): Promise<void> {
      const location = storageUtil.get<string>(STORAGE_KEYS.LOCATION);
      if (location !== LANDING_PAGE_NAME) {
        await this.$router.replace({ name: location });
        storageUtil.remove(STORAGE_KEYS.LOCATION);
      } else if (this.$route.name === LANDING_PAGE_NAME || window.location.pathname === "/login/callback") {
        const redirect = this.authStore.redirectByPermission();
        await this.$router.replace({ name: redirect });
      }
    },
    closeLicenseModal(): void {
      this.openedLicenseModal = true;
    },
    closeAdminMessage(dismiss: boolean): void {
      if (dismiss && this.adminMessage) adminMessageUtil.dismissAdminMessage(this.adminMessage.id);
      this.adminMessage = null;
    },
    async loadAdminMessage(): Promise<void> {
      const adminMessage = await this.settingStore.loadAdminMessage();
      if (!adminMessage) return;
      this.adminMessage = adminMessageUtil.getAdminMessage(adminMessage);
    },
    async startAppIntervals(): Promise<void> {
      await this.loadAdminMessage();
      intervalUtil.startInterval(INTERVAL_LABELS.ADMIN_MESSAGE, this.loadAdminMessage, FIVE_MINUTES);
    },
    stopAppIntervals(): void {
      intervalUtil.stopInterval(INTERVAL_LABELS.ADMIN_MESSAGE);
    },
  },
  unmounted() {
    this.stopAppIntervals();
  },
});
</script>
<style scoped lang="scss">
.version-info {
  display: flex;
  flex: 1;
  align-items: flex-end;
  .app-info {
    font-size: 12px;
  }
}
</style>
