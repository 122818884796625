// services
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { filterService } from "@/services/filter.service/filter.service";
import { pick } from "@/utils/common.util";

// consts
import { API } from "@/common/api.constant";

// models
import type {
  WorkloadTemplate,
  WorkloadTemplateCreationRequest,
  WorkloadTemplateUpdateRequest,
} from "@/swagger-models/assets-service-client";
import type { IAssetsFilter } from "@/models/filter.model";
import {
  allWorkloadTemplateColumns,
  type IUIWorkloadTemplateCreationRequestModel,
} from "@/models/workload-template.model";

export const workloadTemplateService = {
  list,
  getById,
  remove,
  create,
  update,
  getEmptyWorkloadTemplateModel,
};

const endpoint = `${API.asset}/workload-template`;

// api calls

async function list(filterBy: IAssetsFilter): Promise<Array<WorkloadTemplate>> {
  const filters: IAssetsFilter = pick(
    filterBy,
    "sortBy",
    "page",
    "rowsPerPage",
    "scope",
    "projectId",
    "clusterId",
    "isWorkspace",
    "isTraining",
    "isDistributed",
    "distributedFramework",
  );
  let templates: Array<WorkloadTemplate> = await controlPlaneService.get(endpoint, filters).then((res) => res.entries);

  if (!filterBy || !filterBy.displayedColumns) return templates;

  if (filterBy.searchTerm) {
    templates = filterService.filterBySearchTerm<WorkloadTemplate>(
      templates,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allWorkloadTemplateColumns,
    );
  }

  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    templates = filterService.filterByColumns(templates, filterBy.columnFilters, allWorkloadTemplateColumns);
  }

  return templates;
}

async function getById(id: string): Promise<WorkloadTemplate> {
  return controlPlaneService.get(`${endpoint}/${id}`);
}

async function create(workloadTemplate: WorkloadTemplateCreationRequest): Promise<WorkloadTemplate> {
  return controlPlaneService.post(endpoint, workloadTemplate);
}

async function remove(workloadTemplate: WorkloadTemplate): Promise<void> {
  await controlPlaneService.delete(`${endpoint}/${workloadTemplate.meta.id}`);
}

async function update(templateId: string, template: WorkloadTemplateUpdateRequest): Promise<WorkloadTemplate> {
  return controlPlaneService.put(`${endpoint}/${templateId}`, template);
}

// helpers
function getEmptyWorkloadTemplateModel(): IUIWorkloadTemplateCreationRequestModel {
  return {
    meta: {
      name: "",
      scope: null,
      clusterId: null,
      projectId: null,
    },
    spec: {
      assets: {
        environment: "",
        compute: null,
        datasources: [],
      },
      specificEnv: {
        allowOverQuota: false,
        args: "",
        command: "",
        environmentVariables: [],
        connections: [],
        runAsGid: null,
        runAsUid: null,
      },
    },
  };
}
