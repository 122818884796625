<template>
  <div class="input-setting row q-my-sm q-pr-sm justify-between items-center">
    <div class="row items-center">
      <span class="text-subtitle1">{{ setting.label }}</span>

      <runai-tooltip v-if="tooltip" :tooltip-text="tooltip" tooltip-position="right" />
      <q-badge class="text-italic q-ml-md" v-if="setting.stage" :color="getBadgeColor(setting.stage)">
        {{ setting.stage }}
      </q-badge>
    </div>
    <q-input
      dense
      :model-value="setting.value"
      @update:model-value="updateSettings(setting.key, $event)"
      :type="getSettingInputType(setting.type)"
      class="setting-input"
      outlined
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiTooltip } from "@/components/common/runai-tooltip";
// Models
import type { Setting } from "@/swagger-models/backend-client";
// Utils
import { debounce } from "quasar";

export default defineComponent({
  name: "input-setting",
  components: {
    RunaiTooltip,
  },
  props: {
    setting: {
      type: Object as PropType<Setting>,
      required: true,
    },
    tooltip: {
      type: String as PropType<string>,
    },
  },
  created() {
    this.updateSettings = debounce(this.updateSettings, 1000);
  },
  methods: {
    updateSettings(key: string | undefined, value: string | number | boolean | null): void {
      if (value === null || !key) return;
      this.$emit("update-setting", key, this.setting.type === "Number" ? +value : value);
    },
    getBadgeColor(stage: string): string {
      return stage === "Alpha" ? "negative" : "amber-5";
    },
    getSettingInputType(settingType = "text"): "number" | "text" {
      return settingType === "Number" ? "number" : "text";
    },
  },
});
</script>

<style lang="scss" scoped>
.input-setting {
  .setting-input {
    width: 300px;
  }
}
</style>
