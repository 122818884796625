<template>
  <section class="sso-setting-input-wrapper row justify-between items-center q-mb-md q-mr-sm">
    <div>{{ label }}</div>
    <q-input
      no-error-icon
      hide-bottom-space
      outlined
      dense
      :model-value="modelValue"
      @update:model-value="$emit('update:model-value', $event)"
      :rules="rules"
      class="setting-input"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Models
import type { ValidationRule } from "quasar";

export default defineComponent({
  name: "sso-setting-input",
  emits: ["update:model-value"],
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    rules: {
      type: Array as PropType<Array<ValidationRule>>,
    },
  },
});
</script>

<style lang="scss" scoped>
.sso-setting-input-wrapper {
  width: 500px;

  .setting-input {
    width: 300px;
  }
}
</style>
