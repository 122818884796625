import type { IRouterTab } from "@/components/common/runai-router-tabs/tabs.model";

export const overviewRoutes: IRouterTab[] = [
  {
    fullPath: "/dashboards/now",
    label: "GPU Overview",
  },
  {
    fullPath: "/dashboards/cpu-overview",
    label: "CPU Overview",
  },
];

export const analyticsRoutes: IRouterTab[] = [
  {
    fullPath: "/dashboards/analytics",
    label: "GPU Analytics",
  },
  {
    fullPath: "/dashboards/cpu-analytics",
    label: "CPU Analytics",
  },
];

export enum EDashboardName {
  Overview = "nowDashboard",
  CpuOverview = "cpuOverview",
  Analytics = "longTermDashboard",
  CpuAnalytics = "cpuAnalytics",
  Consumption = "consumptionDashboard",
  Clusters = "clustersDashboard",
}
