import { debounce } from "quasar";
import { useAuthStore } from "@/stores/auth.store";

// utils
import { storageUtil } from "@/utils/storage.util";
import { intervalUtil } from "@/utils/interval.util";

// services
import { oldAppService } from "@/services/infra/old-app.service";

// consts
import { POST_MESSAGE_EVENTS } from "@/common/event.constant";
import { INTERVAL_LABELS } from "@/common/interval.constant";

interface IEventListener {
  event: string;
  callback: EventListenerOrEventListenerObject;
}

interface IFunction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...argArray: any[]): any;
}

const second = 1000;
const minute = 60 * second;

export class IdleTimer {
  // if you change the events list here, please make sure you udpate the
  // list in v1 as well
  events: Array<string> = ["mousemove", "scroll", "keydown", "click"];
  callbacks: Record<string, Array<IEventListener>> = {};
  eventHandler: IFunction;

  timeout: number = 60 * minute;
  timeoutTracker: number | undefined;
  expiredTimeName = "_expiredTime";
  v1Listener: string | undefined;

  constructor() {
    // binding the handler because we use this inside the fanction to get the timeout value
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.cleanUp();
  }

  track(minutesToLogout: number): void {
    this.timeout = minutesToLogout * minute;
    // add default listeners
    this.addListener("default", window.document);

    // add iframe listeners
    this.v1Listener = oldAppService.addListener(
      POST_MESSAGE_EVENTS.UPDATE_EXPRIED_TIMEOUT,
      this.getHandler() as IFunction,
    );

    this.updateExpiredTime();
    this.startInterval();
  }

  onTimeout(): void {
    this.cleanUp();
    useAuthStore().logout();
  }

  private cleanUp(): void {
    // remove default listeners
    this.removeListener("default", window.document);
    // remove iframe listeners
    this.v1Listener && oldAppService.removeListener(POST_MESSAGE_EVENTS.UPDATE_EXPRIED_TIMEOUT, this.v1Listener);

    // window.clearInterval(this.interval);
    intervalUtil.stopInterval(INTERVAL_LABELS.IDLE_TIMER);
    storageUtil.remove(this.expiredTimeName);
  }

  private addListener(name: string, doc: Document) {
    if (this.callbacks[name]) return;

    this.callbacks[name] = this.events.map((event: string) => {
      const callback: EventListenerOrEventListenerObject = this.getHandler();
      doc.addEventListener(event, callback);
      return { event, callback };
    });
  }

  private removeListener(name: string, doc: Document) {
    if (!this.callbacks[name]) return;

    const eventListeners: Array<IEventListener> = this.callbacks[name];
    eventListeners.forEach((listener: { event: string; callback: EventListenerOrEventListenerObject }) => {
      doc.removeEventListener(listener.event, listener.callback);
    });

    delete this.callbacks[name];
  }

  private getHandler(): EventListenerOrEventListenerObject {
    const timeDelay = second;
    return debounce(this.eventHandler, timeDelay); // the bounce here is an optimization only
  }

  private startInterval(): void {
    intervalUtil.stopInterval(INTERVAL_LABELS.IDLE_TIMER);
    intervalUtil.startInterval(
      INTERVAL_LABELS.IDLE_TIMER,
      () => {
        // the default  `${Date.now() + 60 * minute}` is for the edge case
        // when you clear the value from local storage to set new expired time
        // and the interval cycle is trying to load the expired time before the new one is set
        const expiredTime = parseInt(storageUtil.get(this.expiredTimeName) || `${Date.now() + 60 * minute}`, 10);
        if (expiredTime < Date.now()) {
          this.onTimeout();
        }
      },
      minute,
    );
  }

  private updateExpiredTime(): void {
    if (this.timeoutTracker) {
      window.clearTimeout(this.timeoutTracker);
    }

    // adding kind of delay to save the new expired time.
    this.timeoutTracker = window.setTimeout(() => {
      const minutesUntilExpiry: number = Date.now() + this.timeout;
      storageUtil.save(this.expiredTimeName, `${minutesUntilExpiry}`);
    }, 300);
  }
}
