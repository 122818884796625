<template>
  <runai-base-dialog :model-value="modelValue" @close="$emit('close')" size="width-md" no-padding>
    <template #header>{{ modalOptions.header }}</template>
    <template #body>
      <runai-list-modal-search v-model="tableFilter.searchTerm" placeholder="Search node pools" />
      <q-separator />
      <runai-table
        :rows="modalOptions.nodePools"
        :columns="columns"
        :loading="modalOptions.loading"
        :filter-by="tableFilter"
        :get-row-key="getRowKey"
        @update-filters="updateFilter"
        modal-view
        disable-selection
      >
        <template #no-data>
          <runai-table-no-data
            v-if="!modalOptions.loading"
            :filter-by="tableFilter"
            entity-name="Node Pools"
            :show-create-btn="false"
            :show-filter-icon-and-button="false"
          />
        </template>
      </runai-table>
    </template>

    <template #footer>
      <q-btn label="Close" color="primary" @click="$emit('close')" />
    </template>
  </runai-base-dialog>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { RunaiListModalSearch } from "@/components/common";

// services
import { filterService } from "@/services/filter.service/filter.service";
// stores
// models
import type { IFilterBy } from "@/models/filter.model";
import type { INodePoolModalOptions, IProject } from "@/models/project.model";
import { allNodePoolsColumnsMap, nodePoolsDependentColumns, nodePoolsMiniTableColumns } from "@/models/project.model";
import RunaiTable from "@/components/common/runai-table/runai-table.vue";
import type { ITableColumn } from "@/models/table.model";
import { useSettingStore } from "@/stores/setting.store";
import { RunaiTableNoData } from "@/components/common/runai-table-no-data";
import { useClusterStore } from "@/stores/cluster.store";
import { EQuotaEntity } from "@/models/resource.model";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_NEW_NODE_POOL_METRIC_VERSION } from "@/common/version.constant";

export default defineComponent({
  components: { RunaiTableNoData, RunaiTable, RunaiBaseDialog, RunaiListModalSearch },
  emits: ["close"],
  props: {
    modalOptions: {
      type: Object as PropType<INodePoolModalOptions>,
      required: true,
    },
  },
  data() {
    return {
      modelValue: true as boolean,
      tableFilter: {} as IFilterBy,
      settingStore: useSettingStore(),
      clusterStore: useClusterStore(),
    };
  },
  created() {
    this.loadFilters();
  },
  computed: {
    columns(): Array<ITableColumn> {
      return nodePoolsMiniTableColumns.filter((col: ITableColumn) => {
        if (nodePoolsDependentColumns.cpu.includes(col.name)) {
          return this.settingStore.isCPUResourcesQuotaEnabled;
        }
        if (nodePoolsDependentColumns.priority.includes(col.name)) {
          return this.modalOptions.entity === EQuotaEntity.project;
        }
        if (nodePoolsDependentColumns.allocatedResources.includes(col.name)) {
          return isNewerVersion(this.clusterStore.currentClusterVersion, MIN_NEW_NODE_POOL_METRIC_VERSION);
        }
        return true;
      });
    },
  },
  methods: {
    loadFilters(): void {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(
        allNodePoolsColumnsMap.nodePoolName.name,
        nodePoolsMiniTableColumns,
      );
      this.tableFilter = filterService.getEmptyFilterByModel(defaultFilters);
    },
    updateFilter(filter: IFilterBy): void {
      this.tableFilter = filter;
    },
    getRowKey(row: IProject): number {
      return row.id;
    },
  },
});
</script>

<style scoped></style>
