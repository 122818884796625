import { ECustomCell, type IStatusColOptions } from "./table.model";
import type { ITrainingList } from "./training.model";
import type { IWorkspaceList } from "./workspace.model";
import {
  type HistoryRecord,
  type Workload,
  type Pod as WorkloadServicePod,
  type Environment,
  type Datasource,
  type WorkloadRequestResources,
  Phase,
  WorkloadSortFilterFields,
} from "@/swagger-models/workloads-service-client";
import type {
  SpecificRunConnectionInfo,
  AssetIdAndKind,
  DisplayedJob,
  Distributed,
} from "@/swagger-models/assets-service-client";

import type { IAutoScaleData } from "@/components/section/compute-resource-section";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { IUIVolume } from "@/models/data-source.model";
import type { ITableColumn } from "@/models/table.model";
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { memoryFormat } from "@/utils/format.util";
import { dateUtil } from "@/utils/date.util";
import { capitalizeString } from "@/utils/string.util/string.util";

export type { WorkloadServicePod };

export type TWorkloadMetricValue = [number, string];
interface IWorkloadMetadataAnnotations {
  workspaceId: string;
}
export interface IGetWorkloadPods200Response {
  displayRecords: number;
  pods: WorkloadServicePod[];
}
export interface IWorkloadMetadata {
  name: string;
  namespace: string;
  creationTimestamp?: null;
  annotations: IWorkloadMetadataAnnotations;
}

export interface IWorkloadSpec {
  environment: {
    items: Record<string, { value: string }>;
  };
  name: { value: string };
  image: { value: string };
  username: { value: string };
  command?: { value: string };
  arguments?: { value: string };
  volumes?: {
    items: Record<string, unknown>;
  };
  cpu?: { value: string };
  gpu?: { value: string };
  memory?: { value: string };
  migProfile?: { value: string };
  nodeType?: { value: string };
  gitSync?: {
    items: Record<string, unknown>;
  };
  largeShm: {
    value: boolean;
  };
  imagePullPolicy: {
    value: string;
  };
  annotations: {
    items: Record<string, unknown>;
  };
  preemptible?: { value: string };
}

export interface IWorkload {
  apiVersion: string;
  kind: string;
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadCreate {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadResponse {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
}

export interface IWorkloadDurationOption {
  value: string;
  label: string;
  duration: number | null;
}

export enum EIdleWorkloadMaxDuration {
  training = "trainingJobMaxIdleDurationSecs",
  interactivePreemptible = "interactivePreemptibleJobMaxIdleDurationSecs",
  interactive = "interactiveJobMaxIdleDurationSecs",
}

export const allWorkloadMiniTableColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Workload",
    field: (row: IWorkspaceList | ITrainingList) => row.meta.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row: IWorkspaceList | ITrainingList) => row.job?.jobType,
    sortable: true,
    align: "left",
    format: (val: string) => {
      switch (val) {
        case "Train":
          return "Training";
        case "Interactive":
          return "Workspace";
        default:
          return val || "-";
      }
    },
  },
  status: {
    name: "workload-status",
    label: "Status",
    field: (row: IWorkspaceList | ITrainingList) => row.job,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (job: DisplayedJob): IStatusColOptions | string =>
      workloadUtil.getWorkspaceTrainingStatusColOptions(job, job?.statusTooltip),
    sort: (jobA: DisplayedJob, jobB: DisplayedJob): number => {
      if (jobA.status && jobB.status) {
        return jobA.status.localeCompare(jobB.status);
      }
      return -1;
    },
    exportFormat: (job: DisplayedJob) => job.status || "NA",
    filterKey: "status",
  },
};

export const workloadMiniTableColumns: Array<ITableColumn> = [
  { ...allWorkloadMiniTableColumnsMap.name, display: true },
  { ...allWorkloadMiniTableColumnsMap.type, display: true },
  { ...allWorkloadMiniTableColumnsMap.status, display: true },
];

export enum EWorkloadName {
  Workspace = "workspace",
  Training = "training",
}

export enum EWorkloadType {
  Workspace = "Workspace",
  Training = "Training",
  Distributed = "Distributed",
}

export enum EWorkloadFormType {
  Workspace = "workspace",
  Training = "training",
  Template = "template",
  Deployment = "deployment",
}

export interface IUIDistributedMaster {
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
}

export interface IUIDistributed extends Omit<Distributed, "master"> {
  master?: IUIDistributedMaster | null;
}

export interface IUIWorkloadCreation {
  name: string;
  projectId: number;
  namespace: string;
  clusterId: string;
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
  distributed?: IUIDistributed | null;
  enableEditingMaster?: boolean;
}

export interface IUIWorkloadModelCreation extends IUIWorkloadCreation {
  modelId: string;
}

export interface IUIWorkloadAssets {
  environment: string | null;
  compute: string | null;
  datasources?: Array<AssetIdAndKind>;
  uiVolumes?: Array<IUIVolume>;
}

export interface IUIWorkloadSpecificEnv {
  command?: string | null;
  args?: string | null;
  environmentVariables?: Array<IItemizedListItem>;
  allowOverQuota?: boolean | null;
  connections?: Array<SpecificRunConnectionInfo> | null;
  runAsGid?: number | null;
  runAsUid?: number | null;
  supplementalGroups?: string | null;
  nodeType?: string | null;
  nodePools?: Array<string> | null;
  annotations?: Array<IItemizedListItem>;
  labels?: Array<IItemizedListItem>;
  autoDeletionTimeAfterCompletionSeconds?: number | null;
  autoScaleData?: IAutoScaleData;
}

export interface IWorkloadMeta {
  name: string;
  namespace: string;
  clusterId: string;
  projectId: number;
}

export enum EWorkloadEntity {
  workspace = "workspace",
  training = "training",
  workload = "workload",
  deployment = "deployment",
}

export const WORKLOADS_REFRESH_INTERVAL = 5000;
export enum EWorkloadAction {
  activate = "activate",
  run = "run",
  stop = "stop",
  connect = "connect",
  delete = "delete",
  clone = "clone",
}

export interface JobMetricsData {
  gpuUtilization: Array<Array<number>>;
  gpuMemory: Array<Array<number>>;
  cpuUtilization: Array<Array<number>>;
  cpuMemory: Array<Array<number>>;
}

const workloadTypeMap: Record<string, string> = {
  Training: "Training",
  Interactive: "Workspace",
};

export enum EWorkloadNodeArchitecture {
  Distributed = "Distributed",
  Standard = "Standard",
}

//workloads table data
export const allWorkloadColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: WorkloadSortFilterFields.Name,
    label: "Workload",
    field: (workload: Workload) => workload.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: WorkloadSortFilterFields.Type,
    label: "Type",
    field: (workload: Workload) => workload.type,
    format: (workloadType: string) => workloadTypeMap[workloadType] || capitalizeString(workloadType),
    sortable: true,
    align: "left",
  },
  status: {
    name: WorkloadSortFilterFields.Phase,
    label: "Status",
    field: (workload: Workload) => workload,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (workload: Workload): IStatusColOptions | string =>
      workloadUtil.getWorkloadStatusColOptions(workload, workload.phaseMessage),
    sort: (workloadA: Workload, workloadB: Workload): number => workloadA.phase.localeCompare(workloadB.phase),
    exportFormat: (workload: Workload) => workload.phase || "NA",
    filterKey: "status",
  },
  submittedBy: {
    name: WorkloadSortFilterFields.SubmittedBy,
    label: "Created by",
    field: (workload: Workload) => workload.submittedBy || "-",
    sortable: true,
    align: "left",
  },
  project: {
    name: WorkloadSortFilterFields.ProjectName,
    label: "Project",
    field: (workload: Workload) => workload.projectName,
    sortable: true,
    align: "left",
  },
  nodePools: {
    name: "node-pools",
    label: "Node pools",
    field: (workload: Workload): string[] => {
      if (workload.currentNodePools && workload.currentNodePools.length > 0) {
        return workload.currentNodePools;
      }
      if (workload.requestedNodePools?.length === 1) {
        return workload.requestedNodePools;
      }
      return [];
    },
    format: (currentNodePools: string[]): string => (currentNodePools.length > 0 ? currentNodePools.join(",") : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  runningPodsVsRequestedPods: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string[] => [workloadUtil.getRunningVsRequestedDisplayedValue(workload)],
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "pods-clicked" },
    hideFilter: true,
  },
  runningPodsVsRequestedPodsForModal: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string => workloadUtil.getRunningVsRequestedDisplayedValue(workload),
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    hideFilter: true,
  },
  creationTime: {
    name: WorkloadSortFilterFields.CreatedAt,
    label: "Creation time",
    field: (workload: Workload) => workload.createdAt,
    format: (createdAt: string): string => {
      return !createdAt ? "-" : dateUtil.dateFormat(new Date(createdAt), "dd/MM/yyyy HH:mm a");
    },
    sortable: true,
    align: "left",
  },
  completionTime: {
    name: WorkloadSortFilterFields.CompletedAt,
    label: "Completion time",
    field: (workload: Workload) => workload.completedAt,
    format: (completedAt: string): string => {
      return !completedAt ? "-" : dateUtil.dateFormat(new Date(completedAt), "dd/MM/yyyy HH:mm a");
    },
    sortable: true,
    align: "left",
    hideFilter: true,
  },
  dataSource: {
    name: "data-sources",
    label: "Data source(s)",
    field: (workload: Workload) => workload.datasources,
    sortable: false,
    hideFilter: true,
    align: "left",
    format: (dataSources: Datasource[]): Array<string> => {
      if (!dataSources?.length) return [];
      return dataSources.map((datasource: Datasource) => datasource.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "data-source-clicked" },
  },
  environment: {
    name: "environment",
    label: "Environment",
    field: (workload: Workload) => workload.environments,
    sortable: false,
    align: "left",
    format: (environments: Environment[]) => {
      if (!environments?.length) return ["-"];
      return environments.map((env: Environment) => env.name);
    },
    customCell: ECustomCell.STRINGS_LIST_COL,
    hideFilter: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: Workload) => row.distributedFramework,
    sortable: false,
    align: "left",
    format: (distributedFramework: string | undefined) =>
      distributedFramework ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    hideFilter: true,
  },
  requestedGPU: {
    name: "requestedGPU",
    label: "Requested GPU devices",
    field: (workload: Workload) => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.gpu?.request || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedGPUMemory: {
    name: "requestedGPUMemory",
    label: "Requested GPU memory",
    field: (workload: Workload) => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.gpuMemory?.request || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUMemory: {
    name: "requestedCPUMemory",
    label: "Requested CPU memory",
    field: (workload: Workload) => {
      const requestedResources: WorkloadRequestResources | undefined | null =
        workload.podsRequestedResources || workload.workloadRequestedResources;
      return requestedResources?.cpuMemory?.request || "-";
    },
    format: (cpuMemory: number) => (cpuMemory || cpuMemory === 0 ? memoryFormat(cpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUCompute: {
    name: "requestedCPUCompute",
    label: "Requested CPU compute",
    field: (workload: Workload) => workload.podsRequestedResources?.cpu?.request || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
};

export const allWorkloadsColumns: Array<ITableColumn> = [
  allWorkloadColumnsMap.name,
  allWorkloadColumnsMap.type,
  allWorkloadColumnsMap.status,
  allWorkloadColumnsMap.submittedBy,
  allWorkloadColumnsMap.project,
  allWorkloadColumnsMap.nodePools,
  allWorkloadColumnsMap.runningPodsVsRequestedPods,
  allWorkloadColumnsMap.creationTime,
  allWorkloadColumnsMap.completionTime,
  allWorkloadColumnsMap.dataSource,
  allWorkloadColumnsMap.environment,
  allWorkloadColumnsMap.multiNode,
  allWorkloadColumnsMap.requestedGPU,
  allWorkloadColumnsMap.requestedGPUMemory,
  allWorkloadColumnsMap.requestedCPUCompute,
  allWorkloadColumnsMap.requestedCPUMemory,
];

export const workloadIndexColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true, mandatory: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: true },
  { ...allWorkloadColumnsMap.project, display: true },
  { ...allWorkloadColumnsMap.nodePools, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPods, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: false },
  { ...allWorkloadColumnsMap.completionTime, display: false },
  { ...allWorkloadColumnsMap.dataSource, display: false },
  { ...allWorkloadColumnsMap.environment, display: false },
  { ...allWorkloadColumnsMap.multiNode, display: false },
  { ...allWorkloadColumnsMap.requestedGPU, display: false },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: false },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: false },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: false },
];

export const workloadDependentColumns = {
  multiNode: new Set([allWorkloadColumnsMap.multiNode.name]),
};

export const ExtendedPhase = {
  ...Phase,
  Succeeded: "Succeeded",
} as const;

export type ExtendedPhase = typeof ExtendedPhase[keyof typeof ExtendedPhase];

export const WorkloadPhaseMap: Record<ExtendedPhase, IStatusColOptions> = {
  [ExtendedPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Initializing]: {
    status: "Initializing",
    displayAnimation: false,
  },
  [ExtendedPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Pending]: {
    status: "Pending",
    color: "warning",
    displayAnimation: false,
  },
  [ExtendedPhase.Running]: {
    status: "Running",
    displayAnimation: false,
    color: "success",
  },
  [ExtendedPhase.Stopped]: {
    status: "Stopped",
    displayAnimation: false,
  },
  [ExtendedPhase.Degraded]: {
    status: "Running with issues",
    displayAnimation: false,
  },
  [ExtendedPhase.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
  },
  [ExtendedPhase.Completed]: {
    status: "Completed",
    displayAnimation: false,
  },
  [ExtendedPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [ExtendedPhase.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
  },
  [ExtendedPhase.Stopping]: {
    status: "Stopping...",
    displayAnimation: true,
  },
  [ExtendedPhase.Terminating]: {
    status: "Terminating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Succeeded]: {
    status: "Succeeded",
    color: "success",
    displayAnimation: false,
  },
  [ExtendedPhase.Resuming]: {
    status: "Resuming...",
    displayAnimation: true,
  },
};

export enum EWorkloadErrorMessage {
  NotRunaiWorkloadMessage = "This action is not supported for the selected workload. To perform the action, go to the application you used to create the workload.",
  FailedToLoadWorkloads = "Failed to load workloads",
  FailedToLoadWorkloadsCount = "Failed to load workloads count",
  FailedToLoadPads = "Failed to load pods",
  FailedToRunWorkload = "Failed to start workload",
  FailedToDeleteWorkloads = "Failed to load workloads",
  FailedToStopWorkloads = "Failed to stop workload",
  NoDeletePermission = "You don't have permission to delete this workload.",
  DeleteWorkloadIsNotSupported = "Delete operation is not supported for this workload type",
  WorkloadIsDeleting = "The selected workload is already deleted or in the process of being deleted",
}

//pods modal
export const allWorkloadPodsModalColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Pod",
    field: (pod: WorkloadServicePod) => pod.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (pod: WorkloadServicePod) => pod,
    sortable: true,
    align: "left",
    format: (pod: WorkloadServicePod): IStatusColOptions | string => workloadUtil.getPodStatusColOptions(pod),
    sort: (podA: WorkloadServicePod, podB: WorkloadServicePod): number => podA.k8sPhase.localeCompare(podB.k8sPhase),
    exportFormat: (pod: WorkloadServicePod) => pod.k8sPhase || "NA",
    filterKey: "status",
    customCell: ECustomCell.STATUS_COL,
  },
  podId: {
    name: "podId",
    label: "Pod ID",
    field: (pod: WorkloadServicePod) => pod.id,
    sortable: true,
    align: "left",
  },
  nodeName: {
    name: "nodeName",
    label: "Node",
    field: (pod: WorkloadServicePod) => pod.nodeName || "-",
    sortable: true,
    align: "left",
  },
  nodePool: {
    name: "nodePool",
    label: "Node pool",
    field: (pod: WorkloadServicePod) => pod.currentNodePool || "-",
    sortable: true,
    align: "left",
  },
  image: {
    name: "image",
    label: "Image",
    field: (pod: WorkloadServicePod) => pod.containers[0].image,
    sortable: true,
    align: "left",
  },
  created: {
    name: "created",
    label: "Creation time",
    field: (pod: WorkloadServicePod) => pod.createdAt,
    format: (createdAt: string): string => (!createdAt ? "-" : dateUtil.dateAndTimeFormat(new Date(createdAt))),
    sortable: true,
    align: "left",
  },
};
export interface IWorkloadPodsModalOptions {
  pods: WorkloadServicePod[];
  loading: boolean;
  header: string;
}
export const workloadPodsModalTableColumns: Array<ITableColumn> = [
  { ...allWorkloadPodsModalColumnsMap.name, display: true },
  { ...allWorkloadPodsModalColumnsMap.status, display: true },
  { ...allWorkloadPodsModalColumnsMap.podId, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodeName, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodePool, display: true },
  { ...allWorkloadPodsModalColumnsMap.image, display: true },
  { ...allWorkloadPodsModalColumnsMap.created, display: true },
];

//workload events history table
// services

const allEventHistoryColumnsMap = {
  createdAt: {
    name: "createdAt",
    label: "Date & Time",
    field: (historyRecord: HistoryRecord) => historyRecord.meta.creationTimestamp,
    align: "left",
    display: true,
    sortable: true,
    format: (createdAt: string) =>
      createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt), { includeMilliseconds: true, includeSeconds: true }),
  },
  event: {
    name: "event",
    label: "Event",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord) ? "Status changed" : historyRecord.spec.event?.reason,
    align: "left",
    sortable: true,
    display: true,
  },
  eventType: {
    name: "eventType",
    label: "Event type",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.type || "-",
    align: "left",
    sortable: true,
    display: true,
  },
  eventIssuer: {
    name: "eventIssuer",
    label: "Event issuer",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.source || "-",
    align: "left",
    display: true,
    sortable: true,
  },
  component: {
    name: "component",
    label: "Component",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.involvedObject?.kind || "",
    align: "left",
    display: true,
    sortable: true,
  },
  details: {
    name: "details",
    label: "Details",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord)
        ? historyRecord.spec.phaseUpdate?.phase
        : historyRecord.spec.event?.message,
    align: "left",
    display: true,
    sortable: true,
  },
} as Record<string, ITableColumn>;

export const allEventHistoryColumns: Array<ITableColumn> = [
  allEventHistoryColumnsMap.createdAt,
  allEventHistoryColumnsMap.event,
  allEventHistoryColumnsMap.eventType,
  allEventHistoryColumnsMap.eventIssuer,
  allEventHistoryColumnsMap.component,
  allEventHistoryColumnsMap.details,
];

export const eventHistoryColumns: Array<ITableColumn> = [
  { ...allEventHistoryColumnsMap.createdAt, display: true },
  { ...allEventHistoryColumnsMap.event, display: true },
  { ...allEventHistoryColumnsMap.eventType, display: true },
  { ...allEventHistoryColumnsMap.eventIssuer, display: true },
  { ...allEventHistoryColumnsMap.component, display: true },
  { ...allEventHistoryColumnsMap.details, display: true },
];

//metric
export interface IWorkloadMetricRangeParams {
  start: number;
  end: number;
  step: number;
}

//workloads list modal
export const workloadListModalColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPodsForModal, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: true },
  { ...allWorkloadColumnsMap.createdAt, display: true },
  { ...allWorkloadColumnsMap.requestedGPU, display: true },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: true },
];

export interface IWorkloadListModalOptions {
  header: string;
  entityFilter: string;
  //in order to support the table to pick the correct filter need to supply both filterName and entityName
  filterName?: WorkloadSortFilterFields;
  entityName?: string;
}
