import type { IWorkloadCreateFormConfig } from "@/components/old-workload/workload-create-form";

export const workloadCreateFormUtil = {
  getWorkloadWorkersConfig,
  getWorkloadMasterConfig,
};

function getWorkloadWorkersConfig(): IWorkloadCreateFormConfig {
  const workloadConfig: IWorkloadCreateFormConfig = {
    project: {
      projects: [],
    },
    workloadName: {
      sectionDefaultOpen: false,
    },
    multiNode: {
      sectionDefaultOpen: false,
    },
    environment: {
      environments: [],
      sectionOptions: {
        cardsTitle: "Select the environment for the workers",
      },
    },
    compute: {
      isRequired: true,
      nodeAffinity: [],
      computeResources: [],
      sectionOptions: {
        applyPolicyDefaults: false,
        cardsTitle: "Select the node resources needed to run each worker",
      },
    },
    dataSource: {
      dataSources: [],
      sectionDefaultOpen: false,
      sectionOptions: {
        cardsTitle: "Select the data sources your workers need to access",
      },
    },
    volumes: {
      storageClasses: [],
      sectionDefaultOpen: false,
    },
    general: {
      sectionDefaultOpen: false,
    },
  };

  return workloadConfig;
}

function getWorkloadMasterConfig(): IWorkloadCreateFormConfig {
  const workloadConfig: IWorkloadCreateFormConfig = {
    toggleEditingForm: {
      sectionOptions: {
        mainTitle: "Allow different setup for the master",
        tooltip: `
        When enabled, you can change the master's setup to be different from the workers'.
        When disabled, the master will inherit the workers' setup automatically.
        `,
      },
    },
    environment: {
      environments: [],
      sectionDefaultOpen: true,
      sectionOptions: {
        cardsTitle: "Select the environment for the master",
      },
    },
    compute: {
      sectionDefaultOpen: true,
      isRequired: true,
      nodeAffinity: [],
      computeResources: [],
      sectionOptions: {
        applyPolicyDefaults: false,
        cardsTitle: "Select the node resources needed to run the master",
        hideNodePools: true,
      },
    },
    dataSource: {
      dataSources: [],
      sectionDefaultOpen: false,
      sectionOptions: {
        cardsTitle: "Select the data sources your master need to access",
      },
    },
    volumes: {
      storageClasses: [],
      sectionDefaultOpen: false,
    },
    general: {
      sectionDefaultOpen: false,
    },
  };

  return workloadConfig;
}
