<template>
  <q-td class="scope-col">
    <q-btn @click.stop class="btn-link no-padding" :ripple="false" flat color="primary" :label="scopeName" no-caps>
      <runai-org-tree-with-pop-over :selected="{ type: row.meta.scope, id: scopeId }" readonly />
    </q-btn>
  </q-td>
</template>

<script lang="ts">
import type { ITableColumn } from "@/models/table.model";
import { useAuthStore } from "@/stores/auth.store";
import type { OrgUnit } from "@/swagger-models/backend-client";
import { Scope } from "@/swagger-models/assets-service-client";
import { defineComponent, type PropType } from "vue";
import { RunaiOrgTreeWithPopOver } from "@/components/common/runai-org-tree/with-popover";

export default defineComponent({
  components: {
    RunaiOrgTreeWithPopOver,
  },
  props: {
    row: {
      type: Object as PropType<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
    };
  },
  computed: {
    scopeName(): string | number {
      return (
        this.authStore.orgUnitList.find(
          (orgUnit: OrgUnit) => orgUnit.id === this.scopeId && orgUnit.type === this.row.meta.scope,
        )?.name || ""
      );
    },
    scopeId(): string {
      switch (this.row.meta.scope) {
        case Scope.Department:
          return String(this.row.meta.departmentId);
        case Scope.Project:
          return String(this.row.meta.projectId);
        default:
          return String(this.authStore.tenant.id);
      }
    },
  },
});
</script>
<style lang="scss" scoped></style>
