import type { ITableColumn } from "@/models/table.model";

export interface IService {
  name: string;
}

export interface IServiceTableModalOptions {
  services: IService[];
  missingPrerequisites: string[];
  columns: Array<ITableColumn>;
  header: string;
  loading: boolean;
}
export const allServiceColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Service",
    field: (row: IService) => row.name,
    sortable: true,
    align: "left",
  },
};

export const serviceMiniTableColumns: Array<ITableColumn> = [{ ...allServiceColumnsMap.name, display: true }];
