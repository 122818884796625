import { pick } from "@/utils/common.util";
import { filterService } from "@/services/filter.service/filter.service";
import type { IFilterBy } from "@/models/filter.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { allUserColumns } from "@/models/user.model";
import type { IUser, IUserCreationRequest, IUserCreationResponse } from "@/models/user.model";
import { API } from "@/common/api.constant";

export const userService = {
  list,
  remove,
  create,
  resetPassword,
};

const endpoint = `${API.v1}/users`;

async function list(filterBy: IFilterBy = {}, localOnly = true): Promise<IUser[]> {
  const filters: IFilterBy = pick(filterBy, "sortBy", "page", "rowsPerPage");
  const localUSerFilter = localOnly ? `?filter=runai.is_local:true` : "";
  let users: Array<IUser> = await controlPlaneService.get(`${endpoint}${localUSerFilter}`, filters);

  if (!filterBy || !filterBy.displayedColumns) return users;
  if (filterBy.searchTerm) {
    users = filterService.filterBySearchTerm<IUser>(
      users,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      allUserColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    users = filterService.filterByColumns(users, filterBy.columnFilters, allUserColumns);
  }
  return users;
}

async function remove(userId: string): Promise<void> {
  return controlPlaneService.delete(`${endpoint}/${userId}`);
}

async function create(userCreationRequest: IUserCreationRequest): Promise<IUserCreationResponse> {
  return controlPlaneService.post(endpoint, userCreationRequest);
}

async function resetPassword(userId: string): Promise<string> {
  return controlPlaneService.post(`${endpoint}/${userId}/password`, userId);
}

export default userService;
