import type { IOrgTreeNode, IOrgTreeNodeId } from "@/models/org-tree.model";
import { EOrgTreeNodeIcons } from "@/models/org-tree.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type { OrgUnit } from "@/swagger-models/backend-client";
import { ScopeType, type PermittedScopes } from "@/swagger-models/authorization-client";
import type { AssetMeta } from "@/swagger-models/assets-service-client";
import { K8S_API } from "@/common/api.constant";

export const orgTreeService = {
  getOrgTreeUnits,
  getOrgTree,
  getNodePathById,
  getExpandedPermittedScopes,
  isAllowedScope,
};

async function getOrgTreeUnits(): Promise<OrgUnit[]> {
  return (await controlPlaneService.get(K8S_API.v1 + "/org-tree")).orgUnits;
}

function getOrgTree(orgUnits: Array<OrgUnit>): IOrgTreeNode {
  const tenantUnit: OrgUnit | undefined = orgUnits.find((unit) => unit.type === ScopeType.Tenant);
  if (!tenantUnit) return {} as IOrgTreeNode;
  // some ids are not unique (departments and projects can have the same id)
  const tenantUniqueKey: string = tenantUnit.id + tenantUnit.type;
  const orgUnitMap: Record<string, OrgUnit> = {};
  const treeNodeMap: Record<string, IOrgTreeNode> = {};
  orgUnits.forEach((unit: OrgUnit) => {
    treeNodeMap[unit.id + unit.type] = _orgUnitToNode(unit);
    orgUnitMap[unit.id + unit.type] = unit;
  });
  for (const node in treeNodeMap) {
    const unit: OrgUnit = orgUnitMap[node];
    if (unit.parentId && unit.parentType) {
      const parent: IOrgTreeNode = treeNodeMap[unit.parentId + unit.parentType];
      if (!parent) continue;
      parent.children.push(treeNodeMap[node]);
    }
  }
  treeNodeMap[tenantUniqueKey].path = tenantUnit.name;
  _setNodesPath(treeNodeMap[tenantUniqueKey].children, treeNodeMap[tenantUniqueKey].path, orgUnitMap);
  return treeNodeMap[tenantUniqueKey];
}

function getNodePathById(node: IOrgTreeNode, nodeId: IOrgTreeNodeId): string | null {
  if (node.id === nodeId.id && node.type === nodeId.type) {
    return node.path;
  }
  for (const child of node.children || []) {
    const result = getNodePathById(child, nodeId);
    if (result) {
      return result;
    }
  }
  return null;
}

function getExpandedPermittedScopes(permittedScopes: PermittedScopes, orgUnits: Array<OrgUnit>): PermittedScopes {
  return orgUnits.reduce(
    (acc: PermittedScopes, unit: OrgUnit) => {
      const { type, id } = unit;
      const { clusters = [], departments = [], projects = [] } = acc;
      if (type === ScopeType.Tenant && permittedScopes.tenant === id) {
        return { ...acc, tenant: id };
      }
      if (
        type === ScopeType.Cluster &&
        (permittedScopes.clusters?.includes(id) || _checkParentPermission(permittedScopes, unit, orgUnits))
      ) {
        return { ...acc, clusters: [...clusters, id] };
      }
      if (
        type === ScopeType.Department &&
        (permittedScopes.departments?.includes(id) || _checkParentPermission(permittedScopes, unit, orgUnits))
      ) {
        return { ...acc, departments: [...departments, id] };
      }
      if (
        type === ScopeType.Project &&
        (permittedScopes.projects?.includes(id) || _checkParentPermission(permittedScopes, unit, orgUnits))
      ) {
        return { ...acc, projects: [...projects, id] };
      }
      return acc;
    },
    { clusters: [], departments: [], projects: [] } as PermittedScopes,
  );
}

function _setNodesPath(nodes: Array<IOrgTreeNode>, pathPrefix: string, orgUnitMap: Record<string, OrgUnit>): void {
  nodes.forEach((node: IOrgTreeNode) => {
    // some ids are not unique (departments and projects can have the same id)
    const nodeUniqueKey = node.id + node.type;
    node.path = `${pathPrefix}/${orgUnitMap[nodeUniqueKey].name}`;
    if (node.children.length > 0) {
      _setNodesPath(node.children, node.path, orgUnitMap);
    }
  });
}

function _orgUnitToNode(unit: OrgUnit): IOrgTreeNode {
  const iconMap = {
    [ScopeType.Tenant]: EOrgTreeNodeIcons.Tenant,
    [ScopeType.Cluster]: EOrgTreeNodeIcons.Cluster,
    [ScopeType.Department]: EOrgTreeNodeIcons.Department,
    [ScopeType.Project]: EOrgTreeNodeIcons.Project,
  };
  return {
    id: unit.id,
    path: unit.name,
    type: unit.type,
    children: [],
    icon: iconMap[unit.type],
    label: unit.name,
  };
}

function _checkParentPermission(permittedScopes: PermittedScopes, unit: OrgUnit, orgUnits: Array<OrgUnit>): boolean {
  const { parentType, parentId } = unit;
  if (!parentId || !parentType) return false;
  if (parentType === ScopeType.Tenant && permittedScopes.tenant === parentId) return true;
  if (parentType === ScopeType.Cluster && permittedScopes.clusters?.includes(parentId)) return true;
  if (parentType === ScopeType.Department && permittedScopes.departments?.includes(parentId)) return true;
  const parentUnit: OrgUnit | undefined = orgUnits.find((unit) => unit.id === parentId);
  if (!parentUnit) return false;
  // Checking permission for more than 1 level of parent
  return _checkParentPermission(permittedScopes, parentUnit, orgUnits);
}

function isAllowedScope(meta: AssetMeta, allowedScopes: PermittedScopes, orgUnits: Array<OrgUnit>): boolean {
  const expandedAllowedScopes = getExpandedPermittedScopes(allowedScopes, orgUnits);
  const { scope, departmentId, projectId } = meta;
  switch (scope) {
    case ScopeType.Tenant:
      return !!expandedAllowedScopes.tenant;
    case ScopeType.Project:
      return !!expandedAllowedScopes.projects?.includes(projectId + "");
    case ScopeType.Department:
      return !!expandedAllowedScopes.departments?.includes(departmentId || "");
    default:
      return false;
  }
}
