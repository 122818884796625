import { API } from "@/common/api.constant";
import type { ClusterControlPlaneConfigAgentClient } from "@/swagger-models/backend-client";
import type { IAssetsFilter } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { pick } from "@/utils/common.util";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import { applicationsColumns } from "@/models/applications.model";
import type {
  IApplication,
  IApplicationCreationRequeset,
  IApplicationCreationResponse,
  IApplicationRegenerateSecretResponse,
} from "@/models/applications.model";

export const applicationsService = {
  list,
  remove,
  create,
  regenerateSecret,
  getRunAiInstallerAppClient,
};
const endpoint = `${API.v1}/apps`;

async function list(filterBy: IAssetsFilter): Promise<IApplication[]> {
  const filters: IAssetsFilter = pick(filterBy, "projectId", "scope", "usageInfo");
  let apps: Array<IApplication> = await controlPlaneService.get(`${endpoint}`, filters);

  if (!filterBy || !filterBy.displayedColumns) return apps;
  if (filterBy.searchTerm) {
    apps = filterService.filterBySearchTerm<IApplication>(
      apps,
      filterBy.searchTerm,
      filterBy.displayedColumns,
      applicationsColumns,
    );
  }
  if (filterBy.columnFilters && filterBy.columnFilters.length) {
    apps = filterService.filterByColumns(apps, filterBy.columnFilters, applicationsColumns);
  }
  return apps;
}

async function remove(id: string): Promise<void> {
  return controlPlaneService.delete(`${endpoint}/${id}`);
}

async function create(appCreationRequest: IApplicationCreationRequeset): Promise<IApplicationCreationResponse> {
  return controlPlaneService.post(`${endpoint}`, appCreationRequest);
}

async function regenerateSecret(id: string): Promise<IApplicationRegenerateSecretResponse> {
  return controlPlaneService.post(`v1/k8s/apps/${id}/secret`);
}

async function getRunAiInstallerAppClient(): Promise<ClusterControlPlaneConfigAgentClient> {
  return await controlPlaneService.get(`${endpoint}/installer`);
}
