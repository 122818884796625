<template>
  <div id="singleStatChart" class="single-stat-frame q-px-md q-py-xs">
    <div class="label text-subtitle2 q-py-sm">
      {{ label }}
      <runai-tooltip-wrapper use-html class="q-pl-xs" v-if="tooltipText">
        <template #tooltip-content>
          <div v-html="tooltipText" />
        </template>
        <q-icon class="q-pl-xs q-pb-xs" name="far fa-circle-question"
      /></runai-tooltip-wrapper>
    </div>
    <div>
      <div class="row">
        <div v-if="isValidSingleValue" class="value col-4">{{ singleValue }}</div>
        <div v-else class="value col-2">
          <span :style="`color: ${color}`">{{ `${fixedNumerator}/${fixedDenominator}` }}</span
          ><span class="unit text-weight-medium">{{ unit }}</span>
        </div>
        <div class="value text-right col-10" v-if="showPercentage">{{ percentage }}%</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { SINGLE_STAT_COLOR_PALETTE, IColorConfigurationItem } from "@/components/dashboard/chart.model";
import { formatNumberWithFixedDecimals } from "@/utils/common.util";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";

export default defineComponent({
  components: { RunaiTooltipWrapper },
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    unit: {
      type: String as PropType<string>,
      default: null,
    },
    numerator: {
      type: Number as PropType<number>,
      required: false,
    },
    denominator: {
      type: Number as PropType<number>,
      required: false,
    },
    singleValue: {
      type: Number as PropType<number>,
      default: null,
    },
    showPercentage: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    colorConfigurationArray: {
      type: Array as PropType<Array<IColorConfigurationItem>>,
      default: null,
    },
    tooltipText: {
      type: String as PropType<string>,
      default: "",
    },
  },
  computed: {
    percentage(): number {
      if (!this.denominator || !this.numerator || this.denominator === 0) return 0;
      return Math.round((this.numerator / this.denominator) * 100);
    },
    // eslint-disable-next-line no-undef
    color(): SINGLE_STAT_COLOR_PALETTE | string {
      if (this.colorConfigurationArray) {
        for (const range of this.colorConfigurationArray) {
          if (this.percentage >= range.from && this.percentage < range.to) {
            return range.color;
          }
        }
      }
      return "unset";
    },
    isValidSingleValue(): boolean {
      return this.singleValue != null && this.singleValue >= 0;
    },
    fixedNumerator(): number {
      return this.numerator ? formatNumberWithFixedDecimals(this.numerator, 1) : 0;
    },
    fixedDenominator(): number {
      return this.denominator ? formatNumberWithFixedDecimals(this.denominator, 1) : 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.single-stat-frame {
  background: $white;
  box-shadow: 0 1px 4px $black-35;
  border-radius: 3px;
  height: 90px;
  min-width: 160px;
}
.label {
  color: $black-default-highcharts;
}
.value {
  font-size: 30px;
  font-weight: 500;
}
.unit {
  padding-left: 10px;
  font-size: 14px;
  color: unset;
}
</style>
