<template>
  <button :disabled="readOnly" class="editable-button" :class="buttonClass" type="button">
    {{ editableText }}
    <q-menu anchor="bottom middle" self="top middle" @hide="resetValue" v-model="isOpen">
      <q-card-section class="row justify-between items-center q-py-sm">
        {{ labelText }}
        <q-btn flat icon="fa-regular fa-xmark" class="q-pa-xs" round v-close-popup @click="resetValue"></q-btn>
      </q-card-section>
      <q-card-section class="q-py-sm">
        <runai-numeric-input
          ref="numeric-input"
          :autofocus="true"
          v-model.number="inputValue"
          :placeholder="inputPlaceholder"
          :is-integer="isInteger"
          input-class="wide-input"
          no-error-icon
          stack-label
        />
      </q-card-section>
      <q-card-section class="row justify-end q-py-sm">
        <q-btn flat color="primary" label="Apply" v-close-popup @click="updateValue" />
      </q-card-section>
    </q-menu>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiNumericInput } from "@/components/common/runai-numeric-input";
const FRACTION_DIGITS_NUMBER = 2;
export default defineComponent({
  components: {
    RunaiNumericInput,
  },
  emits: ["update:model-value", "close"],
  props: {
    labelText: {
      type: String as PropType<string>,
      required: true,
    },
    inputPlaceholder: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    modelValue: {
      type: [Number, null] as PropType<number | null | undefined>,
      required: true,
    },
    isInteger: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.modelValue as number | null,
      isOpen: false as boolean,
    };
  },
  computed: {
    editableText() {
      if (this.modelValue === null) return this.inputPlaceholder;
      if (this.isInteger) return this.modelValue;
      return this.modelValue.toFixed(FRACTION_DIGITS_NUMBER);
    },
    buttonClass(): Record<string, boolean> {
      if (this.isOpen) return { "primary-border": true };
      return {};
    },
  },
  methods: {
    updateValue(): void {
      this.$emit("update:model-value", this.inputValue);
    },
    resetValue(): void {
      this.inputValue = this.modelValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.wide-input {
  width: 200px;
}

.editable-button {
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $black-54;
  text-align: center;
  box-shadow: 0 1px 6px $black-15;
  color: $black-70;
  background-color: white;
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 6px 2px $black-25;
  }
}
.primary-border {
  border: 2px solid $primary;
}
</style>
