<template>
  <section class="runai-log-reader">
    <div class="logs-title-wrapper">
      <div class="runai-log-header">
        <div class="text-body2 text-weight-medium ellipsis">Logs of current active status</div>
        <div v-if="showLogs && podsOptions?.length" class="row items-center">
          <div class="text-body2 text-weight-medium q-mr-sm">Pod:</div>
          <runai-select
            class="runai-slim-select"
            outlined
            options-dense
            :options="podsOptions"
            :model-value="selectedPodName"
            @update:model-value="$emit('update-selected-pod', $event)"
          />
        </div>
        <runai-tooltip-wrapper disable-break-line>
          <q-btn
            class="download-button"
            size="12px"
            icon="fa-regular fa-download"
            :disable="showDrawerNoData"
            flat
            round
            @click="downloadLogs"
          >
          </q-btn>
          <template #tooltip-content>
            {{ showDrawerNoData ? `There are no logs to download for this ${entityType}.` : "Download as text" }}
          </template>
        </runai-tooltip-wrapper>
      </div>
      <div class="separator"></div>
    </div>
    <section class="runai-log-body">
      <section v-if="showLoading">
        <q-linear-progress animation-speed="100" indeterminate />
        <div class="loader">Getting everything ready ...</div>
      </section>

      <section v-else class="runai-log-container" :class="{ 'paused-scroll': scrollPaused }">
        <runai-drawer-no-data
          v-if="showDrawerNoData"
          :messages="drawerNoDataMessages"
          :fa-icon-name-and-style="drawerNoDataIcon"
        />
        <template v-else>
          <div class="logs-container">
            <div class="list-container" ref="list-container">
              <q-virtual-scroll
                :items="lines"
                class="logs-list"
                v-slot="{ item, index }"
                @mousedown="pauseAutoScroll"
                @wheel="pauseAutoScroll"
                virtual-scroll-slice-size="200"
              >
                <q-item :key="index">
                  <q-item-section>
                    <q-item-label> {{ item }} </q-item-label>
                  </q-item-section>
                </q-item>
              </q-virtual-scroll>
            </div>
          </div>
          <div class="resume-button-wrapper">
            <q-btn
              v-if="scrollPaused"
              text-color="black"
              label="resume logs"
              class="resume-button"
              @click="resumeAutoScroll"
            />
          </div>
        </template>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { nextTick, type PropType } from "vue";
// models
import type { ILogParams } from "@/models/logs.model";
import type { IWebSocketSubscription } from "@/models/global.model";

// services
import { webSocketsService } from "@/services/infra/websockets.service/websockets.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { createDownloadLink } from "@/utils/common.util";

// stores
import { useClusterStore } from "@/stores/cluster.store";

// constants
import { RESEARCHER } from "@/common/api.constant";

// Components
import { RunaiDrawerNoData } from "@/components/common/runai-drawer-no-data";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { RunaiSelect } from "@/components/common/runai-select";

export default defineComponent({
  components: { RunaiDrawerNoData, RunaiTooltipWrapper, RunaiSelect },
  props: {
    logParams: {
      type: [Object, null] as PropType<ILogParams | null>,
      required: true,
    },
    showLogs: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    loadingLogs: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    entityType: {
      type: String as PropType<string>,
      required: true,
    },
    podsOptions: {
      type: [Array, null] as PropType<string[] | null>,
      required: true,
    },
    selectedPodName: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
  },
  data() {
    return {
      wsSubscription: null as IWebSocketSubscription | null,
      remainderLine: "" as string,
      lines: [] as string[],
      error: false as boolean,
      connected: false as boolean,
      scrollPaused: false as boolean,
      clusterStore: useClusterStore(),
      isLoading: false as boolean,
    };
  },
  computed: {
    showDrawerNoData(): boolean {
      return this.error || this.lines.length === 0 || !this.showLogs;
    },
    drawerNoDataMessages(): string[] {
      if (this.error) return ["Logs aren't available right now.", "If this issue persists, contact your administrator."];
      else if (!this.showLogs) return [`This ${this.entityType} has no logs because it is not active.`];
      return [`There are no logs for this ${this.entityType} yet.`];
    },
    drawerNoDataIcon(): string {
      if (this.error) return "fa-thin fa-file-exclamation";
      return "fa-thin fa-file";
    },
    showLoading(): boolean {
      if (!this.showLogs) return false;
      return this.isLoading || this.loadingLogs;
    },
  },
  methods: {
    appendLog(event: MessageEvent): void {
      this.connected = true;
      this.isLoading = false;
      let logs: string[] = event.data.split("\n");
      logs[0] = this.remainderLine + logs[0];
      this.remainderLine = logs[logs.length - 1];
      logs = logs.slice(0, logs.length - 1);
      logs.forEach((log: string) => {
        this.lines.push(log);
      });
      if (!this.scrollPaused) {
        this.autoScroll();
      }
    },
    initLogReader(): void {
      this.isLoading = true;
      this.wsSubscription = webSocketsService.subscribe(
        `${useClusterStore().currentCluster.domain}/${RESEARCHER.v1}/`,
        "logs",
        this.logParams,
      );
      this.wsSubscription.onMessage = this.appendLog;
      this.wsSubscription.onError = this.onError;
      this.wsSubscription.onClose = this.onClose;
    },
    onError(event: Event): void {
      console.error(event);
      this.wsSubscription?.unsubscribe();
      this.error = true;
      this.connected = true;
      this.isLoading = false;
    },
    onClose(): void {
      this.isLoading = false;
      if (!this.connected) {
        this.error = true;
      }
    },
    autoScroll(): void {
      if (this.showDrawerNoData || !this.logParams) return;
      nextTick(() => {
        const listContainer: HTMLElement = this.$refs["list-container"] as HTMLElement;
        if (!listContainer) return;
        listContainer.scrollTop = (listContainer as HTMLElement).scrollHeight;
      });
    },
    pauseAutoScroll(): void {
      if (this.lines.length) {
        this.scrollPaused = true;
      }
    },
    resumeAutoScroll(): void {
      this.scrollPaused = false;
      this.autoScroll();
    },
    async downloadLogs(): Promise<void> {
      if (!this.logParams) return;
      const logsResponse: Blob = await researcherService.downloadLogs({
        project: this.logParams.project,
        jobName: this.logParams.jobName,
      });
      createDownloadLink(logsResponse, "logs.txt");
    },
  },
  watch: {
    scrollPaused: function (paused: boolean) {
      if (paused) return;
      this.autoScroll();
    },
    logParams: {
      handler(): void {
        if (!this.logParams) return;
        this.initLogReader();
      },
      immediate: true,
    },
    showLogs: {
      async handler(): Promise<void> {
        if (!this.showLogs || !this.logParams) return;
        this.initLogReader();
      },
    },
  },
  unmounted() {
    this.wsSubscription && this.wsSubscription?.unsubscribe();
  },
});
</script>

<style lang="scss" scoped>
.runai-log-reader {
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;

  .runai-log-header {
    height: 48px;
    width: 100%;
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid $black-10;
  }

  .runai-log-body {
    color: $white;
    flex: 1;
    background-color: $navy;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .runai-log-container {
      display: flex;
      flex: 1;
      overflow: hidden;
      flex-direction: column;
    }

    .logs-container {
      flex: 1;
      overflow: hidden;
      display: flex;
      padding-top: 16px;

      .list-container {
        overflow: auto;
        width: 100%;
      }

      .logs-list {
        width: 100%;
      }

      .q-item {
        min-height: 24px;
        font-size: 13px;
        padding-bottom: 2px;
        padding-top: 2px;
      }
    }

    .resume-button-wrapper {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: center;
      margin: 16px 0;
      .resume-button {
        background-color: $grey-4;
        color: $navy;
        width: 155px;
      }
    }
  }

  .loader {
    text-align: center;
    width: 100%;
    padding-top: 50px;
    color: $white;
  }

  .no-logs {
    height: 100%;
  }
}
</style>
