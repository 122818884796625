import { Action, ResourceType } from "@/swagger-models/authorization-client";
import type { RunaiRouteRecord } from "vue-router";

export enum WORKLOAD_ROUTE_NAMES {
  WORKLOAD_INDEX = "workload-index",
}
export const workloadRoutes: RunaiRouteRecord = {
  path: "/workloads",
  component: () => import("@/views/workloads/workload-app.vue"),
  name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
  meta: {
    pageTitle: "Workloads",
    resourceType: ResourceType.Workloads,
    requiresAuth: true,
    minPermittedActions: [Action.Read],
  },
};
