<template>
  <runai-expansion-item label="Projects" class="project-section q-pb-none" disable-closing>
    <runai-form-card-section
      :loading="loading"
      :main-message="`Select the project in which your ${entityType} will run`"
      :cards-list="projectCards"
      :selected-cards-ids="selectedProjectsIds"
      search-name="projects"
      @selected-card-changed="onSelectedProject"
      :sort-options="{ name: true, creation: true }"
      default-sort-option="name"
    />
    <div class="col-12">
      <q-field class="hidden-field" :model-value="selectedProjectIdAsString" :rules="[isRequiredProject]"></q-field>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Models
import type { IProjectResources } from "@/models/project.model";
import { TCardCmpName, type ICardListItem } from "@/components/common/runai-card-list";

// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiFormCardSection } from "@/components/common";

// Utils
import { required } from "@/common/form.validators";

// Constants
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiFormCardSection,
  },
  props: {
    entityType: {
      type: String as PropType<string>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    projects: {
      type: Array as PropType<Array<IProjectResources>>,
      required: true,
    },
    selectedProjectId: {
      type: [Number, null] as PropType<number | null>,
      required: true,
    },
  },
  computed: {
    projectCards(): Array<ICardListItem> {
      return this.projects.map((project: IProjectResources) => {
        const name = project.name;
        const quota = (project.deservedGpus || 0).toString();
        const allocated = (project.allocatedGpus || 0).toString();
        const overquota = (project.allocatedGpus - project.deservedGpus).toString();

        return {
          id: String(project.id),
          cardName: TCardCmpName.PROJECT,
          searchValues: [name, quota, allocated, overquota].filter((val) => !!val),
          data: project,
          sortInfo: {
            name: project.name,
            createdAt: project.createdAt,
          },
        };
      });
    },
    selectedProjectIdAsString(): string | null {
      return this.selectedProjectId !== null && this.selectedProjectId !== -1 ? String(this.selectedProjectId) : null;
    },
    selectedProjectsIds(): Array<string> {
      return this.selectedProjectId ? [String(this.selectedProjectId)] : [];
    },
  },
  methods: {
    onSelectedProject(selectedItems: Array<string>): void {
      selectedItems.length === 0
        ? this.$emit("project-changed", null)
        : this.$emit("project-changed", Number(selectedItems[0]));
    },
    isRequiredProject(val: string | null): boolean | string {
      return (val && required(val)) || errorMessages.SELECT_PROJECT;
    },
  },
});
</script>
